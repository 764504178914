import { createAction } from 'redux-act';

import { handleResourceNotFound } from 'baas-ui/action_wrappers';
import { Trigger, TriggerResumeOptions } from 'baas-ui/events/types';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { track } from 'baas-ui/tracking';
import { BaseRequestPayload } from 'baas-ui/types';
import { BaseEventSubscription, EventSubscriptionExecution, ResourceType, ServiceSupportedRegions } from 'admin-sdk';

const NAME = 'events/';

interface TriggerRequest extends BaseRequestPayload {
  triggerId: string;
}
interface TriggerPayload extends BaseRequestPayload {
  trigger: Trigger;
}

interface LoadRequest extends BaseRequestPayload {
  type?: ResourceType;
}

interface ResumeRequest extends TriggerRequest {
  options?: TriggerResumeOptions;
}

type FetchRequest = TriggerRequest;
type CreateRequest = TriggerPayload;
type UpdateRequest = TriggerRequest & TriggerPayload;
type RemoveRequest = TriggerRequest;

interface SupportedRegionsRequest {
  provider: string;
  service: string;
}

// editor actions
export const clearErrors = createAction(`${NAME}clear errors`);

export const setAtlasClusterErrors = createAction<string>(`${NAME}set atlas cluster errors`);

// api actions
export const [loadEventsActions, loadEvents] = createActionsAndExecutor<LoadRequest, BaseEventSubscription[]>(
  `${NAME}load`,
  (client, { groupId, appId, type }) =>
    () =>
      client.apps(groupId).app(appId).eventSubscriptions().list(type)
);

export const [fetchEventActions, fetchEventInner] = createActionsAndExecutor<FetchRequest, BaseEventSubscription>(
  `${NAME}fetch`,
  (client, { groupId, appId, triggerId }) =>
    client.apps(groupId).app(appId).eventSubscriptions().eventSubscription(triggerId).get
);

export const fetchEvent = handleResourceNotFound(fetchEventInner);

export const [createEventActions, createEvent] = createActionsAndExecutor<CreateRequest, BaseEventSubscription>(
  `${NAME}create`,
  (client, { groupId, appId, trigger }) =>
    () => {
      track('TRIGGERS_CREATE.SAVE_SUBMITTED', trigger);
      return client.apps(groupId).app(appId).eventSubscriptions().create(trigger);
    }
);

export const [updateEventActions, updateEvent] = createActionsAndExecutor<UpdateRequest, void>(
  `${NAME}update`,
  (client, { groupId, appId, triggerId, trigger }) =>
    () => {
      track('TRIGGERS_CREATE.SAVE_SUBMITTED', { ...trigger, _id: triggerId });
      return client.apps(groupId).app(appId).eventSubscriptions().eventSubscription(triggerId).update(trigger);
    }
);

export const [resumeEventActions, resumeEvent] = createActionsAndExecutor<ResumeRequest, void>(
  `${NAME}resume`,
  (client, { groupId, appId, triggerId, options = { disableToken: false } }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .eventSubscriptions()
        .eventSubscription(triggerId)
        .resume({ disableToken: options.disableToken })
);

export const [removeEventActions, removeEvent] = createActionsAndExecutor<RemoveRequest, void>(
  `${NAME}remove`,
  (client, { groupId, appId, triggerId }) =>
    client.apps(groupId).app(appId).eventSubscriptions().eventSubscription(triggerId).remove
);

export const [fetchEventExecutionActions, fetchEventExecution] = createActionsAndExecutor<
  TriggerRequest,
  EventSubscriptionExecution
>(
  `${NAME}execution`,
  (client, { groupId, appId, triggerId }) =>
    client.apps(groupId).app(appId).eventSubscriptions().eventSubscription(triggerId).execution
);

export const [loadServiceSupportedRegionsActions, loadServiceSupportedRegions] = createActionsAndExecutor<
  SupportedRegionsRequest,
  ServiceSupportedRegions[]
>(`${NAME}load`, (client, { provider, service }) => client.private().provider(provider).service(service).regions().get);

export const asyncEditRcvActions = [createEventActions.rcv, removeEventActions.rcv, updateEventActions.rcv];

export const updateExecutionsAction = createAction<EventSubscriptionExecution[]>(`${NAME}update-executions`);
