import { Theme } from '@emotion/react';

import { MetricsColor } from './types';

// styledMetricsColor should be used within emotion styled components
export const styledMetricsColor =
  (color: MetricsColor) =>
  ({ theme }: { theme: Theme }) => {
    switch (color) {
      case 'red':
        return theme.leafygreen.colors.red.base;
      case 'light-red':
        return 'var(--mdb-redLight1)'; // returns css variable for dark mode compatibility
      case 'blue':
        return theme.leafygreen.colors.blue.light1;
      case 'green':
        return theme.leafygreen.colors.green.dark1;
      case 'light-gray':
        return theme.leafygreen.colors.gray.light1;
      case 'light-gray-2':
        return theme.leafygreen.colors.gray.light2;
      case 'light-gray-3':
        return 'var(--mdb-grayLight3)'; // returns css variable for dark mode compatibility
      case 'dark-gray-1':
        return theme.leafygreen.colors.gray.dark1;
      case 'dark-gray-2':
        return theme.leafygreen.colors.gray.dark2;
      case 'orange':
        return '#f97216';
      default:
        throw new Error(`color '${color}' does not exist in metrics color palette`);
    }
  };

// metricsColorCode should be used directly in components
export const metricsColorCode = (theme: Theme, color: MetricsColor) => styledMetricsColor(color)({ theme });
