export const emptyNameError = 'Name must not be empty.';
export const tooLongNameError = 'Name must not be longer than 100 characters.';

export const validateRoleOrFilterName = (roleName: string): string => {
  if (!roleName.trim() || roleName.length === 0) {
    return emptyNameError;
  }
  if (roleName.length > 100) {
    return tooLongNameError;
  }
  return '';
};
