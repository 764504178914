import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HostName, ResourceSegmentInterface, useCloudNavContext } from '@lg-private/cloud-nav';

import { loadApps as loadAppsAction } from 'baas-ui/home/actions';
import { appServicesVisibleProductTypes } from 'baas-ui/home/types';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { getAppState, getSettingsState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';
import rootUrl from 'baas-ui/urls';

// useAppsResourceNavigator adds an app select to the top nav in CloudNavLayout
// If the hook fails to load apps, the resource navigator will not render
export const useAppsResourceNavigator = () => {
  const dispatch = useDispatch<AsyncDispatch>();
  const { setProjectResourcePath } = useCloudNavContext();

  const app = useSelector((state: RootState) => getAppState(state).app);
  const { showCloudNav } = useSelector((state: RootState) => getSettingsState(state));

  const groupId = app?.groupId ?? '';
  const appId = app?.id ?? '';

  const loadAppResources = useCallback(async () => {
    try {
      const loadedApps = await dispatch(loadAppsAction({ groupId, products: appServicesVisibleProductTypes }));
      if (!loadedApps) {
        return [];
      }

      const resourceSegment: ResourceSegmentInterface = {
        resourceType: 'app',
        resourceTypeLabel: 'App',
        currentId: appId,
        allResourcesLink: {
          host: HostName.BAAS,
          path: rootUrl.groups().group(groupId).apps().list(),
        },
        availableResources: loadedApps.map((loadedApp) => ({
          resourceType: 'app',
          resourceId: loadedApp.id,
          resourceName: loadedApp.name,
          link: {
            host: HostName.BAAS,
            path: rootUrl.groups().group(loadedApp.groupId).apps().app(loadedApp.id).dashboard(),
          },
        })),
      };

      return [resourceSegment];
    } catch {
      return [];
    }
  }, [groupId, appId]);

  useEffect(() => {
    if (showCloudNav && groupId) {
      setProjectResourcePath(loadAppResources());
    }
  }, [groupId, setProjectResourcePath, loadAppResources]);
};
