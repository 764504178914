import {
  RawValidateCollectionErr,
  RawValidateCollectionResults,
  ValidateCollectionErr,
  ValidateCollectionResults,
} from './types';
export const fromRawValidateCollectionErrs = (rawValidateErrors: RawValidateCollectionErr[]) =>
  rawValidateErrors.map(
    (rawValErr): ValidateCollectionErr => ({
      errorCode: rawValErr.error_code,
      errorCount: rawValErr.error_count,
      field: rawValErr.field,
      failedIds: rawValErr.failed_ids.map((failedId) => JSON.stringify(failedId)),
      failedDocumentsQuery: rawValErr.failed_documents_query,
    })
  );

export const fromRawValidateCollectionResults = (
  rawValidateCollectionResults: RawValidateCollectionResults
): ValidateCollectionResults => ({
  errors: fromRawValidateCollectionErrs(rawValidateCollectionResults.errors),
  totalErrorCount: rawValidateCollectionResults.total_error_count,
  totalProcessedCount: rawValidateCollectionResults.total_processed_count,
});
