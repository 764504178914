import moment from 'moment-timezone';

const SHORT_DATE_MOMENT_FORMAT = 'll z';
const LONG_DATE_MOMENT_FORMAT = 'lll z';

interface BillingPeriod {
  today: string;
  lastUpdated: string;
  timeUntilEndOfDay: string;
}

const useMetricBillingPeriod = (timeZoneId: string): BillingPeriod => {
  // use preferred timezone (defaulted to UTC)
  const now = moment().tz(timeZoneId);

  // use UTC timezone (not the preferred timezone) since the request for metrics uses start time of midnight UTC
  const timeUntilEndOfDay = moment().utc().endOf('day').fromNow();

  return {
    today: now.format(SHORT_DATE_MOMENT_FORMAT),
    lastUpdated: now.format(LONG_DATE_MOMENT_FORMAT),
    timeUntilEndOfDay,
  };
};

export default useMetricBillingPeriod;
