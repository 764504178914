import React from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm';

import { IncompatibleRolesModal } from 'baas-ui/sync/sync-page-config/sync-config-modals';
import { IncompatibleRolesModalPayload } from 'baas-ui/sync/types/ruleTypes';

interface ModalProps {
  incompatibleRoles: IncompatibleRolesModalPayload;
}

type Props = ModalProps & ReactConfirmProps;

const IncompatibleRolesModalWrapper = ({ incompatibleRoles, show, proceed, cancel }: Props) => {
  return (
    <IncompatibleRolesModal
      incompatibleRoles={incompatibleRoles}
      open={show}
      showConfirmButton
      onClickConfirm={() => proceed()}
      onCancel={() => cancel()}
    />
  );
};

export default createConfirmation(confirmable(IncompatibleRolesModalWrapper));
