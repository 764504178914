import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import Banner from '@leafygreen-ui/banner';
import Modal from '@leafygreen-ui/modal';
import { palette } from '@leafygreen-ui/palette';
import { Cell, Row, Table, TableHeader } from '@leafygreen-ui/table-legacy';
import { Tab, Tabs } from '@leafygreen-ui/tabs';
import { Body, Link, Subtitle } from '@leafygreen-ui/typography';

import { redirectTo as redirectToAction } from 'baas-ui/actions';
import { ScaledValue } from 'baas-ui/app/metrics-card/types';
import { FeatureFlag } from 'baas-ui/common/featureSettings';
import { ZIndex } from 'baas-ui/common/styles/zIndex';
import { AppUsage, MeasurementsByName, UsageByMeasurement } from 'baas-ui/measurements/types';
import MeasurementsUsageBar from 'baas-ui/measurements/usage-bar';
import { BarWidth } from 'baas-ui/measurements/usage-bar/UsageBar';
import {
  legacyFormatMeasurementThreshold,
  legacyFormatMeasurementUsage,
  legacyMeasurementTitle,
} from 'baas-ui/measurements/utils';
import { BilledMetric, MetricUsagesByName } from 'baas-ui/metrics/types';
import { BILLED_METRICS_DETAILS, NO_VALUE, scaledMetricUsage } from 'baas-ui/metrics/utils';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { featureSettings } from 'baas-ui/stitch_ui';
import urls from 'baas-ui/urls';
import { MeasurementName, PartialApp } from 'admin-sdk';

export enum TabOption {
  Requests,
  DataTransfer,
  ComputeRuntime,
  SyncRuntime,
}

export interface PublicProps {
  groupTotalMetrics: MetricUsagesByName;
  appTotalMetrics: Record<string, MetricUsagesByName>;
  apps: PartialApp[];
  usageByMeasurement: UsageByMeasurement;
  open: boolean;
  initialTab: TabOption;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  groupMeasurementsByName: MeasurementsByName;
  lastUpdated: string;
}

interface DispatchProps {
  redirectTo(url: string, options?: { replace: boolean }): void;
}

export type Props = PublicProps & DispatchProps;

export enum TestSelector {
  MetricModalTabs = 'metric-modal-tabs',
  RequestsTab = 'request-tab',
  DataTransferTab = 'data-transfer-tab',
  ComputeRuntimeTab = 'compute-runtime-tab',
  SyncRuntimeTab = 'sync-runtime-tab',
  CellAppName = 'cell-app-name',
  CellAppUsage = 'cell-app-usage',
  CellAppPercentage = 'cell-app-percentage',
  TotalUsage = 'total-usage',
  UsageThreshold = 'usage-threshold',
  LastUpdated = 'last-updated',
  ErrorBanner = 'error-banner',
}

const StyledDivider = styled.hr`
  width: 1px;
  background-color: ${palette.gray.base};
  border: none;
  margin: 0px 12px;
`;

const StyledSubHeader = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 8px 16px;
  margin-top: 12px;
  background-color: ${palette.gray.light2};
  border-radius: 6px;
`;

const StyledLastUpdated = styled(Body)`
  font-size: 11px;
  margin-bottom: 12px;
`;

const StyledBanner = styled(Banner)`
  margin-top: 12px;
`;

const tabToLegacyMeasurementName = (tabOption: TabOption) => {
  switch (tabOption) {
    case TabOption.Requests:
      return MeasurementName.RequestCount;
    case TabOption.DataTransfer:
      return MeasurementName.DataOut;
    case TabOption.ComputeRuntime:
      return MeasurementName.ComputeTime;
    case TabOption.SyncRuntime:
      return MeasurementName.SyncTime;
    default:
      return MeasurementName.RequestCount;
  }
};

const StyledModal = styled(Modal)`
  z-index: ${ZIndex.Modal};
`;

export const formatMetricUsage = (scaledValue: ScaledValue, metric: BilledMetric) => {
  const value = scaledMetricUsage(scaledValue);

  if (value === NO_VALUE || metric === BilledMetric.RequestCount) {
    return value;
  }
  return `${value} ${scaledValue.units}`;
};

export const formatPercentContribution = (appTotal, groupTotal: number) => {
  if (appTotal === -1 || groupTotal === -1) {
    return NO_VALUE;
  }
  const percentage = groupTotal === 0 ? 0 : (appTotal / groupTotal) * 100;
  if (percentage < 1 && percentage > 0) {
    return `< 1%`;
  }
  return `${Math.round(percentage)}%`;
};

const legacyTable = (
  redirectTo: (url: string, options?: { replace: boolean }) => void,
  usageByMeasurement: UsageByMeasurement,
  activeMeasurement: MeasurementName,
  isPricingChangeEnabledAndSyncMetric: boolean
) => {
  const headers = [
    <TableHeader<AppUsage> label="App" sortBy={(datum) => datum.app.name} />,
    <TableHeader label="App Usage" />,
    <TableHeader<AppUsage>
      align="left"
      label="%"
      sortBy={(datum) =>
        formatPercentContribution(datum.measurement.usage, usageByMeasurement[activeMeasurement].totalUsage)
      }
    />,
    <TableHeader label="" />,
  ];

  return (
    <Table data={usageByMeasurement[activeMeasurement].appMeasurements} columns={headers}>
      {({ datum }) => (
        <Row>
          <Cell key="app-name" data-testid={TestSelector.CellAppName}>
            <Link onClick={() => redirectTo(urls.groups().group(datum.app.groupId).apps().app(datum.app.id).get())}>
              {datum.app.name}
            </Link>
          </Cell>
          <Cell key="app-usage-count" data-testid={TestSelector.CellAppUsage} align="right">
            {legacyFormatMeasurementUsage(datum.measurement, isPricingChangeEnabledAndSyncMetric)}
          </Cell>
          <Cell key="app-percent-contribution" data-testid={TestSelector.CellAppPercentage} align="right">
            {formatPercentContribution(datum.measurement.usage, usageByMeasurement[activeMeasurement].totalUsage)}
          </Cell>
          <Cell key="app-usage-bar">
            <MeasurementsUsageBar
              usage={datum.measurement.usage}
              threshold={usageByMeasurement[activeMeasurement].totalUsage}
              barWidth={BarWidth.mini}
            />
          </Cell>
        </Row>
      )}
    </Table>
  );
};

const ContributionsModal = ({
  open,
  setOpen,
  initialTab,
  usageByMeasurement,
  groupMeasurementsByName,
  lastUpdated,
  redirectTo,
}: Props) => {
  const isPricingChangeEnabled = featureSettings.useFeatureSetting(FeatureFlag.PricingChange);

  const [activeTab, setActiveTab] = React.useState<TabOption>(initialTab);
  const [activeLegacyMeasurement, setActiveLegacyMeasurement] = React.useState(tabToLegacyMeasurementName(initialTab));

  const updateTab = (newTab: TabOption) => {
    setActiveTab(newTab);
    setActiveLegacyMeasurement(tabToLegacyMeasurementName(newTab));
  };

  const formattedTitle = legacyMeasurementTitle(activeLegacyMeasurement);

  const isPricingChangeEnabledAndSyncMetric =
    isPricingChangeEnabled && activeLegacyMeasurement === MeasurementName.SyncTime;
  const formattedThreshold = legacyFormatMeasurementThreshold(
    groupMeasurementsByName[activeLegacyMeasurement],
    isPricingChangeEnabled
  );
  const formattedTotalUsage = legacyFormatMeasurementUsage(
    groupMeasurementsByName[activeLegacyMeasurement],
    isPricingChangeEnabledAndSyncMetric
  );
  const tableLayout = legacyTable(
    redirectTo,
    usageByMeasurement,
    activeLegacyMeasurement,
    isPricingChangeEnabled && activeLegacyMeasurement === MeasurementName.SyncTime
  );

  React.useEffect(() => {
    updateTab(initialTab);
  }, [initialTab]);

  return (
    <StyledModal open={open} setOpen={setOpen}>
      <Subtitle>Total App Usage</Subtitle>
      <Tabs
        selected={activeTab}
        setSelected={setActiveTab}
        aria-label="metric usage tabs"
        data-testid={TestSelector.MetricModalTabs}
      >
        <Tab
          name={BILLED_METRICS_DETAILS[BilledMetric.RequestCount].title}
          data-testid={TestSelector.RequestsTab}
          onClick={() => updateTab(TabOption.Requests)}
        />
        <Tab
          name={BILLED_METRICS_DETAILS[BilledMetric.DataOut].title}
          data-testid={TestSelector.DataTransferTab}
          onClick={() => updateTab(TabOption.DataTransfer)}
        />
        <Tab
          name={BILLED_METRICS_DETAILS[BilledMetric.ComputeTime].title}
          data-testid={TestSelector.ComputeRuntimeTab}
          onClick={() => updateTab(TabOption.ComputeRuntime)}
        />
        <Tab
          name={BILLED_METRICS_DETAILS[BilledMetric.SyncTime].title}
          data-testid={TestSelector.SyncRuntimeTab}
          onClick={() => updateTab(TabOption.SyncRuntime)}
        />
      </Tabs>
      {formattedTotalUsage === NO_VALUE && (
        <StyledBanner variant="danger" data-testid={TestSelector.ErrorBanner}>
          <Body>The request to fetch metrics has failed. Please try again later.</Body>
        </StyledBanner>
      )}
      <StyledSubHeader>
        <Body data-testid={TestSelector.TotalUsage}>
          Total {`${formattedTitle} - `}
          <strong>{formattedTotalUsage}</strong>
        </Body>
        <StyledDivider />
        <Body data-testid={TestSelector.UsageThreshold}>
          Free Tier available - <strong>{formattedThreshold}</strong>
        </Body>
      </StyledSubHeader>
      <StyledLastUpdated data-testid={TestSelector.LastUpdated}>Last updated: {lastUpdated}</StyledLastUpdated>
      {tableLayout}
    </StyledModal>
  );
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  redirectTo: (url: string, options?: { replace: boolean }) => dispatch(redirectToAction(url, options)),
});

export default connect(() => ({}), mapDispatchToProps)(ContributionsModal);
