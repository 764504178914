import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import * as rootActions from 'baas-ui/actions';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { withQueryParams } from 'baas-ui/urls';

const BAAS_LOCAL_STORAGE_PREFIX = '_baas.';

interface DispatchProps {
  redirectTo(path: string): void;
}

export type Props = DispatchProps;

const LocalStorageMigrationProvider = ({ redirectTo }: Props) => {
  const location = useLocation();

  useEffect(() => {
    const queryParams = qs.parse(location.search);

    const newQueryParams = Object.keys(queryParams).reduce((accQueryParams, key: string) => {
      if (key.startsWith(BAAS_LOCAL_STORAGE_PREFIX)) {
        const localStorageValue = window.localStorage.getItem(key);
        if (!localStorageValue || localStorageValue === '{}') {
          window.localStorage.setItem(key, queryParams[key] as string);
        }
        return accQueryParams;
      }
      // do not remove query params unrelated to local storage
      return { ...accQueryParams, [key]: queryParams[key] };
    }, {});

    if (JSON.stringify(queryParams) !== JSON.stringify(newQueryParams)) {
      const urlWithoutQuery = window.location.pathname;
      const newUrl = withQueryParams(urlWithoutQuery, newQueryParams);

      redirectTo(newUrl);
    }
  }, []);

  return null;
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  redirectTo: (path: string) => dispatch(rootActions.redirectTo(path, { replace: true })),
});

export default connect(() => ({}), mapDispatchToProps)(LocalStorageMigrationProvider);
