import React from 'react';
import { Map, OrderedMap } from 'immutable';

import loadable from 'baas-ui/common/components/loadable';
import { PRODUCT_CLOUD, PRODUCT_SELF_HOSTED } from 'baas-ui/common/constants';
import { Action, AvailableService } from 'baas-ui/models';
import * as svg from 'baas-ui/svg';
import { IncomingWebhook } from 'admin-sdk';

import {
  GITHUB_INCOMING_WEBHOOK_DEFAULT_DEBUG_CONSOLE,
  GITHUB_INCOMING_WEBHOOK_DEFAULT_SOURCE,
  HTTP_INCOMING_WEBHOOK_DEFAULT_DEBUG_CONSOLE,
  HTTP_INCOMING_WEBHOOK_DEFAULT_SOURCE,
  TWILIO_INCOMING_WEBHOOK_DEFAULT_DEBUG_CONSOLE,
  TWILIO_INCOMING_WEBHOOK_DEFAULT_SOURCE,
} from './defaultIncomingWebhookSources';

const RulesPage = loadable(() => import(/* webpackChunkName: 'RulesPage' */ '../rules'));

const EditHTTPIncomingWebhookOptions = loadable(
  () => import(/* webpackChunkName: 'EditIncomingWebhook' */ './http/components/EditIncomingWebhook')
);

const HTTPWebhookCurlExample = loadable(
  () => import(/* webpackChunkName: 'HTTPWebhookCurlExample' */ './http/components/HTTPWebhookCurlExample')
);

const EditGithubIncomingWebhookOptions = loadable(
  () => import(/* webpackChunkName: 'EditIncomingWebhook' */ './github/components/EditIncomingWebhook')
);

const EditTwilioConfig = loadable(
  () =>
    import(/* webpackChunkName: 'EditTwilioConfig' */ './twilio/components/EditConfig').then((_) => ({
      default: _.EditTwilioConfig,
    })),
  { name: 'EditTwilioConfig' }
);

const TwilioConfigForm = loadable(
  () =>
    import(/* webpackChunkName: 'TwilioConfigForm' */ './twilio/components/EditConfig').then((_) => ({
      default: _.TwilioConfigForm,
    })),
  { name: 'TwilioConfigForm' }
);

const EditAWSConfig = loadable(
  () =>
    import(/* webpackChunkName: 'EditAWSConfig' */ './aws/components/EditConfig').then((_) => ({
      default: _.EditAWSConfig,
    })),
  {
    name: 'EditAWSConfig',
  }
);
const EditAWSRules = loadable(() => import(/* webpackChunkName: 'EditAWSRules' */ './aws/components/ActionEditRules'));

const AWSConfigForm = loadable(
  () =>
    import(/* webpackChunkName: 'AWSConfigForm' */ './aws/components/EditConfig').then((_) => ({
      default: _.AWSConfigForm,
    })),
  {
    name: 'AWSConfigForm',
  }
);

const EditMongoConfig = loadable(
  () =>
    import(/* webpackChunkName: 'EditMongoConfig' */ './mongodb/edit-config/EditConfig').then((_) => ({
      default: _.EditMongoConfig,
    })),
  { name: 'EditMongoConfig' }
);

const MongoConfigForm = loadable(
  () =>
    import(/* webpackChunkName: 'MongoConfigForm' */ './mongodb/mongo-config-form/MongoConfigForm').then((_) => ({
      default: _.MongoConfigForm,
    })),
  { name: 'MongoConfigForm' }
);

const EditGCMConfig = loadable(
  () =>
    import(/* webpackChunkName: 'EditGCMConfig' */ './push/gcm/components/EditConfig').then((_) => ({
      default: _.EditGCMConfig,
    })),
  { name: 'EditGCMConfig' }
);

const GCMConfigForm = loadable(
  () =>
    import(/* webpackChunkName: 'GCMConfigForm' */ './push/gcm/components/EditConfig').then((_) => ({
      default: _.GCMConfigForm,
    })),
  { name: 'GCMConfigForm' }
);

export enum MongoDataSourceType {
  SelfHosted = 'mongodb',
  Atlas = 'mongodb-atlas',
  DataFederation = 'datalake',
}

export const SVCTYPE_TWILIO = 'twilio';
export const SVCTYPE_HTTP = 'http';
export const SVCTYPE_AWS_S3 = 'aws-s3';
export const SVCTYPE_AWS_GENERIC = 'aws';
export const SVCTYPE_AWS_SES = 'aws-ses';
export const SVCTYPE_GITHUB = 'github';
export const SVCTYPE_GCM = 'gcm';

export const CONFIG_TAB = 'config';
export const INCOMING_WEBHOOK_TAB = 'incomingWebhooks';
export const RULES_TAB = 'rules';

export const isSelfHostedMongoService = (serviceType) => serviceType === MongoDataSourceType.SelfHosted;
export const isAtlasMongoService = (serviceType) => serviceType === MongoDataSourceType.Atlas;
export const isSelfHostedOrAtlasMongoService = (serviceType) =>
  isSelfHostedMongoService(serviceType) || isAtlasMongoService(serviceType);
export const isDataLakeServiceType = (serviceType) => serviceType === MongoDataSourceType.DataFederation;
export const secretFieldNameBySvcType = {
  [SVCTYPE_AWS_GENERIC]: 'secretAccessKey',
  [SVCTYPE_TWILIO]: 'auth_token',
  [SVCTYPE_GCM]: 'apiKey',
};

const makeActionsMap = (actions) => OrderedMap(actions.map((action) => [action.name, action]));

const mongoDefaultArgs = Map({ database: 'dbname', collection: 'c_name' });
const httpDefaultArgs = Map({ url: 'http://httpbin.org/post' });

const servicesList = [
  new AvailableService({
    type: MongoDataSourceType.SelfHosted,
    description: 'Store and query data in MongoDB instance',
    displayName: 'MongoDB',
    defaultTab: RULES_TAB,
    rulesEditor: RulesPage,
    configEditor: EditMongoConfig,
    configForm: MongoConfigForm,
    incomingWebhooks: false,
    actions: makeActionsMap([
      new Action({
        name: 'find',
        defaultArgs: { ...mongoDefaultArgs, query: {}, project: {}, limit: 10 },
      }),
      new Action({
        name: 'insert',
        defaultArgs: mongoDefaultArgs,
      }),
      new Action({
        name: 'update',
        defaultArgs: {
          ...mongoDefaultArgs,
          query: {},
          update: { $inc: { counter: 1 } },
          upsert: false,
          multi: false,
        },
      }),
      new Action({
        name: 'delete',
        defaultArgs: { ...mongoDefaultArgs, singleDoc: true, query: { counter: 10 } },
      }),
    ]),
    hideSidebar: true,
    defaultRules: {
      read: {
        owner_id: '%%user.id',
      },
      write: {
        owner_id: '%%user.id',
      },
      filters: [
        {
          match_expression: {
            owner_id: '%%user.id',
          },
          when: {
            '%%true': true,
          },
        },
      ],
      fields: {
        owner_id: {
          valid: {
            '%or': [
              {
                '%%prev': '%%user.id',
              },
              {
                '%%prev': {
                  '%exists': false,
                },
              },
            ],
          },
        },
      },
      other_fields: {},
    },
    icon: svg.MongoDB,
    forProduct: PRODUCT_SELF_HOSTED,
  }),
  new AvailableService({
    type: MongoDataSourceType.Atlas,
    description: 'Store and query data in a MongoDB Atlas instance',
    displayName: 'MongoDB Atlas',
    defaultTab: RULES_TAB,
    rulesEditor: RulesPage,
    configEditor: EditMongoConfig,
    incomingWebhooks: false,
    actions: makeActionsMap([
      new Action({
        name: 'find',
        defaultArgs: { ...mongoDefaultArgs, query: {}, project: {}, limit: 10 },
      }),
      new Action({
        name: 'insert',
        defaultArgs: mongoDefaultArgs,
      }),
      new Action({
        name: 'update',
        defaultArgs: {
          ...mongoDefaultArgs,
          query: {},
          update: { $inc: { counter: 1 } },
          upsert: false,
          multi: false,
        },
      }),
      new Action({
        name: 'delete',
        defaultArgs: { ...mongoDefaultArgs, singleDoc: true, query: { counter: 10 } },
      }),
    ]),
    icon: svg.MongoDB,
    hideAdd: true,
    hideSidebar: true,
    forProduct: PRODUCT_CLOUD,
  }),
  new AvailableService({
    type: MongoDataSourceType.DataFederation,
    description: 'Query data in a MongoDB Federated Database Instance',
    displayName: 'MongoDB Atlas Data Federation',
    defaultTab: RULES_TAB,
    configEditor: EditMongoConfig,
    incomingWebhooks: false,
    actions: makeActionsMap([
      new Action({
        name: 'find',
        defaultArgs: { ...mongoDefaultArgs, query: {}, project: {}, limit: 10 },
      }),
    ]),
    icon: svg.MongoDB,
    hideAdd: true,
    hideSidebar: true,
    forProduct: PRODUCT_CLOUD,
  }),
  new AvailableService({
    type: SVCTYPE_TWILIO,
    description: 'Send and receive text messages',
    displayName: 'Twilio',
    defaultTab: INCOMING_WEBHOOK_TAB,
    actions: makeActionsMap([
      new Action({
        name: 'send',
        defaultArgs: Map({
          to: '+12013705553',
          from: '+12018675309',
          body: 'hello from twilio!',
        }),
      }),
    ]),
    incomingWebhooks: true,
    configEditor: EditTwilioConfig,
    configForm: TwilioConfigForm,
    defaultIncomingWebhook: new IncomingWebhook({
      name: 'webhook0',
      respondResult: true,
      functionSource: TWILIO_INCOMING_WEBHOOK_DEFAULT_SOURCE,
      disableArgLogs: true,
    }),
    defaultDebugSource: TWILIO_INCOMING_WEBHOOK_DEFAULT_DEBUG_CONSOLE,
    icon: svg.Twilio,
  }),
  new AvailableService({
    type: SVCTYPE_HTTP,
    description: 'Send requests over HTTP',
    displayName: 'HTTP',
    defaultTab: INCOMING_WEBHOOK_TAB,
    actions: makeActionsMap([
      new Action({ name: 'get', defaultArgs: httpDefaultArgs }),
      new Action({ name: 'post', defaultArgs: httpDefaultArgs }),
      new Action({ name: 'put', defaultArgs: httpDefaultArgs }),
      new Action({ name: 'delete', defaultArgs: httpDefaultArgs }),
      new Action({ name: 'patch', defaultArgs: httpDefaultArgs }),
      new Action({ name: 'head', defaultArgs: httpDefaultArgs }),
    ]),
    incomingWebhookOptionsEditor: EditHTTPIncomingWebhookOptions,
    incomingWebhookCurlExample: HTTPWebhookCurlExample,
    incomingWebhooks: true,
    defaultIncomingWebhook: new IncomingWebhook({
      name: 'webhook0',
      options: { validationMethod: 'NO_VALIDATION', httpMethod: 'POST' },
      respondResult: true,
      functionSource: HTTP_INCOMING_WEBHOOK_DEFAULT_SOURCE,
      disableArgLogs: true,
    }),
    defaultDebugSource: HTTP_INCOMING_WEBHOOK_DEFAULT_DEBUG_CONSOLE,
    defaultRules: {
      when: { '%%args.url.host': { '%in': ['google.com'] } },
    },
    icon: svg.Code,
  }),
  new AvailableService({
    type: SVCTYPE_AWS_S3,
    description: 'Upload files into an S3 bucket',
    displayName: 'S3',
    defaultTab: RULES_TAB,
    longDisplayName: 'AWS - S3',
    configEditor: EditAWSConfig,
    configForm: (props) => <AWSConfigForm type={SVCTYPE_AWS_S3} {...props} />,
    actions: makeActionsMap([new Action({ name: 'put' }), new Action({ name: 'signPolicy' })]),
    incomingWebhooks: false,
    defaultRules: {
      when: { '%%args.bucket': 'myS3Bucket' },
    },
    icon: svg.AWS,
  }),
  new AvailableService({
    type: SVCTYPE_AWS_GENERIC,
    hideSidebar: true,
    description: 'Access AWS services',
    displayName: 'AWS',
    defaultTab: RULES_TAB,
    rulesEditor: EditAWSRules,
    longDisplayName: 'AWS',
    configEditor: EditAWSConfig,
    configForm: (props) => <AWSConfigForm type={SVCTYPE_AWS_GENERIC} {...props} />,
    actions: makeActionsMap([]),
    incomingWebhooks: false,
    defaultRules: {
      when: {},
    },
    icon: svg.AWS,
  }),
  new AvailableService({
    type: SVCTYPE_AWS_SES,
    description: 'Send e-mails with SES',
    displayName: 'SES',
    defaultTab: RULES_TAB,
    actions: makeActionsMap([new Action({ name: 'send' })]),
    incomingWebhooks: false,
    configEditor: EditAWSConfig,
    configForm: (props) => <AWSConfigForm type={SVCTYPE_AWS_SES} {...props} />,
    defaultRules: {
      when: { '%%args.fromAddress': 'user@domain.com' },
    },
    icon: svg.AWS,
  }),
  new AvailableService({
    type: SVCTYPE_GITHUB,
    description: 'Respond to GitHub events',
    displayName: 'GitHub',
    defaultTab: INCOMING_WEBHOOK_TAB,
    actions: OrderedMap(),
    incomingWebhooks: true,
    longDisplayName: 'GitHub',
    incomingWebhookOptionsEditor: EditGithubIncomingWebhookOptions,
    defaultIncomingWebhook: new IncomingWebhook({
      name: 'webhook0',
      functionSource: GITHUB_INCOMING_WEBHOOK_DEFAULT_SOURCE,
      respondResult: true,
      disableArgLogs: true,
    }),
    defaultDebugSource: GITHUB_INCOMING_WEBHOOK_DEFAULT_DEBUG_CONSOLE,
    icon: svg.Github,
  }),
  new AvailableService({
    type: SVCTYPE_GCM,
    description: 'Push Notifications via Google Cloud Messaging',
    displayName: 'GCM',
    defaultTab: RULES_TAB,
    longDisplayName: 'Google Cloud Messaging',
    actions: makeActionsMap([
      new Action({
        name: 'send',
        defaultArgs: Map({
          userIds: ['58f8dab21d0e26622d8fbd79'],
          notification: { body: 'hello!' },
        }),
      }),
    ]),
    incomingWebhooks: false,
    configEditor: EditGCMConfig,
    configForm: GCMConfigForm,
    hideAdd: true,
    hideSidebar: true,
    defaultRules: {
      when: { '%%user.type': 'server' },
    },
    icon: svg.PushNotifications,
  }),
];

export const nopService = new AvailableService({
  actions: makeActionsMap([]),
});

export const servicesByType = OrderedMap<string, AvailableService>(servicesList.map((svc) => [svc.type, svc]));

export const findServiceDefinition = (serviceName, appServices) => {
  const appSvc = (appServices || []).find((svc) => svc.name === serviceName);
  if (!appSvc || !servicesByType.has(appSvc.type)) {
    return nopService;
  }
  return servicesByType.get(appSvc.type);
};
