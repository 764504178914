import { useContext } from 'react';

import { RulesPageContext } from './RulesPage';

export const useRulesPageContext = () => {
  const context = useContext(RulesPageContext);
  if (!context) {
    throw new Error('useRulesPageContext must be used within a RulesPageContextProvider');
  }
  return context;
};
