import React from 'react';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import { Body } from '@leafygreen-ui/typography';

import './terminate-sync-modal.less';

const baseClassName = 'terminate-sync-modal';

export enum TestSelector {
  Modal = 'terminate-sync-modal',
  Description = 'terminate-sync-modal-description',
}

export interface Props {
  open: boolean;
  onConfirm(): void;
  onCancel(): void;
  clusterName: string;
}

const TerminateSyncModal = ({ open, onConfirm, onCancel, clusterName }: Props) => {
  return (
    <ConfirmationModal
      title={`Terminate Sync for ${clusterName}`}
      open={open}
      confirmButtonProps={{
        children: 'Terminate Sync',
        onClick: onConfirm,
      }}
      cancelButtonProps={{
        onClick: onCancel,
      }}
      variant="danger"
      requiredInputText="Terminate Sync"
      data-test-selector={TestSelector.Modal}
      data-testid={TestSelector.Modal}
      data-cy={TestSelector.Modal}
      className={baseClassName}
    >
      <Body data-test-selector={TestSelector.Description}>
        Terminating Sync will remove metadata associated with Sync and prevent all applications from syncing. You may
        re-enable Sync to re-create this metadata.
      </Body>
    </ConfirmationModal>
  );
};

export default TerminateSyncModal;
