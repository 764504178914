import React, { useState } from 'react';
import Box from '@leafygreen-ui/box';
import { RadioBox, RadioBoxGroup } from '@leafygreen-ui/radio-box-group';
import { Body } from '@leafygreen-ui/typography';

import ProviderRegionSelect from 'baas-ui/common/components/provider-region-select';
import { DeploymentModel, ProviderRegion } from 'admin-sdk';

import './application-region-selection.less';

const baseClassName = `application-region-selection`;
const radioGroupClassName = `${baseClassName}-radio-group`;

export enum TestSelector {
  DeploymentModelRadioGroup = 'deployment-model-radio-group',
  DeploymentModelTooltip = 'deployment-model-tooltip',
  GlobalDeploymentRadio = 'global-deployment-radio',
  LocalDeploymentRadio = 'local-deployment-radio',
}

export interface ApplicationRegionSelectionProps {
  providerRegion: ProviderRegion;
  setProviderRegion(providerRegion: ProviderRegion): void;
  deploymentModel: DeploymentModel;
  showRegionRecommendationInfo: boolean;
  setShowRegionRecommendationInfo(boolean): void;
  onChangeDeploymentModel(deploymentModel: DeploymentModel): void;
  onConfirm(): void;
  onChange(): void;
  onCancel(): void;
  showRegionSelect?: boolean;
  disabled?: boolean;
}

const ApplicationRegionSelection = ({
  deploymentModel,
  providerRegion,
  setProviderRegion,
  showRegionRecommendationInfo,
  setShowRegionRecommendationInfo,
  onChangeDeploymentModel,
  onCancel,
  onConfirm,
  onChange,
  showRegionSelect = false,
  disabled = false,
}: ApplicationRegionSelectionProps) => {
  const [newShowRegionSelect, setNewShowRegionSelect] = useState(showRegionSelect);

  return (
    <>
      <RadioBoxGroup
        size="full"
        name="deploymentModel"
        data-testid={TestSelector.DeploymentModelRadioGroup}
        className={`${radioGroupClassName}`}
        onChange={(e) => {
          setNewShowRegionSelect(false);
          onChangeDeploymentModel(e.target.value as DeploymentModel);
        }}
        value={deploymentModel}
      >
        <RadioBox
          data-cy="deployment-radio--local"
          data-testid={TestSelector.LocalDeploymentRadio}
          value={DeploymentModel.Local}
          checked={deploymentModel === DeploymentModel.Local}
          disabled={disabled}
        >
          <Box className={`${radioGroupClassName}-label`}>
            <Body weight="medium">Single Region</Body>
            <Body>All read and write requests will be processed in selected region.</Body>
          </Box>
        </RadioBox>
        <RadioBox
          data-testid={TestSelector.GlobalDeploymentRadio}
          value={DeploymentModel.Global}
          checked={deploymentModel === DeploymentModel.Global}
          disabled={disabled}
        >
          <Box className={`${radioGroupClassName}-label`}>
            <Body weight="medium">Global</Body>
            <Body>
              Requests are distributed globally. Triggers processing, users, and other app metadata will be stored in
              the selected region.{' '}
            </Body>
          </Box>
        </RadioBox>
      </RadioBoxGroup>
      <ProviderRegionSelect
        deploymentModel={deploymentModel}
        providerRegion={providerRegion}
        setProviderRegion={setProviderRegion}
        showRegionSelect={newShowRegionSelect}
        onCancel={() => {
          setNewShowRegionSelect(false);
          onCancel();
        }}
        onChange={() => {
          setNewShowRegionSelect(true);
          onChange();
        }}
        onConfirm={() => {
          setShowRegionRecommendationInfo(false);
          setNewShowRegionSelect(false);
          onConfirm();
        }}
        disabled={disabled}
        showRegionRecommendationInfo={showRegionRecommendationInfo}
      />
    </>
  );
};

export default ApplicationRegionSelection;
