import React from 'react';
import { connect } from 'react-redux';

import { PRODUCT_SELF_HOSTED } from 'baas-ui/common/constants';
import { BreadcrumbsItem } from 'baas-ui/nav';
import { getAppState, getHomeState, getNavigationState, getSettingsState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';
import { rootUrl } from 'baas-ui/urls';

interface ReduxStateProps {
  cloudUIBaseUrl?: string;
  orgName: string;
  orgId: string;
  projectId: string;
  projectName: string;
  appName: string;
  appId: string;
  isSelfHosted: boolean;
  groupId: string;
}

export type Props = ReduxStateProps;

const CloudBreadcrumbs = ({
  orgName,
  orgId,
  projectId,
  projectName,
  cloudUIBaseUrl,
  appName,
  appId,
  isSelfHosted,
  groupId,
}: Props) => {
  const appRootUrl = isSelfHosted
    ? rootUrl.groups().group(groupId).apps().app(appId)
    : rootUrl.groups().group(projectId).apps().app(appId);
  const appsListUrl = rootUrl.groups().group(groupId).apps().list();

  const handleRedirect = (event: Event, url: string) => {
    event.stopPropagation();
    event.preventDefault();
    window.location.assign(url);
  };

  return (
    <>
      <BreadcrumbsItem
        to={`${cloudUIBaseUrl}/v2#/org/${orgId}/projects`}
        onClick={(e) => handleRedirect(e, isSelfHosted ? appsListUrl : `${cloudUIBaseUrl}/v2#/org/${orgId}/projects`)}
        orderIndex={0}
      >
        {orgName}
      </BreadcrumbsItem>
      <BreadcrumbsItem
        to={`${cloudUIBaseUrl}/v2/${projectId}#clusters`}
        orderIndex={1}
        onClick={(e) => handleRedirect(e, isSelfHosted ? appsListUrl : `${cloudUIBaseUrl}/v2/${projectId}#clusters`)}
      >
        {projectName}
      </BreadcrumbsItem>
      {appName && (
        <BreadcrumbsItem to={appRootUrl.dashboard()} orderIndex={2}>
          {appName}
        </BreadcrumbsItem>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { cloudUIBaseUrl, product } = getSettingsState(state);
  const { organization, project } = getNavigationState(state);
  const { groupId } = getHomeState(state);
  const { app } = getAppState(state);

  return {
    cloudUIBaseUrl,
    orgName: organization?.orgName,
    orgId: organization?.orgId,
    projectId: project?.projectId,
    projectName: project?.projectName,
    appName: app?.name,
    appId: app?.id,
    groupId: groupId || app?.groupId || '',
    isSelfHosted: product === PRODUCT_SELF_HOSTED,
  };
};

export { CloudBreadcrumbs as CloudBreadcrumbsComponent };

export default connect(mapStateToProps)(CloudBreadcrumbs);
