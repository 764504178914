import React from 'react';
import Button, { Size as ButtonSize } from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import { Menu, MenuItem } from '@leafygreen-ui/menu';
import { palette } from '@leafygreen-ui/palette';

import { stopPropagation } from 'baas-ui/util';

import 'baas-ui/common/styles/menu.less';

export enum TestSelector {
  ClusterActionsDropdownMenu = 'cluster-actions-dropdown-menu',
  UnlinkButton = 'unlink-button',
  EditConfigItem = 'edit-config-item',
  EditRulesItem = 'edit-rules-item',
}

export interface Props {
  editConfig(): void;
  editRule(): void;
  unlink(): void;
}

function ClusterActionsDropdown({ editConfig, editRule, unlink }: Props) {
  return (
    <Menu
      trigger={
        <Button
          data-testid={TestSelector.ClusterActionsDropdownMenu}
          size={ButtonSize.XSmall}
          onClick={(e) => e.stopPropagation()}
        >
          <Icon glyph="Ellipsis" />
        </Button>
      }
    >
      <MenuItem onClick={stopPropagation(editConfig)} data-testid={TestSelector.EditConfigItem}>
        Edit Data Source Configuration
      </MenuItem>
      <MenuItem onClick={stopPropagation(editRule)} data-testid={TestSelector.EditRulesItem}>
        Edit Rules
      </MenuItem>
      <MenuItem
        onClick={stopPropagation(unlink)}
        data-testid={TestSelector.UnlinkButton}
        style={{ color: palette.red.base }}
      >
        Unlink Data Source
      </MenuItem>
    </Menu>
  );
}

export default ClusterActionsDropdown;
