import { createReducer } from 'redux-act';

import { AsyncDataState, makeDefaultAsyncDataState } from 'baas-ui/redux_util';
import { IncomingWebhook, PartialIncomingWebhook } from 'admin-sdk';

import * as actions from './actions';
import { AuthenticationMethod, DEFAULT_USER_SCRIPT_SOURCE } from './constants';

interface PartialSvcIncomingWebhook extends PartialIncomingWebhook {
  svcId: string;
}
export interface IncomingWebhookState {
  loading: boolean;
  editorError: {
    error?: string;
  };
  editorIncomingWebhookConfig: {
    local: IncomingWebhook | null;
    stored: AsyncDataState<IncomingWebhook>;
    isSaving: boolean;
    saveError?: string;
    isRemoving: boolean;
    removeError?: string;

    // the following states are used for the editing session
    // eslint-disable-next-line max-len
    canEvaluateSource: string; // used because canEvaluate needs to be typed in, and it is not always a Record<string, any>
    userId: string; // persist userId between saves
    userIdScriptSource: string; // persist userIdScriptSource between saves
    authMethod: AuthenticationMethod; // controls authentication radio group
  };
  incomingWebhooks: AsyncDataState<Record<string, PartialSvcIncomingWebhook>>;
}

export const defaultState: IncomingWebhookState = {
  loading: false,
  editorError: {},
  editorIncomingWebhookConfig: {
    local: null,
    stored: makeDefaultAsyncDataState(),
    isSaving: false,
    saveError: undefined,
    isRemoving: false,
    removeError: undefined,

    canEvaluateSource: '',
    userId: '',
    userIdScriptSource: '',
    authMethod: AuthenticationMethod.System,
  },
  incomingWebhooks: makeDefaultAsyncDataState(),
};

const reducer = createReducer<IncomingWebhookState>({}, defaultState);
/* Load Webhooks */
reducer.on(actions.loadIncomingWebhooksActions.req, (state) => ({
  ...state,
  incomingWebhooks: {
    ...state.incomingWebhooks,
    isLoading: true,
    error: undefined,
  },
}));
reducer.on(actions.loadIncomingWebhooksActions.rcv, (state, { payload, reqArgs: { svcId } }) => {
  const newIncomingWebhooks = payload.reduce(
    (acc: Record<string, PartialSvcIncomingWebhook>, webhook: PartialIncomingWebhook) => ({
      ...acc,
      [webhook.id]: { ...webhook, svcId },
    }),
    {}
  );

  return {
    ...state,
    incomingWebhooks: {
      ...state.incomingWebhooks,
      isLoading: false,
      data: newIncomingWebhooks,
    },
  };
});
reducer.on(actions.loadIncomingWebhooksActions.fail, (state, { error }) => ({
  ...state,
  incomingWebhooks: {
    ...state.incomingWebhooks,
    error,
    isLoading: false,
  },
}));

/* Fetch Webhook */
reducer.on(actions.fetchIncomingWebhookActions.req, (state) => ({
  ...state,
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    stored: {
      ...state.editorIncomingWebhookConfig.stored,
      error: undefined,
      isLoading: true,
    },
  },
}));
reducer.on(actions.fetchIncomingWebhookActions.rcv, (state, { payload }) => {
  let authMethod;
  if (payload.runAsAuthedUser) {
    authMethod = AuthenticationMethod.AuthedUser;
  } else if (payload.runAsUserId !== '') {
    authMethod = AuthenticationMethod.UserId;
  } else if (payload.runAsUserIdScriptSource !== '') {
    authMethod = AuthenticationMethod.UserIdScriptSource;
  } else {
    authMethod = AuthenticationMethod.System;
  }
  return {
    ...state,
    editorIncomingWebhookConfig: {
      ...state.editorIncomingWebhookConfig,
      local: payload,
      stored: {
        ...state.editorIncomingWebhookConfig.stored,
        error: undefined,
        data: payload,
        isLoading: false,
      },
      authMethod,
      canEvaluateSource: JSON.stringify(payload.canEvaluate, null, 2),
    },
  };
});
reducer.on(actions.fetchIncomingWebhookActions.fail, (state, { error }) => ({
  ...state,
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    stored: {
      ...state.editorIncomingWebhookConfig.stored,
      error,
      isLoading: false,
    },
  },
}));

/* Create */
reducer.on(actions.createIncomingWebhookActions.req, (state) => ({
  ...state,
  editorError: {},
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    isSaving: true,
    saveError: undefined,
  },
}));
reducer.on(actions.createIncomingWebhookActions.rcv, (state) => ({
  ...state,
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    isSaving: false,
    saveError: undefined,
  },
}));
reducer.on(actions.createIncomingWebhookActions.fail, (state, payload) => ({
  ...state,
  editorError: payload,
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    isSaving: false,
    saveError: payload.error,
  },
}));

/* Update */
reducer.on(actions.updateIncomingWebhookActions.req, (state) => ({
  ...state,
  editorError: {},
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    isSaving: true,
  },
}));
reducer.on(actions.updateIncomingWebhookActions.rcv, (state) => ({
  ...state,
  saving: false,
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    stored: {
      ...state.editorIncomingWebhookConfig.stored,
      data: state.editorIncomingWebhookConfig.local,
    },
    isSaving: false,
  },
}));
reducer.on(actions.updateIncomingWebhookActions.fail, (state, error) => ({
  ...state,
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    isSaving: false,
    saveError: error.error,
  },
  editorError: error,
}));

/* Remove */
reducer.on(actions.removeIncomingWebhookActions.req, (state) => ({
  ...state,
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    isRemoving: true,
    removeError: undefined,
  },
}));
reducer.on(actions.removeIncomingWebhookActions.rcv, (state, { reqArgs }) => {
  if (!state.incomingWebhooks.data) {
    return state;
  }
  const remainingIncomingWebhooks = { ...state.incomingWebhooks };
  delete remainingIncomingWebhooks.data![reqArgs.incomingWebhookId];
  return {
    ...state,
    incomingWebhooks: remainingIncomingWebhooks,
    editorIncomingWebhookConfig: {
      ...state.editorIncomingWebhookConfig,
      isRemoving: false,
    },
  };
});
reducer.on(actions.removeIncomingWebhookActions.fail, (state, { error }) => ({
  ...state,
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    removeError: error,
    isRemoving: false,
  },
}));

/* Editor functions */
reducer.on(actions.editNewWebhook, (state, webhook) => ({
  ...state,
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    local: webhook,
    stored: {
      ...state.editorIncomingWebhookConfig.stored,
      data: webhook,
    },
    authMethod: AuthenticationMethod.System,
  },
}));
reducer.on(actions.finishEditingWebhook, (state) => ({
  ...state,
  editorError: {},
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    local: null,
    stored: makeDefaultAsyncDataState(),
    saveError: undefined,
    removeError: undefined,

    canEvaluateSource: '',
    userId: '',
    userIdScriptSource: '',
    authMethod: AuthenticationMethod.System,
  },
  incomingWebhooks: {
    ...state.incomingWebhooks,
    error: undefined,
  },
}));
reducer.on(
  actions.updateEditor,
  (
    state,
    {
      name,
      functionSource,
      canEvaluate,
      runAsUserId,
      runAsUserIdScriptSource,
      runAsAuthedUser,
      options,
      respondResult,
      disableArgLogs,
      fetchCustomUserData,
      createUserOnAuth,
      authMethod,
      canEvaluateSource,
    }
  ) => {
    let newLocalWebhook: IncomingWebhook = { ...state.editorIncomingWebhookConfig.local! };
    let newLocalWebhookConfig = { ...state.editorIncomingWebhookConfig };

    if (name !== undefined) {
      newLocalWebhook = { ...newLocalWebhook, name };
    }

    if (options !== undefined) {
      newLocalWebhook = { ...newLocalWebhook, options };
    }

    if (functionSource !== undefined) {
      newLocalWebhook = { ...newLocalWebhook, functionSource };
    }

    if (respondResult !== undefined) {
      newLocalWebhook = { ...newLocalWebhook, respondResult };
    }

    // triggered when switching Authentication Methods
    if (authMethod !== undefined) {
      newLocalWebhookConfig = { ...newLocalWebhookConfig, authMethod };
      switch (authMethod) {
        case AuthenticationMethod.System:
          newLocalWebhook = {
            ...newLocalWebhook,
            runAsAuthedUser: false,
            runAsUserId: '',
            runAsUserIdScriptSource: '',
          };
          break;
        case AuthenticationMethod.AuthedUser:
          newLocalWebhook = {
            ...newLocalWebhook,
            runAsAuthedUser: true,
            runAsUserId: '',
            runAsUserIdScriptSource: '',
          };
          break;
        case AuthenticationMethod.UserId:
          newLocalWebhook = {
            ...newLocalWebhook,
            runAsAuthedUser: false,
            runAsUserId: newLocalWebhookConfig.userId || '',
            runAsUserIdScriptSource: '',
          };
          break;
        case AuthenticationMethod.UserIdScriptSource:
          newLocalWebhook = {
            ...newLocalWebhook,
            runAsAuthedUser: false,
            runAsUserId: '',
            runAsUserIdScriptSource: newLocalWebhookConfig.userIdScriptSource || DEFAULT_USER_SCRIPT_SOURCE,
          };
          break;
        default:
          break;
      }
    }

    if (runAsAuthedUser !== undefined) {
      if (!runAsAuthedUser) {
        newLocalWebhook = { ...newLocalWebhook, fetchCustomUserData: false, createUserOnAuth: false };
      }
      newLocalWebhook = { ...newLocalWebhook, runAsAuthedUser };
    }

    if (runAsUserId !== undefined) {
      newLocalWebhook = { ...newLocalWebhook, runAsUserId };
      newLocalWebhookConfig = { ...newLocalWebhookConfig, userId: runAsUserId };
    }
    if (runAsUserIdScriptSource !== undefined) {
      newLocalWebhook = { ...newLocalWebhook, runAsUserIdScriptSource };
      newLocalWebhookConfig = { ...newLocalWebhookConfig, userIdScriptSource: runAsUserIdScriptSource };
    }

    if (canEvaluateSource !== undefined) {
      try {
        const validJSON = JSON.parse(canEvaluateSource);
        newLocalWebhook = { ...newLocalWebhook, canEvaluate: validJSON };
      } catch {
        // json is invalid for now
      } finally {
        newLocalWebhookConfig = { ...newLocalWebhookConfig, canEvaluateSource };
      }
    }

    if (canEvaluate !== undefined) {
      newLocalWebhook = { ...newLocalWebhook, canEvaluate };
    }

    if (disableArgLogs !== undefined) {
      newLocalWebhook = { ...newLocalWebhook, disableArgLogs };
    }

    if (fetchCustomUserData !== undefined) {
      newLocalWebhook = { ...newLocalWebhook, fetchCustomUserData };
    }

    if (createUserOnAuth !== undefined) {
      newLocalWebhook = { ...newLocalWebhook, createUserOnAuth };
    }

    return {
      ...state,
      editorIncomingWebhookConfig: {
        ...state.editorIncomingWebhookConfig,
        ...newLocalWebhookConfig,
        local: new IncomingWebhook(newLocalWebhook),
      },
    };
  }
);
reducer.on(actions.discardChanges, (state) => ({
  ...state,
  editorError: {},
  editorIncomingWebhookConfig: {
    ...state.editorIncomingWebhookConfig,
    local: state.editorIncomingWebhookConfig.stored.data,
  },
}));
reducer.on(actions.setError, (state, error) => ({
  ...state,
  editorError: error,
}));

export default reducer;
