export enum EventType {
  Application = 'Application',
  ClusterLink = 'Cluster Link',
  CustomUserData = 'Custom User Data',
  DataLakeLink = 'Atlas Data Federation Link',
  DataSource = 'Data Source',
  Dashboard = 'Dashboard',
  DeployConfiguration = 'Deploy Configuration',
  DraftDeploy = 'Draft Deploy',
  DocsLink = 'Docs Link',
  Edge = 'Edge',
  ExperimentViewed = 'Experiment Viewed',
  Functions = 'Functions',
  GraphQL = 'GraphQL',
  Guides = 'Guides',
  Onboarding = 'Onboarding',
  RulesConfiguration = 'Rules Configuration',
  Schemas = 'Schemas',
  SDKS = 'SDKS',
  SideNav = 'Side Nav',
  Sync = 'Sync',
  SyncConfigure = 'Sync Configure',
  TriggersCreate = 'Triggers Create',
  TriggersEdit = 'Triggers Edit',
  TriggerSnippets = 'Triggers Expression Snippet',
  FunctionSnippets = 'Function Snippet',
  UsersManage = 'Users Manage',
  ValuesSecrets = 'Values & Secrets Create',
  AdminError = 'Admin Error',
  TemplateStarterApp = 'Template Starter App',
  AccessList = 'IP Access List',
  LogForwarder = 'Log Forwarder',
  Endpoint = 'HTTPS Endpoints',
  UserSettings = 'User Settings',
  ProviderPrivateEndpoints = 'Provider Private Endpoints',
  MetricsCard = 'Metrics Card',
  DataApi = 'Data API',
}

const EVENTS = {
  'APPLICATION.LIST_EMPTY_STATE_VIEWED': {
    eventType: EventType.Application,
    action: 'Application: List Empty State Viewed',
  },
  'APPLICATION.CREATE_NEW_CLICKED': {
    eventType: EventType.Application,
    action: 'Application: Create New Clicked',
  },
  'APPLICATION.CREATE_NEW_SUBMITTED': {
    eventType: EventType.Application,
    action: 'Application: Create New Submitted',
  },
  'APPLICATION.LIST_VIEWED': {
    eventType: EventType.Application,
    action: 'Application: List Viewed',
  },
  'APPLICATION.APPLICATION_CLICKED': {
    eventType: EventType.Application,
    action: 'Application: Application Clicked',
  },
  'APPLICATION.EXPLORE_APP_SERVICES_CLICKED': {
    eventType: EventType.Application,
    action: `${EventType.Application}: Explore App Services Button Clicked`,
  },
  'APPLICATION.OLD_DOMAIN_USED': {
    eventType: EventType.Application,
    action: `${EventType.Application}: Navigated to old domain`,
  },
  'APPLICATION.DOMAIN_REDIRECT_REJECTED': {
    eventType: EventType.Application,
    action: `${EventType.Application}: Rejected redirection to cloud services domain`,
  },
  'APPLICATION.DOMAIN_REDIRECT_ISSUED': {
    eventType: EventType.Application,
    action: `${EventType.Application}: Redirected to cloud services domain`,
  },
  'APPLICATION.TEMPLATE_STARTER_MENU_CLICKED': {
    eventType: EventType.Application,
    action: `${EventType.Application}: Template Starter Apps Menu Clicked`,
  },
  'APPLICATION.TEMPLATE_STARTER_APP_CLICKED': {
    eventType: EventType.Application,
    action: `${EventType.Application}: Template Starter App Clicked`,
  },
  'APPLICATION.TEMPLATE_STARTER_NO_TEMPLATE_CLICKED': {
    eventType: EventType.Application,
    action: `${EventType.Application}: Template Starter No Template Clicked`,
  },
  'APPLICATION.TEMPLATE_MODAL_NEXT_CLICKED': {
    eventType: EventType.Application,
    action: `${EventType.Application}: Template Modal Next Clicked`,
  },
  'ONBOARDING.QUICK_START_DIMISSED': {
    eventType: EventType.Onboarding,
    action: 'Onboarding: Quick Start Dismissed',
  },
  'ONBOARDING.QUICK_START_STEP_CLICKED': {
    eventType: EventType.Onboarding,
    actionFn: ({ step }) => `Onboarding: Quick Start Step ${step} Clicked`,
  },

  'CUSTOM_USER_DATA.USER_CREATION_FUNCTION_ENABLED': {
    eventType: EventType.CustomUserData,
    action: `${EventType.CustomUserData}: User Creation Function Enabled`,
  },

  'CUSTOM_USER_DATA.USER_CREATION_FUNCTION_DISABLED': {
    eventType: EventType.CustomUserData,
    action: `${EventType.CustomUserData}: User Creation Function Disabled`,
  },

  'DATA_SOURCE.DISABLE_COLLECTION_PREIMAGE_CLICKED': {
    eventType: EventType.DataSource,
    action: `${EventType.DataSource}: Disable Collection Preimage Clicked`,
  },
  'DATA_SOURCE.DISABLE_COLLECTION_PREIMAGE_CONFIRMED': {
    eventType: EventType.DataSource,
    action: `${EventType.DataSource}: Disable Collection Preimage Confirmed`,
  },

  'DASHBOARD.VIEWED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: Viewed`,
  },
  'DASHBOARD.ALL_LOGS_LINK_CLICKED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: All Logs Link Clicked`,
  },
  'DASHBOARD.LOGS_ENTRY_NAME_CLICKED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: Log Entry Name Clicked`,
  },
  'DASHBOARD.RECENTLY_OPENED_LINK_CLICKED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: Recently Opened Link Clicked`,
  },
  'DASHBOARD.APP_ID_COPIED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: App ID Copied`,
  },
  'DASHBOARD.TEMPLATE_STARTER_CLI_MODAL_BUTTON_CLICKED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: Template App Get Frontend via CLI Modal Clicked`,
  },
  'DASHBOARD.TEMPLATE_STARTER_GITHUB_MODAL_BUTTON_CLICKED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: Template App Get Frontend via Github Modal Clicked`,
  },
  'DASHBOARD.TEMPLATE_STARTER_CLI_COMMAND_COPIED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: Template App Get Frontend via CLI Command Copied`,
  },
  'DASHBOARD.TEMPLATE_STARTER_CLI_FRONTEND_OPTION_SELECTED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: Template App Get Frontend via CLI Frontend Option Selected`,
  },
  'DASHBOARD.TEMPLATE_STARTER_GITHUB_LINK_BUTTON_CLICKED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: Template App Get Frontend via Github Clicked`,
  },
  'DASHBOARD.TEMPLATE_APP_DOWNLOAD_CODE_CLICKED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: Template App Download Code Clicked`,
  },
  'DASHBOARD.TEMPLATE_APP_VIEW_ON_GITHUB_CLICKED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: Template App View on Github Clicked`,
  },
  'DASHBOARD.TEMPLATE_APP_TAB_CLICKED': {
    eventType: EventType.Dashboard,
    action: `${EventType.Dashboard}: Template App Tab Clicked`,
  },
  'DOCSLINK.LINK_CLICKED': {
    eventType: EventType.DocsLink,
    action: `Docs Link: Link Clicked`,
  },

  'EDGE.ENABLE_SYNC_FROM_EMPTY_STATE': {
    eventType: EventType.Edge,
    action: `${EventType.Edge}: Sync Enabled From Empty State`,
  },
  'EDGE.ADD_SERVER_FROM_EMPTY_STATE': {
    eventType: EventType.Edge,
    action: `${EventType.Edge}: Server Added From Empty State`,
  },
  'EDGE.ADD_SERVER_FROM_EDGE_LIST': {
    eventType: EventType.Edge,
    action: `${EventType.Edge}: Server Added From Edge List Page`,
  },
  'EDGE.EMPTY_STATE_VIEWED': {
    eventType: EventType.Edge,
    action: `${EventType.Edge}: Empty State Viewed`,
  },
  'EDGE.LINK_DATA_SOURCE_VIEWED': {
    eventType: EventType.Edge,
    action: `${EventType.Edge}: Link Data Source Page Viewed`,
  },
  'EDGE.REGISTRATION_TOKEN_COPIED': {
    eventType: EventType.Edge,
    action: `${EventType.Edge}: Registration Token Copied`,
  },
  'EDGE.INSTALL_SCRIPT_COPIED': {
    eventType: EventType.Edge,
    action: `${EventType.Edge}: Install Script Copied`,
  },

  'SIDE_NAV.APP_ID_COPIED': {
    eventType: EventType.SideNav,
    action: `${EventType.SideNav}: App ID Copied`,
  },
  'SIDE_NAV.ITEM_CLICKED': {
    eventType: EventType.SideNav,
    actionFn: ({ text }) => `Side Nav: ${text} Clicked`,
  },

  'TRIGGERS_CREATE.LIST_VIEWED': {
    eventType: EventType.TriggersCreate,
    action: 'Triggers: List Viewed',
  },
  'TRIGGERS_CREATE.NEW_TRIGGER_VIEWED': {
    eventType: EventType.TriggersCreate,
    action: 'Triggers: New Trigger Viewed',
  },
  'TRIGGERS_CREATE.PREIMAGE_STATE_CHANGED': {
    eventType: EventType.TriggersCreate,
    action: 'Triggers: Preimage State Changed',
  },
  'TRIGGERS_CREATE.EDIT_TRIGGER_VIEWED': {
    eventType: EventType.TriggersCreate,
    action: 'Triggers: Edit Trigger Viewed',
  },
  'TRIGGERS_CREATE.SAVE_SUBMITTED': {
    eventType: EventType.TriggersCreate,
    action: 'Triggers: Save Submitted',
  },
  'TRIGGERS_EDIT.CANCEL_CLICKED': {
    eventType: EventType.TriggersEdit,
    action: 'Triggers Edit Form Cancel Button Clicked',
    context: 'Edit Trigger Page',
  },
  'TRIGGERS_EDIT.SAVED': {
    eventType: EventType.TriggersEdit,
    action: 'Trigger Saved',
    context: 'Edit Trigger Page',
  },

  'TRIGGERS_SNIPPET.INSERT_CLICKED': {
    eventType: EventType.TriggerSnippets,
    action: 'Triggers Expression Snippet Insert Icon Clicked',
  },
  'TRIGGERS_SNIPPET.COPY_CLICKED': {
    eventType: EventType.TriggerSnippets,
    action: 'Triggers Expression Snippet Copy Icon Clicked',
  },

  'USERS_MANAGE.LIST_VIEWED': {
    eventType: EventType.UsersManage,
    action: 'Users: Users List Viewed',
  },
  'USERS_MANAGE.ADD_USER_CLICKED': {
    eventType: EventType.UsersManage,
    action: 'Users: Add User Clicked',
  },
  'USERS_MANAGE.ADD_USER_SUBMITTED': {
    eventType: EventType.UsersManage,
    action: 'Users: Add User Submitted',
  },
  'USERS_MANAGE.SAVE_PROVIDER_SUBMITTED': {
    eventType: EventType.UsersManage,
    action: 'Authentication: Save Provider Submitted',
  },

  'DEPLOY_CONFIGURATION.HISTORY_VIEWED': {
    eventType: EventType.DeployConfiguration,
    action: 'Deploy: History Viewed',
  },
  'DEPLOY_CONFIGURATION.CONFIGURATION_VIEWED': {
    eventType: EventType.DeployConfiguration,
    action: 'Deploy: Configuration Viewed',
  },
  'DEPLOY_CONFIGURATION.GITHUB_INSTALL_CLICKED': {
    eventType: EventType.DeployConfiguration,
    action: 'Deploy: GitHub Installed Clicked',
  },
  'DEPLOY_CONFIGURATION.GITHUB_APP_AUTHORIZED_CLICKED': {
    eventType: EventType.DeployConfiguration,
    action: 'Deploy: GitHub App Authorized Clicked',
  },
  'DEPLOY_CONFIGURATION.GITHUB_REPOSITORY_ENTERED': {
    eventType: EventType.DeployConfiguration,
    action: 'Deploy: GitHub Repository Entered',
  },
  'DEPLOY_CONFIGURATION.DEPLOYMENT_EXPORTED': {
    eventType: EventType.DeployConfiguration,
    action: 'Deploy: Deployment exported',
  },
  'DEPLOY_CONFIGURATION.DEPLOYMENT_REDEPLOYED': {
    eventType: EventType.DeployConfiguration,
    action: 'Deploy: Deployment re-deployed',
  },

  'DRAFT_DEPLOY.REVIEW_VIEWED': {
    eventType: EventType.DraftDeploy,
    action: 'Draft: Review Viewed',
  },
  'DRAFT_DEPLOY.REVIEW_FINISHED': {
    eventType: EventType.DraftDeploy,
    action: 'Draft: Review Finished',
  },

  'CLUSTER_LINK.LIST_VIEWED': {
    eventType: EventType.ClusterLink,
    action: 'Clusters: List Viewed',
  },
  'CLUSTER_LINK.LINK_CLUSTER_CLICKED': {
    eventType: EventType.ClusterLink,
    action: 'Clusters: Link Cluster Clicked',
  },
  'CLUSTER_LINK.LINK_CLUSTER_SUBMITTED': {
    eventType: EventType.ClusterLink,
    action: 'Clusters: Link Cluster Submitted',
  },

  'FUNCTIONS.LIST_VIEWED': {
    eventType: EventType.Functions,
    action: 'Functions: List Viewed',
  },
  'FUNCTIONS.NEW_FUNCTION_VIEWED': {
    eventType: EventType.Functions,
    action: 'Functions: New Function Viewed',
  },
  'FUNCTIONS.EDIT_FUNCTION_VIEWED': {
    eventType: EventType.Functions,
    action: 'Functions: Edit Function Viewed',
  },
  'FUNCTIONS.SAVE_SUBMITTED': {
    eventType: EventType.Functions,
    action: 'Functions: Save Submitted',
  },
  'FUNCTIONS.SAVE_FAILED': {
    eventType: EventType.Functions,
    action: 'Functions: Save Failed',
  },
  'FUNCTIONS.RUN_CLICKED': {
    eventType: EventType.Functions,
    action: 'Functions: Run Clicked',
  },
  'FUNCTIONS.RUN_FAILED': {
    eventType: EventType.Functions,
    action: 'Functions: Run Failed',
  },
  'FUNCTIONS.CHANGE_USER_CLICKED': {
    eventType: EventType.Functions,
    action: 'Functions: Change User Clicked',
  },

  'FUNCTION_SNIPPET.BUTTON_CLICKED': {
    eventType: EventType.FunctionSnippets,
    action: 'Show Function Snippets button Clicked',
  },

  'FUNCTION_SNIPPET_LIST.DETAILS_SELECTED': {
    eventType: EventType.FunctionSnippets,
    action: 'Function Snippet Details selected',
  },

  'FUNCTION_SNIPPET_DETAILS.INSERT_CLICKED': {
    eventType: EventType.FunctionSnippets,
    action: 'Function Snippet Details Insert Clicked',
  },
  'FUNCTION_SNIPPET_DETAILS.COPY_CLICKED': {
    eventType: EventType.FunctionSnippets,
    action: 'Function Snippet Details Copy Clicked',
  },
  'FUNCTION_SNIPPET_DETAILS.VIEW_GITHUB_URL': {
    eventType: EventType.FunctionSnippets,
    action: 'View Github URL for the Function Snippet',
  },

  'FUNCTIONS_SNIPPET_LIST.INSERT_CLICKED': {
    eventType: EventType.FunctionSnippets,
    action: 'Function Snippet List Insert Icon Clicked',
  },
  'FUNCTIONS_SNIPPET_LIST.COPY_CLICKED': {
    eventType: EventType.FunctionSnippets,
    action: 'Function Snippet List Copy Icon Clicked',
  },

  'RULES_CONFIGURATION.RULES_VIEWED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Rules Viewed',
  },
  'RULES_CONFIGURATION.ADD_COLLECTION_VIEWED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Add Collection Viewed',
  },
  'RULES_CONFIGURATION.CONFIGURE_COLLECTION_VIEWED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Configure Collection Viewed',
  },
  'RULES_CONFIGURATION.UNCONFIGURED_COLLECTIONS_TOGGLED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Unconfigured Collections Toggled',
  },
  'RULES_CONFIGURATION.COLLECTION_SET_UP_SUBMITTED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Collection Set Up Submitted',
  },
  'RULES_CONFIGURATION.DEFAULT_RULE_CREATED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Default Rule Created ',
  },
  'RULES_CONFIGURATION.COLLECTION_CHANGES_SAVED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Collection Changes Saved',
  },
  'RULES_CONFIGURATION.DEFAULT_RULE_UPDATED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Default Rule Updated',
  },
  'RULES_CONFIGURATION.COLLECTION_CHANGES_DISCARDED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Collection Changes Discarded',
  },
  'RULES_CONFIGURATION.DEFAULT_RULE_CHANGES_DISCARDED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Default Rule Changes Discarded',
  },
  'RULES_CONFIGURATION.GENERATE_SCHEMA_SUBMITTED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Generate Schema Submitted',
  },
  'RULES_CONFIGURATION.RUN_VALIDATION_SUBMITTED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Run Validation Submitted',
  },
  'RULES_CONFIGURATION.ADD_RELATIONSHIP_SUBMITTED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Add Relationship Submitted',
  },
  'RULES_CONFIGURATION.SCHEMA_CHANGE_COMPLETE': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Schema Change Complete',
  },
  'RULES_CONFIGURATION.DELETE_DEFAULT_RULE_VIEWED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Delete Default Rule Viewed',
  },
  'RULES_CONFIGURATION.DELETE_DEFAULT_RULE_SUBMITTED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Delete Default Rule Submitted',
  },
  'RULES_CONFIGURATION.DELETE_RULES_VIEWED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Delete Rules Viewed',
  },
  'RULES_CONFIGURATION.DELETE_RULES_SUBMITTED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Delete Rules Submitted',
  },
  'RULES_CONFIGURATION.RULE_ADVANCED_MODE_CONFIRMED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Advanced Mode Confirmed',
  },
  'RULES_CONFIGURATION.PRESET_ROLE_ADDED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Preset Role Added',
  },
  'RULES_CONFIGURATION.PRESET_ROLE_SKIPPED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Skip Adding Preset Role To Start From Scratch',
  },
  'RULES_CONFIGURATION.ROLES_ADVANCED_MODE_VIEW_TOGGLED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Advanced Mode View Toggled',
  },
  'RULES_CONFIGURATION.ROLES_CARD_VIEW_TOGGLED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Card View Toggled',
  },
  'RULES_CONFIGURATION.ROLE_REMOVED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Role Removed',
  },
  'RULES_CONFIGURATION.FILTER_REMOVED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Filter Removed',
  },
  'RULES_CONFIGURATION.ROLES_VIEWED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Roles Viewed',
  },
  'RULES_CONFIGURATION.FILTERS_VIEWED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Filters Viewed',
  },
  'RULES_CONFIGURATION.ROLE_REORDERED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Role Reordered',
  },
  'RULES_CONFIGURATION.APPLY_WHEN_MODAL_VIEWED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Role Apply When Modal Viewed',
  },
  'RULES_CONFIGURATION.CREATE_ROLE_FROM_SCRATCH_CLICKED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Create Role From Scratch Clicked',
  },
  'RULES_CONFIGURATION.ADD_FILTER_CLICKED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Create Filter Clicked',
  },
  'RULES_CONFIGURATION.EDIT_ROLE_CLICKED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Edit Role Clicked',
  },
  'RULES_CONFIGURATION.EDIT_FILTER_CLICKED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Edit Filter Clicked',
  },
  'RULES_CONFIGURATION.UNDERSTANDING_ROLES_AND_FILTERS_MODAL_VIEWED': {
    eventType: EventType.RulesConfiguration,
    action: 'Rules: Understanding Roles and Filters Modal Viewed',
  },

  'VALUES_SECRETS_CREATE.NEW_VALUE_VIEWED': {
    eventType: EventType.ValuesSecrets,
    action: 'New Value Viewed',
  },
  'VALUES_SECRETS_CREATE.EDIT_VALUE_VIEWED': {
    eventType: EventType.ValuesSecrets,
    action: 'Edit Value Viewed',
  },
  'VALUES_SECRETS_CREATE.SAVE_VALUE_SUBMITTED': {
    eventType: EventType.ValuesSecrets,
    action: 'Save Value Submitted',
  },
  'VALUES_SECRETS_CREATE.NEW_SECRET_VIEWED': {
    eventType: EventType.ValuesSecrets,
    action: 'New Secret Viewed',
  },
  'VALUES_SECRETS_CREATE.EDIT_SECRET_VIEWED': {
    eventType: EventType.ValuesSecrets,
    action: 'Edit Secret Viewed',
  },
  'VALUES_SECRETS_CREATE.SAVE_SECRET_SUBMITTED': {
    eventType: EventType.ValuesSecrets,
    action: 'Save Secret Submitted',
  },

  'GRAPHQL.EXPLORE_TAB_VIEWED': {
    eventType: EventType.GraphQL,
    action: 'Explore Tab Viewed',
  },
  'GRAPHQL.SCHEMA_TAB_VIEWED': {
    eventType: EventType.GraphQL,
    action: 'Schema Tab Viewed',
  },
  'GRAPHQL.DOWNLOAD_SCHEMA_CLICKED': {
    eventType: EventType.GraphQL,
    action: 'Download Schema Clicked',
  },
  'GRAPHQL.GRAPHIQL_QUERY_RUN': {
    eventType: EventType.GraphQL,
    action: 'GraphiQL Query Run',
  },
  'GRAPHQL.CUSTOM_RESOLVERS_LIST_VIEWED': {
    eventType: EventType.GraphQL,
    action: 'Custom Resolvers List Viewed',
  },
  'GRAPHQL.EDIT_CUSTOM_RESOLVER_VIEWED': {
    eventType: EventType.GraphQL,
    action: 'Edit Custom Resolver Viewed',
  },
  'GRAPHQL.CREATE_CUSTOM_RESOLVER_VIEWED': {
    eventType: EventType.GraphQL,
    action: 'Create Custom Resolver Viewed',
  },
  'GRAPHQL.CUSTOM_RESOLVER_UPDATE': {
    eventType: EventType.GraphQL,
    action: 'Save Custom Resolver Changes',
  },
  'GRAPHQL.CUSTOM_RESOLVER_UPDATE_FAILED': {
    eventType: EventType.GraphQL,
    action: 'Save Custom Resolver Changes Failed',
  },
  'GRAPHQL.CUSTOM_RESOLVER_CREATE': {
    eventType: EventType.GraphQL,
    action: 'Create Custom Resolver',
  },
  'GRAPHQL.CUSTOM_RESOLVER_CREATE_FAILED': {
    eventType: EventType.GraphQL,
    action: 'Create Custom Resolver Failed',
  },
  'GRAPHQL.CUSTOM_RESOLVER_DELETE': {
    eventType: EventType.GraphQL,
    action: 'Delete Custom Resolver',
  },
  'GRAPHQL.CUSTOM_RESOLVER_DELETE_FAILED': {
    eventType: EventType.GraphQL,
    action: 'Delete Custom Resolver Failed',
  },
  'GRAPHQL.CUSTOM_RESOLVER_CREATE_FUNCTION': {
    eventType: EventType.GraphQL,
    action: 'Create Function With Custom Resolver',
  },
  'GRAPHQL.SETTINGS_VIEWED': {
    eventType: EventType.GraphQL,
    action: 'GraphQL Settings Viewed',
  },
  'GRAPHQL.SETTINGS_SAVED': {
    eventType: EventType.GraphQL,
    action: 'GraphQL Settings Saved',
  },
  'GRAPHQL.LINK_COPIED': {
    eventType: EventType.GraphQL,
    action: 'GraphQL Link Copied',
  },

  'SCHEMAS.CREATE_SCHEMA_VIEWED': {
    eventType: EventType.Schemas,
    action: 'Schemas: Create Schema Viewed',
  },

  'SCHEMAS.CREATE_SCHEMA_SUBMITTED': {
    eventType: EventType.Schemas,
    action: 'Schemas: Create Schema Submitted',
  },

  'SCHEMAS.DEFINE_SCHEMA_CLICKED': {
    eventType: EventType.Schemas,
    action: 'Schemas: Define Schema Clicked',
  },

  'SCHEMAS.DELETE_SCHEMA_VIEWED': {
    eventType: EventType.Schemas,
    action: 'Schemas: Delete Schema Viewed',
  },

  'SCHEMAS.DELETE_SCHEMA_SUBMITTED': {
    eventType: EventType.Schemas,
    action: 'Schemas: Delete Schema Submitted',
  },

  'SCHEMAS.DEVELOPER_MODE_CLICKED': {
    eventType: EventType.Schemas,
    action: 'Schemas: Developer Mode Clicked',
  },

  'SCHEMAS.EMPTY_STATE_VIEWED': {
    eventType: EventType.Schemas,
    action: 'Schemas: Empty State Viewed',
  },

  'SCHEMAS.GENERATE_SCHEMA_CLICKED': {
    eventType: EventType.Schemas,
    action: 'Schemas: Generate Schema Clicked',
  },

  'SCHEMAS.RUN_VALIDATION_SUBMITTED': {
    eventType: EventType.Schemas,
    action: 'Schemas: Run Validation Submitted',
  },

  'SCHEMAS.GENERATE_SCHEMA_SUBMITTED': {
    eventType: EventType.Schemas,
    action: 'Schemas: Generate Schema Submitted',
  },

  'SCHEMAS.CONFIGURE_COLLECTION_VIEWED': {
    eventType: EventType.Schemas,
    action: 'Schemas: Configure Collection Viewed',
  },

  'SCHEMAS.GRAPHQL_TAB_VIEWED': {
    eventType: EventType.Schemas,
    action: `${EventType.Schemas}: GraphQL Tab Viewed`,
  },

  'SCHEMAS.SYNC_TAB_VIEWED': {
    eventType: EventType.Schemas,
    action: `${EventType.Schemas}: Sync Tab Viewed`,
  },

  'SCHEMAS.GRAPHQL_SCHEMA_COPIED': {
    eventType: EventType.Schemas,
    action: `${EventType.Schemas}: GraphQL Schema Copied`,
  },

  'SCHEMAS.SYNC_MODEL_COPIED': {
    eventType: EventType.Schemas,
    action: `${EventType.Schemas}: Sync Model Copied`,
  },

  'SCHEMAS.GRAPHQL_VALIDATION_ERROR_BANNER_CLICKED': {
    eventType: EventType.Schemas,
    action: `${EventType.Schemas}: GraphQL Validation Error Banner Clicked`,
  },

  'SCHEMAS.SYNC_MODELS_VALIDATION_ERROR_BANNER_CLICKED': {
    eventType: EventType.Schemas,
    action: `${EventType.Schemas}: Sync Models Validation Error Banner Clicked`,
  },

  'SCHEMAS.SCHEMA_TABLE_ADD_FIELD_ICON_CLICKED': {
    eventType: EventType.Schemas,
    action: `${EventType.Schemas}: Schema Table Add Field Icon Clicked`,
  },

  'SCHEMAS.SCHEMA_TABLE_ADD_RELATIONSHIP_CLICKED': {
    eventType: EventType.Schemas,
    action: `${EventType.Schemas}: Schema Table Add Relationship Clicked`,
  },

  'SCHEMAS.SCHEMA_TABLE_VIEW_INCOMPATIBLE': {
    eventType: EventType.Schemas,
    action: `${EventType.Schemas}: Schema Table View Incompatible On Draft Save`,
  },

  'SDKS.DATA_MODELS_VIEWED': {
    eventType: EventType.SDKS,
    action: 'SDKS: Data Models Viewed',
  },
  'SDKS.ALL_DATA_MODELS_COPIED': {
    eventType: EventType.SDKS,
    action: 'SDKS: All Data Models Copied',
  },
  'SDKS.DATA_MODEL_COPIED': {
    eventType: EventType.SDKS,
    action: 'SDKS: Data Model Copied',
  },

  'SYNC.DASHBOARD_TAB_VIEWED': {
    eventType: EventType.Sync,
    action: 'Sync: Dashboard tab viewed',
  },
  'SYNC.METRICS_TAB_VIEWED': {
    eventType: EventType.Sync,
    action: 'Sync: Metrics tab viewed',
  },
  'SYNC.LOGS_TAB_VIEWED': {
    eventType: EventType.Sync,
    action: 'Sync: Logs tab viewed',
  },
  'SYNC.SYNC_MODELS_TAB_VIEWED': {
    eventType: EventType.Sync,
    action: 'Sync: Sync models tab viewed',
  },
  'SYNC.CONFIGURATION_TAB_VIEWED': {
    eventType: EventType.Sync,
    action: 'Sync: Configuration tab viewed',
  },
  'SYNC.GENERATE_SCHEMAS_MODAL_OPENED': {
    eventType: EventType.Sync,
    action: 'Sync: Generate schemas modal opened',
  },
  'SYNC.GENERATE_SCHEMAS_CLICKED': {
    eventType: EventType.Sync,
    action: 'Sync: Generate schemas clicked',
  },
  'SYNC.DOWNLOAD_SDK_CLICKED': {
    eventType: EventType.Sync,
    action: 'Sync: Download SDK clicked',
  },
  'SYNC.CONNECT_DATA_CLICKED': {
    eventType: EventType.Sync,
    action: 'Sync: Connect data clicked',
  },
  'SYNC.DEFINE_SCHEMAS_MANUALLY_CLICKED': {
    eventType: EventType.Sync,
    action: 'Sync: Define schemas manually clicked',
  },
  'SYNC.COPY_ALL_MODELS_MODAL_OPENED': {
    eventType: EventType.Sync,
    action: 'Sync: Copy all schemas modal opened',
  },
  'SYNC.SDK_PLATFORM_UPDATED': {
    eventType: EventType.Sync,
    action: 'Sync: SDK platform updated',
  },
  'SYNC.SDK_LANGUAGE_UPDATED': {
    eventType: EventType.Sync,
    action: 'Sync: SDK language updated',
  },
  'SYNC.ALERT_LINK_CLICKED': {
    eventType: EventType.Sync,
    action: 'Sync: Alert link clicked',
  },
  'SYNC.DASHBOARD_EXAMPLE_APP_CLICKED': {
    eventType: EventType.Sync,
    action: 'Sync: Dashboard example app badge clicked',
  },
  'SYNC.DASHBOARD_METRIC_TITLE_CLICKED': {
    eventType: EventType.Sync,
    action: 'Sync: Metric title clicked',
  },

  'SYNC_CONFIGURE.SYNC_ENABLE_SUBMITTED': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Sync Enable Submitted',
  },
  'SYNC_CONFIGURE.SYNC_PAUSE_SUBMITTED': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Sync Pause Submitted',
  },
  'SYNC_CONFIGURE.SYNC_TERMINATE_SUBMITTED': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Sync Terminate Submitted',
  },
  'SYNC_CONFIGURE.DEVELOPMENT_MODE_TOGGLED': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Development Mode Toggled',
  },
  'SYNC_CONFIGURE.SYNC_REINITIALIZED': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Sync Reinitialized',
  },
  'SYNC_CONFIGURE.SYNC_PERMISSION_UPDATED': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Sync Permission Updated',
  },
  'SYNC_CONFIGURE.SYNC_MAX_OFFLINE_TIME_UPDATED': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Sync Max Offline Time Updated',
  },
  'SYNC_CONFIGURE.SELECT_FLEXIBLE_SYNC': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select Flexible Sync',
  },
  'SYNC_CONFIGURE.UPDATE_QUERYABLE_FIELDS': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Update Queryable Fields',
  },
  'SYNC_CONFIGURE.SELECT_PARTITION_BASED_SYNC': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select Partition Based Sync',
  },
  'SYNC_CONFIGURE.UPDATE_PARTITION_KEY': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Update Partition Key',
  },
  'SYNC_CONFIGURE.SELECT_CLUSTER_TO_SYNC': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select Cluster To Sync',
  },
  'SYNC_CONFIGURE.SELECT_EXISTING_DATABASE_TO_SYNC': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select Existing Database To Sync',
  },
  'SYNC_CONFIGURE.SELECT_NEW_DATABASE_TO_SYNC': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select New Database To Sync',
  },
  'SYNC_CONFIGURE.SELECT_EXISTING_QUERYABLE_FIELD': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select Existing Queryable Field',
  },
  'SYNC_CONFIGURE.SELECT_NEW_QUERYABLE_FIELD': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select New Queryable Field',
  },
  'SYNC_CONFIGURE.SELECT_ASYMMETRIC_SYNC_EXISTING_COLLECTION': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select Asymmetric Sync On Existing Collection',
  },
  'SYNC_CONFIGURE.SELECT_ASYMMETRIC_SYNC_NEW_COLLECTION': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select Asymmetric Sync On New Collection',
  },
  'SYNC_CONFIGURE.UPDATE_ASYMMETRIC_SYNC': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Update Asymmetric Sync',
  },
  'SYNC_CONFIGURE.SELECT_CLIENT_RECOVERY_ENABLED': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select Client Recovery Enabled',
  },
  'SYNC_CONFIGURE.SELECT_CLIENT_RECOVERY_DISABLED': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select Client Recovery Disabled',
  },
  'SYNC_CONFIGURE.UPDATE_CLIENT_RECOVERY': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Update Client Recovery',
  },
  'SYNC_CONFIGURE.SELECT_PBS_PERMISSIONS_NO_TEMPLATE': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select PBS Permissions No Template',
  },
  'SYNC_CONFIGURE.SELECT_PBS_PERMISSIONS_READ_WRITE_OWN_DATA': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select PBS Permissions Read Write Own Data Template',
  },
  'SYNC_CONFIGURE.SELECT_PBS_PERMISSIONS_READ_ALL_WRITE_OWN_DATA': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select PBS Permissions Read All Write Own Data Template',
  },
  'SYNC_CONFIGURE.SELECT_PBS_PERMISSIONS_READ_ALL_DATA_WRITE_NONE': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Select PBS Permissions Read All Data Write None Template',
  },
  'SYNC_CONFIGURE.UPDATE_PBS_PERMISSIONS': {
    eventType: EventType.SyncConfigure,
    action: 'Sync: Update PBS Permissions',
  },

  'GUIDES.WELCOME_MODAL_OPENED': {
    eventType: EventType.Guides,
    action: 'Guides: Welcome Modal Opened',
  },
  'GUIDES.WELCOME_MODAL_CLOSED': {
    eventType: EventType.Guides,
    action: 'Guides: Welcome Modal Closed',
  },
  'GUIDES.RECOMMEND_MODAL_CLOSED': {
    eventType: EventType.Guides,
    action: 'Guides: Recommend Modal Closed',
  },
  'GUIDES.RECOMMEND_MODAL_SEE_ALL_GUIDES_CLICKED': {
    eventType: EventType.Guides,
    action: 'Guides: Recommend Modal See All Guides Clicked',
  },
  'GUIDES.STARTED_GUIDE': {
    eventType: EventType.Guides,
    action: 'Guides: Started Guide',
  },
  'GUIDES.VIEWED_STEP': {
    eventType: EventType.Guides,
    action: 'Guides: Viewed Step',
  },
  'GUIDES.CLICKED_NEXT_STEP': {
    eventType: EventType.Guides,
    action: 'Guides: Clicked Next Step',
  },
  'GUIDES.CLICKED_PREVIOUS_STEP': {
    eventType: EventType.Guides,
    action: 'Guides: Clicked Previous Step',
  },
  'GUIDES.CLOSED_GUIDE': {
    eventType: EventType.Guides,
    action: 'Guides: Closed Guide',
  },

  'DATA_LAKE_LINK.LINK_DATA_LAKE_SUBMITTED': {
    eventType: EventType.DataLakeLink,
    action: 'Atlas Data Federation Link: Federated Database Instance Submitted',
  },

  'ADMIN_ERROR.ERROR_ENCOUNTERED': {
    eventType: EventType.AdminError,
    action: `${EventType.AdminError}: Error Encountered`,
  },

  'ACCESS_LIST.ALLOWED_IP_LIST_VIEWED': {
    eventType: EventType.AccessList,
    action: 'Access List: Allowed IPs List Viewed',
  },
  'ACCESS_LIST.ALLOWED_IP_UPDATE': {
    eventType: EventType.AccessList,
    action: 'Access List: Allowed IP Updated',
  },
  'ACCESS_LIST.ALLOWED_IP_UPDATE_FAILED': {
    eventType: EventType.AccessList,
    action: 'Access List: Allowed IP Update Failed',
  },
  'ACCESS_LIST.ALLOWED_IP_CREATE': {
    eventType: EventType.AccessList,
    action: 'Access List: Allowed IP Created',
  },
  'ACCESS_LIST.ALLOWED_IP_CREATE_FAILED': {
    eventType: EventType.AccessList,
    action: 'Access List: Allowed IP Create Failed',
  },
  'ACCESS_LIST.ALLOWED_IP_DELETE': {
    eventType: EventType.AccessList,
    action: 'Access List: Allowed IP Deleted',
  },
  'ACCESS_LIST.ALLOWED_IP_DELETE_FAILED': {
    eventType: EventType.AccessList,
    action: 'Access List: Allowed IP Delete Failed',
  },
  'LOG_FORWARDER.FORWARDER_TAB_VIEWED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: Log Forwarder Tab Viewed',
  },
  'LOG_FORWARDER.FORWARDER_EDIT_TAB_VIEWED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: Log Forwarder Edit Tab Viewed',
  },
  'LOG_FORWARDER.FORWARDER_CREATED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: New Log Forwarder Created',
  },
  'LOG_FORWARDER.FORWARDER_UPDATED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: Existing Log Forwarder Updated',
  },
  'LOG_FORWARDER.FORWARDER_DELETED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: Log Forwarder Deleted',
  },
  'LOG_FORWARDER.FORWARDER_ENABLED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: Log Forwarder Enabled',
  },
  'LOG_FORWARDER.FORWARDER_DISABLED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: Log Forwarder Disabled',
  },
  'LOG_FORWARDER.FORWARDER_RESUMED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: Log Forwarder Resumed',
  },
  'LOG_FORWARDER.FORWARDER_CREATE_FAILED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: New Log Forwarder Create Failed',
  },
  'LOG_FORWARDER.FORWARDER_UPDATE_FAILED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: Existing Log Forwarder Update Failed',
  },
  'LOG_FORWARDER.FORWARDER_DELETE_FAILED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: Log Forwarder Delete Failed',
  },
  'LOG_FORWARDER.FORWARDER_ENABLE_FAILED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: Log Forwarder Enable Failed',
  },
  'LOG_FORWARDER.FORWARDER_DISABLE_FAILED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: Log Forwarder Disable Failed',
  },
  'LOG_FORWARDER.FORWARDER_RESUME_FAILED': {
    eventType: EventType.LogForwarder,
    action: 'Log Forwarder: Log Forwarder Resume Failed',
  },

  'ENDPOINT.ENDPOINT_LIST_VIEWED': {
    eventType: EventType.Endpoint,
    action: 'Endpoint: Endpoints List Viewed',
  },
  'ENDPOINT.ENDPOINT_CREATE': {
    eventType: EventType.Endpoint,
    action: 'Endpoint: Endpoint Created',
  },
  'ENDPOINT.ENDPOINT_CREATE_FAILED': {
    eventType: EventType.Endpoint,
    action: 'Endpoint: Endpoint Create Failed',
  },
  'ENDPOINT.ENDPOINT_DELETE': {
    eventType: EventType.Endpoint,
    action: 'Endpoint: Endpoint Deleted',
  },
  'ENDPOINT.ENDPOINT_DELETE_FAILED': {
    eventType: EventType.Endpoint,
    action: 'Endpoint: Endpoint Delete Failed',
  },
  'ENDPOINT.ENDPOINT_UPDATE': {
    eventType: EventType.Endpoint,
    action: 'Endpoint: Endpoint Updated',
  },
  'ENDPOINT.ENDPOINT_UPDATE_FAILED': {
    eventType: EventType.Endpoint,
    action: 'Endpoint: Endpoint Update Failed',
  },
  'ENDPOINT.CONVERT_ENDPOINT_MODAL_OPENED': {
    eventType: EventType.Endpoint,
    action: `Endpoint: Convert Endpoint Modal Opened`,
  },
  'ENDPOINT.CONVERT_ENDPOINT_MODAL_CONVERT_BUTTON_CLICKED': {
    eventType: EventType.Endpoint,
    action: `Endpoint: Convert Endpoint Modal Convert Button Clicked`,
  },
  'ENDPOINT.CONVERT_ENDPOINT_MODAL_OVERRIDE_BUTTON_CLICKED': {
    eventType: EventType.Endpoint,
    action: `Endpoint: Convert Endpoint Modal Override Button Clicked`,
  },
  'ENDPOINT.ENDPOINT_CONVERT_FAILED': {
    eventType: EventType.Endpoint,
    action: 'Endpoint: Endpoint Convert Failed',
  },
  'USER_SETTINGS.REFRESH_TOKEN_EXPIRATION_CHANGED': {
    eventType: EventType.UserSettings,
    action: `${EventType.UserSettings}: Refresh Token Expiration Changed`,
  },
  'PROVIDER_PRIVATE_ENDPOINT_CONFIGURATION.LIST_CONFIGS_VIEWED': {
    eventType: EventType.ProviderPrivateEndpoints,
    action: `${EventType.ProviderPrivateEndpoints}: Private Endpoints List Viewed`,
  },
  'PROVIDER_PRIVATE_ENDPOINT_CONFIGURATION.SETUP_MODAL_VIEWED': {
    eventType: EventType.ProviderPrivateEndpoints,
    action: `${EventType.ProviderPrivateEndpoints}: Setup Modal Viewed`,
  },
  'PROVIDER_PRIVATE_ENDPOINT_CONFIGURATION.UPDATE_ALLOW_NON_VPC_CLIENT_REQUESTS_MODAL_VIEWED': {
    eventType: EventType.ProviderPrivateEndpoints,
    action: `${EventType.ProviderPrivateEndpoints}: Allow Non VPC Client Requests Modal Viewed`,
  },
  'PROVIDER_PRIVATE_ENDPOINT_CONFIGURATION.UPDATE_ALLOW_NON_VPC_CLIENT_REQUESTS_MODAL_SUBMITTED': {
    eventType: EventType.ProviderPrivateEndpoints,
    action: `${EventType.ProviderPrivateEndpoints}: Allow Non VPC Client Requests Modal Submitted`,
  },
  'PROVIDER_PRIVATE_ENDPOINT_CONFIGURATION.SETUP_MODAL_STEP_VIEWED': {
    eventType: EventType.ProviderPrivateEndpoints,
    action: `${EventType.ProviderPrivateEndpoints}: Setup Modal Step Viewed`,
  },
  'PROVIDER_PRIVATE_ENDPOINT_CONFIGURATION.SETUP_MODAL_STEP_COMPLETED': {
    eventType: EventType.ProviderPrivateEndpoints,
    action: `${EventType.ProviderPrivateEndpoints}: Setup Modal Step Completed`,
  },
  'PROVIDER_PRIVATE_ENDPOINT_CONFIGURATION.SETUP_COMPLETED': {
    eventType: EventType.ProviderPrivateEndpoints,
    action: `${EventType.ProviderPrivateEndpoints}: Setup Completed`,
  },
  'PROVIDER_PRIVATE_ENDPOINT_CONFIGURATION.EDIT_BUTTON_CLICKED': {
    eventType: EventType.ProviderPrivateEndpoints,
    action: `${EventType.ProviderPrivateEndpoints}: Edit Button Clicked`,
  },
  'METRICS_CARD.EXPANDED_CHART_VIEWED': {
    eventType: EventType.MetricsCard,
    action: `${EventType.MetricsCard}: Expanded Chart Viewed`,
  },
  'METRICS_CARD.CONTROLS_CHANGED': {
    eventType: EventType.MetricsCard,
    action: `${EventType.MetricsCard}: Controls Changed`,
  },

  'DATA_API.SETTINGS_SAVED': {
    eventType: EventType.DataApi,
    action: `${EventType.DataApi}: Settings Saved`,
  },

  // event structure matches structure recommended by Growth for "Experiment Viewed"
  // event and therefore does not prepend an "eventType" to the event
  EXPERIMENT_VIEWED: {
    eventType: EventType.ExperimentViewed,
    action: 'Experiment Viewed',
  },
};

export default EVENTS;
