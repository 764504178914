import React from 'react';
import { Body, Subtitle } from '@leafygreen-ui/typography';

import { formatDiffValue } from 'baas-ui/deploy/confirm-deployment-diff/utils';
import { DraftDiff } from 'admin-sdk';

export enum TestSelector {
  Diffs = 'graphql-config-diffs',
  ConfigField = 'graphql-config-diffs-field',
}

export interface Props {
  graphqlDiff: DraftDiff['graphQLConfigDiff'];
  graphqlValidationDiffs: DraftDiff['schemaOptionsDiff']['graphQLValidationDiffs'];
}

const fieldToDisplayNameMap: { [name: string]: string } = {
  use_natural_pluralization: 'Natural Pluralization',
  read_validation_action: 'Validation Action (Reads)',
  read_validation_level: 'Validation Level (Reads)',
  write_validation_action: 'Validation Action (Writes)',
  write_validation_level: 'Validation Level (Writes)',
};

export default function GraphQLConfigDisplayDiff({ graphqlDiff, graphqlValidationDiffs }: Props) {
  const diffs = [...graphqlDiff.fieldDiffs, ...graphqlValidationDiffs];

  if (!diffs.length) {
    return null;
  }

  return (
    <div data-testid={TestSelector.Diffs} className="deployment-diff">
      <Subtitle className="deployment-diff-title">GraphQL Settings</Subtitle>
      <div className="deployment-diff-content">
        {diffs.map((diff) => (
          <div
            data-testid={`${TestSelector.ConfigField}-${diff.field}`}
            className="deployment-diff-graphql-config-field"
            key={diff.field}
          >
            <Body
              data-testid={`${TestSelector.ConfigField}-${diff.field}-previous`}
              className="deployment-diff-graphql-config-previous"
            >
              {`− ${fieldToDisplayNameMap[diff.field]}: ${formatDiffValue(diff.previousValue)}`}
            </Body>
            <Body data-testid={`${TestSelector.ConfigField}-${diff.field}-updated`}>{`+ ${
              fieldToDisplayNameMap[diff.field]
            }: ${formatDiffValue(diff.updatedValue)}`}</Body>
          </div>
        ))}
      </div>
    </div>
  );
}
