import React from 'react';
import Banner, { Variant } from '@leafygreen-ui/banner';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import { Body } from '@leafygreen-ui/typography';

import './pause-sync-modal.less';

export enum TestSelector {
  Modal = 'pause-sync-modal',
  Confirmation = 'pause-sync-modal-confirmation',
  ClusterName = 'pause-sync-modal-cluster-name',
  Description = 'pause-sync-modal-description',
}

export interface Props {
  open: boolean;
  clusterName: string;
  onConfirm(): void;
  onCancel(): void;
}

const baseClassName = 'pause-sync-modal';

const PauseSyncModal = ({ open, clusterName, onConfirm, onCancel }: Props) => {
  return (
    <ConfirmationModal
      title="Pause Sync"
      open={open}
      confirmButtonProps={{
        children: 'Pause Sync',
        onClick: onConfirm,
      }}
      cancelButtonProps={{
        onClick: onCancel,
      }}
      data-test-selector={TestSelector.Modal}
      data-testid={TestSelector.Modal}
      data-cy={TestSelector.Modal}
      className={baseClassName}
    >
      <div className={`${baseClassName}-confirmation`} data-test-selector={TestSelector.Confirmation}>
        <Body>Are you sure you want to pause sync for</Body>
        <Body weight="medium" data-test-selector={TestSelector.ClusterName}>
          &nbsp;{clusterName}
        </Body>
        <Body>?</Body>
      </div>
      <Body className={`${baseClassName}-description`} data-test-selector={TestSelector.Description}>
        Pausing sync will stop syncing changes between Atlas and your application, and any pending changes from your
        application will be lost.
      </Body>
      <Banner variant={Variant.Warning} className={`${baseClassName}-banner`}>
        You may need to re-enable sync if your oplog rolls past the time that you paused Sync.
      </Banner>
    </ConfirmationModal>
  );
};

export default PauseSyncModal;
