import { useLocalStorage } from 'baas-ui/common/hooks/use-local-storage';
import { timeZoneKey } from 'baas-ui/common/local-storage-keys';

export interface TimeZone {
  timeZoneCurrentOffset: number;
  timeZoneId: string;
  timeZoneDisplay: string;
}

export const getTimeZone = (cloudUIBaseUrl: string, groupId: string): Promise<TimeZone> => {
  const timezoneUrl = `${cloudUIBaseUrl}/settings/groupTimeZone/${groupId}?considerUserPrefs=true`;
  return fetch(timezoneUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then((response) => response.json());
};

// useTimeZone is a hook for getting stored timezone preferences
export const useTimeZone = (groupId: string) => {
  const defaultTimeZone: TimeZone = {
    timeZoneId: 'Etc/UTC',
    timeZoneDisplay: 'UTC',
    timeZoneCurrentOffset: 0,
  };
  return useLocalStorage(timeZoneKey(groupId), defaultTimeZone)[0];
};

export const setTimeZone = (groupId: string, timezone: TimeZone) => {
  window.localStorage.setItem(timeZoneKey(groupId), JSON.stringify(timezone));
};
