import * as Sentry from '@sentry/react';
import { ErrorEvent, EventHint } from '@sentry/types';

import { ErrInvalidSession, ErrMissingSession, ErrUIIPRestricted, ErrUnauthorized, ResponseError } from 'admin-sdk';

export function hasOwnProperty<T extends {}, K extends PropertyKey>(o: T, k: K): o is T & Record<K, unknown> {
  return Object.prototype.hasOwnProperty.call(o, k);
}

export const existy = (val: any) => {
  // Intentional `!=` instead of `!==`,
  // will evaluate to false for both `undefined`
  // and `null` because javascript. Useful for
  // checking whether or not a value is defined,
  // treating `null` and `undefined` as the only
  // "falsy" options.
  return val != null;
};

export function asResponseError(error: unknown): ResponseError {
  if (error instanceof ResponseError) {
    return error;
  }

  let message = '';
  let code = '';

  if (typeof error === 'object' && error) {
    if (hasOwnProperty(error, 'message')) {
      message = String(error.message);
    }
    if (hasOwnProperty(error, 'code')) {
      code = String(error.code);
    }
  }

  if (!message) {
    message = String(error);
  }
  return new ResponseError(message, code);
}

function buildErrorFromResponse(response: Response) {
  return ['Error', response?.status, response?.statusText].filter(existy).join(' ');
}

export function parseErrorMessage({ message, response }: ResponseError): string {
  if (existy(message) && message!.length) {
    return message!;
  }
  return buildErrorFromResponse(response);
}

export const isAuthErrorCode = (code) =>
  [ErrUnauthorized, ErrInvalidSession, ErrUIIPRestricted, ErrMissingSession].includes(code);

const isFilteredOutByMessage = (errorMessage) =>
  [
    'Must auth first', // This is not an expected error if the user is not authenticated
    'Session invalid according to user service',
    'NetworkError when attempting to fetch resource',
    'recaptcha', // This can surface as part of the recaptcha user flow, 3rd party
  ].some((msg) => errorMessage.includes(msg));

export const errorHandlerNotify = (error) => {
  if (process.env.DISABLE_SENTRY || process.env.NODE_ENV === 'test') {
    return;
  }

  const responseError = asResponseError(error);
  // If the error is sdk auth related don't send it to Sentry
  if (isAuthErrorCode(responseError.code) || isFilteredOutByMessage(responseError.message)) {
    return;
  }

  try {
    Sentry.captureException(error);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const warningHandlerNotify = (message: string) => {
  if (process.env.DISABLE_SENTRY || process.env.NODE_ENV === 'test') {
    return;
  }

  try {
    Sentry.captureMessage(message, 'warning');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
};

// Used to filter events before sending to Sentry. Returns null if the event should be filtered and returns the event
// otherwise.
export const beforeSend = (event: ErrorEvent, hint: EventHint) => {
  if (hint.originalException) {
    const responseError = asResponseError(hint.originalException);

    // If the error is sdk auth related don't send it to Sentry
    if (isAuthErrorCode(responseError.code)) {
      return null;
    }
  }

  if (event.exception && event.exception.values) {
    event.fingerprint = ['{{ default }}', `${event.exception?.values[0].type} ||| ${event.exception?.values[0].value}`];
    if (!event.tags) {
      event.tags = {};
    }
    event.tags.sub_error = `${event.exception?.values[0].value}`;
  }

  return event;
};
