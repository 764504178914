import { useEffect, useState } from 'react';

import { generateDefaultResourceName } from 'baas-ui/common/utils/util';
import { PartialApp } from 'admin-sdk';

const useDefaultAppName = ({ apps, setAppName }: { apps: readonly PartialApp[]; setAppName: (string) => void }) => {
  const [defaultAppName, setDefaultAppName] = useState('Application-0');

  // Determine default app name
  useEffect(() => {
    const name = generateDefaultResourceName(
      apps.map((app) => app.name),
      'Application'
    );
    setDefaultAppName(name);
    setAppName(name);
  }, [apps]);

  return defaultAppName;
};

export default useDefaultAppName;
