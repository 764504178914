import { createReducer } from 'redux-act';

import {
  PartitionKeyType,
  SyncAlert,
  SyncClientSchema,
  SyncMigrationStatus,
  SyncProgress,
  SyncState as SyncStatus,
} from 'admin-sdk';

import * as actions from './actions';
import { ConfigState, DataState, PermissionsErrorState, SyncEnabledToastState } from './types';

export interface SyncState {
  config: ConfigState;
  data: DataState;
  alerts: SyncAlert[];
  clientSchemas: SyncClientSchema[];
  loadingClientSchemas: boolean;
  clientSchemasServerError?: string;
  patchSchemasInProgress: boolean;
  patchSchemasServerError?: string;
  pauseSyncInProgress: boolean;
  terminateSyncInProgress: boolean;
  syncState: SyncStatus;
  syncStateError: string;
  pauseSyncError?: string;
  permissionsError: PermissionsErrorState;
  flexiblePermissionsError?: string;
  syncProgress: {
    progress: SyncProgress;
    error?: string;
    pollCount: number;
  };
  terminateSyncError: string;
  syncEventSubscriptionError?: string;
  syncEnabledToastStatus: string;
  migration: {
    status?: SyncMigrationStatus;
    error?: string;

    // pollCount defines the number of unsuccessful polling
    // attempts to perform before disabling the poller
    pollCount: number;
  };
}

export const defaultState: SyncState = {
  config: {
    developmentModeEnabled: false,
    isSaving: false,
    isLoading: false,
  },
  data: {
    serviceId: '',
    partitionFields: [],
    queryableFieldsNames: [],
  },
  alerts: [],
  clientSchemas: [],
  loadingClientSchemas: false,
  patchSchemasInProgress: false,
  pauseSyncInProgress: false,
  terminateSyncInProgress: false,
  syncState: SyncStatus.Terminated,
  syncStateError: '',
  syncEnabledToastStatus: SyncEnabledToastState.None,
  permissionsError: { read: '', write: '' },
  syncProgress: {
    progress: new SyncProgress(),
    pollCount: 0,
  },
  terminateSyncError: '',
  migration: {
    pollCount: 0,
  },
};

const reducer = createReducer<SyncState>({}, defaultState);

reducer.on(actions.setDevelopmentModeEnabled, (state, developmentModeEnabled) => ({
  ...state,
  config: {
    ...state.config,
    developmentModeEnabled,
  },
}));

reducer.on(actions.setPauseSyncInProgress, (state, pauseSyncInProgress) => ({
  ...state,
  pauseSyncInProgress,
}));

reducer.on(actions.setTerminateSyncInProgress, (state, terminateSyncInProgress) => ({
  ...state,
  terminateSyncInProgress,
}));

reducer.on(actions.setDataServiceId, (state, serviceId) => ({
  ...state,
  data: {
    ...state.data,
    serviceId,
  },
}));

reducer.on(actions.setConfigServiceId, (state, serviceId) => ({
  ...state,
  config: {
    ...state.config,
    serviceId,
  },
}));

reducer.on(actions.setPermissionsError, (state, permissionsError) => ({
  ...state,
  permissionsError,
}));

reducer.on(actions.setFlexiblePermissionsError, (state, flexiblePermissionsError) => ({
  ...state,
  flexiblePermissionsError,
}));

reducer.on(actions.setPauseSyncError, (state, pauseSyncError) => ({
  ...state,
  pauseSyncError,
}));

reducer.on(actions.setTerminateSyncError, (state, terminateSyncError) => ({
  ...state,
  terminateSyncError,
}));

reducer.on(actions.setSyncEventSubscriptionError, (state, syncEventSubscriptionError) => ({
  ...state,
  syncEventSubscriptionError,
}));

reducer.on(actions.setSyncEnabledToastState, (state, syncEnabledToastStatus) => ({
  ...state,
  syncEnabledToastStatus,
}));

reducer.on(actions.loadConfigActions.req, (state) => ({
  ...state,
  config: { ...state.config, isLoading: true, loadError: undefined },
}));

reducer.on(actions.loadConfigActions.rcv, (state, { payload }) => ({
  ...state,
  config: {
    ...state.config,
    ...payload,
    isLoading: false,
    loadError: undefined,
  },
}));

reducer.on(actions.loadConfigActions.fail, (state, { error }) => ({
  ...state,
  config: { ...state.config, isLoading: false, loadError: error },
}));

reducer.on(actions.updateConfigActions.req, (state) => ({
  ...state,
  config: { ...state.config, isSaving: true },
}));

reducer.on(actions.updateConfigActions.rcv, (state, { reqArgs }) => ({
  ...state,
  config: {
    ...state.config,
    ...reqArgs.config,
    isSaving: false,
  },
}));

reducer.on(actions.updateConfigActions.fail, (state) => ({
  ...state,
  config: { ...state.config, isSaving: false },
}));

reducer.on(actions.loadClientSchemasActions.req, (state) => ({
  ...state,
  loadingClientSchemas: true,
  clientSchemasServerError: undefined,
}));

reducer.on(actions.loadClientSchemasActions.rcv, (state, { payload }) => ({
  ...state,
  loadingClientSchemas: false,
  clientSchemas: payload,
  clientSchemasServerError: undefined,
}));

reducer.on(actions.loadClientSchemasActions.fail, (state, { error }) => ({
  ...state,
  loadingClientSchemas: false,
  clientSchemasServerError: error,
}));

reducer.on(actions.loadDataActions.req, (state) => ({
  ...state,
  data: { ...state.data, loadError: undefined },
}));

reducer.on(actions.loadDataActions.rcv, (state, { payload: { serviceId, partitionFields, queryableFieldsNames } }) => ({
  ...state,
  data: {
    ...state.data,
    serviceId: serviceId || '',
    partitionFields: (partitionFields || []).map((field) => ({
      key: field.key,
      type: field.type as PartitionKeyType,
      required: field.required,
    })),
    queryableFieldsNames: queryableFieldsNames || [],
    loadError: undefined,
  },
}));

reducer.on(actions.loadDataActions.fail, (state, { error }) => ({
  ...state,
  data: { ...state.data, loadError: error },
}));

reducer.on(actions.patchSchemasActions.req, (state) => ({
  ...state,
  patchSchemasInProgress: true,
  patchSchemasServerError: undefined,
}));

reducer.on(actions.patchSchemasActions.rcv, (state) => ({
  ...state,
  patchSchemasInProgress: false,
  patchSchemasServerError: undefined,
}));

reducer.on(actions.patchSchemasActions.fail, (state, { error }) => ({
  ...state,
  patchSchemasInProgress: false,
  patchSchemasServerError: error,
}));

reducer.on(actions.setProgressPollCount, (state, pollCount) => ({
  ...state,
  syncProgress: {
    ...state.syncProgress,
    progress: new SyncProgress(),
    pollCount,
  },
}));

reducer.on(actions.dismissProgress, (state) => ({
  ...state,
  syncProgress: {
    progress: new SyncProgress(),
    pollCount: 0,
    error: undefined,
  },
}));

reducer.on(actions.loadProgressActions.req, (state) => ({
  ...state,
  syncProgress: {
    ...state.syncProgress,
    error: undefined,
  },
}));

reducer.on(actions.loadProgressActions.rcv, (state, { payload }) => {
  let pollCount = state.syncProgress.pollCount;
  if (Object.values(payload.progress).some((p) => !p.complete)) {
    // continue polling if there is ongoing sync progress
    pollCount = 1;
  } else {
    // decrease pollCount by 1 unless it would go below 0
    pollCount = Math.max(0, pollCount - 1);
  }

  return {
    ...state,
    syncProgress: {
      ...state.syncProgress,
      progress: payload,
      pollCount,
    },
  };
});

reducer.on(actions.loadProgressActions.fail, (state, { error }) => ({
  ...state,
  syncProgress: {
    ...state.syncProgress,
    error,
    pollCount: 0,
  },
}));

reducer.on(actions.setMigrationStatusPollCount, (state, pollCount) => ({
  ...state,
  migration: {
    pollCount,
  },
}));

reducer.on(actions.loadSyncMigrationStatusAction.req, (state) => ({
  ...state,
  migration: {
    ...state.migration,
    error: undefined, // unset error when sending a new request for the migration status
  },
}));

reducer.on(actions.loadSyncMigrationStatusAction.rcv, (state, { payload }) => {
  // if statusMessage is empty, then decrement pollCount
  // otherwise, continue polling
  const migrationPollCount = payload.statusMessage ? 1 : Math.max(0, state.migration.pollCount - 1);

  // if the migration poller is finding migration statuses, turn off the initial sync poller
  // otherwise, turn the initial sync poller back on in case it was turned off due to a
  // sync migration being committed while an initial sync was running
  let progressPollCount = state.syncProgress.pollCount;
  if (payload.statusMessage) {
    progressPollCount = 0;
  } else if (state.migration.status?.statusMessage) {
    progressPollCount = 1;
  }

  return {
    ...state,
    migration: {
      status: payload,
      pollCount: migrationPollCount,
    },
    syncProgress: {
      ...state.syncProgress,
      pollCount: progressPollCount,
    },
  };
});

reducer.on(actions.loadSyncMigrationStatusAction.fail, (state, { error }) => ({
  ...state,
  migration: {
    error,
    pollCount: 0, // stop polling
  },
}));

reducer.on(actions.getSyncStateAction.req, (state) => ({
  ...state,
  syncStateError: '',
}));

reducer.on(actions.getSyncStateAction.rcv, (state, { payload }) => ({
  ...state,
  syncState: payload.state,
  syncStateError: '',
}));

reducer.on(actions.getSyncStateAction.fail, (state, { error }) => ({
  ...state,
  syncStateError: error,
}));

reducer.on(actions.loadSchemaVersionsAction.req, (state) => ({
  ...state,
}));

reducer.on(actions.loadSchemaVersionsAction.rcv, (state, { payload }) => ({
  ...state,
  schemaVersionResponse: payload,
}));

reducer.on(actions.getSyncAlertsAction.req, (state) => ({
  ...state,
}));

reducer.on(actions.getSyncAlertsAction.rcv, (state, { payload }) => ({
  ...state,
  alerts: payload.alerts,
}));

export default reducer;
