import { createAction } from 'redux-act';

import { makeBaseActions, SetRulePayload } from 'baas-ui/services/default/actions';
const NAME = 'aws-service/';

interface AddActionPayload extends SetRulePayload {
  value: any;
}

interface DeleteActionPayload extends SetRulePayload {
  index: number;
}

interface UpdateAPIPayload extends SetRulePayload {
  value: any;
  index: number;
}

const baseActions = makeBaseActions(NAME);

const addAction = createAction<AddActionPayload>(`${NAME}add action in aws svc`);
const deleteAction = createAction<DeleteActionPayload>(`${NAME}delete action in aws svc`);
const setAPI = createAction<UpdateAPIPayload>(`${NAME}set aws svc api`);
const setAPIAction = createAction<UpdateAPIPayload>(`${NAME}set aws svc api action`);

const awsActions = { ...baseActions, addAction, deleteAction, setAPI, setAPIAction };
export default awsActions;
