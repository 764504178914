import { RootState } from './types';

export const getApiKeyState = (state: RootState) => state.auth.apiKeys;
export const getAppState = (state: RootState) => state.app;
export const getAdminClientState = (state: RootState) => state.base.adminClient;
export const getDeploymentState = (state: RootState) => state.deployment;
export const getEventsState = (state: RootState) => state.events;
export const getFunctionsState = (state: RootState) => state.functions;
export const getGraphQLState = (state: RootState) => state.graphql;
export const getDependenciesState = (state: RootState) => state.dependencies;
export const getHomeState = (state: RootState) => state.home;
export const getHostingState = (state: RootState) => state.hosting;
export const getIncomingWebhooksState = (state: RootState) => state.incomingWebhooks;
export const getLogsState = (state: RootState) => state.logs;
export const getMeasurementsState = (state: RootState) => state.measurements;
export const getMetricsState = (state: RootState) => state.metrics;
export const getNavigationState = (state: RootState) => state.navigation;
export const getProviderState = (state: RootState) => state.auth.auth;
export const getPushState = (state: RootState) => state.push;
export const getRouterState = (state: RootState) => state.router;
export const getSchemasState = (state: RootState) => state.schemas;
export const getSecurityState = (state: RootState) => state.auth.security;
export const getServiceState = (state: RootState) => state.service.base;
export const getServiceTypesState = (state: RootState) => state.service.serviceTypes;
export const getSettingsState = (state: RootState) => state.base.settings;
export const getUserProfileState = (state: RootState) => state.base.userProfile;
export const getFirstAppSaveState = (state: RootState) => state.base.firstAppSave;
export const getFirstDraftSaveState = (state: RootState) => state.base.firstDraftSave;
export const getDomainRedirectState = (state: RootState) => state.domainRedirect;
export const getUsersState = (state: RootState) => state.users;
export const getValuesState = (state: RootState) => state.values;
export const getEditStatusState = (state: RootState) => state.editStatus;
export const getUserSettingsState = (state: RootState) => state.userSettings;
export const getSyncState = (state: RootState) => state.sync;
export const getSdkState = (state: RootState) => state.sdks;
