import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';

import { generateDefaultResourceName } from 'baas-ui/common/utils/util';
import { JSONState } from 'baas-ui/models';
import { AppFunction, DebugExecuteFunctionResponse, User } from 'admin-sdk';

import * as actions from './actions';
import { CONSOLE_SOURCE_DEFAULT, EDITOR_FUNCTION_SOURCE_DEFAULT } from './constants';
import { AppFunctionsState, DebugFunctionState } from './types';

/* Function Reducer */
export const defaultAppFunction: AppFunction = {
  id: '',
  private: false,
  name: '',
  source: EDITOR_FUNCTION_SOURCE_DEFAULT,
  disableArgLogs: true,
};

export const defaultFuncsState: AppFunctionsState = {
  createFunctionError: '',
  editorError: '',
  editorFunction: defaultAppFunction,
  editorFunctionPristine: defaultAppFunction,
  fetchFunctionError: '',
  fetchingFunction: false,
  fetchingFunctions: false,
  functions: [],
  loadFunctionsError: '',
  loading: false,
  removeFunctionError: '',
  saving: false,
};

const appFunctionsReducer = createReducer<AppFunctionsState>({}, defaultFuncsState);

/* Load Function */
appFunctionsReducer.on(actions.loadFunctionsActions.req, (state) => ({
  ...state,
  loadFunctionsError: '',
  fetchingFunctions: true,
  editorError: '',
  createFunctionError: '',
  removeFunctionError: '',
  fetchFunctionError: '',
}));

appFunctionsReducer.on(actions.loadFunctionsActions.rcv, (state, { payload }) => ({
  ...state,
  functions: payload,
  fetchingFunctions: false,
}));

appFunctionsReducer.on(actions.loadFunctionsActions.fail, (state, { error }) => ({
  ...state,
  loadFunctionsError: error,
  fetchingFunctions: false,
}));

/* Fetch Function */
appFunctionsReducer.on(actions.fetchFunctionActions.req, (state, { functionId }) => {
  let editorFunction = state.editorFunction;

  if (editorFunction?.id !== functionId) {
    editorFunction = defaultAppFunction;
    editorFunction.name = generateDefaultResourceName(
      state.functions.map((oneFunction) => oneFunction.name),
      'Function',
      '_'
    );
  }

  return {
    ...state,
    createFunctionError: '',
    editorError: '',
    editorFunction,
    fetchFunctionError: '',
    fetchingFunction: true,
    loadFunctionsError: '',
    removeFunctionError: '',
  };
});

appFunctionsReducer.on(actions.fetchFunctionActions.rcv, (state, { payload }) => {
  let canEvaluate;
  if (payload.canEvaluate) {
    canEvaluate = payload.canEvaluate.input ? payload.canEvaluate : JSONState.fromRaw(payload.canEvaluate);
  }

  return {
    ...state,
    editorFunction: { ...payload, canEvaluate },
    editorFunctionPristine: { ...payload, canEvaluate },
    fetchFunctionError: '',
    fetchingFunction: false,
  };
});

appFunctionsReducer.on(actions.fetchFunctionActions.fail, (state, { error }) => ({
  ...state,
  fetchFunctionError: error,
  fetchingFunction: false,
}));

/* Create Function */
appFunctionsReducer.on(actions.createFunctionActions.req, (state) => ({
  ...state,
  saving: true,
  editorError: '',
  createFunctionError: '',
  removeFunctionError: '',
  fetchFunctionError: '',
  loadFunctionsError: '',
}));

appFunctionsReducer.on(actions.createFunctionActions.rcv, (state, { payload }) => {
  const appFunc = new AppFunction({ ...payload });
  return {
    ...state,
    saving: false,
    editorFunction: appFunc,
    editorFunctionPristine: appFunc,
    createFunctionError: '',
  };
});

appFunctionsReducer.on(actions.createFunctionActions.fail, (state, { error }) => ({
  ...state,
  saving: false,
  editorError: error,
  createFunctionError: error,
}));

/* Update Function */
appFunctionsReducer.on(actions.updateFunctionActions.req, (state) => ({
  ...state,
  saving: true,
  editorError: '',
  createFunctionError: '',
  removeFunctionError: '',
  fetchFunctionError: '',
  loadFunctionsError: '',
}));

appFunctionsReducer.on(actions.updateFunctionActions.rcv, (state) => ({
  ...state,
  editorFunctionPristine: state.editorFunction,
  saving: false,
}));

appFunctionsReducer.on(actions.updateFunctionActions.fail, (state, { error }) => ({
  ...state,
  saving: false,
  editorError: error,
}));

/* Remove Function */
appFunctionsReducer.on(actions.removeFunctionActions.req, (state) => ({
  ...state,
  removeFunctionError: '',
}));

appFunctionsReducer.on(actions.removeFunctionActions.rcv, (state) => ({
  ...state,
  removeFunctionError: '',
}));

appFunctionsReducer.on(actions.removeFunctionActions.fail, (state, { error }) => ({
  ...state,
  removeFunctionError: error,
}));

/* Edit Form */
appFunctionsReducer.on(actions.openDefaultEditor, (state) => {
  const editorFunction = defaultAppFunction;
  editorFunction.name = generateDefaultResourceName(
    state.functions.map((oneFunction) => oneFunction.name),
    'Function',
    '_'
  );
  return {
    ...state,
    editorFunction,
    editorError: '',
  };
});

appFunctionsReducer.on(actions.updateEditor, (state, updatedFunc: Partial<AppFunction>) => {
  // Reset the CanEvaluate field to undefined if the input string is empty
  if (updatedFunc.canEvaluate && !updatedFunc.canEvaluate.input) {
    updatedFunc.canEvaluate = undefined;
  }

  return { ...state, editorFunction: { ...state.editorFunction, ...updatedFunc } };
});

/* Clear Errors */
appFunctionsReducer.on(actions.clearErrors, (state) => ({
  ...state,
  editorError: '',
  createFunctionError: '',
  removeFunctionError: '',
  fetchFunctionError: '',
  loadFunctionsError: '',
}));

/* Discard Changes */
appFunctionsReducer.on(actions.discardChanges, (state) => ({
  ...state,
  editorFunction: state.editorFunctionPristine,
  editorError: '',
}));

/* Update Editor Error */
appFunctionsReducer.on(actions.updateEditorError, (state, error) => ({
  ...state,
  saving: false,
  editorError: String(error),
}));

/* Debug Reducer */
export const debugDefaultState: DebugFunctionState = {
  consoleSource: CONSOLE_SOURCE_DEFAULT,
  executing: false,
  resultSource: [],
  selectedUser: undefined,
};

const debugReducer = createReducer<DebugFunctionState>({}, debugDefaultState);

debugReducer.on(actions.selectUser, (state, payload: User) => ({ ...state, selectedUser: payload }));

debugReducer.on(actions.clearSelectedUser, (state) => ({ ...state, selectedUser: undefined }));

debugReducer.on(actions.updateDebugSource, (state, payload) => ({ ...state, consoleSource: payload }));

debugReducer.on(actions.clearDebugSource, (state) => ({ ...state, consoleSource: CONSOLE_SOURCE_DEFAULT }));

debugReducer.on(actions.clearDebugResultSource, (state) => ({ ...state, resultSource: [] }));

/* Execute Debug Source */
debugReducer.on(actions.executeDebugSourceActions.req, (state) => {
  const date = Date.now();
  const resultSource = [...state.resultSource];
  const newDebugResp = new DebugExecuteFunctionResponse();
  resultSource.push({ ...newDebugResp, timestamp: date, pending: true });

  return { ...state, resultSource, executing: true };
});

debugReducer.on(actions.executeDebugSourceActions.rcv, (state, { payload }) => {
  const resultSource = [...state.resultSource];
  const lastIdx = resultSource.length - 1;
  const previous = resultSource[lastIdx];
  const resultData = { ...previous, ...payload, pending: false };

  resultSource[lastIdx] = resultData;

  return { ...state, resultSource, executing: false };
});

debugReducer.on(actions.executeDebugSourceActions.fail, (state, { error }) => {
  const resultSource = [...state.resultSource];
  const lastIdx = resultSource.length - 1;
  const previous = resultSource[lastIdx];
  const resultData = { ...previous, error, pending: false };

  resultSource[lastIdx] = resultData;

  return { ...state, resultSource, executing: false };
});

const functionReducers = combineReducers({
  appFunctionsState: appFunctionsReducer,
  debugFunctionState: debugReducer,
});

export default functionReducers;
