import { SyncState } from 'admin-sdk';

import { DEFAULT_PARTITION_RULE_TEMPLATE, RawPartitionSyncConfig, RawQuerySyncConfig } from './types';

export const STATUS_TYPE_DEV_MODE = 'DEVELOPMENT_MODE';
export const STATUS_TYPE_SYNC_INIT = 'SYNC_INITIALIZATION';
export const STATUS_TYPE_SYNC_MIGRATION = 'SYNC_MIGRATION';

export const DEFAULT_RAW_TERMINATED_SYNC_CONFIG: RawPartitionSyncConfig = {
  state: SyncState.Terminated,
  database_name: '',
  partition: {
    key: '',
    permissions: DEFAULT_PARTITION_RULE_TEMPLATE.permissions,
  },
  last_disabled: 0,
};

export const DEFAULT_RAW_TERMINATED_QUERY_SYNC_CONFIG: RawQuerySyncConfig = {
  state: SyncState.Terminated,
  database_name: '',
  queryable_fields_names: [],
  collection_queryable_fields_names: {},
  indexed_queryable_fields_names: [],
  permissions: '',
};

// 30s of polling at 3s per poll
export const DEFAULT_POLL_COUNT = 10;

export const MAX_OFFLINE_TIME_DEFAULT = 30;
export const MAX_OFFLINE_TIME_DISABLED = 0;

// MAX_INCOMPATIBLE_COLLECTION_NAME_LENGTH is the maximum amount of characters
// that a collection name can have before being truncated in the incompatible roles modal
export const MAX_INCOMPATIBLE_COLLECTION_NAME_LENGTH = 14;

// MAX_INCOMPATIBLE_ROLE_NAME_LENGTH is the maximum amount of characters
// that a role name can have before being truncated in the incompatible roles modal
export const MAX_INCOMPATIBLE_ROLE_NAME_LENGTH = 14;

// COLLECTION_SECTION_LENGTH is the number of characters (including whitespace)
// that the collection section must include in the incompatible roles modal
export const COLLECTION_SECTION_LENGTH = 23;

// COLLECTION_SECTION_LEADING_WHITESPACE_LENGTH denotes the number of spaces that
// precede the collection text in the incompatible roles modal
export const COLLECTION_SECTION_LEADING_WHITESPACE_LENGTH = 2;

// MAX_ROLE_CHARS_PER_LINE denotes the limit for the number of characters
// that a list of incompatible role names can take up for a particular collection before
// having to create a new line for the remaining roles
export const MAX_ROLE_CHARS_PER_LINE = 20;

// DEFAULT_HEADER_OVERFLOW_THRESHOLD gives the default window width (in pixels) threshold for
// estimating whether the sync page header is already overflowing on component mount
export const DEFAULT_HEADER_OVERFLOW_THRESHOLD = 1450;
