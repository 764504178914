import React, { useState } from 'react';
import { connect } from 'react-redux';
import Toast, { Variant } from '@leafygreen-ui/toast';

import usePoller from 'baas-ui/common/hooks/use-poller';
import { AsyncDispatch, AsyncDispatchPayload } from 'baas-ui/redux_util';
import { getAppState, getDeploymentState, getServiceState, getSyncState } from 'baas-ui/selectors';
import * as syncActions from 'baas-ui/sync/actions';
import { getSyncConfigFromServiceConfig, SyncType } from 'baas-ui/sync/types';
import { RootState } from 'baas-ui/types';
import { GetSyncStateResponse, SyncState } from 'admin-sdk';

import './sync-terminating-toast.less';

interface ReduxStateProps {
  serviceId?: string;
  syncState: SyncState;
  syncType?: SyncType;
  isLoadingLatestDeployment: boolean;
}

interface ReduxDispatchProps {
  loadSyncStateForPolling(syncType: SyncType): AsyncDispatchPayload<GetSyncStateResponse>;
}

export type Props = ReduxStateProps & ReduxDispatchProps;

export enum TestSelector {
  SyncTerminatingToast = 'sync-terminating-toast',
}

export const getSyncStateInterval = 3e3;

function getToastProps(syncState: SyncState): {
  variant: Variant;
  title: string;
  body: string;
} {
  switch (syncState) {
    case SyncState.Terminated:
      return {
        variant: Variant.Success,
        title: 'Sync successfully terminated',
        body: '',
      };
    case SyncState.Terminating:
    default:
      return {
        variant: Variant.Progress,
        title: 'Sync is currently terminating...',
        body: 'Please wait for sync to finish terminating before enabling again.',
      };
  }
}

const SyncTerminatingToast = ({ syncType, syncState, loadSyncStateForPolling, isLoadingLatestDeployment }: Props) => {
  const poller = usePoller();
  const [isOpen, setIsOpen] = useState(false);

  const getTerminatorStateWrapper = async () => {
    await loadSyncStateForPolling(syncType!).catch(() => {});
  };

  React.useEffect(() => {
    if (!isLoadingLatestDeployment && syncType !== undefined) {
      getTerminatorStateWrapper();
    }
  }, [isLoadingLatestDeployment, syncType]);

  React.useEffect(() => {
    if (syncState === SyncState.Terminating && !poller.isPolling) {
      setIsOpen(true);
      poller.start(getTerminatorStateWrapper, getSyncStateInterval);
    }

    if (syncState === SyncState.Terminated && poller.isPolling) {
      poller.stop();
    }
  }, [syncState]);

  if (syncState === SyncState.Enabled || syncState === SyncState.Disabled) {
    // Only render this toast during termination and close it after it has finished
    return null;
  }

  const { variant, title, body } = getToastProps(syncState);
  return (
    // Use a progress toast with 100% progress to give the effect of an 'in progress' task without knowing actual % done
    <Toast
      className="sync-terminating-toast"
      data-cy={TestSelector.SyncTerminatingToast}
      data-testid={TestSelector.SyncTerminatingToast}
      variant={variant}
      title={title}
      close={syncState === SyncState.Terminating ? undefined : () => setIsOpen(false)}
      open={isOpen}
      body={body}
      progress={100}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    app: { id: appId, groupId },
  } = getAppState(state);
  const sync = getSyncState(state);
  const { svcConfigsById } = getServiceState(state);
  const syncServiceConfig = svcConfigsById[sync.config.serviceId || ''];
  const { loadingLatestDeployment } = getDeploymentState(state);

  // getSyncConfigFromServiceConfig will default to flexible sync config, even if the service cfg is undefined
  const syncType = syncServiceConfig && getSyncConfigFromServiceConfig(syncServiceConfig).type;

  return {
    appId,
    groupId,
    syncState: sync.syncState,
    syncType,
    isLoadingLatestDeployment: loadingLatestDeployment,
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  loadSyncStateForPolling: (groupId: string, appId: string) => (syncType: SyncType) =>
    dispatch(syncActions.getSyncState({ groupId, appId, syncType })),
});

const mergeProps = (
  { appId, groupId, ...stateProps }: ReturnType<typeof mapStateToProps>,
  { loadSyncStateForPolling, ...dispatchProps }: ReturnType<typeof mapDispatchToProps>
): Props => {
  return {
    ...stateProps,
    ...dispatchProps,
    loadSyncStateForPolling: loadSyncStateForPolling(groupId, appId),
  };
};

export { SyncTerminatingToast as SyncTerminatingToastComponent };
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SyncTerminatingToast);
