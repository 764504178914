import React from 'react';
import { Body } from '@leafygreen-ui/typography';

export const appCardMetricsRowClassname = `app-card-metrics-row`;

export enum TestSelector {
  Title = 'title',
  Usage = 'usage',
}

export interface Props {
  usage: string;
  title: string;
  selector: string;
}

const MetricsRow = ({ usage, title, selector }: Props) => {
  return (
    <div className={appCardMetricsRowClassname} data-testid={selector}>
      <Body data-testid={TestSelector.Title} className={`${appCardMetricsRowClassname}-text`}>
        {title}
      </Body>
      <Body data-testid={TestSelector.Usage} className={`${appCardMetricsRowClassname}-bold`}>
        {usage}
      </Body>
    </div>
  );
};

export default MetricsRow;
