export type SyncSchemaChangeErrorResponse = {
  combinedType: SchemaModificationType;
  updates: SyncSchemaChange[];
};

// SyncSchemaAction represents the possible types of schema changes
// This maps to SchemaAction in the backend
// https://github.com/10gen/baas/blob/debcccfa74dbe872f8b710cc09422509ed668126/devicesync/models/modifications.go#L579
export enum SyncSchemaAction {
  // ActionTableAdded indicates a table was added
  ActionTableAdded = 'TABLE_ADDED',
  // ActionTableRemoved indicates a table was removed
  ActionTableRemoved = 'TABLE_REMOVED',
  // ActionTableChanged indicates a table was changed e.g. its title
  ActionTableChanged = 'TABLE_CHANGED',
  // ActionPropertyAdded indicates a property was added to a table
  ActionPropertyAdded = 'PROPERTY_ADDED',
  // ActionPropertyRemoved indicates a property was removed from a table
  ActionPropertyRemoved = 'PROPERTY_REMOVED',
  // ActionPropertyOptionalityChanged indicates a property's optionality changed
  ActionPropertyOptionalityChanged = 'PROPERTY_OPTIONALITY_CHANGED',
  // ActionPropertyTypeChanged indicates a property's type changed
  ActionPropertyTypeChanged = 'PROPERTY_TYPE_CHANGED',
  // ActionPropertyLinkChanged indicates a property's link changed
  ActionPropertyLinkChanged = 'PROPERTY_LINK_CHANGED',
  // ActionIQFAdded indicates that the indexed queryable field was added to an existing table
  ActionIQFAdded = 'IQF_ADDED',
}

// SchemaModificationType represents the category of change for a SyncSchemaAction (Breaking, Supported, Additive)
// This maps to SchemaModificationType in the backend
// https://github.com/10gen/baas/blob/debcccfa74dbe872f8b710cc09422509ed668126/devicesync/models/modifications.go#L515
export enum SchemaModificationType {
  Noop = 'NOOP',
  Additive = 'ADDITIVE',
  Versioning = 'VERSIONING',
  Breaking = 'BREAKING',
}

// SyncNoteType represents the assigned severity of a note
// This maps to NoteType in the backend
// https://github.com/10gen/baas/blob/debcccfa74dbe872f8b710cc09422509ed668126/devicesync/models/modifications.go#L601
export enum SyncNoteType {
  Benign = 'BENIGN',
  Warn = 'WARN',
  Danger = 'DANGER',
}

export type SyncSchemaChange = {
  action: SyncSchemaAction;
  type: SchemaModificationType;
  table: string;
  field?: string;
  description: string;
  note?: string;
  noteType?: SyncNoteType;
};

export function SchemaModificationTypeToInt(type: SchemaModificationType): number {
  switch (type) {
    case SchemaModificationType.Noop:
      return 0;
    case SchemaModificationType.Additive:
      return 1;
    case SchemaModificationType.Versioning:
      return 2;
    case SchemaModificationType.Breaking:
      return 3;
    default:
      return -1;
  }
}
