import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from 'baas-ui/common/constants';
import { regionOptionNames } from 'baas-ui/home/constants';
import { CloudProvider } from 'baas-ui/home/types';
import { AsyncDispatchPayload } from 'baas-ui/redux_util';
import { AtlasCluster, DeploymentModel, ProviderRegion } from 'admin-sdk';

export const DEFAULT_RETRY_BACKOFF_IN_MILLISECONDS = 3000;
export const DEFAULT_RETRY_ATTEMPTS = 3;

export const prettyJSONStringify = (x: any) => JSON.stringify(x, null, 2);

export const createDownloadFromBlob = (blob: Blob, fileName: string, elementClassName?: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  let documentElement;
  if (elementClassName) {
    // In certain cases (such as when a modal is being rendered) the anchor element is not
    // accessible if it's appended to the document body. For this edge case, provide the optional
    // elementClassName parameter to append the anchor element to something else that's accessible.
    documentElement = document.getElementsByClassName(elementClassName)[0];
  } else {
    documentElement = document.body;
  }
  documentElement.appendChild(a);
  a.href = url;
  a.download = fileName;
  a.click();

  // Release anchor and object url after one time use.
  // Delay till next tick so data isn't released prematurely for firefox.
  setTimeout(() => {
    documentElement.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};

export const downloadFile = (
  fetchFile: () => Promise<Response | void>,
  filename: string,
  elementClassName?: string
) => {
  fetchFile()
    .then((response: Response) => response.blob())
    .then((blob: Blob) => createDownloadFromBlob(blob, filename, elementClassName));
};

export const extractFilenameFromHeader = (contentDisposition: string | null, defaultFilename: string): string => {
  if (contentDisposition?.includes('filename=')) {
    return contentDisposition.split('filename=')[1].replace(/^"(.*)"$/, '$1');
  }

  return defaultFilename;
};

export const clusterIsFlexCluster = (cluster: AtlasCluster) => cluster.providerSettings?.providerName === 'FLEX';

export const clusterIsServerlessInstance = (cluster: AtlasCluster) => {
  return cluster.providerSettings?.providerName === 'SERVERLESS';
};

export const clusterIsFreeTier = (cluster: AtlasCluster) => {
  return (
    cluster.replicationSpecs &&
    cluster.replicationSpecs[0]?.regionConfigs &&
    cluster.replicationSpecs[0].regionConfigs[0]?.electableSpecs?.instanceSize === 'M0'
  );
};

// from stitch-ui-toolbox; filter out props so that only data-* props get passed through
export const passThroughProps = (props: Record<string, any>) =>
  Object.keys(props).reduce((obj, key) => {
    if (key.startsWith('data-')) {
      obj[key] = props[key];
    }
    return obj;
  }, {});

// delay helper function for setTimeout
const delay = async (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

// retryAsyncFunction retries the function passed in until retryAttempts are met or until a success
// retryBackOff is the time in milliseconds until the next attempt is made
export const retryAsyncFunction =
  <T>(
    retryFn: (...args: any[]) => AsyncDispatchPayload<T>,
    retryAttempts: number = DEFAULT_RETRY_ATTEMPTS,
    retryBackOff: number = DEFAULT_RETRY_BACKOFF_IN_MILLISECONDS
  ) =>
  // eslint-disable-next-line consistent-return
  async (...args: any[]): Promise<void | T> => {
    for (let i = 0; i < retryAttempts; i++) {
      /* eslint-disable no-await-in-loop */
      try {
        const result = await retryFn(...args);
        return Promise.resolve(result);
      } catch (err) {
        if (i + 1 === retryAttempts) {
          return Promise.reject(err);
        }
        await delay(retryBackOff);
      }
    }
  };

// dateTimeDisplay takes optionally a unix time (seconds since the Unix Epoch), optionally a format string,
// optionally a time zone offset, and optionally a timeZoneDisplay.
// It returns a formatted date time (which takes into account the optional offset and timeZone) or a default placeholder
export const dateTimeDisplay = ({
  time,
  format = DEFAULT_DATE_FORMAT,
  timeZoneOffset = 0,
  timeZoneDisplay = '',
}: {
  time?: number;
  format?: string;
  timeZoneOffset?: number;
  timeZoneDisplay?: string;
}): string => {
  if (!time) return '--';

  const dateTime = moment.unix(time).utc().utcOffset(timeZoneOffset).format(format);

  return timeZoneDisplay ? `${dateTime} ${timeZoneDisplay}` : dateTime;
};

// clusterTime takes an optional Date and offset and returns a formatted date time or a placeholder
export const clusterTime = ({
  time,
  timeZoneOffset = 0,
  timeZoneDisplay = '',
}: {
  time?: Date;
  timeZoneOffset?: number;
  timeZoneDisplay?: string;
}) => dateTimeDisplay({ time: time ? time.getTime() / 1000 : 0, timeZoneOffset, timeZoneDisplay });

// getCloudProviderFromProviderRegion takes a providerRegion and returns the cloud provider
export const getCloudProviderFromProviderRegion = (providerRegion: string = CloudProvider.AWS) =>
  providerRegion.slice(0, providerRegion.indexOf('-')) as CloudProvider;

// deploymentModelLabel converts DeploymentModel to how we want to present it in the application
export const deploymentModelLabel = (deploymentModel: DeploymentModel) =>
  deploymentModel === DeploymentModel.Global ? 'Global' : 'Single Region';

// applicationDeploymentMetadataLabel takes DeploymentModel and ProviderRegion and converts to how we want to present
// application deployment metadata in the application
export const applicationDeploymentMetadataLabel = (deploymentModel: DeploymentModel, providerRegion: ProviderRegion) =>
  `${regionOptionNames[providerRegion]} • ${deploymentModelLabel(
    deploymentModel
  )} • ${getCloudProviderFromProviderRegion(providerRegion).toUpperCase()}`;

// applicationDeploymentLabel takes ProviderRegion and outputs a provider • region label
export const applicationDeploymentLabel = (providerRegion: ProviderRegion) =>
  `${getCloudProviderFromProviderRegion(providerRegion).toUpperCase()} • ${regionOptionNames[providerRegion]}`;

export const generateDefaultResourceName = (existingNames: string[], namePrefix: string, separator = '-') => {
  const filteredApps = existingNames.filter((name) => {
    return RegExp(`^${namePrefix}${separator}\\d{1,}$`).test(name);
  });
  const highest = filteredApps.reduce((max, name) => {
    const nameNumber = parseInt(name.substring(name.lastIndexOf(separator) + 1), 10);
    return Math.max(max, nameNumber);
  }, -1);
  return `${namePrefix}${separator}${highest + 1}`;
};

// Treats input lists as a list of sets and returns a list containing the elements in A OR B
// NOTE: This function uses sets and therefore checks for equality based on the SameValueZero algorithm
export const listSetUnion = <T>(...lists: T[][]): T[] => {
  const res: Set<T> = new Set();
  for (let i = 0; i < lists.length; i++) {
    for (let j = 0; j < lists[i].length; j++) {
      res.add(lists[i][j]);
    }
  }

  return [...res];
};

// Treats input lists as a list of sets and returns a list containing the elements in A, not in B
// NOTE: This function uses sets and therefore checks for equality based on the SameValueZero algorithm
export const listSetDiff = <T>(listA: T[], listB: T[]): T[] => {
  return listA.filter((elem) => !listB.includes(elem));
};

// Treats input lists as a list of sets and returns a list containing the elements in A AND B
// NOTE: This function uses sets and therefore checks for equality based on the SameValueZero algorithm
export const listSetIntersection = <T>(listA: T[], listB: T[]): T[] => {
  return listA.filter((value) => listB.includes(value));
};
