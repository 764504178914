import { createReducer } from 'redux-act';

import * as actions from './actions';

export interface SessionState {
  loginError?: string;
  authProviders: {};
}

export const defaultState: SessionState = {
  loginError: undefined,
  authProviders: {},
};

const sessionReducer = createReducer<SessionState>({}, defaultState);

sessionReducer.on(actions.loginActions.req, (state) => ({
  ...state,
  loginError: undefined,
}));

sessionReducer.on(actions.loginActions.fail, (state, payload) => ({
  ...state,
  loginError: payload.error,
}));

sessionReducer.on(actions.loginCloudActions.req, (state) => ({
  ...state,
  loginError: undefined,
}));

sessionReducer.on(actions.loginCloudActions.fail, (state, payload) => ({
  ...state,
  loginError: payload.error,
}));

sessionReducer.on(actions.setLoginErrorAction, (state, payload) => ({
  ...state,
  loginError: payload.error,
}));

sessionReducer.on(actions.loadRootAuthProvidersActions.rcv, (state, { payload }) => ({
  ...state,
  authProviders: payload.reduce((providers, provider) => {
    providers[provider.type] = !provider.disabled;
    return providers;
  }, {}),
}));

export default sessionReducer;
