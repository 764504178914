import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadProviderRegions } from 'baas-ui/app/actions';
import { retryAsyncFunction } from 'baas-ui/common/utils/util';
import { asResponseError } from 'baas-ui/error_util';
import { RegionOptionTypes } from 'baas-ui/home/types';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { ProviderRegionData } from 'admin-sdk';

type RegionsByProviderByDeployment = Record<string, Record<string, RegionOptionTypes[]>>;

interface UseProviderRegionPayload {
  providerRegions: ProviderRegionData[];
  regionsByProviderByDeployment: RegionsByProviderByDeployment;
  loading: boolean;
  error: string;
}

// build map of region opts keyed by deployment, then by cloud provider
export const getOptsByCloudProviderByDeployment = (prs: ProviderRegionData[]): RegionsByProviderByDeployment => {
  const opts = {};
  prs.forEach(({ name, id, country, deploymentModels: supportedDeploymentModels, cloudProvider }) => {
    supportedDeploymentModels.forEach((dm) => {
      if (!opts[dm]) {
        opts[dm] = {};
      }
      if (!opts[dm][cloudProvider]) {
        opts[dm][cloudProvider] = [];
      }
      opts[dm][cloudProvider].push({ label: name, value: id, country });
    });
  });
  return opts;
};

const useProviderRegions = (): UseProviderRegionPayload => {
  const [providerRegions, setProviderRegions] = useState<ProviderRegionData[]>([]);
  const [regionsByProviderByDeployment, setRegionsByProviderByDeployment] = useState<RegionsByProviderByDeployment>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const dispatch = useDispatch<AsyncDispatch>();

  useEffect(() => {
    let isActive = true;
    setLoading(true);
    retryAsyncFunction<ProviderRegionData[]>(() => dispatch(loadProviderRegions()), 3, 1000)()
      .then((prs) => {
        if (!prs || !isActive) {
          return;
        }
        setProviderRegions(prs);
        setRegionsByProviderByDeployment(getOptsByCloudProviderByDeployment(prs));
      })
      .catch((err) => {
        const responseError = asResponseError(err);
        if (isActive) {
          setError(responseError.message);
        }
      })
      .finally(() => {
        if (isActive) {
          setLoading(false);
        }
      });

    return () => {
      isActive = false;
    };
  }, []);

  return { providerRegions, regionsByProviderByDeployment, loading, error };
};

export default useProviderRegions;
