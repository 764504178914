import React from 'react';
import Code from '@leafygreen-ui/code';

import { DraftDiff } from 'admin-sdk';

export enum TestSelector {
  ApplicationDiff = 'application-diff',
  ApplicationDiffTitle = 'application-diff-title',
}

export interface PublicProps {
  applicationDiff: DraftDiff['diffs'];
  deploymentName?: string;
}

const ApplicationDiff = ({ deploymentName = 'Application', applicationDiff }: PublicProps) => {
  return (
    <div className="deployment-diff" data-testid={TestSelector.ApplicationDiff}>
      <div className="deployment-diff-title" data-testid={TestSelector.ApplicationDiffTitle}>
        {deploymentName}
      </div>
      <Code className="deployment-diff-code-block" language="diff" copyable={false}>
        {applicationDiff.join('') || 'N/A'}
      </Code>
    </div>
  );
};

export default ApplicationDiff;
