import React from 'react';
import { useSelector } from 'react-redux';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';

import { SaveButton } from 'baas-ui/common/components/form-row';
import { useRulesPageContext } from 'baas-ui/rules/useRulesPageContext';
import { RootState } from 'baas-ui/types';

import './save-footer.less';

export enum TestSelector {
  Container = 'save-footer-container',
  SaveButtonC = 'save-button',
  CancelButton = 'cancel-button',
  ErrorBanner = 'error-banner',
}

export interface Props {
  namePrefix: string;
  onCancel: () => void;
  footerBanner?: React.ReactNode;
}

const baseClassName = 'save-footer';

export const SaveFooter = ({ namePrefix, onCancel, footerBanner }: Props) => {
  const uiDraftsDisabled = useSelector((state: RootState) => state.deployment.deployConfig.uiDraftsDisabled);
  const { isSavingRule, hasChanges, hasClientValidationError, pristineRule, ruleSaveError, saveRule } =
    useRulesPageContext();

  return (
    <div className={baseClassName} data-testid={TestSelector.Container}>
      {!!ruleSaveError && (
        <Banner
          variant={Variant.Danger}
          className={`${baseClassName}-error-banner`}
          data-test-selector={TestSelector.ErrorBanner}
        >
          {ruleSaveError}
        </Banner>
      )}
      {footerBanner}
      {hasChanges && (
        <Button
          onClick={onCancel}
          className={`${baseClassName}-button`}
          data-test-selector={TestSelector.CancelButton}
          data-cy={`footer-${TestSelector.CancelButton}`}
        >
          Cancel
        </Button>
      )}
      <SaveButton
        disabled={(!hasChanges && !!pristineRule) || hasClientValidationError}
        namePrefix={namePrefix}
        saving={isSavingRule}
        isDraft={!uiDraftsDisabled}
        isDirty={hasChanges}
        editing={!!pristineRule}
        onSave={saveRule}
        className={`${baseClassName}-button`}
        data-test-selector={TestSelector.SaveButtonC}
        data-cy={`${namePrefix}-footer-${TestSelector.SaveButtonC}`}
      />
    </div>
  );
};

export default SaveFooter;
