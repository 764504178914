import React, { useEffect, useState } from 'react';
import Button, { Size } from '@leafygreen-ui/button';
import QuestionMarkWithCircleIcon from '@leafygreen-ui/icon/dist/QuestionMarkWithCircle';
import XIcon from '@leafygreen-ui/icon/dist/X';
import IconButton from '@leafygreen-ui/icon-button';
import classNames from 'classnames';

import { passThroughProps } from 'baas-ui/common/utils/util';

import StatusAgent from './StatusAgent';

import './status-banner.less';

const baseClassName = 'status-banner';

export enum Variant {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export interface Props {
  action?(): void;
  actionLabel?: React.ReactNode;
  className?: string;
  clearable?: boolean;
  disabled?: boolean;
  dismissable?: boolean;
  displayTimeMillis?: number;
  helpUrl?: string;
  message: React.ReactNode;
  onClear?(): void;
  onHelp?(): void;
  secondaryAction?(): void;
  secondaryActionLabel?: React.ReactNode;
  statusId: string;
  statusType?: string;
  variant: Variant;
}

export enum TestSelector {
  ActionButton = 'action-button',
  DismissButton = 'dismiss-button',
  HelpIconButton = 'help-icon-button',
  SecondaryActionButton = 'secondary-action-button',
  StatusAgentC = 'status-agent',
  ClearBannerIconButton = 'clear-banner-icon-button',
}

const StatusBanner = ({
  action = () => {},
  actionLabel,
  className = '',
  clearable = false,
  disabled = false,
  dismissable = false,
  displayTimeMillis = 0,
  variant = Variant.Info,
  helpUrl = '',
  message,
  onClear = () => {},
  onHelp = () => {},
  secondaryAction = () => {},
  secondaryActionLabel,
  statusId,
  statusType,
  ...rest
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [timeoutID, setTimeoutID] = useState<number | null>(null);

  useEffect(() => {
    if (displayTimeMillis) {
      setTimeoutID(
        window.setTimeout(() => {
          setIsOpen(false);
          onClear();
        }, displayTimeMillis)
      );
    }
    return () => {
      timeoutID && clearTimeout(timeoutID);
    };
  }, []);

  const allClassNames: string = classNames(className, {
    [`${baseClassName}-is-warning`]: variant === Variant.Warning,
    [`${baseClassName}-is-error`]: variant === Variant.Error,
    [`${baseClassName}-is-info`]: variant === Variant.Info,
    [`${baseClassName}-is-success`]: variant === Variant.Success,
  });

  if (!isOpen) {
    return null;
  }

  return (
    <StatusAgent
      data-testid={TestSelector.StatusAgentC}
      statusId={statusId}
      statusType={statusType}
      className={allClassNames}
      message={message}
      messageAddOn={
        <div className={`${baseClassName}-control-button-container`}>
          {secondaryActionLabel && (
            <Button
              size={Size.XSmall}
              onClick={secondaryAction}
              disabled={disabled}
              data-testid={TestSelector.SecondaryActionButton}
              className={`${baseClassName}-action-button`}
            >
              {secondaryActionLabel}
            </Button>
          )}
          {actionLabel && (
            <Button
              size={Size.XSmall}
              onClick={action}
              disabled={disabled}
              data-testid={TestSelector.ActionButton}
              className={`${baseClassName}-action-button`}
            >
              {actionLabel}
            </Button>
          )}
          {dismissable && (
            <Button
              size={Size.XSmall}
              onClick={onClear}
              disabled={disabled}
              data-testid={TestSelector.DismissButton}
              className={`${baseClassName}-action-button`}
            >
              Dismiss
            </Button>
          )}
        </div>
      }
      controls={
        <>
          {helpUrl && (
            <IconButton
              aria-label="Help Button"
              className={`${baseClassName}-help`}
              data-testid={TestSelector.HelpIconButton}
              href={helpUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onHelp}
            >
              <QuestionMarkWithCircleIcon />
            </IconButton>
          )}
          {clearable && (
            <IconButton
              data-testid={TestSelector.ClearBannerIconButton}
              data-cy={TestSelector.ClearBannerIconButton}
              aria-label="Dismiss Button"
              className={`${baseClassName}-close`}
              onClick={onClear}
            >
              <XIcon />
            </IconButton>
          )}
        </>
      }
      {...passThroughProps(rest)}
    />
  );
};

export default StatusBanner;
