import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import Toast, { Variant } from '@leafygreen-ui/toast';

import { SnippetsContext } from 'baas-ui/common/context/SnippetsContext';
import { ZIndex } from 'baas-ui/common/styles/zIndex';

const StyledToast = styled(Toast)`
  z-index: ${ZIndex.Toast};
`;

export const TestSelector = {
  InsertedSnippet: 'inserted-snippet-toast',
};

export const InsertedSnippetToast: React.FC = () => {
  const { inserted, setInserted } = useContext(SnippetsContext);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setInserted(false);
    }, 3000); // using 3 seconds based on design feedback

    return () => clearTimeout(timeoutId);
  }, [inserted]);

  return (
    <StyledToast
      data-testid={TestSelector.InsertedSnippet}
      open={inserted}
      variant={Variant.Success}
      body="Code snippet was successfully inserted."
    />
  );
};
