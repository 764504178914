import { DEFAULT_RAW_TERMINATED_QUERY_SYNC_CONFIG, DEFAULT_RAW_TERMINATED_SYNC_CONFIG } from 'baas-ui/sync/constants';
import { fromRawPartitionSyncConfig, fromRawQuerySyncConfig, ServiceSyncConfig } from 'baas-ui/sync/types';
import { PartitionKeyType, SyncState } from 'admin-sdk';

import { Partition, SyncType } from './types';

/* eslint-disable @typescript-eslint/ban-types,camelcase */
export interface RawAppSyncData {
  service_id?: string;
  partition_fields?: Partition[];
}

export interface RawSyncConfig {
  state: SyncState;
  database_name: string;
  last_disabled?: number;
  client_max_offline_days?: number;
  is_recovery_mode_disabled?: boolean;
}

export interface RawPartitionSyncConfig extends RawSyncConfig {
  partition: {
    key: string;
    type?: PartitionKeyType;
    required?: boolean;
    permissions: RawPartitionSyncPermissions;
  };
}

export interface RawPartitionSyncPermissions {
  read: unknown;
  write: unknown;
}

export interface RawQuerySyncConfig extends RawSyncConfig {
  queryable_fields_names?: string[];
  collection_queryable_fields_names?: Record<string, string[]>;
  indexed_queryable_fields_names?: string[];
  asymmetric_tables?: string[];
  permissions?: string;
}

function getConfigSyncType(config: any): SyncType | undefined {
  if ('sync' in config) {
    return SyncType.Partition;
  }

  if ('flexible_sync' in config) {
    return SyncType.Flexible;
  }

  return undefined;
}

export function getSyncConfigFromServiceConfig(serviceConfig: any): ServiceSyncConfig {
  if (!serviceConfig || !serviceConfig.config) {
    return fromRawQuerySyncConfig(DEFAULT_RAW_TERMINATED_QUERY_SYNC_CONFIG);
  }

  const configSyncType = getConfigSyncType(serviceConfig.config);
  switch (configSyncType) {
    case SyncType.Flexible:
      return fromRawQuerySyncConfig(serviceConfig.config?.flexible_sync || DEFAULT_RAW_TERMINATED_QUERY_SYNC_CONFIG);
    case SyncType.Partition:
      return fromRawPartitionSyncConfig(serviceConfig.config?.sync || DEFAULT_RAW_TERMINATED_SYNC_CONFIG);
    default:
      return fromRawQuerySyncConfig(DEFAULT_RAW_TERMINATED_QUERY_SYNC_CONFIG);
  }
}

export interface RawClientSchemaAlert {
  error_code: string;
  error: string;
}

export interface RawClientSchema {
  service_id: string;
  rule_id: string;
  collection_display_name: string;
  model_name: string;
  import_statements: string[];
  schema: string;
  warnings: RawClientSchemaAlert[];
  error?: RawClientSchemaAlert;
}

export interface RawSyncProgress {
  progress: {
    [namespace: string]: {
      started_at: string;
      updated_at: string;
      error?: string;
      documents_completed?: number;
      total_documents?: number;
      complete?: boolean;
    };
  };
}

/* eslint-enable */
