import React from 'react';
import { Body, Subtitle } from '@leafygreen-ui/typography';

import { formatDiffValue } from 'baas-ui/deploy/confirm-deployment-diff/utils';
import { SchemaOptionsDiff } from 'admin-sdk';

const nullTypeSchemaValidationFieldName = 'Null Type Schema Validation';

export enum TestSelector {
  Diffs = 'diffs',
  PreviousValue = 'null-type-schema-validation-prev-value',
  UpdatedValue = 'null-type-schema-validation-updated-value',
}

export interface Props {
  diff: SchemaOptionsDiff['nullTypeSchemaValidationDiff'];
}

export default function NullTypeSchemaValidationDiff({ diff }: Props) {
  if (!diff?.field) {
    return null;
  }

  return (
    <div data-testid={TestSelector.Diffs} className="deployment-diff">
      <Subtitle className="deployment-diff-title">Null Type Schema Validation Setting</Subtitle>
      <div className="deployment-diff-content">
        <div className="deployment-diff-null-type-schema-validation-field" key={diff.field}>
          <Body
            data-testid={TestSelector.PreviousValue}
            className="deployment-diff-null-type-schema-validation-previous"
          >
            {`− ${nullTypeSchemaValidationFieldName}: ${formatDiffValue(diff.previousValue)}`}
          </Body>
          <Body data-testid={TestSelector.UpdatedValue}>{`+ ${nullTypeSchemaValidationFieldName}: ${formatDiffValue(
            diff.updatedValue
          )}`}</Body>
        </div>
      </div>
    </div>
  );
}
