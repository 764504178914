import AWSRule from 'baas-ui/services/aws/rule';
import { DefaultRulesState, makeReducerForRuleClass } from 'baas-ui/services/default/reducers';

import awsActions from './actions';

export const defaultState: DefaultRulesState<AWSRule> = {
  loadingRules: false,
  error: undefined,
  ruleSaveState: {},
  rulesById: {},
  pristineRules: {},
};

const awsReducer = makeReducerForRuleClass(AWSRule, awsActions, defaultState);

awsReducer.on(awsActions.addAction, (state, { ruleId, value }) => {
  const rule = state.rulesById[ruleId];

  return {
    ...state,
    rulesById: { ...state.rulesById, [ruleId]: rule.addAction(value) },
  };
});

awsReducer.on(awsActions.deleteAction, (state, { ruleId, index }) => {
  const rule = state.rulesById[ruleId];

  return {
    ...state,
    rulesById: { ...state.rulesById, [ruleId]: rule.deleteAction(index) },
  };
});

awsReducer.on(awsActions.setAPI, (state, { ruleId, value, index }) => {
  const rule = state.rulesById[ruleId];

  return {
    ...state,
    rulesById: { ...state.rulesById, [ruleId]: rule.setApi(value, index) },
  };
});

awsReducer.on(awsActions.setAPIAction, (state, { ruleId, value, index }) => {
  const rule = state.rulesById[ruleId];

  return {
    ...state,
    rulesById: { ...state.rulesById, [ruleId]: rule.setApiAction(value, index) },
  };
});

export default awsReducer;
