import React, { useState } from 'react';

import { StatusBanner, StatusBannerProps, Variant } from 'baas-ui/common/components/status';
import { dateTimeDisplay } from 'baas-ui/common/utils/util';
import { STATUS_TYPE_DEPLOYMENT, statusMessagesByDeployStatus } from 'baas-ui/deploy/constants';
import { DeployStatus, useDeployContext } from 'baas-ui/deploy/deploy-context';

import './deploy-status-banner.less';

export enum TestSelector {
  MainBanner = 'main-banner',
  WarningBanner = 'warning-banner',
}

const getBannerVariantFromStatus = (status: DeployStatus): Variant => {
  switch (status) {
    case DeployStatus.Creating:
    case DeployStatus.Drafting:
    case DeployStatus.Deploying:
    case DeployStatus.Discarding:
      return Variant.Info;
    case DeployStatus.Error:
    case DeployStatus.Failure:
      return Variant.Error;
    case DeployStatus.Successful:
    case DeployStatus.SuccessfulWithWarning:
      return Variant.Success;
    default:
      return Variant.Info;
  }
};

const isBannerClearable = (status: DeployStatus): boolean =>
  status === DeployStatus.Successful || status === DeployStatus.SuccessfulWithWarning;

export default function DeployStatusBanner() {
  const { openDeployDraftModal, status, message, deployedAt, disableBanner } = useDeployContext();
  const [bannerVariant, setBannerVariant] = useState(getBannerVariantFromStatus(status));
  const [isClearable, setIsClearable] = useState(isBannerClearable(status));
  const [isDismissed, setIsDismissed] = useState(false);
  const [isWarningDismissed, setIsWarningDismissed] = useState(false);

  React.useEffect(() => {
    if (status !== DeployStatus.Successful && status !== DeployStatus.SuccessfulWithWarning) {
      setIsDismissed(false);
      setIsWarningDismissed(false);
    }
    setBannerVariant(getBannerVariantFromStatus(status));
    setIsClearable(isBannerClearable(status));
  }, [status]);

  let fullMessage: React.ReactNode;
  if (status === DeployStatus.Successful || status === DeployStatus.SuccessfulWithWarning) {
    fullMessage = (
      <div className="deploy-status-banner-message">
        {dateTimeDisplay({ time: deployedAt })} <b>{statusMessagesByDeployStatus[DeployStatus.Successful]}</b>
      </div>
    );
  } else if (status === DeployStatus.Creating || status === DeployStatus.Failure || status === DeployStatus.Error) {
    fullMessage = (
      <div className="deploy-status-banner-message">
        <b>{statusMessagesByDeployStatus[status]}</b>
        {message}
      </div>
    );
  } else {
    fullMessage = <div className="deploy-status-banner-message">{statusMessagesByDeployStatus[status]}</div>;
  }

  const bannerProps: StatusBannerProps = {
    statusId: 'deploy-status-banner',
    statusType: STATUS_TYPE_DEPLOYMENT,
    message: fullMessage,
    onClear: () => setIsDismissed(true),
    variant: bannerVariant,
    clearable: isClearable,
  };

  if (status === DeployStatus.Drafting || status === DeployStatus.Error || status === DeployStatus.Failure) {
    bannerProps.action = openDeployDraftModal;
    bannerProps.actionLabel = 'Review Draft & Deploy';
    bannerProps.disabled = disableBanner;
  }

  const warningBannerProps: StatusBannerProps = {
    statusId: 'deploy-status-banner-warning',
    statusType: STATUS_TYPE_DEPLOYMENT,
    message: (
      <div className="deploy-status-banner-message">
        <b>{statusMessagesByDeployStatus[status]}</b>
        {message}
      </div>
    ),
    onClear: () => setIsWarningDismissed(true),
    variant: Variant.Warning,
    clearable: true,
  };

  if (status === DeployStatus.None) {
    return null;
  }

  return (
    <>
      {!isDismissed && (
        <StatusBanner {...bannerProps} data-cy="deploy-status-banner" data-test-selector={TestSelector.MainBanner} />
      )}
      {status === DeployStatus.SuccessfulWithWarning && !isWarningDismissed && (
        <StatusBanner
          {...warningBannerProps}
          data-cy="deploy-status-banner-warning"
          data-test-selector={TestSelector.WarningBanner}
        />
      )}
    </>
  );
}
