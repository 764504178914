import { createReducer } from 'redux-act';

import * as actions from './actions';
import { DependenciesState } from './types';

export const defaultState: DependenciesState = {
  dependencies: [],
  loadingDependencies: false,
  dependenciesError: '',
  uploadingError: '',
  installedViaArchive: false,
  lastInstallStatus: undefined,
};

const dependencyReducer = createReducer<DependenciesState>({}, defaultState);

/* LOAD */
dependencyReducer.on(actions.loadDependenciesActions.req, (state) => ({
  ...state,
  loadingDependencies: true,
  dependenciesError: '',
}));
dependencyReducer.on(actions.loadDependenciesActions.rcv, (state, { payload }) => ({
  ...state,
  dependencies: payload.dependenciesList.sort(({ name: a }, { name: b }) => a.localeCompare(b)),
  installedViaArchive: !payload.installedViaPackageManager && payload.dependenciesList.length > 0,
  loadingDependencies: false,
}));
dependencyReducer.on(actions.loadDependenciesActions.fail, (state, { error }) => ({
  ...state,
  dependenciesError: error,
  loadingDependencies: false,
}));

/* UPLOAD */
dependencyReducer.on(actions.uploadDependenciesActions.req, (state) => ({
  ...state,
  uploadingError: '',
}));
dependencyReducer.on(actions.uploadDependenciesActions.fail, (state, { error }) => ({
  ...state,
  uploadingError: error,
}));

/* DELETE ONE */
// We don't listen to the .rcv action since deleting one dependency won't return anything on success
dependencyReducer.on(actions.deleteDependencyActions.req, (state) => ({
  ...state,
  dependenciesError: '',
}));
dependencyReducer.on(actions.deleteDependencyActions.fail, (state, { error }) => ({
  ...state,
  dependenciesError: error,
}));

/* DELETE ALL */
dependencyReducer.on(actions.deleteAllDependenciesActions.req, (state) => ({
  ...state,
  dependenciesError: '',
}));
dependencyReducer.on(actions.deleteAllDependenciesActions.rcv, (state) => ({
  ...state,
  dependencies: [],
}));
dependencyReducer.on(actions.deleteAllDependenciesActions.fail, (state, { error }) => ({
  ...state,
  dependenciesError: error,
}));

/* INSTALL STATUS */
dependencyReducer.on(actions.fetchInstallStatusActions.rcv, (state, { payload }) => ({
  ...state,
  lastInstallStatus: payload,
}));
dependencyReducer.on(actions.fetchInstallStatusActions.fail, (state) => ({
  ...state,
  lastInstallStatus: undefined,
}));

export default dependencyReducer;
