import React from 'react';
import Banner from '@leafygreen-ui/banner';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import { Option, OptionGroup } from '@leafygreen-ui/select';
import { Body, Link } from '@leafygreen-ui/typography';

import CreatableSelect from 'baas-ui/common/components/creatable-select/CreatableSelect';
import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import { DatabaseSelectOption } from 'baas-ui/sync/types';

import './enable-dev-mode-modal.less';

const baseClassName = 'enable-dev-mode-modal';

enum AriaLabel {
  DatabaseSelect = 'database',
}

export enum TestSelector {
  Modal = 'modal',
  Header = 'header',
  Description = 'description',
  DatabaseSelect = 'database-select',
  SchemaVersionWarning = 'schema-version-warning',
}

export interface Props {
  service: { id: string; name: string };
  open: boolean;
  dbName: string;
  databases: DatabaseSelectOption[];
  showSchemaVersioningWarning: boolean;
  onDatabaseChange(value: string): void;
  addNewDatabaseOption(value: string): void;
  onConfirm(): void;
  onCancel(): void;
}

const EnableDevModeModal = ({
  open,
  dbName,
  service,
  databases,
  showSchemaVersioningWarning,
  onDatabaseChange,
  addNewDatabaseOption,
  onConfirm,
  onCancel,
}: Props) => {
  const [databaseSelected, setDatabaseSelected] = React.useState(!!dbName);

  return (
    <ConfirmationModal
      className={baseClassName}
      open={open}
      confirmButtonProps={{
        children: 'OK',
        disabled: !databaseSelected,
        onClick: onConfirm,
      }}
      cancelButtonProps={{
        onClick: onCancel,
      }}
      title="Turning Development Mode ON"
      data-testid={TestSelector.Modal}
      data-cy={baseClassName}
    >
      <Banner className={`${baseClassName}-banner`} data-testid={TestSelector.Header}>
        Dev Mode disables drafts and enables anonymous authentication
      </Banner>
      {showSchemaVersioningWarning && (
        // TODO BAAS-25050: Update doc link
        <Banner
          className={`${baseClassName}-banner`}
          variant={'danger'}
          data-testid={TestSelector.SchemaVersionWarning}
        >
          Enabling dev mode will erase ALL current schema versions and reset the current schema version to 0. This has
          the potential to break clients using these schema versions. See the
          <Link href={docLinks.Sync.SyncMode}> Schema Versioning documentation</Link> for more info on this.
        </Banner>
      )}
      <Body className={`${baseClassName}-description`} data-testid={TestSelector.Description}>
        In Dev Mode, when adding new tables in your mobile app, a new collection will be created in MongoDB.{' '}
        <DocLink href={docLinks.Sync.MappingWithDevMode} showExternalIcon>
          Learn more
        </DocLink>
      </Body>
      <CreatableSelect
        id={AriaLabel.DatabaseSelect}
        aria-labelledby={AriaLabel.DatabaseSelect}
        allowDeselect
        usePortal={false}
        name="dev-mode-modal-database-select"
        data-cy="dev-mode-modal-database-select"
        className={`${baseClassName}-database-select`}
        placeholder="Select the database that new collections will be added to"
        createTextInputPlaceholder="Create a new database name"
        createNewOptionText="Add new database"
        onCreateNewValue={(value) => {
          onDatabaseChange(value);
          addNewDatabaseOption(value);
          setDatabaseSelected(true);
        }}
        onChange={(value) => {
          onDatabaseChange(value);
          setDatabaseSelected(true);
        }}
        value={dbName || ''}
        disabled={service.id === ''}
        isValidNewValue={(value) => !!value}
        data-testid={TestSelector.DatabaseSelect}
      >
        {!databases.some((dbOpt) => dbOpt.label === dbName) && dbName && <Option value={dbName}>{dbName}</Option>}
        <OptionGroup label="Existing Databases">
          {databases.map((opt) => (
            <Option value={opt.value} key={opt.value}>
              {opt.label}
            </Option>
          ))}
        </OptionGroup>
      </CreatableSelect>
    </ConfirmationModal>
  );
};

export default EnableDevModeModal;
