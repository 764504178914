import { createReducer } from 'redux-act';

import * as actions from './actions';
import { NavigationState } from './types';

export const defaultState = {
  organization: undefined,
  account: undefined,
  project: undefined,
};

const reducer = createReducer<NavigationState>({}, defaultState);

reducer.on(actions.setNavigation, (state, { account, currentOrganization, currentProject }) => ({
  ...state,
  account,
  organization: currentOrganization,
  project: currentProject,
}));

export default reducer;
