import React, { useContext, useState } from 'react';
import Icon from '@leafygreen-ui/icon';
import IconButton from '@leafygreen-ui/icon-button';
import { Menu, MenuItem } from '@leafygreen-ui/menu';
import classNames from 'classnames';

import { DatabaseMenuProps } from 'baas-ui/common/components/collection-explorer';
import { RulesPageContext } from 'baas-ui/rules/RulesPage';

import 'baas-ui/common/components/collection-explorer/collection-explorer/collection-explorer.less';

const collectionExplorerBaseClassName = 'collection-explorer';

export enum TestSelector {
  MenuTrigger = 'menu-trigger',
  CreateCollection = 'create-collection',
  DeleteRules = 'delete-rules',
}

export default function DatabaseActionMenu({ dataSourceName, databaseName, hasCollectionData }: DatabaseMenuProps) {
  const [open, setOpen] = useState(false);
  const { onClickCreateCollection, onClickDeleteAction, partialDataSources, deletingDatasources, deletingDatabases } =
    useContext(RulesPageContext);
  const dataSourceId = partialDataSources.filter((dataSource) => dataSource.name === dataSourceName)[0].id;

  const isDeletingFromCurrentDatabase = deletingDatabases.has(databaseName);
  const isDeletingFromCurrentDatasource = deletingDatasources.has(dataSourceId);
  const isDeletingOwnRules = isDeletingFromCurrentDatabase || isDeletingFromCurrentDatasource;
  const isDisabled = isDeletingOwnRules || !hasCollectionData;

  return (
    <Menu
      open={open}
      setOpen={setOpen}
      trigger={
        <IconButton
          className={classNames(`${collectionExplorerBaseClassName}-list-item__menu-trigger`, {
            [`${collectionExplorerBaseClassName}-list-item__menu-trigger-visible`]: open,
          })}
          aria-label="Database Options"
          data-cy="database-menu-trigger"
          data-testid={TestSelector.MenuTrigger}
        >
          <Icon glyph="Ellipsis" />
        </IconButton>
      }
    >
      <MenuItem
        data-testid={TestSelector.CreateCollection}
        data-cy="database-menu-create-collection"
        disabled={isDeletingOwnRules}
        onClick={() => {
          if (!isDeletingOwnRules) {
            onClickCreateCollection(dataSourceName, databaseName);
          }
        }}
      >
        Create a collection
      </MenuItem>
      <MenuItem
        className={classNames({
          'collection-explorer__menu-item--destructive': !isDisabled,
          'collection-explorer__menu-item--disabled': isDisabled,
        })}
        data-testid={TestSelector.DeleteRules}
        data-cy="database-menu-delete-rules"
        disabled={isDisabled}
        onClick={() => {
          if (hasCollectionData && !isDeletingOwnRules) {
            onClickDeleteAction({
              dataSourceName,
              databaseName,
              dataSourceId,
            });
          }
        }}
      >
        Delete roles and filters from all collections
      </MenuItem>
    </Menu>
  );
}
