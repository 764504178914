import React from 'react';
import { connect, useDispatch } from 'react-redux';
import Toast, { Variant } from '@leafygreen-ui/toast';

import { AsyncDispatch } from 'baas-ui/redux_util';
import { getSyncState } from 'baas-ui/selectors';
import { setSyncEnabledToastState } from 'baas-ui/sync/actions';
import { SyncEnabledToastState } from 'baas-ui/sync/types';
import { RootState } from 'baas-ui/types';

import './sync-enabled-toast.less';

export interface ReduxStateProps {
  toastStatus: SyncEnabledToastState;
}

export enum TestSelector {
  SyncEnabledToast = 'sync-enabled-toast',
}

const SyncEnabledToast = ({ toastStatus }: ReduxStateProps) => {
  const dispatch = useDispatch<AsyncDispatch>();
  return (
    <Toast
      className="sync-enabled-toast"
      data-test-selector={TestSelector.SyncEnabledToast}
      data-cy={TestSelector.SyncEnabledToast}
      variant={Variant.Success}
      title="Sync enabled"
      body=""
      open={toastStatus === SyncEnabledToastState.EnablingSucceeded}
      close={() => {
        dispatch(setSyncEnabledToastState(SyncEnabledToastState.None));
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const { syncEnabledToastStatus } = getSyncState(state);

  return {
    toastStatus: syncEnabledToastStatus,
  };
};

export { SyncEnabledToast as SyncEnabledToastComponent };

export default connect(mapStateToProps)(SyncEnabledToast);
