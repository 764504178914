import { List, Map, Set } from 'immutable';
import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';

import { Asset } from 'baas-ui/models';

import * as actions from './actions';
import INVALID_DOMAIN_ERROR_CODE from './constants';
import { isPathErrorCode } from './errors';

export const assetsDefaultState = Map({
  cache: Map({
    flushCacheError: undefined,
  }),
  fileBrowser: Map({
    loadingAsset: false,
    loadAssetError: undefined,
    loadingAssets: false,
    loadAssetsError: undefined,
    loadPresignError: undefined,
    pathError: undefined,
    currentRequestPrefix: '',
    uploadInProgress: false,
    filesInProgress: Set(),
    filesCompleted: 0,
    assets: List(),
    selectedAssets: List(),
  }),
  modal: Map({
    loadingAssets: false,
    loadAssetsError: undefined,
    pathError: undefined,
    currentRequestPrefix: '',
    assets: List(),
  }),
});

export const configDefaultState = Map({
  loadHostingConfigError: undefined,
  saveHostingConfigError: undefined,
  invalidDomainError: undefined,
  loadingHostingConfig: false,
  savingHostingConfig: false,
  customDomainEnabled: false,
  customDomainName: '',
  customDomainState: '',
  config: undefined,
  hostingEnabledAt: undefined,
});

const assetReducer = createReducer(
  {
    /* LOAD for multiple assets */
    [actions.loadAssetsActions.req]: (state, reqArgs) => {
      // If assets are loaded recursively, this action is not meant to update the store
      if (reqArgs.params.recursive) {
        return state;
      }

      const key = reqArgs.key;
      const prev = state.get(key);

      return state.merge(
        Map({
          [key]: prev.merge({
            loadAssetsError: undefined,
            pathError: undefined,
            loadingAssets: true,
            assets: new List(),
            currentRequestPrefix: reqArgs.params.prefix,
          }),
        })
      );
    },
    [actions.loadAssetsActions.rcv]: (state, { payload, reqArgs }) => {
      // If assets are loaded recursively, this action is not meant to update the store
      if (reqArgs.params.recursive) {
        return state;
      }

      const key = reqArgs.key;
      const prev = state.get(key);

      // If the action received is not the for the most recent request, ignore.
      // There is more up to date information incoming.
      const prefixArg = reqArgs.params.prefix;
      if (prefixArg !== prev.get('currentRequestPrefix')) {
        return state;
      }

      return state.merge(
        Map({
          [key]: prev.merge({
            loadingAssets: false,
            assets: new List(payload.map((asset) => Asset.fromRaw(asset))),
          }),
        })
      );
    },
    [actions.loadAssetsActions.fail]: (state, { reqArgs, error, rawError: { code } }) => {
      // If assets are loaded recursively, this action is not meant to update the store
      if (reqArgs.params.recursive) {
        return state;
      }

      const key = reqArgs.key;
      const prev = state.get(key);

      return state.merge(
        Map({
          [key]: prev.merge({
            loadAssetsError: error,
            pathError: isPathErrorCode(code) ? code : undefined,
            loadingAssets: false,
          }),
        })
      );
    },

    /* LOAD for single asset */
    [actions.loadAssetActions.req]: (state) =>
      state.merge(
        Map({
          fileBrowser: state.get('fileBrowser').merge({
            loadAssetError: undefined,
            loadingAsset: true,
          }),
        })
      ),
    [actions.loadAssetActions.rcv]: (state, { payload }) => {
      const prev = state.get('fileBrowser');
      const updatedAsset = Asset.fromRaw(payload);

      const oldAssets = prev.get('assets');
      const index = oldAssets.findIndex((asset) => asset.path === updatedAsset.path);

      if (index < 0) {
        return state.merge(
          Map({
            fileBrowser: prev.merge(
              Map({
                loadingAsset: false,
                assets: oldAssets.push(updatedAsset),
              })
            ),
          })
        );
      }

      const oldSelectedAssets = prev.get('selectedAssets');
      const saIndex = oldSelectedAssets.findIndex((asset) => asset.path === updatedAsset.path);

      return state.merge(
        Map({
          fileBrowser: prev.merge({
            loadingAsset: false,
            assets: oldAssets.set(index, updatedAsset),
            selectedAssets: saIndex > -1 ? oldSelectedAssets.set(saIndex, updatedAsset) : oldSelectedAssets,
          }),
        })
      );
    },
    [actions.loadAssetActions.fail]: (state, { error }) =>
      state.merge(
        Map({
          fileBrowser: state.get('fileBrowser').merge({
            loadAssetError: error,
            loadingAsset: false,
          }),
        })
      ),

    /* UPLOAD */
    [actions.setUploadStarted]: (state) => state.setIn(['fileBrowser', 'uploadInProgress'], true),
    [actions.setUploadFinished]: (state) => state.setIn(['fileBrowser', 'uploadInProgress'], false),
    [actions.uploadAssetActions.req]: (state, reqArgs) => {
      const path = reqArgs.metadata.path;
      return state.updateIn(['fileBrowser', 'filesInProgress'], (s) => s.add(path));
    },
    [actions.uploadAssetActions.rcv]: (state, { reqArgs }) => {
      const path = reqArgs.metadata.path;
      return state
        .updateIn(['fileBrowser', 'filesInProgress'], (s) => s.remove(path))
        .updateIn(['fileBrowser', 'filesCompleted'], (f) => f + 1);
    },
    [actions.uploadAssetActions.fail]: (state, { reqArgs }) => {
      const path = reqArgs.metadata.path;
      return state.updateIn(['fileBrowser', 'filesInProgress'], (s) => s.remove(path));
    },
    [actions.resetUploadState]: (state) =>
      state.merge(
        Map({
          fileBrowser: state.get('fileBrowser').merge(
            Map({
              filesInProgress: Set(),
              filesCompleted: 0,
              uploadInProgress: false,
            })
          ),
        })
      ),

    /* DELETE */
    [actions.deleteAssetActions.rcv]: (state, { reqArgs }) => {
      const prev = state.get('fileBrowser');
      const deletedPath = reqArgs.path;

      const oldAssets = prev.get('assets');
      const index = oldAssets.findIndex((asset) => asset.path === deletedPath);

      return state.merge(
        Map({
          fileBrowser: prev.merge({
            assets: oldAssets.delete(index),
          }),
        })
      );
    },

    /* CACHE */
    [actions.flushCacheActions.req]: (state) => state.deleteIn(['cache', 'flushCacheError']),
    [actions.flushCacheActions.fail]: (state, e) => state.setIn(['cache', 'flushCacheError'], e.error),

    /* PRESIGN */
    [actions.loadPresignURLActions.req]: (state) => state.set('loadPresignError', undefined),
    [actions.loadPresignURLActions.rcv]: (state) => state.set('loadPresignError', undefined),
    [actions.loadPresignURLActions.fail]: (state, { error }) => state.set('loadPresignError', error),

    /* SELECTING ASSETS */
    [actions.toggleSelect]: (state, { selected, e }) => {
      const prev = state.get('fileBrowser');
      const oldSelectedAssets = prev.get('selectedAssets');

      let selectedAssets;
      if (e.target.checked) {
        selectedAssets = oldSelectedAssets.push(selected);
      } else {
        const index = oldSelectedAssets.findIndex((asset) => asset.path === selected.path);
        selectedAssets = oldSelectedAssets.delete(index);
      }

      return state.merge(
        Map({
          fileBrowser: prev.merge({ selectedAssets }),
        })
      );
    },

    [actions.toggleSelectAll]: (state) => {
      const prev = state.get('fileBrowser');

      const assets = prev.get('assets');
      const selectedAssets = prev.get('selectedAssets');

      return state.merge(
        Map({
          fileBrowser: prev.merge({
            selectedAssets: selectedAssets.size === assets.size ? selectedAssets.clear() : new List(assets),
          }),
        })
      );
    },

    [actions.clearSelections]: (state) =>
      state.merge(
        Map({
          fileBrowser: state.get('fileBrowser').merge({
            selectedAssets: new List(),
          }),
        })
      ),
  },
  assetsDefaultState
);

const configReducer = createReducer(
  {
    [actions.loadHostingConfigActions.req]: (state) => {
      return state.merge(
        Map({
          loadingHostingConfig: true,
        })
      );
    },
    [actions.loadHostingConfigActions.fail]: (state, { error }) => {
      return state.merge(
        Map({
          loadHostingConfigError: `Failed to load hosting config: ${error}`,
          loadingHostingConfig: false,
        })
      );
    },
    [actions.loadHostingConfigActions.rcv]: (state, { payload }) => {
      if (payload?.defaultDomain?.config) {
        const customDomainEnabled = !!payload.defaultDomain.config.custom_domain;
        const customDomainName = payload.defaultDomain.config.custom_domain || '';
        const customDomainState = payload.defaultDomain.config.custom_domain_state || '';
        const errorFilePath = payload.defaultDomain.config.default_error_path || '';
        const defaultResponseCode = payload.defaultDomain.config.default_response_code || '';

        return state.merge(
          Map({
            loadHostingConfigError: undefined,
            invalidDomainError: undefined,
            loadingHostingConfig: false,
            config: payload,
            customDomainEnabled,
            customDomainName,
            customDomainState,
            errorFilePath,
            defaultResponseCode,
          })
        );
      }

      // Otherwise, this app doesn't have a hosting config yet
      return state.merge(
        Map({
          loadHostingConfigError: undefined,
          invalidDomainError: undefined,
          loadingHostingConfig: false,
        })
      );
    },
    [actions.setErrorFileConfig]: (state, { path, code }) => {
      return state.merge(
        Map({
          errorFilePath: path,
          defaultResponseCode: code,
        })
      );
    },
    [actions.updateHostingConfigActions.req]: (state) => {
      return state.merge(
        Map({
          savingHostingConfig: true,
        })
      );
    },
    [actions.updateHostingConfigActions.fail]: (state, { rawError }) => {
      if (rawError.code && rawError.code === INVALID_DOMAIN_ERROR_CODE) {
        return state.merge(
          Map({
            invalidDomainError: rawError.message,
            savingHostingConfig: false,
          })
        );
      }
      return state.merge(
        Map({
          saveHostingConfigError: rawError.message,
          savingHostingConfig: false,
        })
      );
    },
    [actions.updateHostingConfigActions.rcv]: (state) => {
      return state.merge(
        Map({
          loadHostingConfigError: undefined,
          saveHostingConfigError: undefined,
          invalidDomainError: undefined,
          savingHostingConfig: false,
          loadingHostingConfig: false,
        })
      );
    },
    [actions.setCustomDomainName]: (state, domainName) => {
      return state.merge(
        Map({
          customDomainName: domainName,
        })
      );
    },
    [actions.toggleCustomDomainEnabled]: (state) => {
      return state.merge(
        Map({
          customDomainEnabled: !state.get('customDomainEnabled'),
        })
      );
    },
    [actions.discardHostingConfigChanges]: (state) => {
      const pristineCustomDomainEnabled = !!state.get('config').defaultDomain.config.custom_domain;
      const pristineCustomDomainName = state.get('config').defaultDomain.config.custom_domain || '';
      const pristineErrorFilePath = state.get('config').defaultDomain.config.default_error_path || '';
      const pristineDefaultResponseCode = state.get('config').defaultDomain.config.default_response_code || '';
      return state.merge(
        Map({
          customDomainEnabled: pristineCustomDomainEnabled,
          customDomainName: pristineCustomDomainName,
          errorFilePath: pristineErrorFilePath,
          defaultResponseCode: pristineDefaultResponseCode,
        })
      );
    },
    [actions.setHostingEnabledAt]: (state, payload) => {
      return state.merge(
        Map({
          hostingEnabledAt: payload,
        })
      );
    },
  },
  configDefaultState
);

const hostingReducers = combineReducers({
  assets: assetReducer,
  config: configReducer,
});

export default hostingReducers;
