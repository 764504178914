import React from 'react';
import { createAgent } from 'react-through';
import classNames from 'classnames';

import { passThroughProps } from 'baas-ui/common/utils/util';
import { useDarkMode } from 'baas-ui/theme';

import { statusContainerAreaName, statusContainerBearingProp } from './constants';

import './status-agent.less';

const baseClassName = 'status-agent';

export enum TestSelector {
  ContainerAgent = 'container-agent',
}

export interface Props {
  className?: string;
  controls?: React.ReactNode;
  message?: React.ReactNode;
  messageAddOn?: React.ReactNode;
  statusId: string;
  statusType?: string;
}

const StatusContainerAgent = createAgent(
  statusContainerAreaName,
  statusContainerBearingProp
) as React.ComponentType<any>;

let lastUniqueId = 0;
const getId = (prefix: string) => `${prefix}-${++lastUniqueId}`;

const StatusAgent = (
  { statusType = '', statusId, className = '', message = '', messageAddOn, controls, ...rest }: Props,
  { through }
) => {
  const darkMode = useDarkMode();
  const allClassNames = classNames(`${baseClassName}-container-content`, className, {
    [`${className}--dark-mode`]: darkMode,
  });

  if (!!through || !message) {
    return null;
  }

  return (
    <StatusContainerAgent statusId={statusId} statusType={statusType} className={classNames}>
      <div data-testid={TestSelector.ContainerAgent} className={allClassNames} {...passThroughProps(rest)}>
        <div className={`${baseClassName}-container-content-message`}>
          {message && (
            <div data-cy="status-agent-message" id={getId(statusId)} className={`${baseClassName}-message`}>
              {message}
            </div>
          )}
          {messageAddOn}
        </div>
        <div className={`${baseClassName}-container-content-controls`}>{controls}</div>
      </div>
    </StatusContainerAgent>
  );
};

export default StatusAgent;
