import {
  CollectionData,
  DatabaseData,
  DataSourceData,
} from 'baas-ui/common/components/collection-explorer/collection-explorer/CollectionExplorer';
import { makeCollectionNamespace } from 'baas-ui/services/mongodb/util';
import { PartialMongoDBNamespaceRule, PartialServiceDesc } from 'admin-sdk';

import { NamespacesByClusterId, PartialRulesByDataSourceId, SyncIncompatibleRolesByDataSourceId } from './types';

export type RulesPageCollectionData = {
  dataSourceName: string;
  partialRule?: PartialMongoDBNamespaceRule;
  dataSourceId?: string;
  hasSyncIncompatibleRoles?: boolean;
};

export type RulesPageDataSourceData = DataSourceData<RulesPageCollectionData>;

export const makeDataSourceData = (
  dataSources: PartialServiceDesc[],
  namespaces: NamespacesByClusterId,
  partialRulesByDataSourceId: PartialRulesByDataSourceId,
  syncIncompatibleRolesByDataSourceId: SyncIncompatibleRolesByDataSourceId
): RulesPageDataSourceData[] => {
  // using objects for easier mapping
  const dataSourceMapping: {
    [key: string]: {
      dataSourceName: string;
      dataSourceId: string;
      hasCollectionData: boolean;
      databases: {
        [key: string]: {
          databaseName: string;
          hasCollectionData: boolean;
          collections: {
            [key: string]: {
              collectionName: string;
              data: RulesPageCollectionData;
            };
          };
        };
      };
    };
  } = {};

  // Initialize empty data sources so that they show up in
  // CollectionExplorer even if no namespaces or rules are available
  dataSources.forEach((dataSource) => {
    dataSourceMapping[dataSource.name] = {
      dataSourceName: dataSource.name,
      dataSourceId: dataSource.id,
      databases: {},
      hasCollectionData: false,
    };
  });

  if (partialRulesByDataSourceId) {
    Object.entries(partialRulesByDataSourceId).forEach(([dataSourceId, partialRules]) => {
      partialRules.forEach((partialRule) => {
        let database = '';
        let collection = '';

        if ((partialRule as PartialMongoDBNamespaceRule).database) {
          ({ database, collection } = partialRule as PartialMongoDBNamespaceRule);
        }

        if (!!database && !!collection) {
          const dataSourceName = dataSources.find(({ id }) => dataSourceId === id)?.name;
          if (!dataSourceName) {
            return;
          }

          const currentDataSource = dataSourceMapping[dataSourceName];
          currentDataSource.hasCollectionData = true;
          dataSourceMapping[dataSourceName] = currentDataSource;

          let currentDatabase = currentDataSource.databases[database];
          if (!currentDatabase) {
            currentDatabase = { databaseName: database, collections: {}, hasCollectionData: true };
          }
          currentDataSource.databases[database] = currentDatabase;

          let currentCollection = currentDatabase.collections[collection];
          if (!currentCollection) {
            let hasSyncIncompatibleRoles = false;

            const dataSourceSyncIncompatibleRoles = syncIncompatibleRolesByDataSourceId[dataSourceId];
            if (dataSourceSyncIncompatibleRoles && dataSourceSyncIncompatibleRoles.namespaceRoles) {
              hasSyncIncompatibleRoles = dataSourceSyncIncompatibleRoles.namespaceRoles.some((role) => {
                return role.database === database && role.collection === collection;
              });
            }

            currentCollection = {
              collectionName: collection,
              data: {
                dataSourceName,
                partialRule,
                dataSourceId,
                hasSyncIncompatibleRoles,
              },
            };
          }

          currentDatabase.collections[collection] = currentCollection;
        }
      });
    });
  }

  Object.entries(namespaces).forEach(([dataSourceId, namespace]) => {
    const dataSourceName = dataSources.find(({ id }) => dataSourceId === id)?.name;

    if (dataSourceName && namespace) {
      let currentDataSource = dataSourceMapping[dataSourceName];

      if (!currentDataSource) {
        currentDataSource = { dataSourceName, dataSourceId, databases: {}, hasCollectionData: false };
      }
      dataSourceMapping[dataSourceName] = currentDataSource;

      namespace.forEach(({ database, collections }) => {
        collections.forEach((collection) => {
          if (!currentDataSource) {
            currentDataSource = { dataSourceName, dataSourceId, databases: {}, hasCollectionData: false };
          }
          dataSourceMapping[dataSourceName] = currentDataSource;

          let currentDatabase = currentDataSource.databases[database];
          if (!currentDatabase) {
            currentDatabase = {
              databaseName: database,
              collections: {},
              hasCollectionData: false,
            };
          }
          currentDataSource.databases[database] = currentDatabase;

          let currentCollection = currentDatabase.collections[collection];
          if (!currentCollection) {
            let hasSyncIncompatibleRoles = false;

            const dataSourceSyncIncompatibleRoles = syncIncompatibleRolesByDataSourceId[dataSourceId];
            if (dataSourceSyncIncompatibleRoles && dataSourceSyncIncompatibleRoles.namespaceRoles) {
              hasSyncIncompatibleRoles = dataSourceSyncIncompatibleRoles.namespaceRoles.some((role) => {
                return role.database === database && role.collection === collection;
              });
            }

            currentCollection = {
              collectionName: collection,
              data: {
                dataSourceName,
                hasSyncIncompatibleRoles,
              },
            };
          }

          currentDatabase.collections[collection] = currentCollection;
        });
      });
    }
  });

  return Object.values(dataSourceMapping)
    .map<DataSourceData<RulesPageCollectionData>>((dataSourceData) => ({
      namespace: dataSourceData.dataSourceName,
      dataSourceName: dataSourceData.dataSourceName,
      dataSourceId: dataSourceData.dataSourceId,
      hasCollectionData: dataSourceData.hasCollectionData,
      databases: Object.values(dataSourceData.databases)
        .map<DatabaseData<RulesPageCollectionData>>((databaseData) => ({
          namespace: `${dataSourceData.dataSourceName}:${databaseData.databaseName}`,
          databaseName: databaseData.databaseName,
          hasCollectionData: databaseData.hasCollectionData,
          collections: Object.values(databaseData.collections)
            .map<CollectionData<RulesPageCollectionData>>((collectionData) => ({
              namespace: makeCollectionNamespace({
                dataSourceName: dataSourceData.dataSourceName,
                database: databaseData.databaseName,
                collection: collectionData.collectionName,
              }),
              collectionName: collectionData.collectionName,
              data: collectionData.data,
            }))
            .sort((a, b) => a.collectionName.localeCompare(b.collectionName)),
        }))
        .sort((a, b) => a.databaseName.localeCompare(b.databaseName)),
    }))
    .sort((a, b) => a.dataSourceName.localeCompare(b.dataSourceName));
};
