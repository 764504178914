import React from 'react';

import { passThroughProps } from 'baas-ui/common/utils/util';

interface Props {
  className?: string;
}

const RunFunctionIcon = ({ className = '', ...rest }: Props) => (
  <svg
    className={className}
    {...passThroughProps(rest)}
    width="20"
    height="20"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5269 7.13679C14.1884 7.52266 14.1884 8.47847 13.5269 8.86434L6.00388 13.2528C6.00388 13.2528 6.00388 13.2528 6.00388 13.2528C5.33721 13.6416 4.5 13.1608 4.5 12.389V3.61212C4.5 2.84033 5.33721 2.35946 6.00388 2.74834L13.5269 7.13679Z"
      stroke="#001E2B"
    />
  </svg>
);

export default RunFunctionIcon;
