import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Icon from '@leafygreen-ui/icon';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SpinnerCircle } from 'baas-ui/common/components/spinner';
import { DEFAULT_SAVE_ERROR_MESSAGE, DEFAULT_SAVE_STATUS_TIMEOUT } from 'baas-ui/common/constants';
import { passThroughProps } from 'baas-ui/common/utils/util';

import './save-status.less';

export interface Props {
  saving?: boolean;
  saveError?: string;
  displayTimeMillis?: number;
  className?: string;
  saveErrorMessage?: string;
  hasValidationError?: boolean;
  isDraft?: boolean;
}

interface State {
  showStatusSuccess: boolean;
  showStatusError: boolean;
  skipExitAnimation: boolean;
  timeoutID?: number;
}

export enum TestSelector {
  SaveStatusContainer = 'save-status-container',
  ErrorMessage = 'error-message',
  SavedMessage = 'saved-message',
  SpinnerCircleIcon = 'spinner-circle-icon',
}

const baseClassName = 'save-status';

export default class SaveStatus extends React.Component<Props, State> {
  static propTypes = {
    className: PropTypes.string,
    displayTimeMillis: PropTypes.number,
    saveError: PropTypes.string,
    hasValidationError: PropTypes.bool,
    saveErrorMessage: PropTypes.string,
    saving: PropTypes.bool,
    isDraft: PropTypes.bool,
  };

  static defaultProps: Props = {
    saving: false,
    className: '',
    displayTimeMillis: DEFAULT_SAVE_STATUS_TIMEOUT,
    saveError: '',
    saveErrorMessage: DEFAULT_SAVE_ERROR_MESSAGE,
    hasValidationError: false,
    isDraft: false,
  };

  state = {
    showStatusSuccess: false,
    showStatusError: false,
    skipExitAnimation: false,
    timeoutID: undefined,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { saving, saveError, displayTimeMillis } = this.props;

    // When a saving attempt is started, immediately clear any previous status.
    if (!prevProps.saving && saving) {
      this.clear(prevState.timeoutID);
    }

    // When saveError is cleared, immediately clear any previous status.
    if (prevProps.saveError && !saveError) {
      this.clear(prevState.timeoutID);
    }

    // When saving completes without error, show success status with timeout.
    if (prevProps.saving && !saving && !saveError) {
      const timeoutID = window.setTimeout(() => {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          showStatusSuccess: false,
        });
      }, displayTimeMillis);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        timeoutID,
        showStatusSuccess: true,
        skipExitAnimation: false,
      });
    }

    // When saving completes with error, show error status.
    if (prevProps.saving && !saving && saveError) {
      this.setState({ showStatusError: true }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutID);
  }

  clear = (timeoutID) => {
    clearTimeout(timeoutID);
    this.setState({ skipExitAnimation: true, showStatusSuccess: false, showStatusError: false });
  };

  render() {
    const { className, saveErrorMessage, hasValidationError, saving, isDraft, ...rest } = this.props;
    const { skipExitAnimation, showStatusError, showStatusSuccess } = this.state;
    const showValidationError = hasValidationError && !saving;
    const showError = showStatusError || showValidationError;
    return (
      <div
        className={classNames(`${baseClassName}-container`, className)}
        data-testid={TestSelector.SaveStatusContainer}
        {...passThroughProps(rest)}
      >
        {saving && <SpinnerCircle data-testid={TestSelector.SpinnerCircleIcon} />}
        {showError && (
          <span className={`${baseClassName}-error-msg`} data-testid={TestSelector.ErrorMessage}>
            {saveErrorMessage}
          </span>
        )}
        <CSSTransition
          in={showStatusSuccess}
          classNames={`${baseClassName}-saved-msg`}
          enter={false}
          exit={!skipExitAnimation}
          timeout={{ exit: 500 }}
          unmountOnExit
        >
          <div className={`${baseClassName}-saved-msg`} data-testid={TestSelector.SavedMessage}>
            <Icon glyph="Checkmark" className={`${baseClassName}-saved-msg-icon`} />
            {isDraft ? 'Draft Saved!' : 'Saved!'}
          </div>
        </CSSTransition>
      </div>
    );
  }
}
