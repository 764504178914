import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import Modal from '@leafygreen-ui/modal';
import { H3 } from '@leafygreen-ui/typography';

import { FormRow, FormRowInputGroup, FormRowLabelGroup } from 'baas-ui/common/components/form-row';
import { selectStyles } from 'baas-ui/common/styles/ReactSelect.styles';
import { MAX_RESOURCE_NAME_LENGTH } from 'baas-ui/constants';
import { locationOptions } from 'baas-ui/home/constants';
import { validateAppName } from 'baas-ui/home/validation';
import { rootUrl } from 'baas-ui/urls';
import { enterPressedCaller } from 'baas-ui/util';
import { CreateAppRequest, PartialApp } from 'admin-sdk';

import 'baas-ui/common/styles/banner.less';
import './internal-create-app-modal.less';

const baseClassName = 'internal-create-app-modal';

export interface Props {
  createApp(groupId: string, app: CreateAppRequest): Promise<PartialApp>;
  groupId: string;
  open: boolean;
  onClose(): void;
  setCreateAppError(err: string): void;
  createAppError: string;
}

type SelectOption = {
  label: string;
  value: string;
};

const NAMESPACE_FIELD_NAME = 'namespace';
const LOCATION_FIELD_NAME = 'location';
const DEPLOYMENT_MODEL_FIELD_NAME = 'deploymentModel';

const DEPLOYMENT_MODEL_OPTIONS: SelectOption[] = [
  { value: 'GLOBAL', label: 'Global' },
  { value: 'LOCAL', label: 'Local' },
];

const CreateAppModal = ({ createApp, groupId = '', open, onClose, createAppError = '', setCreateAppError }: Props) => {
  const history = useHistory();

  const [appName, setAppName] = useState('');
  const [location, setLocation] = useState<SelectOption>(locationOptions[0]);
  const [deploymentModel, setDeploymentModel] = useState<SelectOption>(DEPLOYMENT_MODEL_OPTIONS[0]);

  const create = () => {
    // TODO change this to not use groupId conditionally
    createApp(
      groupId,
      new CreateAppRequest({
        name: appName,
        location: location.value,
        deploymentModel: deploymentModel.value,
      })
    )
      .then((app: PartialApp) => history.push(rootUrl.groups().group(app.groupId).apps().app(app.id).get()))
      .catch((err) => setCreateAppError(err.message));
  };

  const submit = () => {
    const appErr = validateAppName(appName);
    if (appErr) {
      setCreateAppError(appErr);
    } else {
      create();
    }
  };

  return (
    <Modal
      open={open}
      setOpen={(isOpen) => {
        if (!isOpen) {
          onClose();
        }
      }}
      className={baseClassName}
      contentClassName={`${baseClassName}-content`}
      data-cy="create-app-modal"
    >
      <H3>Create a new App</H3>
      <div className="clearfix" onKeyPress={enterPressedCaller(submit)}>
        {createAppError && (
          <Banner className="banner-margin-top" variant={Variant.Danger}>
            {createAppError}
          </Banner>
        )}
        <FormRow>
          <FormRowLabelGroup>
            <label className="form-row-label" htmlFor={NAMESPACE_FIELD_NAME}>
              App Name
            </label>
          </FormRowLabelGroup>
          <FormRowInputGroup>
            <input
              type="text"
              id={NAMESPACE_FIELD_NAME}
              className="text-input form-row-text-input"
              placeholder="my-new-app"
              value={appName}
              onChange={(e) => setAppName(e.target.value)}
              maxLength={MAX_RESOURCE_NAME_LENGTH}
            />
          </FormRowInputGroup>
        </FormRow>
        <FormRow>
          <FormRowLabelGroup>
            <label className="form-row-label" htmlFor={LOCATION_FIELD_NAME}>
              Location
            </label>
          </FormRowLabelGroup>
          <FormRowInputGroup>
            <Select
              placeholder="Select a Location"
              id={LOCATION_FIELD_NAME}
              value={location}
              onChange={(e: SelectOption) => setLocation(e)}
              options={locationOptions}
              styles={selectStyles}
            />
          </FormRowInputGroup>
        </FormRow>
        <FormRow last>
          <FormRowLabelGroup>
            <label className="form-row-label" htmlFor={DEPLOYMENT_MODEL_FIELD_NAME}>
              Deployment Model
            </label>
          </FormRowLabelGroup>
          <FormRowInputGroup>
            <Select
              placeholder="Select a Deployment Model"
              id={DEPLOYMENT_MODEL_FIELD_NAME}
              value={deploymentModel}
              onChange={(e: SelectOption) => setDeploymentModel(e)}
              options={DEPLOYMENT_MODEL_OPTIONS}
              styles={selectStyles}
            />
          </FormRowInputGroup>
        </FormRow>
      </div>
      <footer className="tb-view-modal-footer">
        <Button variant="primary" onClick={submit}>
          Create
        </Button>
      </footer>
    </Modal>
  );
};

export default CreateAppModal;
