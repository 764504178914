const INVALID_DOMAIN_ERROR_CODE = 'HostingInvalidDomain';

export default INVALID_DOMAIN_ERROR_CODE;

export const SELECTABLE_TYPE_DIR = 'directory';
export const SELECTABLE_TYPE_FILE = 'file';
export const SELECTABLE_TYPE_ANY = 'any';

export const CREATING_DRAFT_MESSAGE = 'Please wait, you can upload more files shortly.';

export const ErrorResponseCode = {
  ForSPA: '200',
  For404: '404',
};
