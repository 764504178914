import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';

export enum LogType {
  AUTH = 'auth',
  FUNCTION = 'function',
  PUSH = 'push',
  SERVICE = 'service',
  TRIGGER = 'trigger',
  GRAPHQL = 'graphql',
  SYNC = 'sync',
  SCHEMA = 'schema',
  ENDPOINT = 'endpoint',
  TRIGGER_ERROR_HANDLER = 'trigger_error_handler',
}

export enum LogStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum LogPolicyType {
  SINGLE = 'single',
  BATCH = 'batch',
}

@JsonObject('LogPolicy')
class LogPolicy {
  @JsonProperty('type', String)
  public type: LogPolicyType = LogPolicyType.SINGLE;

  constructor(required?: Required<LogPolicy>) {
    Object.assign(this, required);
  }
}

@JsonObject('SingleLogPolicy')
export class SingleLogPolicy extends LogPolicy {
  constructor(required?: Required<SingleLogPolicy>) {
    super({ ...required, type: LogPolicyType.SINGLE });
    Object.assign(this, required);
  }
}

@JsonObject('BatchLogPolicy')
export class BatchLogPolicy extends LogPolicy {
  constructor(required?: Required<BatchLogPolicy>) {
    super({ ...required, type: LogPolicyType.BATCH });
    Object.assign(this, required);
  }
}

export enum LogForwardingActionType {
  FUNCTION = 'function',
  COLLECTION = 'collection',
}

@JsonObject('LogForwardingAction')
class LogForwardingAction {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('type', String)
  public type: LogForwardingActionType = LogForwardingActionType.FUNCTION;

  constructor(required?: Required<LogForwardingAction>) {
    Object.assign(this, required);
  }
}

@JsonObject('FunctionForwardingAction')
export class FunctionForwardingAction extends LogForwardingAction {
  constructor(required?: Required<FunctionForwardingAction>) {
    super(required);
    Object.assign(this, required);
  }
}

@JsonObject('CollectionForwardingAction')
export class CollectionForwardingAction extends LogForwardingAction {
  @JsonProperty('database')
  public database = '';

  @JsonProperty('collection', String)
  public collection = '';

  constructor(required?: Required<CollectionForwardingAction>) {
    super(required);
    Object.assign(this, required);
  }
}

const jsonConvert: JsonConvert = new JsonConvert();

/* eslint-disable class-methods-use-this */
@JsonConverter
class ActionConverter implements JsonCustomConvert<LogForwardingAction> {
  serialize(action: LogForwardingAction): any {
    const type: string = action.type ?? '';
    switch (type) {
      case LogForwardingActionType.FUNCTION:
        return jsonConvert.serializeObject(action, FunctionForwardingAction);
      case LogForwardingActionType.COLLECTION:
        return jsonConvert.serializeObject(action, CollectionForwardingAction);
      default:
        throw new Error('unexpected action type');
    }
  }

  deserialize(data: any): LogForwardingAction {
    if ('type' in data) {
      const type = data.type;
      switch (type) {
        case LogForwardingActionType.FUNCTION:
          return jsonConvert.deserializeObject(data, FunctionForwardingAction);
        case LogForwardingActionType.COLLECTION:
          return jsonConvert.deserializeObject(data, CollectionForwardingAction);
        default:
          throw new Error('unexpected action type');
      }
    }
    throw new Error('unexpected action type');
  }
}

@JsonObject('LogForwarder')
export class LogForwarder {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('log_types', [String])
  public logTypes: LogType[] = [];

  @JsonProperty('log_statuses', [String])
  public logStatuses: LogStatus[] = [];

  @JsonProperty('policy')
  public policy: LogPolicy = new LogPolicy();

  @JsonProperty('action', ActionConverter)
  public action: LogForwardingAction = new LogForwardingAction();

  @JsonProperty('disabled')
  public disabled = false;

  @JsonProperty('error', String, true)
  public error?: string = undefined;

  @JsonProperty('error_co_id', String, true)
  public errorCoId?: string = undefined;

  constructor(required?: Required<LogForwarder>) {
    Object.assign(this, required);
  }
}

@JsonObject('CreateLogForwarderRequest')
export class CreateLogForwarderRequest {
  @JsonProperty('name')
  public name = '';

  @JsonProperty('log_types', [String])
  public logTypes: LogType[] = [];

  @JsonProperty('log_statuses', [String])
  public logStatuses: LogStatus[] = [];

  @JsonProperty('policy')
  public policy: LogPolicy = new LogPolicy();

  @JsonProperty('action', ActionConverter)
  public action: LogForwardingAction = new LogForwardingAction();

  @JsonProperty('disabled')
  public disabled = false;

  constructor(required?: Required<CreateLogForwarderRequest>) {
    Object.assign(this, required);
  }
}

@JsonObject('PatchLogForwarderRequest')
export class PatchLogForwarderRequest {
  @JsonProperty('name', String, true)
  public name?: string = undefined;

  @JsonProperty('log_types', [String], true)
  public logTypes?: LogType[] = undefined;

  @JsonProperty('log_statuses', [String], true)
  public logStatuses?: LogStatus[] = undefined;

  @JsonProperty('policy', LogPolicy, true)
  public policy?: LogPolicy = undefined;

  @JsonProperty('action', ActionConverter, true)
  public action?: LogForwardingAction = undefined;

  @JsonProperty('disabled', Boolean, true)
  public disabled?: boolean = undefined;

  constructor(partial?: Partial<PatchLogForwarderRequest>) {
    Object.assign(this, partial);
  }
}
