import Login from './components/Login';
import Logout from './components/Logout';
import * as actions from './actions';
import * as reducers from './reducers';

export default {
  actions,
  reducers,
  Logout,
  Login,
};
