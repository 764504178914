import { isSelfHostedOrAtlasMongoService, MongoDataSourceType } from 'baas-ui/services/registry';
import { PartialServiceDesc, ServiceDescConfig } from 'admin-sdk';

import {
  ClusterServiceConfig,
  DataLakeServiceConfig,
  MDBService,
  MongoServiceConfig,
  Namespaces,
  SelfHostedMongoServiceConfig,
} from './types';

export const convertToMDBServices = (
  svcsById: Record<string, PartialServiceDesc>,
  svcConfigsById: Record<string, ServiceDescConfig>
): MDBService[] =>
  Object.values(svcsById)
    .filter(({ type }) => isSelfHostedOrAtlasMongoService(type))
    .map((svc) => ({ ...svc, config: { ...svcConfigsById[svc.id]?.config } }));

export const isCluster = (cfg?: MongoServiceConfig): cfg is ClusterServiceConfig =>
  cfg?.serviceType === MongoDataSourceType.Atlas;

export const isDataLake = (cfg?: MongoServiceConfig): cfg is DataLakeServiceConfig =>
  cfg?.serviceType === MongoDataSourceType.DataFederation;

export const isSelfHostedMongoService = (cfg?: MongoServiceConfig): cfg is SelfHostedMongoServiceConfig =>
  cfg?.serviceType === MongoDataSourceType.SelfHosted;

export const inputId = (prefix, key, index) => `${prefix}-${key}-${index}`;
export const collectionsNavActiveId = (svcId, dbOrColl) => `${svcId}-${dbOrColl}`;

export const mergeNamespaces = (nsA: Namespaces, nsB: Namespaces) => {
  const mergedNs: Namespaces = [];

  const dbs = {};
  let nsIdx = 0;
  const mergeNamespace = (ns) => {
    if (!Number.isInteger(dbs[ns.database])) {
      mergedNs.push(ns);
      dbs[ns.database] = nsIdx++;
    }

    if (ns.collections) {
      const dbIdx = dbs[ns.database];
      const existingNs = mergedNs[dbIdx].collections;
      // make a new set from the two arrays and then convert back to an array and sort
      mergedNs[dbIdx].collections = [...new Set([...ns.collections, ...existingNs])].sort((a, b) => a.localeCompare(b));
    }
  };

  nsA.forEach(mergeNamespace);
  nsB.forEach(mergeNamespace);

  return mergedNs.sort((a, b) => a.database.localeCompare(b.database));
};

// Namespaces should typically use dataSourceName so using
// a kwarg to help callers know that.
interface CollectionNamespaceObject {
  dataSourceName: string;
  database: string;
  collection: string;
}

export function makeCollectionNamespace({ dataSourceName, database, collection }: CollectionNamespaceObject): string {
  return `${dataSourceName}:${database}:${collection}`;
}

export function parseCollectionNamespace(namespace: string): CollectionNamespaceObject {
  const [dataSourceName, database, collection] = namespace.split(':');

  return {
    dataSourceName,
    database,
    collection,
  };
}

export const getDataSourceDisplay = (svc: MDBService): string => {
  let name: string;
  if (isCluster(svc.config)) {
    name = `${svc.name} (${svc.config.clusterName})`;
  } else if (isDataLake(svc.config)) {
    name = `${svc.name} (${svc.config.dataLakeName})`;
  } else {
    name = svc.name;
  }
  return name;
};
