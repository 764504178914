import React, { useContext, useEffect, useState } from 'react';
import Banner, { Variant as BannerVariant } from '@leafygreen-ui/banner';
import Checkbox from '@leafygreen-ui/checkbox';
import ConfirmationModal, { Variant } from '@leafygreen-ui/confirmation-modal';
import ArrowLeftIcon from '@leafygreen-ui/icon/dist/ArrowLeft';
import InfoWithCircle from '@leafygreen-ui/icon/dist/InfoWithCircle';
import IconButton from '@leafygreen-ui/icon-button';
import { palette } from '@leafygreen-ui/palette';
import { Option, Select, Size } from '@leafygreen-ui/select';
import { Cell, Row, Table, TableHeader } from '@leafygreen-ui/table-legacy';
import TextInput, { State } from '@leafygreen-ui/text-input';
import Tooltip from '@leafygreen-ui/tooltip';
import { Body, Description, H3, InlineCode, Label } from '@leafygreen-ui/typography';
import classNames from 'classnames';
import JSON5 from 'json5';
import { useDebouncedCallback } from 'use-debounce';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import { prettyJSONStringify } from 'baas-ui/common/utils/util';
import { PARSE_JSON_DEBOUNCE_TIMEOUT } from 'baas-ui/rules/constants';
import ExpressionEditorFormRow from 'baas-ui/rules/rule-viewer/common/ExpressionEditorFormRow';
import SaveFooter from 'baas-ui/rules/rule-viewer/common/SaveFooter';
import { RulesPageCollectionExplorerContext } from 'baas-ui/rules/RulesPage';
import { DocumentFilterExpression, Permission } from 'baas-ui/rules/types';
import { useRulesPageContext } from 'baas-ui/rules/useRulesPageContext';
import { isBooleanLiteralExpression } from 'baas-ui/rules/utils';
import { validateRoleOrFilterName } from 'baas-ui/rules/validation';
import { MongoDBRuleField, MongoDBRulePermissions, MongoDBRuleRole, MongoDBSyncIncompatibilityError } from 'admin-sdk';

import { AdditionalFieldsPermissionsRadioGroup } from './AdditionalFieldsPermissionsRadioGroup';
import { DocumentFiltersCollapsible } from './DocumentFiltersCollapsible';
import { FieldNameInput } from './FieldNameInput';
import { FieldPermissionsRadioGroup } from './FieldPermissionsRadioGroup';

import './edit-role-form.less';

const applyWhenExampleCode = prettyJSONStringify({ owner_id: '%%user.id', owner_name: '%%user.data.name' });

const applyWhenDescription = (
  <>
    For each document returned by a request, an Apply When{' '}
    <DocLink showExternalIcon={false} href={docLinks.Rules.Expressions}>
      JSON expression
    </DocLink>{' '}
    will apply a role only when it evaluates to <InlineCode>true</InlineCode>. An empty expression always defaults to{' '}
    <InlineCode>true</InlineCode>.
  </>
);

const applyWhenExampleText = (
  <>
    The following is a MongoDB role&apos;s Apply When expression that evaluates to <InlineCode>true</InlineCode> only if
    a document&apos;s <InlineCode>owner_id</InlineCode> and <InlineCode>owner_name</InlineCode> values match the values
    of <InlineCode>%%user.id</InlineCode> and <InlineCode>%%user.data.name</InlineCode>:
  </>
);

export enum FieldPermissionValue {
  None = 'none',
  Read = 'read',
  ReadAndWrite = 'readAndWrite',
}

export enum FieldPermissionSelectOption {
  FieldLevel = 'fieldLevel',
  NoAccess = 'noAccess',
  None = 'none',
  ReadAll = 'readAll',
  ReadAndWriteAll = 'readAndWriteAll',
}

export const FieldPermSelectOptToDisplayText: Record<FieldPermissionSelectOption, string> = {
  [FieldPermissionSelectOption.FieldLevel]: 'Specify field-level permissions',
  [FieldPermissionSelectOption.NoAccess]: 'Deny all access',
  [FieldPermissionSelectOption.None]: 'Select permissions option',
  [FieldPermissionSelectOption.ReadAll]: 'Read all fields',
  [FieldPermissionSelectOption.ReadAndWriteAll]: 'Read and write all fields',
};

export enum FieldType {
  Field,
  FieldInput,
  AdditionalFields,
}

interface FieldData {
  type: FieldType;
}

interface FieldWithFieldKeyData extends FieldData {
  fieldKey: string;
}

export const isFieldWithFieldKeyData = (fieldData: FieldData): fieldData is FieldWithFieldKeyData => {
  return !!(fieldData as FieldWithFieldKeyData).fieldKey;
};

export interface FieldPermissionsTableData {
  fieldData: FieldData;
}

export enum TestSelector {
  AddFieldInput = 'add-field-input',
  AdditionalFieldPermissionsRadioGroup = 'additional-fields-permissions-radio-group',
  AdditionalFieldsName = 'additional-fields-name',
  ApplyWhenExpEditor = 'apply-when-editor',
  BackNavIcon = 'back-nav-icon',
  CancelAndExitModal = 'cancel-exit-modal',
  DocFiltersCollapsible = 'document-filters-collapsible',
  DocFiltersContent = 'document-filters-content',
  DocFiltersCopyReadFilterButton = 'document-filters-copy-read-filter',
  DocFiltersCopyWriteFilterButton = 'document-filters-copy-write-filter-button',
  DocFiltersHeader = 'document-filters-header',
  DocFiltersReadWarningBanner = 'document-filters-read-warning-banner',
  DocFiltersWriteWarningBanner = 'document-filters-write-warning-banner',
  DocPermissionDelete = 'document-permission-delete',
  DocPermissionInsert = 'document-permission-insert',
  DocPermissionSearch = 'document-permission-search',
  DocPermissionsGroup = 'document-permissions-group',
  EditFieldInput = 'edit-field-input',
  FieldInputCheckmarkButton = 'field-input-checkmark-button',
  FieldInputDeleteButton = 'field-input-delete-button',
  FieldName = 'field-name',
  FieldPermissionsLabel = 'field-permissions-label',
  FieldPermissionsRadioGroupC = 'field-permissions-radio-group',
  FieldPermissionsSelect = 'field-permissions-select',
  FieldPermissionsTable = 'field-permissions-table',
  NameInput = 'name-input',
  PermissionsNoneOption = 'permissions-none-option',
  PermissionsReadAndWriteOption = 'permissions-read-and-write-option',
  PermissionsReadOption = 'permissions-read-option',
  SaveFooterC = 'save-footer',
  SyncInfoBanner = 'sync-info-banner',
  SyncWarningBanner = 'sync-warning-banner',
  SaveFooterBanner = 'save-footer-banner',
  Title = 'title',
}

export interface Props {
  role?: MongoDBRuleRole;
  roleIncompatibilities?: MongoDBSyncIncompatibilityError[];
  onClickBackOrCancel: () => void;
  onChange: (role: MongoDBRuleRole) => void;
}

const baseClassName = 'edit-role-form';
const baseGroupItemClassName = `${baseClassName}-doc-permissions-group-item`;
const fieldPermsBaseClassName = `${baseClassName}-field-level-perms`;

export const getFieldPermissionOptionFromRole = (role?: MongoDBRuleRole): FieldPermissionSelectOption => {
  if (!role) {
    return FieldPermissionSelectOption.None;
  }

  if (
    role.additionalFields?.read !== undefined ||
    role.additionalFields?.write !== undefined ||
    (role.fields && Object.keys(role.fields).length)
  ) {
    return FieldPermissionSelectOption.FieldLevel;
  }

  if (role.write !== undefined && role.write) {
    return FieldPermissionSelectOption.ReadAndWriteAll;
  }

  if (role.read !== undefined && role.read) {
    return FieldPermissionSelectOption.ReadAll;
  }

  if (!role.write && !role.read) {
    return FieldPermissionSelectOption.NoAccess;
  }

  return FieldPermissionSelectOption.None;
};

export const getFieldsDisplayVals = (
  fields?: Record<string, MongoDBRuleField>
): Record<string, FieldPermissionValue> => {
  const evalFields: Record<string, FieldPermissionValue> = {};
  Object.keys(fields || {}).forEach((fieldKey) => {
    if (!fields) {
      return;
    }

    // We do not assign field permission value when both read and write are undefined
    if (fields[fieldKey].write) {
      evalFields[fieldKey] = FieldPermissionValue.ReadAndWrite;
    } else if (fields[fieldKey].read) {
      evalFields[fieldKey] = FieldPermissionValue.Read;
    } else if (fields[fieldKey].write === false && fields[fieldKey].read === false) {
      evalFields[fieldKey] = FieldPermissionValue.None;
    }
  });
  return evalFields;
};

export const getAdditionalFieldsPermissionDisplayVal = (addtlFields?: MongoDBRulePermissions): FieldPermissionValue => {
  if (!addtlFields?.read && !addtlFields?.write) {
    return FieldPermissionValue.None;
  }

  return addtlFields.write ? FieldPermissionValue.ReadAndWrite : FieldPermissionValue.Read;
};

export const EditRoleFormComponent = ({ role, roleIncompatibilities, onClickBackOrCancel, onChange }: Props) => {
  const { selectedDataSourceData } = useContext(RulesPageCollectionExplorerContext);
  const {
    hasChanges,
    setHasClientValidationError,
    setOnDiscardChanges,
    syncIncompatibleRolesByDataSourceId,
    syncIncompatibleFields,
    setSyncIncompatibleFields,
  } = useRulesPageContext();

  const isDocFiltersReadDefined =
    isBooleanLiteralExpression(role?.documentFilters?.read) || role?.documentFilters?.read;
  const isDocFiltersWriteDefined =
    isBooleanLiteralExpression(role?.documentFilters?.write) || role?.documentFilters?.write;

  const clusterHasSyncEnabled =
    selectedDataSourceData !== null &&
    syncIncompatibleRolesByDataSourceId[selectedDataSourceData.dataSourceId]?.flexibleSyncEnabled;

  // Role display fields
  const [roleDisplayName, setRoleDisplayName] = useState<string>(role?.name || '');
  const [applyWhenDisplayVal, setApplyWhenDisplayVal] = useState<string>(prettyJSONStringify(role?.applyWhen || {}));
  const [insertPermDisplayVal, setInsertPermDisplayVal] = useState<boolean>(role?.insert);
  const [deletePermDisplayVal, setDeletePermDisplayVal] = useState<boolean>(role?.delete);
  const [searchPermDisplayVal, setSearchPermDisplayVal] = useState<boolean>(role?.search);
  const [docFiltersReadDisplayVal, setDocFiltersReadDisplayVal] = useState<string>(
    isDocFiltersReadDefined ? prettyJSONStringify(role?.documentFilters?.read) : '{}'
  );
  const [docFiltersWriteDisplayVal, setDocFiltersWriteDisplayVal] = useState<string>(
    isDocFiltersWriteDefined ? prettyJSONStringify(role?.documentFilters?.write) : '{}'
  );
  const [readPermDisplayVal, setEvaluatedReadPerm] = useState<boolean | undefined>(role?.read || role?.write);
  const [writePermDisplayVal, setEvaluatedWritePerm] = useState<boolean | undefined>(role?.write);
  const [fieldsDisplayVals, setEvaluatedFields] = useState<Record<string, FieldPermissionValue>>(
    getFieldsDisplayVals(role?.fields)
  );
  const [addtlFieldsPermDisplayVal, setEvaluatedAddtlFieldsPermission] = useState<FieldPermissionValue>(
    getAdditionalFieldsPermissionDisplayVal(role?.additionalFields)
  );

  const [roleDisplayNameError, setRoleDisplayNameError] = useState<string>('');
  const [fieldNameToAdd, setFieldNameToAdd] = useState<string>('');
  const [fieldNameToEdit, setFieldNameToEdit] = useState<string>('');
  const [editedFieldNameValue, setEditedFieldNameValue] = useState<string>('');
  const [applyWhenParsedObj, setApplyWhenParsedObj] = useState<Record<string, any>>(
    JSON5.parse(prettyJSONStringify(role?.applyWhen || {}))
  );
  const [applyWhenParseError, setApplyWhenParseError] = useState('');
  const [docFiltersReadParsed, setDocFiltersReadParsed] = useState<DocumentFilterExpression>(
    isDocFiltersReadDefined ? JSON5.parse(prettyJSONStringify(role?.documentFilters?.read)) : undefined
  );
  const [docFiltersReadParseError, setDocFiltersReadParseError] = useState('');
  const [docFiltersWriteParsed, setDocFiltersWriteParsed] = useState<DocumentFilterExpression>(
    isDocFiltersWriteDefined ? JSON5.parse(prettyJSONStringify(role?.documentFilters?.write)) : undefined
  );
  const [docFiltersWriteParseError, setDocFiltersWriteParseError] = useState('');
  const [docFiltersExpanded, setDocFiltersExpanded] = useState(
    role?.documentFilters !== undefined || clusterHasSyncEnabled
  );

  const [isCancelChangesModalOpen, setIsCancelChangesModalOpen] = useState<boolean>(false);
  const [selectedFieldPermOpt, setSelectedFieldPermOpt] = useState<FieldPermissionSelectOption>(
    getFieldPermissionOptionFromRole(role)
  );
  const [isSyncInfoBannerDismissed, setIsSyncInfoBannerDismissed] = useState(false);

  const shouldShowFooterBanner = syncIncompatibleFields.length > 0;

  // TODO(BAAS-23632): Make it more clear what purpose roleIncompatibilites and syncIncompatibleFields serve
  const shouldShowSyncWarningBanner =
    roleIncompatibilities && roleIncompatibilities.length > 0 && !shouldShowFooterBanner;

  const shouldShowSyncInfoBanner = clusterHasSyncEnabled && !isSyncInfoBannerDismissed && !shouldShowSyncWarningBanner;

  useEffect(() => {
    setOnDiscardChanges(() => setIsCancelChangesModalOpen(true));
    setSyncIncompatibleFields([]);
  }, []);

  useEffect(() => {
    setDocFiltersExpanded(docFiltersExpanded || clusterHasSyncEnabled);
  }, [clusterHasSyncEnabled]);

  const parseExpressionJSON = (
    exprVal: string,
    setParsed: React.Dispatch<React.SetStateAction<any>>,
    setParseError: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setParseError('');
    try {
      const parsedExpr = JSON5.parse(exprVal);
      setParsed(parsedExpr);
    } catch (e) {
      setParseError(e.message);
    }
  };

  const parseApplyWhenJSON = (applyWhenVal) =>
    parseExpressionJSON(applyWhenVal, setApplyWhenParsedObj, setApplyWhenParseError);

  const parseDocFiltersReadJSON = (docFiltersReadVal) => {
    if (docFiltersReadVal !== '') {
      parseExpressionJSON(docFiltersReadVal, setDocFiltersReadParsed, setDocFiltersReadParseError);
    } else {
      setDocFiltersReadParsed(undefined);
      setDocFiltersReadParseError('');
    }
  };

  const parseDocFiltersWriteJSON = (docFiltersWriteVal) => {
    if (docFiltersWriteVal !== '') {
      parseExpressionJSON(docFiltersWriteVal, setDocFiltersWriteParsed, setDocFiltersWriteParseError);
    } else {
      setDocFiltersWriteParsed(undefined);
      setDocFiltersWriteParseError('');
    }
  };

  const parseApplyWhenJSONDebounced = useDebouncedCallback(parseApplyWhenJSON, PARSE_JSON_DEBOUNCE_TIMEOUT);
  const parseDocFiltersReadJSONDebounced = useDebouncedCallback(parseDocFiltersReadJSON, PARSE_JSON_DEBOUNCE_TIMEOUT);
  const parseDocFiltersWriteJSONDebounced = useDebouncedCallback(parseDocFiltersWriteJSON, PARSE_JSON_DEBOUNCE_TIMEOUT);

  useEffect(() => {
    parseApplyWhenJSONDebounced(applyWhenDisplayVal);
  }, [applyWhenDisplayVal]);

  useEffect(() => {
    parseDocFiltersReadJSONDebounced(docFiltersReadDisplayVal);
  }, [docFiltersReadDisplayVal]);

  useEffect(() => {
    parseDocFiltersWriteJSONDebounced(docFiltersWriteDisplayVal);
  }, [docFiltersWriteDisplayVal]);

  useEffect(() => {
    let documentFilters: MongoDBRulePermissions | undefined;
    if (docFiltersReadParsed !== undefined || docFiltersWriteParsed !== undefined) {
      documentFilters = new MongoDBRulePermissions({
        read: docFiltersReadParsed,
        write: docFiltersWriteParsed,
      });
    }

    const fields: Record<string, MongoDBRuleField> = {};
    Object.keys(fieldsDisplayVals).forEach((fieldKey) => {
      if (fieldsDisplayVals[fieldKey] === FieldPermissionValue.Read) {
        fields[fieldKey] = new MongoDBRuleField({ read: true, write: false });
      } else if (fieldsDisplayVals[fieldKey] === FieldPermissionValue.ReadAndWrite) {
        fields[fieldKey] = new MongoDBRuleField({ read: true, write: true });
      } else if (fieldsDisplayVals[fieldKey] === FieldPermissionValue.None) {
        fields[fieldKey] = new MongoDBRuleField({ read: false, write: false });
      }
    });

    let additionalFields: MongoDBRulePermissions = {};
    if (addtlFieldsPermDisplayVal === FieldPermissionValue.Read) {
      additionalFields = new MongoDBRulePermissions({ read: true, write: false });
    } else if (addtlFieldsPermDisplayVal === FieldPermissionValue.ReadAndWrite) {
      additionalFields = new MongoDBRulePermissions({ read: true, write: true });
    }

    const constructedRole = new MongoDBRuleRole({
      name: roleDisplayName,
      applyWhen: applyWhenParsedObj,
      insert: insertPermDisplayVal,
      delete: deletePermDisplayVal,
      search: searchPermDisplayVal,
      documentFilters,
      read: readPermDisplayVal,
      write: writePermDisplayVal,
      fields: Object.keys(fields).length ? fields : undefined,
      additionalFields: Object.keys(additionalFields).length ? additionalFields : undefined,
    });

    onChange(constructedRole);
  }, [
    roleDisplayName,
    applyWhenParsedObj,
    docFiltersReadParsed,
    docFiltersWriteParsed,
    insertPermDisplayVal,
    deletePermDisplayVal,
    searchPermDisplayVal,
    readPermDisplayVal,
    writePermDisplayVal,
    fieldsDisplayVals,
    addtlFieldsPermDisplayVal,
  ]);

  const onChangeFieldPermOption = (value: FieldPermissionSelectOption) => {
    switch (value) {
      case FieldPermissionSelectOption.NoAccess: {
        setEvaluatedReadPerm(false);
        setEvaluatedWritePerm(false);

        setEvaluatedFields({});
        setEvaluatedAddtlFieldsPermission(FieldPermissionValue.None);

        setSelectedFieldPermOpt(FieldPermissionSelectOption.NoAccess);
        break;
      }
      case FieldPermissionSelectOption.ReadAll: {
        setEvaluatedReadPerm(true);
        setEvaluatedWritePerm(false);

        setEvaluatedFields({});
        setEvaluatedAddtlFieldsPermission(FieldPermissionValue.None);

        setSelectedFieldPermOpt(FieldPermissionSelectOption.ReadAll);
        break;
      }
      case FieldPermissionSelectOption.ReadAndWriteAll: {
        setEvaluatedReadPerm(true);
        setEvaluatedWritePerm(true);

        setEvaluatedFields({});
        setEvaluatedAddtlFieldsPermission(FieldPermissionValue.None);

        setSelectedFieldPermOpt(FieldPermissionSelectOption.ReadAndWriteAll);
        break;
      }
      case FieldPermissionSelectOption.FieldLevel: {
        setEvaluatedReadPerm(undefined);
        setEvaluatedWritePerm(undefined);

        setEvaluatedFields({});
        setEvaluatedAddtlFieldsPermission(FieldPermissionValue.None);

        setSelectedFieldPermOpt(FieldPermissionSelectOption.FieldLevel);
        break;
      }
      default: {
        setSelectedFieldPermOpt(FieldPermissionSelectOption.None);
      }
    }
  };

  const syncIncompatibleFieldsBanner = (
    <Banner variant={'warning'} className="sync-banner" data-testid={TestSelector.SaveFooterBanner}>
      <Body>The following are incompatible with Sync:</Body>{' '}
      <InlineCode className="sync-banner-fields">
        {syncIncompatibleFields.map((field, i) => {
          return i === 0 ? `${field}` : `, ${field}`;
        })}
      </InlineCode>
    </Banner>
  );

  const constructFieldPermsTableData = (): FieldPermissionsTableData[] => {
    // Field names
    const tableData: FieldPermissionsTableData[] = Object.keys(fieldsDisplayVals).map((fieldKey) => {
      return {
        fieldData: {
          type: FieldType.Field,
          fieldKey,
        },
      };
    });

    // Add field name input
    tableData.push({
      fieldData: { type: FieldType.FieldInput },
    });

    // Additional Fields
    tableData.push({
      fieldData: { type: FieldType.AdditionalFields },
    });

    return tableData;
  };

  const renderFieldName = (fieldData: FieldData): React.ReactNode => {
    switch (fieldData.type) {
      case FieldType.Field:
        if (!isFieldWithFieldKeyData(fieldData)) {
          return null;
        }

        return fieldData.fieldKey === fieldNameToEdit ? (
          <FieldNameInput
            data-testid={TestSelector.EditFieldInput}
            fieldName={editedFieldNameValue}
            onFieldNameChange={setEditedFieldNameValue}
            onClickSubmit={() => {
              setEvaluatedFields((currEvalFields) => {
                const fieldPermVal = currEvalFields[fieldData.fieldKey];
                delete currEvalFields[fieldData.fieldKey];
                return { ...currEvalFields, [editedFieldNameValue]: fieldPermVal };
              });
              setFieldNameToEdit('');
              setEditedFieldNameValue('');
            }}
            onClickDelete={() => {
              setEvaluatedFields((currEvalFields) => {
                delete currEvalFields[fieldData.fieldKey];
                return { ...currEvalFields };
              });
              setFieldNameToEdit('');
              setEditedFieldNameValue('');
            }}
          />
        ) : (
          <Body data-testid={TestSelector.FieldName} data-cy="field-name">
            {fieldData.fieldKey}
          </Body>
        );
      case FieldType.FieldInput:
        return (
          <FieldNameInput
            data-testid={TestSelector.AddFieldInput}
            data-test-selector={TestSelector.AddFieldInput}
            fieldName={fieldNameToAdd}
            onFieldNameChange={setFieldNameToAdd}
            onClickSubmit={() => {
              setEvaluatedFields((currEvalFields) => {
                return { ...currEvalFields, [fieldNameToAdd]: FieldPermissionValue.Read };
              });
              setFieldNameToAdd('');
            }}
            onClickDelete={() => setFieldNameToAdd('')}
          />
        );
      case FieldType.AdditionalFields:
        return (
          <div
            className={`${fieldPermsBaseClassName}-additional-fields`}
            data-testid={TestSelector.AdditionalFieldsName}
            data-test-selector={TestSelector.AdditionalFieldsName}
          >
            Additional Fields{' '}
            <Tooltip
              justify="middle"
              trigger={
                <IconButton aria-label="Additional Fields Info" style={{ color: palette.black }}>
                  <InfoWithCircle />
                </IconButton>
              }
              align="top"
              className={`${fieldPermsBaseClassName}-additional-fields-tooltip`}
            >
              Default permissions will apply to all fields that don&apos;t have individual permissions.
            </Tooltip>
          </div>
        );
      default:
        return null;
    }
  };

  const renderFieldPermissions = (fieldData: FieldData): React.ReactNode => {
    switch (fieldData.type) {
      case FieldType.Field:
        if (!isFieldWithFieldKeyData(fieldData)) {
          return null;
        }

        return (
          <FieldPermissionsRadioGroup
            data-test-selector={TestSelector.FieldPermissionsRadioGroupC}
            name={fieldData.fieldKey}
            value={fieldsDisplayVals[fieldData.fieldKey]}
            onChange={(newPermVal: FieldPermissionValue) => {
              setEvaluatedFields((currEvalFields) => {
                return { ...currEvalFields, [fieldData.fieldKey]: newPermVal };
              });
            }}
            disabled={false}
          />
        );
      case FieldType.FieldInput:
        return (
          <FieldPermissionsRadioGroup
            data-test-selector={TestSelector.FieldPermissionsRadioGroupC}
            name={'field-name-input'}
            value={FieldPermissionValue.Read}
            disabled
          />
        );
      case FieldType.AdditionalFields:
        return (
          <AdditionalFieldsPermissionsRadioGroup
            data-test-selector={TestSelector.AdditionalFieldPermissionsRadioGroup}
            value={addtlFieldsPermDisplayVal}
            onChange={setEvaluatedAddtlFieldsPermission}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={baseClassName} data-cy="edit-role-form">
      {shouldShowSyncInfoBanner && (
        <Banner
          className={`${baseClassName}-sync-info-banner`}
          data-testid={TestSelector.SyncInfoBanner}
          data-cy="sync-banner"
          dismissible
          onClose={() => setIsSyncInfoBannerDismissed(true)}
        >
          Learn more about how to write rules for synced collections.&nbsp;
          <DocLink href={docLinks.Rules.RolesFlexibleSync} showExternalIcon>
            Learn more
          </DocLink>
        </Banner>
      )}
      {shouldShowSyncWarningBanner && (
        <Banner
          className={`${baseClassName}-sync-warning-banner`}
          variant={BannerVariant.Warning}
          data-testid={TestSelector.SyncWarningBanner}
          data-cy="sync-warning-banner"
        >
          The following config options are incompatible with Sync:{' '}
          {roleIncompatibilities?.map((incompatibility, idx) => {
            return (
              <span key={incompatibility.key}>
                <span className={`${baseClassName}-sync-warning-banner-key`}>{incompatibility.key}</span>
                {idx < roleIncompatibilities.length - 1 ? ', ' : ''}
              </span>
            );
          })}
          . Edit and click &quot;Save Draft&quot; to re-validate.{' '}
          <DocLink href={docLinks.Rules.SyncCompatibility} showExternalIcon>
            View docs
          </DocLink>
        </Banner>
      )}
      <div className={`${baseClassName}-header`}>
        <IconButton
          aria-label="back-nav"
          className={`${baseClassName}-back-nav`}
          data-testid={TestSelector.BackNavIcon}
          data-test-selector={TestSelector.BackNavIcon}
          data-cy="back-nav-icon"
          onClick={() => (hasChanges ? setIsCancelChangesModalOpen(true) : onClickBackOrCancel())}
        >
          <ArrowLeftIcon />
        </IconButton>
        <H3 className={`${baseClassName}-header-title`} data-testid={TestSelector.Title}>
          {roleDisplayName ? `Edit ${roleDisplayName}` : 'Configure a new role'}
        </H3>
      </div>

      <div className={`${baseClassName}-form`}>
        <TextInput
          data-cy="name-input"
          label="Role name"
          placeholder="Enter role name"
          value={roleDisplayName}
          onChange={(e) => setRoleDisplayName(e.target.value)}
          type="text"
          className={classNames(`${baseClassName}-name-input`, `${baseClassName}-form-input-section`)}
          data-testid={TestSelector.NameInput}
          data-test-selector={TestSelector.NameInput}
          onBlur={() => {
            const validationError = validateRoleOrFilterName(roleDisplayName);
            setRoleDisplayNameError(validationError);
            setHasClientValidationError(!!validationError);
          }}
          errorMessage={roleDisplayNameError}
          state={roleDisplayNameError ? State.Error : State.None}
          autoFocus
        />

        <hr className={`${baseClassName}-divider`} />

        <ExpressionEditorFormRow
          labelText="Apply When"
          data-testid={TestSelector.ApplyWhenExpEditor}
          data-test-selector={TestSelector.ApplyWhenExpEditor}
          data-cy={TestSelector.ApplyWhenExpEditor}
          description={applyWhenDescription}
          exampleCode={applyWhenExampleCode}
          exampleText={applyWhenExampleText}
          expressionStr={applyWhenDisplayVal}
          onChangeExpression={setApplyWhenDisplayVal}
          className={`${baseClassName}-form-input-section`}
          parsingError={applyWhenParseError}
        />

        <hr className={`${baseClassName}-divider`} />

        <div className={`${baseClassName}-form-input-section`} data-testid={TestSelector.DocPermissionsGroup}>
          <Label htmlFor="document-permissions-input">Document Permissions</Label>
          <Description>Define document-level permissions for this collection.</Description>
          <div id="document-permissions-input" className={`${baseClassName}-doc-permissions-group`}>
            <Checkbox
              name={Permission.Insert}
              data-cy="insert-perm"
              label={Permission.Insert}
              className={baseGroupItemClassName}
              value={Permission.Insert}
              checked={insertPermDisplayVal}
              onChange={(e) => setInsertPermDisplayVal(e.target.checked)}
              data-testid={TestSelector.DocPermissionInsert}
              data-test-selector={TestSelector.DocPermissionInsert}
            />
            <Checkbox
              name={Permission.Delete}
              data-cy="delete-perm"
              label={Permission.Delete}
              className={baseGroupItemClassName}
              value={Permission.Delete}
              checked={deletePermDisplayVal}
              onChange={(e) => setDeletePermDisplayVal(e.target.checked)}
              data-testid={TestSelector.DocPermissionDelete}
              data-test-selector={TestSelector.DocPermissionDelete}
            />
            <Checkbox
              name={Permission.Search}
              data-cy="search-perm"
              label={Permission.Search}
              className={baseGroupItemClassName}
              value={Permission.Search}
              checked={searchPermDisplayVal}
              onChange={(e) => setSearchPermDisplayVal(e.target.checked)}
              data-testid={TestSelector.DocPermissionSearch}
              data-test-selector={TestSelector.DocPermissionSearch}
            />
          </div>
          <DocumentFiltersCollapsible
            expanded={docFiltersExpanded}
            readExpression={docFiltersReadDisplayVal}
            writeExpression={docFiltersWriteDisplayVal}
            onChangeReadExpression={setDocFiltersReadDisplayVal}
            onChangeWriteExpression={setDocFiltersWriteDisplayVal}
            onClickHeader={() => setDocFiltersExpanded(!docFiltersExpanded)}
            readParsingError={docFiltersReadParseError}
            writeParsingError={docFiltersWriteParseError}
          />
        </div>

        <hr className={`${baseClassName}-divider`} />

        <div
          data-cy="field-permissions-select"
          className={classNames(`${baseClassName}-form-input-section`, `${fieldPermsBaseClassName}-section`)}
          data-testid={TestSelector.FieldPermissionsSelect}
        >
          <Label id={TestSelector.FieldPermissionsLabel} htmlFor={TestSelector.FieldPermissionsSelect}>
            Field Permissions
          </Label>
          <Description>
            Define this role&apos;s{' '}
            <DocLink showExternalIcon={false} href={docLinks.Rules.FieldPermissions}>
              read/write permissions
            </DocLink>
          </Description>
          <Select
            id={TestSelector.FieldPermissionsSelect}
            aria-labelledby={TestSelector.FieldPermissionsLabel}
            placeholder={FieldPermSelectOptToDisplayText[FieldPermissionSelectOption.None]}
            name="field-permissions"
            value={selectedFieldPermOpt}
            size={Size.Default}
            onChange={(value: FieldPermissionSelectOption) => onChangeFieldPermOption(value)}
            allowDeselect={false}
            data-test-selector={TestSelector.FieldPermissionsSelect}
          >
            <Option value={FieldPermissionSelectOption.NoAccess}>
              {FieldPermSelectOptToDisplayText[FieldPermissionSelectOption.NoAccess]}
            </Option>
            <Option data-cy="read-all-select-option" value={FieldPermissionSelectOption.ReadAll}>
              {FieldPermSelectOptToDisplayText[FieldPermissionSelectOption.ReadAll]}
            </Option>
            <Option data-cy="read-and-write-all-select-option" value={FieldPermissionSelectOption.ReadAndWriteAll}>
              {FieldPermSelectOptToDisplayText[FieldPermissionSelectOption.ReadAndWriteAll]}
            </Option>
            <Option value={FieldPermissionSelectOption.FieldLevel}>
              {FieldPermSelectOptToDisplayText[FieldPermissionSelectOption.FieldLevel]}
            </Option>
          </Select>
          {selectedFieldPermOpt === FieldPermissionSelectOption.FieldLevel && (
            <Table
              data-testid={TestSelector.FieldPermissionsTable}
              data-test-selector={TestSelector.FieldPermissionsTable}
              data-cy="field-permissions-table"
              className={`${fieldPermsBaseClassName}-table`}
              data={constructFieldPermsTableData()}
              columns={[<TableHeader label="Field Name" />, <TableHeader label="Permissions" />]}
            >
              {({ datum }) => (
                <Row>
                  <Cell
                    className={classNames(`${fieldPermsBaseClassName}-field`, {
                      [`${fieldPermsBaseClassName}-field-name`]:
                        !fieldNameToEdit && datum.fieldData.type === FieldType.Field,
                    })}
                    onClick={() => {
                      if (!fieldNameToEdit && isFieldWithFieldKeyData(datum.fieldData)) {
                        setFieldNameToEdit(datum.fieldData.fieldKey);
                        setEditedFieldNameValue(datum.fieldData.fieldKey);
                      }
                    }}
                  >
                    {renderFieldName(datum.fieldData)}
                  </Cell>
                  <Cell>{renderFieldPermissions(datum.fieldData)}</Cell>
                </Row>
              )}
            </Table>
          )}
        </div>

        <hr className={`${baseClassName}-divider`} />

        <SaveFooter
          data-testid={TestSelector.SaveFooterC}
          namePrefix="roles"
          onCancel={() => setIsCancelChangesModalOpen(true)}
          footerBanner={shouldShowFooterBanner ? syncIncompatibleFieldsBanner : undefined}
        />
      </div>
      <ConfirmationModal
        className={`${baseClassName}-cancel-modal`}
        title={roleDisplayName ? `Discard changes to role: ${roleDisplayName}` : 'Discard changes to role'}
        confirmButtonProps={{
          children: 'OK',
          onClick: onClickBackOrCancel,
        }}
        cancelButtonProps={{
          onClick: () => setIsCancelChangesModalOpen(false),
        }}
        variant={Variant.Danger}
        open={isCancelChangesModalOpen}
        data-testid={TestSelector.CancelAndExitModal}
        data-test-selector={TestSelector.CancelAndExitModal}
        data-cy="cancel-exit-modal"
      >
        Are you sure you want to discard your changes to{' '}
        {roleDisplayName ? (
          <span>
            <b>role: {roleDisplayName}</b>?
          </span>
        ) : (
          <span>the current role?</span>
        )}
      </ConfirmationModal>
    </div>
  );
};

export default EditRoleFormComponent;
