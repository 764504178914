import { List } from 'immutable';

import JSONState from 'baas-ui/models/JSONState';
import { BaseRule } from 'baas-ui/services/default/rule';

export default class Rule extends BaseRule({
  actions: new List(),
}) {
  static fromRawRule(raw) {
    return new Rule({
      id: raw.id,
      name: raw.name,
      actions: new List(raw.actions),
      when: JSONState.fromRaw(raw.when, {}),
      dirty: false,
    });
  }

  setApiAction(newValue, index) {
    return this.updateIn(['actions', index], (oldValue) => {
      const oldValueParts = oldValue.split(':');
      oldValueParts[1] = newValue;
      return oldValueParts.join(':');
    }).set('dirty', true);
  }

  setApi(newValue, index) {
    return this.setIn(['actions', index], `${newValue}:*`).set('dirty', true);
  }

  addAction(newValue) {
    return this.updateIn(['actions'], (oldValue) => oldValue.push(newValue)).set('dirty', true);
  }

  deleteAction(index) {
    return this.deleteIn(['actions', index]).set('dirty', true);
  }
}
