import React from 'react';
import { useDispatch } from 'react-redux';

import { loadTemplates } from 'baas-ui/app/actions';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { Template } from 'admin-sdk';

const useTemplates = (): [Template[], boolean, string, () => Promise<void>] => {
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');

  const dispatch = useDispatch<AsyncDispatch>();

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      setError('');
      const res = await dispatch(loadTemplates({}));
      if (res) {
        setTemplates(res);
      }
    } catch (err) {
      const assertedError = err as Error;
      setError(assertedError?.message);
    } finally {
      setLoading(false);
    }
  };

  return [templates, loading, error, fetchTemplates];
};

export default useTemplates;
