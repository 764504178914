import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { Namespaces } from 'baas-ui/services/mongodb/types';
import { BaseRequestPayload } from 'baas-ui/types';
import {
  BuiltinRule,
  BypassServiceChangeValue,
  MongoDBBaseRule,
  MongoDBNamespace,
  MongoDBNamespaceRule,
  MongoDBSyncIncompatibleRoles,
  PartialBuiltinRule,
  PartialMongoDBNamespaceRule,
  PartialServiceDesc,
  PresetRole,
} from 'admin-sdk';

export interface MongoDBDataSourceRequest extends BaseRequestPayload {
  dataSourceId: string;
}
export interface MongoDBRuleRequest extends MongoDBDataSourceRequest {
  ruleId: string;
}
export interface MongoDBDeleteRulesRequest extends MongoDBDataSourceRequest {
  databaseName?: string;
}
export interface MongoDBUpdateRuleRequest extends MongoDBRuleRequest {
  rule: BuiltinRule | MongoDBNamespaceRule;
}
export interface MongoDBCreateRuleRequest extends MongoDBDataSourceRequest {
  rule: BuiltinRule | MongoDBNamespaceRule;
}
export interface MongoDBDefaultRuleRequest extends MongoDBDataSourceRequest {
  rule: MongoDBBaseRule;
}

const NAME = 'data sources/';

export const [loadDataSourcesActions, loadDataSources] = createActionsAndExecutor<
  BaseRequestPayload,
  PartialServiceDesc[]
>(
  `${NAME}load data sources`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).services().list()
);

export const [listDataSourceNamespacesActions, listDataSourceNamespaces] = createActionsAndExecutor<
  MongoDBDataSourceRequest,
  Namespaces
>(
  `${NAME}list data source namespaces`,
  (client, { groupId, appId, dataSourceId }) =>
    () =>
      client.apps(groupId).app(appId).services().service(dataSourceId).runCommand('list_namespaces', {})
);

export const [loadRulesActions, loadRules] = createActionsAndExecutor<
  MongoDBDataSourceRequest,
  (PartialMongoDBNamespaceRule | PartialBuiltinRule)[]
>(
  `${NAME}load data source rules`,
  (client, { groupId, appId, dataSourceId }) =>
    () =>
      client.apps(groupId).app(appId).services().service(dataSourceId).rules().list()
);

export const [loadDefaultRuleActions, loadDefaultRule] = createActionsAndExecutor<
  MongoDBDataSourceRequest,
  MongoDBBaseRule
>(
  `${NAME}load data source default rule`,
  (client, { groupId, appId, dataSourceId }) =>
    () =>
      client.apps(groupId).app(appId).services().service(dataSourceId).defaultRule().get()
);

export const [loadNamespaceRuleActions, loadNamespaceRule] = createActionsAndExecutor<
  MongoDBRuleRequest,
  MongoDBNamespaceRule | BuiltinRule
>(
  `${NAME}load data source namespace rule`,
  (client, { groupId, appId, dataSourceId, ruleId }) =>
    () =>
      client.apps(groupId).app(appId).services().service(dataSourceId).rules().rule(ruleId).get()
);

export const [loadPresetRolesActions, loadPresetRoles] = createActionsAndExecutor<{}, PresetRole[]>(
  `${NAME}load preset roles`,
  (client) => () => client.rules().presetRoles().list()
);

export const [deleteDefaultRuleActions, deleteDefaultRule] = createActionsAndExecutor<MongoDBDataSourceRequest, void>(
  `${NAME}delete mongo data source default rule`,
  (client, { groupId, appId, dataSourceId }) =>
    () =>
      client.apps(groupId).app(appId).services().service(dataSourceId).defaultRule().remove()
);

export const [deleteRuleActions, deleteRule] = createActionsAndExecutor<MongoDBRuleRequest, void>(
  `${NAME}delete mongo data source rule`,
  (client, { groupId, appId, dataSourceId, ruleId }) =>
    () =>
      client.apps(groupId).app(appId).services().service(dataSourceId).rules().rule(ruleId).remove()
);

export const [deleteRulesActions, deleteRules] = createActionsAndExecutor<MongoDBDeleteRulesRequest, void>(
  `${NAME}delete mongo data source rules`,
  (client, { groupId, appId, dataSourceId, databaseName }) =>
    () =>
      client.apps(groupId).app(appId).services().service(dataSourceId).rules().remove(databaseName)
);

export const [updateRuleActions, updateRule] = createActionsAndExecutor<
  MongoDBUpdateRuleRequest & { bypassServiceChange?: BypassServiceChangeValue },
  void
>(
  `${NAME}update mongo data source rule`,
  (client, { groupId, appId, dataSourceId, ruleId, rule, bypassServiceChange }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .services()
        .service(dataSourceId)
        .rules()
        .rule(ruleId)
        .update(new MongoDBNamespaceRule({ ...rule }), bypassServiceChange)
);

export const [createRuleActions, createRule] = createActionsAndExecutor<
  MongoDBCreateRuleRequest & { bypassServiceChange?: BypassServiceChangeValue },
  PartialMongoDBNamespaceRule | PartialBuiltinRule
>(
  `${NAME}create mongo data source rule`,
  (client, { groupId, appId, dataSourceId, rule, bypassServiceChange }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .services()
        .service(dataSourceId)
        .rules()
        .create(new MongoDBNamespaceRule({ ...rule }), bypassServiceChange)
);

export const [updateDefaultRuleActions, updateDefaultRule] = createActionsAndExecutor<
  MongoDBDefaultRuleRequest & { bypassServiceChange?: BypassServiceChangeValue },
  void
>(
  `${NAME}update mongo data source  default rule`,
  (client, { groupId, appId, dataSourceId, rule, bypassServiceChange }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .services()
        .service(dataSourceId)
        .defaultRule()
        .update(new MongoDBBaseRule({ ...rule }), bypassServiceChange)
);

export const [createDefaultRuleActions, createDefaultRule] = createActionsAndExecutor<
  MongoDBDefaultRuleRequest & { bypassServiceChange?: BypassServiceChangeValue },
  MongoDBBaseRule
>(
  `${NAME}create mongo data source default rule`,
  (client, { groupId, appId, dataSourceId, rule, bypassServiceChange }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .services()
        .service(dataSourceId)
        .defaultRule()
        .create(new MongoDBBaseRule({ ...rule }), bypassServiceChange)
);

export const [loadDefaultRuleNamespacesActions, loadDefaultRuleNamespaces] = createActionsAndExecutor<
  MongoDBDataSourceRequest,
  MongoDBNamespace[]
>(
  `${NAME}load namespaces using default rule`,
  (client, { groupId, appId, dataSourceId }) =>
    () =>
      client.apps(groupId).app(appId).services().service(dataSourceId).defaultRule().namespaces()
);

export const [loadSyncIncompatibleRolesActions, loadSyncIncompatibleRoles] = createActionsAndExecutor<
  MongoDBDataSourceRequest,
  MongoDBSyncIncompatibleRoles
>(
  `${NAME}load sync incompatible roles`,
  (client, { groupId, appId, dataSourceId }) =>
    () =>
      client.apps(groupId).app(appId).services().service(dataSourceId).rules().syncIncompatibleRoles()
);

export const asyncEditRcvActions = [
  createRuleActions.rcv,
  createDefaultRuleActions.rcv,
  updateRuleActions.rcv,
  updateDefaultRuleActions.rcv,
  deleteRuleActions.rcv,
  deleteRulesActions.rcv,
  deleteDefaultRuleActions.rcv,
];
