import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import { AppDependencies } from 'admin-sdk';

const NAME = 'dependencies/';

interface UploadRequest extends BaseRequestPayload {
  filename: string;
  body: Blob;
}

interface DeletePackageRequest extends BaseRequestPayload {
  packageName: string;
}

// api actions
export const [loadDependenciesActions, loadDependencies] = createActionsAndExecutor<
  BaseRequestPayload,
  AppDependencies
>(
  `${NAME}load`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).dependencies().list()
);

export const [uploadDependenciesActions, uploadDependencies] = createActionsAndExecutor<UploadRequest, AppDependencies>(
  `${NAME}upload`,
  (client, { groupId, appId, filename, body }) =>
    () =>
      client.apps(groupId).app(appId).dependencies().put(filename, body)
);

export const [deleteAllDependenciesActions, deleteAllDependencies] = createActionsAndExecutor<BaseRequestPayload, void>(
  `${NAME}deleteAll`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).dependencies().deleteAll()
);

export const [deleteDependencyActions, deleteDependency] = createActionsAndExecutor<DeletePackageRequest, void>(
  `${NAME}deletePackage`,
  (client, { groupId, appId, packageName }) =>
    () =>
      client.apps(groupId).app(appId).dependencies().delete(packageName)
);

export const [fetchInstallStatusActions, fetchInstallStatus] = createActionsAndExecutor(
  `${NAME}fetch install status`,
  (client, { groupId, appId }: BaseRequestPayload) =>
    () =>
      client.apps(groupId).app(appId).dependencies().status()
);

export interface UpsertRequest extends BaseRequestPayload {
  packageName: string;
  packageVersion?: string;
}

export const [upsertDependencyActions, upsertDependency] = createActionsAndExecutor(
  `${NAME}upsert dependency`,
  (client, { groupId, appId, packageName, packageVersion }: UpsertRequest) =>
    () =>
      client.apps(groupId).app(appId).dependencies().upsert(packageName, packageVersion)
);

export const asyncEditRcvActions = [deleteAllDependenciesActions.rcv];
