export enum ZIndex {
  Base = 0,
  // Icons and dropdowns that need to be above inputs
  InputOverlay = 100,
  MongoNav = 200,
  ToolTip = 201,
  Toast = 300,
  Guide = 400,
  // Modal backgrounds should probably be above everything
  Modal = 500,
  // Used to calculate relative z indices above or below the "breakpoints"
  // above or relative to other elements with unique z indices
  Above = 1,
  Below = -1,
}
