import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { passThroughProps } from 'baas-ui/common/utils/util';

import './form-row.less';

export interface Props {
  children?: React.ReactNode;
  right?: boolean;
  className?: string;
}

export enum TestSelector {
  FormRowInputGroupContainer = 'form-row-input-group-container',
}

const FormRowInputGroup = ({ children, right, className, ...rest }: Props) => {
  return (
    <div
      data-testid={TestSelector.FormRowInputGroupContainer}
      className={classnames(
        'form-row-input-group',
        {
          'form-row-input-group-is-right': right,
        },
        className
      )}
      {...passThroughProps(rest)}
    >
      {children}
    </div>
  );
};

FormRowInputGroup.defaultProps = {
  children: null,
  className: '',
  right: false,
};

// Leave these until all components using this component are converted to TS
FormRowInputGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  right: PropTypes.bool,
};

export default FormRowInputGroup;
