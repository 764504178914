/* eslint-disable camelcase */
import { Deployment, DraftDiff, GitHubBranch, Installation, PartialCodeDeploy, PartialDraft } from 'admin-sdk';

export enum DraftAction {
  DEPLOY = 'DEPLOY',
  DISCARD = 'DISCARD',
}

export interface SelectOption {
  label: string;
  value: string;
}

export interface RepositorySelectOption extends SelectOption {
  installationId: string;
  fullName: string;
}

interface DeployHistoryState {
  [key: string]: Deployment;
}

interface State {
  draft?: PartialDraft;
  diff?: DraftDiff;
  deployConfig: PartialCodeDeploy;
  deployHistory: DeployHistoryState;
  latestDeployment?: Deployment;
  deployInstallations: Installation[];
  deployBranches: GitHubBranch[];
  lastDeployedAt?: number;
  deleteDraftError?: string;
  deployDraftError?: string;
  exportError?: string;
  loadDeployConfigError?: string;
  loadDeployHistoryError?: string;
  loadDeployInstallationError?: string;
  loadDeployBranchesError?: string;
  loadDraftsError?: string;
  loadDiffError?: string;
  loadDeploymentError?: string;
  loadLatestDeploymentError?: string;
  deploymentError?: string;
  loadingDeployConfig: boolean;
  loadingDeployHistory: boolean;
  loadingDeployInstallations: boolean;
  loadingDeployBranches: boolean;
  loadingDeployment: boolean;
  loadingLatestDeployment: boolean;
  loadingDiff: boolean;
  loadingDraft: boolean;
  mostRecentDeploy?: Deployment;
}

export type DeployState = Readonly<State>;
export type DeployStoreState = Readonly<State>;
