import { createReducer } from 'redux-act';

import * as actions from './actions';
import { MetricsState } from './types';

const defaultState: MetricsState = {
  isError: false,
  isLoading: false,
};

const reducer = createReducer<MetricsState>({}, defaultState);

reducer.on(actions.loadMetricsActions.req, (state) => ({
  ...state,
  isLoading: true,
  isError: false,
}));

reducer.on(actions.loadMetricsActions.rcv, (state, { payload }) => ({
  ...state,
  isLoading: false,
  metrics: payload,
}));

reducer.on(actions.loadMetricsActions.fail, (state) => ({
  ...state,
  isLoading: false,
  isError: true,
}));

export default reducer;
