import { Any, JsonObject, JsonProperty } from 'json2typescript';

export enum AuthProviderType {
  Userpass = 'local-userpass',
  Anonymous = 'anon-user',
  MongoDBCloud = 'mongodb-cloud',
  APIKey = 'api-key',
  Google = 'oauth2-google',
  Facebook = 'oauth2-facebook',
  Custom = 'custom-token',
  CustomFunction = 'custom-function',
  Apple = 'oauth2-apple',
}

@JsonObject('MetadataField')
export class MetadataField {
  @JsonProperty('required')
  public required = false;

  @JsonProperty('name')
  public name = '';

  @JsonProperty('field_name', String, true)
  public fieldName?: string = undefined;

  constructor(partial?: Partial<MetadataField>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AuthProviderConfig')
export class AuthProviderConfig {
  @JsonProperty('_id', String, true)
  public id?: string = undefined;

  @JsonProperty('name')
  public name: string = AuthProviderType.Userpass as string;

  @JsonProperty('type')
  public type: AuthProviderType = AuthProviderType.Userpass;

  @JsonProperty('metadata_fields', [MetadataField], true)
  public metadataFields?: MetadataField[] = undefined;

  @JsonProperty('domain_restrictions', [String], true)
  public domainRestrictions?: string[] = undefined;

  @JsonProperty('redirect_uris', [String], true)
  public redirectUris?: string[] = undefined;

  @JsonProperty('config', Any, true)
  public config?: Record<string, any> = undefined;

  @JsonProperty('secret_config', Any, true)
  public secretConfig?: Record<string, any> = undefined;

  @JsonProperty('disabled')
  public disabled = false;

  constructor(partial?: Partial<AuthProviderConfig>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PartialAuthProviderConfig')
export class PartialAuthProviderConfig {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('name')
  public name: string = AuthProviderType.Userpass as string;

  @JsonProperty('type')
  public type: AuthProviderType = AuthProviderType.Userpass;

  @JsonProperty('disabled')
  public disabled = false;

  constructor(partial?: Partial<PartialAuthProviderConfig>) {
    Object.assign(this, partial);
  }
}
