import { PasswordRecord } from 'admin-sdk';

import { PendingUser } from './types';

export const fromPasswordRecord = (raw: PasswordRecord): PendingUser => {
  const emailLoginId = raw.loginIds.find((loginId) => loginId.idType === 'email');
  return {
    name: emailLoginId ? emailLoginId.id : '',
    confirmed: emailLoginId ? !!emailLoginId.confirmed : false,
    recordID: raw.id,
    providers: 'Username/Password',
  };
};
