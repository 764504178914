import { createAction } from 'redux-act';

const defaultAlertTTL = 700;
const NAME = 'util/';

export const createAlert = createAction(`${NAME}create alert`);
export const expireAlert = createAction(`${NAME}expire alert`);

export const addAlert =
  (key, message, options = { timeout: defaultAlertTTL }) =>
  (dispatch) => {
    const id = Math.random();
    dispatch(createAlert({ key, id, message }));
    setTimeout(() => {
      dispatch(expireAlert({ key, id }));
    }, (options || {}).timeout || defaultAlertTTL);
  };
