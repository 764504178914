import { getUserProfile } from './actions';

const CANNY_APP_ID = '59efb9184492b302d6b20bc2';

const identifyWithCanny = (userProfile = { data: {} }) => {
  userProfile.data.email &&
    // eslint-disable-next-line no-undef
    Canny('identify', {
      appID: CANNY_APP_ID,
      user: {
        email: userProfile.data.email,
        id: userProfile.userId,
        name: userProfile.data.name ? userProfile.data.name : userProfile.data.email,
      },
    });
};

export const performUserProfileInitActions = () => (dispatch) => {
  return dispatch(getUserProfile()).then((userProfile) => {
    if (!process.env.DISABLE_CANNY) {
      identifyWithCanny(userProfile);
    }
    return userProfile;
  });
};
