import React from 'react';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import { Option, Select } from '@leafygreen-ui/select';
import { Body } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import LeafygreenRouterLink from 'baas-ui/common/components/leafygreen-router-link';
import { docLinks } from 'baas-ui/common/links';
import {
  migratedPresetRoleNames,
  migratedPresetRoleNameToDisplayNameMap,
  presetRoleDescriptions,
} from 'baas-ui/rules/preset-roles-display/constants';
import { PresetRole } from 'admin-sdk';

import './default-role-modal.less';

const baseClassName = 'default-role-modal';

export enum TestSelector {
  Modal = 'default-role-modal',
  DefaultRoleSelect = 'default-role-select',
  Description = 'default-role-modal-description',
  SelectOption = 'default-role-modal-select-option',
}

export interface Props {
  open: boolean;
  onClickConfirm(role: PresetRole | undefined): void;
  onCancel(): void;
  isLoadingRoles: boolean;
  presetRoles: PresetRole[];
  rulesUrl: string;
}

const getPresetRoleDisplayName = (presetRole) => {
  if (migratedPresetRoleNames.includes(presetRole.name)) {
    return migratedPresetRoleNameToDisplayNameMap[presetRole.name];
  }
  return presetRole.name;
};

const DefaultRoleModal = ({ isLoadingRoles, open, onClickConfirm, onCancel, presetRoles, rulesUrl }: Props) => {
  const [selectedRoleString, setSelectedRoleString] = React.useState('');

  return (
    <ConfirmationModal
      open={open}
      confirmButtonProps={{
        children: 'Add default role',
        disabled: selectedRoleString === '',
        onClick: () => {
          const selectedRole = presetRoles.find((presetRole) => presetRole.name === selectedRoleString);
          onClickConfirm(selectedRole);
        },
      }}
      cancelButtonProps={{
        onClick: onCancel,
      }}
      title={'Add a default role from a preset'}
      className={baseClassName}
      data-cy={TestSelector.Modal}
      data-testid={TestSelector.Modal}
    >
      <>
        <div data-testid={TestSelector.Description} data-cy={TestSelector.Description}>
          <Body className={`${baseClassName}-description`}>
            We noticed that you do not have permissions defined in Rules. Now that Sync is enabled, all access to your
            application is denied by default.
          </Body>
          <Body className={`${baseClassName}-description`}>
            To quickstart data access, you can select a <b>role preset</b> below, which will be added as a{' '}
            <DocLink href={docLinks.Rules.DefaultRule} showExternalIcon>
              default role
            </DocLink>
            in your Rules. Or, to add a custom role, navigate to{' '}
            <LeafygreenRouterLink to={rulesUrl}>Rules</LeafygreenRouterLink>.
          </Body>
        </div>
        <Select
          placeholder={isLoadingRoles ? 'Loading preset roles...' : 'Select a role preset'}
          aria-labelledby={baseClassName}
          onChange={setSelectedRoleString}
          name={TestSelector.DefaultRoleSelect}
          data-cy={TestSelector.DefaultRoleSelect}
          data-testid={TestSelector.DefaultRoleSelect}
          disabled={isLoadingRoles}
        >
          {presetRoles.map((role) => {
            return (
              <Option
                key={role.name}
                value={role.name}
                data-testid={`${TestSelector.SelectOption}-${role.name}`}
                data-cy={`${TestSelector.SelectOption}-${role.name}`}
              >
                {presetRoleDescriptions[getPresetRoleDisplayName(role)]}
              </Option>
            );
          })}
        </Select>
      </>
    </ConfirmationModal>
  );
};

export default DefaultRoleModal;
