import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { match as Match, RouteComponentProps, RouterChildContext, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

interface MatchParams {
  url: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {
  children: React.ReactNode;
  match: Match<MatchParams>;
  staticContext?: Record<string, any>;
  [otherProp: string]: any;
}

type ContextType = RouterChildContext & { through: any };

const StitchBreadcrumbsItem: React.FC<PropsType> = (
  { children, match, staticContext: _, ...props },
  { through }: ContextType
) => {
  // Testing is complicated due to the way BreadcrumbsItem expects
  // a BreadcrumbsProvider to be present. For now at least, disable
  // breadcrumbs if the through provider context is not available
  if (!through) {
    return null;
  }

  return (
    <BreadcrumbsItem to={match.url} {...props}>
      {children}
    </BreadcrumbsItem>
  );
};

StitchBreadcrumbsItem.propTypes = {
  children: PropTypes.node.isRequired,
  match: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

StitchBreadcrumbsItem.contextTypes = {
  through: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

export default withRouter(StitchBreadcrumbsItem);
