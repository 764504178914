import { JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';

import { DateConverter } from '../../Converter';
import createStringMapSerializers from '../common/createStringMapSerializers';

export enum BypassServiceChangeValue {
  DestructiveChangeNotAllowed = 'DestructiveChangeNotAllowed',
  InvalidSyncSchema = 'InvalidSyncSchema',
  SyncProtocolIncrease = 'SyncProtocolVersionIncrease',
  DestructiveSyncProtocolIncrease = 'DestructiveSyncProtocolVersionIncrease',
  SyncIncompatibleRole = 'SyncIncompatibleRole',
  SyncAdditiveSchemaChange = 'SyncAdditiveSchemaChange',
}

@JsonObject('SyncClientSchemaAlert')
export class SyncClientSchemaAlert {
  @JsonProperty('error_code')
  public errorCode = '';

  @JsonProperty('error')
  public error = '';

  constructor(partial?: Partial<SyncClientSchemaAlert>) {
    Object.assign(this, partial);
  }
}

@JsonObject('SyncClientSchema')
export class SyncClientSchema {
  @JsonProperty('service_id')
  public serviceId = '';

  @JsonProperty('rule_id')
  public ruleId = '';

  @JsonProperty('schema_id')
  public schemaId = '';

  @JsonProperty('collection_display_name')
  public collectionDisplayName = '';

  @JsonProperty('model_name')
  public modelName = '';

  @JsonProperty('import_statements')
  public importStatements: string[] = [];

  @JsonProperty('schema')
  public schema = '';

  @JsonProperty('warnings', [SyncClientSchemaAlert], true)
  public warnings: SyncClientSchemaAlert[] = [];

  @JsonProperty('error', SyncClientSchemaAlert, true)
  public error?: SyncClientSchemaAlert = undefined;

  constructor(partial?: Partial<SyncClientSchema>) {
    Object.assign(this, partial);
  }
}

@JsonObject('SyncConfig')
export class SyncConfig {
  @JsonProperty('development_mode_enabled')
  public developmentModeEnabled = false;

  @JsonProperty('service_id', String, true)
  public serviceId?: string = undefined;

  constructor(partial?: Partial<SyncConfig>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PartitionField')
export class PartitionField {
  @JsonProperty('key')
  public key = '';

  @JsonProperty('type')
  public type = '';

  @JsonProperty('required', Boolean, true)
  public required?: boolean = undefined;

  constructor(partial?: Partial<PartitionField>) {
    Object.assign(this, partial);
  }
}

@JsonObject('NumCollections')
export class NumCollections {
  @JsonProperty('num_collections')
  public numCollections? = 0;

  constructor(partial?: Partial<NumCollections>) {
    Object.assign(this, partial);
  }
}

@JsonObject('FieldList')
export class FieldList {
  @JsonProperty('fields')
  public fields?: string[];

  constructor(partial?: Partial<NumCollections>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AllowedQueryableFields')
export class AllowedQueryableFields {
  @JsonProperty('queryable_fields')
  public queryableFields?: Map<string, NumCollections>;

  @JsonProperty('queryable_fields_by_collection')
  public queryableFieldsByCollection?: Map<string, FieldList>;

  @JsonProperty('indexed_queryable_fields')
  public indexedQueryableFields?: FieldList;

  constructor(partial?: Partial<AllowedQueryableFields>) {
    Object.assign(this, partial);
  }
}

@JsonObject('HasSyncEnabledApps')
export class HasSyncEnabledAppsResponse {
  @JsonProperty('has_sync_enabled_apps')
  public hasSyncEnabledApps = false;

  constructor(partial?: Partial<HasSyncEnabledAppsResponse>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AllowedAsymmetricTables')
export class AllowedAsymmetricTables {
  @JsonProperty('asymmetric_tables')
  public asymmetricTables?: string[];

  constructor(partial?: Partial<AllowedAsymmetricTables>) {
    Object.assign(this, partial);
  }
}

@JsonObject('SyncData')
export class SyncData {
  @JsonProperty('service_id', String, true)
  public serviceId?: string = undefined;

  @JsonProperty('partition_fields', [PartitionField], true)
  public partitionFields?: PartitionField[] = undefined;

  // these are global queryable fields
  @JsonProperty('queryable_fields_names', [String], true)
  public queryableFieldsNames?: string[] = undefined;

  @JsonProperty('asymmetric_tables', [String], true)
  public asymmetricTables?: string[] = undefined;

  constructor(partial?: Partial<SyncData>) {
    Object.assign(this, partial);
  }
}

export enum PartitionKeyType {
  String = 'string',
  ObjectId = 'objectId',
  Long = 'long',
  UUID = 'uuid',
}

@JsonObject('PatchSyncSchemasRequest')
export class PatchSyncSchemasRequest {
  @JsonProperty('service_id')
  public serviceId = '';

  @JsonProperty('partition_key')
  public partitionKey = '';

  @JsonProperty('partition_key_type')
  public partitionKeyType: PartitionKeyType = PartitionKeyType.String;

  @JsonProperty('partition_key_required', Boolean, true)
  public partitionKeyRequired?: boolean = undefined;

  constructor(partial?: Partial<PatchSyncSchemasRequest>) {
    Object.assign(this, partial);
  }
}

export enum SchemaChangeType {
  COMPATIBLE = 'COMPATIBLE',
  INCOMPATIBLE = 'INCOMPATIBLE',
}

@JsonObject('SchemaVersionMetadata')
export class SchemaVersionMetadata {
  @JsonProperty('version_major', Number)
  public versionMajor = 0;

  @JsonProperty('schema_change_type', String)
  public schemaChangeType: SchemaChangeType = SchemaChangeType.COMPATIBLE;

  @JsonProperty('timestamp', DateConverter)
  public timestamp: Date = new Date();

  constructor(partial?: Partial<SchemaVersionMetadata>) {
    Object.assign(this, partial);
  }
}

@JsonObject('GetSchemaVersionsResponse')
export class GetSchemaVersionsResponse {
  @JsonProperty('versions', [SchemaVersionMetadata])
  public versions: SchemaVersionMetadata[] = [];

  constructor(partial?: Partial<GetSchemaVersionsResponse>) {
    Object.assign(this, partial);
  }
}

@JsonObject('SyncProgressDetails')
export class SyncProgressDetails {
  @JsonProperty('started_at')
  public startedAt = '';

  @JsonProperty('updated_at')
  public updatedAt = '';

  @JsonProperty('error', String, true)
  public error?: string = undefined;

  @JsonProperty('documents_completed', Number, true)
  public documentsCompleted?: number = undefined;

  @JsonProperty('total_documents', Number, true)
  public totalDocuments?: number = undefined;

  @JsonProperty('complete', Boolean, true)
  public complete?: boolean = undefined;

  constructor(partial?: Partial<SyncProgressDetails>) {
    Object.assign(this, partial);
  }
}

const syncProgressDetailsMapSerializers = createStringMapSerializers(SyncProgressDetails);
@JsonConverter
class SyncProgressDetailsMapConverter implements JsonCustomConvert<Record<string, SyncProgressDetails>> {
  public serialize = syncProgressDetailsMapSerializers.serialize;

  public deserialize = syncProgressDetailsMapSerializers.deserialize;
}

@JsonObject('SyncProgress')
export class SyncProgress {
  @JsonProperty('progress', SyncProgressDetailsMapConverter)
  public progress: Record<string, SyncProgressDetails> = {};

  constructor(partial?: Partial<SyncProgress>) {
    Object.assign(this, partial);
  }
}

export enum SyncState {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Terminating = 'terminating',
  /** Sync has been terminated OR has never been enabled. */
  Terminated = '',
}

@JsonObject('GetSyncStateResponse')
export class GetSyncStateResponse {
  @JsonProperty('state', String, true)
  public state: SyncState = SyncState.Terminated;

  @JsonProperty('last_processed_timestamp', DateConverter, true)
  public lastProcessedTimestamp?: Date = undefined;

  constructor(partial?: Partial<GetSyncStateResponse>) {
    Object.assign(this, partial);
  }
}

export enum AlertLevel {
  Error = 'ERROR',
  Warning = 'WARNING',
}

export enum LinkType {
  // External indicates this links outside atlas/app services and the link should show the external icon
  External = 'EXTERNAL',
  // Logs indicates that the alert is linking to the logs
  Logs = 'LOGS',
}

@JsonObject('SyncAlert')
export class SyncAlert {
  @JsonProperty('alert_key', String)
  public alertKey = '';

  @JsonProperty('level', String)
  public level?: AlertLevel = undefined;

  @JsonProperty('message', String)
  public message = '';

  @JsonProperty('link', String, true)
  public link?: string = undefined;

  @JsonProperty('link_type', String, true)
  public linkType?: LinkType = undefined;

  constructor(partial?: Partial<SyncAlert>) {
    Object.assign(this, partial);
  }
}

@JsonObject('GetSyncAlertsResponse')
export class GetSyncAlertsResponse {
  @JsonProperty('alerts', [SyncAlert])
  public alerts: SyncAlert[] = [];

  constructor(partial?: Partial<GetSyncAlertsResponse>) {
    Object.assign(this, partial);
  }
}

@JsonObject('SyncMigrationPrecheck')
export class SyncMigrationPrecheck {
  @JsonProperty('clusterName')
  public clusterName = '';

  @JsonProperty('clusterMongoDBVersion')
  public clusterMongoDBVersion = '';

  @JsonProperty('clusterTimeBasedOplogWindowHours')
  public clusterTimeBasedOplogWindowHours: number | null = null;

  @JsonProperty('last10DaysConnectionsTotal')
  public last10DaysConnectionsTotal = 0;

  @JsonProperty('last10DaysConnectionsSupportMigration')
  public last10DaysConnectionsSupportMigration = 0;

  @JsonProperty('canAutoMigratePermissions')
  public canAutoMigratePermissions = false;

  constructor(partial?: Partial<SyncMigrationPrecheck>) {
    Object.assign(this, partial);
  }
}

@JsonObject('SyncMigrationStatus')
export class SyncMigrationStatus {
  @JsonProperty('statusMessage')
  public statusMessage = '';

  @JsonProperty('errorMessage')
  public errorMessage = '';

  @JsonProperty('isMigrated')
  public isMigrated = false;

  @JsonProperty('isCancelable')
  public isCancelable = false;

  @JsonProperty('isRevertible')
  public isRevertible = false;

  @JsonProperty('clusterTimeBasedOplogWindowHours')
  public clusterTimeBasedOplogWindowHours: number | null = null;

  @JsonProperty('startedAt', DateConverter)
  public startedAt: Date = new Date();

  @JsonProperty('lastUpdated', DateConverter)
  public lastUpdated: Date = new Date();

  constructor(partial?: Partial<SyncMigrationStatus>) {
    Object.assign(this, partial);
  }
}

export enum SyncMigrationAction {
  Start = 'start',
  Cancel = 'cancel',
  Rollback = 'rollback',
  Commit = 'commit',
}

@JsonObject('PutSyncMigrationRequest')
export class PutSyncMigrationRequest {
  @JsonProperty('serviceId')
  public serviceId = '';

  @JsonProperty('action')
  public action: SyncMigrationAction = SyncMigrationAction.Start;

  constructor(data?: PutSyncMigrationRequest) {
    Object.assign(this, data);
  }
}
