import React from 'react';
import { Body, H3, Overline } from '@leafygreen-ui/typography';
import BrandIcon from '@mongodb-js/brand-icons';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';

import './select-collection-empty-state.less';

const baseClassName = 'select-collection-empty-state';

export enum TestSelector {
  SelectCollection = 'select-collection',
  RulesDocLink = 'rules-doc-link',
  Image = 'image',
  HeaderText = 'header-text',
}

export default function SelectCollectionEmptyState() {
  const overlineTextSection = (
    <div className={`${baseClassName}-or-section`}>
      <div className={`${baseClassName}-or-line`}>
        <span className={`${baseClassName}-or-text`}>
          <Overline>OR</Overline>
        </span>
      </div>
    </div>
  );
  return (
    <div
      className={`${baseClassName}-get-started`}
      data-test-selector={TestSelector.SelectCollection}
      data-cy="empty-state-select-a-collection"
    >
      <BrandIcon iconName="Technical_MDB_Collection" height={150} width={150} data-test-selector={TestSelector.Image} />
      <H3 data-test-selector={TestSelector.HeaderText} className={`${baseClassName}-get-started-title`}>
        Select a Collection
      </H3>
      <Body className={`${baseClassName}-get-started-description`}>
        <DocLink data-test-selector={TestSelector.RulesDocLink} href={docLinks.Rules.LearnMore} showExternalIcon>
          <span style={{ fontSize: '15px' }}>Add rules</span>
        </DocLink>{' '}
        to control the app&apos;s access to your MongoDB data. Get started by selecting a collection from the left-hand
        panel.
      </Body>
      <div className={`${baseClassName}-get-started-description-divider`}>{overlineTextSection}</div>
      <Body className={`${baseClassName}-get-started-description`}>
        Select <b>Default roles and filters</b> under one of your clusters on the left, to apply a single set of
        roles/filters to every unconfigured collection.
      </Body>
    </div>
  );
}
