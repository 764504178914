import { Middleware } from 'redux';

import { setFirstAppSaveAction, setFirstDraftSaveAction } from 'baas-ui/actions';
import { firstAppSave } from 'baas-ui/common/local-storage-keys';
import { errorHandlerNotify } from 'baas-ui/error_util';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { getAppState, getDeploymentState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';

import { asyncEditRcvActions, getActionType, ignoredSaveActions } from './constants';

const saveMiddleware: Middleware<{}, RootState, AsyncDispatch> =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const { app } = getAppState(getState());
    const { deployConfig } = getDeploymentState(getState());

    const { automaticDeploymentConfig, uiDraftsDisabled } = deployConfig;
    const { enabled: autoDeployEnabled } = automaticDeploymentConfig;

    if (
      asyncEditRcvActions[getActionType(action)] &&
      !ignoredSaveActions.map((exception) => getActionType(exception)).includes(getActionType(action))
    ) {
      if (!uiDraftsDisabled) {
        // drafts are enabled
        try {
          const localFirstAppSave = !!window.localStorage.getItem(firstAppSave(app.id));
          const sessionFirstAppSave = !!window.sessionStorage.getItem(firstAppSave(app.id));

          if (!localFirstAppSave) {
            // app has not be saved before
            dispatch(setFirstAppSaveAction());
            window.localStorage.setItem(firstAppSave(app.id), 'true');
          } else if (localFirstAppSave && !sessionFirstAppSave && autoDeployEnabled) {
            // app has not saved this session
            dispatch(setFirstDraftSaveAction());
            window.sessionStorage.setItem(firstAppSave(app.id), 'true');
          }
        } catch (err) {
          errorHandlerNotify(err);
        }
      }
    }
    next(action);
  };

export default saveMiddleware;
