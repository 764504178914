export enum Location {
  Frankfurt = 'DE-FF',
  Ireland = 'IE',
  Mumbai = 'IN-MB',
  Oregon = 'US-OR',
  Singapore = 'SG',
  Sydney = 'AU',
  Virginia = 'US-VA',
  SaoPaulo = `BR-SP`,
}

export enum ProviderRegion {
  AWSProviderRegionUSEast1 = 'aws-us-east-1',
  AWSProviderRegionUSWest2 = 'aws-us-west-2',
  AWSProviderRegionEUCentral1 = 'aws-eu-central-1',
  AWSProviderRegionEUWest1 = 'aws-eu-west-1',
  AWSProviderRegionAPSoutheast1 = 'aws-ap-southeast-1',
  AWSProviderRegionAPSoutheast2 = 'aws-ap-southeast-2',
  AWSProviderRegionAPSouth1 = 'aws-ap-south-1',
  AWSProviderRegionUSEast2 = 'aws-us-east-2',
  AWSProviderRegionEUWest2 = 'aws-eu-west-2',
  AWSProviderRegionSAEast1 = 'aws-sa-east-1',
  AzureProviderRegionEastUS2 = 'azure-eastus2',
  AzureProviderRegionWestUS = 'azure-westus',
  AzureProviderRegionWestEurope = 'azure-westeurope',
  AzureProviderRegionEastAsia = 'azure-eastasia',
  AzureProviderRegionSouthEastAsia = 'azure-southeastasia',
  GCPProviderRegionUSCentral1 = 'gcp-us-central1',
  GCPProviderRegionUSWest1 = 'gcp-us-west1',
  GCPProviderRegionEuropeWest1 = 'gcp-europe-west1',
  GCPProviderRegionAsiaSouth1 = 'gcp-asia-south1',
  GCPProviderRegionUSEast4 = 'gcp-us-east4',
}

export enum DeploymentModel {
  Global = 'GLOBAL',
  Local = 'LOCAL',
}

export enum AppEnvironment {
  None = '',
  Development = 'development',
  Testing = 'testing',
  Staging = 'staging',
  QA = 'qa',
  Production = 'production',
}
