import { JsonObject, JsonProperty } from 'json2typescript';

import { DeploymentModel, ProviderRegion } from '../common';

@JsonObject('ProviderRegionData')
export class ProviderRegionData {
  @JsonProperty('id')
  public id = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('deployment_models')
  public deploymentModels: DeploymentModel[] = [];

  @JsonProperty('cloud_provider')
  public cloudProvider = '';

  @JsonProperty('country')
  public country = '';

  constructor(partial?: Partial<ProviderRegionData>) {
    Object.assign(this, partial);
  }
}

@JsonObject('NearestProviderRegion')
export class NearestProviderRegion {
  @JsonProperty('nearest_provider_region')
  public nearestProviderRegion: ProviderRegion = null!;

  constructor(required?: Required<NearestProviderRegion>) {
    Object.assign(this, required);
  }
}
