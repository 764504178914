import { Any, JsonObject, JsonProperty } from 'json2typescript';

import { IncomingWebhook } from './IncomingWebhook';
import { Rule, RulesConverter } from './Rule';

@JsonObject('ServiceDesc')
export class ServiceDesc {
  @JsonProperty('_id', String, true)
  public id?: string = undefined;

  @JsonProperty('name')
  public name = '';

  @JsonProperty('type')
  public type = '';

  @JsonProperty('config', Any, true)
  public config?: Record<string, any> = undefined;

  @JsonProperty('secret_config', Any, true)
  public secretConfig?: Record<string, any> = undefined;

  @JsonProperty('rules', RulesConverter, true)
  public rules?: Rule[] = undefined;

  @JsonProperty('incoming_webhooks', [IncomingWebhook], true)
  public incomingWebhooks?: IncomingWebhook[] = undefined;

  @JsonProperty('version', Number, true)
  public version?: number = undefined;

  @JsonProperty('last_modified', Number, true)
  public lastModified?: number = undefined;

  constructor(partial?: Partial<ServiceDesc>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PartialServiceDesc')
export class PartialServiceDesc {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('type')
  public type = '';

  @JsonProperty('version', Number, true)
  public version?: number = undefined;

  @JsonProperty('last_modified', Number, true)
  public lastModified?: number = undefined;

  constructor(partial?: Partial<PartialServiceDesc>) {
    Object.assign(this, partial);
  }
}

@JsonObject('ServiceDescConfig')
export class ServiceDescConfig {
  @JsonProperty('config', Any, true)
  public config: Record<string, any> = {};

  @JsonProperty('secret_config', Any, true)
  public secretConfig?: Record<string, any> = undefined;

  constructor(partial?: Partial<ServiceDescConfig>) {
    Object.assign(this, partial);
  }
}
