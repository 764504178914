/* eslint-disable camelcase */
import { OptionProps } from 'react-select/src/types';

import { MeasurementsByName } from 'baas-ui/measurements/types';
import { Location, MeasurementName, PartialApp } from 'admin-sdk';

export interface LocationOptionTypes extends Partial<OptionProps> {
  label: string;
  value: Location;
}

export interface RegionOptionTypes extends Partial<OptionProps> {
  label: string;
  value: string;
}

export interface CloudProviderOptionTypes extends Partial<OptionProps> {
  label: string;
  value: CloudProvider;
}

export enum RequestError {
  NotFound = 'Not Found',
  Forbidden = 'Forbidden',
  NoError = '',
}

export enum DataSourceLinkMethod {
  CreateCluster = 'create-cluster',
  UseExisting = 'use-existing',
}

export type MeasurementData = {
  [K in MeasurementName]: { usage: string };
};

export enum CloudProvider {
  AWS = 'aws',
  GCP = 'gcp',
  AZURE = 'azure',
}

export enum ClusterState {
  Idle = 'IDLE',
  Creating = 'CREATING',
  Updating = 'UPDATING',
  Deleting = 'DELETING',
  Deleted = 'DELETED',
  Repairing = 'REPAIRING',
}

export enum DataLakeState {
  Active = 'ACTIVE',
  Unverified = 'UNVERIFIED',
}

export enum DefaultDataSourceServiceName {
  Cluster = 'mongodb-atlas',
  DataLake = 'mongodb-datafederation',
  OnlineArchive = 'mongodb-onlinearchive',
}

export enum ClusterProvisionToastState {
  None = 'none',
  CreatingCluster = 'creating-cluster',
  CreatingSucceeded = 'creating-succeeded',
  Error = 'error',
}

export enum CreateClusterErrorMessages {
  FreeClusterExists = 'This project already has another free cluster',
}

export enum AppProduct {
  Standard = 'standard',
  Atlas = 'atlas',
  DataAPI = 'data-api',
  DeviceSync = 'device-sync',
  EdgeServer = 'edge-server',
  Charts = 'charts',
}

/** contains the list of product types the app services UI should load */
export const appServicesVisibleProductTypes = [
  AppProduct.Standard,
  AppProduct.Atlas,
  AppProduct.DataAPI,
  AppProduct.DeviceSync,
  AppProduct.EdgeServer,
];

interface State {
  groupId: string;
  apps: Readonly<PartialApp[]>;
  loading: boolean;
  showNewAppModal: boolean;
  createAppError: string;
  dataSourceBuildError: string;
  error: string;
  clusterProvisionToastState: ClusterProvisionToastState;
  showSideNav: boolean;
}

export type AppsStoreState = Readonly<State>;

export interface AppDataResult {
  userCount: number;
  lastHourSuccessfulRequests: number;
  lastHourFailedRequests: number;
  measurementsByName: MeasurementsByName;
}

export interface RecaptchaPayload {
  token: string;
}
