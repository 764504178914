import { createReducer } from 'redux-act';

import AWSRule from 'baas-ui/services/aws/rule';
import { makeBaseActions } from 'baas-ui/services/default/actions';
import DefaultRule from 'baas-ui/services/default/rule';

import defaultActions from './actions';

interface SaveState {
  error?: string;
  saving: boolean;
}

export interface DefaultRulesState<RuleClass> {
  loadingRules: boolean;
  error?: string;
  ruleSaveState: Record<string, SaveState>;
  rulesById: Record<string, RuleClass>;
  pristineRules: Record<string, RuleClass>;
  serviceId?: string;
}

export const defaultState: DefaultRulesState<DefaultRule> = {
  loadingRules: false,
  error: undefined,
  ruleSaveState: {},
  rulesById: {},
  pristineRules: {},
  serviceId: undefined,
};

export function makeReducerForRuleClass(
  RuleClass: DefaultRule | AWSRule,
  actions: ReturnType<typeof makeBaseActions>,
  defaultRuleState: DefaultRulesState<DefaultRule | AWSRule>
) {
  const defaultRuleReducer = createReducer<DefaultRulesState<DefaultRule | AWSRule>>({}, defaultRuleState);

  defaultRuleReducer.on(actions.loadRulesActions.req, (state, { svcId: nextServiceId }) => ({
    ...state,
    rulesById: nextServiceId === state.serviceId ? state.rulesById : {},
    loadingRules: true,
  }));

  defaultRuleReducer.on(actions.loadRulesActions.rcv, (state, { payload, reqArgs }) => {
    const rulesById: Record<string, DefaultRule | AWSRule> = payload.reduce((acc, rule) => {
      return {
        ...acc,
        [rule.id]: RuleClass.fromRawRule(rule),
      };
    }, {});

    return {
      ...state,
      error: undefined,
      serviceId: reqArgs.svcId,
      loadingRules: false,
      rulesById,
      pristineRules: rulesById,
    };
  });

  defaultRuleReducer.on(actions.loadRulesActions.fail, (state, { error }) => ({
    ...state,
    loadingRules: false,
    error: `Failed to load rules: ${error}`,
  }));

  defaultRuleReducer.on(actions.loadRuleActions.rcv, (state, { payload, reqArgs }) => {
    const fullRule = RuleClass.fromRawRule(payload);

    return {
      ...state,
      rulesById: { ...state.rulesById, [reqArgs.ruleId]: fullRule },
      pristineRules: { ...state.rulesById, [reqArgs.ruleId]: fullRule },
    };
  });

  defaultRuleReducer.on(actions.loadRuleActions.fail, (state, { reqArgs: { ruleId }, error }) => {
    return {
      ...state,
      error: `Failed to load rule with id '${ruleId}': ${error}`,
    };
  });

  defaultRuleReducer.on(actions.addRuleActions.rcv, (state, { payload }) => {
    const newRule = RuleClass.fromRawRule(payload);

    return {
      ...state,
      error: undefined,
      rulesById: { ...state.rulesById, [newRule.id]: newRule },
      pristineRules: { ...state.rulesById, [newRule.id]: newRule },
    };
  });

  defaultRuleReducer.on(actions.addRuleActions.fail, (state, { error }) => ({
    ...state,
    error: `Failed to add rule: ${error}`,
  }));

  defaultRuleReducer.on(actions.deleteRuleActions.rcv, (state, { reqArgs: { ruleId } }) => {
    const newRules = { ...state.rulesById };
    delete newRules[ruleId];

    return {
      ...state,
      rulesById: newRules,
      pristineRules: newRules,
      ruleSaveState: {},
    };
  });

  defaultRuleReducer.on(actions.deleteRuleActions.fail, (state, { reqArgs: { ruleId }, error }) => ({
    ...state,
    ruleSaveState: {
      ...state.ruleSaveState,
      [ruleId]: { error: `Failed to delete rule with id '${ruleId}': ${error}`, saving: false },
    },
  }));

  defaultRuleReducer.on(actions.discardChanges, (state, { ruleId }) => ({
    ...state,
    rulesById: { ...state.rulesById, [ruleId]: state.pristineRules[ruleId] },
  }));

  defaultRuleReducer.on(actions.setRuleWhen, (state, { ruleId, value }) => {
    const rule = state.rulesById[ruleId];

    return {
      ...state,
      rulesById: { ...state.rulesById, [ruleId]: rule.updateWhenInput(value) },
    };
  });

  defaultRuleReducer.on(actions.setRuleAction, (state, { ruleId, action, enabled }) => {
    const rule = state.rulesById[ruleId];

    return {
      ...state,
      rulesById: { ...state.rulesById, [ruleId]: rule.setAction(action, enabled) },
    };
  });

  defaultRuleReducer.on(actions.updateRuleActions.req, (state, { ruleId }) => ({
    ...state,
    ruleSaveState: {
      ...state.ruleSaveState,
      [ruleId]: {
        ...state.ruleSaveState[ruleId],
        saving: true,
      },
    },
  }));

  defaultRuleReducer.on(actions.updateRuleActions.rcv, (state, { reqArgs: { ruleId } }) => ({
    ...state,
    rulesById: { ...state.rulesById, [ruleId]: state.rulesById[ruleId].set('dirty', false) },
    pristineRules: { ...state.pristineRules, [ruleId]: state.rulesById[ruleId] },
    ruleSaveState: {
      ...state.ruleSaveState,
      [ruleId]: {
        error: undefined,
        saving: false,
      },
    },
  }));

  defaultRuleReducer.on(actions.updateRuleActions.fail, (state, { reqArgs: { ruleId }, error }) => ({
    ...state,
    ruleSaveState: {
      ...state.ruleSaveState,
      [ruleId]: {
        error,
        saving: false,
      },
    },
  }));

  return defaultRuleReducer;
}

export default makeReducerForRuleClass(DefaultRule, defaultActions, defaultState);
