import PropTypes from 'prop-types';

const ExternalRedirect = ({ url }) => {
  window.location.replace(url);
  return null;
};

ExternalRedirect.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ExternalRedirect;
