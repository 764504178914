import React from 'react';
import Card from '@leafygreen-ui/card';
import Icon from '@leafygreen-ui/icon';
import IconButton from '@leafygreen-ui/icon-button';
import { Menu, MenuItem } from '@leafygreen-ui/menu';
import { palette } from '@leafygreen-ui/palette';
import { Body, Subtitle } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import './filter-card.less';

export enum TestSelector {
  ActionsMenu = 'actions-menu',
  CardComponent = 'card',
  DeleteFilter = 'delete-filter',
  EditFilter = 'edit-filter',
  Name = 'name',
}

export interface Props {
  className?: string;
  name: string;
  onClickDelete(): void;
  onClickEdit(): void;
}

const baseClassName = 'filter-card';
const menuItemContentClassName = `${baseClassName}-menu-item-content`;
const menuItemContentIconClassName = `${menuItemContentClassName}-icon`;

const FilterCard = ({ className = '', name, onClickEdit, onClickDelete }: Props) => {
  return (
    <Card
      className={classNames(baseClassName, className)}
      onClick={onClickEdit}
      data-testid={TestSelector.CardComponent}
      data-test-selector={TestSelector.CardComponent}
      data-cy={TestSelector.CardComponent}
    >
      <Subtitle className={`${baseClassName}-name`} data-test-selector={TestSelector.Name}>
        {name}
      </Subtitle>
      <Menu
        trigger={
          <IconButton
            aria-label="open filter menu"
            className={`${baseClassName}-menu-trigger`}
            data-cy="filter-card-menu-trigger"
            data-testid={TestSelector.ActionsMenu}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Icon glyph="Ellipsis" fill={palette.blue.base} size="xlarge" />
          </IconButton>
        }
      >
        <MenuItem
          data-test-selector={TestSelector.EditFilter}
          data-testid={TestSelector.EditFilter}
          data-cy={TestSelector.EditFilter}
          onClick={onClickEdit}
        >
          <div className={menuItemContentClassName}>
            <Icon glyph="Edit" size="large" className={menuItemContentIconClassName} />
            <Body className={`${menuItemContentClassName}-edit`}>Edit filter</Body>
          </div>
        </MenuItem>
        <MenuItem
          data-test-selector={TestSelector.DeleteFilter}
          data-testid={TestSelector.DeleteFilter}
          data-cy={TestSelector.DeleteFilter}
          onClick={onClickDelete}
        >
          <div className={menuItemContentClassName}>
            <Icon glyph="Trash" size="large" fill={palette.red.base} className={menuItemContentIconClassName} />
            <Body className={`${menuItemContentClassName}-delete`}>Delete filter</Body>
          </div>
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default FilterCard;
