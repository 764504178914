import React from 'react';
import Icon from '@leafygreen-ui/icon';
import { palette } from '@leafygreen-ui/palette';
import { Overline } from '@leafygreen-ui/typography';
import classnames from 'classnames';

import { passThroughProps } from 'baas-ui/common/utils/util';

import './collapsible-example.less';

interface CollapsibleExampleProps {
  className?: string;
  collapsibleText?: string;
}

export type Props = React.PropsWithChildren<CollapsibleExampleProps>;

export enum TestSelector {
  Example = 'example',
  ChevronIcon = 'chevron-icon',
  ExampleHeader = 'example-header',
  ViewExample = 'view-example',
}

const baseClassName = 'collapsible-example';

const CollapsibleExample = ({ className, collapsibleText = 'View Example', children, ...rest }: Props) => {
  const [showCollapsedExample, setShowCollapsedExample] = React.useState(false);
  const passedProps = passThroughProps(rest);
  return (
    <div data-testid={TestSelector.Example} className={classnames(baseClassName, className)} {...passedProps}>
      <div
        className={`${baseClassName}-heading`}
        onClick={() => setShowCollapsedExample(!showCollapsedExample)}
        data-testid={TestSelector.ExampleHeader}
      >
        <Icon
          data-testid={TestSelector.ChevronIcon}
          className={`${baseClassName}-heading-icon-${showCollapsedExample ? 'open' : 'closed'}`}
          glyph="ChevronRight"
          size="small"
          color={palette.blue.base}
        />
        <Overline className={`${baseClassName}-heading-text`} data-testid={TestSelector.ViewExample}>
          {collapsibleText}
        </Overline>
      </div>
      {showCollapsedExample && children}
    </div>
  );
};

export default CollapsibleExample;
