import { createReducer } from 'redux-act';

import { makeDefaultAsyncDataState } from 'baas-ui/redux_util';

import * as actions from './actions';
import { fromRawValidateCollectionResults } from './converters';
import { DataSourceState } from './types';
import { makeCollectionNamespace, mergeNamespaces } from './util';

export const defaultState: DataSourceState = {
  collValidationResultsById: {},
  createCollectionError: undefined,
  estimatedDocumentCountByNamespace: {},
  namespaces: [],
  namespacesByClusterId: {},
};

const dataSourceReducer = createReducer<DataSourceState>({}, defaultState);

// Validation
dataSourceReducer.on(actions.validateCollectionDocumentsActions.rcv, (state, { payload, reqArgs: { ruleId } }) => ({
  ...state,
  collValidationResultsById: {
    ...state.collValidationResultsById,
    [ruleId]: fromRawValidateCollectionResults(payload),
  },
}));

dataSourceReducer.on(actions.clearValidationResults, (state, { id }) => ({
  ...state,
  collValidationResultsById: {
    ...state.collValidationResultsById,
    [id]: undefined,
  },
}));

// List Namespaces
dataSourceReducer.on(actions.listSvcNamespacesActions.req, (state, { svcId }) => {
  return {
    ...state,
    namespacesByClusterId: {
      ...state.namespacesByClusterId,
      [svcId]: {
        ...makeDefaultAsyncDataState(),
        ...state.namespacesByClusterId[svcId],
        isLoading: true,
        error: undefined,
      },
    },
  };
});
dataSourceReducer.on(actions.listSvcNamespacesActions.rcv, (state, { payload, reqArgs: { svcId } }) => {
  return {
    ...state,
    namespacesByClusterId: {
      ...state.namespacesByClusterId,
      [svcId]: {
        ...state.namespacesByClusterId[svcId],
        isLoading: false,
        data: payload,
        error: undefined,
      },
    },
  };
});
dataSourceReducer.on(actions.listSvcNamespacesActions.fail, (state, { reqArgs: { svcId }, error }) => {
  return {
    ...state,
    namespacesByClusterId: {
      ...state.namespacesByClusterId,
      [svcId]: {
        ...state.namespacesByClusterId[svcId],
        isLoading: false,
        error,
      },
    },
  };
});

// Create Collection
dataSourceReducer.on(actions.createCollectionActions.req, (state) => ({
  ...state,
  createCollectionError: undefined,
}));
dataSourceReducer.on(actions.createCollectionActions.rcv, (state) => ({
  ...state,
  createCollectionError: undefined,
}));
dataSourceReducer.on(actions.createCollectionActions.fail, (state, { error }) => ({
  ...state,
  createCollectionError: error,
}));

// Load Estimated Documents
dataSourceReducer.on(actions.loadEstimatedDocumentsActions.req, (state, { svcId, collectionName, databaseName }) => {
  const namespace = makeCollectionNamespace({
    dataSourceName: svcId,
    database: databaseName,
    collection: collectionName,
  });

  return {
    ...state,
    estimatedDocumentCountByNamespace: {
      ...state.estimatedDocumentCountByNamespace,
      [namespace]: {
        ...makeDefaultAsyncDataState(),
        ...state.estimatedDocumentCountByNamespace[namespace],
        isLoading: true,
        error: undefined,
      },
    },
  };
});

dataSourceReducer.on(
  actions.loadEstimatedDocumentsActions.rcv,
  (state, { payload, reqArgs: { svcId, collectionName, databaseName } }) => {
    const namespace = makeCollectionNamespace({
      dataSourceName: svcId,
      database: databaseName,
      collection: collectionName,
    });

    return {
      ...state,
      estimatedDocumentCountByNamespace: {
        ...state.estimatedDocumentCountByNamespace,
        [namespace]: {
          ...state.estimatedDocumentCountByNamespace[namespace],
          isLoading: false,
          data: payload,
          error: undefined,
        },
      },
    };
  }
);

dataSourceReducer.on(
  actions.loadEstimatedDocumentsActions.fail,
  (state, { reqArgs: { svcId, collectionName, databaseName }, error }) => {
    const namespace = makeCollectionNamespace({
      dataSourceName: svcId,
      database: databaseName,
      collection: collectionName,
    });

    return {
      ...state,
      estimatedDocumentCountByNamespace: {
        ...state.estimatedDocumentCountByNamespace,
        [namespace]: {
          ...state.estimatedDocumentCountByNamespace[namespace],
          isLoading: false,
          error,
        },
      },
    };
  }
);

// Update Namespaces
dataSourceReducer.on(actions.updateNamespaces, (state, namespaces) => ({
  ...state,
  namespaces: mergeNamespaces(namespaces, state.namespaces),
}));

export default dataSourceReducer;
