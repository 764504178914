import { JsonObject, JsonProperty } from 'json2typescript';

export enum EndpointHTTPMethod {
  POST = 'POST',
  GET = 'GET',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
  ANY = '*',
}

export enum EndpointValidationMethod {
  NoValidation = 'NO_VALIDATION',
  VerifyPayload = 'VERIFY_PAYLOAD',
  SecretAsQueryParam = 'SECRET_AS_QUERY_PARAM',
}

export enum WebhookConversionStrategy {
  DryRun = 'dryRun',
  Overwrite = 'overwrite',
  NoStrategy = '',
}

export enum EndpointReturnType {
  JSON = 'JSON',
  EJSON = 'EJSON',
}

@JsonObject('Endpoint')
export class Endpoint {
  @JsonProperty('_id', String, true)
  public id?: string = undefined;

  @JsonProperty('route')
  public route = '';

  @JsonProperty('function_name')
  public functionName = '';

  @JsonProperty('function_id')
  public functionId = '';

  @JsonProperty('http_method')
  public httpMethod: EndpointHTTPMethod = EndpointHTTPMethod.ANY;

  @JsonProperty('validation_method', String)
  public validationMethod: EndpointValidationMethod = EndpointValidationMethod.NoValidation;

  @JsonProperty('secret_id', String, true)
  public secretId?: string = undefined;

  @JsonProperty('secret_name', String, true)
  public secretName?: string = undefined;

  @JsonProperty('create_user_on_auth', Boolean, true)
  public createUserOnAuth?: boolean = false;

  @JsonProperty('fetch_custom_user_data', Boolean, true)
  public fetchCustomUserData?: boolean = false;

  @JsonProperty('respond_result', Boolean, true)
  public respondResult?: boolean = false;

  @JsonProperty('last_modified', Number, true)
  public lastModified?: number = undefined;

  @JsonProperty('disabled', Boolean)
  public disabled = false;

  @JsonProperty('return_type', String)
  public returnType = '';

  @JsonProperty('read_only', Boolean, true)
  public readOnly? = false;

  constructor(partial?: Partial<Endpoint>) {
    Object.assign(this, partial);
  }
}

@JsonObject('ConvertEndpointRequest')
export class ConvertEndpointRequest {
  strategy?: WebhookConversionStrategy = WebhookConversionStrategy.NoStrategy;

  constructor(partial?: Partial<ConvertEndpointRequest>) {
    Object.assign(this, partial);
  }
}
