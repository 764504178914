import { Any, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';

import { DateConverter } from '../../Converter';
import createStringMapSerializers from '../common/createStringMapSerializers';

@JsonObject('FunctionCallLogItem')
export class FunctionCallLogItem {
  @JsonProperty('name')
  public name = '';

  @JsonProperty('arguments', Any, true)
  public arguments?: any = undefined;

  @JsonProperty('service', String, true)
  public service?: string = undefined;

  constructor(partial?: Partial<FunctionCallLogItem>) {
    Object.assign(this, partial);
  }
}

export enum AuthEventType {
  Login = 'login',
  Logout = 'logout',
  Other = 'other',
}

@JsonObject('AuthEvent')
export class AuthEvent {
  @JsonProperty('failed', Boolean, true)
  public failed?: boolean = undefined;

  @JsonProperty('type', Any, true)
  public type?: AuthEventType = AuthEventType.Other;

  @JsonProperty('provider')
  public provider = '';

  constructor(partial?: Partial<AuthEvent>) {
    Object.assign(this, partial);
  }
}

@JsonObject('RoleMetrics')
export class RoleMetrics {
  @JsonProperty('matching_documents')
  public matchingDocuments = 0;

  @JsonProperty('evaluated_fields')
  public evaluatedFields = 0;

  @JsonProperty('discarded_fields')
  public discardedFields = 0;

  constructor(partial?: Partial<RoleMetrics>) {
    Object.assign(this, partial);
  }
}

const roleMetricsMapSerializers = createStringMapSerializers(RoleMetrics);
@JsonConverter
class RoleMetricsMapConverter implements JsonCustomConvert<Record<string, RoleMetrics>> {
  public serialize = roleMetricsMapSerializers.serialize;

  public deserialize = roleMetricsMapSerializers.deserialize;
}

@JsonObject('NamespaceMetrics')
export class NamespaceMetrics {
  @JsonProperty('roles', RoleMetricsMapConverter, true)
  public roles: Record<string, RoleMetrics> = {};

  @JsonProperty('no_matching_role')
  public noMatchingRole = 0;

  constructor(partial?: Partial<NamespaceMetrics>) {
    Object.assign(this, partial);
  }
}

const namespaceMetricsMapSerializers = createStringMapSerializers(NamespaceMetrics);
@JsonConverter
class NamespaceMetricsMapConverter implements JsonCustomConvert<Record<string, NamespaceMetrics>> {
  public serialize = namespaceMetricsMapSerializers.serialize;

  public deserialize = namespaceMetricsMapSerializers.deserialize;
}

@JsonObject('RuleMetrics')
export class RuleMetrics {
  @JsonProperty('namespaces_metrics', NamespaceMetricsMapConverter)
  public namespacesMetrics: Record<string, NamespaceMetrics> = {};

  constructor(partial?: Partial<RuleMetrics>) {
    Object.assign(this, partial);
  }
}

@JsonObject('SyncSessionMetrics')
export class SyncSessionMetrics {
  @JsonProperty('uploads', Number, true)
  public uploads?: number = undefined;

  @JsonProperty('downloads', Number, true)
  public downloads?: number = undefined;

  @JsonProperty('errors', Number, true)
  public errors?: number = undefined;

  @JsonProperty('changesets', Number, true)
  public changesets?: number = undefined;

  @JsonProperty('dirty_changesets', Number, true)
  public dirtyChangesets?: number = undefined;

  constructor(partial?: Partial<SyncSessionMetrics>) {
    Object.assign(this, partial);
  }
}

@JsonObject('ModifiedDocIDsByOpType')
export class ModifiedDocIDsByOpType {
  @JsonProperty('inserted', Any, true)
  public inserted?: string[] = undefined;

  @JsonProperty('updated', Any, true)
  public updated?: string[] = undefined;

  @JsonProperty('deleted', Any, true)
  public deleted?: string[] = undefined;

  @JsonProperty('replaced', Any, true)
  public replaced?: string[] = undefined;

  constructor(partial?: Partial<ModifiedDocIDsByOpType>) {
    Object.assign(this, partial);
  }
}

const modifiedDocIDsByOpTypeMapSerializers = createStringMapSerializers(ModifiedDocIDsByOpType);
@JsonConverter
class ModifiedDocIDsByOpTypeMapConverter implements JsonCustomConvert<Record<string, ModifiedDocIDsByOpType>> {
  public serialize = modifiedDocIDsByOpTypeMapSerializers.serialize;

  public deserialize = modifiedDocIDsByOpTypeMapSerializers.deserialize;
}

@JsonObject('RequestLogItem')
export class RequestLogItem {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('co_id')
  public coId = '';

  @JsonProperty('type')
  public type = '';

  @JsonProperty('request_url')
  public requestUrl = '';

  @JsonProperty('request_method')
  public requestMethod = '';

  @JsonProperty('user_id', String, true)
  public userId?: string = undefined;

  @JsonProperty('domain_id', String, true)
  public domainId?: string = undefined;

  @JsonProperty('app_id', String, true)
  public appId?: string = undefined;

  @JsonProperty('group_id', String, true)
  public groupId?: string = undefined;

  @JsonProperty('service_id', String, true)
  public serviceId?: string = undefined;

  @JsonProperty('remote_ip_address', String, true)
  public remoteIpAddress?: string = undefined;

  @JsonProperty('started', String, true)
  public started?: string = undefined;

  @JsonProperty('completed', String, true)
  public completed?: string = undefined;

  @JsonProperty('function_call_location', String, true)
  public functionCallLocation?: string = undefined;

  @JsonProperty('function_call_provider_region', String, true)
  public functionCallProviderRegion?: string = undefined;

  @JsonProperty('function_call', FunctionCallLogItem, true)
  public functionCall?: FunctionCallLogItem = undefined;

  @JsonProperty('function_id', String, true)
  public functionId?: string = undefined;

  @JsonProperty('function_name', String, true)
  public functionName?: string = undefined;

  @JsonProperty('incoming_webhook_args', Any, true)
  public incomingWebhookArgs?: Record<string, any> = undefined;

  @JsonProperty('incoming_webhook_id', String, true)
  public incomingWebhookId?: string = undefined;

  @JsonProperty('incoming_webhook_name', String, true)
  public incomingWebhookName?: string = undefined;

  @JsonProperty('error', String, true)
  public error?: string = undefined;

  @JsonProperty('error_code', String, true)
  public errorCode?: string = undefined;

  @JsonProperty('error_details', Any, true)
  public errorDetails?: Record<string, any> = undefined;

  @JsonProperty('parent_co_id', String, true)
  public parentCoId?: string = '';

  @JsonProperty('event_subscription_id', String, true)
  public eventSubscriptionId?: string = undefined;

  @JsonProperty('event_subscription_name', String, true)
  public eventSubscriptionName?: string = undefined;

  @JsonProperty('status', Number, true)
  public status?: number = undefined;

  @JsonProperty('messages', Any, true)
  public messages?: any[] = undefined;

  @JsonProperty('auth_event', AuthEvent, true)
  public authEvent?: AuthEvent = undefined;

  @JsonProperty('mem_time_usage', Number, true)
  public memTimeUsage?: number = undefined;

  @JsonProperty('rule_metrics', RuleMetrics, true)
  public ruleMetrics?: RuleMetrics = undefined;

  @JsonProperty('platform', String, true)
  public platform?: string = undefined;

  @JsonProperty('platform_version', String, true)
  public platformVersion?: string = undefined;

  @JsonProperty('sdk_name', String, true)
  public sdkName?: string = undefined;

  @JsonProperty('sdk_version', String, true)
  public sdkVersion?: string = undefined;

  @JsonProperty('framework_name', String, true)
  public frameworkName?: string = undefined;

  @JsonProperty('framework_version', String, true)
  public frameworkVersion?: string = undefined;

  @JsonProperty('graphql_query', String, true)
  public graphqlQuery?: string = undefined;

  @JsonProperty('sync_partition', String, true)
  public syncPartition?: string = undefined;

  @JsonProperty('sync_query', Any, true)
  public syncQuery?: Record<string, any> = undefined;

  @JsonProperty('sync_session_metrics', SyncSessionMetrics, true)
  public syncSessionMetrics?: SyncSessionMetrics = undefined;

  @JsonProperty('sync_write_summary', ModifiedDocIDsByOpTypeMapConverter, true)
  public syncWriteSummary?: Record<string, ModifiedDocIDsByOpType> = undefined;

  @JsonProperty('sync_is_from_mdb', Boolean, true)
  public syncIsFromMdb?: boolean = undefined;

  @JsonProperty('document_key', String, true)
  public documentKey?: string = undefined;

  @JsonProperty('endpoint_args', Any, true)
  public endpointArgs?: Record<string, any> = undefined;

  @JsonProperty('endpoint_id', String, true)
  public endpointId?: string = undefined;

  @JsonProperty('endpoint_route', String, true)
  public endpointRoute?: string = undefined;

  @JsonProperty('log_forwarder_id', String, true)
  public logForwarderID?: string = undefined;

  @JsonProperty('log_forwarder_name', String, true)
  public logForwarderName?: string = undefined;

  @JsonProperty('failed_to_forward_log_ids', [String], true)
  public failedToForwardLogIDs?: string[] = undefined;

  @JsonProperty('data_source_id', String, true)
  public dataSourceID?: string = undefined;

  @JsonProperty('data_source_name', String, true)
  public dataSourceName?: string = undefined;

  @JsonProperty('data_source_namespace', String, true)
  public dataSourceNamespace?: string = undefined;

  @JsonProperty('auth_method', String, true)
  public authMethod?: string = undefined;

  constructor(partial?: Partial<RequestLogItem>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AppLogResponse')
export class AppLogResponse {
  @JsonProperty('logs', [RequestLogItem])
  public logs: RequestLogItem[] = [];

  @JsonProperty('nextEndDate', DateConverter, true)
  public nextEndDate?: Date = undefined;

  @JsonProperty('nextSkip', Number, true)
  public nextSkip?: number = undefined;

  constructor(partial?: Partial<AppLogResponse>) {
    Object.assign(this, partial);
  }
}

export interface AppLogRequest {
  endDate?: Date;
  startDate?: Date;
  type?: string;
  userId?: string;
  errorsOnly?: boolean;
  coId?: string;
  skip?: number;
  limit?: number;
}

export function getLogFilter(request?: AppLogRequest) {
  let filter: Record<string, any> | undefined;
  if (request) {
    filter = {};
    if (request.endDate) {
      filter.end_date = request.endDate.toISOString();
    }
    if (request.startDate) {
      filter.start_date = request.startDate.toISOString();
    }
    if (request.type) {
      filter.type = request.type;
    }
    if (request.userId) {
      filter.user_id = request.userId;
    }
    if (request.errorsOnly) {
      filter.errors_only = request.errorsOnly;
    }
    if (request.coId) {
      filter.co_id = request.coId;
    }
    if (request.limit) {
      filter.limit = request.limit;
    }
    if (request.skip) {
      filter.skip = request.skip;
    }
  }
  return filter;
}
