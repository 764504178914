import React from 'react';
import { Radio, RadioGroup, Size } from '@leafygreen-ui/radio-group';

import { FieldPermissionValue, TestSelector } from './EditRoleForm';

import './edit-role-form.less';
const fieldPermsBaseClassName = 'edit-role-form-field-level-perms';

export interface FieldPermissionsRadioGroupProps {
  name: string;
  value: FieldPermissionValue;
  onChange?: (newVal: FieldPermissionValue) => void;
  disabled?: boolean;
}

export const FieldPermissionsRadioGroup = ({ name, value, onChange, disabled }: FieldPermissionsRadioGroupProps) => {
  return (
    <RadioGroup
      className={`${fieldPermsBaseClassName}-perms-radio-group`}
      onChange={(e) => onChange && onChange(e.target.value as FieldPermissionValue)}
      value={value}
      name={name}
      size={Size.Small}
      data-testid={TestSelector.FieldPermissionsRadioGroupC}
    >
      <Radio
        value={FieldPermissionValue.None}
        className={`${fieldPermsBaseClassName}-perms-radio-group-option`}
        disabled={disabled}
        data-testid={TestSelector.PermissionsNoneOption}
      >
        None
      </Radio>
      <Radio
        value={FieldPermissionValue.Read}
        className={`${fieldPermsBaseClassName}-perms-radio-group-option`}
        disabled={disabled}
        data-testid={TestSelector.PermissionsReadOption}
        data-cy={`fields-${TestSelector.PermissionsReadOption}`}
      >
        Read
      </Radio>
      <Radio
        value={FieldPermissionValue.ReadAndWrite}
        className={`${fieldPermsBaseClassName}-perms-radio-group-option`}
        disabled={disabled}
        data-testid={TestSelector.PermissionsReadAndWriteOption}
        data-cy={`fields-${TestSelector.PermissionsReadAndWriteOption}`}
      >
        Read &amp; Write
      </Radio>
    </RadioGroup>
  );
};
