import React from 'react';
import { Body } from '@leafygreen-ui/typography';

import { confirm as discardChangesConfirm } from './components/discard-changes-modal/DiscardChangesModal';
import existingTriggerDiscardConfirm from './components/prevent-navigation-modals/ExistingTrigger';
import newTriggerDiscardConfirm from './components/prevent-navigation-modals/NewTrigger';

import './components/discard-changes-modal/discard-changes-modal.less';

export enum PreventNavigationModalVariant {
  NewTrigger,
  ExistingTrigger,
}

interface CurrentResource {
  resourceType: string;
  resourceName?: string;
  buttonText?: string;
  variant?: PreventNavigationModalVariant;
}

const preventNavHistoryArgs = {
  getUserConfirmation: (message, callback) => {
    const currResource: CurrentResource = JSON.parse(message);
    const hasResourceName = !!currResource.resourceName;
    const hasButtonText = !!currResource.buttonText;
    const variant = currResource.variant;

    if (variant === PreventNavigationModalVariant.NewTrigger) {
      newTriggerDiscardConfirm({
        triggerName: currResource.resourceName || 'trigger',
      }).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    }

    if (variant === PreventNavigationModalVariant.ExistingTrigger) {
      existingTriggerDiscardConfirm({
        triggerName: currResource.resourceName || 'trigger',
        triggerType: currResource.resourceType || '',
      }).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    }

    const title = hasResourceName
      ? `Discard changes to ${currResource.resourceType}: ${currResource.resourceName}`
      : `Discard changes to ${currResource.resourceType}`;
    const modalBody = (
      <Body>
        Are you sure you want to navigate away and discard your changes to {currResource.resourceType}
        {hasResourceName && (
          <span className="discard-changes-modal-resource-name">&nbsp;{currResource.resourceName}</span>
        )}
        ?
      </Body>
    );
    const buttonText = hasButtonText ? currResource.buttonText : 'OK';

    // renders modal as dialog component outside of the app
    discardChangesConfirm({
      title,
      modalBody,
      buttonText,
      modalDataProps: { 'data-cy': 'discard-changes-modal', 'data-test-selector': 'discard-changes-modal' },
    }).then(
      () => callback(true),
      () => callback(false)
    );
  },
};

export default preventNavHistoryArgs;
