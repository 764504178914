import * as eventsActions from 'baas-ui/events/actions';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { loadSvcConfig, saveSvcConfig } from 'baas-ui/services/actions';
import { convertToRawSyncConfig } from 'baas-ui/sync/types/converters';
import {
  BaseEventSubscription,
  BypassServiceChangeValue,
  ResourceType,
  SyncConfig,
  SyncMigrationAction,
  SyncState,
} from 'admin-sdk';

import { TrackerLocation } from './submit-confirmable-request/constants';
import {
  dismissProgress,
  loadConfig,
  loadData,
  loadSchemaVersions,
  patchSchemas,
  putSyncMigration,
  setConfigServiceId,
  setDataServiceId,
  setDevelopmentModeEnabled,
  setFlexiblePermissionsError,
  setMigrationStatusPollCount,
  setPauseSyncError,
  setPauseSyncInProgress,
  setPermissionsError,
  setSyncEnabledToastState,
  setTerminateSyncError,
  setTerminateSyncInProgress,
  updateConfig,
} from './actions';
import { DEFAULT_POLL_COUNT } from './constants';
import submitConfirmableRequest from './submit-confirmable-request';
import SyncMigrationActionConfirmation from './sync-migration-action-confirmation';
import {
  CreatablePartition,
  PartitionSyncConfig,
  QuerySyncConfig,
  RawSyncConfig,
  ServiceSyncConfig,
  SyncEnabledToastState,
  SyncType,
  toRawPartitionSyncConfig,
  toRawQuerySyncConfig,
} from './types';

export const pauseSyncConfig =
  (groupId: string, appId: string, serviceId: string, syncConfig: ServiceSyncConfig) =>
  async (dispatch: AsyncDispatch) => {
    dispatch(setPauseSyncInProgress(true));
    const { rawConfig, syncType, errs } = convertToRawSyncConfig(syncConfig);
    if (!rawConfig || errs.length !== 0) {
      const errMsg = `invalid sync config: ${errs.join(', ')}`;
      dispatch(setPauseSyncError(errMsg));
      setPauseSyncInProgress(false);
      throw new Error(errMsg);
    }
    rawConfig.state = SyncState.Disabled;
    switch (syncType) {
      case SyncType.Partition:
        await dispatch(saveSvcConfig({ groupId, appId, svcId: serviceId, config: { sync: rawConfig } }));
        break;
      case SyncType.Flexible:
        await dispatch(saveSvcConfig({ groupId, appId, svcId: serviceId, config: { flexible_sync: rawConfig } }));
        break;
      default:
        throw new Error('unknown sync type');
    }
    const config = new SyncConfig({ developmentModeEnabled: false });
    await dispatch(updateConfig({ groupId, appId, config }));
    dispatch(setDevelopmentModeEnabled(false));

    dispatch(setDataServiceId(serviceId));
    dispatch(dismissProgress());
    dispatch(setPauseSyncError(''));
    await dispatch(loadSvcConfig({ groupId, appId, svcId: serviceId }));
    await dispatch(loadData({ groupId, appId, serviceId }));
    await dispatch(loadConfig({ groupId, appId }));
    dispatch(setPauseSyncInProgress(false));
  };

export const updatePartitionSyncConfig =
  (
    groupId: string,
    appId: string,
    serviceId: string,
    syncConfig: PartitionSyncConfig,
    syncEnabled: boolean,
    partition?: CreatablePartition,
    developmentModeEnabled?: boolean
  ) =>
  async (dispatch: AsyncDispatch) => {
    const { rawConfig, readErr, writeErr, maxOfflineTimeInvalid } = toRawPartitionSyncConfig(syncConfig);

    dispatch(setPermissionsError({ read: readErr, write: writeErr }));

    if (maxOfflineTimeInvalid) {
      throw new Error('max offline time is invalid');
    }

    if (readErr || writeErr) {
      throw new Error('permissions have invalid json');
    }

    if (partition) {
      await dispatch(
        patchSchemas({
          groupId,
          appId,
          serviceId,
          partitionKey: partition.key,
          partitionKeyType: partition.type!,
          partitionKeyRequired: partition.required,
        })
      );
    }

    await dispatch(saveSvcConfig({ groupId, appId, svcId: serviceId, config: { sync: rawConfig } }));
    if (developmentModeEnabled !== undefined) {
      const config = new SyncConfig({ serviceId, developmentModeEnabled });
      await dispatch(updateConfig({ groupId, appId, config }));
    }

    dispatch(setPauseSyncError(''));
    dispatch(setTerminateSyncError(''));
    dispatch(setDataServiceId(serviceId));
    if (syncEnabled) {
      dispatch(setSyncEnabledToastState(SyncEnabledToastState.EnablingSucceeded));
    }
    dispatch(dismissProgress());
    await dispatch(loadSvcConfig({ groupId, appId, svcId: serviceId }));
    await dispatch(loadData({ groupId, appId, serviceId }));
    await dispatch(loadConfig({ groupId, appId }));
  };

export const updateQuerySyncConfig =
  (
    groupId: string,
    appId: string,
    serviceId: string,
    syncConfig: QuerySyncConfig,
    syncEnabled: boolean,
    developmentModeEnabled?: boolean
  ) =>
  async (dispatch: AsyncDispatch) => {
    const { rawConfig, permissionsErr, maxOfflineTimeInvalid } = toRawQuerySyncConfig(syncConfig);

    dispatch(setFlexiblePermissionsError(permissionsErr));

    if (permissionsErr) {
      throw new Error('permissions have invalid json');
    }

    if (maxOfflineTimeInvalid) {
      throw new Error('max offline time is invalid');
    }

    if (developmentModeEnabled !== undefined) {
      const config = new SyncConfig({ serviceId, developmentModeEnabled });
      await dispatch(updateConfig({ groupId, appId, config }));
    }

    const saveSvcConfigFunc = (bypassServiceChange?: BypassServiceChangeValue) =>
      dispatch(
        saveSvcConfig({ groupId, appId, svcId: serviceId, config: { flexible_sync: rawConfig }, bypassServiceChange })
      );

    const getSchemaVersions = () => dispatch(loadSchemaVersions({ groupId, appId }));

    await submitConfirmableRequest(TrackerLocation.SyncConfig, saveSvcConfigFunc, {
      getSchemaVersions,
    });

    dispatch(setPauseSyncError(''));
    dispatch(setTerminateSyncError(''));
    dispatch(setDataServiceId(serviceId));
    if (syncEnabled) {
      dispatch(setSyncEnabledToastState(SyncEnabledToastState.EnablingSucceeded));
    }
    dispatch(dismissProgress());
    await dispatch(loadSvcConfig({ groupId, appId, svcId: serviceId }));
    await dispatch(loadData({ groupId, appId, serviceId }));
    await dispatch(loadConfig({ groupId, appId }));
  };

export const terminateSyncServiceConfigRaw =
  (
    groupId: string,
    appId: string,
    serviceId: string,
    rawConfig: RawSyncConfig,
    syncType: SyncType,
    reloadConfig: boolean
  ) =>
  async (dispatch: AsyncDispatch) => {
    dispatch(setTerminateSyncInProgress(true));

    // Note that we do not update the terminator state on the backend here because the draft will not be deployed at
    // this point (if drafts are enabled)

    rawConfig.state = SyncState.Terminated;
    switch (syncType) {
      case SyncType.Flexible:
        await dispatch(
          saveSvcConfig({ groupId, appId, svcId: serviceId, config: { flexible_sync: { ...rawConfig } } })
        );

        // terminating flexible sync may lead to a sync migration state change
        dispatch(setMigrationStatusPollCount(DEFAULT_POLL_COUNT));

        break;
      case SyncType.Partition:
      default:
        await dispatch(saveSvcConfig({ groupId, appId, svcId: serviceId, config: { sync: { ...rawConfig } } }));
        break;
    }
    const config = new SyncConfig({ developmentModeEnabled: false });
    // updateConfig response will also contain serviceId, which can overwrite the explicit setDataServiceId below
    await dispatch(updateConfig({ groupId, appId, config }));
    dispatch(setDevelopmentModeEnabled(false));
    dispatch(setTerminateSyncError(''));
    dispatch(setDataServiceId(''));
    dispatch(setConfigServiceId(''));
    dispatch(dismissProgress());
    if (reloadConfig) {
      await dispatch(loadSvcConfig({ groupId, appId, svcId: serviceId }));
      await dispatch(loadData({ groupId, appId, serviceId }));
      await dispatch(loadConfig({ groupId, appId }));
    }
    dispatch(setTerminateSyncInProgress(false));
  };

export const terminateSyncServiceConfig =
  (groupId: string, appId: string, serviceId: string, syncConfig: ServiceSyncConfig) =>
  async (dispatch: AsyncDispatch) => {
    const { rawConfig, syncType, errs } = convertToRawSyncConfig(syncConfig);

    if (!rawConfig || errs.length !== 0) {
      const errMsg = `invalid sync config: ${errs.join(', ')}`;
      dispatch(setTerminateSyncError(errMsg));
      throw new Error(errMsg);
    }

    if (syncType) {
      await dispatch(terminateSyncServiceConfigRaw(groupId, appId, serviceId, rawConfig, syncType, true));
    }
  };

export const fetchSyncEventSubscriptions =
  (groupId: string, appId: string) =>
  async (dispatch: AsyncDispatch): Promise<BaseEventSubscription[]> => {
    // TODO(BAAS-32261): replace with single API call
    return Promise.all([
      dispatch(eventsActions.loadEvents({ groupId, appId, type: ResourceType.SyncPublisher })),
      dispatch(eventsActions.loadEvents({ groupId, appId, type: ResourceType.SyncTranslator })),
    ]).then((eventsArr) => {
      return eventsArr.map((events) => events ?? []).flat();
    });
  };

export const triggerSyncMigrationAction =
  (groupId: string, appId: string, serviceId: string, action: SyncMigrationAction) =>
  async (dispatch: AsyncDispatch): Promise<void> => {
    if (action !== 'start') {
      // start action already has a dedicated workflow, so no need to trigger a confirmation dialog

      try {
        await SyncMigrationActionConfirmation({ action });
      } catch {
        // confirmation dialog was dismissed
        return;
      }
    }

    await dispatch(putSyncMigration({ groupId, appId, serviceId, action }));

    // start polling for the status
    dispatch(setMigrationStatusPollCount(DEFAULT_POLL_COUNT));
  };
