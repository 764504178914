import { Record } from 'immutable';
import JSON5 from 'json5';

import { prettyJSONStringify } from 'baas-ui/common/utils/util';

export default class JSONState extends Record({
  dirty: false,
  input: '',
  error: null,
  data: undefined,
}) {
  static fromRaw(raw, defaultInputVal) {
    let out = new JSONState({
      dirty: false,
      input: prettyJSONStringify(raw),
      error: null,
      data: raw,
    });
    if (!out.input) {
      out = out.set('input', prettyJSONStringify(defaultInputVal));
    }
    return out;
  }

  discardChanges(defaultInputVal) {
    return JSONState.fromRaw(this.data, defaultInputVal);
  }

  parseInput() {
    if (this.input === '') {
      return this.set('error', null).set('data', undefined);
    }
    try {
      const parsedRaw = JSON5.parse(this.input);
      return this.set('input', prettyJSONStringify(parsedRaw))
        .set('error', null)
        .set('data', parsedRaw)
        .set('dirty', false);
    } catch (err) {
      return this.set('error', 'Invalid JSON');
    }
  }
}
