import { ScaledValue } from 'baas-ui/app/metrics-card/types';

export enum BilledMetric {
  RequestCount,
  ComputeTime,
  DataOut,
  SyncTime,
}

export enum MetricName {
  OVERALL_SUCCESSFUL_REQUESTS = 'OVERALL_SUCCESSFUL_REQUESTS',
  OVERALL_FAILED_REQUESTS = 'OVERALL_FAILED_REQUESTS',
  OVERALL_COMPUTE_MS = 'OVERALL_COMPUTE_MS',
  OVERALL_EGRESS_BYTES = 'OVERALL_EGRESS_BYTES',
  OVERALL_SYNC_MINUTES = 'OVERALL_SYNC_MINUTES',
}

export interface PricingThreshold {
  value: number;
  formatted: string;
}

export const metricNames: MetricName[] = Object.values(MetricName);

export interface MetricDetails {
  titleWithUnits: string;
  title: string;
  description: string;
  pricingThreshold: (isPricingChangeEnabled: boolean) => PricingThreshold;
  converter: (value: number, isPricingChangeEnabled: boolean) => ScaledValue;
}

export type MetricDetailsByName = { [K in BilledMetric]: MetricDetails };
export type MetricUsagesByName = { [K in BilledMetric]: number };
