import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('InstalledDependency')
export class InstalledDependency {
  @JsonProperty('name')
  public name = '';

  @JsonProperty('version')
  public version = '';

  @JsonProperty('last_modified')
  public lastModified = 0;

  constructor(partial?: Partial<InstalledDependency>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AppDependencies')
export class AppDependencies {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('location')
  public location = '';

  @JsonProperty('domain_id_hash')
  public domainIdHash = 0;

  @JsonProperty('user_id')
  public userId = '';

  @JsonProperty('last_modified')
  public lastModified = 0;

  @JsonProperty('dependencies_list', [InstalledDependency])
  public dependenciesList: InstalledDependency[] = [];

  @JsonProperty('installed_via_package_manager')
  public installedViaPackageManager = false;

  constructor(partial?: Partial<AppDependencies>) {
    Object.assign(this, partial);
  }
}

export enum InstallationStatus {
  Created = 'created',
  Successful = 'successful',
  Failed = 'failed',
}

@JsonObject('DependencyInstallation')
export class DependencyInstallation {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('app_id')
  public appId = '';

  @JsonProperty('user_id')
  public userId = '';

  @JsonProperty('updated_at')
  public updatedAt = 0;

  @JsonProperty('status')
  public status: InstallationStatus = InstallationStatus.Created;

  @JsonProperty('status_message')
  public statusMessage = '';

  @JsonProperty('location')
  public location = '';

  @JsonProperty('domain_id_hash')
  public domainIdHash = 0;

  constructor(partial?: Partial<DependencyInstallation>) {
    Object.assign(this, partial);
  }
}
