import queryString from 'query-string';

// 2000 is the de facto character limit supported by virtually all browsers, see:
// https://stackoverflow.com/questions/417142/what-is-the-maximum-length-of-a-url-in-different-browsers/417184#417184
const MAX_URL_CHARACTER_LIMIT = 2000;

declare global {
  interface Window {
    settings: any;
  }
}

// returns query string that when appended to current URL is shorter than or equal to maxCharacterLimit
// assumes additionalQueryString begins with a ? or & character
export const trimQueryString = (currentUrlLength: number, additionalQueryString: string, maxCharacterLimit: number) => {
  const maxQueryParamLength = maxCharacterLimit - currentUrlLength;

  if (additionalQueryString.length <= maxQueryParamLength) {
    return additionalQueryString;
  }

  const endIndex = additionalQueryString.lastIndexOf('&', maxQueryParamLength);
  if (endIndex <= 0) {
    return '';
  }
  return additionalQueryString.substring(0, endIndex);
};

// returns url with additional query params appended
// trims additional params to fit MAX_URL_CHARACTER_LIMIT if requested
export const withQueryParams = (url: string, params = {}, shouldTrimUrl = false) => {
  const hasCurrentQueryParams = url.includes('?');
  const newQueryParams = queryString.stringify(params);

  if (newQueryParams) {
    let queryParamString = `${hasCurrentQueryParams ? '&' : '?'}${newQueryParams}`;
    if (shouldTrimUrl) {
      queryParamString = trimQueryString(url.length, queryParamString, MAX_URL_CHARACTER_LIMIT);
    }
    return `${url}${queryParamString}`;
  }

  return url;
};

export const hasServicesInUrl = (url: string) => {
  return /^(https?:\/\/)?(www.)?(?:[a-zA-Z0-9-]+.)?services.cloud/.test(url);
};

export const hasRealmInUrl = (url: string) => {
  return /^(https?:\/\/)?(www.)?realm/.test(url);
};

// TODO(BAAS-7827): This can be removed once we deprecate stitch hostname
// hasStitchInUrl returns a boolean on whether the subdomain contains "stitch"
export const hasStitchInUrl = (url: string) => {
  return /^(https?:\/\/)?(www.)?stitch/.test(url);
};

// TODO(BAAS-7827): This can be removed once we deprecate stitch hostname
// replaceBaseUrl replaces the url's domain with the baseUrl
export const replaceBaseUrl = (baseUrl: string, url: string) => {
  const newUrl = new URL(url);
  return `${baseUrl}${newUrl.pathname || ''}${newUrl.search || ''}`;
};

let GITHUB_APP_NAME = 'mongodb-atlas-app-services';

if (window.settings && window.settings.apiUrl) {
  if (window.settings.apiUrl.includes('realm-dev') || window.settings.apiUrl.includes('services.cloud-dev')) {
    GITHUB_APP_NAME = 'mongodb-atlas-app-services-dev';
  } else if (window.settings.apiUrl.includes('realm-qa') || window.settings.apiUrl.includes('services.cloud-qa')) {
    GITHUB_APP_NAME = 'mongodb-atlas-app-services-qa';
  } else if (
    window.settings.apiUrl.includes('realm-staging') ||
    window.settings.apiUrl.includes('services.cloud-stage')
  ) {
    GITHUB_APP_NAME = 'mongodb-atlas-app-services-staging';
  } else if (window.settings.apiUrl.includes('localhost')) {
    GITHUB_APP_NAME = 'mongodb-atlas-app-services-test';
  }
}

export const GITHUB_APP_URL = `https://github.com/apps/${GITHUB_APP_NAME}/installations/new`;

export interface UrlObj {
  [path: string]: (param?: string | object) => UrlObj | any;
}

export type ResourceUrlType<key extends keyof AppUrlType> = ReturnType<AppUrlType[key]>;

export const rootUrl = {
  admin: () => '/admin',
  get: () => '/',
  login: () => '/login',
  logout: () => '/logout',
  profile: () => '/profile',
  groups: () => ({
    group: (groupId: string) => ({
      apps: () => ({
        list: (preventRedirect = false) => `/groups/${groupId}/apps${preventRedirect ? '?redirect=false' : ''}`,
        create: () => `/groups/${groupId}/apps/create`,
        templates: () => ({
          create: () => `/groups/${groupId}/apps/templates/create`,
        }),
        app: (appId: string) => ({
          get: () => `/groups/${groupId}/apps/${appId}`,
          dashboard: () => `/groups/${groupId}/apps/${appId}/dashboard`,
          guides: () => `/groups/${groupId}/apps/${appId}/dashboard?guidesModalOpen=true`,
          clients: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/clients`,
          }),
          graphql: () => ({
            root: () => `/groups/${groupId}/apps/${appId}/graphql`,
            explore: () => `/groups/${groupId}/apps/${appId}/graphql/explore`,
            schema: () => `/groups/${groupId}/apps/${appId}/graphql/schema`,
            settings: () => `/groups/${groupId}/apps/${appId}/graphql/settings`,
            customResolvers: () => ({
              list: () => `/groups/${groupId}/apps/${appId}/graphql/customResolvers`,
              new: () => `/groups/${groupId}/apps/${appId}/graphql/customResolvers/new`,
              customResolver: (id: string) => ({
                get: () => `/groups/${groupId}/apps/${appId}/graphql/customResolvers/${id}`,
              }),
            }),
          }),
          // Revamped Rules UI Routes
          rules: () => {
            const rulesUrlPrefix = `/groups/${groupId}/apps/${appId}/rules`;
            return {
              default: (dataSourceName: string) => `${rulesUrlPrefix}/${dataSourceName}/defaultRule`,
              defaultSync: (dataSourceName: string) =>
                `${rulesUrlPrefix}/${dataSourceName}/defaultRule?showSyncBanner=true`,
              new: (dataSourceName: string) => `${rulesUrlPrefix}/${dataSourceName}/new`,
              list: () => rulesUrlPrefix,
              rule: ({ dataSourceName, ruleId }: { dataSourceName: string; ruleId: string }) => {
                const ruleUrlPrefix = `${rulesUrlPrefix}/${dataSourceName}/rule/${ruleId}`;
                return {
                  get: () => ruleUrlPrefix,
                  filters: () => `${ruleUrlPrefix}/filters`,
                  roles: () => `${ruleUrlPrefix}/roles`,
                };
              },
            };
          },
          schemas: () => ({
            root: () => `/groups/${groupId}/apps/${appId}/schema`,
            generate: () => `/groups/${groupId}/apps/${appId}/schema/generate`,
            schema: (schemaId: string) => ({
              get: () => `/groups/${groupId}/apps/${appId}/schema/${schemaId}`,
            }),
          }),
          sdks: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/sdks`,
            dataModels: () => `/groups/${groupId}/apps/${appId}/sdks/dataModels`,
          }),
          environmentValues: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/environmentValues`,
            new: () => `/groups/${groupId}/apps/${appId}/environmentValues/new`,
            environmentValue: (id: string) => ({
              get: () => `/groups/${groupId}/apps/${appId}/environmentValues/${id}`,
            }),
          }),
          values: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/values`,
            new: () => `/groups/${groupId}/apps/${appId}/values/new`,
            value: (valueId: string) => ({
              get: () => `/groups/${groupId}/apps/${appId}/values/${valueId}`,
            }),
          }),
          secrets: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/secrets`,
            new: () => `/groups/${groupId}/apps/${appId}/secrets/new`,
            secret: (secretId: string) => ({
              get: () => `/groups/${groupId}/apps/${appId}/secrets/${secretId}`,
            }),
          }),
          auth: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/auth`,
            providers: () => ({
              list: () => `/groups/${groupId}/apps/${appId}/auth/providers`,
              provider: (providerType: string) => ({
                get: () => `/groups/${groupId}/apps/${appId}/auth/providers/${providerType}`,
              }),
            }),
            users: () => ({
              list: () => `/groups/${groupId}/apps/${appId}/auth/users`,
            }),
            userSettings: () => ({
              list: () => `/groups/${groupId}/apps/${appId}/auth/userSettings`,
            }),
            customUserData: () => ({
              list: () => `/groups/${groupId}/apps/${appId}/auth/customUserData`,
            }),
          }),
          logs: () => ({
            list: (filter = {}) => withQueryParams(`/groups/${groupId}/apps/${appId}/logs`, filter),
            forwarders: () => ({
              list: (filter = {}) => withQueryParams(`/groups/${groupId}/apps/${appId}/logs/forwarders`, filter),
              new: () => `/groups/${groupId}/apps/${appId}/logs/forwarders/new`,
              forwarder: (id: string) => ({
                get: () => `/groups/${groupId}/apps/${appId}/logs/forwarders/${id}`,
              }),
            }),
          }),
          push: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/push`,
            sent: () => `/groups/${groupId}/apps/${appId}/push/sent`,
            drafts: () => `/groups/${groupId}/apps/${appId}/push/drafts`,
            config: () => `/groups/${groupId}/apps/${appId}/push/config`,
            rules: () => ({
              list: () => `/groups/${groupId}/apps/${appId}/push/rules`,
              rule: (ruleId: string) => ({
                get: () => `/groups/${groupId}/apps/${appId}/push/rules/${ruleId}`,
              }),
            }),
          }),
          triggers: () => ({
            list: ({ type }: { type?: string } = {}) => {
              const url = `/groups/${groupId}/apps/${appId}/triggers`;
              return type ? `${url}?type=${type}` : url;
            },
            new: ({ type }: { type?: string } = {}) => {
              const url = `/groups/${groupId}/apps/${appId}/triggers/new`;
              return type ? `${url}?type=${type}` : url;
            },
            trigger: (triggerId: string) => ({
              get: ({ type }: { type?: string } = {}) => {
                const url = `/groups/${groupId}/apps/${appId}/triggers/${triggerId}`;
                return type ? `${url}?type=${type}` : url;
              },
            }),
          }),
          functions: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/functions`,
            new: () => `/groups/${groupId}/apps/${appId}/functions/new`,
            function: (functionId: string) => ({
              get: () => `/groups/${groupId}/apps/${appId}/functions/${functionId}`,
            }),
          }),
          dependencies: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/dependencies`,
          }),
          endpoints: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/endpoints`,
            new: () => `/groups/${groupId}/apps/${appId}/endpoints/new`,
            endpoint: (endpointId: string) => ({
              get: () => `/groups/${groupId}/apps/${appId}/endpoints/${endpointId}`,
            }),
            dataAPI: () => `/groups/${groupId}/apps/${appId}/endpoints/dataAPI`,
          }),
          services: () => ({
            new: () => `/groups/${groupId}/apps/${appId}/services/new`,
            list: () => `/groups/${groupId}/apps/${appId}/services`,
            service: (svcId: string) => ({
              get: () => `/groups/${groupId}/apps/${appId}/services/${svcId}`,
              config: () => ({
                get: () => `/groups/${groupId}/apps/${appId}/services/${svcId}/config`,
              }),
              rules: () => ({
                new: () => `/groups/${groupId}/apps/${appId}/services/${svcId}/rules/new`,
                list: () => `/groups/${groupId}/apps/${appId}/services/${svcId}/rules`,
                rule: (ruleId: string) => ({
                  get: () => `/groups/${groupId}/apps/${appId}/services/${svcId}/rules/${ruleId}`,
                }),
              }),
              incomingWebhooks: () => ({
                new: () => `/groups/${groupId}/apps/${appId}/services/${svcId}/incomingWebhooks/new`,
                list: () => `/groups/${groupId}/apps/${appId}/services/${svcId}/incomingWebhooks`,
                incomingWebhook: (incomingWebhookId: string) => ({
                  get: () => `/groups/${groupId}/apps/${appId}/services/${svcId}/incomingWebhooks/${incomingWebhookId}`,
                }),
              }),
            }),
          }),
          clusters: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/dataSources`,
            new: () => `/groups/${groupId}/apps/${appId}/dataSources/new`,
            cluster: (svcId: string) => ({
              get: () => `/groups/${groupId}/apps/${appId}/dataSources/${svcId}`,
              rules: () => {
                const rulesUrlPrefix = `/groups/${groupId}/apps/${appId}/dataSources/${svcId}/rules`;

                return {
                  new: () => `/groups/${groupId}/apps/${appId}/dataSources/${svcId}/rules/new`,
                  list: () => `${rulesUrlPrefix}`,
                  permissions: () => `${rulesUrlPrefix}/permissions`,
                  schema: () => `${rulesUrlPrefix}/schema`,
                  relationships: () => `${rulesUrlPrefix}/relationships`,
                  filters: () => `${rulesUrlPrefix}/filters`,
                  rule: (ruleId: string) => {
                    const ruleUrlPrefix = `${rulesUrlPrefix}/${ruleId}`;

                    return {
                      get: () => `${ruleUrlPrefix}`,
                      schema: () => `${ruleUrlPrefix}/schema`,
                      permissions: () => `${ruleUrlPrefix}/permissions`,
                      relationships: () => `${ruleUrlPrefix}/relationships`,
                      filters: () => `${ruleUrlPrefix}/filters`,
                    };
                  },
                };
              },
            }),
          }),
          settings: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/settings`,
            general: () => ({
              list: () => `/groups/${groupId}/apps/${appId}/settings/general`,
            }),
            ipAccessList: () => ({
              list: () => `/groups/${groupId}/apps/${appId}/settings/ipAccessList`,
            }),
            providerPrivateEndpoints: () => ({
              list: () => `/groups/${groupId}/apps/${appId}/settings/providerPrivateEndpoints`,
            }),
          }),
          hosting: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/hosting`,
            assets: () => `/groups/${groupId}/apps/${appId}/hosting/assets`,
            settings: () => `/groups/${groupId}/apps/${appId}/hosting/settings`,
          }),
          deploy: () => ({
            list: () => `/groups/${groupId}/apps/${appId}/deployment`,
            history: () => ({
              list: () => `/groups/${groupId}/apps/${appId}/deployment/history`,
            }),
            config: () => `/groups/${groupId}/apps/${appId}/deployment/config`,
            export: () => `/groups/${groupId}/apps/${appId}/deployment/export`,
            environment: () => `/groups/${groupId}/apps/${appId}/deployment/environment`,
          }),
          sync: () => {
            const root = `/groups/${groupId}/apps/${appId}/sync`;
            return {
              root: () => root,
              config: () => `${root}/config`,
              metrics: () => `${root}/metrics`,
              logs: () => `${root}/logs`,
              models: () => `${root}/models`,
            };
          },
          edge: () => {
            const root = `/groups/${groupId}/apps/${appId}/edge`;
            return {
              root: () => root,
              info: (edgeServerId: string) => `${root}/${edgeServerId}`,
            };
          },
        }),
      }),
    }),
  }),
};

export type AppUrlType = ReturnType<
  ReturnType<ReturnType<ReturnType<(typeof rootUrl)['groups']>['group']>['apps']>['app']
>;

export default rootUrl;
