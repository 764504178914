import { List, Record } from 'immutable';

export default class Asset extends Record({
  path: '',
  assetSize: undefined,
  lastModified: undefined,
  attributes: new List(),
  url: '',
}) {
  constructor({ path, assetSize, lastModified, attributes, url }) {
    super({
      path,
      assetSize,
      lastModified,
      attributes,
      url,
    });
  }

  static fromRaw(raw) {
    return new Asset({
      path: raw.path,
      assetSize: raw.size,
      lastModified: raw.last_modified,
      url: raw.url,
      attributes: List(raw.attributes),
    });
  }
}
