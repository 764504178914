import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { Title } from 'baas-ui/common/components/title';
import { AppProduct } from 'baas-ui/home/types';
import { BreadcrumbsItem, CloudBreadcrumbs, ErrorBoundaryRoute } from 'baas-ui/nav';
import RulesPage from 'baas-ui/rules';
import { EditConfig } from 'baas-ui/service';
import DataSourceList from 'baas-ui/services/mongodb/data-source-list';
import { rootUrl } from 'baas-ui/urls';

import './clusters.less';

export enum TestSelector {
  RulesPageRoute = 'rules-page-route',
  RulesPageRedirect = 'rules-page-redirect',
}

export interface Props {
  app: {
    id: string;
    groupId: string;
    product: AppProduct;
  };
  match: {
    url?: string;
  };
}

const baseClassName = 'clusters';

function Clusters(props: Props) {
  const { match, app } = props;
  const { groupId, id: appId } = app;

  const clustersRootUrl = rootUrl.groups().group(groupId).apps().app(appId).clusters();
  const appUrl = rootUrl.groups().group(app.groupId).apps().app(app.id);

  return (
    <div data-cy="clusters-page" className={baseClassName} id="clusters">
      <Title>Data Sources</Title>
      <Switch>
        <ErrorBoundaryRoute exact path={`${match.url}`} render={() => <DataSourceList />} />
        <ErrorBoundaryRoute
          exact
          path={`${match.url}/new`}
          render={(routeProps) => (
            <>
              <EditConfig key="new" {...props} {...routeProps} clusterLink />
              <CloudBreadcrumbs />
              <BreadcrumbsItem to={match.url}>Data Sources</BreadcrumbsItem>
            </>
          )}
        />

        <>
          {[
            clustersRootUrl.cluster(':svcId').get(),
            clustersRootUrl.cluster(':svcId').rules().list(),
            clustersRootUrl.cluster(':svcId').rules().new(),
            clustersRootUrl.cluster(':svcId').rules().permissions(),
            clustersRootUrl.cluster(':svcId').rules().rule(':ruleId').permissions(),
            clustersRootUrl.cluster(':svcId').rules().filters(),
            clustersRootUrl.cluster(':svcId').rules().rule(':ruleId').filters(),
            clustersRootUrl.cluster(':svcId').rules().rule(':ruleId').get(),
          ].map((oldUrl) => (
            <Redirect
              exact
              from={oldUrl}
              to={appUrl.rules().list()}
              key={oldUrl}
              data-test-selector={TestSelector.RulesPageRedirect}
            />
          ))}
          <ErrorBoundaryRoute
            path={appUrl.rules().list()}
            component={RulesPage}
            data-test-selector={TestSelector.RulesPageRoute}
          />
        </>
      </Switch>
    </div>
  );
}

export default Clusters;
