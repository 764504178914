import React from 'react';
import Badge from '@leafygreen-ui/badge';
import Box from '@leafygreen-ui/box';
import Icon from '@leafygreen-ui/icon';
import IconButton from '@leafygreen-ui/icon-button';
import { Menu, MenuItem } from '@leafygreen-ui/menu';
import { palette } from '@leafygreen-ui/palette';
import Tooltip from '@leafygreen-ui/tooltip';
import classNames from 'classnames';

import { DefaultConfigButtonProps } from 'baas-ui/common/components/collection-explorer/collection-explorer/CollectionExplorer';
import { useRulesPageContext } from 'baas-ui/rules/useRulesPageContext';
import { useDarkMode } from 'baas-ui/theme';

import './default-roles-filters-button.less';

export type Props = DefaultConfigButtonProps;

export enum TestSelector {
  DefaultRolesFiltersButtonTooltip = 'default-roles-filters-button-tooltip',
  DefaultRolesFiltersButtonContainer = 'default-roles-filters-button-container',
  InfoIcon = 'info-icon',
  LockIcon = 'lock-icon',
  EllipsisIcon = 'ellipsis-icon',
  EllipsisIconButton = 'ellipsis-icon-button',
  EnabledBadge = 'enabled-badge',
  ChevronIcon = 'chevron-icon',
  DefaultRolesFiltersMenu = 'default-roles-filters-menu',
  DeleteDefaultRule = 'delete-default-rule',
  IncompatibleRolesIcon = 'incompatible-roles-icon',
}

const baseClassName = 'default-roles-filters-button';

export default function DefaultRolesFiltersButton({ dataSourceId, dataSourceName, updateCurrentDataSource }: Props) {
  const [showMenu, setShowMenu] = React.useState(false);
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);

  const {
    onClickDeleteAction,
    defaultRulesByDataSourcesId,
    deletingDSDefaultRules,
    syncIncompatibleRolesByDataSourceId,
  } = useRulesPageContext();

  const [isConfigured, setIsConfigured] = React.useState(!!defaultRulesByDataSourcesId[dataSourceId]);
  const [hasRoles, setHasRoles] = React.useState(defaultRulesByDataSourcesId[dataSourceId]?.roles?.length);

  const dataSourceSyncIncompatibleRoles = syncIncompatibleRolesByDataSourceId[dataSourceId];
  const hasSyncIncompatibleDefaultRoles =
    dataSourceSyncIncompatibleRoles &&
    dataSourceSyncIncompatibleRoles.defaultRoles &&
    dataSourceSyncIncompatibleRoles.defaultRoles?.length > 0;

  React.useEffect(() => {
    if (!isOpenMenu) {
      setShowMenu(false);
    }
  }, [isOpenMenu]);

  React.useEffect(() => {
    setIsConfigured(!!defaultRulesByDataSourcesId[dataSourceId]);
    setHasRoles(defaultRulesByDataSourcesId[dataSourceId]?.roles?.length);
  }, [defaultRulesByDataSourcesId]);

  const isDeletingFromCurrentDatasource = deletingDSDefaultRules.has(dataSourceId);
  const isDisabled = !defaultRulesByDataSourcesId[dataSourceId] || isDeletingFromCurrentDatasource;

  const statusIndicator = () => {
    if (!isConfigured) {
      return (
        <Tooltip
          data-test-selector={TestSelector.DefaultRolesFiltersButtonTooltip}
          className={`${baseClassName}-overview-tooltip`}
          trigger={
            <Box>
              <Icon
                data-test-selector={TestSelector.InfoIcon}
                data-testid={TestSelector.InfoIcon}
                data-cy={TestSelector.InfoIcon}
                className={`${baseClassName}-info`}
                glyph="InfoWithCircle"
                fill={palette.gray.base}
              />
            </Box>
          }
          triggerEvent="hover"
        >
          Default rules are sets of roles and filters that you define and add to every collection without Rules yet.
        </Tooltip>
      );
    }

    if (hasRoles) {
      if (hasSyncIncompatibleDefaultRoles) {
        return (
          <Tooltip
            className={`${baseClassName}-sync-incompatible-roles-tooltip`}
            justify="middle"
            trigger={
              <Box data-testid={TestSelector.IncompatibleRolesIcon}>
                <Icon glyph="ImportantWithCircle" fill={palette.yellow.dark2} className={`${baseClassName}-info`} />
              </Box>
            }
            triggerEvent="hover"
          >
            You have default roles that are incompatible with Sync
          </Tooltip>
        );
      }

      return (
        <Badge
          variant="green"
          className={`${baseClassName}-info`}
          data-cy={TestSelector.EnabledBadge}
          data-testid={TestSelector.EnabledBadge}
        >
          Enabled
        </Badge>
      );
    }
    return (
      <Icon
        data-cy={TestSelector.LockIcon}
        data-testid={TestSelector.LockIcon}
        className={`${baseClassName}-info`}
        glyph="Lock"
        fill={palette.gray.dark3}
      />
    );
  };

  const darkMode = useDarkMode();
  return (
    <div
      data-test-selector={TestSelector.DefaultRolesFiltersButtonContainer}
      data-testid={TestSelector.DefaultRolesFiltersButtonContainer}
      data-cy="default-roles-filters-button"
      onClick={() => updateCurrentDataSource()}
      className={classNames(baseClassName, {
        [`${baseClassName}-light-mode`]: !darkMode,
        [`${baseClassName}-dark-mode`]: darkMode,
      })}
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => {
        if (!isOpenMenu) setShowMenu(false);
      }}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <div
        className={classNames(`${baseClassName}-left`, {
          'default-roles-filters-button-left-configured': isConfigured,
        })}
      >
        Default roles and filters {statusIndicator()}
      </div>
      <div className={`${baseClassName}-right`}>
        {showMenu && (
          <Menu
            open={isOpenMenu}
            setOpen={() => setIsOpenMenu(!isOpenMenu)}
            align="bottom"
            justify="end"
            data-test-selector={TestSelector.DefaultRolesFiltersMenu}
            trigger={
              <IconButton
                data-test-selector={TestSelector.EllipsisIconButton}
                data-cy="default-roles-filters-button-trigger"
                className={`${baseClassName}-ellipsis`}
                aria-label="Menu"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Icon data-test-selector={TestSelector.EllipsisIcon} glyph="Ellipsis" fill={palette.blue.base} />
              </IconButton>
            }
          >
            <MenuItem
              className={classNames({ 'collection-explorer__menu-item--destructive': !isDisabled })}
              data-test-selector={TestSelector.DeleteDefaultRule}
              data-cy={TestSelector.DeleteDefaultRule}
              disabled={isDisabled}
              onClick={() => {
                onClickDeleteAction({
                  dataSourceName,
                  dataSourceId,
                  isDefaultRule: true,
                });
              }}
            >
              Delete default roles and filters
            </MenuItem>
          </Menu>
        )}
        <Icon
          data-test-selector={TestSelector.ChevronIcon}
          className={`${baseClassName}-chevron`}
          glyph="ChevronRight"
          fill={palette.gray.base}
          size="small"
        />
      </div>
    </div>
  );
}
