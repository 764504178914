import { createReducer } from 'redux-act';

import * as actions from './actions';

export interface DataAPIState {
  isDataAPIEnabledToastOpen: boolean;
}

export const defaultState: DataAPIState = {
  isDataAPIEnabledToastOpen: false,
};

const reducer = createReducer<DataAPIState>({}, defaultState);

reducer.on(actions.showDataAPIEnabledToast, (state) => ({
  ...state,
  isDataAPIEnabledToastOpen: true,
}));

reducer.on(actions.hideDataAPIEnabledToast, (state) => ({
  ...state,
  isDataAPIEnabledToastOpen: false,
}));

export default reducer;
