import React from 'react';
import { Body, Link, Subtitle } from '@leafygreen-ui/typography';

import { DraftDiff } from 'admin-sdk';

export enum TestSelector {
  Diff = 'dependency-dff',
  Added = 'added',
  Modified = 'modified',
  Deleted = 'deleted',
}

export interface Props {
  dependenciesDiff: DraftDiff['dependenciesDiff'];
  dependenciesUrl: string;
}

export default function DependenciesDisplayDiff({ dependenciesDiff, dependenciesUrl }: Props) {
  const { added, deleted, modified } = dependenciesDiff;
  if (!added.length && !deleted.length && !modified.length) {
    return null;
  }

  return (
    <div className="deployment-diff" data-testid={TestSelector.Diff}>
      <Subtitle className="deployment-diff-title">Dependencies</Subtitle>
      <div className="deployment-diff-content">
        <Body className="deployment-diff-dependencies-info">
          You have changes to your&nbsp;
          <Link hideExternalIcon href={dependenciesUrl} target="_blank" rel="noopener noreferrer">
            dependency files.
          </Link>
        </Body>
        {!!dependenciesDiff.added && (
          <div className="deployment-diff-dependencies-diff">
            <Body data-testid={TestSelector.Added}>{`Dependencies Added: ${added.length}`}</Body>
          </div>
        )}
        {!!dependenciesDiff.modified && (
          <div className="deployment-diff-dependencies-diff">
            <Body data-testid={TestSelector.Modified}>{`Dependencies Modified: ${modified.length}`}</Body>
          </div>
        )}
        {!!dependenciesDiff.deleted && (
          <div className="deployment-diff-dependencies-diff">
            <Body data-testid={TestSelector.Deleted}>{`Dependencies Removed: ${deleted.length}`}</Body>
          </div>
        )}
      </div>
    </div>
  );
}
