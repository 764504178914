import React from 'react';
import { Radio, RadioGroup } from '@leafygreen-ui/radio-group';
import { Disclaimer } from '@leafygreen-ui/typography';

import { DataSourceLinkMethod } from 'baas-ui/home/types';

import './cluster-selection-radio.less';

export interface Props {
  dataSourceLinkMethod: DataSourceLinkMethod;
  setDataSourceLinkMethod(method: DataSourceLinkMethod): void;
  createClusterDisabled: boolean;
  useExistingDisabled: boolean;
  m0Version: string;
}

export enum TestSelector {
  LinkDatabaseRadioGroup = 'link-database-radio-group',
  CreateClusterRadio = 'create-cluster-radio',
  UseExistingRadio = 'use-existing-radio',
  CreateDisclaimer = 'disclaimer',
}

const baseClassName = 'cluster-selection-radio';
const disabledTextClassName = `${baseClassName}-disabled-text`;
const ClusterSelectionRadio = ({
  setDataSourceLinkMethod,
  dataSourceLinkMethod,
  createClusterDisabled,
  useExistingDisabled,
  m0Version,
}: Props) => (
  <RadioGroup
    data-test-selector={TestSelector.LinkDatabaseRadioGroup}
    className={`${baseClassName}-group`}
    name="clusterLinkingMethod"
    onChange={(e) => setDataSourceLinkMethod(e.target.value as DataSourceLinkMethod)}
    value={dataSourceLinkMethod}
  >
    <Radio
      data-test-selector={TestSelector.CreateClusterRadio}
      data-testid={TestSelector.CreateClusterRadio}
      className={baseClassName}
      id="createClusterRadio"
      value={DataSourceLinkMethod.CreateCluster}
      checked={dataSourceLinkMethod === DataSourceLinkMethod.CreateCluster}
      disabled={createClusterDisabled}
    >
      <Disclaimer
        data-test-selector={TestSelector.CreateDisclaimer}
        className={createClusterDisabled ? disabledTextClassName : ''}
      >
        We&apos;ll automatically create a free-tier {m0Version && `${m0Version} `}Atlas cluster for you with 512 MB of
        space
      </Disclaimer>
    </Radio>
    <Radio
      data-test-selector={TestSelector.UseExistingRadio}
      data-testid={TestSelector.UseExistingRadio}
      className={baseClassName}
      id="useExistingRadio"
      value={DataSourceLinkMethod.UseExisting}
      checked={dataSourceLinkMethod === DataSourceLinkMethod.UseExisting}
      disabled={useExistingDisabled}
    >
      <Disclaimer className={useExistingDisabled ? disabledTextClassName : ''}>
        Use an existing MongoDB Atlas Data Source
      </Disclaimer>
    </Radio>
  </RadioGroup>
);

export default ClusterSelectionRadio;
