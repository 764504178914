import React from 'react';
import styled from '@emotion/styled';
import Box from '@leafygreen-ui/box';
import Icon from '@leafygreen-ui/icon';
import { palette } from '@leafygreen-ui/palette';
import { Cell, Row, Table, TableHeader } from '@leafygreen-ui/table-legacy';
import { Body, InlineCode, Link } from '@leafygreen-ui/typography';
import semverGte from 'semver/functions/gte';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import { MONGODB_FLEXIBLE_SYNC_MIN_VERSION } from 'baas-ui/constants';
import { UrlObj } from 'baas-ui/urls';

export interface Props {
  appUrl: UrlObj;
  atlasClustersUrl: string;

  timeBasedOplogHours: number | null;
  clusterName: string;
  clusterVersion: string;
  isPermissionsMigratable: boolean;
}

const StyledRow = styled(Row, {
  shouldForwardProp: (propName): propName is 'isGray' => propName !== 'isGray',
})<{ isGray: boolean }>(({ theme, isGray }) => ({
  backgroundColor: isGray ? theme.leafygreen.colors.gray.light3 : theme.leafygreen.colors.white,
}));

const TopPaddedBody = styled(Body)(({ theme }) => ({
  paddingTop: theme.leafygreen.spacing[4],
}));

const RedTextSpan = styled.span(({ theme }) => ({
  color: theme.leafygreen.colors.red.dark2,
}));

const successIcon = <Icon glyph="CheckmarkWithCircle" fill={palette.green.dark1} />;
const failIcon = <Icon glyph="Warning" fill={palette.red.base} />;

const rows = [
  {
    title: 'Time-based Oplog in Cluster Settings',
    description: ({ atlasClustersUrl, clusterName, timeBasedOplogHours }: Props) => (
      <>
        <Body>
          You must have a time-based minimum oplog window set for your linked cluster. To enable this setting, storage
          auto-scaling should be enabled.
        </Body>

        <TopPaddedBody>
          You have the duration of your oplog window to revert back to Partition-Based Sync.
        </TopPaddedBody>

        {timeBasedOplogHours ? (
          <TopPaddedBody>
            <span className="italic-text">{`Oplog window is set to ${timeBasedOplogHours} hours. `}</span>
            <Link href={`${atlasClustersUrl}/edit/${clusterName}`} target="_blank" rel="noopener noreferrer">
              Update in Cluster Settings
            </Link>
          </TopPaddedBody>
        ) : (
          <TopPaddedBody>
            <RedTextSpan>{'Time-based Oplog Window is not set. '}</RedTextSpan>
            <Link href={`${atlasClustersUrl}/edit/${clusterName}`} target="_blank" rel="noopener noreferrer">
              Update in Cluster Settings
            </Link>
          </TopPaddedBody>
        )}
      </>
    ),
    statusIcon: ({ timeBasedOplogHours }: Props) => (timeBasedOplogHours ? successIcon : failIcon),
  },

  {
    title: 'Cluster Version',
    description: ({ atlasClustersUrl, clusterName, clusterVersion }: Props) => (
      <>
        <Body>
          {`Your data source needs to be running ${MONGODB_FLEXIBLE_SYNC_MIN_VERSION}+ in order to use Flexible Sync.`}
        </Body>

        {semverGte(clusterVersion, MONGODB_FLEXIBLE_SYNC_MIN_VERSION) ? (
          <TopPaddedBody>
            <InlineCode>{clusterName}</InlineCode>
            <span className="italic-text">{` is running ${clusterVersion}`}</span>
          </TopPaddedBody>
        ) : (
          <TopPaddedBody>
            <RedTextSpan>{`${clusterName} is running ${clusterVersion}. `}</RedTextSpan>
            <Link href={`${atlasClustersUrl}/edit/${clusterName}`} target="_blank" rel="noopener noreferrer">
              Upgrade your cluster
            </Link>
          </TopPaddedBody>
        )}
      </>
    ),
    statusIcon: ({ clusterVersion }: Props) =>
      semverGte(clusterVersion, MONGODB_FLEXIBLE_SYNC_MIN_VERSION) ? successIcon : failIcon,
  },

  {
    title: 'Rules & Permissions',
    description: ({ appUrl, isPermissionsMigratable }: Props) =>
      isPermissionsMigratable ? (
        <>
          <Body>
            {
              'Your sync permissions are compatible with Flexible Sync and will be converted to a Flexible Sync compatible format. You will be able to view these permissions in the '
            }
            <Link href={appUrl.rules().list()} hideExternalIcon>
              Rules tab
            </Link>
            {' after migration.'}
          </Body>

          <TopPaddedBody>
            {'Learn more about writing '}
            <DocLink href={docLinks.Sync.DefineReadWritePermissions} showExternalIcon>
              Flexible Sync permissions
            </DocLink>
          </TopPaddedBody>
        </>
      ) : (
        <Body>
          {
            'Your sync permissions are incompatible with Flexible Sync and will not be converted to a Flexible-Sync compatible format. You may consider re-defining your desired set of permissions in the '
          }
          <Link href={appUrl.rules().list()} hideExternalIcon>
            Rules tab
          </Link>
          {' to be used with Flexible Sync.'}
        </Body>
      ),
    statusIcon: ({ isPermissionsMigratable }: Props) => (isPermissionsMigratable ? successIcon : <div />),
  },
];

export enum TestSelector {
  IconPrefix = 'icon',
  RowPrefix = 'row',
}

export const CheckRequirementsBody = (props: Props) => (
  <>
    <Body>
      Next, we will confirm that your cluster and permissions are ready for Flexible Sync. Any errors below will need to
      be resolved in order to being migration.
    </Body>

    <Table data={rows} columns={[<TableHeader label="Status" />, <TableHeader label="Migration Requirements" />]}>
      {({ index, datum }) => (
        <StyledRow isGray={index % 2 === 0}>
          <Cell
            style={{ display: 'flex', justifyContent: 'center' }}
            data-testid={`${TestSelector.IconPrefix}${index}`}
          >
            {datum.statusIcon(props)}
          </Cell>
          <Cell>
            <Box data-testid={`${TestSelector.RowPrefix}${index}`}>
              <Body className="bold-text">{datum.title}</Body>

              {datum.description(props)}
            </Box>
          </Cell>
        </StyledRow>
      )}
    </Table>
  </>
);
