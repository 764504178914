import React from 'react';
import PropTypes from 'prop-types';

import { passThroughProps } from 'baas-ui/common/utils/util';

import './form-row.less';

const baseClassName = 'form-row';

export interface Props {
  children: React.ReactNode;
}

const FormRowHeader = ({ children, ...rest }: Props) => (
  <div className={`${baseClassName}-header`} {...passThroughProps(rest)}>
    {children}
  </div>
);

FormRowHeader.propTypes = {
  children: PropTypes.node,
};

FormRowHeader.defaultProps = {
  children: null,
};

export default FormRowHeader;
