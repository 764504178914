import { createAction } from 'redux-act';

import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import { DataAPIConfig } from 'admin-sdk';

const NAME = 'dataAPI/';

interface DataAPIConfigPayload extends BaseRequestPayload {
  config: DataAPIConfig;
}

export const showDataAPIEnabledToast = createAction(`${NAME}show data api enabled toast`);
export const hideDataAPIEnabledToast = createAction(`${NAME}hide data api enabled toast`);

export const [loadDataAPIVersionsActions, loadDataAPIVersions] = createActionsAndExecutor<void, String[]>(
  `${NAME}load versions`,
  (client) => () => client.dataAPI().versions().list()
);

export const [loadDataAPIConfigActions, loadDataAPIConfig] = createActionsAndExecutor<
  BaseRequestPayload,
  DataAPIConfig
>(
  `${NAME}load config`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).dataAPI().config().get()
);

export const [createDataAPIConfigActions, createDataAPIConfig] = createActionsAndExecutor<
  DataAPIConfigPayload,
  DataAPIConfig
>(
  `${NAME}create config`,
  (client, { groupId, appId, config }) =>
    () =>
      client.apps(groupId).app(appId).dataAPI().config().create(config)
);

export const [updateDataAPIConfigActions, updateDataAPIConfig] = createActionsAndExecutor<DataAPIConfigPayload, void>(
  `${NAME}update config`,
  (client, { groupId, appId, config }) =>
    () =>
      client.apps(groupId).app(appId).dataAPI().config().update(config)
);

export const asyncEditRcvActions = [createDataAPIConfigActions.rcv, updateDataAPIConfigActions.rcv];
