import React from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm';
import styled from '@emotion/styled';
import ConfirmationModal, { Variant } from '@leafygreen-ui/confirmation-modal';

import { ZIndex } from 'baas-ui/common/styles/zIndex';

const StyledConfirmationModal = styled(ConfirmationModal)`
  z-index: ${ZIndex.Modal};
`;

interface Props extends ReactConfirmProps {
  triggerName: string;
}

export const NewTrigger: React.FC<Props> = ({ cancel, proceed, show, triggerName }) => {
  return (
    <StyledConfirmationModal
      open={show}
      title="Trigger has unsaved changes"
      variant={Variant.Danger}
      cancelButtonProps={{
        onClick: cancel,
      }}
      confirmButtonProps={{
        children: 'Discard Changes',
        onClick: proceed,
      }}
    >
      &quot;{triggerName}&quot; has unsaved changes that will be discarded if you leave the page. Are you sure you want
      to discard your changes?
    </StyledConfirmationModal>
  );
};

export default createConfirmation(confirmable(NewTrigger));
