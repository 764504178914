import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  HostName,
  SecondarySideNavConfigInterface,
  SideNavOptionInterface,
  URLString,
  useCloudNavContext,
} from '@lg-private/cloud-nav';

import { docLinks } from 'baas-ui/common/links';
import { setShowSideNav } from 'baas-ui/home/actions';
import { getAppState, getServiceState, getSettingsState } from 'baas-ui/selectors';
import { isSelfHostedOrAtlasMongoService } from 'baas-ui/services/registry';
import { RootState } from 'baas-ui/types';
import rootUrl from 'baas-ui/urls';
import {
  isEdgeFeatureAllowed,
  isEndpointsAndDataApiAllowed,
  isGraphQLFeatureAllowed,
  isHostingFeaturedAllowed,
  isSyncFeatureAllowed,
  isThirdPartyServicesAllowed,
} from 'baas-ui/util';

export const filterDeprecatedItems = (items: SideNavOptionInterface[], appId: string): SideNavOptionInterface[] =>
  items.filter((item) => {
    switch (item.label) {
      case 'HTTPS Endpoints':
        return isEndpointsAndDataApiAllowed();
      case 'Device Sync':
      case 'Atlas Device SDK':
        return isSyncFeatureAllowed();
      case 'Edge Server':
        return isEdgeFeatureAllowed();
      case 'GraphQL':
        return isGraphQLFeatureAllowed();
      case 'Hosting':
        return isHostingFeaturedAllowed();
      case '3rd Party Services':
        return isThirdPartyServicesAllowed(appId);
      default:
        return true;
    }
  });

// useSideNav causes the secondary side nav (SSN) of CloudNavLayout to render. This will
// mainly be used within app specific (/groups/*/apps/*/**) pages
export const useSideNav = () => {
  const { setSecondaryNav } = useCloudNavContext();
  const dispatch = useDispatch();

  const app = useSelector((state: RootState) => getAppState(state).app);
  const svcsById = useSelector((state: RootState) => getServiceState(state).svcsById);

  const groupId = app?.groupId ?? '';
  const appId = app?.id ?? '';

  const { showCloudNav } = useSelector((state: RootState) => getSettingsState(state));
  const appsUrl = rootUrl.groups().group(groupId).apps();
  const appUrl = appsUrl.app(appId);

  const secondaryNav: SecondarySideNavConfigInterface | undefined = useMemo(() => {
    const buildSectionItems = [
      {
        id: 'deviceSDK',
        label: 'Atlas Device SDK',
        link: {
          host: HostName.BAAS,
          path: appUrl.sdks().list(),
          matcher: (url: string) => url.includes('/sdks'),
        },
      },
      {
        id: 'sync',
        label: 'Device Sync',
        link: {
          host: HostName.BAAS,
          path: appUrl.sync().root(),
          matcher: (url: string) => url.includes('/sync'),
        },
      },
      {
        id: 'edge',
        label: 'Edge Server',
        link: {
          host: HostName.BAAS,
          path: appUrl.edge().root(),
          matcher: (url: string) => url.includes('/edge'),
        },
      },
      {
        id: 'graphql',
        label: 'GraphQL',
        link: {
          host: HostName.BAAS,
          path: appUrl.graphql().root(),
          matcher: (url: string) => url.includes('/graphql'),
        },
      },
      {
        id: 'functions',
        label: 'Functions',
        link: {
          host: HostName.BAAS,
          path: appUrl.functions().list(),
          matcher: (url: string) => url.includes('/functions') || url.includes('/dependencies'),
        },
      },
      {
        id: 'triggers',
        label: 'Triggers',
        link: {
          host: HostName.BAAS,
          path: appUrl.triggers().list(),
          matcher: (url: string) => url.includes('/triggers'),
        },
      },
      {
        id: 'endpoints',
        label: 'HTTPS Endpoints',
        link: {
          host: HostName.BAAS,
          path: appUrl.endpoints().list(),
          matcher: (url: string) => url.includes('/endpoints'),
        },
      },
      {
        id: 'thirdPartyServices',
        label: '3rd Party Services',
        link: {
          host: HostName.BAAS,
          path: appUrl.services().list(),
          matcher: (url: string) =>
            url.includes('/services') &&
            !Object.values(svcsById).some((svc) => isSelfHostedOrAtlasMongoService(svc) && url.includes(svc.id)),
        },
      },
      {
        id: 'values',
        label: 'Values',
        link: {
          host: HostName.BAAS,
          path: appUrl.values().list(),
          matcher: (url: string) =>
            url.includes('/values') || url.includes('/sercrets') || url.includes('/environmentValues'),
        },
      },
    ];

    const manageSectionItems = [
      {
        id: 'dataSources',
        label: 'Linked Data Sources',
        link: {
          host: HostName.BAAS,
          path: appUrl.clusters().list(),
          matcher: (url: string) =>
            !url.includes('/rules') &&
            (url.includes('/dataSources') ||
              Object.values(svcsById).some((svc) => isSelfHostedOrAtlasMongoService(svc) && url.includes(svc.id))),
        },
      },
      {
        id: 'deployment',
        label: 'Deployment',
        link: {
          host: HostName.BAAS,
          path: appUrl.deploy().list(),
          matcher: (url: string) => url.includes('/deployment'),
        },
      },
      {
        id: 'hosting',
        label: 'Hosting',
        link: {
          host: HostName.BAAS,
          path: appUrl.hosting().list(),
          matcher: (url: string) => url.includes('/hosting'),
        },
      },
      {
        id: 'logs',
        label: 'Logs',
        link: {
          host: HostName.BAAS,
          path: appUrl.logs().list(),
          matcher: (url: string) => url.includes('/logs'),
        },
      },
      {
        id: 'settings',
        label: 'App Settings',
        link: {
          host: HostName.BAAS,
          path: appUrl.settings().list(),
          matcher: (url: string) => url.includes('/settings'),
        },
      },
      {
        id: 'pushNotifications',
        label: 'Push Notifications',
        link: {
          host: HostName.BAAS,
          path: Object.values(svcsById).some((svc) => svc.type === 'gcm')
            ? appUrl.push().list()
            : appUrl.push().config(),
          matcher: (url: string) => url.includes('/push'),
        },
      },
    ];

    return groupId && appId
      ? {
          title: '',
          parentId: 'all_services',
          items: [
            {
              id: 'allApps',
              label: 'All Apps',
              link: {
                host: HostName.BAAS,
                path: appsUrl.list(),
                matcher: (url: string) => url.endsWith('/apps'),
              },
            },
            {
              id: 'dashboard',
              label: 'Dashboard',
              link: {
                host: HostName.BAAS,
                path: appUrl.dashboard(),
                matcher: (url: string) => url.includes('/dashboard'),
              },
            },
            {
              id: 'dataAccess',
              label: 'Data Access',
              children: [
                {
                  id: 'rules',
                  label: 'Rules',
                  link: {
                    host: HostName.BAAS,
                    path: appUrl.rules().list(),
                    matcher: (url: string) =>
                      url.includes('/rules') && !url.includes('/services') && !url.includes('/push'),
                  },
                },
                {
                  id: 'schema',
                  label: 'Schema',
                  link: {
                    host: HostName.BAAS,
                    path: appUrl.schemas().root(),
                    matcher: (url: string) => url.includes('/schema'),
                  },
                },
                {
                  id: 'authUsers',
                  label: 'App Users',
                  link: {
                    host: HostName.BAAS,
                    path: appUrl.auth().users().list(),
                    matcher: (url: string) => url.includes('/users') || url.includes('/userSettings'),
                  },
                },
                {
                  id: 'authProviders',
                  label: 'Authentication',
                  link: {
                    host: HostName.BAAS,
                    path: appUrl.auth().providers().list(),
                    matcher: (url: string) => url.includes('/providers'),
                  },
                },
              ],
            },
            {
              id: 'build',
              label: 'Build',
              children: filterDeprecatedItems(buildSectionItems, appId),
            },
            {
              id: 'manage',
              label: 'Manage',
              children: filterDeprecatedItems(manageSectionItems, appId),
            },
            {
              id: 'help',
              label: 'Help',
              children: [
                {
                  id: 'documentation',
                  label: 'Documentation',
                  link: {
                    newTab: true,
                    absolute: true,
                    path: docLinks.General.AppServices as URLString,
                  },
                },
                {
                  id: 'tutorials',
                  label: 'Tutorials',
                  link: {
                    newTab: true,
                    absolute: true,
                    path: docLinks.General.Tutorials as URLString,
                  },
                },
              ],
            },
          ],
        }
      : undefined;
  }, [groupId, appId, svcsById]);

  // if baas-ui is using the new CloudNavLayout component, render the secondary side nav with all baas-specific links
  // setSecondaryNav should only be called from a descendant of the CloudNavLayout component, so if showCloudNav is
  // false, then skip making the call to setSecondaryNav
  useEffect(() => {
    if (showCloudNav) {
      dispatch(setShowSideNav(true));
      setSecondaryNav(secondaryNav);
    }
  }, [secondaryNav, showCloudNav, setSecondaryNav]);
};
