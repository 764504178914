import React, { useState } from 'react';
import Box from '@leafygreen-ui/box';
import Button from '@leafygreen-ui/button';
import { css, cx } from '@leafygreen-ui/emotion';
import { usePrevious } from '@leafygreen-ui/hooks';
import Icon from '@leafygreen-ui/icon';
import { Option, Select } from '@leafygreen-ui/select';
import { Body, Link } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import { Spinner } from 'baas-ui/common/components/spinner';
import { flagByCountry } from 'baas-ui/common/flags';
import useProviderRegions from 'baas-ui/common/hooks/use-provider-regions';
import { deploymentModelLabel, getCloudProviderFromProviderRegion } from 'baas-ui/common/utils/util';
import { cloudProviderOptions } from 'baas-ui/home/constants';
import { CloudProvider, RegionOptionTypes } from 'baas-ui/home/types';
import { useDarkMode } from 'baas-ui/theme';
import { DeploymentModel, ProviderRegion, ProviderRegionData } from 'admin-sdk';

import './provider-region-select.less';

export enum TestSelector {
  ProviderSelect = 'provider-select',
  RegionSelect = 'provider-region-select',
  ProviderRegionSelection = 'provider-region-selection',
  DeploymentModelSelection = 'deployment-model-selection',
  RegionRecommendationInfo = 'provider-region-recommendation-info',
  Confirm = 'provider-region-confirm',
  Cancel = 'provider-region-cancel',
  Change = 'provider-region-change',
  Error = 'provider-regions-error',
  LoadingSpinner = 'provider-regions-spinner',
}

const baseClassName = 'provider-region';
const baseFlagClassName = `${baseClassName}-flag`;

export interface ProviderRegionSelectProps {
  providerRegion: string;
  setProviderRegion(providerRegion: string): void;
  deploymentModel: DeploymentModel;
  onChange?(): void;
  onCancel?(): void;
  onConfirm?(): void;
  showRegionRecommendationInfo: boolean;
  showRegionSelect: boolean;
  showDeploymentModel?: boolean;
  allowChange?: boolean;
  disabled?: boolean;
  className?: string;
  showConfirmationButtons?: boolean;
}

const ProviderRegionSelect = ({
  providerRegion,
  setProviderRegion,
  deploymentModel,
  showRegionRecommendationInfo,
  onCancel = () => {},
  onConfirm = () => {},
  onChange = () => {},
  showRegionSelect = false,
  showDeploymentModel = false,
  allowChange = true,
  disabled = false,
  className = css`
    padding: 20px;
  `,
  showConfirmationButtons = true,
}: ProviderRegionSelectProps) => {
  const [unconfirmedProvider, setUnconfirmedProvider] = useState(CloudProvider.AWS);
  const [unconfirmedProviderRegion, setUnconfirmedProviderRegion] = useState(providerRegion);
  const [regionOptions, setRegionOptions] = useState<RegionOptionTypes[]>([]);
  const [providerRegionData, setProviderRegionData] = useState<ProviderRegionData>();
  const [onCancelWasCalled, setOnCancelWasCalled] = useState(false);
  const prevDeploymentModel = usePrevious(deploymentModel);
  const providerRegionsPayload = useProviderRegions();

  const cloudProvider = getCloudProviderFromProviderRegion(providerRegion);

  const darkMode = useDarkMode();

  // This guarantees that we always display the right provider given a provider region
  React.useEffect(() => {
    setUnconfirmedProvider(getCloudProviderFromProviderRegion(unconfirmedProviderRegion));
  }, [unconfirmedProviderRegion, setUnconfirmedProvider]);
  // TODO(BAAS-23307) Fix maximum depth exceeded error from useEffects
  React.useEffect(() => {
    if (providerRegionsPayload.error) {
      return;
    }
    const selectedRegion = providerRegionsPayload.providerRegions.find(({ id }) => id === providerRegion);
    if (selectedRegion) {
      setProviderRegionData(selectedRegion);
    }
  }, [providerRegion, providerRegionsPayload]);

  React.useEffect(() => {
    if (providerRegionsPayload.error) {
      return;
    }
    if (
      deploymentModel === DeploymentModel.Global &&
      prevDeploymentModel &&
      prevDeploymentModel !== deploymentModel &&
      !onCancelWasCalled
    ) {
      setUnconfirmedProviderRegion(
        providerRegionsPayload.regionsByProviderByDeployment[deploymentModel]?.[CloudProvider.AWS]?.[0].value
      );
    }
  }, [providerRegionsPayload, prevDeploymentModel, onCancelWasCalled, deploymentModel, setUnconfirmedProviderRegion]);

  React.useEffect(() => {
    if (providerRegionsPayload.error) {
      return;
    }
    if (deploymentModel === DeploymentModel.Global) {
      setRegionOptions(
        providerRegionsPayload.regionsByProviderByDeployment?.[deploymentModel]?.[CloudProvider.AWS] ?? []
      );
    } else {
      setRegionOptions(
        providerRegionsPayload.regionsByProviderByDeployment?.[deploymentModel]?.[unconfirmedProvider] ?? []
      );
    }
  }, [providerRegionsPayload, unconfirmedProvider, deploymentModel]);

  if (providerRegionsPayload.loading) {
    return (
      <Box className={`${baseClassName}-loading-container`}>
        <Spinner data-testid={TestSelector.LoadingSpinner} open xlarge />
      </Box>
    );
  }

  return (
    <Box
      className={cx(
        baseClassName,
        {
          [`${baseClassName}-flex-column`]: showRegionSelect,
          [`${baseClassName}-with-bg`]: allowChange,
          [`${baseClassName}-with-bg-dark-mode`]: allowChange && darkMode,
          [`${baseClassName}-with-bg-light-mode`]: allowChange && !darkMode,
          [`${baseClassName}-with-bg-expanded`]: !showDeploymentModel,
        },
        className
      )}
    >
      {showRegionSelect ? (
        <>
          {providerRegionsPayload.error && (
            <Body className={`${baseClassName}-loading-error`} data-testid={TestSelector.Error}>
              Failed to get deployment information - please try again later to select a different deployment provider
              and region.
            </Body>
          )}
          <Box className={`${baseClassName}-select-wrapper`}>
            <Select
              data-testid={TestSelector.ProviderSelect}
              className={`${baseClassName}-provider-select`}
              aria-labelledby="Provider Select"
              data-cy={TestSelector.ProviderSelect}
              value={deploymentModel === DeploymentModel.Global ? CloudProvider.AWS : unconfirmedProvider}
              onChange={(selectedProvider) => {
                setUnconfirmedProvider(selectedProvider as CloudProvider);
                // When switching providers we want to make sure a valid region is always selected
                setUnconfirmedProviderRegion(
                  providerRegionsPayload.regionsByProviderByDeployment[deploymentModel]?.[selectedProvider]?.[0]
                    ?.value ?? ''
                );
                if (!showConfirmationButtons) {
                  // return new selected ProviderRegion to parent
                  setProviderRegion(
                    providerRegionsPayload.regionsByProviderByDeployment[deploymentModel]?.[selectedProvider]?.[0]
                      ?.value ?? ''
                  );
                }
              }}
              allowDeselect={false}
              disabled={disabled || deploymentModel === DeploymentModel.Global}
            >
              {cloudProviderOptions.map(({ value, label }) => (
                <Option value={value} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
            <Select
              id="provider-region-select"
              data-testid={TestSelector.RegionSelect}
              className={`${baseClassName}-region-select`}
              data-cy={TestSelector.RegionSelect}
              aria-labelledby="Provider Region Select"
              value={unconfirmedProviderRegion}
              onChange={(selectedRegion) => {
                setUnconfirmedProviderRegion(selectedRegion as ProviderRegion);
                if (!showConfirmationButtons) {
                  setProviderRegion(selectedRegion as ProviderRegion);
                }
              }}
              allowDeselect={false}
              disabled={disabled}
            >
              {regionOptions.map(({ value, label }) => {
                const country = providerRegionsPayload.providerRegions.find(({ id }) => value === id)?.country || '';
                return (
                  <Option value={value} key={value} aria-label={label} className={`${baseClassName}-select-option`}>
                    <span className={`${baseClassName}-select-option-flag`}>{flagByCountry[country]}</span>
                    {label}
                  </Option>
                );
              })}
            </Select>
            {showConfirmationButtons && (
              <>
                <Button
                  className={`${baseClassName}-selection-button`}
                  data-cy="save-deployment-button"
                  data-testid={TestSelector.Confirm}
                  variant="primary"
                  disabled={disabled}
                  onClick={() => {
                    setUnconfirmedProviderRegion(unconfirmedProviderRegion);
                    setProviderRegion(unconfirmedProviderRegion);
                    onConfirm();
                  }}
                >
                  <Icon glyph="Checkmark" />
                </Button>
                <Button
                  className={`${baseClassName}-selection-button`}
                  data-testid={TestSelector.Cancel}
                  disabled={disabled}
                  onClick={() => {
                    setUnconfirmedProviderRegion(providerRegion);
                    onCancel();
                    setOnCancelWasCalled(true);
                  }}
                >
                  <Icon glyph="X" />
                </Button>
              </>
            )}
          </Box>
          <Body className={`${baseClassName}-select-info`}>
            We recommend choosing the region closest to your cluster&apos;s primary node or your Federated Database
            Instance&apos;s S3 bucket region.
          </Body>
        </>
      ) : (
        <>
          <Box data-cy="provider-region-and-deployment-model" className={`${baseClassName}-selection`}>
            {showDeploymentModel && (
              <Body className={`${baseClassName}-selection-body`}>
                <span>{deploymentModelLabel(deploymentModel)} •</span>
              </Body>
            )}
            <Body
              className={`${baseFlagClassName} ${providerRegionData?.country}`}
              data-testid={TestSelector.ProviderRegionSelection}
            >
              <span className={`${baseClassName}-select-option-flag`}>
                {flagByCountry[providerRegionData?.country ?? '']}
              </span>
              <span>{`${providerRegionData?.name || providerRegion} • ${cloudProvider.toUpperCase()} `}</span>
            </Body>
          </Box>
          {showRegionRecommendationInfo && (
            <Body data-testid={TestSelector.RegionRecommendationInfo} className={`${baseClassName}-info`}>
              Region chosen based on your cluster&apos;s region
            </Body>
          )}
          {allowChange && (
            <Link
              data-testid={TestSelector.Change}
              className={classNames(`${baseClassName}-change`, {
                [`${baseClassName}-change-with-border`]: !showRegionRecommendationInfo,
              })}
              onClick={() => {
                if (!disabled) {
                  onChange();
                }
              }}
              hideExternalIcon
            >
              Change
            </Link>
          )}
        </>
      )}
    </Box>
  );
};

export default ProviderRegionSelect;
