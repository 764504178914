import { AuthProviderConfig, AuthProviderType, PartialAuthProviderConfig } from 'admin-sdk';

import { ALL_PROVIDERS, NEW_PROVIDER_ID } from './constants';

export const defaultProviders: Partial<{ [k in AuthProviderType]: AuthProviderConfig }> = Object.fromEntries(
  ALL_PROVIDERS.map((id) => [id, new AuthProviderConfig({ id: NEW_PROVIDER_ID, name: id, type: id, disabled: true })])
);

export const isAuthProviderNew = (provider: AuthProviderConfig) => !provider.id || provider.id === NEW_PROVIDER_ID;

export const constructProvidersByType = (providers: PartialAuthProviderConfig[]) => {
  const newProviders = Object.fromEntries(
    providers.map((provider) => [provider.type, new AuthProviderConfig(provider)])
  );
  return { ...defaultProviders, ...newProviders };
};
