import { createAction } from 'redux-act';

import { handleResourceNotFound } from 'baas-ui/action_wrappers';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import { EnvironmentValue, PartialEnvironmentValue, PartialSecret, PartialValue, Secret, Value } from 'admin-sdk';

import { EnvironmentValueForCreate, SecretForCreate, ValueForCreate } from './types';

export const [loadValuesActions, loadValues] = createActionsAndExecutor<BaseRequestPayload, PartialValue[]>(
  `values/load all`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).values().list()
);

export const [loadValueActions, loadValueInner] = createActionsAndExecutor<BaseRequestPayload & { id: string }, Value>(
  `values/load one`,
  (client, { groupId, appId, id }) =>
    () =>
      client.apps(groupId).app(appId).values().value(id).get()
);

export const loadValue = handleResourceNotFound(loadValueInner);

export const [createValueActions, createValue] = createActionsAndExecutor<
  BaseRequestPayload & { value: ValueForCreate },
  Value
>(
  `values/create`,
  (client, { groupId, appId, value }) =>
    () =>
      client.apps(groupId).app(appId).values().create(new Value(value))
);

export const [updateValueActions, updateValue] = createActionsAndExecutor<BaseRequestPayload & { value: Value }, void>(
  `values/update`,
  (client, { groupId, appId, value }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .values()
        .value(value.id || '')
        .update(new Value(value))
);

export const [deleteValueActions, deleteValue] = createActionsAndExecutor<BaseRequestPayload & { id: string }, void>(
  `values/delete`,
  (client, { groupId, appId, id }) =>
    () =>
      client.apps(groupId).app(appId).values().value(id).remove()
);

export const discardEditingValues = createAction('values/discard editing values');

export const [loadEnvironmentValuesActions, loadEnvironmentValues] = createActionsAndExecutor<
  BaseRequestPayload,
  PartialEnvironmentValue[]
>(
  `environmentValues/load all`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).environmentValues().list()
);

export const [loadEnvironmentValueActions, loadEnvironmentValueInner] = createActionsAndExecutor<
  BaseRequestPayload & { id: string },
  EnvironmentValue
>(
  `environmentValues/load one`,
  (client, { groupId, appId, id }) =>
    () =>
      client.apps(groupId).app(appId).environmentValues().environmentValue(id).get()
);

export const loadEnvironmentValue = handleResourceNotFound(loadEnvironmentValueInner);

export const [createEnvironmentValueActions, createEnvironmentValue] = createActionsAndExecutor<
  BaseRequestPayload & { environmentValue: EnvironmentValueForCreate },
  EnvironmentValue
>(
  `environmentValues/create`,
  (client, { groupId, appId, environmentValue }) =>
    () =>
      client.apps(groupId).app(appId).environmentValues().create(new EnvironmentValue(environmentValue))
);

export const [updateEnvironmentValueActions, updateEnvironmentValue] = createActionsAndExecutor<
  BaseRequestPayload & { environmentValue: EnvironmentValue },
  void
>(
  `environmentValues/update`,
  (client, { groupId, appId, environmentValue }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .environmentValues()
        .environmentValue(environmentValue.id || '')
        .update(new EnvironmentValue(environmentValue))
);

export const [deleteEnvironmentValueActions, deleteEnvironmentValue] = createActionsAndExecutor<
  BaseRequestPayload & { id: string },
  void
>(
  `environmentValues/delete`,
  (client, { groupId, appId, id }) =>
    () =>
      client.apps(groupId).app(appId).environmentValues().environmentValue(id).remove()
);

export const [loadSecretsActions, loadSecrets] = createActionsAndExecutor<BaseRequestPayload, PartialSecret[]>(
  `secrets/load`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).secrets().list()
);

export const [createSecretActions, createSecret] = createActionsAndExecutor<
  BaseRequestPayload & { secret: SecretForCreate },
  Secret
>(
  `secrets/create`,
  (client, { groupId, appId, secret }) =>
    () =>
      client.apps(groupId).app(appId).secrets().create(new Secret(secret))
);

export const [updateSecretActions, updateSecret] = createActionsAndExecutor<
  BaseRequestPayload & { secret: Secret },
  void
>(
  `secrets/update`,
  (client, { groupId, appId, secret }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .secrets()
        .secret(secret.id || '')
        .update(new Secret(secret))
);

export const [deleteSecretActions, deleteSecret] = createActionsAndExecutor<BaseRequestPayload & { id: string }, void>(
  `secrets/delete`,
  (client, { groupId, appId, id }) =>
    () =>
      client.apps(groupId).app(appId).secrets().secret(id).remove()
);

export const asyncEditRcvActions = [
  createValueActions.rcv,
  updateValueActions.rcv,
  deleteValueActions.rcv,
  createEnvironmentValueActions.rcv,
  updateEnvironmentValueActions.rcv,
  deleteEnvironmentValueActions.rcv,
];
