import { ScaledValue } from 'baas-ui/app/metrics-card/types';
import { DataUnit, TimeUnit, ValueUnit } from 'baas-ui/app/metrics-card/utils';
import { GroupMetrics, Metric } from 'admin-sdk';

import { BilledMetric, MetricDetailsByName, MetricName, MetricUsagesByName } from './types';

export const DEFAULT_METRICS: MetricUsagesByName = {
  [BilledMetric.RequestCount]: -1,
  [BilledMetric.ComputeTime]: -1,
  [BilledMetric.DataOut]: -1,
  [BilledMetric.SyncTime]: -1,
};

export const NO_VALUE = '--';

const bytesInGigabyte = 1024 * 1024 * 1024;

const convertBytesToGigabytes = (bytes: number) => {
  return bytes / bytesInGigabyte;
};

const convertMinutesToHours = (minutes: number) => {
  return minutes / 60;
};

const convertMillisecondsToHours = (minutes: number) => {
  return minutes / (1000 * 60 * 60);
};

export const BILLED_METRICS_DETAILS: MetricDetailsByName = {
  [BilledMetric.ComputeTime]: {
    title: 'Compute Runtime',
    titleWithUnits: 'Compute Runtime (hr)',
    description: 'Measures the runtime and memory usage of all requests excluding sync and authentication',
    pricingThreshold: (isPricingChangeEnabled: boolean) =>
      isPricingChangeEnabled ? { value: 25, formatted: '25 hr' } : { value: 500, formatted: '500 hr' },
    converter: (value: number, _: boolean) => ({
      value: convertMillisecondsToHours(value),
      units: TimeUnit.Hours,
    }),
  },
  [BilledMetric.DataOut]: {
    title: 'Data Transfer',
    titleWithUnits: 'Data Transfer (GB)',
    description: 'Measures the amount of data that App Services sends to external services and client applications',
    pricingThreshold: (isPricingChangeEnabled: boolean) =>
      isPricingChangeEnabled ? { value: 0.5, formatted: '0.5 GB' } : { value: 10, formatted: '10 GB' },
    converter: (value: number, _: boolean) => ({ value: convertBytesToGigabytes(value), units: DataUnit.GB }),
  },
  [BilledMetric.RequestCount]: {
    title: 'Requests',
    titleWithUnits: 'Requests',
    description:
      'Measures the number of requests that App Services sends and receives from client applications and external services',
    pricingThreshold: (isPricingChangeEnabled: boolean) =>
      isPricingChangeEnabled ? { value: 50000, formatted: '50K' } : { value: 1000000, formatted: '1M' },
    converter: (value: number, _: boolean) => ({ value, units: ValueUnit.None }),
  },
  [BilledMetric.SyncTime]: {
    title: 'Sync Runtime',
    titleWithUnits: 'Sync Runtime (min)',
    description: 'Measures the total amount of time in which a client application is actively syncing',
    pricingThreshold: (isPricingChangeEnabled: boolean) =>
      isPricingChangeEnabled ? { value: 30000, formatted: '30K min' } : { value: 10000, formatted: '10K hr' },
    converter: (value: number, isPricingChangeEnabled: boolean) =>
      isPricingChangeEnabled
        ? { value, units: TimeUnit.Minutes }
        : { value: convertMinutesToHours(value), units: TimeUnit.Hours },
  },
};

export function calculateTotalUsageForGroupAndAppMetrics(groupMetrics: GroupMetrics | void): {
  groupUsages: MetricUsagesByName;
  appUsages: Record<string, MetricUsagesByName>;
} {
  const groupUsages: MetricUsagesByName = {
    [BilledMetric.RequestCount]: 0,
    [BilledMetric.ComputeTime]: 0,
    [BilledMetric.DataOut]: 0,
    [BilledMetric.SyncTime]: 0,
  };

  const appUsages: Record<string, MetricUsagesByName> = {};

  if (groupMetrics === undefined) {
    return { groupUsages, appUsages };
  }

  Object.entries(groupMetrics.metricsMap).forEach(([appId, appMetrics]) => {
    appUsages[appId] = {
      [BilledMetric.RequestCount]: 0,
      [BilledMetric.ComputeTime]: 0,
      [BilledMetric.DataOut]: 0,
      [BilledMetric.SyncTime]: 0,
    };

    appMetrics.measurements.forEach((metric: Metric) => {
      switch (metric.name) {
        case MetricName.OVERALL_SUCCESSFUL_REQUESTS:
          groupUsages[BilledMetric.RequestCount] += metric.totalUsage ?? 0;
          appUsages[appId][BilledMetric.RequestCount] += metric.totalUsage ?? 0;
          break;
        case MetricName.OVERALL_FAILED_REQUESTS:
          groupUsages[BilledMetric.RequestCount] += metric.totalUsage ?? 0;
          appUsages[appId][BilledMetric.RequestCount] += metric.totalUsage ?? 0;
          break;
        case MetricName.OVERALL_COMPUTE_MS:
          groupUsages[BilledMetric.ComputeTime] += metric.totalUsage ?? 0;
          appUsages[appId][BilledMetric.ComputeTime] = metric.totalUsage ?? 0;
          break;
        case MetricName.OVERALL_EGRESS_BYTES:
          groupUsages[BilledMetric.DataOut] += metric.totalUsage ?? 0;
          appUsages[appId][BilledMetric.DataOut] = metric.totalUsage ?? 0;
          break;
        case MetricName.OVERALL_SYNC_MINUTES:
          groupUsages[BilledMetric.SyncTime] += metric.totalUsage ?? 0;
          appUsages[appId][BilledMetric.SyncTime] = metric.totalUsage ?? 0;
          break;
        default:
        // do nothing
      }
    });
  });

  return { groupUsages, appUsages };
}

const ONE_THOUSAND = 1000;

export const scaledMetricUsage = (scaledValue: ScaledValue): string => {
  let usage = NO_VALUE;

  const rawUsage = scaledValue.value;
  if (rawUsage < 0) {
    return usage;
  }

  const scaled = scaledValue.value > 1000 || scaledValue.units !== ValueUnit.None;

  const precision = scaled ? 2 : 0;
  const min = (1 / 10) ** precision;

  if (rawUsage >= ONE_THOUSAND ** 3) {
    usage = `${(rawUsage / ONE_THOUSAND ** 3).toFixed(precision)}B`;
  } else if (rawUsage >= ONE_THOUSAND ** 2) {
    usage = `${(rawUsage / ONE_THOUSAND ** 2).toFixed(precision)}M`;
  } else if (rawUsage >= ONE_THOUSAND) {
    usage = `${(rawUsage / ONE_THOUSAND).toFixed(precision)}K`;
  } else if (rawUsage < min && rawUsage > 0) {
    usage = `< ${min.toFixed(precision)}`;
  } else {
    usage = rawUsage.toFixed(precision);
  }
  return usage;
};

export const filterNullDataPoints = (metrics: Metric[]) => {
  return metrics.map((metric) => {
    // CONTEXT(CLOUDP-98364): Drop datapoints containing null values as this behavior "works as designed"
    return { ...metric, dataPoints: metric.dataPoints.filter((d) => d.value !== null) };
  });
};
