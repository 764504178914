import { createAction } from 'redux-act';

import { handleResourceNotFound } from 'baas-ui/action_wrappers';
import { redirectTo } from 'baas-ui/actions';
import { AsyncDispatch, createActionsAndExecutor } from 'baas-ui/redux_util';
import { getAppState, getServiceState, getValuesState } from 'baas-ui/selectors';
import { BaseRequestPayload, RootState } from 'baas-ui/types';
import urls from 'baas-ui/urls';
import * as valueActions from 'baas-ui/values/actions';
import { SecretForCreate } from 'baas-ui/values/types';
import { BypassServiceChangeValue, PartialServiceDesc, ServiceDesc, ServiceDescConfig } from 'admin-sdk';

import * as sagas from './sagas';
import { Config } from './types';

const NAME = 'services/';

export interface ServiceRequestPayload extends BaseRequestPayload {
  svcId: string;
}

interface BypassServiceChangePayload {
  bypassServiceChange?: BypassServiceChangeValue;
}

interface CreateServicePayload extends BaseRequestPayload {
  serviceDesc: ServiceDesc;
  skipDraft?: boolean;
}

interface UpgradeServicePayload extends ServiceRequestPayload {
  version: number;
}

interface SaveConfigPayload extends ServiceRequestPayload {
  config: Config;
}

interface MultipleDataSourcesPayload extends BaseRequestPayload {
  dataSources: ServiceDesc[];
}

export const clearErrors = createAction(`${NAME}clear errors`);
export const clearServiceConfig = createAction(`${NAME}clear service config`);
export const setConfigInput = createAction<Config>(`${NAME}set config input`);
export const removeConfigInputField = createAction<{ key: string }>(`${NAME}remove config input field`);
export const setReadPreferenceTagsError = createAction<string>(`${NAME}set read preference tags error`);
export const resetConfigInput = createAction(`${NAME}reset config input`);

export const [createServiceActions, createService] = createActionsAndExecutor<CreateServicePayload, PartialServiceDesc>(
  `${NAME}create service`,
  (client, { groupId, appId, serviceDesc, skipDraft }) =>
    () =>
      client.apps(groupId).app(appId).services().create(serviceDesc, skipDraft)
);

export const [loadServicesActions, loadServices] = createActionsAndExecutor<BaseRequestPayload, PartialServiceDesc[]>(
  `${NAME}load services`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).services().list()
);

export const [deleteSvcActions, deleteSvc] = createActionsAndExecutor<
  ServiceRequestPayload & BypassServiceChangePayload,
  void
>(
  `${NAME}delete service`,
  (client, { groupId, appId, svcId, bypassServiceChange }) =>
    () =>
      client.apps(groupId).app(appId).services().service(svcId).remove(bypassServiceChange)
);

export const [upgradeSvcActions, upgradeSvc] = createActionsAndExecutor<UpgradeServicePayload, PartialServiceDesc>(
  `${NAME}upgrade service`,
  (client, { groupId, appId, svcId, version }) =>
    () =>
      client.apps(groupId).app(appId).services().service(svcId).update(version)
);

export const [loadSvcActions, _loadSvc] = createActionsAndExecutor<ServiceRequestPayload, PartialServiceDesc>(
  `${NAME}load service`,
  (client, { groupId, appId, svcId }) =>
    () =>
      client.apps(groupId).app(appId).services().service(svcId).get()
);
export const loadSvc = handleResourceNotFound(_loadSvc);

export const [loadSvcConfigActions, _loadSvcConfig] = createActionsAndExecutor<
  ServiceRequestPayload,
  ServiceDescConfig
>(
  `${NAME}load service config`,
  (client, { groupId, appId, svcId }) =>
    () =>
      client.apps(groupId).app(appId).services().service(svcId).config().getWithSecrets()
);
export const loadSvcConfig = handleResourceNotFound(_loadSvcConfig);

export const [saveSvcConfigActions, saveSvcConfig] = createActionsAndExecutor<
  SaveConfigPayload & BypassServiceChangePayload,
  void
>(`${NAME}save svc config`, (client, { groupId, appId, svcId, config, bypassServiceChange }) => () => {
  return client.apps(groupId).app(appId).services().service(svcId).config().update(config, bypassServiceChange);
});

export const [linkMultipleDataSourcesActions, linkMultipleDataSources] = createActionsAndExecutor<
  MultipleDataSourcesPayload,
  void
>(
  `${NAME}link multiple data sources`,
  (client, { groupId, appId, dataSources }) =>
    () =>
      client.apps(groupId).app(appId).linkMultipleDataSources(dataSources)
);

export const asyncEditRcvActions = [
  createServiceActions.rcv,
  saveSvcConfigActions.rcv,
  deleteSvcActions.rcv,
  upgradeSvcActions.rcv,
];

interface ConfigEditProps {
  app: {
    groupId: string;
    id: string;
  };
  service: {
    id: string;
  };
}

export const configEditMapper = {
  mapStateToProps: (state: RootState) => {
    const { error } = getAppState(state);
    const { createServiceError, serviceConfig, configInput, savingConfig, configSaveError, svcConfigsById } =
      getServiceState(state);
    const { secrets } = getValuesState(state);

    return {
      error,
      secrets: secrets.data || [],
      loadingSecrets: secrets.isLoading,
      createServiceError,
      serviceConfig,
      serviceConfigInputs: configInput,
      savingConfig,
      configSaveError,
      secretSaveError: secrets.error,
      svcConfigsById,
    };
  },
  mapDispatchToProps: (dispatch: AsyncDispatch, ownProps: ConfigEditProps) => ({
    createSecret: (newSecret: SecretForCreate) =>
      dispatch(valueActions.createSecret({ groupId: ownProps.app.groupId, appId: ownProps.app.id, secret: newSecret })),
    loadSecrets: () => dispatch(valueActions.loadSecrets({ groupId: ownProps.app.groupId, appId: ownProps.app.id })),
    loadSvcWithConfig: ({ groupId, appId, svcId }: ServiceRequestPayload) =>
      dispatch(sagas.loadSvcWithConfig(groupId, appId, svcId)),
    setConfigInput: (key: string, value: any) => dispatch(setConfigInput({ key, value })),
    removeConfigInputField: (key: string) => dispatch(removeConfigInputField({ key })),
    setReadPreferenceTagsError: (error: string) => dispatch(setReadPreferenceTagsError(error)),
    onSubmit: (config: Config) =>
      dispatch(sagas.updateServiceConfig(ownProps.app.groupId, ownProps.app.id, ownProps.service.id, config)),
    onCreateService: (svcConfig: ServiceDesc) =>
      dispatch(sagas.createService(ownProps.app.groupId, ownProps.app.id, svcConfig)).catch(() => {}),
    onCancelCreateAtlasService: () =>
      dispatch(
        redirectTo(urls.groups().group(ownProps.app.groupId).apps().app(ownProps.app.id).clusters().list(), {
          replace: false,
        })
      ),
  }),
};
