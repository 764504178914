import { createReducer } from 'redux-act';

import * as actions from './actions';
import { LogFilterType, LogsState } from './types';

export const defaultState = {
  coId: '',
  endDate: undefined,
  entries: [],
  error: undefined,
  errorsOnly: false,
  limit: undefined,
  loadingLogs: false,
  logFilterTypes: new Set() as Set<LogFilterType>,
  paginationNextEndTime: undefined,
  paginationNextSkip: undefined,
  startDate: undefined,
  userId: '',
  applied: undefined,
};

const reducer = createReducer<LogsState>({}, defaultState);

reducer.on(actions.resetLogs, (state) => ({
  ...state,
  entries: [],
  loadingLogs: false,
  error: undefined,
  paginationNextEndTime: undefined,
  paginationNextSkip: undefined,
}));

reducer.on(actions.setStartDate, (state, startDate) => ({ ...state, startDate }));

reducer.on(actions.setEndDate, (state, endDate) => ({ ...state, endDate }));

reducer.on(actions.setErrorsOnly, (state, errorsOnly) => ({ ...state, errorsOnly }));

reducer.on(actions.setLogFilterTypes, (state, logFilterTypes) => ({ ...state, logFilterTypes }));

reducer.on(actions.setUserId, (state, userId) => ({ ...state, userId }));

reducer.on(actions.setCoId, (state, coId) => ({ ...state, coId }));

reducer.on(actions.setLimit, (state, limit) => ({ ...state, limit }));

reducer.on(actions.clearError, (state) => ({ ...state, error: undefined }));

reducer.on(actions.clearDownloadError, (state) => ({ ...state, downloadError: undefined }));

reducer.on(actions.setApplied, (state, applied) => ({ ...state, applied }));

reducer.on(actions.loadLogsActions.req, (state) => ({ ...state, loadingLogs: true, entries: [] }));

reducer.on(actions.loadLogsActions.fail, (state, { error }) => ({
  ...state,
  error,
  loadingLogs: false,
}));

reducer.on(actions.loadLogsActions.rcv, (state, { payload }) => {
  let entries;
  if (state.paginationNextEndTime || state.paginationNextSkip) {
    entries = state.entries.concat(payload.logs ? payload.logs : []);
  } else {
    entries = payload.logs;
  }
  return {
    ...state,
    error: undefined,
    loadingLogs: false,
    entries,
    paginationNextEndTime: payload.nextEndDate ? new Date(payload.nextEndDate) : undefined,
    paginationNextSkip: payload.nextSkip,
  };
});

reducer.on(actions.downloadLogsAction.req, (state) => ({
  ...state,
  downloadError: undefined,
}));

reducer.on(actions.downloadLogsAction.rcv, (state) => ({
  ...state,
  downloadError: undefined,
}));

reducer.on(actions.downloadLogsAction.fail, (state, { error }) => ({
  ...state,
  downloadError: error,
}));

export default reducer;
