import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Guide')
export class Guide {
  @JsonProperty('title')
  public title = '';

  @JsonProperty('body', String, true)
  public body?: string = '';

  constructor(partial?: Partial<Guide>) {
    Object.assign(this, partial);
  }
}

@JsonObject('Snippet')
export class Snippet {
  @JsonProperty('id')
  public id = '';

  @JsonProperty('title')
  public title = '';

  @JsonProperty('category')
  public category = '';

  @JsonProperty('description')
  public description = '';

  @JsonProperty('guide', [Guide], true)
  public guide?: Guide[] = undefined;

  @JsonProperty('github_url')
  public githubUrl = '';

  @JsonProperty('snippet')
  public snippet = '';

  @JsonProperty('snippet_type')
  public snippetType = '';

  @JsonProperty('view_type')
  public viewType = '';

  constructor(partial?: Partial<Snippet>) {
    Object.assign(this, partial);
  }
}
