import { HeliotropeReturn, UpdateStateOptions } from 'heliotrope';

import EVENTS from './events';

export type EventProperties = { [key: string]: any };

export type TrackingEvent = {
  eventType: string;
  action?: string;
  actionFn?: (properties: EventProperties) => string;
  context?: string;
};

type EventKey = keyof typeof EVENTS;

type EventKeyMap = {
  [K in keyof typeof EVENTS]?:
    | boolean
    | {
        shouldTrack: boolean;
        args?: object;
      };
};

export type TrackableEvent = EventKey | EventKeyMap;

export interface EventLogger {
  init(userId: string);
  reset();
  logEvent(eventType: string, eventProperties?: EventProperties): Promise<HeliotropeReturn>;
  updateState(stateOptions: UpdateStateOptions);
}

// AdminErrorSource documents where the error for an ADMIN_ERROR.ERROR_ENCOUNTERED event comes from
export enum AdminErrorSource {
  AdminRequest = 'Admin Request',
}
