import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { passThroughProps } from 'baas-ui/common/utils/util';

import './spinner.less';

const baseClassName = 'spinner';

export enum TestSelector {
  Spinner = 'spinner',
}

export interface Props {
  large?: boolean;
  xlarge?: boolean;
  open?: boolean;
  centered?: boolean;
}

const Spinner = ({ open, large, xlarge, centered, ...rest }: Props) => {
  if (!open) {
    return null;
  }

  return (
    <div
      className={classNames(baseClassName, {
        [`${baseClassName}-is-large`]: large,
        [`${baseClassName}-is-xlarge`]: xlarge,
        [`${baseClassName}-is-centered`]: centered,
      })}
      data-testid={TestSelector.Spinner}
      {...passThroughProps(rest)}
    />
  );
};

Spinner.defaultProps = {
  large: false,
  xlarge: false,
  open: false,
  centered: false,
};

Spinner.propTypes = {
  large: PropTypes.bool,
  xlarge: PropTypes.bool,
  open: PropTypes.bool,
  centered: PropTypes.bool,
};

export default Spinner;
