import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('WebhookOptions')
export class WebhookOptions {
  @JsonProperty('secret', String, true)
  public secret?: string = undefined;

  @JsonProperty('httpMethod', String, true)
  public httpMethod?: string = undefined;

  @JsonProperty('validationMethod', String, true)
  public validationMethod?: string = undefined;

  constructor(partial?: Partial<WebhookOptions>) {
    Object.assign(this, partial);
  }
}

@JsonObject('IncomingWebhook')
export class IncomingWebhook {
  @JsonProperty('_id', String, true)
  public id?: string = undefined;

  @JsonProperty('name')
  public name = '';

  @JsonProperty('function_source')
  public functionSource = '';

  @JsonProperty('last_modified', Number, true)
  public lastModified?: number = undefined;

  @JsonProperty('can_evaluate', Any, true)
  public canEvaluate?: Record<string, any> = {};

  @JsonProperty('run_as_user_id')
  public runAsUserId = '';

  @JsonProperty('run_as_user_id_script_source')
  public runAsUserIdScriptSource = '';

  @JsonProperty('run_as_authed_user')
  public runAsAuthedUser = false;

  @JsonProperty('options', WebhookOptions, true)
  public options?: WebhookOptions = new WebhookOptions();

  @JsonProperty('respond_result')
  public respondResult = false;

  @JsonProperty('disable_arg_logs', Boolean, true)
  public disableArgLogs?: boolean = false;

  @JsonProperty('fetch_custom_user_data')
  public fetchCustomUserData = false;

  @JsonProperty('create_user_on_auth')
  public createUserOnAuth = false;

  constructor(partial?: Partial<IncomingWebhook>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PartialIncomingWebhook')
export class PartialIncomingWebhook {
  @JsonProperty('_id', String, true)
  public id = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('last_modified', Number, true)
  public lastModified?: number = undefined;
}
