import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadApp, setDeploymentMigrating } from 'baas-ui/app/actions';
import { StatusBanner, Variant } from 'baas-ui/common/components/status';
import usePoller from 'baas-ui/common/hooks/use-poller';
import { docLinks } from 'baas-ui/common/links';
import { applicationDeploymentMetadataLabel } from 'baas-ui/common/utils/util';
import { getAdminClientState, getAppState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';
import { DeploymentMigrationStatus } from 'admin-sdk';

export enum TestSelector {
  MigrationStatusBanner = 'migration-status-banner',
  PendingStatus = 'migration-pending-status',
  SuccessStatus = 'migration-success-status',
  FailedStatus = 'migration-failed-status',
}

export const MIGRATION_SUCCESS = 'successful';
export const MIGRATION_FAILED = 'failed';

// Exporting so I can mock this function easily in the tests
export const fetchDeploymentMigrationStatus = (client: any, appId: string, groupId: string) =>
  client?.apps(groupId).app(appId).deploymentMigration().get();

function MigrationStatusBannerComponent() {
  const poller = usePoller('deploymentMigrationStatusPoller');
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [failed, setFailed] = useState(false);
  const [fromLabel, setFromLabel] = useState('');
  const [toLabel, setToLabel] = useState('');
  const [message, setMessage] = useState('');
  const {
    app: { id: appId, groupId },
    isDeploymentMigrating,
  } = useSelector((state: RootState) => getAppState(state));
  const client = useSelector((state: RootState) => getAdminClientState(state));

  const loadMigrationStatus = () => {
    fetchDeploymentMigrationStatus(client, appId, groupId)
      .then((response: DeploymentMigrationStatus) => {
        setFromLabel(applicationDeploymentMetadataLabel(response.from.deploymentModel, response.from.providerRegion));
        setToLabel(applicationDeploymentMetadataLabel(response.to.deploymentModel, response.to.providerRegion));
        setMessage(response.message);
        const inProgress =
          !!response.status && response.status !== MIGRATION_SUCCESS && response.status !== MIGRATION_FAILED;
        // If the migration is not in progress, the only other scenario where we would want to be visible is if we were
        // previously migrating
        setVisible(inProgress || isDeploymentMigrating);

        const hasSucceeded = response.status === MIGRATION_SUCCESS;
        setSucceeded(hasSucceeded);
        if (hasSucceeded && isDeploymentMigrating) {
          dispatch(loadApp({ groupId, appId }));
        }

        setFailed(response.status === MIGRATION_FAILED);
        dispatch(setDeploymentMigrating(inProgress));
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    loadMigrationStatus();
  }, []);

  React.useEffect(() => {
    if (isDeploymentMigrating) {
      poller.start(loadMigrationStatus, 3e3);
    }

    return () => poller.stop();
  }, [isDeploymentMigrating]);

  if (!visible) {
    return null;
  }

  const styledMessage = (
    <div data-testid={TestSelector.FailedStatus} data-cy={TestSelector.FailedStatus}>
      {failed && (
        <div>
          <p>
            <b>App region change failed</b>
          </p>
          <p>
            There was an issue changing your app from {fromLabel}, to {toLabel}.
          </p>
          <p>Please try changing your deployment model again.</p>
        </div>
      )}
      {succeeded && (
        <div data-testid={TestSelector.SuccessStatus} data-cy={TestSelector.SuccessStatus}>
          <p>
            App region was successfully changed from {fromLabel}, to {toLabel}.
          </p>
          <p>Be sure to update your requests URLs (if applicable).</p>
        </div>
      )}
      {!succeeded && !failed && (
        <div data-testid={TestSelector.PendingStatus} data-cy={TestSelector.PendingStatus}>
          <p>
            Updating app region from {fromLabel}, to {toLabel}.
          </p>
          <p>No changes can be saved until region migration is complete: {message}</p>
        </div>
      )}
    </div>
  );

  let bannerVariant = Variant.Info;
  if (isDeploymentMigrating) {
    bannerVariant = Variant.Info;
  }
  if (succeeded) {
    bannerVariant = Variant.Success;
  }
  if (failed) {
    bannerVariant = Variant.Warning;
  }

  return (
    <StatusBanner
      message={styledMessage}
      variant={bannerVariant}
      statusType={'MIGRATION'}
      statusId="migration-status-banner"
      clearable={succeeded}
      onClear={() => setVisible(false)}
      helpUrl={failed ? docLinks.General.Support : ''}
      data-testid={TestSelector.MigrationStatusBanner}
      data-cy={TestSelector.MigrationStatusBanner}
    />
  );
}

export default MigrationStatusBannerComponent;
