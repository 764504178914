import React, { useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { connect, useSelector } from 'react-redux';

import { redirectTo } from 'baas-ui/actions';
import { DataSourceSelectOptionType, DataSourceType } from 'baas-ui/common/components/data-source-select';
import { EnvironmentDesc, PRODUCT_SELF_HOSTED } from 'baas-ui/common/constants';
import useSessionStorage from 'baas-ui/common/hooks/use-session-storage';
import {
  clusterForNewAppIsProvisioningKey,
  githubRequestIdKey,
  showClusterProvisioningToastKey,
  showFrontendTemplateModalOnNewAppKey,
  showGuidesOnStartKey,
  showGuidesTooltipOnStartKey,
} from 'baas-ui/common/local-storage-keys';
import { selectedTemplateIdKey } from 'baas-ui/common/session-storage-keys';
import { clusterIsFreeTier } from 'baas-ui/common/utils/util';
import * as deployActions from 'baas-ui/deploy/actions';
import {
  DEFAULT_BRANCH,
  environmentOptions,
  EnvironmentSelectOption,
  INSTALLATION_CHECK_DELAY,
  MAX_INSTALLATION_CHECKS,
} from 'baas-ui/deploy/constants';
import { RepositorySelectOption, SelectOption } from 'baas-ui/deploy/types';
import * as actions from 'baas-ui/home/actions';
import { CreateAppErrors, providerRegionToLocation } from 'baas-ui/home/constants';
import { useDefaultAppName } from 'baas-ui/home/create-app/form/hooks';
import {
  createClusterSelectOptions,
  createDataLakeSelectOptions,
  createFlexClusterSelectOptions,
  createServerlessSelectOptions,
} from 'baas-ui/home/create-app/form/util';
import { TemplateIdentifier } from 'baas-ui/home/create-app/types';
import { DataSourceLinkMethod, DefaultDataSourceServiceName } from 'baas-ui/home/types';
import { validateStoredRequestId } from 'baas-ui/home/validation';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { getHomeState, getSettingsState } from 'baas-ui/selectors';
import { MongoDataSourceType } from 'baas-ui/services/registry';
import { track } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import urls from 'baas-ui/urls';
import {
  AtlasProduct,
  CreateAppRequest,
  DeploymentModel,
  GitHubBranch,
  GithubGroupAuthenticationResponse,
  Installation,
  Location,
  PartialApp,
  ProviderRegion,
} from 'admin-sdk';

interface AppProps {
  groupId: string;
  isLoadingApps: boolean;
  setIsLoadingApps(loading: boolean): void;
  defaultAppName: string;
  appName: string;
  setAppName(appName: string): void;
  appNameError: string;
  setAppNameError(appNameError: string): void;
  templateId: TemplateIdentifier;
  setTemplateId(templateId: TemplateIdentifier): void;
  appEnvironment: EnvironmentSelectOption;
  setAppEnvironment(environment: EnvironmentSelectOption): void;
  deploymentModel: DeploymentModel;
  setDeploymentModel(deploymentModel: DeploymentModel): void;
  location: Location;
  setLocation(location: Location): void;
  defaultProviderRegion: ProviderRegion;
  providerRegion: ProviderRegion;
  setProviderRegion(providerRegion: ProviderRegion): void;
  setIsUserSelectedProviderRegion(isUserSelectedProviderRegion: boolean): void;
  isCreatingApp: boolean;
  handleCreateApp(): Promise<void>;
  createAppError: string;
}

interface DataSourceProps {
  product: string;
  loadingDataSources: boolean;
  m0Version: string;
  clusterOpts: DataSourceSelectOptionType[];
  atlasClustersUrl: string;
  dataLakeOpts: DataSourceSelectOptionType[];
  flexClusterOpts: DataSourceSelectOptionType[];
  onlineArchiveOpts: DataSourceSelectOptionType[];
  serverlessInstanceOpts: DataSourceSelectOptionType[];
  dataSourceError: string;
  dataSourceLinkMethod: DataSourceLinkMethod;
  setDataSourceLinkMethod(dataSourceLinkMethod: DataSourceLinkMethod): void;
  selectedDataSource?: DataSourceSelectOptionType;
  setSelectedDataSource(selectedDataSource?: DataSourceSelectOptionType): void;
}

interface CodeDeployProps {
  hasAuthorizationError: boolean;
  onGithubAuth(): void;
  isGithubLinked: boolean;
  onUnlinkClick(): void;
  isLoadingInstallations: boolean;
  deployInstallations: Installation[];
  repositoryOptions: RepositorySelectOption[];
  isLoadingBranches: boolean;
  branchOptions: SelectOption[];
  repository: RepositorySelectOption | null;
  setRepository(so: RepositorySelectOption): void;
  branch: SelectOption | null;
  setBranch(so: SelectOption): void;
  directory: string;
  setDirectory(string): void;
}

// CreateAppProps is the set of props that withCreateApp provides to its wrapped child components.
// It is NOT the props that are passed into withCreateApp.
export type CreateAppProps = AppProps & CodeDeployProps & DataSourceProps;
export interface WithCreateAppProps {
  createAppProps: CreateAppProps;
}

const formatStoredGithubRequestId = (requestId: string, requestTimestamp: string) => {
  return `${requestId}-${requestTimestamp}`;
};

// withCreateApp manages various props and state needed for any component that wants to create apps
// NOTE: Components that are wrapped by this HOC need to be inside a GoogleReCaptchaProvider, so that
// creating a cluster with a new app works
const withCreateApp = <P extends Record<string, any>>(
  WrappedComponent: React.ComponentType<P & WithCreateAppProps>
): {
  unconnectedComponent: React.ComponentType<P & WithCreateAppProps>;
  wrappedComponent: React.ComponentType<Omit<P, 'createAppProps'>>;
} => {
  const Component = (props) => {
    const [isLoadingApps, setIsLoadingApps] = useState(false);
    const [appName, setAppName] = useState('');
    const [appNameError, setAppNameError] = useState('');
    const [templateId, setTemplateId] = useSessionStorage<TemplateIdentifier>(
      selectedTemplateIdKey(props.groupId),
      TemplateIdentifier.Default
    );
    const [appEnvironment, setAppEnvironment] = useState(environmentOptions[0]);
    const [deploymentModel, setDeploymentModel] = useState(DeploymentModel.Local);
    const [location, setLocation] = useState(Location.Virginia);
    const [defaultProviderRegion, setDefaultProviderRegion] = useState(ProviderRegion.AWSProviderRegionUSEast1);
    const [providerRegion, setProviderRegion] = useState(ProviderRegion.AWSProviderRegionUSEast1);
    const [isUserSelectedProviderRegion, setIsUserSelectedProviderRegion] = React.useState(false);
    const [isCreatingApp, setIsCreatingApp] = useState(false);
    const [loadingDataSources, setLoadingDataSources] = useState(false);
    const [m0Version, setM0Version] = useState('');
    const [clusterOpts, setClusterOpts] = React.useState<DataSourceSelectOptionType[]>([]);
    const [dataLakeOpts, setDataLakeOpts] = React.useState<DataSourceSelectOptionType[]>([]);
    const [flexClusterOpts, setFlexClusterOpts] = React.useState<DataSourceSelectOptionType[]>([]);
    const [onlineArchiveOpts, setOnlineArchiveOpts] = React.useState<DataSourceSelectOptionType[]>([]);
    const [serverlessInstanceOpts, setServerlessInstanceOpts] = React.useState<DataSourceSelectOptionType[]>([]);
    const [dataSourceError, setDataSourceError] = useState('');
    const [dataSourceLinkMethod, setDataSourceLinkMethod] = useState(DataSourceLinkMethod.UseExisting);
    const [selectedDataSource, setSelectedDataSource] = useState<DataSourceSelectOptionType | undefined>();
    const [hasAuthorizationError, setHasAuthorizationError] = useState(false);
    const [isLoadingInstallations, setIsLoadingInstallations] = useState(false);
    const [deployInstallations, setDeployInstallations] = useState<Installation[]>([]);
    const [githubRequestId, setGithubRequestId] = useState('');
    const [isLoadingBranches, setIsLoadingBranches] = useState(false);
    const [deployBranches, setDeployBranches] = useState<GitHubBranch[]>([]);
    const [repository, setRepository] = useState<RepositorySelectOption | null>(null);
    const [branch, setBranch] = useState<SelectOption | null>(null);
    const [directory, setDirectory] = useState('/');

    const { executeRecaptcha } = useGoogleReCaptcha();
    const interval = useRef<number>();
    const authWindow = useRef<Window | null>();

    const atlasClustersUrl = `${props.cloudUIBaseUrl}/v2/${props.groupId}#clusters`;
    const atlasDataLakesUrl = `${props.cloudUIBaseUrl}/v2/${props.groupId}#dataFederation`;

    const defaultAppName = useDefaultAppName({ apps: props.apps, setAppName });
    const { nearestProviderRegion, nearestProviderRegions } = useSelector(getSettingsState);

    // Handles setting the closest provider region and location when a user selects a data source or changes the
    // data source link method. If the user has manually selected the provider region, this logic is skipped.
    React.useEffect(() => {
      if (isUserSelectedProviderRegion) {
        return;
      }

      let recommendedProviderRegion!: ProviderRegion;
      if (dataSourceLinkMethod === DataSourceLinkMethod.CreateCluster) {
        recommendedProviderRegion = nearestProviderRegion;
      } else if (dataSourceLinkMethod === DataSourceLinkMethod.UseExisting) {
        const backingProviderName = selectedDataSource?.backingProviderName?.toLowerCase();
        const regionName = selectedDataSource?.regionName?.toUpperCase();
        if (backingProviderName && regionName && nearestProviderRegions[backingProviderName]?.[regionName]) {
          recommendedProviderRegion = nearestProviderRegions[backingProviderName][regionName];
        }
      }

      if (recommendedProviderRegion) {
        setProviderRegion(recommendedProviderRegion);
        setDefaultProviderRegion(recommendedProviderRegion);
        setLocation(providerRegionToLocation[recommendedProviderRegion]);

        if (
          props.environmentDesc === EnvironmentDesc.Local ||
          props.environmentDesc === EnvironmentDesc.Dev ||
          props.environmentDesc === EnvironmentDesc.QA
        ) {
          // Local Deployment Model only works in Prod and Staging, so we'll only force this change there
          setDeploymentModel(DeploymentModel.Global);
        }
      }
    }, [dataSourceLinkMethod, selectedDataSource, isUserSelectedProviderRegion, props.environmentDesc]);

    // persist and load selected template id in app creation form
    // this useEffect is needed because groupId is not immediately available
    React.useEffect(() => {
      const { setCreateAppError } = props;
      try {
        setTemplateId(JSON.parse(window.sessionStorage[selectedTemplateIdKey(props.groupId)]));
      } catch {
        actions.setCreateAppError(CreateAppErrors.Generic);
      }

      return () => {
        setCreateAppError(CreateAppErrors.None);
      };
    }, [props.groupId]);

    React.useEffect(() => {
      const { getDefaultM0Version } = props;
      const m0VersionAsync = async () => {
        try {
          const version = await getDefaultM0Version();
          setM0Version(version);
        } catch {
          // if request fails, we use an empty string as the version
        }
      };
      m0VersionAsync();
    }, []);

    // Load data sources
    React.useEffect(() => {
      const {
        groupId,
        product,
        getAtlasClusters,
        getAtlasDataLakes,
        getAtlasFlexClusters,
        getAtlasOnlineArchives,
        getAtlasServerlessInstances,
      } = props;
      const getDataSourcesAsync = async () => {
        setDataSourceError('');
        setLoadingDataSources(true);

        try {
          const [loadedClusters, loadedLakes, loadedFlexClusters, loadedOnlineArchives, loadedServerlessInstances] =
            await Promise.all([
              getAtlasClusters(),
              getAtlasDataLakes(),
              getAtlasFlexClusters().catch(() => []),
              getAtlasOnlineArchives(),
              getAtlasServerlessInstances(),
            ]);

          if (loadedClusters) {
            setClusterOpts(createClusterSelectOptions(loadedClusters, atlasClustersUrl));
          }

          if (loadedLakes) {
            setDataLakeOpts(createDataLakeSelectOptions(loadedLakes, atlasDataLakesUrl));
          }

          if (loadedFlexClusters) {
            setFlexClusterOpts(createFlexClusterSelectOptions(loadedFlexClusters, atlasClustersUrl));
          }

          if (loadedOnlineArchives) {
            setOnlineArchiveOpts(createDataLakeSelectOptions(loadedOnlineArchives, atlasClustersUrl));
          }

          if (loadedServerlessInstances) {
            setServerlessInstanceOpts(createServerlessSelectOptions(loadedServerlessInstances, atlasClustersUrl));
          }
        } catch (e) {
          setDataSourceError(`Error loading data sources.${e?.message && ` Error: ${e?.message}`}`);
        }
        setLoadingDataSources(false);
      };

      if (product !== PRODUCT_SELF_HOSTED && groupId) {
        getDataSourcesAsync();
      }
      // createAppError is included in the dep array to avoid having stale info after app creation fails
    }, [props.groupId, props.createAppError]);

    React.useEffect(() => {
      const { groupId, loadInstallations, setCreateAppError } = props;
      const loadInstallationsAsync = async () => {
        setIsLoadingInstallations(true);
        try {
          const storedRequestId = window.localStorage.getItem(githubRequestIdKey(groupId));
          const validRequestId = validateStoredRequestId(storedRequestId ?? '');
          if (validRequestId) {
            setGithubRequestId(validRequestId);
            const installations = await loadInstallations(groupId, validRequestId);
            if (installations) {
              setDeployInstallations(installations);
            }
          }
        } catch (e) {
          setCreateAppError(e.message);
        } finally {
          setIsLoadingInstallations(false);
        }
      };

      if (groupId) {
        loadInstallationsAsync();
      }
    }, [props.groupId]);

    React.useEffect(() => {
      const { loadBranches, setCreateAppError } = props;
      const loadBranchesAsync = async () => {
        if (repository) {
          setIsLoadingBranches(true);
          try {
            const branches = await loadBranches(repository.installationId, repository.value, githubRequestId);
            if (branches) {
              setDeployBranches(branches);
            }
          } catch (e) {
            setCreateAppError(e.message);
          } finally {
            setIsLoadingBranches(false);
          }
        }
      };

      loadBranchesAsync();
    }, [repository]);

    const repositoryOptions: RepositorySelectOption[] = deployInstallations
      ? deployInstallations.reduce((repoOpts: RepositorySelectOption[], installation: Installation) => {
          installation.authenticatedRepositories.forEach((repo) =>
            repoOpts.push({
              label: repo.fullName,
              value: repo.id,
              installationId: installation.installationId,
              fullName: repo.fullName,
            })
          );
          return repoOpts;
        }, [])
      : [];

    const branchOptions: SelectOption[] = deployBranches?.length
      ? deployBranches.reduce((branchOpts: SelectOption[], branches: GitHubBranch) => {
          branchOpts.push({ label: branches.name, value: branches.name });
          return branchOpts;
        }, [])
      : [
          {
            label: DEFAULT_BRANCH,
            value: DEFAULT_BRANCH,
          },
        ];

    const isGithubLinked = !!repositoryOptions.length;

    React.useEffect(() => {
      if (isGithubLinked) {
        setHasAuthorizationError(false);
        clearInterval(interval.current);
      }

      return () => {
        clearInterval(interval.current);
      };
    }, [interval, isGithubLinked]);

    const handleGithubAuth = async () => {
      const { groupId, initGithub, loadInstallations, setCreateAppError } = props;
      setIsLoadingInstallations(true);
      try {
        const resp: GithubGroupAuthenticationResponse = await initGithub();
        if (resp && resp.githubRedirectUrl !== '') {
          // Set the response request ID in local storage before redirecting
          try {
            window.localStorage.setItem(
              githubRequestIdKey(groupId),
              formatStoredGithubRequestId(resp.requestId, resp.requestTimestamp)
            );
          } catch (e) {
            setCreateAppError(CreateAppErrors.Generic);
          }

          authWindow.current = window.open(resp.githubRedirectUrl, 'Authorize App Services to Access Github');

          let tries = 0;
          interval.current = window.setInterval(async () => {
            if (++tries > MAX_INSTALLATION_CHECKS) {
              setHasAuthorizationError(true);
              clearInterval(interval.current);
              return;
            }

            setGithubRequestId(resp.requestId);
            const installations = await loadInstallations(groupId, resp.requestId);
            if (installations) {
              setDeployInstallations(installations);
            }
          }, INSTALLATION_CHECK_DELAY);
        }
      } catch (e) {
        setCreateAppError(e.message);
      } finally {
        setIsLoadingInstallations(false);
      }
    };

    const handleUnlinkClick = () => {
      window.localStorage.removeItem(githubRequestIdKey(props.groupId));
      props.clearInstallations();
      setDeployInstallations([]);
      setDeployBranches([]);
    };

    const handleCreateApp = async () => {
      const {
        apps,
        product,
        createApp,
        clusterStatePoller,
        getNewAppClusterState,
        clearDataSourceErrors,
        getAtlasClusters,
        navigateToApp,
        setCreateAppError,
        enableAutoDeployWithTTLInstallation,
        pushApp,
        verifyRecaptcha,
      } = props;

      setCreateAppError(CreateAppErrors.None);

      if (templateId !== TemplateIdentifier.Default && product === PRODUCT_SELF_HOSTED) {
        setCreateAppError(CreateAppErrors.SelfHosted);
        setIsCreatingApp(false);
        return;
      }

      let requestBody: Partial<CreateAppRequest> = {
        name: appName,
        location,
        providerRegion,
        deploymentModel,
        environment: appEnvironment.value,
        templateId,
      };

      if (product !== PRODUCT_SELF_HOSTED) {
        switch (dataSourceLinkMethod) {
          case DataSourceLinkMethod.UseExisting:
            if (selectedDataSource?.type === DataSourceType.CLUSTER) {
              requestBody = {
                ...requestBody,
                dataSource: {
                  name: DefaultDataSourceServiceName.Cluster,
                  type: MongoDataSourceType.Atlas,
                  config: {
                    clusterName: selectedDataSource?.value,
                  },
                },
              };
            } else if (selectedDataSource?.type === DataSourceType.DATA_FEDERATION) {
              requestBody = {
                ...requestBody,
                dataSource: {
                  name: selectedDataSource?.value.includes(DataSourceType.ONLINE_ARCHIVE)
                    ? DefaultDataSourceServiceName.OnlineArchive
                    : DefaultDataSourceServiceName.DataLake,
                  type: MongoDataSourceType.DataFederation,
                  config: {
                    dataLakeName: selectedDataSource?.value,
                  },
                },
              };
            }
            break;
          case DataSourceLinkMethod.CreateCluster:
            requestBody = {
              ...requestBody,
              createCluster: true,
            };
            break;
          default:
            break;
        }
      }

      const createAppRequest = new CreateAppRequest(requestBody);
      track('APPLICATION.CREATE_NEW_SUBMITTED', {
        ...createAppRequest,
        clusterTier: selectedDataSource?.instanceSize,
        clusterCloudProvider: selectedDataSource?.backingProviderName,
        clusterRegion: selectedDataSource?.regionName,
      });
      setIsCreatingApp(true);

      try {
        clearDataSourceErrors();

        // verify the session in order to create a cluster
        if (product !== PRODUCT_SELF_HOSTED && dataSourceLinkMethod === DataSourceLinkMethod.CreateCluster) {
          const rcToken = await executeRecaptcha!('createApp');
          await verifyRecaptcha(rcToken);
        }
        const createdApp = await createApp(createAppRequest);
        if (!createdApp) {
          return;
        }

        const isFirstApp = apps.length === 0;
        if (isFirstApp && templateId === TemplateIdentifier.Default) {
          window.localStorage.setItem(showGuidesOnStartKey(createdApp.id), 'true');
        } else {
          if (templateId !== TemplateIdentifier.Default) {
            window.localStorage.setItem(showFrontendTemplateModalOnNewAppKey(createdApp.id), 'true');
          }
          window.localStorage.setItem(showGuidesTooltipOnStartKey(createdApp.id), 'true');
        }

        // Data source related errors are handled in redux and not here, for this reason we use an empty catch
        // so that they don't bleed over to the current try -> catch clause. This is useful because these
        // two data source related errors are handled on the newly create app's dashboard with a banner on the top.
        if (product !== PRODUCT_SELF_HOSTED && dataSourceLinkMethod === DataSourceLinkMethod.CreateCluster) {
          apps.forEach((app) => {
            window.localStorage.removeItem(clusterForNewAppIsProvisioningKey(app.id));
            window.localStorage.removeItem(showClusterProvisioningToastKey(app.id));
          });
          window.localStorage.setItem(clusterForNewAppIsProvisioningKey(createdApp.id), 'true');
          window.localStorage.setItem(showClusterProvisioningToastKey(createdApp.id), 'true');

          getAtlasClusters().then((atlasClusters) => {
            if (atlasClusters) {
              const freeCluster = atlasClusters.find((cluster) => clusterIsFreeTier(cluster));
              const freeClusterName = freeCluster?.name;
              if (freeClusterName) {
                clusterStatePoller.start(() => getNewAppClusterState(freeClusterName).catch(() => {}), 3000);
              }
            }
          });
        }

        // edit the app's deployConfig to turn autoDeploy on, and push
        // the initial app to the user's selected GitHub repository
        if (repository?.value && branch?.value) {
          await enableAutoDeployWithTTLInstallation(
            createdApp.id,
            repository?.installationId,
            githubRequestId,
            repository?.value,
            branch?.value,
            directory
          );

          await pushApp(createdApp.id, 'Initial deployment from App Services');
        }

        sessionStorage.removeItem(selectedTemplateIdKey(props.groupId));
        navigateToApp(createdApp);
      } catch (e) {
        clusterStatePoller.stop();
        if (!e || !e.message) {
          setCreateAppError(CreateAppErrors.Generic);
        } else if (e.response && e.response.status === 403) {
          setCreateAppError(CreateAppErrors.Forbidden);
        } else if (e.message) {
          setCreateAppError(e.message);
        }
      } finally {
        setIsCreatingApp(false);
      }
    };

    const allCreateAppProps: CreateAppProps = {
      groupId: props.groupId,
      isLoadingApps,
      setIsLoadingApps,
      defaultAppName,
      appName,
      setAppName,
      appNameError,
      setAppNameError,
      templateId,
      setTemplateId,
      appEnvironment,
      setAppEnvironment,
      deploymentModel,
      setDeploymentModel,
      location,
      setLocation,
      defaultProviderRegion,
      providerRegion,
      setProviderRegion,
      setIsUserSelectedProviderRegion,
      isCreatingApp,
      handleCreateApp,
      createAppError: props.createAppError,
      product: props.product,
      loadingDataSources,
      m0Version,
      clusterOpts,
      atlasClustersUrl,
      dataLakeOpts,
      flexClusterOpts,
      onlineArchiveOpts,
      serverlessInstanceOpts,
      dataSourceError,
      dataSourceLinkMethod,
      setDataSourceLinkMethod,
      selectedDataSource,
      setSelectedDataSource,
      hasAuthorizationError,
      onGithubAuth: handleGithubAuth,
      isGithubLinked,
      onUnlinkClick: handleUnlinkClick,
      isLoadingInstallations,
      deployInstallations,
      repositoryOptions,
      isLoadingBranches,
      branchOptions,
      repository,
      setRepository,
      branch,
      setBranch,
      directory,
      setDirectory,
    };

    return <WrappedComponent {...props} createAppProps={{ ...allCreateAppProps }} />;
  };

  const mapStateToProps = (state: RootState) => {
    const { apps, createAppError, groupId } = getHomeState(state);
    const { cloudUIBaseUrl, product, environmentDesc } = getSettingsState(state);

    return {
      apps,
      cloudUIBaseUrl,
      createAppError,
      environmentDesc,
      groupId,
      product,
    };
  };

  const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
    clearInstallations: () => dispatch(deployActions.clearInstallations()),
    createApp: (groupId: string, request: CreateAppRequest) => dispatch(actions.createApp({ groupId, request })),
    getNewAppClusterState: (groupId: string, clusterName: string) =>
      dispatch(actions.getNewAppClusterState({ groupId, clusterName })),
    getDefaultM0Version: () => dispatch(actions.getDefaultM0Version()),
    getAtlasClusters: (groupId: string) => dispatch(actions.getAtlasClusters({ groupId })),
    getAtlasDataLakes: (groupId: string) => dispatch(actions.getAtlasDataLakes({ groupId })),
    getAtlasFlexClusters: (groupId: string) => dispatch(actions.getAtlasFlexClusters({ groupId })),
    getAtlasOnlineArchives: (groupId: string) =>
      dispatch(actions.getAtlasDataLakes({ groupId, atlasProduct: AtlasProduct.OnlineArchive })),
    getAtlasServerlessInstances: (groupId: string) => dispatch(actions.getAtlasServerlessInstances({ groupId })),
    initGithub: (groupId: string) => dispatch(deployActions.initializeGithubAuthForGroup({ groupId })),
    loadInstallations: (groupId: string, githubAuthRequestId: string) =>
      dispatch(deployActions.loadInstallationsByRequestId({ groupId, githubAuthRequestId })),
    verifyRecaptcha: (token: string) => dispatch(actions.verifyRecaptcha({ token })),
    loadBranches: (groupId: string, installationId: string, repositoryId: string, githubAuthRequestId: string) =>
      dispatch(
        deployActions.loadTTLInstallationBranches({ groupId, installationId, repositoryId, githubAuthRequestId })
      ),
    enableAutoDeployWithTTLInstallation: (
      groupId: string,
      appId: string,
      installationId: string,
      githubAuthRequestId: string,
      repositoryId: string,
      branch: string,
      directory: string
    ) =>
      dispatch(
        deployActions.enableAutoDeployWithTTLInstallation({
          groupId,
          appId,
          installationId,
          githubAuthRequestId,
          repositoryId,
          branch,
          directory,
        })
      ),
    clearDataSourceErrors: () => dispatch(actions.clearDataSourceErrors()),
    pushApp: (groupId: string, appId: string, deploymentName: string) =>
      dispatch(deployActions.pushApp({ groupId, appId, deploymentName })),
    navigateToApp: (app: PartialApp) =>
      dispatch(redirectTo(urls.groups().group(app.groupId!).apps().app(app.id!).get())),
    setCreateAppError: (error) => dispatch(actions.setCreateAppError(error)),
  });

  const mergeProps = (
    { groupId, ...otherStateProps }: ReturnType<typeof mapStateToProps>,
    {
      createApp,
      enableAutoDeployWithTTLInstallation,
      getAtlasClusters,
      getAtlasDataLakes,
      getAtlasFlexClusters,
      getAtlasOnlineArchives,
      getAtlasServerlessInstances,
      getNewAppClusterState,
      initGithub,
      loadBranches,
      pushApp,
      ...otherDispatchProps
    }: ReturnType<typeof mapDispatchToProps>,
    ownProps: P
  ) => ({
    ...otherStateProps,
    ...otherDispatchProps,
    ...ownProps,
    groupId,
    createApp: (request: CreateAppRequest) => createApp(groupId, request),
    enableAutoDeployWithTTLInstallation: (
      appId: string,
      ttlInstallationId: string,
      githubAuthRequestId: string,
      repositoryId: string,
      branch: string,
      directory: string
    ) =>
      enableAutoDeployWithTTLInstallation(
        groupId,
        appId,
        ttlInstallationId,
        githubAuthRequestId,
        repositoryId,
        branch,
        directory
      ),
    pushApp: (appId: string, deploymentName: string) => pushApp(groupId, appId, deploymentName),
    getAtlasClusters: () => getAtlasClusters(groupId),
    getAtlasDataLakes: () => getAtlasDataLakes(groupId),
    getAtlasFlexClusters: () => getAtlasFlexClusters(groupId),
    getAtlasOnlineArchives: () => getAtlasOnlineArchives(groupId),
    getAtlasServerlessInstances: () => getAtlasServerlessInstances(groupId),
    initGithub: () => initGithub(groupId),
    getNewAppClusterState: (clusterName: string) => getNewAppClusterState(groupId, clusterName),
    loadBranches: (installationId: string, repositoryId: string, githubAuthRequestId: string) =>
      loadBranches(groupId, installationId, repositoryId, githubAuthRequestId),
  });

  return {
    unconnectedComponent: Component,
    wrappedComponent: connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component),
  };
};

export default withCreateApp;
