import React from 'react';
import { Link } from '@leafygreen-ui/typography';

import './footer.less';

const baseClassName = 'footer';

const Footer = () => {
  const uiVersion = process.env.UI_VERSION;
  const serverGitRev = process.env.SERVER_GIT_REV;
  const serverVersion = process.env.SERVER_VERSION;

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}-app-data`}>
        <ul>
          {serverGitRev && (
            <li>
              Server Version:&nbsp;
              <Link href={`https://github.com/10gen/baas/commit/${serverGitRev}`} hideExternalIcon>
                {serverGitRev.substring(0, 10)}
              </Link>
            </li>
          )}
          {serverVersion && (
            <li>
              Server Version:&nbsp;
              <Link href={`https://github.com/10gen/baas/releases/tag/${serverVersion}`} hideExternalIcon>
                {serverVersion}
              </Link>
            </li>
          )}
          {uiVersion && (
            <li>
              UI Version:&nbsp;
              <Link href={`https://github.com/10gen/baas-ui/releases/tag/v${uiVersion}`} hideExternalIcon>
                {uiVersion}
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Footer;
