import { JsonObject, JsonProperty } from 'json2typescript';

import { ProviderRegion } from '../common';

export enum PrivateEndpointType {
  // TODO(BAAS-29271): Remove legacy type
  Legacy = 'legacy',
  General = 'general',
  Sync = 'sync',
}

@JsonObject('ProviderPrivateEndpointServiceInfo')
export class ProviderPrivateEndpointServiceInfo {
  @JsonProperty('cloud_provider_region', String)
  public cloudProviderRegion: ProviderRegion = ProviderRegion.AWSProviderRegionUSEast1;

  @JsonProperty('endpoint_type', String)
  public endpointType: PrivateEndpointType = PrivateEndpointType.General;

  @JsonProperty('service_name', String)
  public serviceName = '';

  constructor(partial?: Partial<ProviderPrivateEndpointServiceInfo>) {
    Object.assign(this, partial);
  }
}
