import React from 'react';
import Button, { Size, Variant } from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import TextInput, { SizeVariant } from '@leafygreen-ui/text-input';

import { TestSelector as EditRoleFormTestSelector } from './EditRoleForm';

import './edit-role-form.less';
const fieldPermsBaseClassName = 'edit-role-form-field-level-perms';

export interface FieldNameInputProps {
  fieldName: string;
  onFieldNameChange: (fieldName: string) => void;
  onClickSubmit: () => void;
  onClickDelete: () => void;
}

export enum TestSelector {
  Container = 'field-name-input-container',
}

export const FieldNameInput = ({ fieldName, onFieldNameChange, onClickSubmit, onClickDelete }: FieldNameInputProps) => {
  return (
    <div className={`${fieldPermsBaseClassName}-input-group`} data-testid={TestSelector.Container}>
      <TextInput
        aria-label="Add field name input"
        aria-labelledby={fieldName || 'Add Field Input'}
        placeholder="Enter field name"
        value={fieldName}
        onChange={(e) => onFieldNameChange(e.target.value)}
        type="text"
        data-test-selector={EditRoleFormTestSelector.AddFieldInput}
        data-cy={EditRoleFormTestSelector.AddFieldInput}
        sizeVariant={SizeVariant.Small}
      />
      <Button
        className={`${fieldPermsBaseClassName}-button`}
        variant={Variant.PrimaryOutline}
        size={Size.Small}
        leftGlyph={<Icon glyph="Checkmark" />}
        data-test-selector={EditRoleFormTestSelector.FieldInputCheckmarkButton}
        data-testid={EditRoleFormTestSelector.FieldInputCheckmarkButton}
        data-cy={EditRoleFormTestSelector.FieldInputCheckmarkButton}
        onClick={onClickSubmit}
        disabled={!fieldName}
      />
      <Button
        className={`${fieldPermsBaseClassName}-button`}
        variant={Variant.Danger}
        size={Size.Small}
        leftGlyph={<Icon glyph="Trash" />}
        data-test-selector={EditRoleFormTestSelector.FieldInputDeleteButton}
        data-testid={EditRoleFormTestSelector.FieldInputDeleteButton}
        data-cy={EditRoleFormTestSelector.FieldInputDeleteButton}
        onClick={onClickDelete}
      />
    </div>
  );
};
