import React from 'react';
import ConfirmationModal, { Variant } from '@leafygreen-ui/confirmation-modal';

import { ZIndex } from 'baas-ui/common/styles/zIndex';

export enum TestSelector {
  Modal = 'delete-app-confirmation-modal',
}
export interface Props {
  appName: string;
  isOpen: boolean;
  onConfirmDelete(): void;
  onCancel(): void;
}

const DeleteAppConfirmation = ({ appName, isOpen, onConfirmDelete, onCancel }: Props) => (
  <ConfirmationModal
    data-testid={TestSelector.Modal}
    title={`Delete Application: ${appName}`}
    confirmButtonProps={{
      children: 'Delete',
      onClick: onConfirmDelete,
    }}
    cancelButtonProps={{
      onClick: onCancel,
    }}
    variant={Variant.Danger}
    requiredInputText={appName}
    open={isOpen}
    style={{ zIndex: ZIndex.Modal }}
  >
    Are you sure you want to delete your application: <b>{appName}</b>?
  </ConfirmationModal>
);

export default DeleteAppConfirmation;
