import { MeasurementName, MeasurementUnit, PartialApp } from 'admin-sdk';

export interface Measurement {
  name: MeasurementName;
  units: MeasurementUnit;
  usage: number;
  threshold: number;
}

export const DEFAULT_MEASUREMENTS: MeasurementsByName = {
  [MeasurementName.ComputeTime]: {
    name: MeasurementName.ComputeTime,
    units: MeasurementUnit.Hours,
    usage: -1,
    threshold: 500,
  },
  [MeasurementName.DataOut]: {
    name: MeasurementName.DataOut,
    units: MeasurementUnit.Gigabytes,
    usage: -1,
    threshold: 10,
  },
  [MeasurementName.RequestCount]: {
    name: MeasurementName.RequestCount,
    units: MeasurementUnit.None,
    usage: -1,
    threshold: 1000000,
  },
  [MeasurementName.SyncTime]: {
    name: MeasurementName.SyncTime,
    units: MeasurementUnit.Hours,
    usage: -1,
    threshold: 10000,
  },
};

export type MeasurementsByName = { [K in MeasurementName]: Measurement };

export interface UsageMetrics {
  lastRefresh: Date;
  measurementsByName: MeasurementsByName;
}

export interface AppUsage {
  app: PartialApp;
  measurement: Measurement;
}

export interface UsageSummary {
  appMeasurements: AppUsage[];
  totalUsage: number;
}

export type UsageByMeasurement = {
  [K in MeasurementName]: UsageSummary;
};

interface State {
  loading: boolean;
  error: string;
  appMetricsById: { [id: string]: UsageMetrics };
  groupMetricsById: { [id: string]: UsageMetrics };
}

export type MeasurementsState = Readonly<State>;
