import { TemplateIdentifier } from './types';

export const TemplateAppNames = {
  [TemplateIdentifier.Default]: 'Build your own app',
  [TemplateIdentifier.TriggersDatabase]: 'Manage Database Views',
  [TemplateIdentifier.IOSSwiftUITodo]: 'SwiftUI + Device Sync Starter',
  [TemplateIdentifier.IOSSwiftTodo]: 'Swift + Device Sync Starter',
  [TemplateIdentifier.AndroidKotlinTodo]: 'Kotlin + Device Sync Starter',
  [TemplateIdentifier.XamarinCSharpTodo]: 'Xamarin + Device Sync Starter',
  [TemplateIdentifier.ReactNativeTodo]: 'React Native + Device Sync Starter',
  [TemplateIdentifier.SyncTodo]: 'Real-time Sync',
  [TemplateIdentifier.WebGraphQLTodo]: 'GraphQL + React App Boilerplate',
  [TemplateIdentifier.WebMQLTodo]: 'React.JS + Realm Web Boilerplate',
};
