import { createAction } from 'redux-act';

import { TemplateIdentifier } from 'baas-ui/home/create-app/types';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import {
  FeatureFlagStatus,
  FeatureSettings,
  PartialApp,
  ProviderRegionData,
  Template,
  UpdateEnvironmentRequest,
} from 'admin-sdk';

const NAME = 'app/';

export const toggleIsFullscreen = createAction(`${NAME}toggle fullscreen`);

export const setResourceNotFoundError = createAction<string | undefined>(`${NAME}set resource not found error`);

export const setDeploymentMigrating = createAction<boolean>(`${NAME}set deployment migrating`);

interface GetTemplateAppClientPayload extends BaseRequestPayload {
  templateId: TemplateIdentifier;
}

/* App loading */

export const [loadAppActions, loadApp] = createActionsAndExecutor<BaseRequestPayload, PartialApp>(
  `${NAME}load app`,
  (client, { groupId, appId }) => client.apps(groupId).app(appId).get
);

export const [getAppEnabledFeatureFlagsActions, getAppEnabledFeatureFlags] = createActionsAndExecutor<
  BaseRequestPayload,
  FeatureSettings
>(`${NAME}get app feature flags`, (client, { groupId, appId }) => () => {
  return client.private().group(groupId).app(appId).features().get(FeatureFlagStatus.Enabled);
});

export const [loadAppsActions, loadApps] = createActionsAndExecutor<Pick<BaseRequestPayload, 'groupId'>, PartialApp[]>(
  `${NAME}load apps`,
  (client, { groupId }) => client.apps(groupId).list
);

export const [loadCompatibleTemplatesActions, loadCompatibleTemplates] = createActionsAndExecutor<
  BaseRequestPayload,
  Template[]
>(
  `${NAME}load compatible templates`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).templates().get()
);

export const [loadTemplatesActions, loadTemplates] = createActionsAndExecutor<{}, Template[]>(
  `${NAME}load templates`,
  (client) => () => client.templates().list()
);

export const [getTemplateAppClientActions, getTemplateAppClient] = createActionsAndExecutor<
  GetTemplateAppClientPayload,
  Response
>(
  `${NAME}get template app client`,
  (client, { groupId, appId, templateId }) =>
    () =>
      client.apps(groupId).app(appId).templates().template(templateId).client()
);

export const [setAppEnvironmentActions, setAppEnvironment] = createActionsAndExecutor(
  `${NAME}set environment`,
  (client, { groupId, appId, environment }) =>
    () =>
      client.apps(groupId).app(appId).environment().update(new UpdateEnvironmentRequest({ environment }))
);

export const [loadProviderRegionsActions, loadProviderRegions] = createActionsAndExecutor<void, ProviderRegionData[]>(
  `${NAME}load provider regions`,
  (client) => client.providerRegions().list
);

export const asyncEditRcvActions = [setAppEnvironmentActions.rcv];
