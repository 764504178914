import React from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm';
import styled from '@emotion/styled';
import ConfirmationModal, { Variant } from '@leafygreen-ui/confirmation-modal';
import { fontWeights } from '@leafygreen-ui/tokens';

import { ZIndex } from 'baas-ui/common/styles/zIndex';
import { TriggerTypes } from 'baas-ui/events/types';

const StyledConfirmationModal = styled(ConfirmationModal)`
  z-index: ${ZIndex.Modal};
`;

const BoldSpan = styled.span`
  font-weight: ${fontWeights.bold};
`;

interface Props extends ReactConfirmProps {
  triggerName: string;
  triggerType: TriggerTypes;
}

export const ExistingTrigger: React.FC<Props> = ({ cancel, proceed, show, triggerName, triggerType }) => {
  const typeDisplayName = triggerType
    .split(' ')
    .map((word) => `${word.charAt(0)}${word.substring(1).toLocaleLowerCase()}`)
    .join(' ');

  return (
    <StyledConfirmationModal
      open={show}
      title="Discard changes?"
      variant={Variant.Danger}
      cancelButtonProps={{
        onClick: cancel,
      }}
      confirmButtonProps={{
        children: 'Discard',
        onClick: proceed,
      }}
    >
      Are you sure you want to navigate away and discard your changes to {typeDisplayName}{' '}
      <BoldSpan>{triggerName}</BoldSpan>?
    </StyledConfirmationModal>
  );
};

export default createConfirmation(confirmable(ExistingTrigger));
