import { asyncEditRcvActions as appAsyncEditRcvActions } from 'baas-ui/app/actions';
import { asyncEditRcvActions as authAsyncEditRcvActions } from 'baas-ui/auth/actions';
import { asyncEditRcvActions as deployAsyncEditRcvActions, redeployActions } from 'baas-ui/deploy/actions';
import { asyncEditRcvActions as endpointsAsyncEditRcvActions } from 'baas-ui/endpoints/actions';
import { asyncEditRcvActions as dataAPIAsyncEditRcvActions } from 'baas-ui/endpoints/data-api/actions';
import { asyncEditRcvActions as eventsAsyncEditRcvActions } from 'baas-ui/events/actions';
import { asyncEditRcvActions as functionsAsyncEditRcvActions } from 'baas-ui/functions/actions';
import { asyncEditRcvActions as dependenciesAsyncEditRcvActions } from 'baas-ui/functions/dependencies/actions';
import { asyncEditRcvActions as graphqlAsyncEditRcvActions } from 'baas-ui/graphql/actions';
import { asyncEditRcvActions as hostingAsyncEditRcvActions } from 'baas-ui/hosting/actions';
import { asyncEditRcvActions as incomingWebhooksAsyncEditRcvActions } from 'baas-ui/incomingwebhooks/actions';
import { asyncEditRcvActions as logsAsyncEditRcvActions } from 'baas-ui/logs/actions';
import { asyncEditRcvActions as pushAsyncEditRcvActions } from 'baas-ui/push/actions';
import { asyncEditRcvActions as rulesAsyncEditRcvActions } from 'baas-ui/rules/actions';
import { asyncEditRcvActions as schemaAsyncEditRcvActions } from 'baas-ui/schema/actions';
import { asyncEditRcvActions as servicesAsyncEditRcvActions, saveSvcConfigActions } from 'baas-ui/services/actions';
import defaultServiceActions from 'baas-ui/services/default/actions';
import { asyncEditRcvActions as usersAsyncEditRcvActions } from 'baas-ui/users/actions';
import { asyncEditRcvActions as userSettingsAsyncEditRcvActions } from 'baas-ui/usersettings/actions';
import { asyncEditRcvActions as valuesAsyncEditRcvActions } from 'baas-ui/values/actions';

export const servicesDefaultAsyncEditRcvActions = [
  defaultServiceActions.addRuleActions.rcv,
  defaultServiceActions.updateRuleActions.rcv,
  defaultServiceActions.deleteRuleActions.rcv,
  defaultServiceActions.addRuleActions.rcv,
  defaultServiceActions.updateRuleActions.rcv,
  defaultServiceActions.deleteRuleActions.rcv,
];

export const getActionType = (action: any): string => {
  if (typeof action?.getType === 'function') {
    return action.getType();
  }

  if (typeof action?.type === 'string') {
    return action.type;
  }

  return '';
};

export const asyncEditRcvActions = [
  ...appAsyncEditRcvActions,
  ...authAsyncEditRcvActions,
  ...userSettingsAsyncEditRcvActions,
  ...dependenciesAsyncEditRcvActions,
  ...endpointsAsyncEditRcvActions,
  ...dataAPIAsyncEditRcvActions,
  ...eventsAsyncEditRcvActions,
  ...functionsAsyncEditRcvActions,
  ...graphqlAsyncEditRcvActions,
  ...hostingAsyncEditRcvActions,
  ...incomingWebhooksAsyncEditRcvActions,
  ...logsAsyncEditRcvActions,
  ...pushAsyncEditRcvActions,
  ...servicesAsyncEditRcvActions,
  ...servicesDefaultAsyncEditRcvActions,
  ...rulesAsyncEditRcvActions,
  ...usersAsyncEditRcvActions,
  ...valuesAsyncEditRcvActions,
  ...schemaAsyncEditRcvActions,
  ...deployAsyncEditRcvActions,
].reduce<{ [action: string]: boolean }>((reduced, action) => {
  const actionType = getActionType(action);
  if (actionType) {
    reduced[actionType] = true;
  }

  return reduced;
}, {});

// This array represents actions that should not trigger the UnderstandingSaveAndDeployModal.
// Reasons to add actions to this array may vary over time, but we started using this array because
// saveSvcConfigActions.rcv was causing a separate modal to open, and having both modals open at the
// same time was causing issues. See BAAS-9497 for more info.
// TODO(BAAS-9729) have this be imported from service or other actions files
export const ignoredSaveActions = [saveSvcConfigActions.rcv, redeployActions.rcv];

export const asyncEditRcvActionsList = [
  ...appAsyncEditRcvActions,
  ...authAsyncEditRcvActions,
  ...userSettingsAsyncEditRcvActions,
  ...dependenciesAsyncEditRcvActions,
  ...endpointsAsyncEditRcvActions,
  ...eventsAsyncEditRcvActions,
  ...functionsAsyncEditRcvActions,
  ...graphqlAsyncEditRcvActions,
  ...hostingAsyncEditRcvActions,
  ...incomingWebhooksAsyncEditRcvActions,
  ...pushAsyncEditRcvActions,
  ...servicesAsyncEditRcvActions,
  ...servicesDefaultAsyncEditRcvActions,
  ...usersAsyncEditRcvActions,
  ...valuesAsyncEditRcvActions,
  ...schemaAsyncEditRcvActions,
  ...rulesAsyncEditRcvActions,
];
