import React from 'react';
import { Body } from '@leafygreen-ui/typography';
import { ConfirmDeprecated as Confirm } from 'stitch-ui-toolbox';

export default function minimumProtocolIncreaseAlert(error: string) {
  const confirmBody = (
    <Body>
      <span className="tb-view-modal-body-confirm">
        The pending changes use newer data types which are not be supported on client apps built with older SDK
        versions. Client connections from SDKs that do not support these data types will be rejected upon connection.
        Are you sure you want to save changes?
      </span>
      <span className="tb-view-modal-body-confirm-bold">{error}</span>
    </Body>
  );
  return Confirm.confirm({
    confirmHeader: 'Increasing Minimum Required Protocol Version',
    confirmBody,
    confirmButtonText: 'Save Changes & Update Protocol Version',
    confirmButtonVariant: 'primaryOutline',
  });
}
