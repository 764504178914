import React from 'react';
import { Body } from '@leafygreen-ui/typography';
import { ConfirmDeprecated as Confirm } from 'stitch-ui-toolbox';

export default function invalidSchemaAlert(error: string) {
  const confirmBody = (
    <Body>
      <span className="tb-view-modal-body-confirm">
        The changes you are trying to save invalidates one or more schemas for synced collections and will require Sync
        to reinitialize.
      </span>
      <span className="tb-view-modal-body-confirm">
        If these changes are saved, the collections with invalid schema(s) will no longer sync. Sync will stop
        temporarily as it terminates (deleting the copy of synced data) and re-enables.
      </span>
      <span className="tb-view-modal-body-confirm"> Are you sure you want to save changes?</span>
      <span className="tb-view-modal-body-confirm-bold">{error}</span>
    </Body>
  );
  return Confirm.confirm({
    confirmHeader: 'Reinitialize Sync: Invalid Schemas',
    confirmBody,
    confirmButtonText: 'Save Changes & Reinitialize Sync',
    confirmButtonVariant: 'danger',
  });
}
