import { createAction } from 'redux-act';

import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { AuthProviderConfig, AuthProviderType, CreateTempAPIKeyRequest, ServerError } from 'admin-sdk';

const NAME = 'session/';

export interface LoginPayload {
  username: string;
  password: string;
}

export interface LoginCloudPayload {
  username: string;
  apiKey: string;
}

export const setLoginErrorAction = createAction<{ error?: string }>(`${NAME}set login error`);

export const setLoginError = (error: ServerError) => setLoginErrorAction({ error: error.message });

export const [loadRootAuthProvidersActions, loadRootAuthProviders] = createActionsAndExecutor<
  undefined,
  AuthProviderConfig[]
>(`${NAME}load root auth providers`, (client) => () => client.getAuthProviders());

export const [loginActions, login] = createActionsAndExecutor<LoginPayload, void>(
  `${NAME}login`,
  (client, { username, password }) =>
    () =>
      client.authenticate(AuthProviderType.Userpass, { username, password })
);

export const [loginCloudActions, loginCloud] = createActionsAndExecutor<LoginCloudPayload, void>(
  `${NAME}login cloud`,
  (client, { username, apiKey }) =>
    () =>
      client.authenticate(AuthProviderType.MongoDBCloud, { username, apiKey })
);

export const [generateTempApiKeyActions, generateTempApiKey] = createActionsAndExecutor(
  `${NAME}/generate temp api key`,
  (client) => () =>
    client
      .private()
      .auth()
      .tempAPIKeys()
      .create(new CreateTempAPIKeyRequest({ desc: 'local-test' }))
);
