import { createReducer } from 'redux-act';

import * as actions from './actions';
import { RealmLanguage, RealmSDK } from './types';

export interface SdkState {
  selectedLanguage: RealmLanguage;
  selectedSDK: RealmSDK;
}

export const defaultState: SdkState = {
  selectedLanguage: RealmLanguage.JS,
  selectedSDK: RealmSDK.Node,
};

const reducer = createReducer<SdkState>({}, defaultState);

reducer.on(actions.setSelectedLanguage, (state, language) => {
  return {
    ...state,
    selectedLanguage: language,
  };
});

reducer.on(actions.setSelectedSDK, (state, sdk) => {
  return {
    ...state,
    selectedSDK: sdk,
  };
});

export default reducer;
