import { createReducer } from 'redux-act';

import * as mongoActions from 'baas-ui/services/mongodb/actions';
import { CustomUserDataConfig } from 'admin-sdk';

import * as actions from './actions';
import { UserSettingsState } from './types';

export const defaultState = {
  loadingCustomUserData: false,
  savingCustomUserDataConfig: false,
  customUserDataLoadError: undefined,
  customUserDataSaveError: undefined,
  namespaceLoadError: undefined,
  customUserData: new CustomUserDataConfig(),
  refreshTokenExpiration: 5184000, // 60 days
  loadingRefreshTokenExpiration: false,
  savingRefreshTokenExpiration: false,
  refreshTokenExpirationSaveError: undefined,
  refreshTokenExpirationLoadError: undefined,
};

const userSettingsReducer = createReducer<UserSettingsState>({}, defaultState);

userSettingsReducer.on(actions.loadCustomUserDataActions.req, (state) => ({
  ...state,
  loadingCustomUserData: true,
  customUserDataLoadError: undefined,
  customUserDataSaveError: undefined,
  namespaceLoadError: undefined,
}));

userSettingsReducer.on(actions.loadCustomUserDataActions.rcv, (state, { payload }) => ({
  ...state,
  loadingCustomUserData: false,
  customUserDataLoadError: undefined,
  customUserData: payload,
}));

userSettingsReducer.on(actions.loadCustomUserDataActions.fail, (state, { error }) => ({
  ...state,
  loadingCustomUserData: false,
  customUserDataLoadError: error,
}));

userSettingsReducer.on(actions.updateCustomUserDataActions.req, (state) => ({
  ...state,
  savingCustomUserDataConfig: true,
  customUserDataSaveError: undefined,
}));

userSettingsReducer.on(actions.updateCustomUserDataActions.rcv, (state, { reqArgs: { data } }) => {
  const updatedData: CustomUserDataConfig = {
    enabled: data.enabled !== undefined ? data.enabled : state.customUserData.enabled,
    mongoServiceId: data.mongoServiceId ? data.mongoServiceId : state.customUserData.mongoServiceId,
    databaseName: data.databaseName ? data.databaseName : state.customUserData.databaseName,
    collectionName: data.collectionName ? data.collectionName : state.customUserData.collectionName,
    userIdField: data.userIdField ? data.userIdField : state.customUserData.userIdField,
    onUserCreationFunctionId: data.onUserCreationFunctionId,
  };

  return {
    ...state,
    savingCustomUserDataConfig: false,
    customUserDataSaveError: undefined,
    customUserData: updatedData,
  };
});

userSettingsReducer.on(actions.updateCustomUserDataActions.fail, (state, { error }) => ({
  ...state,
  savingCustomUserDataConfig: false,
  customUserDataSaveError: error,
}));

userSettingsReducer.on(mongoActions.listSvcNamespacesActions.req, (state) => ({
  ...state,
  namespaceLoadError: undefined,
}));

userSettingsReducer.on(mongoActions.listSvcNamespacesActions.fail, (state, { error }) => ({
  ...state,
  namespaceLoadError: error,
}));

userSettingsReducer.on(actions.loadRefreshTokenExpirationActions.req, (state) => ({
  ...state,
  loadingRefreshTokenExpiration: true,
  refreshTokenExpirationLoadError: undefined,
  refreshTokenExpirationSaveError: undefined,
}));

userSettingsReducer.on(actions.loadRefreshTokenExpirationActions.rcv, (state, { payload }) => ({
  ...state,
  loadingRefreshTokenExpiration: false,
  refreshTokenExpirationLoadError: undefined,
  refreshTokenExpiration: payload.expirationTimeSeconds,
}));

userSettingsReducer.on(actions.loadRefreshTokenExpirationActions.fail, (state, { error }) => ({
  ...state,
  loadingRefreshTokenExpiration: false,
  refreshTokenExpirationLoadError: error,
}));

userSettingsReducer.on(actions.updateRefreshTokenExpirationActions.req, (state) => ({
  ...state,
  savingRefreshTokenExpiration: true,
  refreshTokenExpirationSaveError: undefined,
}));

userSettingsReducer.on(actions.updateRefreshTokenExpirationActions.rcv, (state, { reqArgs: { expiration } }) => ({
  ...state,
  savingRefreshTokenExpiration: false,
  refreshTokenExpirationSaveError: undefined,
  refreshTokenExpiration: expiration.expirationTimeSeconds,
}));

userSettingsReducer.on(actions.updateRefreshTokenExpirationActions.fail, (state, { error }) => ({
  ...state,
  savingRefreshTokenExpiration: false,
  refreshTokenExpirationSaveError: error,
}));

userSettingsReducer.on(actions.clearRefreshTokenExpirationSaveError, (state) => ({
  ...state,
  refreshTokenExpirationSaveError: undefined,
}));

export default userSettingsReducer;
