import React from 'react';
import styled from '@emotion/styled';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Code from '@leafygreen-ui/code';
import { Body, Description, Label } from '@leafygreen-ui/typography';

import CollapsibleExample from 'baas-ui/common/components/collapsible-example';
import { CodeEditor } from 'baas-ui/functions/code-editor';
import { SupportedLanguages } from 'baas-ui/functions/code-editor/CodeEditor';

import './expression-editor-form-row.less';

const baseClassName = 'expression-editor-form-row';

const RuleExpressionEditor = styled(CodeEditor)`
  height: 200px;
`;

export enum TestSelector {
  CollapsibleExampleC = 'collapsible-example',
  DescriptionText = 'description-text',
  ExampleCode = 'example-code',
  ExampleDescriptionText = 'example-text',
  ExpressionEditor = 'expression-editor',
  LabelC = 'label',
  ParsingErrorBanner = 'parsing-error-banner',
}

export interface Props {
  'data-cy': string;
  className?: string;
  description: React.ReactElement;
  exampleCode: string;
  exampleText: React.ReactElement;
  expressionStr: string;
  labelText: string;
  onChangeExpression(expression: string): void;
  parsingError?: string;
}

export const ExpressionEditorFormRow = ({
  'data-cy': cyTarget,
  className,
  expressionStr,
  labelText,
  description,
  exampleText,
  exampleCode,
  onChangeExpression,
  parsingError,
}: Props) => {
  return (
    <div className={className}>
      <Label htmlFor="expression-editor" data-test-selector={TestSelector.LabelC} data-testid={TestSelector.LabelC}>
        {labelText}
      </Label>
      <Description
        data-test-selector={TestSelector.DescriptionText}
        data-testid={TestSelector.DescriptionText}
        className={`${baseClassName}-description`}
      >
        {description}
      </Description>
      <CollapsibleExample
        className={`${baseClassName}-view-example`}
        data-test-selector={TestSelector.CollapsibleExampleC}
        data-testid={TestSelector.CollapsibleExampleC}
      >
        <div className={`${baseClassName}-view-example-content-wrapper`}>
          <Body
            className={`${baseClassName}-view-example-description`}
            data-test-selector={TestSelector.ExampleDescriptionText}
            data-testid={TestSelector.ExampleDescriptionText}
          >
            {exampleText}
          </Body>
          <Code language="json" data-test-selector={TestSelector.ExampleCode}>
            {exampleCode}
          </Code>
        </div>
      </CollapsibleExample>
      <RuleExpressionEditor
        data-cy={cyTarget}
        data-test-selector={TestSelector.ExpressionEditor}
        data-testid={TestSelector.ExpressionEditor}
        functionInput={expressionStr}
        language={SupportedLanguages.JSON}
        onChange={(expression: string) => onChangeExpression(expression)}
      />
      {parsingError && (
        <Banner
          variant={Variant.Danger}
          className={`${baseClassName}-parsing-error-banner`}
          data-testid={TestSelector.ParsingErrorBanner}
        >
          {parsingError}
        </Banner>
      )}
    </div>
  );
};

export default ExpressionEditorFormRow;
