import React from 'react';
import Code from '@leafygreen-ui/code';
import { Link } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import LeafygreenRouterLink from 'baas-ui/common/components/leafygreen-router-link';
import { docLinks } from 'baas-ui/common/links';
import GuideContentBody from 'baas-ui/guides/content/body';
import GuideFeedbackBlurb from 'baas-ui/guides/content/guide-feedback-blurb';
import GuideStepHeader from 'baas-ui/guides/step-header';
import { GuideContent } from 'baas-ui/guides/types';
import { rootUrl } from 'baas-ui/urls';

import 'baas-ui/guides/content/guide-content.less';

export enum TestSelector {
  AtlasClustersLink = 'atlas-clusters',
  ChangeEventCodeSnippet = 'change-event-code-snippet',
  DeployDocs = 'deploy-docs',
  FunctionsDocs = 'functions-docs',
  GuidesLink = 'guides-link',
  LoadSampleDataDocs = 'load-sample-data',
  TriggersDocs = 'triggers-docs',
  TriggerFunctionCodeSnippet = 'trigger-function-code-snippet',
}

const triggersGuideDataTarget = 'triggers-guide';

const buildTriggersGuide = ({ groupId, appId, cloudUIBaseUrl, clusterId }): GuideContent => {
  const appUrl = rootUrl.groups().group(groupId).apps().app(appId);
  const guidesUrl = appUrl.guides();
  const logsUrl = appUrl.logs().list();
  const newCollectionUrl = appUrl.clusters().cluster(clusterId).rules().new();
  const triggersUrl = appUrl.triggers().list();

  const atlasClustersUrl = `${cloudUIBaseUrl}/v2/${groupId}#clusters`;

  return {
    header: 'Triggers',
    steps: [
      {
        body: (
          <div data-cy={`${triggersGuideDataTarget}-introduction`}>
            <GuideContentBody>
              This walkthrough takes you through the steps of configuring your own Database Trigger:
            </GuideContentBody>
            <GuideStepHeader index={1} text="Triggers Introduction" />
            <GuideStepHeader index={2} text="Configure a Collection" />
            <GuideStepHeader index={3} text="Configure Your Trigger" />
            <GuideStepHeader index={4} text="Configure Trigger Change Events" />
            <GuideStepHeader index={5} text="Write Trigger Logic" />
            <GuideStepHeader index={6} text="Test Your Trigger" />
            <GuideStepHeader index={7} text="Success" />
          </div>
        ),
        showProgress: false,
        nextStepTitle: 'Start',
        nextIsButton: true,
      },
      {
        url: triggersUrl,
        docsLink: docLinks.Triggers.LearnMore,
        body: (
          <div data-cy={`${triggersGuideDataTarget}-step-1`}>
            <GuideStepHeader isTitle index={1} text="Triggers Intro" url={triggersUrl} />
            <GuideContentBody>
              <DocLink data-test-selector={TestSelector.TriggersDocs} href={docLinks.Triggers.LearnMore}>
                App Services Triggers
              </DocLink>
              &nbsp;enable you to execute application and database logic automatically in response to events.
            </GuideContentBody>
            <GuideContentBody>
              Each Trigger is linked to a specific&nbsp;
              <DocLink data-test-selector={TestSelector.FunctionsDocs} href={docLinks.Functions.LearnMore}>
                App Services Function
              </DocLink>
              &nbsp;- whenever a Trigger observes an event, it “fires” and calls the Function with an object that
              describes the event as the Function argument.
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Configure a Collection',
        nextIsButton: false,
      },
      {
        url: newCollectionUrl,
        docsLink: docLinks.MongoDB.RolesPermissions,
        body: (
          <div data-cy={`${triggersGuideDataTarget}-step-2`}>
            <GuideStepHeader isTitle index={2} text="Configure a Collection" url={newCollectionUrl} />
            <GuideContentBody>
              Take a look at your collections and make sure there is one you’d like to add a Trigger for. We’ll set up a
              Trigger for this collection in the next step.
            </GuideContentBody>
            <GuideContentBody>
              (Optional) If you don’t have existing data to work with, you can load sample data into your cluster
              through Atlas.&nbsp;
              <DocLink data-test-selector={TestSelector.LoadSampleDataDocs} href={docLinks.Atlas.LoadSampleData}>
                Load Sample Data.
              </DocLink>
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Create a Trigger',
        nextIsButton: false,
      },
      {
        url: triggersUrl,
        body: (
          <div data-cy={`${triggersGuideDataTarget}-step-3`}>
            <GuideStepHeader isTitle index={3} text="Trigger Configuration" url={triggersUrl} />
            <GuideContentBody>
              Click “<b>Add a Trigger.</b>” For the sake of this example, you can choose Database for&nbsp;
              <b>Trigger Type.</b> A Database Trigger fires in response to events in a specific MongoDB collection.
              Specify the database and collection that this Trigger should observe.
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Database Change Events',
        nextIsButton: false,
      },
      {
        docsLink: docLinks.Triggers.DatabaseEvents,
        body: (
          <div data-cy={`${triggersGuideDataTarget}-step-4`}>
            <GuideStepHeader isTitle index={4} text="Database Change Events" />
            <GuideContentBody>
              Database change events represent individual changes to the chosen collection, such as when a document is
              added, modified, or deleted.
            </GuideContentBody>
            <GuideContentBody>
              For your sample Trigger, you can check <b>Insert.</b> This way only insert events will fire the Trigger
              when any changes are made to your collection.
            </GuideContentBody>
            <GuideContentBody>Your Trigger change event object will have the following structure:</GuideContentBody>
            <GuideContentBody>
              <Code language="none" data-test-selector={TestSelector.ChangeEventCodeSnippet}>
                {`{
  _id : <ObjectId>,
  operationType: <string>,
  fullDocument: <document>,
  ns: {
    db : <string>,
    coll : <string>
  },
  documentKey: {
    _id: <ObjectId>
  },
  updateDescription: <document>,
  clusterTime: <Timestamp>
}`}
              </Code>
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Write Your Trigger Logic',
        nextIsButton: false,
      },
      {
        body: (
          <div data-cy={`${triggersGuideDataTarget}-step-5`}>
            <GuideStepHeader isTitle index={5} text="Write Your Trigger Logic" />
            <GuideContentBody>
              Choose <i>Function</i> as your <b>Event Type.</b> Then click &apos;<b>+ New Function</b>&apos; from the
              dropdown.
            </GuideContentBody>
            <GuideContentBody>
              This will open an editor where you can write JavaScript code that will execute once your Trigger fires.
            </GuideContentBody>
            <GuideContentBody>
              The following Trigger Function adds a timestamp to your inserted document:
              <Code language="javascript" data-test-selector={TestSelector.TriggerFunctionCodeSnippet}>
                {`exports = async function (changeEvent) {
  // Get a Collection object for the changed collection
  const cluster = context.service.get("mongodb-atlas");  // Linked Cluster
  const db = cluster.db(changeEvent.ns.db);              // Changed Database
  const collection = db.collection(changeEvent.ns.coll); // Changed Collection

  try {
    await collection.updateOne(
      { _id: changeEvent._id },            // Update the document that was inserted
      { $set: { createdAt: new Date() } }, // Set the createdAt field to the current time
      { upsert: false }                    // The document was just inserted, so we don't need to upsert
    );
    console.log("Successfully set the createdAt field");
  } catch (err) {
    console.error("Failed to set the createdAt field", err);
  }
};
`}
              </Code>
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Save and Deploy Your Trigger',
        nextIsButton: false,
      },
      {
        body: (
          <div data-cy={`${triggersGuideDataTarget}-step-6`}>
            <GuideStepHeader isTitle index={6} text="Save and Deploy Your Trigger" />
            <GuideContentBody>
              You can leave the remaining defaults as is. To learn more about other Trigger configuration options, you
              can view the docs&nbsp;
              <DocLink data-test-selector={TestSelector.TriggersDocs} href={docLinks.Triggers.LearnMore}>
                here.
              </DocLink>
            </GuideContentBody>
            <GuideContentBody>
              Remember to&nbsp;
              <b>
                Save and&nbsp;
                <DocLink data-test-selector={TestSelector.DeployDocs} href={docLinks.Deploy.DeployUI}>
                  Deploy
                </DocLink>
              </b>
              &nbsp;your changes.
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Test Your Trigger',
        nextIsButton: false,
      },
      {
        url: logsUrl,
        body: (
          <div data-cy={`${triggersGuideDataTarget}-step-7`}>
            <GuideStepHeader isTitle index={7} text="Test Your Trigger" url={logsUrl} />
            <GuideContentBody>
              To test your Trigger, you can insert a document into your Trigger’s linked collection with Atlas’s&nbsp;
              <Link data-test-selector={TestSelector.AtlasClustersLink} href={atlasClustersUrl}>
                Data Explorer.
              </Link>
              &nbsp;Notice that the Trigger has fired in your logs.
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Success',
        nextIsButton: false,
      },
      {
        body: (
          <div data-cy={`${triggersGuideDataTarget}-conclusion`}>
            <GuideStepHeader isTitle text="Success" />
            <GuideContentBody>
              Congratulations - you’ve built and tested your first Trigger. To learn more, you can visit our&nbsp;
              <DocLink data-test-selector={TestSelector.TriggersDocs} href={docLinks.Triggers.LearnMore}>
                documentation.
              </DocLink>
            </GuideContentBody>
            <GuideContentBody>
              Be sure to check out{' '}
              <LeafygreenRouterLink data-test-selector={TestSelector.GuidesLink} to={guidesUrl}>
                other guides
              </LeafygreenRouterLink>{' '}
              to learn more about other services.
            </GuideContentBody>
            <GuideFeedbackBlurb />
          </div>
        ),
        showProgress: false,
        nextStepTitle: 'Finish',
        nextIsButton: true,
      },
    ],
  };
};

export default buildTriggersGuide;
