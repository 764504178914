import { NO_VALUE } from 'baas-ui/metrics/utils';
import { Measurement as RawMeasurement, MeasurementName, MeasurementUnit } from 'admin-sdk';

import { Measurement, MeasurementsByName } from './types';

export const legacyMeasurementTitle = (name: MeasurementName): string => {
  switch (name) {
    case MeasurementName.RequestCount:
      return 'Requests';
    case MeasurementName.DataOut:
      return 'Data Transfer';
    case MeasurementName.ComputeTime:
      return 'Compute Runtime';
    case MeasurementName.SyncTime:
      return 'Sync Runtime';
    default:
      return '';
  }
};

const ONE_THOUSAND = 1000;

export const legacyScaledMeasurementUsage = (rawUsage: number, scaled = false): string => {
  let usage = NO_VALUE;
  if (rawUsage < 0) {
    return usage;
  }

  const precision = scaled ? 2 : 0;
  const min = (1 / 10) ** precision;

  if (rawUsage >= ONE_THOUSAND ** 3) {
    usage = `${(rawUsage / ONE_THOUSAND ** 3).toFixed(precision)}B`;
  } else if (rawUsage >= ONE_THOUSAND ** 2) {
    usage = `${(rawUsage / ONE_THOUSAND ** 2).toFixed(precision)}M`;
  } else if (rawUsage >= ONE_THOUSAND) {
    usage = `${(rawUsage / ONE_THOUSAND).toFixed(precision)}K`;
  } else if (rawUsage < min && rawUsage > 0) {
    usage = `< ${min.toFixed(precision)}`;
  } else {
    usage = rawUsage.toFixed(precision);
  }
  return usage;
};

export const legacyMeasurementUsage = (rawUsage: number, units: MeasurementUnit): string => {
  return legacyScaledMeasurementUsage(rawUsage, rawUsage > ONE_THOUSAND || units !== MeasurementUnit.None);
};

export const legacyFormatMeasurementUnits = (
  units: MeasurementUnit,
  isPricingChangeEnabledAndSyncMetric: boolean
): string => {
  switch (units) {
    case MeasurementUnit.None:
      return '';
    case MeasurementUnit.Gigabytes:
      return 'GB';
    case MeasurementUnit.Hours:
      return isPricingChangeEnabledAndSyncMetric ? 'min' : 'Hr';
    default:
      return '';
  }
};

export const legacyFormatMeasurementUsage = (
  measurement: Measurement,
  isPricingChangeEnabledAndSyncMetric: boolean
) => {
  const value = legacyMeasurementUsage(
    isPricingChangeEnabledAndSyncMetric ? measurement.usage * 60 : measurement.usage,
    measurement.units
  );
  const units = legacyFormatMeasurementUnits(measurement.units, isPricingChangeEnabledAndSyncMetric);

  if (measurement.name === MeasurementName.RequestCount || value === NO_VALUE) {
    return value;
  }
  return `${value} ${units}`;
};

export const legacyFormatMeasurementThreshold = (measurement: Measurement, isPricingChangeEnabled: boolean) => {
  let thresholdValue = measurement.threshold;

  if (isPricingChangeEnabled) {
    switch (measurement.name) {
      case MeasurementName.ComputeTime:
        thresholdValue = 25;
        break;
      case MeasurementName.DataOut:
        thresholdValue = 0.5;
        break;
      case MeasurementName.RequestCount:
        thresholdValue = 50000;
        break;
      case MeasurementName.SyncTime:
        thresholdValue = 30000;
        break;
      default:
        thresholdValue = measurement.threshold;
        break;
    }
  }

  const value = legacyScaledMeasurementUsage(
    thresholdValue,
    isPricingChangeEnabled && measurement.name === MeasurementName.DataOut // requires decimal precision
  );
  const units = legacyFormatMeasurementUnits(
    measurement.units,
    isPricingChangeEnabled && measurement.name === MeasurementName.SyncTime
  );

  if (measurement.name === MeasurementName.RequestCount) {
    return value;
  }

  return `${value} ${units}`;
};

export const legacyMeasurementFromRaw = (measurement?: RawMeasurement): Measurement => {
  const emptyMeasurement = { name: MeasurementName.RequestCount, units: MeasurementUnit.None, usage: 0, threshold: 1 };
  if (!measurement) {
    return emptyMeasurement;
  }

  const usage = (measurement?.dataPoints || []).reduce((tot, dp) => tot + dp.value, 0);
  switch (measurement.name) {
    case MeasurementName.ComputeTime:
      return { name: MeasurementName.ComputeTime, units: MeasurementUnit.Hours, usage, threshold: 500 };
    case MeasurementName.DataOut:
      return { name: MeasurementName.DataOut, units: MeasurementUnit.Gigabytes, usage, threshold: 10 };
    case MeasurementName.RequestCount:
      return { name: MeasurementName.RequestCount, units: MeasurementUnit.None, usage, threshold: ONE_THOUSAND ** 2 };
    case MeasurementName.SyncTime:
      return { name: MeasurementName.SyncTime, units: MeasurementUnit.Hours, usage, threshold: ONE_THOUSAND * 10 };
    default:
      return emptyMeasurement;
  }
};

export const legacyMapMeasurementsByName = (measurements: RawMeasurement[]): MeasurementsByName =>
  Object.values(MeasurementName).reduce(
    (acc, measurementName) => ({
      ...acc,
      [measurementName]: legacyMeasurementFromRaw(
        measurements.find((measurement) => measurement.name === measurementName)
      ),
    }),
    {} as MeasurementsByName
  );
