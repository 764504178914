import { createReducer } from 'redux-act';

import { AsyncDataState, makeDefaultAsyncDataState } from 'baas-ui/redux_util';
import * as settingsActions from 'baas-ui/settings/actions';
import { PartialSchema, Schema } from 'admin-sdk';

import * as actions from './actions';
import { isCollectionMetadata } from './dataSourceData';

interface NullTypeSchemaValidationState {
  setting: boolean;
  loadingError: string;
  savingError: string;
}

export interface SchemasState {
  schemas: AsyncDataState<PartialSchema[]>;
  schema: AsyncDataState<Schema>;
  nullTypeSchemaValidation: NullTypeSchemaValidationState;
  shouldRefreshGQLModelData: boolean;
  shouldRefreshSyncModelData: boolean;
}

export const defaultState: SchemasState = {
  schemas: makeDefaultAsyncDataState(),
  schema: makeDefaultAsyncDataState(),
  nullTypeSchemaValidation: {
    setting: false,
    loadingError: '',
    savingError: '',
  },
  shouldRefreshGQLModelData: false,
  shouldRefreshSyncModelData: false,
};

export const reducer = createReducer<SchemasState>({}, defaultState);

reducer.on(actions.loadSchemasActions.req, (state) => ({
  ...state,
  schemas: {
    ...state.schemas,
    isLoading: true,
    error: undefined,
  },
}));
reducer.on(actions.loadSchemasActions.rcv, (state, { payload }) => ({
  ...state,
  schemas: {
    ...state.schemas,
    isLoading: false,
    data: payload,
  },
}));
reducer.on(actions.loadSchemasActions.fail, (state, { error }) => ({
  ...state,
  schemas: {
    ...state.schemas,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.clearLoadedSchema, (state) => ({
  ...state,
  schema: makeDefaultAsyncDataState(),
}));

reducer.on(actions.clearLoadedSchemaError, (state) => ({
  ...state,
  schema: { ...state.schema, error: undefined },
}));

reducer.on(actions.setGQLModelDataRefetched, (state) => ({
  ...state,
  shouldRefreshGQLModelData: false,
  shouldRefreshSyncModelData: false,
}));

reducer.on(actions.setSyncModelDataRefetched, (state) => ({
  ...state,
  shouldRefreshGQLModelData: false,
  shouldRefreshSyncModelData: false,
}));

reducer.on(actions.loadSchemaActions.req, (state) => ({
  ...state,
  schema: {
    ...state.schema,
    isLoading: true,
    error: undefined,
  },
}));
reducer.on(actions.loadSchemaActions.rcv, (state, { payload }) => ({
  ...state,
  schema: {
    ...state.schema,
    isLoading: false,
    data: payload,
  },
}));
reducer.on(actions.loadSchemaActions.fail, (state, { error }) => ({
  ...state,
  schema: {
    ...state.schema,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.createSchemaActions.req, (state) => ({
  ...state,
  schema: {
    ...state.schema,
    isSaving: true,
    error: undefined,
  },
}));
reducer.on(actions.createSchemaActions.rcv, (state, { payload }) => ({
  ...state,
  schema: {
    ...state.schema,
    isSaving: false,
  },
  schemas: {
    ...state.schemas,
    data: (state.schemas.data || []).concat(new PartialSchema(payload)),
  },
  shouldRefreshGQLModelData: true,
  shouldRefreshSyncModelData: true,
}));
reducer.on(actions.createSchemaActions.fail, (state, { error }) => ({
  ...state,
  schema: {
    ...state.schema,
    isSaving: false,
    error,
  },
}));

reducer.on(actions.bulkGenerateSchemaActions.rcv, (state) => ({
  ...state,
  shouldRefreshGQLModelData: true,
  shouldRefreshSyncModelData: true,
}));

reducer.on(actions.updateSchemaActions.req, (state) => ({
  ...state,
  schema: {
    ...state.schema,
    isSaving: true,
    error: undefined,
  },
}));
reducer.on(actions.updateSchemaActions.rcv, (state, { reqArgs }) => ({
  ...state,
  schema: {
    ...state.schema,
    isSaving: false,
    error: undefined,
    data: new Schema(reqArgs.schema),
  },
  shouldRefreshGQLModelData: true,
  shouldRefreshSyncModelData: true,
}));
reducer.on(actions.updateSchemaActions.fail, (state, { error }) => ({
  ...state,
  schema: {
    ...state.schema,
    isSaving: false,
    error,
  },
}));

reducer.on(actions.deleteSchemaActions.req, (state) => ({
  ...state,
  schemas: {
    ...state.schemas,
    isDeleting: true,
  },
}));
reducer.on(actions.deleteSchemaActions.rcv, (state, { reqArgs }) => ({
  ...state,
  schemas: {
    ...state.schemas,
    isDeleting: false,
    data: (state.schemas.data || []).filter(({ id }) => id !== reqArgs.schemaId),
  },
  shouldRefreshGQLModelData: true,
  shouldRefreshSyncModelData: true,
}));
reducer.on(actions.deleteSchemaActions.fail, (state, { error }) => ({
  ...state,
  schemas: {
    ...state.schemas,
    isDeleting: false,
    error,
  },
}));

reducer.on(actions.deleteSchemasActions.req, (state) => ({
  ...state,
  schemas: { ...state.schemas, isDeleting: true },
}));
reducer.on(actions.deleteSchemasActions.rcv, (state, { reqArgs }) => ({
  ...state,
  schemas: {
    ...state.schemas,
    isDeleting: false,
    data: (state.schemas.data || []).filter(({ metadata }) => {
      if (!isCollectionMetadata(metadata)) {
        return true;
      }
      if (reqArgs.database) {
        return metadata.data_source !== reqArgs.dataSource || metadata.database !== reqArgs.database;
      }
      return metadata.data_source !== reqArgs.dataSource;
    }),
  },
  shouldRefreshGQLModelData: true,
  shouldRefreshSyncModelData: true,
}));
reducer.on(actions.deleteSchemasActions.fail, (state, { error }) => ({
  ...state,
  schemas: {
    ...state.schemas,
    isDeleting: false,
    error,
  },
}));

// Load Null Type Schema Validation Setting
reducer.on(settingsActions.loadNullTypeSchemaValidationSettingActions.req, (state) => state);
reducer.on(settingsActions.loadNullTypeSchemaValidationSettingActions.rcv, (state, { payload }) => ({
  ...state,
  nullTypeSchemaValidation: {
    setting: payload.enabled,
    loadingError: '',
    savingError: '',
  },
}));
reducer.on(settingsActions.loadNullTypeSchemaValidationSettingActions.fail, (state, { error }) => ({
  ...state,
  nullTypeSchemaValidation: {
    ...state.nullTypeSchemaValidation,
    loadingError: error,
  },
}));

// Update Null Type Schema Validation Setting
reducer.on(settingsActions.updateNullTypeSchemaValidationSettingActions.req, (state) => state);
reducer.on(settingsActions.updateNullTypeSchemaValidationSettingActions.rcv, (state, { reqArgs }) => ({
  ...state,
  nullTypeSchemaValidation: {
    setting: reqArgs.setting.enabled,
    loadingError: '',
    savingError: '',
  },
}));
reducer.on(settingsActions.updateNullTypeSchemaValidationSettingActions.fail, (state, { error }) => ({
  ...state,
  nullTypeSchemaValidation: {
    ...state.nullTypeSchemaValidation,
    isLoading: false,
    savingError: error,
  },
}));

export default reducer;
