import { ErrorCode } from 'baas-ui/constants';
import destructiveChangeAlert from 'baas-ui/sync/submit-confirmable-request/destructiveChangeAlert';
import { IncompatibleRolesModalPayload } from 'baas-ui/sync/types/ruleTypes';
import {
  deserializeIncompatibleRolesJSON,
  getNumIncompatibleCollections,
  parseJSONToSyncSchemaChange,
} from 'baas-ui/sync/util';
import { track } from 'baas-ui/tracking';
import { GetSchemaVersionsResponse } from 'admin-sdk';

import invalidSchemaAlert from './invalidSchemaAlert';
import protocolIncreaseAlert from './protocolIncreaseAlert';
import updateSchemaConfirm from './updateSchemaConfirm';
import updateSyncIncompatibilityConfirm from './updateSyncIncompatibilityConfirm';

export type SubmitConfirmableRequestCallbacks = {
  onOpenConfirmableRequestModal?: () => void;
  getSchemaVersions: () => Promise<GetSchemaVersionsResponse | void>;
};

// submitConfirmableRequest will:
// 1. Submit the given request without the bypass flag
// 2. Display a confirmation dialog or modal on specific service change failure types
// 3. Issue the request again with the corresponding bypass flag if the dialog/modal is confirmed
export default function submitConfirmableRequest<T>(
  trackerLocation: string,
  onSubmit: (bypassWarning?: string) => Promise<T>,
  callbacks: SubmitConfirmableRequestCallbacks
): Promise<T> {
  return onSubmit().catch((e) => {
    if (e.code === ErrorCode.SyncIncompatibleRoleError) {
      if (
        !e.json.errorDetails ||
        !e.json.errorDetails.sync_incompatible_roles ||
        !e.json.errorDetails.sync_incompatible_roles.all_incompatibilities
      ) {
        return Promise.reject(e);
      }

      let incompatibleRolesJSON = e.json.errorDetails.sync_incompatible_roles.all_incompatibilities;

      // TODO(BAAS-23633): The type of incompatibleRolesJSON is "unknown", as the type of
      // "errorDetails" is Record<string, unknown>. There seems to be strange behavior
      // with deserializing this type using the json2convert library, so the below works
      // as a temporary workaround
      incompatibleRolesJSON = JSON.parse(JSON.stringify(incompatibleRolesJSON));

      // flexible_sync_enabled is a required key for deserializing the raw JSON into the
      // MongoDBSyncIncompatibleRoles type, and thus is arbitrarily set to false here
      incompatibleRolesJSON.flexible_sync_enabled = false;

      const incompatibleRolesByDatabase = deserializeIncompatibleRolesJSON(incompatibleRolesJSON);
      const numIncompatibleCollections = getNumIncompatibleCollections(incompatibleRolesByDatabase);

      // Bypass the error if no collections have incompatible roles being applied to them
      if (numIncompatibleCollections === 0) {
        return onSubmit(e.code);
      }

      const incompatibleRoles: IncompatibleRolesModalPayload = {
        incompatibleRolesByDatabase,
        numIncompatibleCollections,
      };

      callbacks.onOpenConfirmableRequestModal?.();
      return updateSyncIncompatibilityConfirm({ incompatibleRoles }).then(
        () => {
          return onSubmit(e.code);
        },
        () => Promise.reject(e)
      );
    }
    if (e.code === ErrorCode.SyncProtocolIncreaseError) {
      callbacks.onOpenConfirmableRequestModal?.();
      return protocolIncreaseAlert(e.message).then(
        () => onSubmit(e.code),
        () => Promise.reject(e)
      );
    }
    if (e.code === ErrorCode.InvalidSyncSchemaError) {
      callbacks.onOpenConfirmableRequestModal?.();
      return invalidSchemaAlert(e.message).then(
        () => {
          track('SYNC_CONFIGURE.SYNC_REINITIALIZED', { location: trackerLocation });
          return onSubmit(e.code);
        },
        () => Promise.reject(e)
      );
    }

    if (
      e.code === ErrorCode.DestructiveChangeError &&
      e.message &&
      // Schema related incompatibilities are handled by the next if block
      !(e.json && e.json.errorDetails && e.json.errorDetails.schema_changes)
    ) {
      callbacks.onOpenConfirmableRequestModal?.();
      return destructiveChangeAlert(e.message).then(
        () => {
          track('SYNC_CONFIGURE.SYNC_REINITIALIZED', { location: trackerLocation });
          return onSubmit(e.code);
        },
        () => Promise.reject(e)
      );
    }

    // The following (destructive, destructive protocol increase, schema versioning) use the SchemaChangeModal
    if (
      e.code === ErrorCode.DestructiveChangeError ||
      e.code === ErrorCode.DestructiveSyncProtocolIncreaseError ||
      e.code === ErrorCode.SyncSchemaVersionIncreaseError ||
      e.code === ErrorCode.SyncAdditiveSchemaChange
    ) {
      if (!e.json.errorDetails || !e.json.errorDetails.schema_changes || !e.json.errorDetails.schema_changes.updates) {
        return Promise.reject(e);
      }

      const schemaChangeErrResponse = parseJSONToSyncSchemaChange(e.json.errorDetails.schema_changes);
      if (!schemaChangeErrResponse) {
        return Promise.reject(e);
      }

      const latestSchemaVersionFunc = function () {
        return callbacks.getSchemaVersions().then((schemaVersions) => {
          if (!schemaVersions || schemaVersions.versions.length === 0) {
            return 0;
          }

          return schemaVersions.versions[0].versionMajor;
        });
      };

      callbacks.onOpenConfirmableRequestModal?.();
      return updateSchemaConfirm({
        schemaChanges: schemaChangeErrResponse.updates,
        schemaChangeType: schemaChangeErrResponse.combinedType,
        latestSchemaVersionFunc,
        errorCode: e.code,
      }).then(
        () => {
          if (
            e.code === ErrorCode.DestructiveChangeError ||
            e.code === ErrorCode.DestructiveSyncProtocolIncreaseError
          ) {
            track('SYNC_CONFIGURE.SYNC_REINITIALIZED', { location: trackerLocation });
          }
          return onSubmit(e.code);
        },
        () => Promise.reject(e)
      );
    }
    return Promise.reject(e);
  });
}
