import { Any, JsonObject, JsonProperty } from 'json2typescript';

import { EndpointReturnType, EndpointValidationMethod } from './Endpoint';

@JsonObject('DataAPIConfig')
export class DataAPIConfig {
  @JsonProperty('versions', [String], true)
  public versions?: string[] = [];

  @JsonProperty('run_as_system', Boolean, true)
  public runAsSystem?: boolean = false;

  @JsonProperty('run_as_user_id', String, true)
  public runAsUserId?: string = '';

  @JsonProperty('run_as_user_id_script_source', String, true)
  public runAsUserIdScriptSource?: string = '';

  @JsonProperty('disabled', Boolean, true)
  public disabled?: boolean = false;

  @JsonProperty('validation_method', String, true)
  public endpointValidationMethod?: EndpointValidationMethod = EndpointValidationMethod.NoValidation;

  @JsonProperty('secret_name', String, true)
  public secretName?: string = '';

  @JsonProperty('respond_result', Boolean, true)
  public respondResult?: boolean = false;

  @JsonProperty('fetch_custom_user_data', Boolean, true)
  public fetchCustomUserData?: boolean = false;

  @JsonProperty('create_user_on_auth', Boolean, true)
  public createUserOnAuth?: boolean = false;

  @JsonProperty('return_type', String, true)
  public returnType?: EndpointReturnType = undefined;

  @JsonProperty('log_function_arguments', Boolean, true)
  public logFunctionArguments?: boolean = false;

  @JsonProperty('can_evaluate', Any, true)
  public canEvaluate?: Record<string, any> = undefined;

  constructor(partial?: Partial<DataAPIConfig>) {
    Object.assign(this, partial);
  }
}
