import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button, { Size as ButtonSize } from '@leafygreen-ui/button';
import ConfirmationModal, { Variant } from '@leafygreen-ui/confirmation-modal';
import Icon from '@leafygreen-ui/icon';
import { Menu, MenuItem } from '@leafygreen-ui/menu';
import { palette } from '@leafygreen-ui/palette';
import { Body, H3 } from '@leafygreen-ui/typography';
import PropTypes from 'prop-types';
import { TableDeprecated as Table } from 'stitch-ui-toolbox';

import { redirectTo as redirectToAction } from 'baas-ui/actions';
import LeafygreenRouterLink from 'baas-ui/common/components/leafygreen-router-link';
import ServiceDeprecationBanner from 'baas-ui/common/components/service-deprecation-banner';
import ToJS from 'baas-ui/common/components/to-js';
import { ZIndex } from 'baas-ui/common/styles/zIndex';
import { dateTimeDisplay } from 'baas-ui/common/utils/util';
import { getAppState, getServiceState } from 'baas-ui/selectors';
import * as serviceActions from 'baas-ui/services/actions';
import { isDataLakeServiceType, isSelfHostedOrAtlasMongoService, servicesByType } from 'baas-ui/services/registry';
import { EmptyServices } from 'baas-ui/svg';
import { rootUrl } from 'baas-ui/urls';
import { stopPropagation } from 'baas-ui/util';
import { PartialServiceDesc } from 'admin-sdk';

import './services-list.less';

export enum TestSelector {
  ActionButton = 'action-button',
  AddServiceButton = 'add-service-button',
  DeleteServiceButton = 'delete-service-button',
  DeleteServiceConfirm = 'delete-service-confirmation-modal',
}

export interface Props {
  loadServices(): void;
  deleteService(id: string): void;
  onClickRow(id: string): void;
  servicesUrlRoot: any;
  services: PartialServiceDesc[];
  redirectTo(url: string): void;
}

interface ColumnProps {
  data: PartialServiceDesc;
}

const baseClassName = 'services-list';

const ServicesList = (props: Props) => {
  const [deleteServiceModalOpen, setDeleteServiceModalOpen] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState<PartialServiceDesc>();

  useEffect(() => {
    props.loadServices();
  }, []);

  const { servicesUrlRoot, onClickRow, redirectTo } = props;
  const services = props.services
    .filter(
      (svc) => !isSelfHostedOrAtlasMongoService(svc.type) && !isDataLakeServiceType(svc.type) && svc.type !== 'gcm'
    )
    .map((svc) => {
      return { ...svc, typeDisplayName: servicesByType.get(svc.type).displayName };
    });

  return (
    <div id="services">
      {serviceToDelete && (
        <ConfirmationModal
          data-testid={TestSelector.DeleteServiceConfirm}
          open={deleteServiceModalOpen}
          title={`Delete Service: ${serviceToDelete.name} (${servicesByType.get(serviceToDelete.type).displayName})`}
          variant={Variant.Danger}
          confirmButtonProps={{
            children: 'Delete Service',
            onClick: () => {
              setDeleteServiceModalOpen(false);
              props.deleteService(serviceToDelete.id);
            },
          }}
          cancelButtonProps={{
            onClick: () => setDeleteServiceModalOpen(false),
          }}
          style={{ zIndex: ZIndex.Modal }}
        >
          Are you sure you want to delete service:
          <b>
            &nbsp;
            {serviceToDelete.name}
          </b>
          ?
        </ConfirmationModal>
      )}
      <div className="section-header">
        <div className="section-header-title">
          <H3 data-cy="header-title-text">Services</H3>
          <div className="section-header-title-controls">
            <LeafygreenRouterLink to={servicesUrlRoot.new()} data-cy="add-service-button-header">
              <Button variant="primary" data-test-selector={TestSelector.AddServiceButton}>
                Add a Service
              </Button>
            </LeafygreenRouterLink>
          </div>
        </div>
      </div>
      <ServiceDeprecationBanner />
      {!services.length && (
        <div className="empty-state">
          <EmptyServices />
          <H3 className={`${baseClassName}-empty-state-header`}>This application has no services</H3>
          <Body className={`${baseClassName}-empty-state-subtext`}>
            You can integrate your application with services and specify custom rules for them.
          </Body>
          <LeafygreenRouterLink to={servicesUrlRoot.new()} data-cy="add-service-button-empty-state">
            <Button variant="primary" size="large">
              Add a Service
            </Button>
          </LeafygreenRouterLink>
        </div>
      )}
      {!!services.length && (
        <Table data={services} rowOnClick={({ id }) => onClickRow(id)}>
          <Table.Column
            header="Service Name"
            accessor="name"
            sortable
            cell={({ value }) => (
              <Table.Cell key="name" className="service-name">
                {value}
              </Table.Cell>
            )}
          />
          <Table.Column header="Service Type" accessor="typeDisplayName" sortable />
          <Table.Column
            header="Last Modified"
            accessor="lastModified"
            cell={({ value }) => <Table.Cell key="lastModified">{dateTimeDisplay({ time: value })}</Table.Cell>}
            sortable
          />
          <Table.Column
            header="Actions"
            cell={(colProps: ColumnProps) => (
              <Table.Cell key="actions">
                <Menu
                  trigger={
                    <Button
                      data-testid={TestSelector.ActionButton}
                      size={ButtonSize.XSmall}
                      onClick={(e) => e.stopPropagation()}
                      data-cy="open-service-actions"
                    >
                      <Icon glyph="Ellipsis" />
                    </Button>
                  }
                >
                  <MenuItem onClick={() => redirectTo(servicesUrlRoot.service(colProps.data.id).get())}>
                    Edit Service
                  </MenuItem>
                  <MenuItem
                    data-testid={TestSelector.DeleteServiceButton}
                    onClick={stopPropagation(() => {
                      setServiceToDelete(colProps.data);
                      setDeleteServiceModalOpen(true);
                    })}
                    data-cy="delete-service"
                    style={{ color: palette.red.base }}
                  >
                    Delete Service
                  </MenuItem>
                </Menu>
              </Table.Cell>
            )}
          />
        </Table>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    app: { groupId, id: appId },
    error,
  } = getAppState(state);
  const { svcsById } = getServiceState(state);
  const servicesUrlRoot = rootUrl.groups().group(groupId).apps().app(appId).services();
  return {
    groupId,
    appId,
    services: Object.values(svcsById),
    error,
    servicesUrlRoot,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadServices: (groupId, appId) => dispatch(serviceActions.loadServices({ groupId, appId })),
  deleteService: (groupId, appId, svcId) => dispatch(serviceActions.deleteSvc({ groupId, appId, svcId })),
  onClickRow: (url) => dispatch(redirectToAction(url, { replace: false })),
  redirectTo: (url) => dispatch(redirectToAction(url)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  loadServices: () => dispatchProps.loadServices(stateProps.groupId, stateProps.appId),
  deleteService: (svcId) => dispatchProps.deleteService(stateProps.groupId, stateProps.appId, svcId),
  onClickRow: (svcId) => dispatchProps.onClickRow(stateProps.servicesUrlRoot.service(svcId).get()),
});

const ServicesListJS = ToJS(ServicesList);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ServicesListJS);

ServicesList.propTypes = {
  loadServices: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      lastModified: PropTypes.number,
    })
  ),
  servicesUrlRoot: PropTypes.shape({
    new: PropTypes.func.isRequired,
    service: PropTypes.func.isRequired,
  }).isRequired,
};

ServicesList.defaultProps = {
  services: [],
};

export { ServicesListJS as ServicesListComponent };
