import React from 'react';
import { Radio, RadioGroup, Size } from '@leafygreen-ui/radio-group';

import { FieldPermissionValue, TestSelector } from './EditRoleForm';

import './edit-role-form.less';
const fieldPermsBaseClassName = 'edit-role-form-field-level-perms';

export interface AdditionalFieldsPermissionsRadioGroupProps {
  value: FieldPermissionValue;
  onChange: (newVal: FieldPermissionValue) => void;
}

export const AdditionalFieldsPermissionsRadioGroup = ({
  value,
  onChange,
}: AdditionalFieldsPermissionsRadioGroupProps) => {
  return (
    <RadioGroup
      className={`${fieldPermsBaseClassName}-perms-radio-group`}
      onChange={(e) => onChange(e.target.value as FieldPermissionValue)}
      value={value}
      name="additional-fields"
      size={Size.Small}
      data-test-selector={TestSelector.AdditionalFieldPermissionsRadioGroup}
    >
      <Radio
        value={FieldPermissionValue.None}
        className={`${fieldPermsBaseClassName}-perms-radio-group-option`}
        data-test-selector={TestSelector.PermissionsNoneOption}
        data-testid={TestSelector.PermissionsNoneOption}
        data-cy={`additional-fields-${TestSelector.PermissionsNoneOption}`}
      >
        None
      </Radio>
      <Radio
        value={FieldPermissionValue.Read}
        className={`${fieldPermsBaseClassName}-perms-radio-group-option`}
        data-test-selector={TestSelector.PermissionsReadOption}
        data-testid={TestSelector.PermissionsReadOption}
        data-cy={`additional-fields-${TestSelector.PermissionsReadOption}`}
      >
        Read
      </Radio>
      <Radio
        value={FieldPermissionValue.ReadAndWrite}
        className={`${fieldPermsBaseClassName}-perms-radio-group-option`}
        data-test-selector={TestSelector.PermissionsReadAndWriteOption}
        data-testid={TestSelector.PermissionsReadAndWriteOption}
        data-cy={`additional-fields-${TestSelector.PermissionsReadAndWriteOption}`}
      >
        Read &amp; Write
      </Radio>
    </RadioGroup>
  );
};
