import React, { useEffect, useState } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import Icon from '@leafygreen-ui/icon';
import IconButton from '@leafygreen-ui/icon-button';
import { palette } from '@leafygreen-ui/palette';
import Tooltip from '@leafygreen-ui/tooltip';

import { ZIndex } from 'baas-ui/common/styles/zIndex';

export interface Props {
  copyText: string;
  tooltipDisplay: string;
  onIconClick: () => void;
  disabled?: boolean;
  iconColor?: string;
}

type IconProp = {
  copied: boolean;
};

const StyledIconButton = styled(IconButton, { shouldForwardProp: (prop) => isPropValid(prop) })<IconProp>`
  color: ${({ copied }) => (copied ? 'white' : '')};
  background-color: ${({ copied }) => (copied ? palette.green.dark1 : '')};

  ${({ copied }) =>
    copied &&
    `
    &:hover {
      background-color: ${palette.green.dark1};
      &:before {
        background-color: ${palette.green.dark1};
      }
    }
    &:focus {
      color: ${palette.white};
      &:before {
        background-color: ${palette.green.dark1};
      }
    }
`}
`;

export enum TestSelector {
  CopyButton = 'copy-button',
  TooltipSelector = 'tooltip',
  GlyphCheck = 'glyph-check',
  GlyphCopy = 'glyph-copy',
}

export default function CopyText({ copyText, tooltipDisplay, onIconClick, disabled, iconColor }: Props) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (copied) {
      navigator.clipboard.writeText(copyText);
      timeoutId = setTimeout(() => {
        setCopied(false);
      }, 1500);
    }

    return () => clearTimeout(timeoutId);
  }, [copied]);

  return (
    <Tooltip
      align="bottom"
      justify="middle"
      data-testid={TestSelector.TooltipSelector}
      trigger={
        <StyledIconButton
          copied={copied}
          aria-label="copy text"
          data-testid={TestSelector.CopyButton}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setCopied(true);
            onIconClick();
          }}
        >
          {copied ? (
            <Icon data-testid={TestSelector.GlyphCheck} glyph="Checkmark" />
          ) : (
            <Icon data-testid={TestSelector.GlyphCopy} glyph="Copy" color={iconColor} />
          )}
        </StyledIconButton>
      }
      popoverZIndex={ZIndex.Guide}
    >
      {tooltipDisplay}
    </Tooltip>
  );
}
