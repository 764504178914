import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Button, { Size as ButtonSize } from '@leafygreen-ui/button';
import Icon, { Size as IconSize } from '@leafygreen-ui/icon';
import { palette } from '@leafygreen-ui/palette';
import { Body, Disclaimer } from '@leafygreen-ui/typography';

import { CodeEditor } from 'baas-ui/functions/code-editor';
import { SupportedLanguages } from 'baas-ui/functions/code-editor/CodeEditor';

import { TestSelector } from './EditRoleForm';

import './edit-role-form.less';

export interface DocumentFiltersCollapsibleProps {
  expanded: boolean;
  readExpression: string;
  writeExpression: string;
  onChangeReadExpression(expression: string): void;
  onChangeWriteExpression(expression: string): void;
  onClickHeader(): void;
  readParsingError: string;
  writeParsingError: string;
}

const baseClassName = 'edit-role-form-document-filters-collapsible';

const DocFilterReadEditor = styled(CodeEditor)`
  height: 200px;
`;

const DocFilterWriteEditor = styled(CodeEditor)`
  height: 200px;
`;

export const DocumentFiltersCollapsible = ({
  expanded,
  readExpression,
  writeExpression,
  onChangeReadExpression,
  onChangeWriteExpression,
  onClickHeader,
  readParsingError,
  writeParsingError,
}: DocumentFiltersCollapsibleProps) => {
  const [readFilterCopied, setReadFilterCopied] = useState(false);
  const [writeFilterCopied, setWriteFilterCopied] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (readFilterCopied) {
      navigator.clipboard.writeText(readExpression);
      timeoutId = setTimeout(() => {
        setReadFilterCopied(false);
      }, 1500);
    }

    return () => clearTimeout(timeoutId);
  }, [readFilterCopied]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (writeFilterCopied) {
      navigator.clipboard.writeText(writeExpression);
      timeoutId = setTimeout(() => {
        setWriteFilterCopied(false);
      }, 1500);
    }

    return () => clearTimeout(timeoutId);
  }, [writeFilterCopied]);

  return (
    <div data-testid={TestSelector.DocFiltersCollapsible} className={baseClassName}>
      <div
        className={`${baseClassName}-heading`}
        onClick={onClickHeader}
        data-testid={TestSelector.DocFiltersHeader}
        data-cy="doc-filters-header"
      >
        <Icon
          className={`${baseClassName}-heading-icon-${expanded ? 'open' : 'closed'}`}
          glyph="ChevronRight"
          size={IconSize.Small}
          color={palette.gray.base}
        />
        <Disclaimer className={`${baseClassName}-heading-text`}>ADVANCED: DOCUMENT FILTERS</Disclaimer>
      </div>
      {expanded && (
        <div data-testid={TestSelector.DocFiltersContent} className={`${baseClassName}-content`}>
          <Body className={`${baseClassName}-content-text`}>
            Document filters evaluate whether the document can be read or written to before evaluating field-level
            permissions. They are required to be defined in order to use Flexible Sync.
          </Body>
          <div className={`${baseClassName}-content-read`}>
            <div className={`${baseClassName}-content-read-description`}>
              <Body className={`${baseClassName}-content-read-description-text`}>Read</Body>
              <Button
                data-testid={TestSelector.DocFiltersCopyReadFilterButton}
                size={ButtonSize.XSmall}
                className={`${baseClassName}-content-read-description-button-${readFilterCopied ? 'copied' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  setReadFilterCopied(true);
                }}
                leftGlyph={readFilterCopied ? <Icon glyph="Checkmark" /> : <Icon glyph="Copy" />}
              />
            </div>
            <DocFilterReadEditor
              data-cy="doc-filters-read-editor"
              functionInput={readExpression}
              language={SupportedLanguages.JSON}
              onChange={(expression: string) => onChangeReadExpression(expression)}
            />
            {readParsingError && (
              <Banner
                variant={Variant.Danger}
                className={`${baseClassName}-content-read-parsing-error-banner`}
                data-testid={TestSelector.DocFiltersReadWarningBanner}
              >
                {readParsingError}
              </Banner>
            )}
          </div>
          <div className={`${baseClassName}-content-write`}>
            <div className={`${baseClassName}-content-write-description`}>
              <Body className={`${baseClassName}-content-write-description-text`}>Write</Body>
              <Button
                data-testid={TestSelector.DocFiltersCopyWriteFilterButton}
                size={ButtonSize.XSmall}
                className={`${baseClassName}-content-write-description-button-${writeFilterCopied ? 'copied' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  setWriteFilterCopied(true);
                }}
                leftGlyph={writeFilterCopied ? <Icon glyph="Checkmark" /> : <Icon glyph="Copy" />}
              />
            </div>
            <DocFilterWriteEditor
              data-cy="doc-filters-write-editor"
              functionInput={writeExpression}
              language={SupportedLanguages.JSON}
              onChange={(expression: string) => onChangeWriteExpression(expression)}
            />
            {writeParsingError && (
              <Banner
                variant={Variant.Danger}
                className={`${baseClassName}-content-write-parsing-error-banner`}
                data-testid={TestSelector.DocFiltersWriteWarningBanner}
              >
                {writeParsingError}
              </Banner>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
