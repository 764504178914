import { GraphQLSchema } from 'graphql';
import { createReducer } from 'redux-act';

import { AsyncDataState, makeDefaultAsyncDataState } from 'baas-ui/redux_util';
import { CustomResolver, ExtendableTypes, GraphQLAlerts, GraphQLConfig, ValidationOptions } from 'admin-sdk';

import * as actions from './actions';
import { GraphQLReadyState } from './types';

const ERROR_NO_SCHEMAS_FOUND = 'NoSchemasFound';
const ERROR_NO_SERVICE_FOUND = 'NoMongoDBServiceFound';

export interface GraphQLState {
  customResolvers: AsyncDataState<CustomResolver[]>;
  extendableTypes: AsyncDataState<ExtendableTypes>;
  graphqlConfig: {
    local: GraphQLConfig | null;
    stored: AsyncDataState<GraphQLConfig>;
    isSaving: boolean;
    saveError?: string;
  };
  graphqlReadyState: GraphQLReadyState | null;
  isNaturalPluralizationEnabled: boolean;
  schema: AsyncDataState<GraphQLSchema>;
  schemaValidation: AsyncDataState<GraphQLAlerts[]>;
  validationSettings: {
    local: ValidationOptions | null;
    stored: AsyncDataState<ValidationOptions>;
    isSaving: boolean;
    saveError?: string;
  };
}

export const defaultState: GraphQLState = {
  customResolvers: makeDefaultAsyncDataState(),
  extendableTypes: makeDefaultAsyncDataState(),
  graphqlConfig: {
    local: null,
    stored: makeDefaultAsyncDataState(),
    isSaving: false,
  },
  graphqlReadyState: null,
  isNaturalPluralizationEnabled: true,
  schema: makeDefaultAsyncDataState(),
  schemaValidation: makeDefaultAsyncDataState(),
  validationSettings: {
    local: null,
    stored: makeDefaultAsyncDataState(),
    isSaving: false,
  },
};

const reducer = createReducer<GraphQLState>({}, defaultState);

reducer.on(actions.updateLocalValidationSettings, (state, validationSettings) => ({
  ...state,
  validationSettings: {
    ...state.validationSettings,
    local: {
      ...validationSettings,
    },
  },
}));

reducer.on(actions.loadGraphQLSchemaActions.req, (state) => ({
  ...state,
  schema: {
    ...state.schema,
    isLoading: true,
  },
}));

reducer.on(actions.loadGraphQLSchemaActions.rcv, (state, { payload }) => ({
  ...state,
  schema: {
    ...state.schema,
    isLoading: false,
    data: payload,
    error: undefined,
  },
}));

reducer.on(actions.loadGraphQLSchemaActions.fail, (state, { error }) => ({
  ...state,
  schema: {
    ...state.schema,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.loadValidationSettingsActions.req, (state) => ({
  ...state,
  validationSettings: {
    ...state.validationSettings,
    stored: {
      ...state.validationSettings.stored,
      isLoading: true,
      error: undefined,
    },
  },
}));

reducer.on(actions.loadValidationSettingsActions.rcv, (state, { payload }) => ({
  ...state,
  validationSettings: {
    ...state.validationSettings,
    local: payload,
    stored: {
      ...state.validationSettings.stored,
      isLoading: false,
      data: payload,
    },
  },
}));

reducer.on(actions.loadValidationSettingsActions.fail, (state, { error }) => ({
  ...state,
  validationSettings: {
    ...state.validationSettings,
    stored: {
      ...state.validationSettings.stored,
      isLoading: false,
      error,
    },
  },
}));

reducer.on(actions.updateValidationSettingsActions.req, (state) => ({
  ...state,
  validationSettings: {
    ...state.validationSettings,
    saveError: undefined,
    isSaving: true,
  },
}));

reducer.on(actions.updateValidationSettingsActions.rcv, (state) => ({
  ...state,
  validationSettings: {
    ...state.validationSettings,
    stored: {
      ...state.validationSettings.stored,
      data: state.validationSettings.local,
    },
    isSaving: false,
  },
}));

reducer.on(actions.updateValidationSettingsActions.fail, (state, { error }) => ({
  ...state,
  validationSettings: {
    ...state.validationSettings,
    saveError: error,
    isSaving: false,
  },
}));

reducer.on(actions.validateGraphQLActions.req, (state) => ({
  ...state,
  schemaValidation: {
    ...state.schemaValidation,
    isLoading: true,
  },
}));

reducer.on(actions.validateGraphQLActions.rcv, (state, { payload }) => ({
  ...state,
  graphqlReadyState: GraphQLReadyState.READY,
  schemaValidation: {
    ...state.schemaValidation,
    isLoading: false,
    data: payload,
  },
}));

reducer.on(actions.validateGraphQLActions.fail, (state, { error, rawError }) => {
  let graphqlReadyState: GraphQLReadyState;

  switch (rawError.code) {
    case ERROR_NO_SCHEMAS_FOUND:
      graphqlReadyState = GraphQLReadyState.NEED_SCHEMAS;
      break;
    case ERROR_NO_SERVICE_FOUND:
      graphqlReadyState = GraphQLReadyState.NEED_SERVICE;
      break;
    default:
      graphqlReadyState = GraphQLReadyState.READY;
  }

  return {
    ...state,
    graphqlReadyState,
    schemaValidation: {
      ...state.schemaValidation,
      isLoading: false,
      error,
    },
  };
});

reducer.on(actions.loadCustomResolverActions.req, (state) => ({
  ...state,
  customResolvers: {
    ...state.customResolvers,
    isLoading: true,
  },
}));

reducer.on(actions.loadCustomResolverActions.rcv, (state, { payload }) => ({
  ...state,
  customResolvers: {
    ...state.customResolvers,
    isLoading: false,
    data: payload,
  },
}));

reducer.on(actions.loadCustomResolverActions.fail, (state, { error }) => ({
  ...state,
  customResolvers: {
    ...state.customResolvers,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.deleteCustomResolverActions.rcv, (state, { reqArgs }) => ({
  ...state,
  customResolvers: {
    ...state.customResolvers,
    data: state.customResolvers.data ? state.customResolvers.data.filter(({ id }) => id !== reqArgs.id) : null,
  },
}));

reducer.on(actions.loadGraphQLConfigActions.req, (state) => ({
  ...state,
  graphqlConfig: {
    ...state.graphqlConfig,
    stored: {
      ...state.graphqlConfig.stored,
      isLoading: true,
      error: undefined,
    },
  },
}));

reducer.on(actions.loadGraphQLConfigActions.rcv, (state, { payload }) => ({
  ...state,
  graphqlConfig: {
    ...state.graphqlConfig,
    local: { ...payload },
    stored: {
      ...state.graphqlConfig.stored,
      isLoading: false,
      data: payload,
    },
  },
}));

reducer.on(actions.loadGraphQLConfigActions.fail, (state, { error }) => ({
  ...state,
  graphqlConfig: {
    ...state.graphqlConfig,
    stored: {
      ...state.graphqlConfig.stored,
      isLoading: false,
      error,
    },
  },
}));

reducer.on(actions.updateGraphQLConfigActions.req, (state) => ({
  ...state,
  graphqlConfig: {
    ...state.graphqlConfig,
    saveError: undefined,
    isSaving: true,
  },
}));

reducer.on(actions.updateGraphQLConfigActions.rcv, (state) => ({
  ...state,
  graphqlConfig: {
    ...state.graphqlConfig,
    stored: {
      ...state.graphqlConfig.stored,
      data: state.graphqlConfig.local && {
        ...state.graphqlConfig.local,
      },
    },
    isSaving: false,
  },
}));

reducer.on(actions.updateGraphQLConfigActions.fail, (state, { error }) => ({
  ...state,
  graphqlConfig: {
    ...state.graphqlConfig,
    saveError: error,
    isSaving: false,
  },
}));

reducer.on(actions.loadExtendableTypesActions.req, (state) => ({
  ...state,
  extendableTypes: {
    ...state.extendableTypes,
    isLoading: true,
  },
}));

reducer.on(actions.loadExtendableTypesActions.rcv, (state, { payload }) => ({
  ...state,
  extendableTypes: {
    ...state.extendableTypes,
    data: payload,
    isLoading: false,
  },
}));

reducer.on(actions.loadExtendableTypesActions.fail, (state, { error }) => ({
  ...state,
  extendableTypes: {
    ...state.extendableTypes,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.updateLocalGraphQLConfig, (state, config) => ({
  ...state,
  graphqlConfig: {
    ...state.graphqlConfig,
    local: {
      ...config,
    },
  },
}));

export default reducer;
