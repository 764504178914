import React from 'react';
import Icon from '@leafygreen-ui/icon';
import IconButton from '@leafygreen-ui/icon-button';
import Tooltip from '@leafygreen-ui/tooltip';
import { Body, Disclaimer } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import DocLink from 'baas-ui/common/components/doc-link';

import ExpandArrowsIcon from './expand-arrows-icon';

import './guide-popover-header.less';

const baseClassName = 'guide-popover-header';

export enum TestSelector {
  ExpandCollapseTooltip = 'expand-collapse-tooltip',
  Header = 'header',
  ViewDocsTooltip = 'view-docs-tooltip',
  AllGuidesButton = 'all-guides-tooltip',
  CloseTooltip = 'close-tooltip',
  CloseIcon = 'close-icon-button',
  ExpandedIcon = 'expanded-icon-button',
}

export interface Props {
  header: string;
  isExpanded: boolean;
  isHidden: boolean;
  docsLink?: string;
  onClickAllGuides(): void;
  onClose(): void;
  onSetIsExpanded(isExpanded: boolean): void;
}

export default function GuidePopoverHeader({
  header,
  isExpanded,
  isHidden,
  docsLink,
  onClickAllGuides,
  onClose,
  onSetIsExpanded,
}: Props) {
  return (
    <div className={classNames(baseClassName, { [`${baseClassName}-collapsed`]: !isExpanded })}>
      <div className={`${baseClassName}-title`}>
        <Tooltip
          portalClassName={`${baseClassName}-tooltip`}
          trigger={({ children, ...rest }) => (
            <div
              {...rest}
              className={classNames(`${rest.className}`, `${baseClassName}-title-expand`, {
                [`${baseClassName}-title-expand-collapsed`]: !isExpanded,
              })}
            >
              <IconButton
                className={classNames({ [`${baseClassName}-icon-button`]: !isExpanded })}
                data-test-selector={TestSelector.ExpandedIcon}
                aria-label="is expanded icon"
                onClick={() => onSetIsExpanded(!isExpanded)}
              >
                <Icon glyph={isExpanded ? 'ChevronDown' : 'ChevronRight'} role="img" tabIndex={0} />
              </IconButton>
              {children}
            </div>
          )}
          data-test-selector={TestSelector.ExpandCollapseTooltip}
          // key is needed to ensure the tooltip disappears since
          // clicking on the trigger causes the tooltip to move
          key={isExpanded ? 'open' : 'closed'}
          enabled={!isHidden}
        >
          {`${isExpanded ? 'Collapse' : 'Expand'} Guide Window`}
        </Tooltip>
        <Body
          weight="medium"
          className={classNames(`${baseClassName}-title-header`, {
            [`${baseClassName}-title-header-collapsed`]: !isExpanded,
          })}
          data-cy="guide-popover-header-title-header"
          data-test-selector={TestSelector.Header}
        >
          {header}
        </Body>
        {isExpanded && docsLink && (
          <Tooltip
            portalClassName={`${baseClassName}-tooltip`}
            trigger={({ children, ...rest }) => (
              <DocLink {...rest} href={docsLink}>
                <ExpandArrowsIcon />
                {children}
              </DocLink>
            )}
            data-test-selector={TestSelector.ViewDocsTooltip}
          >
            View in Docs
          </Tooltip>
        )}
      </div>
      <div className={`${baseClassName}-actions`}>
        {isExpanded && (
          <Disclaimer
            className={`${baseClassName}-actions-all-guides`}
            onClick={onClickAllGuides}
            role="button"
            tabIndex={0}
            data-test-selector={TestSelector.AllGuidesButton}
          >
            All Guides
          </Disclaimer>
        )}
        <div className={`${baseClassName}-actions-seperator`} />
        <Tooltip
          portalClassName={`${baseClassName}-tooltip`}
          trigger={({ children, ...rest }) => (
            <div
              {...rest}
              className={classNames(`${rest.className}`, `${baseClassName}-actions-close`, {
                [`${baseClassName}-actions-close-collapsed`]: !isExpanded,
              })}
            >
              <IconButton
                className={classNames({ [`${baseClassName}-icon-button`]: !isExpanded })}
                data-test-selector={TestSelector.CloseIcon}
                aria-label="close icon"
                onClick={onClose}
              >
                <Icon glyph="X" role="img" tabIndex={0} />
              </IconButton>
              {children}
            </div>
          )}
          data-test-selector={TestSelector.CloseTooltip}
          enabled={!isHidden}
        >
          Close Guide
        </Tooltip>
      </div>
    </div>
  );
}
