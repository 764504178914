import React from 'react';

import { track } from 'baas-ui/tracking';
import EVENTS from 'baas-ui/tracking/events';
import { EventProperties } from 'baas-ui/tracking/types';

export interface Props {
  event: keyof typeof EVENTS;
  properties?: EventProperties;
}

const TrackOnRender = ({ event, properties }: Props) => {
  React.useEffect(() => {
    track(event, properties);
  }, []);
  return null;
};

export default TrackOnRender;
