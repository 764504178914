import React from 'react';
import Button from '@leafygreen-ui/button';
import PropTypes from 'prop-types';

import { passThroughProps } from 'baas-ui/common/utils/util';

export interface Props {
  className?: string;
  editing?: boolean;
  saving?: boolean;
  isDirty?: boolean;
  onSave(): void;
  namePrefix?: string;
  disabled?: boolean;
  isDraft?: boolean;
}

export enum TestSelector {
  PrimarySaveButton = 'primary-save-button',
  NoChangesButton = 'no-changes-button',
}

export default function SaveButton({
  className = '',
  editing = true,
  onSave,
  saving = false,
  isDirty = false,
  namePrefix = 'header',
  disabled = false,
  isDraft = false,
  ...rest
}: Props) {
  return isDirty || !editing ? (
    <Button
      name={`${namePrefix}Save`}
      data-testid={TestSelector.PrimarySaveButton}
      className={className}
      variant="primary"
      onClick={onSave}
      disabled={saving || disabled}
      {...passThroughProps(rest)}
    >
      {isDraft && (saving ? 'Saving Draft...' : 'Save Draft')}
      {!isDraft && (saving ? 'Saving...' : 'Save')}
    </Button>
  ) : (
    <Button
      name={`${namePrefix}NoChanges`}
      data-testid={TestSelector.NoChangesButton}
      className={className}
      disabled
      {...passThroughProps(rest)}
    >
      No Changes
    </Button>
  );
}

SaveButton.propTypes = {
  className: PropTypes.string,
  editing: PropTypes.bool,
  isDirty: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  namePrefix: PropTypes.string,
  disabled: PropTypes.bool,
  isDraft: PropTypes.bool,
};
