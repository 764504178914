import React from 'react';
import classNames from 'classnames';

import './shimmer-fill.less';

export enum TestSelector {
  Container = 'container',
}

const ShimmerFill: React.FC<{ className?: string; visible?: boolean }> = ({ className, visible, children }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {visible ? (
      <div data-testid={TestSelector.Container} className={classNames('shimmer-fill', className)} />
    ) : (
      children
    )}
  </>
);

export default ShimmerFill;
