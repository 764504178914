import React, { createContext, useMemo, useState } from 'react';

interface ContextData {
  savedTriggerName: string;
  setSavedTriggerName: (name: string) => void;
}
export const SavedTriggerNameContext = createContext<ContextData>({
  savedTriggerName: '',
  setSavedTriggerName: () => {},
});

export const SavedTriggerNameContextProvider: React.FC = ({ children }) => {
  const [savedTriggerName, setSavedTriggerName] = useState('');

  const memoizedData = useMemo(
    () => ({
      savedTriggerName,
      setSavedTriggerName,
    }),
    [savedTriggerName]
  );

  return <SavedTriggerNameContext.Provider value={memoizedData}>{children}</SavedTriggerNameContext.Provider>;
};
