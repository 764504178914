import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { SideNavItem } from '@leafygreen-ui/side-nav';

import { passThroughProps } from 'baas-ui/common/utils/util';
import { track } from 'baas-ui/tracking';

interface SideNavWithLinkPublicProps {
  isBeta?: boolean;
  to: string;
  children: React.ReactNode;
  active?: boolean;
  className?: string;
  dataTarget?: string;
  glyph?: React.ReactNode;
  target?: string;
  onClick?(): void;
}

export type SideNavWithLinkProps = SideNavWithLinkPublicProps & RouteComponentProps<{}>;

export const SideNavItemWithLinkComponent: React.FC<SideNavWithLinkProps> = ({
  isBeta,
  children,
  to,
  active,
  location,
  className,
  dataTarget,
  glyph,
  target = '_blank',
  onClick,
  ...rest
}) => {
  let dataCy: string | undefined;
  if (typeof dataTarget === 'string') {
    dataCy = `nav-link-${dataTarget.replace(/\s/g, '-')}`;
  } else if (typeof children === 'string') {
    dataCy = `nav-link-${children}`.replace(/\s/g, '-');
  }

  const sharedProps = {
    active: active !== undefined ? active : location.pathname.includes(to),
    'data-cy': dataCy,
    className,
    glyph,
    onClick: () => {
      if (onClick) {
        onClick();
      }
      if (typeof children === 'string') {
        track('SIDE_NAV.ITEM_CLICKED', { text: children });
      } else if (to.endsWith('dataSources')) {
        // track linked data sources clicks
        track('SIDE_NAV.ITEM_CLICKED', { text: 'Linked Data Sources' });
      } else if (to.endsWith('dashboard')) {
        // track app name clicks
        track('SIDE_NAV.ITEM_CLICKED', { text: 'App Name' });
      }
    },
  };

  const sharedContents = (
    <>
      {children}
      {isBeta && <span className="side-nav-item-beta-tag">BETA</span>}
    </>
  );

  return to.startsWith('http') ? (
    <SideNavItem {...sharedProps} {...passThroughProps(rest)} rel="noopener noreferrer" target={target} href={to}>
      {sharedContents}
    </SideNavItem>
  ) : (
    <SideNavItem {...sharedProps} {...passThroughProps(rest)} as={Link} to={to}>
      {sharedContents}
    </SideNavItem>
  );
};

export default withRouter(SideNavItemWithLinkComponent);
