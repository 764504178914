import React, { useRef } from 'react';
import Button from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import { Body } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import GuidePopoverHeader from './header';

import './guide-popover.less';

const baseClassName = 'guide-popover';
const contentFooterClassName = `${baseClassName}-content-footer`;

export enum TestSelector {
  PopoverContent = 'popover-content',
  PopoverContentGuide = 'popover-content-guide',
  BackTextButton = 'back-text-button',
  StepProgress = 'step-progress',
  NextButton = 'next-button',
  NextTextButton = 'next-text-button',
}

export interface Props {
  className?: string;
  children?: React.ReactNode;
  currentStep: number;
  header: string;
  isHidden: boolean;
  showProgress?: boolean;
  nextIsButton?: boolean;
  nextLabel: string;
  totalSteps: number;
  docsLink?: string;
  isExpanded: boolean;
  setIsExpanded(boolean): void;
  onClickAllGuides(): void;
  onBack?(): void;
  onClose(): void;
  onNext(): void;
}

const resetScrollEffect = ({ element }) => {
  element.current.scrollTop = 0;
};

const GuidePopover = ({
  className,
  children,
  currentStep,
  header,
  isHidden,
  showProgress,
  nextIsButton,
  nextLabel,
  totalSteps,
  docsLink,
  onBack,
  onClose,
  onClickAllGuides,
  onNext,
  isExpanded,
  setIsExpanded,
}: Props) => {
  const guideContentRef = useRef(null);

  React.useEffect(() => resetScrollEffect({ element: guideContentRef }), [children]);

  return (
    <div data-cy="guide-popover" className={classNames(`${baseClassName}`, className)}>
      <GuidePopoverHeader
        header={header}
        isHidden={isHidden}
        isExpanded={isExpanded}
        onClickAllGuides={onClickAllGuides}
        onClose={onClose}
        onSetIsExpanded={setIsExpanded}
        docsLink={docsLink}
      />
      <div
        className={classNames(`${baseClassName}-content`, {
          [`${baseClassName}-content-expanded`]: isExpanded,
        })}
        data-test-selector={TestSelector.PopoverContent}
      >
        <div
          ref={guideContentRef}
          className={`${baseClassName}-content-guide`}
          data-test-selector={TestSelector.PopoverContentGuide}
        >
          {children}
        </div>
        <div className={contentFooterClassName}>
          <div className={`${contentFooterClassName}-left`}>
            {onBack && (
              <Body
                className={`${contentFooterClassName}-nav`}
                onClick={onBack}
                role="button"
                tabIndex={0}
                data-test-selector={TestSelector.BackTextButton}
              >
                <Icon glyph="ChevronLeft" /> Back
              </Body>
            )}
          </div>
          <div className={`${contentFooterClassName}-center`}>
            {showProgress && (
              <Body
                data-cy="guide-popover-progress"
                className={`${contentFooterClassName}-center-progress`}
                data-test-selector={TestSelector.StepProgress}
              >
                {`${currentStep} of ${totalSteps}`}
              </Body>
            )}
          </div>
          <div className={`${contentFooterClassName}-right`}>
            {nextIsButton ? (
              <Button
                data-cy="guide-popover-next-button"
                size="small"
                onClick={onNext}
                variant="primary"
                data-test-selector={TestSelector.NextButton}
              >
                {nextLabel}
              </Button>
            ) : (
              <Body
                data-cy="guide-popover-next-button"
                className={`${contentFooterClassName}-nav`}
                onClick={onNext}
                role="button"
                tabIndex={0}
                data-test-selector={TestSelector.NextTextButton}
              >
                {nextLabel} <Icon glyph="ChevronRight" />
              </Body>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidePopover;
