import { createAction } from 'redux-act';

import { createActionsAndExecutor } from 'baas-ui/redux_util';
import {
  GenerateSchemaResponse,
  GenerateSchemasPayload,
  GenerateSingleSchemaPayload,
} from 'baas-ui/services/mongodb/actions';
import { updateNullTypeSchemaValidationSettingActions } from 'baas-ui/settings/actions';
import { BaseRequestPayload } from 'baas-ui/types';
import { BypassServiceChangeValue, GetSyncSchemasErrorResponse, PartialSchema, Schema } from 'admin-sdk';

const NAME = 'schema/';

export interface SchemaByIdPayload extends BaseRequestPayload {
  schemaId: string;
}

export interface NewSchemaPayload extends BaseRequestPayload {
  schema: Omit<Schema, 'id'>;
  bypassServiceChange?: BypassServiceChangeValue;
}

export interface SchemaPayload extends BaseRequestPayload {
  schema: Schema;
  bypassServiceChange?: BypassServiceChangeValue;
}

interface SchemaDeletePayload extends BaseRequestPayload {
  dataSource: string;
  database?: string;
}

export const clearLoadedSchema = createAction(`${NAME}clear loaded schema`);
export const clearLoadedSchemaError = createAction(`${NAME}clear loaded schema error`);
export const setGQLModelDataRefetched = createAction(`${NAME}gql models refetched`);
export const setSyncModelDataRefetched = createAction(`${NAME}sync models refetched`);

export const [loadSchemasActions, loadSchemas] = createActionsAndExecutor(
  `${NAME}load schemas`,
  (client, { groupId, appId }: BaseRequestPayload) =>
    () =>
      client.apps(groupId).app(appId).schemas().list()
);

export const [loadSchemaActions, loadSchema] = createActionsAndExecutor(
  `${NAME}load schema`,
  (client, { groupId, appId, schemaId }: SchemaByIdPayload) =>
    () =>
      client.apps(groupId).app(appId).schemas().schema(schemaId).get()
);

// loadTempSchema does not have a matching reducer as to avoid overriding the schema in the reducer state.
export const [loadTempSchemaActions, loadTempSchema] = createActionsAndExecutor(
  `${NAME}load temporary schema`,
  (client, { groupId, appId, schemaId }: SchemaByIdPayload) =>
    () =>
      client.apps(groupId).app(appId).schemas().schema(schemaId).get()
);

export const [createSchemaActions, createSchema] = createActionsAndExecutor<NewSchemaPayload, PartialSchema>(
  `${NAME}create schema`,
  (client, { groupId, appId, schema, bypassServiceChange }) =>
    () =>
      client.apps(groupId).app(appId).schemas().create(new Schema(schema), bypassServiceChange)
);

export const [updateSchemaActions, updateSchema] = createActionsAndExecutor<SchemaPayload, null>(
  `${NAME}update schema`,
  (client, { groupId, appId, schema, bypassServiceChange }) =>
    () =>
      client.apps(groupId).app(appId).schemas().schema(schema.id).update(new Schema(schema), bypassServiceChange)
);

export const [generateSchemaActions, generateSchema] = createActionsAndExecutor<
  GenerateSingleSchemaPayload,
  GenerateSchemaResponse
>(
  `${NAME}generate schema`,
  (client, { groupId, appId, svcId, ...requestBody }) =>
    () =>
      client.apps(groupId).app(appId).services().service(svcId).runCommand('generate_schema', requestBody)
);

export const [bulkGenerateSchemaActions, bulkGenerateSchema] = createActionsAndExecutor<
  GenerateSchemasPayload,
  GenerateSchemaResponse
>(
  `${NAME}generate bulk schema`,
  (client, { groupId, appId, ...requestBody }) =>
    () =>
      client.apps(groupId).app(appId).schemas().bulkGenerateSchema(requestBody)
);

export type DeleteSchemaPayload = SchemaByIdPayload & { bypassServiceChange?: BypassServiceChangeValue };

export const [deleteSchemaActions, deleteSchema] = createActionsAndExecutor<DeleteSchemaPayload, null>(
  `${NAME}delete schema`,
  (client, { groupId, appId, schemaId, bypassServiceChange }) =>
    () =>
      client.apps(groupId).app(appId).schemas().schema(schemaId).remove(bypassServiceChange)
);

export const [deleteSchemasActions, deleteSchemas] = createActionsAndExecutor<SchemaDeletePayload, null>(
  `${NAME}delete schemas`,
  (client, { groupId, appId, dataSource, database }) =>
    () =>
      client.apps(groupId).app(appId).schemas().remove(dataSource, database)
);

export const [loadSchemaSyncErrorsActions, loadSchemaSyncErrors] = createActionsAndExecutor<
  BaseRequestPayload,
  GetSyncSchemasErrorResponse
>(
  `${NAME}load schema sync errors`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).schemas().getSyncErrors()
);

export const asyncEditRcvActions = [
  createSchemaActions.rcv,
  updateSchemaActions.rcv,
  deleteSchemaActions.rcv,
  deleteSchemasActions.rcv,
  updateNullTypeSchemaValidationSettingActions.rcv,
  bulkGenerateSchemaActions.rcv,
];
