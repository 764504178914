import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Badge, { Variant } from '@leafygreen-ui/badge';
import MarketingModal, { BlobPosition } from '@leafygreen-ui/marketing-modal';
import { Link } from '@leafygreen-ui/typography';

import { setHasAcknowledgedDarkMode } from 'baas-ui/actions';
import { acknowledgeDarkMode } from 'baas-ui/cloudStartupPreferences';
import { ZIndex } from 'baas-ui/common/styles/zIndex';
import { passThroughProps } from 'baas-ui/common/utils/util';
import { AsyncDispatch } from 'baas-ui/redux_util';
import LaptopThumbnail from 'baas-static/images/LaptopThumbnail.png';

export interface Props {
  open?: boolean;
  onRedirect(url: string): void;
}

const DarkModeMarketingModal = ({ open = false, onRedirect, ...rest }: Props) => {
  const [isOpen, setIsOpen] = useState(open);
  const dispatch = useDispatch<AsyncDispatch>();

  const onAcknowledge = async () => {
    try {
      await acknowledgeDarkMode(window.settings.cloudUIBaseUrl);
      dispatch(setHasAcknowledgedDarkMode());
    } catch (err) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const cloudPreferencesURL = `${window.settings.cloudUIBaseUrl}/v2#/preferences`;

  return (
    <MarketingModal
      showBlob
      title={
        <>
          <div>
            <Badge variant={Variant.Blue}>Preview</Badge>
          </div>
          Introducing dark mode
        </>
      }
      blobPosition={BlobPosition.TopRight}
      buttonText="Go to user preferences"
      linkText="Close"
      onLinkClick={onAcknowledge}
      onButtonClick={() => onRedirect(cloudPreferencesURL)}
      onClose={onAcknowledge}
      open={isOpen}
      closeIconColor="default"
      style={{ zIndex: ZIndex.Modal }}
      graphic={
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        <img
          src={LaptopThumbnail}
          height="150px"
          width="226px"
          alt="Laptop with mountains on screen next to phone showing same image."
        />
      }
      {...passThroughProps(rest)}
    >
      You asked. We listened. Dark mode is here. You can now update your theme settings in{' '}
      <Link href={cloudPreferencesURL}>personalization</Link> preferences.
    </MarketingModal>
  );
};

export default DarkModeMarketingModal;
