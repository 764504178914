import { Location, ProviderRegion } from 'admin-sdk';

import { CloudProvider, CloudProviderOptionTypes, RegionOptionTypes } from './types';

export const locationNames = {
  [Location.Virginia]: 'Virginia (us-east-1)',
  [Location.Oregon]: 'Oregon (us-west-2)',
  [Location.Ireland]: 'Ireland (eu-west-1)',
  [Location.Sydney]: 'Sydney (ap-southeast-2)',
  [Location.Frankfurt]: 'Frankfurt (eu-central-1)',
  [Location.Mumbai]: 'Mumbai (ap-south-1)',
  [Location.Singapore]: 'Singapore (ap-southeast-1)',
};

export const providerRegionToLocation = {
  [ProviderRegion.AWSProviderRegionUSEast1]: Location.Virginia,
  [ProviderRegion.AWSProviderRegionUSWest2]: Location.Oregon,
  [ProviderRegion.AWSProviderRegionEUCentral1]: Location.Frankfurt,
  [ProviderRegion.AWSProviderRegionEUWest1]: Location.Ireland,
  [ProviderRegion.AWSProviderRegionAPSoutheast1]: Location.Singapore,
  [ProviderRegion.AWSProviderRegionAPSoutheast2]: Location.Sydney,
  [ProviderRegion.AWSProviderRegionAPSouth1]: Location.Mumbai,
  [ProviderRegion.AWSProviderRegionUSEast2]: Location.Virginia,
  [ProviderRegion.AWSProviderRegionEUWest2]: Location.Ireland,
  [ProviderRegion.AWSProviderRegionSAEast1]: Location.SaoPaulo,
  [ProviderRegion.AzureProviderRegionEastUS2]: Location.Virginia,
  [ProviderRegion.AzureProviderRegionWestUS]: Location.Oregon,
  [ProviderRegion.AzureProviderRegionWestEurope]: Location.Frankfurt,
  [ProviderRegion.AzureProviderRegionEastAsia]: Location.Mumbai,
  [ProviderRegion.AzureProviderRegionSouthEastAsia]: Location.Singapore,
  [ProviderRegion.GCPProviderRegionUSCentral1]: Location.Virginia,
  [ProviderRegion.GCPProviderRegionUSWest1]: Location.Oregon,
  [ProviderRegion.GCPProviderRegionEuropeWest1]: Location.Frankfurt,
  [ProviderRegion.GCPProviderRegionAsiaSouth1]: Location.Mumbai,
  [ProviderRegion.GCPProviderRegionUSEast4]: Location.Virginia,
};

export const locationOptions = [
  { value: Location.Virginia, label: locationNames[Location.Virginia] },
  { value: Location.Oregon, label: locationNames[Location.Oregon] },
  { value: Location.Ireland, label: locationNames[Location.Ireland] },
  { value: Location.Sydney, label: locationNames[Location.Sydney] },
];

export const regionOptionNames = {
  [ProviderRegion.AWSProviderRegionUSEast1]: 'Virginia (us-east-1)',
  [ProviderRegion.AWSProviderRegionUSWest2]: 'Oregon (us-west-2)',
  [ProviderRegion.AWSProviderRegionEUWest1]: 'Ireland (eu-west-1)',
  [ProviderRegion.AWSProviderRegionAPSoutheast2]: 'Sydney (ap-southeast-2)',
  [ProviderRegion.AWSProviderRegionEUCentral1]: 'Frankfurt (eu-central-1)',
  [ProviderRegion.AWSProviderRegionAPSouth1]: 'Mumbai (ap-south-1)',
  [ProviderRegion.AWSProviderRegionAPSoutheast1]: 'Singapore (ap-southeast-1)',
  [ProviderRegion.AWSProviderRegionUSEast2]: 'Ohio (us-east-2)',
  [ProviderRegion.AWSProviderRegionEUWest2]: 'London (eu-west-2)',
  [ProviderRegion.AWSProviderRegionSAEast1]: 'São Paulo (sa-east-1)',
  [ProviderRegion.AzureProviderRegionEastUS2]: 'Virginia (eastus2)',
  [ProviderRegion.AzureProviderRegionWestUS]: 'California (westus)',
  [ProviderRegion.AzureProviderRegionWestEurope]: 'Netherlands (westeurope)',
  [ProviderRegion.AzureProviderRegionSouthEastAsia]: 'Singapore (southeastasia)',
  [ProviderRegion.AzureProviderRegionEastAsia]: 'Hong Kong (eastasia)',
  [ProviderRegion.GCPProviderRegionUSCentral1]: 'Iowa (us-central1)',
  [ProviderRegion.GCPProviderRegionUSWest1]: 'Oregon (us-west1)',
  [ProviderRegion.GCPProviderRegionEuropeWest1]: 'Belgium (europe-west1)',
  [ProviderRegion.GCPProviderRegionAsiaSouth1]: 'Mumbai (asia-south1)',
  [ProviderRegion.GCPProviderRegionUSEast4]: 'Virginia (us-east4)',
};

export const localLocationOptions = [
  ...locationOptions,
  { value: Location.Frankfurt, label: locationNames[Location.Frankfurt] },
  { value: Location.Mumbai, label: locationNames[Location.Mumbai] },
  { value: Location.Singapore, label: locationNames[Location.Singapore] },
];

export const awsRegionOptions: RegionOptionTypes[] = [
  { value: ProviderRegion.AWSProviderRegionUSEast1, label: regionOptionNames[ProviderRegion.AWSProviderRegionUSEast1] },
  { value: ProviderRegion.AWSProviderRegionUSWest2, label: regionOptionNames[ProviderRegion.AWSProviderRegionUSWest2] },
  { value: ProviderRegion.AWSProviderRegionEUWest1, label: regionOptionNames[ProviderRegion.AWSProviderRegionEUWest1] },
  {
    value: ProviderRegion.AWSProviderRegionAPSoutheast2,
    label: regionOptionNames[ProviderRegion.AWSProviderRegionAPSoutheast2],
  },
];

export const awsLocalRegionOptions: RegionOptionTypes[] = [
  ...awsRegionOptions,
  {
    value: ProviderRegion.AWSProviderRegionEUCentral1,
    label: regionOptionNames[ProviderRegion.AWSProviderRegionEUCentral1],
  },
  {
    value: ProviderRegion.AWSProviderRegionAPSouth1,
    label: regionOptionNames[ProviderRegion.AWSProviderRegionAPSouth1],
  },
  {
    value: ProviderRegion.AWSProviderRegionAPSoutheast1,
    label: regionOptionNames[ProviderRegion.AWSProviderRegionAPSoutheast1],
  },
  {
    value: ProviderRegion.AWSProviderRegionUSEast2,
    label: regionOptionNames[ProviderRegion.AWSProviderRegionUSEast2],
  },
  {
    value: ProviderRegion.AWSProviderRegionEUWest2,
    label: regionOptionNames[ProviderRegion.AWSProviderRegionEUWest2],
  },
  {
    value: ProviderRegion.AWSProviderRegionSAEast1,
    label: regionOptionNames[ProviderRegion.AWSProviderRegionSAEast1],
  },
];

export const azureLocalRegionOptions: RegionOptionTypes[] = [
  {
    value: ProviderRegion.AzureProviderRegionEastUS2,
    label: regionOptionNames[ProviderRegion.AzureProviderRegionEastUS2],
  },
  {
    value: ProviderRegion.AzureProviderRegionWestUS,
    label: regionOptionNames[ProviderRegion.AzureProviderRegionWestUS],
  },
  {
    value: ProviderRegion.AzureProviderRegionWestEurope,
    label: regionOptionNames[ProviderRegion.AzureProviderRegionWestEurope],
  },
  {
    value: ProviderRegion.AzureProviderRegionSouthEastAsia,
    label: regionOptionNames[ProviderRegion.AzureProviderRegionSouthEastAsia],
  },
  {
    value: ProviderRegion.AzureProviderRegionEastAsia,
    label: regionOptionNames[ProviderRegion.AzureProviderRegionEastAsia],
  },
];

export const gcpLocalRegionOptions: RegionOptionTypes[] = [
  { value: ProviderRegion.GCPProviderRegionUSCentral1, label: 'Iowa (us-central1)' },
  { value: ProviderRegion.GCPProviderRegionUSEast4, label: 'Virginia (us-east4)' },
  { value: ProviderRegion.GCPProviderRegionUSWest1, label: 'Oregon (us-west1)' },
  { value: ProviderRegion.GCPProviderRegionEuropeWest1, label: 'Belgium (europe-west1)' },
  { value: ProviderRegion.GCPProviderRegionAsiaSouth1, label: 'Mumbai (asia-south1)' },
];

export const cloudProviderOptions: CloudProviderOptionTypes[] = [
  { value: CloudProvider.AWS, label: CloudProvider.AWS.toUpperCase() },
  { value: CloudProvider.AZURE, label: CloudProvider.AZURE.toUpperCase() },
  { value: CloudProvider.GCP, label: CloudProvider.GCP.toUpperCase() },
];

export const localCloudProvidersToRegionOptions = {
  [CloudProvider.AWS]: awsLocalRegionOptions,
  [CloudProvider.AZURE]: azureLocalRegionOptions,
  [CloudProvider.GCP]: gcpLocalRegionOptions,
};

export enum CreateAppErrors {
  Forbidden = 'You do not have the required permissions to create applications for this project.',
  Generic = 'Error creating application.',
  None = '',
  SelfHosted = 'Cannot create app with atlas data source when self hosted',
}

export const localStorageDisabledErrorMessage =
  'Error accessing local storage, some features may not work as intended if local storage is disabled';

export const templatesNotAvailableErrorMessage = 'Templates are currently unavailable. Please check back later.';
