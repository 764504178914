import { JsonObject, JsonProperty } from 'json2typescript';

export enum HostingStatus {
  Ok = 'setup_ok',
  ChangeInProgress = 'change_in_progress',
  ChangeFailed = 'change_failed',
  ChangeFailedFatal = 'change_failed_fatal',
}

@JsonObject('DefaultDomain')
export class DefaultDomain {
  @JsonProperty('provider_type')
  public providerType = '';

  @JsonProperty('config')
  public config: Record<string, any> = {};

  constructor(partial?: Partial<DefaultDomain>) {
    Object.assign(this, partial);
  }
}

@JsonObject('HostingConfig')
export class HostingConfig {
  @JsonProperty('enabled')
  public enabled = false;

  @JsonProperty('status', String, true)
  public status: HostingStatus = HostingStatus.Ok;

  @JsonProperty('status_error_message', String, true)
  public statusErrorMessage?: string = undefined;

  @JsonProperty('default_domain', DefaultDomain, true)
  public defaultDomain?: DefaultDomain = undefined;

  constructor(partial?: Partial<HostingConfig>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AssetMetadata')
export class AssetMetadata {
  @JsonProperty('appId')
  public appId = '';

  @JsonProperty('path')
  public path = '';

  @JsonProperty('hash')
  public hash = '';

  @JsonProperty('size')
  public size = 0;

  @JsonProperty('attrs')
  public attributes: Array<{ name: string; value: string }> = [];

  @JsonProperty('last_modified', Number, true)
  public lastModified?: number = undefined;

  @JsonProperty('url', String, true)
  public url?: string = undefined;

  constructor(partial?: Partial<AssetMetadata>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AssetURL')
export class AssetURL {
  @JsonProperty('url', String, true)
  public url?: string = undefined;

  constructor(partial?: Partial<AssetURL>) {
    Object.assign(this, partial);
  }
}

@JsonObject('TransformAssetRequest')
export class TransformAssetRequest {
  @JsonProperty('move_from', String, true)
  public moveFrom = '';

  @JsonProperty('move_to', String, true)
  public moveTo = '';

  @JsonProperty('copy_from', String, true)
  public copyFrom = '';

  @JsonProperty('copy_to', String, true)
  public copyTo = '';

  constructor(partial?: Partial<TransformAssetRequest>) {
    Object.assign(this, partial);
  }
}
