class ResponseError extends Error {
  public code?: string;

  // TODO (BAAS-32590): Make this type optional to more accurately reflect usage
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public response: Response;

  // TODO (BAAS-32590): Make this type optional to more accurately reflect usage
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public json: ErrorResponse;

  constructor(message: string, code?: string) {
    super(message);
    // restore prototype chain: ensure that type checking different types of errors will work
    // reference: https://stackoverflow.com/questions/41102060/typescript-extending-error-class
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = this.constructor.name;
    if (code) {
      this.code = code;
    }
  }
}

class ServerError extends ResponseError {}

const ErrInvalidSession = 'InvalidSession';
const ErrInvalidCloudSession = 'InvalidCloudSession';
const ErrMissingSession = 'MissingSession';
const ErrUnauthorized = 'Unauthorized';
const ErrUIIPRestricted = 'RestrictedUIIP';

export {
  ResponseError,
  ServerError,
  ErrInvalidSession,
  ErrInvalidCloudSession,
  ErrMissingSession,
  ErrUnauthorized,
  ErrUIIPRestricted,
};

/* eslint-disable camelcase */
export interface RawErrorResponse {
  error: string;
  error_code?: string;
  link?: string;
  error_details?: Record<string, unknown>;
  admin_error_details?: Record<string, unknown>;
  admin_error?: string;
}
/* eslint-enable camelcase */

export interface ErrorResponse {
  error: string;
  errorCode?: string;
  link?: string;
  errorDetails?: Record<string, unknown>;
  adminErrorDetails?: Record<string, unknown>;
  adminError?: string;
}

export function isRawErrorResponse(json: any): json is RawErrorResponse {
  return 'error' in json;
}

export function toErrorResponse(raw: RawErrorResponse): ErrorResponse {
  return {
    error: raw.error,
    errorCode: raw.error_code,
    link: raw.link,
    errorDetails: raw.error_details,
    adminErrorDetails: raw.admin_error_details,
    adminError: raw.admin_error,
  };
}
