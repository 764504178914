import React from 'react';

import { CreateAppProps } from 'baas-ui/home/common/withCreateApp';
import ConfigFormSection from 'baas-ui/home/create-app/form/config-form-section';
import CreateDefaultAppForm from 'baas-ui/home/create-app/form/create-default-app-form';
import TemplatesFormSection from 'baas-ui/home/create-app/form/templates-form-section';
import { CreateAppFormState } from 'baas-ui/home/create-app/types';

import EmptyStateDataSourcesSelect from './empty-state-data-sources-select/EmptyStateDataSourcesSelect';

export enum TestSelector {
  DefaultSection = 'default-section',
  TemplatesSection = 'templates-section',
  ConfigSection = 'config-section',
}

interface PublicProps {
  formState: CreateAppFormState;
  onChangeDeploymentModel(): void;
  onCancelProviderRegion(): void;
  onConfirmProviderRegion(): void;
  onChangeProviderRegion(): void;
}

export type Props = CreateAppProps & PublicProps;

const CreateAppForm = ({
  formState,
  onChangeDeploymentModel,
  onCancelProviderRegion,
  onChangeProviderRegion,
  onConfirmProviderRegion,
  ...createAppProps
}: Props) => {
  const { templateId, setTemplateId } = createAppProps;

  switch (formState) {
    case CreateAppFormState.Templates:
      return (
        <TemplatesFormSection
          data-testid={TestSelector.TemplatesSection}
          {...createAppProps}
          selectedTemplate={templateId}
          setSelectedTemplate={setTemplateId}
        />
      );
    case CreateAppFormState.Config:
      return (
        <ConfigFormSection
          data-test-selector={TestSelector.ConfigSection}
          {...createAppProps}
          onChangeDeploymentModel={onChangeDeploymentModel}
          onCancelProviderRegion={onCancelProviderRegion}
          onConfirmProviderRegion={onConfirmProviderRegion}
          onChangeProviderRegion={onChangeProviderRegion}
        />
      );
    case CreateAppFormState.LinkDataSource:
      return <EmptyStateDataSourcesSelect {...createAppProps} />;
    default:
      return (
        <CreateDefaultAppForm
          data-test-selector={TestSelector.DefaultSection}
          {...createAppProps}
          onChangeDeploymentModel={onChangeDeploymentModel}
          onCancelProviderRegion={onCancelProviderRegion}
          onConfirmProviderRegion={onConfirmProviderRegion}
          onChangeProviderRegion={onChangeProviderRegion}
        />
      );
  }
};

export default CreateAppForm;
