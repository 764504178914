import React from 'react';
import Banner, { Variant as BannerVariant } from '@leafygreen-ui/banner';
import { Variant as ButtonVariant } from '@leafygreen-ui/button';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import { Body } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import { track } from 'baas-ui/tracking';

import './convert-to-advanced-mode-modal.less';

export enum TestSelector {
  Modal = 'convert-rule-modal',
  ModalBanner = 'convert-rule-modal-banner',
  ModalBody = 'convert-rule-modal-body',
}

export interface Props {
  open: boolean;
  ruleName: string;
  onConfirm(): void;
  onCancel(): void;
}

const baseClassName = 'convert-to-advanced-modal';

export default function ConvertToAdvancedModeModal({ open, ruleName, onConfirm, onCancel }: Props) {
  return (
    <ConfirmationModal
      className={baseClassName}
      variant={ButtonVariant.Primary}
      open={open}
      confirmButtonProps={{
        children: 'Convert',
        onClick: () => {
          onConfirm();
          track('RULES_CONFIGURATION.RULE_ADVANCED_MODE_CONFIRMED');
        },
      }}
      cancelButtonProps={{
        onClick: onCancel,
      }}
      title={`Convert Rule to Advanced Mode: ${ruleName}`}
      requiredInputText="convert"
      data-test-selector={TestSelector.Modal}
      data-cy="convert-to-advanced-mode-modal"
    >
      <Banner
        className={`${baseClassName}-banner`}
        variant={BannerVariant.Warning}
        data-test-selector={TestSelector.ModalBanner}
      >
        Warning! This action may be irreversible. Please read the following before continuing.
      </Banner>
      <Body className={`${baseClassName}-text`} data-test-selector={TestSelector.ModalBody}>
        You can convert roles and filters for this collection to advanced mode. This means you will be required to edit
        these roles and filters using JSON configuration, and you may not be able to switch back to basic mode without
        removing the configuration. <DocLink href={docLinks.Rules.AdvancedRules}>Learn more</DocLink>
      </Body>
    </ConfirmationModal>
  );
}
