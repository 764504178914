import { Any, JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';

import { Relationship, RelationshipMapConverter } from './Schema';

const jsonConvert: JsonConvert = new JsonConvert();

export interface Rule {
  id?: string;
}

export interface PartialRule {
  id?: string;
}

export interface Namespace {
  Database: string;
  Collections: string[];
}

export const MongoDBRuleRelationship = Relationship;

@JsonObject('MongoDBRulePermissions')
export class MongoDBRulePermissions {
  @JsonProperty('write', Any, true)
  public write?: any = undefined;

  @JsonProperty('read', Any, true)
  public read?: any = undefined;

  constructor(partial?: Partial<MongoDBRulePermissions>) {
    Object.assign(this, partial);
  }
}

@JsonObject('MongoDBRuleField')
export class MongoDBRuleField {
  @JsonProperty('fields', Any, true)
  public fields?: Record<string, MongoDBRuleField> = undefined;

  @JsonProperty('read', Any, true)
  public read?: any = undefined;

  @JsonProperty('write', Any, true)
  public write?: any = undefined;

  @JsonProperty('additional_fields', MongoDBRulePermissions, true)
  public additionalFields?: MongoDBRulePermissions = undefined;

  constructor(partial?: Partial<MongoDBRuleField>) {
    Object.assign(this, partial);
  }
}

const serializeMongoDBRuleField = (ruleField: MongoDBRuleField): any => {
  let ruleFieldData = jsonConvert.serializeObject(ruleField, MongoDBRuleField);
  if (ruleField.fields) {
    const fields = Object.entries(ruleField.fields).reduce(
      (fieldMap, [key, fieldData]) => ({ ...fieldMap, [key]: serializeMongoDBRuleField(fieldData) }),
      {}
    );
    ruleFieldData = { ...ruleFieldData, fields };
  }
  return ruleFieldData;
};

const deserializeMongoDBRuleField = (data: any): MongoDBRuleField => {
  let ruleField = jsonConvert.deserializeObject(data, MongoDBRuleField);
  if ('fields' in data) {
    const fields = Object.entries(data.fields).reduce(
      (fieldMap, [key, fieldData]) => ({ ...fieldMap, [key]: deserializeMongoDBRuleField(fieldData) }),
      {}
    );
    ruleField = new MongoDBRuleField({ ...ruleField, fields });
  }
  return ruleField;
};

/* eslint-disable class-methods-use-this */

// A custom map converter is needed for MongoDBRuleFieldMap since a
// MongoDBRuleField can recursively contain a map of MongoDBRuleFields
@JsonConverter
class MongoDBRuleFieldMapConverter implements JsonCustomConvert<Record<string, MongoDBRuleField>> {
  public serialize(fieldMap: Record<string, MongoDBRuleField>): any {
    return Object.entries(fieldMap).reduce(
      (dataMap, [key, fieldRule]) => ({
        ...dataMap,
        [key]: serializeMongoDBRuleField(fieldRule),
      }),
      {}
    );
  }

  public deserialize(data: any): Record<string, MongoDBRuleField> {
    return Object.entries(data).reduce(
      (fieldMap, [key, fieldData]) => ({
        ...fieldMap,
        [key]: deserializeMongoDBRuleField(fieldData),
      }),
      {}
    );
  }
}
/* eslint-enable class-methods-use-this */

@JsonObject('MongoDBRuleRole')
export class MongoDBRuleRole {
  @JsonProperty('name')
  public name = '';

  @JsonProperty('apply_when', Any, true)
  public applyWhen?: Record<string, any> = {};

  @JsonProperty('document_filters', MongoDBRulePermissions, true)
  public documentFilters?: MongoDBRulePermissions = undefined;

  @JsonProperty('fields', MongoDBRuleFieldMapConverter, true)
  public fields?: Record<string, MongoDBRuleField> = undefined;

  @JsonProperty('read', Any, true)
  public read?: any = undefined;

  @JsonProperty('write', Any, true)
  public write?: any = undefined;

  @JsonProperty('insert', Any, true)
  public insert?: any = undefined;

  @JsonProperty('delete', Any, true)
  public delete?: any = undefined;

  @JsonProperty('search', Any, true)
  public search?: any = undefined;

  @JsonProperty('additional_fields', MongoDBRulePermissions, true)
  public additionalFields?: MongoDBRulePermissions = undefined;

  constructor(partial?: Partial<MongoDBRuleRole>) {
    Object.assign(this, partial);
  }
}

@JsonObject('MongoDBRuleFilter')
export class MongoDBRuleFilter {
  @JsonProperty('name')
  public name = '';

  @JsonProperty('query')
  public query: Record<string, any> = {};

  @JsonProperty('projection', Any, true)
  public projection?: Record<string, any> = undefined;

  @JsonProperty('apply_when')
  public applyWhen: Record<string, any> = {};

  constructor(partial?: Partial<MongoDBRuleFilter>) {
    Object.assign(this, partial);
  }
}

@JsonObject('MongoDBBaseRule')
export class MongoDBBaseRule implements Rule {
  @JsonProperty('_id', String, true)
  public id?: string = undefined;

  @JsonProperty('filters', [MongoDBRuleFilter], true)
  public filters?: MongoDBRuleFilter[] = undefined;

  @JsonProperty('roles', [MongoDBRuleRole], true)
  public roles?: MongoDBRuleRole[] = undefined;

  constructor(partial?: Partial<MongoDBBaseRule>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PresetRole')
export class PresetRole extends MongoDBBaseRule {
  @JsonProperty('name')
  public name = '';

  @JsonProperty('roles', [MongoDBRuleRole], true)
  public roles: MongoDBRuleRole[] = [];

  constructor(partial?: Partial<PresetRole>) {
    super(partial);
    Object.assign(this, partial);
  }
}

@JsonObject('MongoDBNamespaceRule')
export class MongoDBNamespaceRule extends MongoDBBaseRule {
  @JsonProperty('database')
  public database = '';

  @JsonProperty('collection')
  public collection = '';

  @JsonProperty('schema', Any, true)
  public schema?: any = undefined;

  @JsonProperty('relationships', RelationshipMapConverter, true)
  public relationships?: Record<string, Relationship> = undefined;

  constructor(partial?: Partial<MongoDBNamespaceRule>) {
    super(partial);
    Object.assign(this, partial);
  }
}

@JsonObject('PartialMongoDBNamespaceRule')
export class PartialMongoDBNamespaceRule implements Rule {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('database')
  public database = '';

  @JsonProperty('collection')
  public collection = '';

  constructor(partial?: Partial<PartialMongoDBNamespaceRule>) {
    Object.assign(this, partial);
  }
}

@JsonObject('BuiltinRule')
export class BuiltinRule implements Rule {
  @JsonProperty('_id', String, true)
  public id?: string = undefined;

  @JsonProperty('name')
  public name = '';

  @JsonProperty('actions', [String])
  public actions: string[] = [];

  @JsonProperty('when', Any, true)
  public when?: Record<string, any> = undefined;

  constructor(partial?: Partial<BuiltinRule>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PartialBuiltinRule')
export class PartialBuiltinRule implements Rule {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('name')
  public name = '';

  constructor(partial?: Partial<BuiltinRule>) {
    Object.assign(this, partial);
  }
}

export const deserializeBaseRule = (data: any) => {
  return jsonConvert.deserializeObject(data, MongoDBBaseRule);
};

export const isRuleMongoDBNamespace = (rule: BuiltinRule | MongoDBNamespaceRule): rule is MongoDBNamespaceRule =>
  'database' in rule;

export const isRuleBuiltin = (rule: BuiltinRule | MongoDBNamespaceRule): rule is BuiltinRule => !('database' in rule);

export const isRulePartialMongoDBNamespace = (
  rule: PartialBuiltinRule | PartialMongoDBNamespaceRule | MongoDBBaseRule
): rule is PartialMongoDBNamespaceRule => 'database' in rule;

export const isRulePartialBuiltin = (
  rule: PartialBuiltinRule | PartialMongoDBNamespaceRule | MongoDBBaseRule
): rule is PartialBuiltinRule => !('database' in rule);

export const deserializeRule = (data: any) => {
  if (isRuleMongoDBNamespace(data)) {
    return jsonConvert.deserializeObject(data, MongoDBNamespaceRule);
  }
  if (isRuleBuiltin(data)) {
    return jsonConvert.deserializeObject(data, BuiltinRule);
  }
  throw new Error('unable to deserialize rule');
};

export const serializeRule = (data: MongoDBNamespaceRule | BuiltinRule) => {
  if (isRuleMongoDBNamespace(data)) {
    return jsonConvert.serializeObject(data, MongoDBNamespaceRule);
  }
  if (isRuleBuiltin(data)) {
    return jsonConvert.serializeObject(data, BuiltinRule);
  }
  throw new Error('unable to serialize rule');
};

export const deserializePartialRule = (data: any): PartialMongoDBNamespaceRule | PartialBuiltinRule => {
  if (isRuleMongoDBNamespace(data)) {
    return jsonConvert.deserializeObject(data, PartialMongoDBNamespaceRule);
  }
  if (isRuleBuiltin(data)) {
    return jsonConvert.deserializeObject(data, PartialBuiltinRule);
  }
  throw new Error('unable to deserialize partial rule');
};

/* eslint-disable class-methods-use-this */
@JsonConverter
export class RulesConverter implements JsonCustomConvert<Rule[]> {
  public serialize(rules: Rule[]): any {
    return jsonConvert.serializeArray(rules);
  }

  public deserialize(rulesData: any): Rule[] {
    if (!(rulesData instanceof Array)) {
      throw new Error('expected rules to be an array');
    }
    return Object.values(rulesData).map(deserializeRule);
  }
}
/* eslint-enable class-methods-use-this */
@JsonObject('MongoDBNamespace')
export class MongoDBNamespace implements Namespace {
  @JsonProperty('Database')
  public Database = '';

  @JsonProperty('Collections')
  public Collections: string[] = [];

  constructor(partial?: Partial<MongoDBNamespace>) {
    Object.assign(this, partial);
  }
}
@JsonObject('MongoDBSyncIncompatibilityError')
export class MongoDBSyncIncompatibilityError {
  @JsonProperty('key')
  public key = '';

  @JsonProperty('error_messages')
  public errorMessages: string[] = [];

  constructor(partial?: Partial<MongoDBSyncIncompatibilityError>) {
    Object.assign(this, partial);
  }
}

@JsonObject('MongoDBSyncIncompatibleRole')
export class MongoDBSyncIncompatibleRole {
  @JsonProperty('name')
  public name = '';

  @JsonProperty('errors', [MongoDBSyncIncompatibilityError])
  public errors: MongoDBSyncIncompatibilityError[] = [];

  constructor(partial?: Partial<MongoDBSyncIncompatibleRole>) {
    Object.assign(this, partial);
  }
}

@JsonObject('MongoDBSyncIncompatibleNamespaceRole')
export class MongoDBSyncIncompatibleNamespaceRole extends MongoDBSyncIncompatibleRole {
  @JsonProperty('database')
  public database = '';

  @JsonProperty('collection')
  public collection = '';

  @JsonProperty('exists_in_schema')
  public existsInSchema = false;

  constructor(partial?: Partial<MongoDBSyncIncompatibleNamespaceRole>) {
    super(partial);
    Object.assign(this, partial);
  }
}

@JsonObject('MongoDBSyncIncompatibleRoles')
export class MongoDBSyncIncompatibleRoles {
  @JsonProperty('namespace_roles', [MongoDBSyncIncompatibleNamespaceRole], true)
  public namespaceRoles?: MongoDBSyncIncompatibleNamespaceRole[] = undefined;

  @JsonProperty('default_roles', [MongoDBSyncIncompatibleRole], true)
  public defaultRoles?: MongoDBSyncIncompatibleRole[] = undefined;

  @JsonProperty('namespaces_using_default_rule', Any, true)
  public namespacesUsingDefaultRule?: Record<string, string[]> = undefined;

  @JsonProperty('flexible_sync_enabled')
  public flexibleSyncEnabled = true;

  constructor(partial?: Partial<MongoDBSyncIncompatibleRoles>) {
    Object.assign(this, partial);
  }
}

export const deserializeMongoDBSyncIncompatibleRoles = (data: any) => {
  return jsonConvert.deserializeObject(data, MongoDBSyncIncompatibleRoles);
};
