import React, { useContext, useState } from 'react';
import { Menu, MenuItem } from '@leafygreen-ui/menu';
import classNames from 'classnames';

import { DataSourceMenuProps } from 'baas-ui/common/components/collection-explorer/collection-explorer/CollectionExplorer';
import { RulesPageContext } from 'baas-ui/rules/RulesPage';

export enum TestSelector {
  CreateCollection = 'create-collection',
  DeleteAllRules = 'delete-all-rules',
}

export default function DataSourceActionMenu({
  trigger,
  dataSourceId,
  dataSourceName,
  hasCollectionData,
}: DataSourceMenuProps) {
  const [open, setOpen] = useState(false);
  const { onClickCreateCollection, onClickDeleteAction, deletingDatasources } = useContext(RulesPageContext);

  const isDeletingOwnRules = deletingDatasources.size > 0 && deletingDatasources.has(dataSourceId);
  const isDisabled = isDeletingOwnRules || !hasCollectionData;

  return (
    <Menu open={open} setOpen={setOpen} trigger={trigger}>
      <MenuItem
        data-test-selector={TestSelector.CreateCollection}
        data-testid={TestSelector.CreateCollection}
        data-cy="datasource-menu-create-collection"
        disabled={isDeletingOwnRules}
        onClick={() => {
          if (!isDeletingOwnRules) {
            onClickCreateCollection(dataSourceName);
          }
        }}
      >
        Create a collection
      </MenuItem>
      <MenuItem
        className={classNames({
          'collection-explorer__menu-item--destructive': !isDisabled,
          'collection-explorer__menu-item--disabled': isDisabled,
        })}
        data-test-selector={TestSelector.DeleteAllRules}
        data-testid={TestSelector.DeleteAllRules}
        data-cy="datasource-menu-delete-rules"
        disabled={isDisabled}
        onClick={() => {
          if (!isDisabled) {
            onClickDeleteAction({
              dataSourceName,
              dataSourceId,
            });
          }
        }}
      >
        Delete roles and filters from all collections
      </MenuItem>
    </Menu>
  );
}
