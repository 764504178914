import React from 'react';
import Banner, { Variant } from '@leafygreen-ui/banner';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import TextInput from '@leafygreen-ui/text-input';
import { Body } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';

import './confirm-enable-modal.less';

const baseClassName = 'confirm-enable-modal';

export enum TestSelector {
  ClusterStorageBanner = 'cluster-storage-banner',
  NoPermissionsInput = 'no-permissions-input',
  NoPermissionsConfirmationText = 'no-permissions-confirmation-text',
  ConfirmEnableText = 'confirm-enable-text',
}

export interface Props {
  open: boolean;
  onClickConfirm(): void;
  onCancel(): void;
  clusterName: string;
  hasPermissionsSet: boolean;
}

const ConfirmEnableModal = ({ open, onClickConfirm, onCancel, clusterName, hasPermissionsSet }: Props) => {
  const [confirmsNoPermissions, setConfirmsNoPermissions] = React.useState(false);

  const onDenyAccessChange = (input: string) => {
    setConfirmsNoPermissions(input === 'Deny all access');
  };

  const onClose = () => {
    onCancel();
    setConfirmsNoPermissions(false);
  };

  return (
    <ConfirmationModal
      open={open}
      confirmButtonProps={{
        children: 'Enable Sync',
        disabled: !(hasPermissionsSet || confirmsNoPermissions),
        onClick: onClickConfirm,
      }}
      cancelButtonProps={{
        onClick: onClose,
      }}
      title={
        hasPermissionsSet
          ? 'Confirm: Enabling Sync'
          : 'Are you sure you want to enable Sync without writing permissions?'
      }
      className={`${baseClassName}`}
      data-cy="confirm-enable-modal-component"
    >
      {!hasPermissionsSet && (
        <div className={`${baseClassName}-permissions`}>
          <Body
            className={`${baseClassName}-body`}
            data-test-selector={TestSelector.NoPermissionsConfirmationText}
            data-cy={TestSelector.NoPermissionsConfirmationText}
          >
            We noticed that you haven&apos;t written any read/write permissions. Leaving your permissions JSON editor
            blank will deny all access to your application by default.
          </Body>
          <TextInput
            label={`Type "Deny all access" to confirm your action`}
            onChange={(e) => onDenyAccessChange(e.target.value)}
            data-test-selector={TestSelector.NoPermissionsInput}
            className={`${baseClassName}-input`}
            data-cy={TestSelector.NoPermissionsInput}
          />
        </div>
      )}
      {(hasPermissionsSet || confirmsNoPermissions) && (
        <div
          className={
            confirmsNoPermissions ? `${baseClassName}-enable-info-no-permissions` : `${baseClassName}-enable-info`
          }
          data-test-selector={TestSelector.ConfirmEnableText}
          data-cy={TestSelector.ConfirmEnableText}
        >
          <Body className={`${baseClassName}-body`}>
            Enabling sync will create a copy of all data that matches a JSON schema in your selected cluster{' '}
            {clusterName}. Learn more about{' '}
            <DocLink href={docLinks.Sync.LearnMore} showExternalIcon>
              enabling Sync
            </DocLink>
          </Body>
          <Banner variant={Variant.Warning} data-test-selector={TestSelector.ClusterStorageBanner}>
            <Body weight="medium" className={`${baseClassName}-banner-text`}>
              Check your cluster&apos;s storage before proceeding.
            </Body>
            <Body className={`${baseClassName}-banner-text`}>
              Before enabling Sync please ensure that your cluster is using less than 50% of its available storage or
              enable auto-scale for your cluster&apos;s storage. Learn more about{' '}
              <DocLink href={docLinks.Atlas.AutoScaling} showExternalIcon>
                auto-scale
              </DocLink>
            </Body>
          </Banner>
        </div>
      )}
    </ConfirmationModal>
  );
};

export default ConfirmEnableModal;
