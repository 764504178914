import { createAction } from 'redux-act';

import { handleResourceNotFound } from 'baas-ui/action_wrappers';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import { IncomingWebhook, PartialIncomingWebhook } from 'admin-sdk';

import { AuthenticationMethod } from './constants';

const NAME = 'incomingWebhooks/';

interface svcIncomingWebhooksPayload extends BaseRequestPayload {
  svcId: string;
}
interface incomingWebhookPayload extends BaseRequestPayload {
  svcId: string;
  incomingWebhookId: string;
}
interface createIncomingWebhookPayload extends BaseRequestPayload {
  svcId: string;
  data: IncomingWebhook;
}
interface updateIncomingWebhookPayload extends BaseRequestPayload {
  svcId: string;
  incomingWebhookId: string;
  data: IncomingWebhook;
}
type editIncomingWebhookPayload = Partial<IncomingWebhook> & {
  authMethod?: AuthenticationMethod;
  canEvaluateSource?: string;
};
interface editorError {
  error: string;
}

// api actions and action types

export const [loadIncomingWebhooksActions, loadIncomingWebhooks] = createActionsAndExecutor<
  svcIncomingWebhooksPayload,
  PartialIncomingWebhook[]
>(
  `${NAME}load`,
  (client, { groupId, appId, svcId }) =>
    () =>
      client.apps(groupId).app(appId).services().service(svcId).incomingWebhooks().list()
);

export const [fetchIncomingWebhookActions, fetchIncomingWebhookInner] = createActionsAndExecutor<
  incomingWebhookPayload,
  IncomingWebhook
>(
  `${NAME}fetch`,
  (client, { groupId, appId, svcId, incomingWebhookId }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .services()
        .service(svcId)
        .incomingWebhooks()
        .incomingWebhook(incomingWebhookId)
        .get()
);
export const fetchIncomingWebhook = handleResourceNotFound(fetchIncomingWebhookInner);

export const [createIncomingWebhookActions, createIncomingWebhook] = createActionsAndExecutor<
  createIncomingWebhookPayload,
  PartialIncomingWebhook
>(
  `${NAME}create`,
  (client, { groupId, appId, svcId, data }) =>
    () =>
      client.apps(groupId).app(appId).services().service(svcId).incomingWebhooks().create(data)
);

export const [updateIncomingWebhookActions, updateIncomingWebhook] = createActionsAndExecutor<
  updateIncomingWebhookPayload,
  void
>(
  `${NAME}update`,
  (client, { groupId, appId, svcId, incomingWebhookId, data }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .services()
        .service(svcId)
        .incomingWebhooks()
        .incomingWebhook(incomingWebhookId)
        .update(data)
);

export const [removeIncomingWebhookActions, removeIncomingWebhook] = createActionsAndExecutor<
  incomingWebhookPayload,
  void
>(
  `${NAME}remove`,
  (client, { groupId, appId, svcId, incomingWebhookId }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .services()
        .service(svcId)
        .incomingWebhooks()
        .incomingWebhook(incomingWebhookId)
        .remove()
);

// These actions cause the drafts banner to appear
export const asyncEditRcvActions = [
  createIncomingWebhookActions.rcv,
  updateIncomingWebhookActions.rcv,
  removeIncomingWebhookActions.rcv,
];

// editor action types
export const editNewWebhook = createAction<IncomingWebhook>(`${NAME}edit new webhook`);
export const discardChanges = createAction(`${NAME}discard changes`);
export const finishEditingWebhook = createAction(`${NAME}finish editing webhook`);
export const setError = createAction<editorError>(`${NAME}set error`);
export const updateEditor = createAction<editIncomingWebhookPayload>(`${NAME}update editor`);
