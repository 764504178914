import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { passThroughProps } from 'baas-ui/common/utils/util';

import './form-row.less';

export interface Props {
  alignTop?: boolean;
  children: React.ReactNode;
  compact?: boolean;
  wide?: boolean;
  className?: string;
}

export enum TestSelector {
  FormRowLabelGroupContainer = 'form-row-label-group-container',
}

const FormRowLabelGroup = ({
  alignTop = false,
  children,
  compact = false,
  wide = false,
  className,
  ...rest
}: Props) => (
  <div
    data-testid={TestSelector.FormRowLabelGroupContainer}
    className={classNames('form-row-label-group', className, {
      'form-row-label-group-is-compact': compact,
      'form-row-label-group-is-wide': wide,
      'form-row-label-group-is-aligned-top': alignTop,
    })}
    {...passThroughProps(rest)}
  >
    {children}
  </div>
);

FormRowLabelGroup.propTypes = {
  children: PropTypes.node,
  compact: PropTypes.bool,
  wide: PropTypes.bool,
  alignTop: PropTypes.bool,
};

export default FormRowLabelGroup;
