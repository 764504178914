import React from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import Icon, { Size } from '@leafygreen-ui/icon';

import { StatusBanner, Variant } from 'baas-ui/common/components/status';
import usePoller from 'baas-ui/common/hooks/use-poller';
import { AsyncDispatch, AsyncDispatchPayload } from 'baas-ui/redux_util';
import { getAppState, getSyncState } from 'baas-ui/selectors';
import { loadSyncMigrationStatus } from 'baas-ui/sync/actions';
import { STATUS_TYPE_SYNC_MIGRATION } from 'baas-ui/sync/constants';
import { triggerSyncMigrationAction } from 'baas-ui/sync/sagas';
import { RootState } from 'baas-ui/types';
import { SyncMigrationAction, SyncMigrationStatus } from 'admin-sdk';

interface ReduxStateProps {
  doPolling: boolean;
  migrationStatus?: SyncMigrationStatus;
}

interface ReduxDispatchProps {
  loadMigrationStatus(): AsyncDispatchPayload<SyncMigrationStatus>;
  cancelMigration(): AsyncDispatchPayload<void>;
}

export type Props = ReduxStateProps & ReduxDispatchProps;

const BannerBody = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.leafygreen.spacing[2],
}));

export enum TestSelector {
  Banner = 'sync-migration-banner',
  WarningIcon = 'sync-migration-warning-icon',
  LoadingSpinner = 'sync-migration-banner-spinner',
  Message = 'sync-migration-banner-message',
  CancelButton = 'sync-migration-cancel-button',
}

export const SyncMigrationBanner = ({ doPolling, migrationStatus, loadMigrationStatus, cancelMigration }: Props) => {
  const poller = usePoller('syncMigrationStatusPoller');

  React.useEffect(() => {
    loadMigrationStatus();
  }, []);

  React.useEffect(() => {
    if (doPolling) {
      poller.start(loadMigrationStatus, 3e3);
    }

    return () => poller.stop();
  }, [doPolling]);

  if (!migrationStatus || (!migrationStatus.statusMessage && !migrationStatus.errorMessage)) {
    return null;
  }

  const bannerBody = migrationStatus.errorMessage ? (
    <BannerBody>
      <Icon glyph="Warning" size={Size.Small} data-testid={TestSelector.WarningIcon} />
      <div data-testid={TestSelector.Message}>
        <div>{migrationStatus.errorMessage}</div>
        <div>Please try the migration process again</div>
      </div>
    </BannerBody>
  ) : (
    <BannerBody>
      <div>
        <FontAwesome name="circle-o-notch" spin data-testid={TestSelector.LoadingSpinner} />
      </div>
      <div data-testid={TestSelector.Message}>
        <div>{migrationStatus.statusMessage}</div>
        {migrationStatus.isCancelable && (
          <div>
            No changes can be saved until migration is complete.{'  '}
            <span
              onClick={() => cancelMigration()}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              data-testid={TestSelector.CancelButton}
              data-cy={TestSelector.CancelButton}
            >
              Stop Migration
            </span>
          </div>
        )}
      </div>
    </BannerBody>
  );

  return (
    <StatusBanner
      data-testid={TestSelector.Banner}
      data-cy={TestSelector.Banner}
      message={bannerBody}
      variant={migrationStatus.errorMessage ? Variant.Error : Variant.Info}
      statusType={STATUS_TYPE_SYNC_MIGRATION}
      statusId="sync-migration-status-banner"
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    app: { id: appId, groupId },
  } = getAppState(state);
  const {
    config: { serviceId },
    migration: { pollCount, status },
  } = getSyncState(state);

  return {
    groupId,
    appId,
    serviceId: serviceId || '',
    doPolling: pollCount > 0,
    migrationStatus: status,
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  loadMigrationStatus: (groupId: string, appId: string) => () =>
    dispatch(loadSyncMigrationStatus({ groupId, appId })).catch(() => {}),
  cancelMigration: (groupId: string, appId: string, serviceId: string) => () =>
    dispatch(triggerSyncMigrationAction(groupId, appId, serviceId, SyncMigrationAction.Cancel)),
});

const mergeProps = (
  { groupId, appId, serviceId, ...otherStateProps }: ReturnType<typeof mapStateToProps>,
  { loadMigrationStatus, cancelMigration }: ReturnType<typeof mapDispatchToProps>
): Props => ({
  ...otherStateProps,
  loadMigrationStatus: loadMigrationStatus(groupId, appId),
  cancelMigration: cancelMigration(groupId, appId, serviceId),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SyncMigrationBanner);
