import { createAction } from 'redux-act';

import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import { MessageState, PushNotification, SendNotificationRequest } from 'admin-sdk';

const NAME = 'push/';

interface SendMessagePayload extends BaseRequestPayload {
  messageId: string;
}

interface CreateMessagePayload extends BaseRequestPayload {
  message: SendNotificationRequest;
}

interface SaveMessagePayload extends BaseRequestPayload {
  message: SendNotificationRequest;
  messageId: string;
}

interface LoadMessagePayload extends BaseRequestPayload {
  state: MessageState;
}

type DeleteMessagePayload = SendMessagePayload;

export const setNewMessageError = createAction<{ error?: string } | undefined>(`${NAME}set msg error`);

export const [sendPushNotificationActions, sendPushNotification] = createActionsAndExecutor<SendMessagePayload, void>(
  `${NAME}send push notification`,
  (client, { groupId, appId, messageId }) =>
    () =>
      client.apps(groupId).app(appId).pushNotifications().pushNotification(messageId).send()
);

export const [createMessageActions, createMessage] = createActionsAndExecutor<CreateMessagePayload, PushNotification>(
  `${NAME}create message`,
  (client, { groupId, appId, message }) =>
    () =>
      client.apps(groupId).app(appId).pushNotifications().create(new SendNotificationRequest(message))
);

export const [saveMessageActions, saveMessage] = createActionsAndExecutor<SaveMessagePayload, void>(
  `${NAME}save message`,
  (client, { groupId, appId, messageId, message }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .pushNotifications()
        .pushNotification(messageId)
        .update(new SendNotificationRequest(message))
);

export const [deleteMessageActions, deleteMessage] = createActionsAndExecutor<DeleteMessagePayload, void>(
  `${NAME}delete message`,
  (client, { groupId, appId, messageId }) =>
    () =>
      client.apps(groupId).app(appId).pushNotifications().pushNotification(messageId).remove()
);

export const [loadMessagesActions, loadMessages] = createActionsAndExecutor<LoadMessagePayload, PushNotification[]>(
  `${NAME}load messages`,
  (client, { groupId, appId, state }) =>
    () =>
      client.apps(groupId).app(appId).pushNotifications().list(state)
);

export const asyncEditRcvActions = [createMessageActions.rcv, saveMessageActions.rcv, deleteMessageActions.rcv];
