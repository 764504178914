import { createReducer } from 'redux-act';

import { handleDomainRedirect, rejectDomainRedirect } from './actions';
import { Domain } from './types';

export type DomainRedirectProviderState = {
  shouldHandleDomainRedirect: boolean;
  rejectedDomainRedirect: boolean;
  oldDomain?: Domain;
};

const defaultDomainRedirectModalState: DomainRedirectProviderState = {
  shouldHandleDomainRedirect: false,
  rejectedDomainRedirect: false,
};

const domainRedirectProviderReducer = createReducer({}, defaultDomainRedirectModalState);

domainRedirectProviderReducer.on(handleDomainRedirect, (state, { oldDomain }) => ({
  ...state,
  shouldHandleDomainRedirect: true,
  oldDomain,
}));

domainRedirectProviderReducer.on(rejectDomainRedirect, (state) => ({
  ...state,
  shouldHandleDomainRedirect: false,
  rejectedDomainRedirect: true,
}));

export default domainRedirectProviderReducer;
