import React from 'react';
import { connect } from 'react-redux';

import { StatusBanner, Variant } from 'baas-ui/common/components/status';
import usePoller from 'baas-ui/common/hooks/use-poller';
import { AsyncDispatch, AsyncExecutorPayload } from 'baas-ui/redux_util';
import { getAppState, getSyncState } from 'baas-ui/selectors';
import * as actions from 'baas-ui/sync/actions';
import { STATUS_TYPE_SYNC_INIT } from 'baas-ui/sync/constants';
import { RootState } from 'baas-ui/types';
import { SyncMigrationStatus, SyncProgress } from 'admin-sdk';

export interface CombinedProgress {
  completed: number;
  total: number;
  errors: string[];
  hasProgress: boolean;
  allComplete: boolean;
}

export function combineSyncProgress(syncProgress: SyncProgress): CombinedProgress {
  return Object.entries(syncProgress.progress).reduce(
    (prev: CombinedProgress, [namespace, { error, documentsCompleted = 0, totalDocuments = 0, complete }]) => ({
      completed: prev.completed + documentsCompleted,
      total: prev.total + totalDocuments,
      errors: error ? prev.errors.concat(`${namespace}: ${error}`) : prev.errors,
      hasProgress: true,
      allComplete: prev.allComplete && !!complete,
    }),
    {
      completed: 0,
      total: 0,
      errors: [],
      hasProgress: false,
      allComplete: true,
    }
  );
}

interface StateProps {
  pollSyncProgress: boolean;
  syncProgress: SyncProgress;
  syncMigrationStatus?: SyncMigrationStatus;
}

interface DispatchProps {
  onLoadSyncProgress(): AsyncExecutorPayload<typeof actions.loadProgress>;
  onDismissSyncProgress(): void;
}

export type Props = StateProps & DispatchProps;

export enum TestSelector {
  SyncStatusProgressErrorMessage = 'sync-status-progress-error-message',
}

export function InitialSyncStatusBannerComponent({
  pollSyncProgress,
  syncProgress,
  syncMigrationStatus,
  onDismissSyncProgress,
  onLoadSyncProgress,
}: Props) {
  const poller = usePoller();

  React.useEffect(() => {
    onLoadSyncProgress();
  }, []);

  React.useEffect(() => {
    if (pollSyncProgress) {
      poller.start(onLoadSyncProgress, 3e3);
    }

    return () => poller.stop();
  }, [pollSyncProgress]);

  // If a sync migration is in progress, don't show this banner since the information will already be captured in the
  // sync migration banner
  if (syncMigrationStatus && syncMigrationStatus.statusMessage) {
    return null;
  }

  const combinedProgress = combineSyncProgress(syncProgress);
  const hasErr = combinedProgress.errors.length > 0;

  if (!combinedProgress.hasProgress || (combinedProgress.allComplete && !hasErr)) {
    return null;
  }

  const progressMsg = (
    <div>
      <b>Enabling Sync</b>
      {combinedProgress.total
        ? `... approximately ${combinedProgress.completed}/${combinedProgress.total} (${(
            100 *
            (combinedProgress.completed / combinedProgress.total)
          ).toFixed(2)}%) documents copied`
        : '... copying data'}
      {hasErr && (
        <div data-test-selector={TestSelector.SyncStatusProgressErrorMessage}>
          Encountered temporary error while enabling sync and will automatically resume copying data shortly.
        </div>
      )}
    </div>
  );

  return (
    <StatusBanner
      message={progressMsg}
      variant={hasErr ? Variant.Error : Variant.Info}
      statusType={STATUS_TYPE_SYNC_INIT}
      statusId="sync-init-status-banner"
      clearable
      onClear={onDismissSyncProgress}
    />
  );
}

function mapStateToProps(state: RootState) {
  const {
    app: { id: appId, groupId },
  } = getAppState(state);
  const {
    syncProgress: { pollCount, progress },
    migration: { status },
  } = getSyncState(state);

  return {
    appId,
    groupId,
    pollSyncProgress: pollCount > 0,
    syncProgress: progress,
    syncMigrationStatus: status,
  };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
  return {
    onLoadSyncProgress: (groupId: string, appId: string) => () =>
      dispatch(actions.loadProgress({ groupId, appId })).catch(() => {}),
    onDismissSyncProgress: () => dispatch(actions.dismissProgress()),
  };
}

function mergeProps(
  { appId, groupId, ...otherStateProps }: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>
): Props {
  return {
    ...otherStateProps,
    onLoadSyncProgress: dispatchProps.onLoadSyncProgress(groupId, appId),
    onDismissSyncProgress: () => dispatchProps.onDismissSyncProgress(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InitialSyncStatusBannerComponent);
