import React, { useState } from 'react';
import Badge, { Variant as BadgeVariant } from '@leafygreen-ui/badge';
import Banner, { Variant as BannerVariant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import { css, cx } from '@leafygreen-ui/emotion';
import Icon from '@leafygreen-ui/icon';
import Modal from '@leafygreen-ui/modal';
import {
  Cell,
  ColumnDef,
  flexRender,
  HeaderCell,
  HeaderGroup,
  HeaderRow,
  LeafyGreenTableCell,
  LeafyGreenTableRow,
  Row,
  Table,
  TableBody,
  TableHead,
  useLeafyGreenTable,
} from '@leafygreen-ui/table';
import { BaseFontSize } from '@leafygreen-ui/tokens';
import { Body, H3, Link } from '@leafygreen-ui/typography';

import { docLinks } from 'baas-ui/common/links';
import { ZIndex } from 'baas-ui/common/styles/zIndex';
import { ErrorCode } from 'baas-ui/constants';
import { SchemaModificationType, SyncNoteType, SyncSchemaChange } from 'baas-ui/sync/types';
import { sortSchemaChanges } from 'baas-ui/sync/util';
import theme from 'baas-ui/theme';

const styles = {
  modal: css`
    z-index: ${ZIndex.Modal};
  `,
  header: css`
    margin-bottom: 24px;
  `,
  banner: css`
    margin-bottom: 24px;
  `,
  schemaVersionSection: css`
    margin-bottom: 24px;
  `,
  tableContainer: css`
    display: flex;
    max-height: 500px;
  `,
  table: css`
    float: left;
  `,
  firstRow: css`
    height: 44px;
  `,
  row: css`
    vertical-align: text-top;
  `,
  body: css`
    margin-top: 15px;
  `,
  footer: css`
    margin-top: 24px;
    display: flex;
    float: right;
  `,
  footerCancel: css`
    margin-right: 20px;
  `,
  warnNote: css``,
  iconWrapper: css`
    float: left;
    max-height: 16px;
    margin-right: 2px;
  `,
  noteWrapper: css``,
  tableCell: css`
    overflow-wrap: anywhere;
  `,
};

export enum TestSelector {
  ModalSelector = 'schema-change-modal',
  TableSelector = 'schema-change-modal-table',
  ConfirmButton = 'schema-change-modal-confirm-button',
  BadgeSelector = 'schema-change-modal-badge',
  BannerSelector = 'schema-change-modal-banner',
  SchemaVersionSection = 'schema-change-modal-schema-version-section',
  NoteWarnIcon = 'schema-change-modal-note-icon',
}

export interface Props {
  schemaChanges: SyncSchemaChange[];
  schemaChangeType: SchemaModificationType;
  latestSchemaVersionFunc(): Promise<number>;
  errorCode: ErrorCode;
  isOpen: boolean;
  onConfirm(): void;
  onCancel(): void;
}

export const SchemaChangeModal = ({
  schemaChanges,
  schemaChangeType,
  latestSchemaVersionFunc,
  errorCode,
  onConfirm,
  onCancel,
  isOpen,
}: Props) => {
  const [latestSchemaVersion, setLatestSchemaVersion] = useState(0);
  const [isLoadingSchemaVersions, setIsLoadingSchemaVersions] = useState(true);

  React.useEffect(() => {
    latestSchemaVersionFunc().then((version) => {
      setIsLoadingSchemaVersions(false);
      setLatestSchemaVersion(version);
    });
  }, []);

  const sortedSchemaChanges = schemaChanges.sort(sortSchemaChanges);

  const getLabel = (type: SchemaModificationType): React.ReactElement => {
    switch (type) {
      // Schema versioning changes
      case SchemaModificationType.Versioning:
        return (
          <Badge variant={BadgeVariant.Yellow} data-testid={TestSelector.BadgeSelector}>
            {'Versioning'}
          </Badge>
        );

      // Breaking changes
      case SchemaModificationType.Breaking:
        return (
          <Badge variant={BadgeVariant.Red} data-testid={TestSelector.BadgeSelector}>
            {'Breaking'}
          </Badge>
        );

      // Additive changes
      case SchemaModificationType.Additive:
        return (
          <Badge variant={BadgeVariant.LightGray} data-testid={TestSelector.BadgeSelector}>
            {'Additive'}
          </Badge>
        );
      default:
        return <div />;
    }
  };

  const getNote = (note: string | undefined, noteType: SyncNoteType | undefined): React.ReactElement => {
    if (!note || !noteType) {
      return <div />;
    }

    switch (noteType) {
      case SyncNoteType.Benign:
        return <Body>{note}</Body>;
      case SyncNoteType.Warn: // Note: warn and danger are currently the same. Will differentiate as we add more notes
      case SyncNoteType.Danger:
        return (
          <div className={cx(styles.warnNote)}>
            <div className={cx(styles.iconWrapper)}>
              <Icon
                data-testid={TestSelector.NoteWarnIcon}
                glyph="ImportantWithCircle"
                fill={theme.leafygreen.colors.red.base}
              />
            </div>
            <Body weight="medium" className={cx(styles.noteWrapper)}>
              {note}
            </Body>
          </div>
        );
      default:
        return <div />;
    }
  };

  let breakingBannerText = 'Deploying breaking changes will terminate and re-initialize Sync';
  if (latestSchemaVersion > 0) {
    breakingBannerText += '. This breaking change will also increment the schema version';
  }
  if (errorCode === ErrorCode.DestructiveSyncProtocolIncreaseError) {
    breakingBannerText +=
      '. Additionally, these changes use newer data types which are not be supported on client apps built with older SDK versions. ' +
      'Client connections from SDKs that do not support these data types will be rejected upon connection.';
  }
  const getBanner = (type: SchemaModificationType): React.ReactElement => {
    switch (type) {
      // Schema versioning changes
      case SchemaModificationType.Versioning:
        return (
          <Banner
            variant={BannerVariant.Warning}
            data-testid={TestSelector.BannerSelector}
            data-cy={TestSelector.BannerSelector}
          >
            <Body weight={'medium'}>Sync schema versioning changes detected</Body>
            <Body>Deploying schema versioning change will increment the schema version</Body>
          </Banner>
        );

      // Breaking changes
      case SchemaModificationType.Breaking:
        return (
          <Banner
            variant={BannerVariant.Danger}
            data-testid={TestSelector.BannerSelector}
            data-cy={TestSelector.BannerSelector}
          >
            <Body weight={'medium'}>Breaking changes detected</Body>
            <Body>{breakingBannerText}</Body>
            <Link href={docLinks.Sync.BreakingChanges}>Learn more about breaking changes</Link>
          </Banner>
        );

      default:
        return <div />;
    }
  };

  const getSubmitButton = (type: SchemaModificationType): React.ReactElement => {
    switch (type) {
      // Breaking changes
      case SchemaModificationType.Breaking:
        return (
          <Button
            variant={'danger'}
            onClick={() => onConfirm()}
            data-testid={TestSelector.ConfirmButton}
            data-cy={TestSelector.ConfirmButton}
          >
            Deploy and re-initialize
          </Button>
        );
      default:
        return (
          <Button
            variant={'primary'}
            leftGlyph={<Icon glyph="Checkmark" />}
            onClick={() => onConfirm()}
            data-testid={TestSelector.ConfirmButton}
            data-cy={TestSelector.ConfirmButton}
          >
            Deploy
          </Button>
        );
    }
  };

  const getSchemaVersionSection = (schemaVersion: number, type: SchemaModificationType): React.ReactElement => {
    if (
      (schemaVersion === 0 && type !== SchemaModificationType.Versioning) ||
      type === SchemaModificationType.Additive ||
      type === SchemaModificationType.Noop
    ) {
      return <div />;
    }

    // TODO BAAS-25050: update doc link
    return (
      <div className={cx(styles.schemaVersionSection)} data-testid={TestSelector.SchemaVersionSection}>
        <Body weight="medium" baseFontSize={BaseFontSize.Body2}>
          Sync Schema Version
        </Body>
        <Body>
          Current <Link href={docLinks.Sync.SyncMode}>schema version:</Link>
          <strong>Version {schemaVersion}</strong>
        </Body>
        <Body weight="medium" as={'em'}>
          After deployment schema version will be incremented to Version {schemaVersion + 1}
        </Body>
      </div>
    );
  };

  const getTableAndField = (table: string, field?: string): React.ReactElement => {
    if (!field || field === '') {
      return <span className={cx(styles.tableCell)}>{`${table}`}</span>;
    }

    return <span className={cx(styles.tableCell)}>{`${table}.${field}`}</span>;
  };

  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const columns = React.useMemo<Array<ColumnDef<SyncSchemaChange, SyncSchemaChange>>>(
    () => [
      {
        header: 'Type of change',
        cell: (info) => getLabel(info.row.original.type),
        minSize: 200,
      },
      {
        header: 'Table / Field',
        cell: (info) => getTableAndField(info.row.original.table, info.row.original.field),
        minSize: 250,
      },
      {
        accessorKey: 'description',
        header: 'Change description',
        size: 200,
      },
      {
        header: 'Notes',
        cell: (info) => getNote(info.row.original.note, info.row.original.noteType),
        minSize: 200,
      },
    ],
    []
  );

  const table = useLeafyGreenTable<SyncSchemaChange>({
    containerRef: tableContainerRef,
    data: sortedSchemaChanges,
    columns,
  });

  const { rows } = table.getRowModel();

  return (
    <Modal
      open={isOpen && !isLoadingSchemaVersions}
      setOpen={() => onCancel()} // Gets called when user clicks the 'x'
      data-testid={TestSelector.ModalSelector}
      data-cy={TestSelector.ModalSelector}
      className={cx(styles.modal)}
      size={'large'}
    >
      <div>
        <H3 className={cx(styles.header)}>Are you sure you want to deploy?</H3>
        <div className={cx(styles.banner)}>{getBanner(schemaChangeType)}</div>
        {getSchemaVersionSection(latestSchemaVersion, schemaChangeType)}
        <Body weight="medium" baseFontSize={BaseFontSize.Body2}>
          Change Summary
        </Body>
        <div className={cx(styles.tableContainer)}>
          <Table
            className={cx(styles.table)}
            data-testid={TestSelector.TableSelector}
            table={table}
            ref={tableContainerRef}
            shouldAlternateRowColor={sortedSchemaChanges.length >= 10}
          >
            <TableHead isSticky>
              {table.getHeaderGroups().map((headerGroup: HeaderGroup<SyncSchemaChange>) => (
                <HeaderRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <HeaderCell key={header.id} header={header} style={{ width: header.column.getSize() }}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </HeaderCell>
                    );
                  })}
                </HeaderRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row: LeafyGreenTableRow<SyncSchemaChange>, index: number) => {
                const cells = row.getVisibleCells();
                let rowClassName = cx(styles.row);
                if (index === 0) {
                  rowClassName += ` ${cx(styles.firstRow)}`;
                }

                return (
                  <Row key={row.id} className={rowClassName}>
                    {cells.map((cell: LeafyGreenTableCell<SyncSchemaChange>) => {
                      return (
                        <Cell key={cell.id} style={{ width: cell.column.getSize() }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Cell>
                      );
                    })}
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div className={cx(styles.footer)}>
          <Button className={cx(styles.footerCancel)} onClick={() => onCancel()}>
            Cancel
          </Button>
          {getSubmitButton(schemaChangeType)}
        </div>
      </div>
    </Modal>
  );
};

export default SchemaChangeModal;
