import React from 'react';

import { passThroughProps } from 'baas-ui/common/utils/util';

interface Props {
  className?: string;
}

const UserIcon = ({ className = '', ...rest }: Props) => (
  <svg width="16px" height="17px" viewBox="0 0 16 17" version="1.1" className={className} {...passThroughProps(rest)}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="functions-3a-copy-2" transform="translate(-377.000000, -843.000000)" fill="#464C4F">
        <g id="Group-3" transform="translate(235.000000, 242.000000)">
          <g id="Group-4" transform="translate(2.000000, 591.000000)">
            <g id="Group-13" transform="translate(9.000000, 8.000000)">
              <g id="Group-12" transform="translate(131.000000, 2.000000)">
                <g id="Page-1">
                  <path
                    d="M8.002,2.0059 C6.899,2.0059 6.002,2.9019 6.002,4.0059 C6.002,5.1089 6.899,6.0059 8.002,6.0059 C9.106,6.0059 10.002,5.1089 10.002,4.0059 C10.002,2.9019 9.106,2.0059 8.002,2.0059 M8.002,8.0059 C5.796,8.0059 4.002,6.2119 4.002,4.0059 C4.002,1.8009 5.796,0.0059 8.002,0.0059 C10.208,0.0059 12.002,1.8009 12.002,4.0059 C12.002,6.2119 10.208,8.0059 8.002,8.0059"
                    id="Fill-1"
                  />
                  <path
                    d="M2.1611,14.0059 L13.8441,14.0059 C13.2461,12.1979 11.0111,11.0059 8.0031,11.0059 C4.9951,11.0059 2.7591,12.1979 2.1611,14.0059 L2.1611,14.0059 Z M16.0021,16.0059 L0.0021,16.0059 L0.0021,15.0059 C0.0021,11.4729 3.2921,9.0059 8.0021,9.0059 C12.7121,9.0059 16.0021,11.4729 16.0021,15.0059 L16.0021,16.0059 Z"
                    id="Fill-3"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UserIcon;
