import React from 'react';
import styled from '@emotion/styled';
import Banner from '@leafygreen-ui/banner';
import ConfirmationModal, { Variant } from '@leafygreen-ui/confirmation-modal';

import { ZIndex } from 'baas-ui/common/styles/zIndex';
import { passThroughProps } from 'baas-ui/common/utils/util';
import { SvcDataForTable } from 'baas-ui/services/mongodb/data-source-list/DataSourceList';

export const SYNC_TERMINATE_DISCLAIMER =
  'Sync will be terminated and all synced data associated with this cluster will be deleted.';

const StyledBanner = styled(Banner)`
  margin-top: 8px;
`;

export enum TestSelector {
  Modal = 'unlink-data-source-confirmation-modal',
}
export interface Props {
  open: boolean;
  dataSource?: SvcDataForTable;
  appName: string;
  onConfirm(): void;
  onCancel(): void;
}

const UnlinkDataSourceConfirm = ({ open, onConfirm, onCancel, dataSource, appName, ...rest }: Props) => (
  <ConfirmationModal
    data-testid={TestSelector.Modal}
    open={open}
    variant={Variant.Danger}
    title={`Unlink Data Source: ${dataSource?.dataSourceName}`}
    confirmButtonProps={{
      children: 'Unlink',
      onClick: onConfirm,
    }}
    cancelButtonProps={{
      onClick: onCancel,
    }}
    requiredInputText={dataSource?.dataSourceName}
    {...passThroughProps(rest)}
    style={{ zIndex: ZIndex.Modal }}
  >
    Are you sure you want to unlink data source
    <b>
      &nbsp;
      {dataSource?.dataSourceName}
      &nbsp;
    </b>
    from your application
    <b>
      &nbsp;
      {appName}
    </b>
    ? Your application will no longer respond to events with triggers associated with this data source.{' '}
    {dataSource?.syncConfig && <StyledBanner variant="warning">{SYNC_TERMINATE_DISCLAIMER}</StyledBanner>}
  </ConfirmationModal>
);

export default UnlinkDataSourceConfirm;
