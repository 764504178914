import { errorHandlerNotify } from 'baas-ui/error_util';

import EVENTS from './events';
import EventTracker from './EventTracker';
import { TrackableEvent } from './types';

export default function track(eventKey: TrackableEvent, args: object = {}) {
  try {
    if (typeof eventKey === 'string') {
      EventTracker.logEvent(EVENTS[eventKey], args);
    } else {
      Object.entries(eventKey).forEach(([key, value]) => {
        if (typeof value === 'boolean' && value) {
          EventTracker.logEvent(EVENTS[key], args);
        } else if (typeof value === 'object') {
          if (value.shouldTrack) {
            EventTracker.logEvent(EVENTS[key], value.args || args);
          }
        }
      });
    }
  } catch (err) {
    errorHandlerNotify(err);
  }
}
