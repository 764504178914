import React from 'react';
import { Body } from '@leafygreen-ui/typography';
import { ConfirmDeprecated as Confirm } from 'stitch-ui-toolbox';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';

export default function destructiveChangeAlert(error: string) {
  const confirmBody = (
    <Body>
      <span className="tb-view-modal-body-confirm">
        The changes you are trying to save are breaking and will require Sync to reinitialize. All clients will
        experience a{' '}
        <DocLink showExternalIcon href={docLinks.Sync.ClientReset}>
          Client Reset
        </DocLink>
        . Are you sure you want to save changes?
      </span>
      <span className="tb-view-modal-body-confirm-bold">{error}</span>
    </Body>
  );
  return Confirm.confirm({
    confirmHeader: 'Reinitialize Sync: Breaking Changes',
    confirmBody,
    confirmButtonText: 'Save Changes & Reinitialize Sync',
    confirmButtonVariant: 'danger',
  });
}
