import PropTypes from 'prop-types';

export const SECRETS_SHAPE = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })
);

export const SELECTED_SECRET_SHAPE = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});
