import React from 'react';
import { Body, Subtitle } from '@leafygreen-ui/typography';

import { DraftDiff } from 'admin-sdk';

export enum TestSelector {
  Diff = 'hosting-diff',
  Added = 'added',
  Modified = 'modified',
  Deleted = 'deleted',
}

export interface Props {
  hostingDiff: DraftDiff['hostingFilesDiff'];
}

export default function HostingDiff({ hostingDiff }: Props) {
  const { added, deleted, modified } = hostingDiff;
  if (!added.length && !deleted.length && !modified.length) {
    return null;
  }

  return (
    <div className="deployment-diff" data-testid={TestSelector.Diff}>
      <Subtitle className="deployment-diff-title">Hosting Files</Subtitle>
      <div className="deployment-diff-content">
        {!!added && (
          <div className="deployment-diff-hosted-files-diff" data-testid={TestSelector.Added}>
            <Body className="deployment-diff-hosted-files-diff-title">Files Added:</Body>
            {added.map((filePath) => (
              <li className="deployment-diff-hosted-file" key={filePath}>
                {filePath}
              </li>
            ))}
          </div>
        )}
        {!!modified && (
          <div className="deployment-diff-hosted-files-diff" data-testid={TestSelector.Modified}>
            <Body className="deployment-diff-hosted-files-diff-title">Files Modified:</Body>
            {modified.map((filePath) => (
              <li className="deployment-diff-hosted-file" key={filePath}>
                {filePath}
              </li>
            ))}
          </div>
        )}
        {!!deleted && (
          <div className="deployment-diff-hosted-files-diff" data-testid={TestSelector.Deleted}>
            <Body className="deployment-diff-hosted-files-diff-title">Files Deleted:</Body>
            {deleted.map((filePath) => (
              <li className="deployment-diff-hosted-file" key={filePath}>
                {filePath}
              </li>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
