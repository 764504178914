export const pluralize = (count: number, value: string, pluralName = `${value}s`) => (count === 1 ? value : pluralName);

export const getFormattedNumber = (num: number) => {
  if (num < 0) {
    return '--';
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }

  if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }

  return `${num}`;
};
