import { useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { CompletionContext } from '@codemirror/autocomplete';
import { esLint, javascript, javascriptLanguage, typescriptLanguage } from '@codemirror/lang-javascript';
import { json, jsonLanguage, jsonParseLinter } from '@codemirror/lang-json';
import { LanguageSupport } from '@codemirror/language';
import { linter, lintGutter } from '@codemirror/lint';
import { Extension } from '@codemirror/state';
import styled from '@emotion/styled';
import { palette } from '@leafygreen-ui/palette';
import {
  createDefaultMapFromCDN,
  createSystem,
  createVirtualTypeScriptEnvironment,
  VirtualTypeScriptEnvironment,
} from '@typescript/vfs';
import CodeMirror, { EditorView, ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { tsFacet, tsHover, tsLinter, tsSync } from '@valtown/codemirror-ts';
import * as eslint from 'eslint-linter-browserify';
import ts from 'typescript';

import {
  AUTOCOMPLETE_OPTIONS_TREE,
  CONTEXT_FUNCTIONS_EXECUTE,
  CONTEXT_SERVICES_GET,
  CONTEXT_VALUES_GET,
  DEFAULT_AUTOCOMPLETE_OPTIONS,
  DYNAMIC_AUTOCOMPLETE_OPTIONS,
  FUNCTION_EDITOR_TS_BACKUP_DOM_LIB_CONTENT,
  FUNCTION_EDITOR_TS_BACKUP_DOM_LIB_PATH,
  FUNCTION_EDITOR_TS_BACKUP_ES6_LIB_CONTENT,
  FUNCTION_EDITOR_TS_BACKUP_ES6_LIB_PATH,
  FUNCTION_EDITOR_TS_ENV_GLOBALS_CONTENT,
  FUNCTION_EDITOR_TS_ENV_GLOBALS_PATH,
  TYPESCRIPT_VERSION,
} from 'baas-ui/functions/constants';
import { AutocompleteOption } from 'baas-ui/functions/types';
import { getAdminClientState, getAppState } from 'baas-ui/selectors';
import { AppResourceNames } from 'admin-sdk';

export const StyledCodeMirror = styled(CodeMirror)`
  display: flex;
  flex-direction: row;
  height: 100%;
  .cm-editor {
    width: 0;
    flex-grow: 1;
    outline-style: none;
    border: 2px solid ${palette.gray.light1};
  }
`;

export enum SupportedLanguages {
  Javascript,
  JavascriptExtended,
  Typescript,
  JSON,
  None,
}

// TODO (BAAS-32531): Can be removed once typescript linting is set up and ff is removed
export const JavascriptLintSettings = {
  globals: {
    context: 'readonly',
  },
  parserOptions: {
    ecmaVersion: 2021, // 2022 features such as Object.hasOwn, Error cause are not supported in our runtime
    sourceType: 'module',
  },
};

export interface Props {
  className?: string;
  'data-testid'?: string;
  functionInput: string;
  language: SupportedLanguages;
  readOnly?: boolean;
  onChange?: (source: string) => void;
  onSave?: () => void;
  scrollToBottom?: boolean;
}

// generate labels based on the user's typed text to the left of the user's cursor position
export const convertResourceNamesToLabels = (
  resourceNames: AppResourceNames
): Map<string, Array<AutocompleteOption>> => {
  const labelsMap = new Map<string, Array<AutocompleteOption>>();

  labelsMap.set(CONTEXT_SERVICES_GET, [
    ...resourceNames.datasources.map((dsName) => ({
      label: `context.services.get("${dsName}"`,
      displayLabel: `"${dsName}"`,
    })),
    ...resourceNames.services.map((serviceName) => ({
      label: `context.services.get("${serviceName}"`,
      displayLabel: `"${serviceName}"`,
    })),
  ]);

  labelsMap.set(
    CONTEXT_FUNCTIONS_EXECUTE,
    resourceNames.functions.map((functionName) => ({
      label: `context.functions.execute("${functionName}"`,
      displayLabel: `"${functionName}"`,
    }))
  );

  labelsMap.set(
    CONTEXT_VALUES_GET,
    resourceNames.values.map((valueName) => ({
      label: `context.values.get("${valueName}"`,
      displayLabel: `"${valueName}"`,
    }))
  );

  return labelsMap;
};

export const getAutocompleteLabels = (
  searchString: string,
  resourceNames: Map<string, Array<AutocompleteOption>>
): Array<AutocompleteOption> => {
  // begin creating a lookup key for autocomplete labels

  // step one: is to remove everything typed after the last period
  // e.g. if context.services.g is typed, the context.services labels are used for autocomplete
  const wordWithPeriod = searchString.substring(0, searchString.lastIndexOf('.'));

  // step two: determine if we need to dynamically create an autocomplete label for subfunctions
  // e.g. context.services.get("anything").db()
  // create lookup key by removing text between parentheses e.g. blah.db("hi").coll("bye") -> blah.db().coll()
  const dynamicLabelLookupKey = wordWithPeriod.replace(/\([^()]*\)/g, '()');

  if (DYNAMIC_AUTOCOMPLETE_OPTIONS[dynamicLabelLookupKey] !== undefined) {
    return DYNAMIC_AUTOCOMPLETE_OPTIONS[dynamicLabelLookupKey].map((option: AutocompleteOption) => ({
      label: `${wordWithPeriod}.${option.displayLabel}`,
      displayLabel: option.displayLabel,
    }));
  }

  // step three: determine if the user's functions, values, or services should be used for populating autocomplete
  // e.g. if context.functions.execute( is typed, context.functions.execute will be used for lookup
  const wordWithParenthesis = searchString.substring(0, searchString.lastIndexOf('('));
  if (resourceNames?.get(wordWithParenthesis) !== undefined) {
    return resourceNames.get(wordWithParenthesis)!;
  }

  // step four: determine if we have a hardcoded lablel to display for autocomplete
  // e.g. if context.app.foo123 is typed, the context.app labels are returned for autocomplete
  if (AUTOCOMPLETE_OPTIONS_TREE[wordWithPeriod] !== undefined) {
    return AUTOCOMPLETE_OPTIONS_TREE[wordWithPeriod];
  }

  // step five: nothing matches so return the default labels for autocomplete options
  return DEFAULT_AUTOCOMPLETE_OPTIONS;
};

// set up tsconfig compiler options for typescript environment
// these options dictate how the typescript will be linted
const esVersion = 'es2021';
const compilerOpts = {
  target: ts.ScriptTarget.ES2021,
  lib: ['dom', esVersion],
  noUnusedLocals: true,
  strictNullChecks: true,
  strictPropertyInitialization: true,
  allowSyntheticDefaultImports: true,
  alwaysStrict: true,
  esModuleInterop: true,
  noImplicitReturns: true,
  noImplicitAny: false,
  useUnknownInCatchVariables: false,
  module: ts.ModuleKind.Node16,
  moduleResolution: ts.ModuleResolutionKind.Node16,
  typeRoots: ['./'],
  skipLibCheck: true,
  noResolve: true,
};

// filesystem configuration containing some important globals for mongo and ts/js
const fsMap = new Map<string, string>();
fsMap.set(FUNCTION_EDITOR_TS_ENV_GLOBALS_PATH, FUNCTION_EDITOR_TS_ENV_GLOBALS_CONTENT);
fsMap.set(FUNCTION_EDITOR_TS_BACKUP_ES6_LIB_PATH, FUNCTION_EDITOR_TS_BACKUP_ES6_LIB_CONTENT);
fsMap.set(FUNCTION_EDITOR_TS_BACKUP_DOM_LIB_PATH, FUNCTION_EDITOR_TS_BACKUP_DOM_LIB_CONTENT);

// default file name for typescript environment
const path = 'index.ts';

// function for basic configuration of typescript environment
const getBasicTSEnvironment = (): VirtualTypeScriptEnvironment => {
  return createVirtualTypeScriptEnvironment(
    createSystem(fsMap),
    [FUNCTION_EDITOR_TS_ENV_GLOBALS_PATH],
    ts,
    compilerOpts
  );
};

// function for full configuration of typescript environment
const getFullTSEnvironment = async () => {
  let env: VirtualTypeScriptEnvironment;
  try {
    // try to create a filesystem map of all core es6 libraries using official typescript CDN (external)
    const fsCdnMap = await createDefaultMapFromCDN(
      { target: ts.ScriptTarget.ES2021, lib: [esVersion] },
      TYPESCRIPT_VERSION,
      true,
      ts
    );
    fsCdnMap.set(FUNCTION_EDITOR_TS_ENV_GLOBALS_PATH, FUNCTION_EDITOR_TS_ENV_GLOBALS_CONTENT);

    const system = createSystem(fsCdnMap);
    env = createVirtualTypeScriptEnvironment(system, [FUNCTION_EDITOR_TS_ENV_GLOBALS_PATH], ts, compilerOpts);
  } catch {
    env = getBasicTSEnvironment();
  }

  return env;
};

const JavascriptExtension = javascript();
const TypescriptExtension = javascript({ typescript: true });
const JSONExtension = json();
const defaultExtensions: Extension[] = [lintGutter(), EditorView.lineWrapping];

// captures the current word until next space or end of line, including periods and parentheses
const wordRegex = /(?<!\S)([^\s]+)/;

function getAutocompleteFacetValue(resourceNames: Map<string, Array<AutocompleteOption>>) {
  return {
    autocomplete: (context: CompletionContext) => {
      const word = context.matchBefore(wordRegex); // matches the current word to the left of the cursor
      if (word == null || word.from === word.to) {
        // there is no word to the left of the cursor so no autocomplete options will be shown e.g. a new line
        return null;
      }
      return {
        from: word.from,
        // by default, show any words that match a word in the default list e.g. context
        options: getAutocompleteLabels(word.text, resourceNames),
      };
    },
  };
}

export const getLanguageExtensions = (
  language: SupportedLanguages,
  resourceNames: Map<string, Array<AutocompleteOption>>
): [LanguageSupport, ...Array<Extension>] => {
  const autocompleteFacetValue = getAutocompleteFacetValue(resourceNames);

  let env: VirtualTypeScriptEnvironment;

  switch (language) {
    case SupportedLanguages.Javascript:
      return [JavascriptExtension, javascriptLanguage];
    case SupportedLanguages.JSON: {
      return [JSONExtension, jsonLanguage, linter(jsonParseLinter())];
    }
    case SupportedLanguages.Typescript:
      // load minimum configuration for typescript environment
      env = getBasicTSEnvironment();
      return [
        TypescriptExtension,
        typescriptLanguage.data.of(autocompleteFacetValue),
        tsFacet.of({ env, path }),
        tsSync(),
        tsLinter(),
        tsHover(),
      ];
    case SupportedLanguages.JavascriptExtended:
    default: {
      return [
        JavascriptExtension,
        javascriptLanguage.data.of(autocompleteFacetValue),
        linter(esLint(new eslint.Linter(), JavascriptLintSettings)),
      ];
    }
  }
};

export const getAdvancedTSLanguageExtensions = async (
  resourceNames: Map<string, Array<AutocompleteOption>>
): Promise<[LanguageSupport, ...Array<Extension>]> => {
  const autocompleteFacetValue = getAutocompleteFacetValue(resourceNames);

  // load full configuration for typescript environment
  const env = await getFullTSEnvironment();
  return [
    TypescriptExtension,
    typescriptLanguage.data.of(autocompleteFacetValue),
    tsFacet.of({ env, path }),
    tsSync(),
    tsLinter(),
    tsHover(),
  ];
};

let debounceTimer: NodeJS.Timeout;

// returns a CodeMirror component that will be memoized to prevent unnecessary rerenders when function input changes
export const CodeEditor = ({
  functionInput,
  language,
  onChange = () => {},
  onSave = () => {},
  scrollToBottom,
  ...other
}: Props) => {
  const { app } = useSelector(getAppState);
  const adminClient = useSelector(getAdminClientState);
  const [resourceNames, setResourceNames] = useState<Map<string, Array<AutocompleteOption>>>(new Map());
  const [languageExtensions, setLanguageExtensions] = useState<Array<Extension>>([defaultExtensions]);

  // listen for crtl + s and call onSave when ctrl + s is pressed
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.code === 'KeyS') {
        event.preventDefault();
        onSave();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [functionInput, onSave]);

  // fetch the app resource names only on initial render or if language changes
  useEffect(() => {
    if (language !== SupportedLanguages.JavascriptExtended && language !== SupportedLanguages.Typescript) {
      return;
    }
    adminClient
      ?.apps(app.groupId)
      .app(app.id)
      .resourceNames()
      .then((resources) => {
        if (resources) {
          setResourceNames(convertResourceNamesToLabels(resources));
        }
      });
  }, []);

  // for loading existing language extensions + basic typescript configuration
  useEffect(() => {
    setLanguageExtensions(getLanguageExtensions(language, resourceNames));
  }, [language, resourceNames]);

  // for loading full typescript configuration
  useEffect(() => {
    if (language === SupportedLanguages.Typescript) {
      const loadLanguageExtensions = async () => {
        try {
          // try load in the advanced typescript configuration
          const extensions = await getAdvancedTSLanguageExtensions(resourceNames);
          setLanguageExtensions(extensions);
        } catch {
          /* empty */
        }
      };
      loadLanguageExtensions();
    }
  }, [language, resourceNames]);

  const onChangeMemo = useMemo(
    () => (updatedInput: string) => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      // CodeMirror updates its own state separately so debouncing here allows the component to wait to update in the
      // App Services UI, preventing performance issues with rerenders.
      debounceTimer = setTimeout(() => {
        onChange(updatedInput);
      }, 100);
    },
    [onChange]
  ); // editor will not rerender unless the input changes

  const editorRef = useRef<ReactCodeMirrorRef>({});

  useEffect(() => {
    const view = editorRef.current.view;
    // scroll the editor to the bottom if prop is true
    if (scrollToBottom && view) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore https://github.com/Microsoft/TypeScript/issues/28755
      view.scrollDOM.scrollTo({ top: view.contentHeight, behavior: 'instant' });
    }
  }, [scrollToBottom]);

  return (
    <StyledCodeMirror
      ref={editorRef}
      onKeyPress={(e) => e.stopPropagation()}
      value={functionInput}
      // don't lint on empty input but add lint gutter so spacing remains similar with/without input
      extensions={
        functionInput?.length > 0 && language !== SupportedLanguages.None
          ? [languageExtensions, ...defaultExtensions]
          : defaultExtensions
      }
      onChange={onChangeMemo}
      {...other}
    />
  );
};

export default CodeEditor;
