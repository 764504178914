/* eslint-disable max-classes-per-file */
import { Record, Set } from 'immutable';

import JSONState from 'baas-ui/models/JSONState';

export const BaseRule = (defaultValues) =>
  class extends Record({
    id: '',
    name: '',
    when: new JSONState({}),
    dirty: false,
    ...defaultValues,
  }) {
    toRawRule() {
      const out = {
        id: this.id,
        name: this.name,
        actions: this.actions.toArray(),
      };

      if (this.when.data) {
        out.when = this.when.data;
      }
      return out;
    }

    updateWhenInput(v) {
      return this.setIn(['when', 'input'], v).set('dirty', true);
    }

    hasError() {
      return this.when.error != null;
    }

    parse() {
      return this.set('when', this.when.parseInput());
    }
  };

export default class Rule extends BaseRule({
  actions: new Set(),
}) {
  static fromRawRule(raw) {
    return new Rule({
      id: raw.id,
      name: raw.name,
      actions: new Set(raw.actions),
      when: JSONState.fromRaw(raw.when, {}),
      dirty: false,
    });
  }

  setAction(a, enabled) {
    return this.set('actions', enabled ? this.actions.add(a) : this.actions.remove(a)).set('dirty', true);
  }
}
