import React from 'react';
import Banner from '@leafygreen-ui/banner';
import { Body, Subtitle } from '@leafygreen-ui/typography';
import BrandIcon from '@mongodb-js/brand-icons';

import { LoadingWrapper } from 'baas-ui/common/components/loading-wrapper';
import { passThroughProps } from 'baas-ui/common/utils/util';
import useTemplates from 'baas-ui/home/common/hooks/use-templates';
import { FlutterIcon, KotlinIcon, ReactIcon, SwiftIcon, XamarinIcon } from 'baas-ui/home/common/icons';
import { CreateAppProps } from 'baas-ui/home/common/withCreateApp';
import { templatesNotAvailableErrorMessage } from 'baas-ui/home/constants';
import TemplateCard from 'baas-ui/home/create-app/form/templates-form-section/template-card';
import { TemplateIdentifier } from 'baas-ui/home/create-app/types';
import { track } from 'baas-ui/tracking';

import './templates-section.less';

export enum TestSelector {
  TemplateOption = 'template-option',
  DefaultOption = 'default-option',
  SyncOption = 'sync-option',
}
interface PublicProps {
  selectedTemplate: TemplateIdentifier;
  setSelectedTemplate: (templateId: TemplateIdentifier) => void;
}

export type Props = PublicProps & CreateAppProps;

const rootClassName = 'create-app-form-templates';

interface TemplateCardContent {
  id: TemplateIdentifier;
  headerImage: React.ReactElement;
  headerText: string;
  description: string;
  clientTechnologyLogos: React.ReactElement[];
}

const defaultCardContent: TemplateCardContent = {
  id: TemplateIdentifier.Default,
  headerImage: <BrandIcon iconName="General_MISC_Create" height={48} width={48} />,
  headerText: 'Build your own App',
  description: 'Set up your own app services to fit your development needs',
  clientTechnologyLogos: [],
};

const syncContentCard: TemplateCardContent = {
  id: TemplateIdentifier.SyncTodo,
  headerImage: <BrandIcon iconName="Technical_REALM_Sync" height={40} width={40} />,
  headerText: 'Real-time Sync',
  description: 'Mobile app using an Atlas Device SDK to sync data to your backend',
  clientTechnologyLogos: [
    <KotlinIcon key="kotlin-icon" />,
    <SwiftIcon key="swift-icon" />,
    <ReactIcon key="react-icon" />,
    <XamarinIcon key="xamarin-icon" />,
    <FlutterIcon key="flutter-icon" />,
  ],
};

const triggersContentCard: TemplateCardContent = {
  id: TemplateIdentifier.TriggersDatabase,
  headerImage: <BrandIcon iconName="Technical_ATLAS_Triggers" height={40} width={40} />,
  headerText: 'Manage Database Views',
  description: 'Event-driven Database Trigger template to update a view in a separate collection',
  clientTechnologyLogos: [],
};

const webMQLTodoContentCard: TemplateCardContent = {
  id: TemplateIdentifier.WebMQLTodo,
  headerImage: <BrandIcon iconName="Technical_REALM_SDK" height={40} width={40} />,
  headerText: 'React.JS + Realm Web Boilerplate',
  description: 'Todo web app using the Realm Web SDK',
  clientTechnologyLogos: [<ReactIcon key="react-icon" />],
};

const TemplatesFormSection = ({ selectedTemplate, setSelectedTemplate, ...rest }: Props) => {
  const [templatesDisabled, setTemplatesDisabled] = React.useState(false);
  const [templates, templatesLoading, templatesError, loadTemplates] = useTemplates();
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    loadTemplates();
  }, []);

  React.useEffect(() => {
    setTemplatesDisabled(false);
    setError('');

    if (templatesLoading) {
      return;
    }

    // Disable templates if templates are not available
    if (templatesError) {
      setError(templatesError ? `Error fetching templates: ${templatesError}` : templatesNotAvailableErrorMessage);
      setTemplatesDisabled(true);
      setSelectedTemplate(TemplateIdentifier.Default);
      return;
    }

    setSelectedTemplate(TemplateIdentifier.Default);
  }, [templates]);

  return (
    <div className={rootClassName} data-cy="create-app-templates-form-section" {...passThroughProps(rest)}>
      <LoadingWrapper isLoading={templatesLoading}>
        <Subtitle>Start with an app template</Subtitle>
        <Body>Using App Services reduces the code you need to write. Get started quicker with Templates.</Body>
        {error && <Banner className={`${rootClassName}-banner`}>{error}</Banner>}
        <div className={`${rootClassName}-default-options`}>
          <TemplateCard
            {...defaultCardContent}
            data-cy="default-app-card"
            data-test-selector={TestSelector.DefaultOption}
            onClick={() => {
              track('APPLICATION.TEMPLATE_STARTER_NO_TEMPLATE_CLICKED');
              setSelectedTemplate(TemplateIdentifier.Default);
            }}
            focus={selectedTemplate === TemplateIdentifier.Default}
          />
          <TemplateCard
            {...triggersContentCard}
            data-cy="triggers-card"
            data-test-selector={TestSelector.TemplateOption}
            disabled={templatesDisabled}
            onClick={() => {
              track('APPLICATION.TEMPLATE_STARTER_APP_CLICKED', { templateId: triggersContentCard.id });
              setSelectedTemplate(triggersContentCard.id);
            }}
            focus={selectedTemplate === triggersContentCard.id}
          />
        </div>
        <div className={`${rootClassName}-options`}>
          <TemplateCard
            {...syncContentCard}
            data-cy="sync-todo-card"
            data-test-selector={TestSelector.SyncOption}
            data-testid={TestSelector.SyncOption}
            onClick={() => {
              track('APPLICATION.TEMPLATE_STARTER_APP_CLICKED', { templateId: TemplateIdentifier.SyncTodo });
              setSelectedTemplate(TemplateIdentifier.SyncTodo);
            }}
            focus={selectedTemplate === TemplateIdentifier.SyncTodo}
          />
          <TemplateCard
            {...webMQLTodoContentCard}
            data-cy="web-mql-todo-card"
            data-test-selector={TestSelector.TemplateOption}
            disabled={templatesDisabled}
            onClick={() => {
              track('APPLICATION.TEMPLATE_STARTER_APP_CLICKED', { templateId: webMQLTodoContentCard.id });
              setSelectedTemplate(webMQLTodoContentCard.id);
            }}
            focus={selectedTemplate === webMQLTodoContentCard.id}
          />
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default TemplatesFormSection;
