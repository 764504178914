import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('DebugExecuteFunctionRequest')
export class DebugExecuteFunctionRequest {
  @JsonProperty('name')
  public name = '';

  @JsonProperty('arguments')
  public arguments: any[] = [];

  constructor(partial?: Partial<DebugExecuteFunctionRequest>) {
    Object.assign(this, partial);
  }
}

@JsonObject('DebugExecuteFunctionSourceRequest')
export class DebugExecuteFunctionSourceRequest {
  @JsonProperty('source')
  public source = '';

  @JsonProperty('eval_source')
  public evalSource = '';

  constructor(partial?: Partial<DebugExecuteFunctionSourceRequest>) {
    Object.assign(this, partial);
  }
}

@JsonObject('DebugExecuteFunctionStats')
export class DebugExecuteFunctionStats {
  @JsonProperty('execution_time', String)
  public executionTime = '';

  constructor(partial?: Partial<DebugExecuteFunctionStats>) {
    Object.assign(this, partial);
  }
}

@JsonObject('DebugExecuteFunctionResponse')
export class DebugExecuteFunctionResponse {
  @JsonProperty('result')
  public result: any = null;

  @JsonProperty('logs', [String], true)
  public logs: string[] = [];

  @JsonProperty('error_logs', [String], true)
  public errorLogs: string[] = [];

  @JsonProperty('stats', DebugExecuteFunctionStats)
  public stats: DebugExecuteFunctionStats = new DebugExecuteFunctionStats();

  constructor(partial?: Partial<DebugExecuteFunctionResponse>) {
    Object.assign(this, partial);
  }
}
