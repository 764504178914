import React from 'react';
import styled from '@emotion/styled';
import Box from '@leafygreen-ui/box';
import { Label } from '@leafygreen-ui/typography';

import DataSourceSelect, { DataSourceSelectOptionType } from 'baas-ui/common/components/data-source-select';
import { LoadingWrapper } from 'baas-ui/common/components/loading-wrapper';
import { CreateAppProps } from 'baas-ui/home/common/withCreateApp';
import { processDataSourceOpts } from 'baas-ui/home/create-app/form/util';

export type Props = CreateAppProps;

export enum TestSelector {
  DataSourcesSelect = 'data-sources-select',
  DataSourcesSelectLabel = 'data-sources-select-label',
}

const StyledDataSourcesSelect = styled(Box)`
  width: 450px;
`;

const EmptyStateDataSourcesSelect = ({
  clusterOpts,
  dataLakeOpts,
  flexClusterOpts,
  onlineArchiveOpts,
  serverlessInstanceOpts,
  selectedDataSource,
  isCreatingApp,
  loadingDataSources,
  templateId,
  setSelectedDataSource,
}: Props) => {
  const {
    enabledClusterOpts,
    enabledDataLakeOpts,
    enabledFlexClusterOpts,
    enabledOnlineArchiveOpts,
    enabledServerlessInstanceOpts,
  } = processDataSourceOpts({
    clusterOpts,
    dataLakeOpts,
    flexClusterOpts,
    onlineArchiveOpts,
    serverlessInstanceOpts,
    templateId,
  });

  return (
    <LoadingWrapper isLoading={loadingDataSources}>
      <StyledDataSourcesSelect data-testid={TestSelector.DataSourcesSelect}>
        <Label htmlFor="createAppDataSourceSelect" data-testid={TestSelector.DataSourcesSelectLabel}>
          Link Data Source
        </Label>
        <DataSourceSelect
          id="createAppDataSourceSelect"
          value={selectedDataSource}
          onChange={(opt: DataSourceSelectOptionType) => setSelectedDataSource(opt)}
          isDisabled={isCreatingApp}
          options={[
            ...clusterOpts,
            ...dataLakeOpts,
            ...flexClusterOpts,
            ...onlineArchiveOpts,
            ...serverlessInstanceOpts,
          ]}
          enabledOptions={[
            ...enabledClusterOpts,
            ...enabledDataLakeOpts,
            ...enabledFlexClusterOpts,
            ...enabledOnlineArchiveOpts,
            ...enabledServerlessInstanceOpts,
          ]}
          selectedTemplate={templateId}
        />
      </StyledDataSourcesSelect>
    </LoadingWrapper>
  );
};

export default EmptyStateDataSourcesSelect;
