import React from 'react';
import { Body } from '@leafygreen-ui/typography';

const guideContentBodyClassName = 'guide-content-body';

export interface Props {
  children: React.ReactNode;
}

const GuideContentBody = ({ children }: Props) => <Body className={guideContentBodyClassName}>{children}</Body>;

export default GuideContentBody;
