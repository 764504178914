import { filterNullDataPoints } from 'baas-ui/metrics/utils';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import { AppMetrics, MetricsRequestOptions } from 'admin-sdk';

const NAME = 'metrics/';

export interface LoadMetricsPayload extends BaseRequestPayload {
  options?: MetricsRequestOptions;
}

export const [loadMetricsActions, loadMetrics] = createActionsAndExecutor<LoadMetricsPayload, AppMetrics>(
  `${NAME}load metrics`,
  (client, { groupId, appId, options }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .metrics(options)
        .then((response) => ({
          ...response,
          measurements: filterNullDataPoints(response.measurements),
        }))
);
