import React from 'react';
import Button from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import { Menu, MenuItem } from '@leafygreen-ui/menu';
import { palette } from '@leafygreen-ui/palette';
import Tooltip from '@leafygreen-ui/tooltip';

import { ZIndex } from 'baas-ui/common/styles/zIndex';
import { stopPropagation } from 'baas-ui/util';

export interface Props {
  editConfig(): void;
  unlink(): void;
}

export enum TestSelector {
  EditConfigItem = 'dropdown-edit-config-item',
  EditRulesItem = 'dropdown-edit-rules-item',
  UnlinkItem = 'dropdown-unlink',
}

function DataLakeActionsDropdown({ editConfig, unlink }: Props) {
  return (
    <Menu
      trigger={
        <Button type="button" size="xsmall" onClick={(e) => e.stopPropagation()}>
          <Icon glyph="Ellipsis" />
        </Button>
      }
    >
      <MenuItem onClick={stopPropagation(editConfig)} data-test-selector={TestSelector.EditConfigItem}>
        Edit Data Source Configuration
      </MenuItem>
      <MenuItem disabled data-test-selector={TestSelector.EditRulesItem}>
        <span>
          Edit Rules
          <Tooltip
            align="top"
            trigger={
              <span className="tooltip-indicator tooltip-indicator-primary">
                <Icon glyph="InfoWithCircle" size="small" />
              </span>
            }
            popoverZIndex={ZIndex.ToolTip}
          >
            Federated Database Instances do not support rules.
          </Tooltip>
        </span>
      </MenuItem>
      <MenuItem
        onClick={stopPropagation(unlink)}
        data-test-selector={TestSelector.UnlinkItem}
        style={{ color: palette.red.base }}
      >
        Unlink Data Source
      </MenuItem>
    </Menu>
  );
}

export default DataLakeActionsDropdown;
