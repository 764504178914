import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import { AppMeasurementGroup, GroupMeasurementGroup, MeasurementRequest } from 'admin-sdk';

const NAME = 'measurements/';

interface MeasurementsRequestPayload extends BaseRequestPayload {
  filter?: MeasurementRequest;
}

export const [loadAppMeasurementsActions, loadAppMeasurements] = createActionsAndExecutor<
  MeasurementsRequestPayload,
  AppMeasurementGroup
>(
  `${NAME}load app measurements`,
  (client, { groupId, appId, filter }) =>
    () =>
      client.apps(groupId).app(appId).measurements(filter)
);

export const [getGroupMeasurementsActions, getGroupMeasurements] = createActionsAndExecutor<
  Omit<MeasurementsRequestPayload, 'appId'>,
  GroupMeasurementGroup
>(
  `${NAME}load group measurements`,
  (client, { groupId, filter }) =>
    () =>
      client.apps(groupId).measurements(filter)
);
