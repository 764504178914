import {
  CollectionData,
  DatabaseData,
  DataSourceData,
} from 'baas-ui/common/components/collection-explorer/collection-explorer/CollectionExplorer';
import { AsyncDataState } from 'baas-ui/redux_util';
import { DataSourceState, MDBService } from 'baas-ui/services/mongodb/types';
import { makeCollectionNamespace } from 'baas-ui/services/mongodb/util';
import { PartialSchema } from 'admin-sdk';

export type SchemaPageCollectionData = {
  schema?: PartialSchema;
  estimatedDocuments?: AsyncDataState<number>;
};

export type SchemaPageDataSourceData = DataSourceData<SchemaPageCollectionData>;

interface CollectionMetadata {
  data_source: string; // eslint-disable-line camelcase
  database: string;
  collection: string;
}

export function hasCollections(dataSources: SchemaPageDataSourceData[]) {
  return dataSources.some(({ databases }) => databases.some(({ collections }) => collections.length));
}

export function isCollectionMetadata(input: any): input is CollectionMetadata {
  if (!input) {
    return false;
  }

  const asCollectionMetadata = input as CollectionMetadata;
  return !!asCollectionMetadata.data_source && !!asCollectionMetadata.database && !!asCollectionMetadata.collection;
}

export const makeDataSourceData = (
  mongodbServices: MDBService[],
  namespaces: DataSourceState['namespacesByClusterId'],
  estimatedDocuments: DataSourceState['estimatedDocumentCountByNamespace'],
  schemas: AsyncDataState<PartialSchema[]>
): SchemaPageDataSourceData[] => {
  // using objects for easier mapping
  const dataSourceMapping: {
    [key: string]: {
      dataSourceName: string;
      dataSourceId: string;
      hasCollectionData: boolean;
      databases: {
        [key: string]: {
          databaseName: string;
          hasCollectionData: boolean;
          collections: {
            [key: string]: {
              collectionName: string;
              data: SchemaPageCollectionData;
            };
          };
        };
      };
    };
  } = {};

  if (schemas.data) {
    schemas.data.forEach((partialSchema) => {
      const { metadata } = partialSchema;

      if (isCollectionMetadata(metadata)) {
        const { data_source: dataSourceName, database, collection } = metadata;
        const dataSourceId = mongodbServices.find(({ name }) => dataSourceName === name)?.id;
        if (!dataSourceId) {
          return;
        }

        let currentDataSource = dataSourceMapping[dataSourceName];
        if (!currentDataSource) {
          currentDataSource = { dataSourceName, dataSourceId, databases: {}, hasCollectionData: true };
        }
        dataSourceMapping[dataSourceName] = currentDataSource;

        let currentDatabase = currentDataSource.databases[database];
        if (!currentDatabase) {
          currentDatabase = { databaseName: database, collections: {}, hasCollectionData: true };
        }
        currentDataSource.databases[database] = currentDatabase;

        let currentCollection = currentDatabase.collections[collection];
        if (!currentCollection) {
          currentCollection = {
            collectionName: collection,
            data: {
              estimatedDocuments:
                estimatedDocuments[makeCollectionNamespace({ dataSourceName: dataSourceId, database, collection })],
              schema: partialSchema,
            },
          };
        }
        currentDatabase.collections[collection] = currentCollection;
      }
    });
  }

  Object.entries(namespaces).forEach(([dataSourceId, namespace]) => {
    const dataSourceName = mongodbServices.find(({ id }) => dataSourceId === id)?.name;

    if (dataSourceName && namespace.data) {
      namespace.data.forEach(({ database, collections }) => {
        collections.forEach((collection) => {
          let currentDataSource = dataSourceMapping[dataSourceName];
          if (!currentDataSource) {
            currentDataSource = { dataSourceName, dataSourceId, databases: {}, hasCollectionData: false };
          }
          dataSourceMapping[dataSourceName] = currentDataSource;

          let currentDatabase = currentDataSource.databases[database];
          if (!currentDatabase) {
            currentDatabase = {
              databaseName: database,
              collections: {},
              hasCollectionData: false,
            };
          }
          currentDataSource.databases[database] = currentDatabase;

          let currentCollection = currentDatabase.collections[collection];
          if (!currentCollection) {
            currentCollection = {
              collectionName: collection,
              data: {
                estimatedDocuments:
                  estimatedDocuments[makeCollectionNamespace({ dataSourceName: dataSourceId, database, collection })],
              },
            };
          }

          currentDatabase.collections[collection] = currentCollection;
        });
      });
    }
  });

  return Object.values(dataSourceMapping)
    .map<DataSourceData<SchemaPageCollectionData>>((dataSourceData) => ({
      namespace: dataSourceData.dataSourceName,
      dataSourceName: dataSourceData.dataSourceName,
      dataSourceId: dataSourceData.dataSourceId,
      hasCollectionData: dataSourceData.hasCollectionData,
      databases: Object.values(dataSourceData.databases)
        .map<DatabaseData<SchemaPageCollectionData>>((databaseData) => ({
          namespace: `${dataSourceData.dataSourceName}:${databaseData.databaseName}`,
          databaseName: databaseData.databaseName,
          hasCollectionData: databaseData.hasCollectionData,
          collections: Object.values(databaseData.collections)
            .map<CollectionData<SchemaPageCollectionData>>((collectionData) => ({
              namespace: makeCollectionNamespace({
                dataSourceName: dataSourceData.dataSourceName,
                database: databaseData.databaseName,
                collection: collectionData.collectionName,
              }),
              collectionName: collectionData.collectionName,
              data: collectionData.data,
            }))
            .sort((a, b) => a.collectionName.localeCompare(b.collectionName)),
        }))
        .sort((a, b) => a.databaseName.localeCompare(b.databaseName)),
    }))
    .sort((a, b) => a.dataSourceName.localeCompare(b.dataSourceName));
};
