import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Store')
export class Store {
  @JsonProperty('region', String, true)
  public region?: string = undefined;

  @JsonProperty('provider', String, true)
  public provider?: string = undefined;
}

@JsonObject('Storage')
export class Storage {
  @JsonProperty('stores', [Store], true)
  public stores?: [Store] = undefined;
}

@JsonObject('AtlasDataLake')
export class AtlasDataLake {
  @JsonProperty('name', String, true)
  public name?: string = undefined;

  @JsonProperty('state', String, true)
  public state?: string = undefined;

  @JsonProperty('hostnames', [String], true)
  public hostnames?: [string] = undefined;

  @JsonProperty('storage', Storage, true)
  public storage?: Storage = undefined;

  constructor(partial?: Partial<AtlasDataLake>) {
    Object.assign(this, partial);
  }
}

export enum AtlasProduct {
  Charts = 'CHARTS',
  OnlineArchive = 'ONLINE_ARCHIVE',
}
