import React, { useState } from 'react';
import Icon from '@leafygreen-ui/icon';
import IconButton from '@leafygreen-ui/icon-button';
import Tooltip from '@leafygreen-ui/tooltip';
import { InlineTextInputDeprecated as InlineTextInput } from 'stitch-ui-toolbox';

import './deployment-name-editor.less';

export interface Props {
  deploymentName: string;
  deploymentId?: string;
  emptyNamePlaceholder?: React.ReactNode;
  tooltipText?: string;
  onChange(name: string): void | Promise<void>;
}

export enum TestSelector {
  DeploymentNameListing = 'deployment-name-listing',
}

const baseClassName = 'deployment-name-editor';

const DeploymentNameEditor = ({ deploymentName, deploymentId, emptyNamePlaceholder, tooltipText, onChange }: Props) => {
  const [name, setName] = useState(deploymentName);
  const [isEditing, setIsEditing] = useState(false);
  const [deploymentNameError, setDeploymentNameError] = useState();

  const handleSave = async (inputName: string) => {
    try {
      await onChange(inputName);
      setName(inputName);
      setIsEditing(false);
    } catch (e) {
      setDeploymentNameError(e.message);
    }
  };

  const editIcon = (
    <IconButton className={`${baseClassName}-edit`} aria-labelledby="edit name" onClick={() => setIsEditing(true)}>
      <Icon glyph="Edit" />
    </IconButton>
  );

  const nameListing =
    deploymentId !== '__pending__' ? (
      <span className={`${baseClassName}`} data-test-selector={TestSelector.DeploymentNameListing}>
        <span className={`${baseClassName}-listing`}>{name.length > 0 ? name : emptyNamePlaceholder}</span>
        {tooltipText ? (
          <Tooltip justify="middle" align="bottom" trigger={editIcon} className={`${baseClassName}-tooltip`}>
            {tooltipText}
          </Tooltip>
        ) : (
          editIcon
        )}
      </span>
    ) : null;

  return isEditing ? (
    <InlineTextInput
      className={`${baseClassName}-input`}
      value={name}
      onCancel={() => setIsEditing(false)}
      onSave={handleSave}
      placeholder={name}
      error={deploymentNameError}
    />
  ) : (
    nameListing
  );
};

export default DeploymentNameEditor;
