import React from 'react';
import { Set } from 'immutable';

import { isImmutable } from 'baas-ui/util';

function convertValue(value) {
  return isImmutable(value) ? value.toJS() : value;
}

// http://redux.js.org/docs/recipes/UsingImmutableJS.html#use-a-higher-order-component-to-\
// convert-your-smart-components-immutablejs-props-to-your-dumb-components-javascript-props
// This Higher-Order Component takes a component (and an optional list of string prop names)
// and returns a component that will convert ImmutableJS props to their JS versions when rendering.
//
// For example:
//
// class MyComponent extends React.Component {
//   render() {
//     ...
//   }
// }
//
// const MyComponentJS = ToJS(MyComponent, "doNotConvert", "meNeither");
// export default connect(state => {
//   return {
//     myImmutableList: state.myImmutableList, // assuming this is an Immutable List, will be passed as a JS Array
//     doNotConvert: new List(), // will be passed as an Immutable List
//     meNeither: new Map() // will be passed as an Immutable Map
//   }
// })(MyComponentJS);
//
export default (Component, ...exclude) => {
  const excludeSet = new Set(exclude);

  return (props) => {
    const jsProps = Object.entries(props).reduce((acc, [key, value]) => {
      acc[key] = excludeSet.has(key) ? value : convertValue(value);
      return acc;
    }, {});

    return <Component {...jsProps} />;
  };
};
