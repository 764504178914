import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@leafygreen-ui/typography';

export interface Props {
  children: React.ReactNode;
  to: string;
  [key: string]: any;
}

// LeafygreenRouterLink wraps the react-router-dom link in a Leafygreen link,
// applying Leafygreen styling but working as a router link under the hood
const LeafygreenRouterLink = ({ children, to, ...rest }: Props) => (
  <Link as={RouterLink} to={to} {...rest}>
    {children}
  </Link>
);

export default LeafygreenRouterLink;
