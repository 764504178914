import { createAction } from 'redux-act';

import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import {
  Device,
  EmailPasswordRegistrationRequest,
  PartialUser,
  PasswordRecord,
  User,
  UserActionToken,
  UserFilter,
} from 'admin-sdk';

import { MAX_PENDING_USER_COUNT } from './constants';
import { PendingUserFilter } from './types';

export const NAME = 'users/';

export interface UserRequestPayload extends BaseRequestPayload {
  userId: string;
}

export interface PendingUsersRequestPayload extends BaseRequestPayload {
  filter?: PendingUserFilter;
}

export interface LoadUsersRequestPayload extends BaseRequestPayload {
  filter?: UserFilter;
}

export interface AddUserRequestPayload extends BaseRequestPayload {
  user: EmailPasswordRegistrationRequest;
}

export interface EmailRequestPayload extends BaseRequestPayload {
  email: string;
}

export interface ClearUserConfirmationStatusPlayload {
  email: string;
}

export const clearUserConfirmationStatus = createAction<ClearUserConfirmationStatusPlayload>(
  `${NAME}clear user confirmation status`
);
export const resetUsersAction = createAction(`${NAME}reset users`);
export const resetLastUserAction = createAction(`${NAME}reset last user`);

export const [addUserAction, addUser] = createActionsAndExecutor<AddUserRequestPayload, PartialUser>(
  `${NAME}add user`,
  (client, { groupId, appId, user }) =>
    () =>
      client.apps(groupId).app(appId).users().create(user)
);

export const [removeUserAction, removeUser] = createActionsAndExecutor<UserRequestPayload, void>(
  `${NAME}delete user`,
  (client, { groupId, appId, userId }) =>
    () =>
      client.apps(groupId).app(appId).users().user(userId).remove()
);

export const [enableUserActions, enableUser] = createActionsAndExecutor<UserRequestPayload, void>(
  `${NAME}enable user`,
  (client, { groupId, appId, userId }) =>
    () =>
      client.apps(groupId).app(appId).users().user(userId).enable()
);

export const [disableUserActions, disableUser] = createActionsAndExecutor<UserRequestPayload, void>(
  `${NAME}disable user`,
  (client, { groupId, appId, userId }) =>
    () =>
      client.apps(groupId).app(appId).users().user(userId).disable()
);

export const [loadUserDevicesActions, loadUserDevices] = createActionsAndExecutor<UserRequestPayload, Device[]>(
  `${NAME}load user devices`,
  (client, { groupId, appId, userId }) =>
    () =>
      client.apps(groupId).app(appId).users().user(userId).devices().get()
);

export const [loadUserActions, loadUser] = createActionsAndExecutor<UserRequestPayload, User>(
  `${NAME}load user`,
  (client, { groupId, appId, userId }) =>
    () =>
      client.apps(groupId).app(appId).users().user(userId).get()
);

export const [loadUsersActions, loadUsers] = createActionsAndExecutor<LoadUsersRequestPayload, PartialUser[]>(
  `${NAME}load users`,
  (client, { groupId, appId, filter }) =>
    () =>
      client.apps(groupId).app(appId).users().list(filter)
);

export const [loadPendingUsersActions, loadPendingUsers] = createActionsAndExecutor<
  PendingUsersRequestPayload,
  PasswordRecord[]
>(
  `${NAME}load pending users`,
  (client, { groupId, appId, filter }) =>
    () =>
      client.apps(groupId).app(appId).userRegistrations().listPending(MAX_PENDING_USER_COUNT, filter?.after)
);

export const [confirmUserByEmailActions, confirmUserByEmail] = createActionsAndExecutor<EmailRequestPayload, void>(
  `${NAME}confirm user by email`,
  (client, { groupId, appId, email }) =>
    () =>
      client.apps(groupId).app(appId).userRegistrations().confirmByEmail(email)
);

export const [removePendingUserByEmailActions, removePendingUserByEmail] = createActionsAndExecutor<
  EmailRequestPayload,
  void
>(
  `${NAME}remove pending user by email`,
  (client, { groupId, appId, email }) =>
    () =>
      client.apps(groupId).app(appId).userRegistrations().removePendingUserByEmail(email)
);

export const [runUserConfirmationByEmailActions, runUserConfirmationByEmail] = createActionsAndExecutor<
  EmailRequestPayload,
  UserActionToken
>(
  `${NAME}run user confirmation`,
  (client, { groupId, appId, email }) =>
    () =>
      client.apps(groupId).app(appId).userRegistrations().runUserConfirmation(email)
);

export const [revokeSessionsActions, revokeSessions] = createActionsAndExecutor(
  `${NAME}revoke user sessions`,
  (client, { groupId, appId, userId }: UserRequestPayload) =>
    client.apps(groupId).app(appId).users().user(userId).logout
);

export const asyncEditRcvActions = [
  addUserAction.rcv,
  removeUserAction.rcv,
  enableUserActions.rcv,
  disableUserActions.rcv,
  revokeSessionsActions.rcv,
];
