import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import { Body } from '@leafygreen-ui/typography';
import equal from 'fast-deep-equal';
import { useDebouncedCallback } from 'use-debounce';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import { CodeEditor } from 'baas-ui/functions/code-editor';
import { SupportedLanguages } from 'baas-ui/functions/code-editor/CodeEditor';
import { PARSE_JSON_DEBOUNCE_TIMEOUT } from 'baas-ui/rules/constants';
import ConvertToAdvancedModeModal from 'baas-ui/rules/convert-to-advanced-mode-modal';
import { ViewerState } from 'baas-ui/rules/rule-viewer/RuleViewer';
import { DataSourceRule } from 'baas-ui/rules/types';
import { useRulesPageContext } from 'baas-ui/rules/useRulesPageContext';
import { advancedRuleJSONStrToRule, isRuleAdvanced, RuleMetadata, ruleToAdvancedJSONString } from 'baas-ui/rules/utils';

import './advanced-view.less';

export enum TestSelector {
  Namespace = 'namespace',
  ConvertButton = 'convert-button',
  DescriptionBanner = 'description-banner',
  DescriptionDocLink = 'description-doclink',
  Editor = 'editor',
  ConvertModal = 'convert-modal',
  RuleSaveErrorBanner = 'rule-save-error-banner',
  ParsingErrorBanner = 'parsing-error-banner',
}

export interface Props {
  headerLeftComponent: React.ReactNode;
  rule: DataSourceRule;
  namespace: string;
  setViewerState(viewerState: ViewerState): void;
  onDiscardChanges(setterFn: Function): void;
}

const baseClassName = 'advanced-view';

const advancedModeDocLink = (
  <DocLink
    href={docLinks.Rules.AdvancedRules}
    showExternalIcon
    data-testid={TestSelector.DescriptionDocLink}
    data-test-selector={TestSelector.DescriptionDocLink}
  >
    Learn about advanced mode
  </DocLink>
);

const AdvancedRuleEditor = styled(CodeEditor)`
  height: 450px;
`;

export default function AdvancedViewComponent({
  headerLeftComponent,
  rule,
  namespace,
  setViewerState,
  onDiscardChanges,
}: Props) {
  const [isConverted, setIsConverted] = useState(isRuleAdvanced(rule));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ruleJSON, setRuleJSON] = useState(ruleToAdvancedJSONString(rule));
  const [ruleJSONParseError, setRuleJSONParseError] = useState('');

  const [, dbName, collName] = namespace && namespace.split(':', 3);
  const ruleIsNamespaceRule = !!dbName && !!collName;

  const {
    ruleSaveError,
    activeRule,
    pristineRule,
    rulesDispatchActions: { setActiveRule },
    setOnSaveSuccess,
    setOnDiscardChanges,
  } = useRulesPageContext();

  useEffect(() => {
    setOnDiscardChanges(onDiscardChanges);
  }, []);

  const metadata: RuleMetadata = ruleIsNamespaceRule
    ? { id: pristineRule?.id, database: dbName, collection: collName }
    : { id: pristineRule?.id };

  const parseRuleJSON = (value: string) => {
    let newActiveRule: DataSourceRule;
    try {
      newActiveRule = advancedRuleJSONStrToRule(value, metadata);
      setActiveRule(newActiveRule);
    } catch (e) {
      setRuleJSONParseError(e.message);
    }
  };

  const parseRuleJSONDebounced = useDebouncedCallback(parseRuleJSON, PARSE_JSON_DEBOUNCE_TIMEOUT);

  const handleOnChange = (value: string) => {
    setRuleJSON(value);
    setRuleJSONParseError('');
    parseRuleJSONDebounced(value);
  };

  useEffect(() => {
    setOnSaveSuccess(() => setViewerState(ViewerState.AdvancedView));
  }, []);

  useEffect(() => {
    let jsonIsInvalid = false;
    let displayedRule: DataSourceRule;
    try {
      displayedRule = advancedRuleJSONStrToRule(ruleJSON, metadata);
    } catch {
      jsonIsInvalid = true;
    }

    // reset if discard changes was clicked in the header
    if (!equal(activeRule, displayedRule) || jsonIsInvalid) {
      setRuleJSON(ruleToAdvancedJSONString(activeRule));
    }
  }, [activeRule]);

  return (
    <div className={baseClassName} data-cy="advanced-view">
      <div className={`${baseClassName}-namespace`}>
        <Body
          className={`${baseClassName}-namespace-text`}
          data-testid={TestSelector.Namespace}
          data-test-selector={TestSelector.Namespace}
        >
          {namespace}
        </Body>
      </div>
      <div className={`${baseClassName}-content`}>
        {!isConverted && (
          <div className={`${baseClassName}-header`}>
            {headerLeftComponent}
            <Button
              onClick={() => setIsModalOpen(true)}
              data-test-selector={TestSelector.ConvertButton}
              data-cy={TestSelector.ConvertButton}
              data-testid={TestSelector.ConvertButton}
            >
              Convert to Advanced Mode
            </Button>
          </div>
        )}
        <div className={`${baseClassName}-description`}>
          {!isConverted ? (
            <Banner data-testid={TestSelector.DescriptionBanner} data-test-selector={TestSelector.DescriptionBanner}>
              This is a read-only preview of advanced mode. {advancedModeDocLink}
            </Banner>
          ) : (
            advancedModeDocLink
          )}
          {ruleSaveError && (
            <Banner
              data-testid={TestSelector.RuleSaveErrorBanner}
              data-test-selector={TestSelector.RuleSaveErrorBanner}
              variant={Variant.Danger}
            >
              {ruleSaveError}
            </Banner>
          )}
        </div>

        {ruleJSONParseError && (
          <Banner
            variant={Variant.Danger}
            className={`${baseClassName}-parsing-error-banner`}
            data-test-selector={TestSelector.ParsingErrorBanner}
          >
            {ruleJSONParseError}
          </Banner>
        )}

        <AdvancedRuleEditor
          data-cy="advanced-rule-json-editor"
          data-test-selector={TestSelector.Editor}
          data-testid={TestSelector.Editor}
          functionInput={ruleJSON}
          language={SupportedLanguages.JSON}
          readOnly={!isConverted}
          onChange={handleOnChange}
        />
      </div>
      <ConvertToAdvancedModeModal
        open={isModalOpen}
        ruleName={namespace}
        onConfirm={() => {
          setIsConverted(true);
          setIsModalOpen(false);
        }}
        onCancel={() => setIsModalOpen(false)}
        data-testid={TestSelector.ConvertModal}
        data-test-selector={TestSelector.ConvertModal}
      />
    </div>
  );
}
