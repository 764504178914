import React from 'react';
import { Link } from '@leafygreen-ui/typography';

import { externalLinks } from 'baas-ui/common/links';
import GuideContentBody from 'baas-ui/guides/content/body';

export enum TestSelector {
  FeedbackLink = 'feedback-link',
}

const GuideFeedbackBlurb = () => (
  <GuideContentBody>
    Was this guide helpful? We would love to hear your feedback&nbsp;
    <Link data-test-selector={TestSelector.FeedbackLink} href={externalLinks.FeedbackLink}>
      here
    </Link>
    .
  </GuideContentBody>
);

export default GuideFeedbackBlurb;
