import React from 'react';
import { useThemeValue } from '@mongodb-js/darkreader';

import { useDarkMode } from 'baas-ui/theme';

export enum BarWidth {
  mini = 90,
  regular = 195,
}

const VIEWBOX_HEIGHT = 10;
const VIEWBOX_HEIGHT_1_2 = VIEWBOX_HEIGHT / 2;

const STROKE_WIDTH = 7;
const STROKE_WIDTH_3_4 = (3 * STROKE_WIDTH) / 4;
const STROKE_WIDTH_1_2 = STROKE_WIDTH / 2;

export const FILL_COLOR = 'gray';
export const EMPTY_COLOR = 'grayLight2';
const DARKMODE_FILL_COLOR = 'grayLight3';
const DARKMODE_EMPTY_COLOR = 'grayDark1';

const MINIMUM_FILL_THRESHOLD = 0.01;

export interface Props {
  threshold: number;
  usage: number;
  barWidth?: BarWidth;
}

export enum TestSelector {
  CapacityBar = 'capacity-bar',
  RoundedFillBar = 'rounded-fill-bar',
  ButtedFillBar = 'butted-fill-bar',
}

function UsageBar({ threshold, usage, barWidth = BarWidth.regular }: Props) {
  const atCapacity = threshold === 0 ? false : usage >= threshold;
  const percentage = threshold === 0 ? 0 : usage / threshold;

  // to account for the rounded linecaps, the stroke width of the bar must be
  // subtracted from the view box width
  const capacityLength = barWidth - STROKE_WIDTH;

  // fill length represents how much of the view box should be filled,
  // with respect to both the measurement usage and threshold
  const fillLength = capacityLength * percentage;

  // rounded fill length must substract the stroke width from the actual fill length
  // to account for the rounded linecaps
  const roundedFillLength = fillLength - STROKE_WIDTH;

  const darkMode = useDarkMode();
  const themeValue = useThemeValue(darkMode);
  const fillColor = themeValue({ dark: DARKMODE_FILL_COLOR, light: FILL_COLOR });
  const emptyColor = themeValue({ dark: DARKMODE_EMPTY_COLOR, light: EMPTY_COLOR });
  return (
    <svg width={`${barWidth}px`} height="10px">
      <path
        d={`M${STROKE_WIDTH_1_2},5 h${capacityLength}`}
        stroke={atCapacity ? fillColor : emptyColor}
        strokeWidth={STROKE_WIDTH}
        strokeLinecap="round"
        data-test-selector={TestSelector.CapacityBar}
      />

      {percentage >= MINIMUM_FILL_THRESHOLD && !atCapacity && (
        <>
          <path
            d={`M${STROKE_WIDTH_1_2},${VIEWBOX_HEIGHT_1_2} h${Math.max(0, roundedFillLength)}`}
            stroke={fillColor}
            strokeWidth={STROKE_WIDTH}
            strokeLinecap="round"
            data-test-selector={TestSelector.RoundedFillBar}
          />
          {roundedFillLength > 0 && (
            <path
              d={`M${STROKE_WIDTH_3_4},${VIEWBOX_HEIGHT_1_2} h${fillLength - STROKE_WIDTH_3_4}`}
              stroke={fillColor}
              strokeWidth={STROKE_WIDTH}
              data-test-selector={TestSelector.ButtedFillBar}
            />
          )}
        </>
      )}
    </svg>
  );
}

export default UsageBar;
