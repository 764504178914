import React from 'react';

import { TrackableEvent } from './types';
import { track } from '.';

export default function usePageLoadTracker(eventKey: TrackableEvent, args: object = {}) {
  React.useEffect(() => {
    track(eventKey, args);
  }, []);
}
