import React, { createContext, useCallback, useContext, useState } from 'react';
import { connect } from 'react-redux';

import usePoller from 'baas-ui/common/hooks/use-poller';
import * as actions from 'baas-ui/deploy/actions';
import ConfirmDeploymentDiff, {
  PublicProps as ConfirmDeploymentDiffProps,
} from 'baas-ui/deploy/confirm-deployment-diff';
import * as sagas from 'baas-ui/deploy/sagas';
import { AsyncDispatch, AsyncExecutorPayload } from 'baas-ui/redux_util';
import { getAppState, getDeploymentState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';
import { Deployment, PartialDraft } from 'admin-sdk';

export enum DeployStatus {
  Deploying = 'deploying',
  Drafting = 'draft',
  Creating = 'creating',
  Discarding = 'discarding',
  Error = 'error',
  Failure = 'failure',
  None = 'none',
  Successful = 'successful',
  SuccessfulWithWarning = 'successfulWithWarning',
}

export interface DeployContextValue {
  setCreatingDraft(isCreatingDraft: boolean, creatingDraftMessage?: string): void;
  setDisableBanner(disableBanner: boolean): void;
  openDeployDraftModal(): void;
  pushApp(): Promise<void>;
  status: DeployStatus;
  message: string;
  deployedAt?: number;
  disableBanner: boolean;
}

export const DeployContext = createContext<DeployContextValue>({
  setCreatingDraft: () => {},
  setDisableBanner: () => {},
  openDeployDraftModal: () => {},
  pushApp: () => Promise.resolve(),
  status: DeployStatus.None,
  message: '',
  deployedAt: undefined,
  disableBanner: false,
});

interface ReduxStateProps {
  autoDeployEnabled: boolean;
  draft: PartialDraft | undefined;
  latestDeployment: Deployment | undefined;
}

interface ReduxDispatchProps {
  deployDraft(
    pushToGithub: boolean,
    draftId: string,
    deploymentName?: string
  ): AsyncExecutorPayload<typeof sagas.deployDraft>;
  discardDraft(draftId: string): AsyncExecutorPayload<typeof sagas.discardDraft>;
  loadDeployConfig(): AsyncExecutorPayload<typeof actions.loadDeployConfig>;
  loadDrafts(): AsyncExecutorPayload<typeof actions.loadDrafts>;
  loadDeployHistory(): AsyncExecutorPayload<typeof actions.loadDeployHistory>;
  loadLatestDeployment(): AsyncExecutorPayload<typeof actions.loadLatestDeployment>;
  pushApp(): AsyncExecutorPayload<typeof actions.pushApp>;
}

interface PublicProps {
  children: React.ReactNode;
}

export type Props = ReduxStateProps & ReduxDispatchProps & PublicProps;

export function DeployContextProviderComponent({
  autoDeployEnabled,
  children,
  deployDraft,
  discardDraft,
  draft,
  latestDeployment,
  loadDeployHistory,
  loadDrafts,
  loadDeployConfig,
  loadLatestDeployment,
  pushApp,
}: Props) {
  const poller = usePoller();
  const [message, setMessage] = useState('');
  const [deployStatus, setDeployStatus] = useState(DeployStatus.None);
  const [disableBanner, setDisableBanner] = useState(false);
  const [confirmDiffModalProps, setConfirmDiffModalProps] = useState<ConfirmDeploymentDiffProps>({
    isOpen: false,
    onCancel: () => {},
    onDiscard: () => {},
    onDeploy: () => {},
  });

  const setCreatingDraft = (isCreatingDraft: boolean, creatingDraftMessage = '') => {
    if (isCreatingDraft) {
      setDeployStatus(DeployStatus.Creating);
    } else {
      setDeployStatus(draft ? DeployStatus.Drafting : DeployStatus.None);
    }
    setMessage(creatingDraftMessage);
  };

  const handlePushApp = async () => {
    setDeployStatus(DeployStatus.Deploying);
    try {
      await pushApp();
      poller.start(loadLatestDeployment, 2000);
    } catch (e) {
      setMessage(e?.message);
      setDeployStatus(DeployStatus.Error);
    }
  };

  const handleOpenDeployDraftModal = useCallback(() => {
    setConfirmDiffModalProps({
      isOpen: true,
      onCancel: () =>
        setConfirmDiffModalProps({
          ...confirmDiffModalProps,
          isOpen: false,
        }),
      onDiscard: () => {
        setDeployStatus(DeployStatus.Discarding);
        setConfirmDiffModalProps({
          ...confirmDiffModalProps,
          isOpen: false,
        });

        if (draft) {
          discardDraft(draft.id);
        }
      },
      onDeploy: (deploymentName) => {
        setConfirmDiffModalProps({
          ...confirmDiffModalProps,
          isOpen: false,
        });

        if (draft) {
          setDeployStatus(DeployStatus.Deploying);
          deployDraft(autoDeployEnabled, draft.id, deploymentName)
            .then(() => {
              poller.start(loadLatestDeployment, 2000);
            })
            .catch((error) => {
              setMessage(error.message);
              setDeployStatus(DeployStatus.Error);
            });
        }
      },
    });
  }, [draft, autoDeployEnabled]);

  React.useEffect(() => {
    loadDrafts();
    loadDeployConfig();
    loadLatestDeployment();
  }, []);

  React.useEffect(() => {
    if (deployStatus === DeployStatus.Deploying) {
      switch (latestDeployment?.status) {
        case 'successful':
          setDeployStatus(DeployStatus.Successful);
          loadDeployHistory();
          poller.stop();
          break;
        case 'successfulWithWarning':
          setDeployStatus(DeployStatus.SuccessfulWithWarning);
          loadDeployHistory();
          setMessage(latestDeployment.statusErrorMessage);
          poller.stop();
          break;
        case 'failed':
          setDeployStatus(DeployStatus.Failure);
          setMessage(latestDeployment.statusErrorMessage);
          poller.stop();
          break;
        default:
      }
    } else if (latestDeployment?.status === 'created' || latestDeployment?.status === 'pending') {
      setDeployStatus(DeployStatus.Deploying);
      poller.start(loadLatestDeployment, 2000);
    }
  }, [latestDeployment]);

  React.useEffect(() => {
    if (!draft) {
      if (deployStatus === DeployStatus.Deploying) {
        setDeployStatus(DeployStatus.Successful);
      } else {
        setDeployStatus(DeployStatus.None);
      }
    } else if (deployStatus === DeployStatus.None || latestDeployment?.draftId !== draft.id) {
      setDeployStatus(DeployStatus.Drafting);
    }
  }, [draft]);

  return (
    <DeployContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        setCreatingDraft,
        setDisableBanner,
        openDeployDraftModal: handleOpenDeployDraftModal,
        pushApp: handlePushApp,
        status: deployStatus,
        message,
        deployedAt: latestDeployment?.deployedAt,
        disableBanner,
      }}
    >
      {confirmDiffModalProps.isOpen && <ConfirmDeploymentDiff {...confirmDiffModalProps} />}
      {children}
    </DeployContext.Provider>
  );
}

const mapStateToProps = (state: RootState) => {
  const { app } = getAppState(state);
  const {
    draft,
    latestDeployment,
    deployConfig: {
      automaticDeploymentConfig: { enabled },
    },
  } = getDeploymentState(state);

  return {
    appId: app.id,
    draft,
    groupId: app.groupId,
    latestDeployment,
    autoDeployEnabled: enabled,
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  deployDraft: (groupId: string, appId: string, draftId: string, pushToGithub: boolean, deploymentName?: string) =>
    dispatch(sagas.deployDraft({ groupId, appId, draftId, pushToGithub, deploymentName })),
  discardDraft: (groupId: string, appId: string, draftId: string) =>
    dispatch(sagas.discardDraft(groupId, appId, draftId)),
  loadDeployHistory: (groupId: string, appId: string) =>
    dispatch(actions.loadDeployHistory({ groupId, appId, filter: { before: Date.now() } })),
  loadDrafts: (groupId: string, appId: string) => dispatch(actions.loadDrafts({ groupId, appId })),
  loadDeployConfig: (groupId: string, appId: string) => dispatch(actions.loadDeployConfig({ groupId, appId })),
  loadLatestDeployment: (groupId: string, appId: string) => dispatch(actions.loadLatestDeployment({ groupId, appId })),
  pushApp: (groupId: string, appId: string) => dispatch(actions.pushApp({ groupId, appId })),
});

const mergeProps = (
  { appId, groupId, ...otherStateProps }: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: Omit<Props, keyof (ReduxStateProps & ReduxDispatchProps)>
): Props => ({
  ...ownProps,
  ...otherStateProps,
  loadDrafts: () => dispatchProps.loadDrafts(groupId, appId),
  deployDraft: (pushToGithub: boolean, draftId: string, deploymentName?: string) =>
    dispatchProps.deployDraft(groupId, appId, draftId, pushToGithub, deploymentName),
  loadDeployConfig: () => dispatchProps.loadDeployConfig(groupId, appId).catch(() => {}),
  discardDraft: (draftId: string) => dispatchProps.discardDraft(groupId, appId, draftId),
  loadDeployHistory: () => dispatchProps.loadDeployHistory(groupId, appId).catch(() => {}),
  loadLatestDeployment: () => dispatchProps.loadLatestDeployment(groupId, appId),
  pushApp: () => dispatchProps.pushApp(groupId, appId),
});

export const DeployContextProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DeployContextProviderComponent);

export function useDeployContext() {
  const value = useContext(DeployContext);
  if (!value) {
    throw new Error('useDeployContext must be used within a DeployContextProvider');
  }

  return value;
}
