import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import { NullTypeSchemaValidationSetting, PutDeploymentMigrationRequest } from 'admin-sdk';

const NAME = 'null_type_schema_validation/';

interface UpdateNullTypeSchemaValSettingPayload extends BaseRequestPayload {
  setting: NullTypeSchemaValidationSetting;
}

interface PutDeploymentMigrationPayload extends BaseRequestPayload {
  deploymentMigration: PutDeploymentMigrationRequest;
}

export const [loadNullTypeSchemaValidationSettingActions, loadNullTypeSchemaValidationSetting] =
  createActionsAndExecutor<BaseRequestPayload, NullTypeSchemaValidationSetting>(
    `${NAME}load setting data`,
    (client, { groupId, appId }: BaseRequestPayload) =>
      () =>
        client.apps(groupId).app(appId).validationSettings().nullTypeSchemaValidation().get()
  );

export const [updateNullTypeSchemaValidationSettingActions, updateNullTypeSchemaValidationSetting] =
  createActionsAndExecutor<UpdateNullTypeSchemaValSettingPayload, null>(
    `${NAME}update setting data`,
    (client, { groupId, appId, setting }: UpdateNullTypeSchemaValSettingPayload) =>
      () =>
        client.apps(groupId).app(appId).validationSettings().nullTypeSchemaValidation().update(setting)
  );

export const [deploymentMigrationActions, putDeploymentMigration] = createActionsAndExecutor<
  PutDeploymentMigrationPayload,
  void
>(
  `${NAME}put deployment migration`,
  (client, { groupId, appId, deploymentMigration }: PutDeploymentMigrationPayload) =>
    () =>
      client.apps(groupId).app(appId).deploymentMigration().put(deploymentMigration)
);

export const asyncSettingsActions = [deploymentMigrationActions.rcv];
