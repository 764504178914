import { Middleware } from 'redux';

import * as actions from 'baas-ui/deploy/actions';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { getAppState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';

import { asyncEditRcvActions, getActionType } from './constants';

const deployMiddleware: Middleware<{}, RootState, AsyncDispatch> =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const { app } = getAppState(getState());

    if (asyncEditRcvActions[getActionType(action)]) {
      dispatch(actions.loadDrafts({ appId: app.id, groupId: app.groupId }));
    }

    next(action);
  };

export default deployMiddleware;
