import React, { useEffect, useState } from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import useDarkReader, { ThemePreference } from '@mongodb-js/darkreader';

import theme from 'baas-ui/theme/theme';

// isDarkMode returns whether the app is in dark mode as determined by the theme preference and OS settings
export const isDarkMode = (themePreference: ThemePreference): boolean => {
  if (themePreference === ThemePreference.DARK) {
    return true;
  }
  if (themePreference === ThemePreference.LIGHT) {
    return false;
  }
  // ThemePreference.OS
  return window.matchMedia('(prefers-color-scheme: dark)').matches;
};

interface Props {
  children: React.ReactNode;
  themePreference: ThemePreference;
}

// ThemeProvider is a wrapper around Emotion's ThemeProvider. It handles setting up darkreader
// and managing the darkMode value in the theme object. This component must be a child of the
// LeafyGreenProvider as darkreader uses hooks that interact with it
const ThemeProvider = ({ children, themePreference }: Props) => {
  useDarkReader(themePreference);
  const [providerTheme, setProviderTheme] = useState({
    ...theme,
    darkMode: isDarkMode(themePreference),
    isOSPreference: themePreference === ThemePreference.OS,
  });

  // subscribe to OS preferences changes if ThemePreference OS
  useEffect(() => {
    const listenToOSDarkMode = (e: MediaQueryListEvent) => {
      setProviderTheme((prevTheme) => ({ ...prevTheme, darkMode: e.matches }));
    };
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    if (themePreference === ThemePreference.OS) {
      mediaQuery.addEventListener('change', listenToOSDarkMode);
    }

    return () => {
      mediaQuery.removeEventListener('change', listenToOSDarkMode);
    };
  }, []);

  return <EmotionThemeProvider theme={providerTheme}>{children}</EmotionThemeProvider>;
};

export default ThemeProvider;
