import moment from 'moment';

import { loadMetrics } from 'baas-ui/app/metrics-card/actions';
import { Granularity, Period } from 'baas-ui/app/metrics-card/time_options';
import { loadEvents } from 'baas-ui/events/actions';
import { loadAppMeasurements } from 'baas-ui/measurements/actions';
import { DEFAULT_MEASUREMENTS, MeasurementsByName } from 'baas-ui/measurements/types';
import { legacyMapMeasurementsByName } from 'baas-ui/measurements/utils';
import { MetricName } from 'baas-ui/metrics/types';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { loadRules } from 'baas-ui/rules/actions';
import { loadServices, loadSvcConfig } from 'baas-ui/services/actions';
import { isAtlasMongoService, isSelfHostedMongoService } from 'baas-ui/services/registry';
import { EventSubscription, MeasurementGroupGranularity, PartialApp, PartialServiceDesc } from 'admin-sdk';

import { countUsers } from './actions';
import { AppDataResult } from './types';

export const getQuickStartDataFromApp = (dispatch: AsyncDispatch) => async (groupId: string, appId: string) => {
  const [services, hasTrigger] = await Promise.all([
    dispatch(loadServices({ groupId, appId })),
    dispatch(loadEvents({ groupId, appId })).then((events: EventSubscription[]) => events.length > 0),
  ]);
  if (!services) {
    return { hasTrigger };
  }

  const atlasServices = services.filter((svc: PartialServiceDesc) => isAtlasMongoService(svc.type));
  const mongoServices = services.filter((svc: PartialServiceDesc) => isSelfHostedMongoService(svc.type));

  let linkedClusterName = '';
  if (atlasServices.length) {
    const svcConfig = await dispatch(loadSvcConfig({ groupId, appId, svcId: atlasServices[0].id }));
    linkedClusterName = svcConfig?.config?.clusterName;
  } else if (mongoServices.length) {
    linkedClusterName = mongoServices[0].name;
  }

  const atlasNamespacePromises = atlasServices.map((dataSource: PartialServiceDesc) =>
    dispatch(loadRules({ groupId, appId, dataSourceId: dataSource.id }))
  );
  const mongoNamespacePromises = mongoServices.map((dataSource: PartialServiceDesc) =>
    dispatch(loadRules({ groupId, appId, dataSourceId: dataSource.id }))
  );

  const hasCollection = await Promise.all([...atlasNamespacePromises, ...mongoNamespacePromises]).then(
    (serviceRulesArray) => serviceRulesArray.some((serviceRules) => serviceRules && serviceRules.length)
  );

  return { linkedClusterName, hasCollection, hasTrigger };
};

export const getAppData =
  (dispatch: AsyncDispatch) =>
  async (app: PartialApp, isPricingChangeEnabled: boolean): Promise<AppDataResult> => {
    const { groupId, id: appId } = app;
    const countUsersP = dispatch(countUsers({ groupId, appId }));

    const startTime = isPricingChangeEnabled ? moment().utc().startOf('day') : moment().utc().startOf('month');
    const endTime = isPricingChangeEnabled ? moment().utc().endOf('day') : moment().utc().endOf('month');

    const loadAppMeasurementsP = dispatch(
      loadAppMeasurements({
        groupId,
        appId,
        filter: {
          start: startTime.toDate(),
          end: endTime.toDate(),
          granularity: MeasurementGroupGranularity.Monthly,
        },
      })
    );

    const loadLastHourRequestsP = dispatch(
      loadMetrics({
        appId,
        groupId,
        options: {
          period: Period.PT1H,
          granularity: Granularity.PT1M,
          metrics: [MetricName.OVERALL_SUCCESSFUL_REQUESTS, MetricName.OVERALL_FAILED_REQUESTS],
        },
      })
    );

    let userCount: number;
    try {
      userCount = (await countUsersP) as number;
    } catch (e) {
      userCount = -1;
    }

    let measurementsByName: MeasurementsByName;
    try {
      const rawMeasurements = await loadAppMeasurementsP;
      measurementsByName = legacyMapMeasurementsByName((rawMeasurements && rawMeasurements.measurements) || []);
    } catch (e) {
      measurementsByName = DEFAULT_MEASUREMENTS;
    }

    let lastHourSuccessfulRequests = -1;
    let lastHourFailedRequests = -1;
    try {
      const requestMeasurements = await loadLastHourRequestsP;
      lastHourSuccessfulRequests =
        requestMeasurements?.measurements.find((m) => m.name === MetricName.OVERALL_SUCCESSFUL_REQUESTS)?.totalUsage ??
        -1;
      lastHourFailedRequests =
        requestMeasurements?.measurements.find((m) => m.name === MetricName.OVERALL_FAILED_REQUESTS)?.totalUsage ?? -1;
    } catch (e) {
      lastHourSuccessfulRequests = -1;
      lastHourFailedRequests = -1;
    }

    return {
      userCount,
      lastHourSuccessfulRequests,
      lastHourFailedRequests,
      measurementsByName,
    };
  };
