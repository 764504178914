import React, { useContext, useState } from 'react';
import Icon from '@leafygreen-ui/icon';
import IconButton from '@leafygreen-ui/icon-button';
import { Menu, MenuItem } from '@leafygreen-ui/menu';
import classNames from 'classnames';

import { CollectionMenuProps } from 'baas-ui/common/components/collection-explorer/collection-explorer/CollectionExplorer';
import { RulesPageCollectionData } from 'baas-ui/rules/dataSourceData';
import { RulesPageContext } from 'baas-ui/rules/RulesPage';

import 'baas-ui/common/components/collection-explorer/collection-explorer/collection-explorer.less';

const collectionExplorerBaseClassName = 'collection-explorer';

export enum TestSelector {
  MenuTrigger = 'menu-trigger',
  DeleteRules = 'delete-rules',
}

export default function CollectionMenu({
  onTriggerClick,
  collectionData,
}: CollectionMenuProps<RulesPageCollectionData>) {
  const [open, setOpen] = useState(false);
  const { onClickDeleteAction, deletingCollections, deletingDatabases, deletingDatasources } =
    useContext(RulesPageContext);
  const { data, collectionName } = collectionData;
  const isDeletingFromCurrentCollection = deletingCollections.has(collectionName);
  const isDeletingFromCurrentDatabase =
    !!data.partialRule && !!data.partialRule.database && deletingDatabases.has(data.partialRule.database);
  const isDeletingFromCurrentDatasource = !!data.dataSourceId && deletingDatasources.has(data.dataSourceId);
  const isDeletingOwnRules =
    isDeletingFromCurrentCollection || isDeletingFromCurrentDatabase || isDeletingFromCurrentDatasource;

  return (
    <Menu
      open={open}
      setOpen={setOpen}
      trigger={
        <IconButton
          className={classNames(`${collectionExplorerBaseClassName}-list-item__menu-trigger`, {
            [`${collectionExplorerBaseClassName}-list-item__menu-trigger-visible`]: open,
          })}
          aria-label="Collection Options"
          data-cy="collection-menu-trigger"
          data-testid={TestSelector.MenuTrigger}
          onClick={onTriggerClick}
        >
          <Icon glyph="Ellipsis" />
        </IconButton>
      }
    >
      <MenuItem
        className={classNames({
          'collection-explorer__menu-item--destructive': !!data.partialRule && !isDeletingOwnRules,
        })}
        disabled={!data.partialRule || isDeletingOwnRules}
        data-cy="collection-menu-delete-rules"
        data-testid={TestSelector.DeleteRules}
        onClick={() => {
          if (data.partialRule && data.partialRule.id && data.dataSourceId && !isDeletingOwnRules) {
            onClickDeleteAction({
              ruleId: data.partialRule.id!,
              dataSourceId: data.dataSourceId,
              collectionName,
            });
          }
        }}
      >
        Delete roles and filters
      </MenuItem>
    </Menu>
  );
}
