import { JsonObject, JsonProperty } from 'json2typescript';

export enum RequestOrigin {
  UI = 'UI',
  AdminAPI = 'Admin API',
  CLI = 'CLI',
  GitHubWebhook = 'GitHub Webhook',
}

export enum DeploymentStatus {
  Created = 'created',
  Successful = 'successful',
  SuccessfulWithWarning = 'successfulWithWarning',
  Failed = 'failed',
  Pending = 'pending',
}

export enum AppConfig {
  Version20180301 = '20180301',
  Version20200603 = '20200603',
  Version20210101 = '20210101',
}

@JsonObject('Deployment')
export class Deployment {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('app_id')
  public appId = '';

  @JsonProperty('draft_id', String, true)
  public draftId?: string = undefined;

  @JsonProperty('user_id', String, true)
  public userId?: string = undefined;

  @JsonProperty('deployed_at')
  public deployedAt = 0;

  @JsonProperty('origin')
  public origin: RequestOrigin = RequestOrigin.UI;

  @JsonProperty('commit')
  public commit = '';

  @JsonProperty('status')
  public status: DeploymentStatus = DeploymentStatus.Pending;

  @JsonProperty('status_error_message')
  public statusErrorMessage = '';

  @JsonProperty('diff_url')
  public diffUrl = '';

  @JsonProperty('name', String, true)
  public name?: string = undefined;

  @JsonProperty('remote_location', String, true)
  public remoteLocation?: string = undefined;

  @JsonProperty('app_diff', [String], true)
  public appDiff?: string[] = undefined;

  constructor(partial?: Partial<Deployment>) {
    Object.assign(this, partial);
  }
}

@JsonObject('DeployDraftPayload')
export class DeployDraftPayload {
  @JsonProperty('name', String, true)
  public name?: string = undefined;

  constructor(partial?: Partial<DeployDraftPayload>) {
    Object.assign(this, partial);
  }
}

export interface DeploymentsFilter {
  before?: number;
  limit?: number;
  draftId?: string;
  userId?: string;
}
