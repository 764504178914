import React from 'react';

import { IconProps } from './types';

export enum TestSelector {
  Icon = 'icon',
}

const SwiftIcon = ({ className, height = 25, width = 25 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={TestSelector.Icon}
    >
      <path
        d="M12.3273 0C21.1196 5.86739 18.2684 12.3148 18.2684 12.3148C18.2684 12.3148 20.7659 15.0785 19.7621 17.4997C19.7621 17.4997 18.7259 15.8085 17.0059 15.8085C15.3435 15.8085 14.3597 17.4997 11.0035 17.4997C3.53868 17.4997 0 11.3823 0 11.3823C6.72737 15.7322 11.3235 12.6498 11.3235 12.6498C8.28609 10.9273 1.84372 2.6787 1.84372 2.6787C7.45986 7.36736 9.88356 8.59859 9.88356 8.59859C8.42609 7.42861 4.36492 1.69497 4.36492 1.69497C7.61486 4.91866 14.0747 9.41357 14.0747 9.41357C15.9272 4.44617 12.3273 0 12.3273 0Z"
        fill="#FF5722"
      />
    </svg>
  );
};

export default SwiftIcon;
