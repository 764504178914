import { AuthProviderType } from 'admin-sdk';

export const PROVIDER_OPTIONS = [
  { id: AuthProviderType.Anonymous, name: 'Anonymous Login' },
  { id: AuthProviderType.Facebook, name: 'Facebook' },
  { id: AuthProviderType.Google, name: 'Google' },
  { id: AuthProviderType.Apple, name: 'Apple ID' },
  { id: AuthProviderType.APIKey, name: 'API Keys' },
  { id: AuthProviderType.Userpass, name: 'Email/Password' },
  { id: AuthProviderType.Custom, name: 'Custom JWT Authentication' },
  { id: AuthProviderType.CustomFunction, name: 'Custom Function Authentication' },
];

export const NEW_PROVIDER_ID = '__new__';

export const PROVIDER_OPTIONS_BY_TYPE = PROVIDER_OPTIONS.reduce((acc, opt) => ({ ...acc, [opt.id]: opt }), {});

export const ALL_PROVIDERS = PROVIDER_OPTIONS.map(({ id }) => id);

export const PROVIDER_TITLES = {
  [AuthProviderType.Anonymous]: 'Allow users to log in anonymously',
  [AuthProviderType.Userpass]: 'Email/Password',
  [AuthProviderType.Facebook]: 'Facebook',
  [AuthProviderType.Google]: 'Google',
  [AuthProviderType.Apple]: 'Apple',
  [AuthProviderType.APIKey]: 'API Keys',
  [AuthProviderType.Custom]: 'Custom JWT Authentication',
  [AuthProviderType.CustomFunction]: 'Custom Function Authentication',
};

export const PROVIDER_ENABLED = 'On';
export const PROVIDER_DISABLED = 'Off';
