import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

function NavItem(props) {
  const { linkClassName } = props;

  const propsCopy = { ...props };
  [
    'router',
    'className',
    'linkClassName',
    'activeLinkClassName',
    'onlyActiveOnIndex',
    'match',
    'history',
    'staticContext',
  ].forEach((prop) => {
    delete propsCopy[prop];
  });

  propsCopy.className = linkClassName;

  return (
    <li className={props.className}>
      <NavLink {...propsCopy}>{props.children}</NavLink>
    </li>
  );
}
NavItem.defaultProps = {
  className: '',
  children: null,
  exact: false,
  onlyActiveOnIndex: false,
  linkClassName: '',
  activeLinkClassName: '',
  activeClassName: '',
  router: null,
};

NavItem.propTypes = {
  className: PropTypes.string,
  onlyActiveOnIndex: PropTypes.bool,
  exact: PropTypes.bool,
  to: PropTypes.string.isRequired,
  router: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  linkClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  activeLinkClassName: PropTypes.string,
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

export default withRouter(NavItem);
