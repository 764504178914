import React from 'react';

export const FlagUSA = () => (
  <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H18V9H0V0Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 0H18V1H9V0Z" fill="#FF7B7B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 2H18V3H9V2Z" fill="#FF7B7B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 4H18V5H9V4Z" fill="#FF7B7B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H9V5H0V0Z" fill="#008EFF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 6H18V7H0V6Z" fill="#FF7B7B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 8H18V9H0V8Z" fill="#FF7B7B" />
  </svg>
);

export const FlagSingapore = () => (
  <svg width="19" height="9" viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_907_50482)">
      <path d="M0.5 0H18.5V9H0.5V0Z" fill="white" stroke="#FA787A" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1 0.5H18V5H1V0.5Z" fill="#FF7B7B" />
      <path
        d="M3.18578 2.63292C3.1861 1.67716 3.59387 0.899237 4.50404 0.887348C4.29791 0.802466 4.07854 0.758577 3.85713 0.757919C2.8709 0.757921 2.07141 1.59739 2.07141 2.63292C2.07141 3.66845 2.8709 4.50792 3.85713 4.50792C4.0801 4.50784 4.3011 4.46392 4.50867 4.37842C3.59671 4.36918 3.18616 3.59052 3.18578 2.63292Z"
        fill="white"
      />
      <path
        d="M6.07143 1.68462C6.31723 1.68462 6.51648 1.4754 6.51648 1.21731C6.51648 0.959221 6.31723 0.75 6.07143 0.75C5.82563 0.75 5.62637 0.959221 5.62637 1.21731C5.62637 1.4754 5.82563 1.68462 6.07143 1.68462Z"
        fill="white"
      />
      <path
        d="M4.58791 2.61923C4.8337 2.61923 5.03296 2.41001 5.03296 2.15192C5.03296 1.89384 4.8337 1.68462 4.58791 1.68462C4.34211 1.68462 4.14285 1.89384 4.14285 2.15192C4.14285 2.41001 4.34211 2.61923 4.58791 2.61923Z"
        fill="white"
      />
      <path
        d="M5.18129 4.17693C5.42709 4.17693 5.62635 3.9677 5.62635 3.70962C5.62635 3.45153 5.42709 3.24231 5.18129 3.24231C4.93549 3.24231 4.73624 3.45153 4.73624 3.70962C4.73624 3.9677 4.93549 4.17693 5.18129 4.17693Z"
        fill="white"
      />
      <path
        d="M6.96153 4.17693C7.20733 4.17693 7.40659 3.9677 7.40659 3.70962C7.40659 3.45153 7.20733 3.24231 6.96153 3.24231C6.71574 3.24231 6.51648 3.45153 6.51648 3.70962C6.51648 3.9677 6.71574 4.17693 6.96153 4.17693Z"
        fill="white"
      />
      <path
        d="M7.55492 2.61923C7.80072 2.61923 7.99997 2.41001 7.99997 2.15192C7.99997 1.89384 7.80072 1.68462 7.55492 1.68462C7.30912 1.68462 7.10986 1.89384 7.10986 2.15192C7.10986 2.41001 7.30912 2.61923 7.55492 2.61923Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_907_50482">
        <rect width="19" height="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const FlagIndia = () => (
  <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 0H0V9H18V0Z" fill="white" />
    <path d="M18 0H0V3H18V0Z" fill="#FF9A1F" />
    <path d="M18 6H0V9H18V6Z" fill="#028900" />
    <path
      d="M9 5.5C9.55228 5.5 10 5.05228 10 4.5C10 3.94772 9.55228 3.5 9 3.5C8.44772 3.5 8 3.94772 8 4.5C8 5.05228 8.44772 5.5 9 5.5Z"
      fill="#4C4CEC"
    />
    <g filter="url(#filter0_i_907_50470)">
      <path
        d="M9 5.5C9.55228 5.5 10 5.05228 10 4.5C10 3.94772 9.55228 3.5 9 3.5C8.44772 3.5 8 3.94772 8 4.5C8 5.05228 8.44772 5.5 9 5.5Z"
        fill="black"
      />
    </g>
    <path
      d="M9 5.5C9.55228 5.5 10 5.05228 10 4.5C10 3.94772 9.55228 3.5 9 3.5C8.44772 3.5 8 3.94772 8 4.5C8 5.05228 8.44772 5.5 9 5.5Z"
      stroke="#4C4CEC"
    />
    <defs>
      <filter
        id="filter0_i_907_50470"
        x="8"
        y="3.5"
        width="2"
        height="2"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_907_50470" />
      </filter>
    </defs>
  </svg>
);

export const FlagIreland = () => (
  <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H6V9H0V0Z" fill="#3DC65C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6 0H12V9H6V0Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 0H18V9H12V0Z" fill="#FBA279" />
  </svg>
);

export const FlagAustralia = () => (
  <svg width="19" height="9" viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5 0H0.5V9H18.5V0Z" fill="#0071CB" />
    <mask
      id="mask0_907_50481"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="11"
      height="5"
    >
      <path d="M10.5 0H0.5V5H10.5V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_907_50481)">
      <path d="M0.274994 4.72499L10.725 -0.225006" stroke="white" strokeWidth="1.05" strokeLinejoin="bevel" />
      <path d="M0.25 4.75L10.75 -0.25" stroke="#FF7B7B" strokeWidth="0.5" strokeLinejoin="bevel" />
      <path d="M10.725 4.72499L0.275005 -0.225006" stroke="white" strokeWidth="1.05" strokeLinejoin="bevel" />
      <path d="M10.75 4.75L0.25 -0.25" stroke="#FF7B7B" strokeWidth="0.5" strokeLinejoin="bevel" />
      <path d="M10.5 1.5H0.5V2.5H10.5V1.5Z" fill="white" />
      <path d="M10.5 2.5H0.5V3.5H10.5V2.5Z" fill="white" />
      <path d="M5.5 0H4.5V5H5.5V0Z" fill="white" />
      <path d="M6.5 0H5.5V5H6.5V0Z" fill="white" />
      <path d="M6 0H5V5H6V0Z" fill="#FF7B7B" />
      <path d="M10.5 2H0.5V3H10.5V2Z" fill="#FF7B7B" />
    </g>
  </svg>
);

export const FlagGermany = () => (
  <svg width="19" height="9" viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5 0H0.5V3H18.5V0Z" fill="#545454" />
    <path d="M18.5 3H0.5V6H18.5V3Z" fill="#FF7B7B" />
    <path d="M18.5 6H0.5V9H18.5V6Z" fill="#E4EB7D" />
  </svg>
);

export const FlagBritain = () => (
  <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_907_50476"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="9"
    >
      <path d="M18 0H0V9H18V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_907_50476)">
      <path d="M18 0H0V9H18V0Z" fill="#0071CA" />
      <mask
        id="mask1_907_50476"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="-1"
        y="0"
        width="20"
        height="9"
      >
        <path d="M18.0909 0H-0.0909119V9H18.0909V0Z" fill="white" />
      </mask>
      <g mask="url(#mask1_907_50476)">
        <path d="M-0.5 8.505L18.5 -0.404999" stroke="white" strokeWidth="1.5" strokeLinejoin="bevel" />
        <path d="M-0.545456 8.55L18.5455 -0.449997" stroke="#FF7B7B" strokeWidth="0.5" strokeLinejoin="bevel" />
        <path d="M18.5 8.505L-0.5 -0.404999" stroke="white" strokeWidth="1.5" strokeLinejoin="bevel" />
        <path d="M18.5455 8.55L-0.545452 -0.449997" stroke="#FF7B7B" strokeWidth="0.5" strokeLinejoin="bevel" />
        <path d="M18.0909 2.7H-0.0909119V4.5H18.0909V2.7Z" fill="white" />
        <path d="M18.0909 4.5H-0.0909119V6.3H18.0909V4.5Z" fill="white" />
        <path d="M8.99999 0H7.18181V9H8.99999V0Z" fill="white" />
        <path d="M10.8182 0H9V9H10.8182V0Z" fill="white" />
        <path d="M9.90909 0H8.09091V9H9.90909V0Z" fill="#FF7B7B" />
        <path d="M18.0909 3.60001H-0.0909119V5.40001H18.0909V3.60001Z" fill="#FF7B7B" />
      </g>
    </g>
  </svg>
);

export const FlagBrazil = () => (
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_679_32805)">
      <path d="M18 0.500488H0V9.50049H18V0.500488Z" fill="#009C34" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.27515 5.16455L9.16423 1.54837L15.0533 5.16455L9.16423 8.78073L3.27515 5.16455Z"
        fill="#FFE100"
      />
      <mask
        id="mask0_679_32805"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="3"
        width="5"
        height="5"
      >
        <path
          d="M9.25 7.50049C10.4926 7.50049 11.5 6.49313 11.5 5.25049C11.5 4.00785 10.4926 3.00049 9.25 3.00049C8.00736 3.00049 7 4.00785 7 5.25049C7 6.49313 8.00736 7.50049 9.25 7.50049Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_679_32805)">
        <path
          d="M9.25 7.50049C10.4926 7.50049 11.5 6.49313 11.5 5.25049C11.5 4.00785 10.4926 3.00049 9.25 3.00049C8.00736 3.00049 7 4.00785 7 5.25049C7 6.49313 8.00736 7.50049 9.25 7.50049Z"
          fill="#002478"
        />
        <path d="M6.94629 3.80957C6.94629 3.80957 10.4639 3.92139 11.7373 6.34814" stroke="white" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_679_32805">
        <rect width="18" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const FlagNetherlands = () => (
  <svg width="19" height="9" viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5 0H0.5V3H18.5V0Z" fill="#B01923" />
    <path d="M18.5 3H0.5V6H18.5V3Z" fill="white" />
    <path d="M18.5 6H0.5V9H18.5V6Z" fill="#1E448D" />
  </svg>
);

export const FlagHongkong = () => (
  <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 0H0V9H18V0Z" fill="#E02600" />
    <mask
      id="mask0_907_50471"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="9"
    >
      <path d="M18 0H0V9H18V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_907_50471)">
      <path d="M17.005 -1H1V9.67H17.005V-1Z" fill="#DE2910" />
      <path
        d="M9.00186 4.33345C7.12993 3.54234 7.95973 1.10432 9.75 1.22687C9.38782 1.41301 9.33533 1.76033 9.54216 2.08207C9.77378 2.44229 9.52084 3.0219 9.20774 3.16272C8.69318 3.39434 8.59016 4.01188 9.00186 4.33345Z"
        fill="white"
      />
      <path
        d="M8.90064 2.57302L8.79536 2.73829L8.75723 2.54598L8.56733 2.49691L8.73849 2.40107L8.72656 2.20546L8.87034 2.33865L9.05273 2.26675L8.97073 2.44475L9.09524 2.59601L8.90064 2.57302Z"
        fill="#DE2910"
      />
      <path
        d="M9.01246 4.31292C8.78606 4.19672 8.60351 3.9545 8.52398 3.66505C8.43302 3.33304 8.48534 2.97608 8.66772 2.6863L8.62846 2.66151C8.43924 2.96256 8.38495 3.33271 8.47933 3.67728C8.56341 3.98354 8.74988 4.23031 8.99126 4.35419L9.01246 4.31292Z"
        fill="#DE2910"
      />
      <path
        d="M9.00377 4.33392C9.1777 2.30914 11.7528 2.34493 12.1895 4.08545C11.9005 3.79852 11.554 3.85593 11.3119 4.15206C11.0409 4.48366 10.4115 4.42221 10.1808 4.16794C9.80153 3.75014 9.18238 3.84299 9.00377 4.33392Z"
        fill="white"
      />
      <path
        d="M10.6468 3.69365L10.457 3.6446L10.6282 3.5489L10.6161 3.35314L10.7602 3.4863L10.9425 3.41451L10.8603 3.59241L10.985 3.74365L10.7904 3.72068L10.685 3.88584L10.6468 3.69365Z"
        fill="#DE2910"
      />
      <path
        d="M9.02656 4.33766C9.06712 4.08643 9.24107 3.83797 9.49178 3.67288C9.77943 3.48378 10.1351 3.42323 10.467 3.50714L10.4785 3.46214C10.1337 3.37521 9.76489 3.43796 9.46635 3.6342C9.20106 3.8088 9.024 4.0624 8.98077 4.33025L9.02656 4.33766Z"
        fill="#DE2910"
      />
      <path
        d="M9.00392 4.33586C10.9834 3.8756 11.7451 6.33573 10.2247 7.28889C10.4083 6.92542 10.2466 6.61357 9.89014 6.47485C9.49102 6.31957 9.35497 5.70198 9.52551 5.40401C9.80565 4.91418 9.52602 4.35403 9.00392 4.33586Z"
        fill="white"
      />
      <path
        d="M10.1206 5.70058L10.1086 5.50499L10.2525 5.63816L10.435 5.56624L10.3528 5.74439L10.4775 5.89562L10.2828 5.87239L10.1776 6.03775L10.1393 5.84555L9.94962 5.79637L10.1206 5.70058Z"
        fill="#DE2910"
      />
      <path
        d="M9.00742 4.3587C9.25888 4.31964 9.54894 4.40829 9.78341 4.59572C10.0522 4.81085 10.2196 5.1304 10.2424 5.47204L10.2888 5.46901C10.2649 5.11423 10.0912 4.78287 9.81235 4.55958C9.56431 4.36123 9.26841 4.2712 9.00031 4.31286L9.00742 4.3587Z"
        fill="#DE2910"
      />
      <path
        d="M9.00211 4.33662C10.0515 6.07694 7.94718 7.56159 6.57085 6.41015C6.97327 6.47245 7.21988 6.22231 7.24167 5.84044C7.26602 5.41288 7.81133 5.09264 8.14742 5.16275C8.69984 5.27782 9.14617 4.83877 9.00211 4.33662Z"
        fill="white"
      />
      <path
        d="M8.04925 5.82034L8.23156 5.74851L8.14938 5.92651L8.27416 6.07782L8.07935 6.05476L7.97403 6.22002L7.93599 6.02776L7.74619 5.97872L7.91714 5.88291L7.90532 5.68735L8.04925 5.82034Z"
        fill="#DE2910"
      />
      <path
        d="M8.98147 4.347C9.09633 4.57408 9.10164 4.87734 8.99585 5.15826C8.87429 5.48032 8.62214 5.73835 8.30426 5.86559L8.32146 5.90872C8.6515 5.77639 8.91298 5.50885 9.03916 5.17461C9.15115 4.87741 9.14534 4.56817 9.02287 4.32607L8.98147 4.347Z"
        fill="#DE2910"
      />
      <path
        d="M9.00084 4.33513C7.66999 5.87098 5.60771 4.32841 6.27749 2.66363C6.3426 3.0656 6.6567 3.22285 7.02661 3.12556C7.44077 3.01659 7.91385 3.43626 7.95102 3.77756C8.0123 4.33851 8.56777 4.62732 9.00084 4.33513Z"
        fill="white"
      />
      <path
        d="M7.29528 3.8874L7.41993 4.03859L7.22525 4.01543L7.1199 4.18087L7.08164 3.98847L6.89192 3.93937L7.06302 3.84378L7.051 3.64811L7.19496 3.78109L7.37728 3.70942L7.29528 3.8874Z"
        fill="#DE2910"
      />
      <path
        d="M8.98459 4.31871C8.80411 4.49812 8.51734 4.59689 8.21748 4.58308C7.87361 4.56699 7.55029 4.40692 7.33105 4.14392L7.29534 4.1736C7.52318 4.44659 7.85844 4.6126 8.21531 4.62932C8.53256 4.644 8.82487 4.54291 9.01728 4.35162L8.98459 4.31871Z"
        fill="#DE2910"
      />
    </g>
  </svg>
);

export const FlagBelgium = () => (
  <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 0H0V9H6V0Z" fill="black" />
    <path d="M12 0H6V9H12V0Z" fill="#FBE22A" />
    <path d="M18 0H12V9H18V0Z" fill="#F02532" />
  </svg>
);

export const flagByCountry = {
  usa: <FlagUSA />,
  singapore: <FlagSingapore />,
  india: <FlagIndia />,
  ireland: <FlagIreland />,
  australia: <FlagAustralia />,
  germany: <FlagGermany />,
  britain: <FlagBritain />,
  brazil: <FlagBrazil />,
  netherlands: <FlagNetherlands />,
  hongkong: <FlagHongkong />,
  belgium: <FlagBelgium />,
};
