import React from 'react';

import { IconProps } from './types';

export enum TestSelector {
  Icon = 'icon',
}

const KotlinIcon = ({ className, height = 25, width = 25 }: IconProps) => {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      viewBox="0 0 20.554 20.543"
      xmlns="http://www.w3.org/2000/svg"
      data-test-selector={TestSelector.Icon}
      data-testid={TestSelector.Icon}
    >
      <radialGradient
        id="a"
        cx="22.432"
        cy="3.493"
        r="21.679"
        gradientTransform="matrix(1.0856 0 0 1.0856 -4.4842 -2.9511)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#e44857" offset=".003" />
        <stop stopColor="#c711e1" offset=".469" />
        <stop stopColor="#7f52ff" offset="1" />
      </radialGradient>
      <path d="m20.554 20.543h-20.554v-20.543h20.554l-10.489 10.119z" fill="url(#a)" strokeWidth="1.0858" />
    </svg>
  );
};

export default KotlinIcon;
