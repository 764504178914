import { buildClientSchema, getIntrospectionQuery } from 'graphql';
import { createAction } from 'redux-act';

import { handleResourceNotFound } from 'baas-ui/action_wrappers';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { track } from 'baas-ui/tracking';
import { BaseRequestPayload } from 'baas-ui/types';
import { CustomResolver, GraphQLAlerts, GraphQLConfig, ValidationOptions } from 'admin-sdk';

const NAME = 'graphql/';

interface DoGraphQLRequestPayload extends BaseRequestPayload {
  params: any;
}

interface UpdateValidationSettingsPayload extends BaseRequestPayload {
  data: ValidationOptions;
}

interface CustomResolverByIdPayload extends BaseRequestPayload {
  id: string;
}

interface PersistCustomResolverPayload extends BaseRequestPayload {
  customResolver: CustomResolver;
}

interface UpdateGraphQLConfigPayload extends BaseRequestPayload {
  config: GraphQLConfig;
}

export type GraphQLResult = { data: any };

export const updateLocalGraphQLConfig = createAction<GraphQLConfig>(`${NAME}update local graphql config`);
export const updateLocalValidationSettings = createAction<ValidationOptions>(`${NAME}update local validation settings`);

export const [loadGraphQLSchemaActions, loadGraphQLSchema] = createActionsAndExecutor(
  `${NAME}load graphql schema`,
  (client, { groupId, appId }: BaseRequestPayload) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .graphql()
        .post({
          query: getIntrospectionQuery({
            descriptions: false,
          }),
        })
        .then((response) => buildClientSchema(response.data))
);

export const [doGraphQLRequestActions, doGraphQLRequest] = createActionsAndExecutor<
  DoGraphQLRequestPayload,
  GraphQLResult
>(
  `${NAME}do graphql request`,
  (client, { groupId, appId, params }) =>
    () =>
      client.apps(groupId).app(appId).graphql().post(params)
);

export const [loadValidationSettingsActions, loadValidationSettings] = createActionsAndExecutor(
  `${NAME}load validation settings data`,
  (client, { groupId, appId }: BaseRequestPayload) =>
    () =>
      client.apps(groupId).app(appId).validationSettings().graphql().get()
);

export const [loadGraphQLConfigActions, loadGraphQLConfig] = createActionsAndExecutor(
  `${NAME}load graphql config`,
  (client, { groupId, appId }: BaseRequestPayload) =>
    () =>
      client.apps(groupId).app(appId).graphql().config().get()
);

export const [updateGraphQLConfigActions, updateGraphQLConfig] = createActionsAndExecutor(
  `${NAME}update graphql config`,
  (client, { groupId, appId, config }: UpdateGraphQLConfigPayload) =>
    () =>
      client.apps(groupId).app(appId).graphql().config().update(config)
);

export const [updateValidationSettingsActions, updateValidationSettings] = createActionsAndExecutor(
  `${NAME}update validation settings data`,
  (client, { groupId, appId, data }: UpdateValidationSettingsPayload) =>
    () => {
      track('GRAPHQL.SETTINGS_SAVED', data);
      return client.apps(groupId).app(appId).validationSettings().graphql().update(data);
    }
);

export const [listCustomResolversActions, listCustomResolvers] = createActionsAndExecutor(
  `${NAME}list custom resolvers`,
  (client, { groupId, appId }: BaseRequestPayload) => {
    return () => client.apps(groupId).app(appId).graphql().customResolvers().list();
  }
);

export const [createCustomResolverActions, createCustomResolver] = createActionsAndExecutor(
  `${NAME}create custom resolver`,
  (client, { groupId, appId, customResolver }: PersistCustomResolverPayload) =>
    () =>
      client.apps(groupId).app(appId).graphql().customResolvers().create(customResolver)
);

export const [saveCustomResolverActions, saveCustomResolver] = createActionsAndExecutor(
  `${NAME}save custom resolver`,
  (client, { groupId, appId, customResolver }: PersistCustomResolverPayload) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .graphql()
        .customResolvers()
        .customResolver(customResolver.id!)
        .update(customResolver)
);

export const [loadCustomResolverActions, loadCustomResolvers] = createActionsAndExecutor(
  `${NAME}load custom resolvers`,
  (client, { groupId, appId }: BaseRequestPayload) =>
    () =>
      client.apps(groupId).app(appId).graphql().customResolvers().list()
);

export const [deleteCustomResolverActions, deleteCustomResolver] = createActionsAndExecutor(
  `${NAME}delete custom resolver`,
  (client, { groupId, appId, id }: CustomResolverByIdPayload) => {
    track('GRAPHQL.CUSTOM_RESOLVER_DELETE');
    return client.apps(groupId).app(appId).graphql().customResolvers().customResolver(id).remove;
  }
);

export const [loadExtendableTypesActions, loadExtendableTypes] = createActionsAndExecutor(
  `${NAME}get extendable types`,
  (client, { groupId, appId }: BaseRequestPayload) =>
    () => {
      return client.apps(groupId).app(appId).graphql().extendableTypes();
    }
);

export const [getCustomResolverActions, getCustomResolverInner] = createActionsAndExecutor(
  `${NAME}get custom resolver`,
  (client, { groupId, appId, id }: CustomResolverByIdPayload) =>
    () =>
      client.apps(groupId).app(appId).graphql().customResolvers().customResolver(id).get()
);

export const getCustomResolver = handleResourceNotFound(getCustomResolverInner);

export const [validateGraphQLActions, validateGraphQL] = createActionsAndExecutor<BaseRequestPayload, GraphQLAlerts[]>(
  `${NAME}validate graphql`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).graphql().validate()
);

export const asyncEditRcvActions = [
  createCustomResolverActions.rcv,
  saveCustomResolverActions.rcv,
  updateGraphQLConfigActions.rcv,
  updateValidationSettingsActions.rcv,
];
