import React from 'react';
import Banner from '@leafygreen-ui/banner';

import { passThroughProps } from 'baas-ui/common/utils/util';

interface Props {
  className?: string;
}

const ExportDownloadBanner = ({ className, ...rest }: Props) => (
  <Banner className={className} data-cy="export-download-banner" {...passThroughProps(rest)}>
    The download should begin shortly...
  </Banner>
);

export default ExportDownloadBanner;
