import React, { lazy, Suspense, useEffect, useState } from 'react';

import { LoadingWrapper } from 'baas-ui/common/components/loading-wrapper';

const Loader = ({ delay = 200 }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  if (!show) {
    return null;
  }

  return (
    <LoadingWrapper isLoading>
      <div style={{ width: '100%' }} />
    </LoadingWrapper>
  );
};

const loadable = <C extends React.ComponentType<JSX.LibraryManagedAttributes<C, React.ComponentProps<C>>>>(
  loadableComponent: () => Promise<{ default: C }>,
  options: {
    name?: string;
    delay?: number;
  } = {}
): React.ComponentType<JSX.LibraryManagedAttributes<C, React.ComponentProps<C>>> => {
  const LoadableComponent = lazy(() => loadableComponent());

  const Loadable = (props) => (
    <Suspense fallback={<Loader delay={options.delay} />}>
      <LoadableComponent {...props} />
    </Suspense>
  );

  Loadable.displayName = options.name;
  return Loadable;
};

export default loadable;
