import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import ConfirmationModal, { Variant } from '@leafygreen-ui/confirmation-modal';
import Icon from '@leafygreen-ui/icon';
import { SegmentedControl, SegmentedControlOption } from '@leafygreen-ui/segmented-control';
import Tooltip from '@leafygreen-ui/tooltip';
import { useThemeValue } from '@mongodb-js/darkreader';

import { DataSourceData } from 'baas-ui/common/components/collection-explorer/collection-explorer/CollectionExplorer';
import { LoadingWrapper } from 'baas-ui/common/components/loading-wrapper';
import { useLocalStorage } from 'baas-ui/common/hooks/use-local-storage';
import { showRolesAndFiltersOnStartKey } from 'baas-ui/common/local-storage-keys';
import { ZIndex } from 'baas-ui/common/styles/zIndex';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { loadDefaultRule, loadNamespaceRule } from 'baas-ui/rules/actions';
import AdvancedViewComponent from 'baas-ui/rules/advanced-view/AdvancedView';
import { RulesPageCollectionData } from 'baas-ui/rules/dataSourceData';
import { RulesPageCollectionExplorerContext } from 'baas-ui/rules/RulesPage';
import { SyncIncompatibleRolesByDataSourceId } from 'baas-ui/rules/types';
import * as RulesPageCtx from 'baas-ui/rules/useRulesPageContext';
import { deepCopyRule, isNamespaceRule, isRuleAdvanced } from 'baas-ui/rules/utils';
import { useDarkMode } from 'baas-ui/theme';
import { track } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import {
  MongoDBBaseRule,
  MongoDBNamespaceRule,
  MongoDBRuleFilter,
  MongoDBRuleRole,
  MongoDBSyncIncompatibilityError,
  PresetRole,
} from 'admin-sdk';

import RolesAndFilters, { RolesAndFiltersTab } from './roles-and-filters/RolesAndFilters';
import SelectCollectionEmptyState from './select-collection-empty-state/SelectCollectionEmptyState';
import { ModalStep } from './understanding-roles-and-filters-modal/UnderstandingRolesAndFiltersModal';
import EditFilterForm from './edit-filter-form';
import EditRoleForm from './edit-role-form';
import UnderstandingRolesAndFiltersModal from './understanding-roles-and-filters-modal';

export enum TestSelector {
  AdvancedOption = 'advanced-option',
  AdvancedTooltip = 'advanced-tooltip',
  AdvancedView = 'advanced-view',
  ApplyWhenModal = 'apply-when-modal',
  ApplyWhenModalCode = 'apply-when-modal-code',
  CancelChangesModal = 'cancel-changes-modal',
  CardOption = 'card-option',
  CardTooltip = 'card-tooltip',
  EditFilter = 'edit-filter',
  EditRole = 'edit-role',
  ErrorBanner = 'error-banner',
  RolesAndFiltersC = 'roles-and-filters',
  ViewerLoadingWrapper = 'rule-viewer-loading-wrapper',
}

export enum ViewerState {
  Loading,
  RolesAndFiltersS,
  SelectCollection,
  AdvancedView,
  AddFilter,
  EditFilter,
  AddRole,
  EditRole,
}

export enum RuleView {
  Card = 'card',
  Advanced = 'advanced',
}

export enum ActiveIndex {
  None = -1,
}

const StyledRuleViewer = styled.div`
  width: 100%;
  min-width: 550px;
  height: 100%;
  background: ${({ color }) => color};
`;

const StyledLoadingWrapper = styled(LoadingWrapper)`
  height: calc(100% - 45px);
`;

export const getActiveRoleIncompatibilities = (
  activeRule: MongoDBNamespaceRule | MongoDBBaseRule | undefined,
  activeRoleIdx: number,
  selectedDataSourceData: DataSourceData<RulesPageCollectionData> | null,
  selectedNamespace: string | null,
  syncIncompatibleRolesByDataSourceId: SyncIncompatibleRolesByDataSourceId
): MongoDBSyncIncompatibilityError[] => {
  const activeRuleHasRoles = activeRule && activeRule.roles && activeRule.roles.length > 0;
  if (!activeRuleHasRoles || activeRoleIdx === ActiveIndex.None || !selectedDataSourceData || !selectedNamespace) {
    return [];
  }

  const activeRole = activeRule!.roles![activeRoleIdx];
  const syncIncompatibleRolesForActiveDS = syncIncompatibleRolesByDataSourceId[selectedDataSourceData.dataSourceId];
  if (!syncIncompatibleRolesForActiveDS) {
    return [];
  }

  // NB: selectedNamespace is either formatted as 'dataSourceName:databaseName:collectionName'
  // or as 'dataSourceName'
  const selectedNsArr = selectedNamespace.split(':', 3);
  const isDefaultRule = selectedNsArr.length < 3;
  if (isDefaultRule) {
    const incompatibleDefaultRoles = syncIncompatibleRolesForActiveDS.defaultRoles;
    if (!incompatibleDefaultRoles) {
      return [];
    }

    const matchingIncompatibleRole = incompatibleDefaultRoles.find((role) => role.name === activeRole.name);
    if (!matchingIncompatibleRole) {
      return [];
    }

    return matchingIncompatibleRole.errors;
  }

  const selectedDatabase = selectedNsArr[1];
  const selectedCollection = selectedNsArr[2];

  const incompatibleNamespaceRoles = syncIncompatibleRolesForActiveDS.namespaceRoles;
  if (!incompatibleNamespaceRoles) {
    return [];
  }

  const matchingIncompatibleRole = incompatibleNamespaceRoles.find(
    (role) =>
      role.name === activeRole.name && role.database === selectedDatabase && role.collection === selectedCollection
  );
  if (!matchingIncompatibleRole) {
    return [];
  }

  return matchingIncompatibleRole.errors;
};

export interface Props {
  setRulesPageError: (errMsg: string) => void;
}

export const RuleViewerComponent = ({ setRulesPageError }: Props) => {
  const dispatch = useDispatch<AsyncDispatch>();
  const { id: appId, groupId } = useSelector((state: RootState) => state.app.app);

  const [isLoadingRule, setIsLoadingRule] = useState<boolean>(false);
  const [viewerState, setViewerState] = useState<ViewerState>(ViewerState.Loading);
  const [rolesAndFiltersTab, setRolesAndFiltersTab] = useState<RolesAndFiltersTab>(RolesAndFiltersTab.Roles);
  const [activeFilterIdx, setActiveFilterIdx] = useState<number>(ActiveIndex.None);
  const [activeRoleIdx, setActiveRoleIdx] = useState<number>(ActiveIndex.None);
  const [waitingToSavePresetRoles, setWaitingToSavePresetRoles] = useState<boolean>(false);
  const [isCancelChangesModalOpen, setIsCancelChangesModalOpen] = useState<boolean>(false);
  const [showRolesAndFiltersModalOnStart, setShowRolesAndFiltersModalOnStart] = useLocalStorage(
    showRolesAndFiltersOnStartKey(appId),
    true
  );
  const [openRolesAndFiltersModal, setOpenRolesAndFiltersModal] = useState(showRolesAndFiltersModalOnStart);

  const {
    isLoading: isContextLoading,
    hasCollections,
    selectedCollectionData,
    selectedDataSourceData,
    selectedNamespace,
  } = useContext(RulesPageCollectionExplorerContext);

  const {
    activeRule,
    pristineRule,
    deletingCollections,
    deletingDatabases,
    deletingDatasources,
    selectedDataSource,
    rulesDispatchActions: {
      deleteFilterOnActiveRuleByIdx,
      deleteRoleOnActiveRuleByIdx,
      pushFilterOnActiveRule,
      pushRoleOnActiveRule,
      replaceFilterOnActiveRuleByIdx,
      replaceRoleOnActiveRuleByIdx,
      setActiveRule,
      swapRolesOnActiveRule,
      pushRolesOnActiveRule,
    },
    setPristineRule,
    setSelectedDataSource,
    saveRule,
    setHasClientValidationError,
    setOnDiscardChanges,
    syncIncompatibleRolesByDataSourceId,
    partialDataSources,
    partialRulesByDataSourceId,
  } = RulesPageCtx.useRulesPageContext();

  const darkMode = useDarkMode();

  useEffect(() => {
    setOnDiscardChanges(() => setIsCancelChangesModalOpen(true));

    let hasRulesConfiguration = false;
    Object.keys(partialRulesByDataSourceId).forEach((dataSourceId) => {
      if (partialRulesByDataSourceId[dataSourceId].length) hasRulesConfiguration = true;
    });
    setOpenRolesAndFiltersModal(!hasRulesConfiguration && showRolesAndFiltersModalOnStart);
  }, []);

  useEffect(() => {
    if (waitingToSavePresetRoles) {
      saveRule();
      setWaitingToSavePresetRoles(false);
    }
  }, [activeRule?.roles]);

  useEffect(() => {
    if (selectedNamespace) {
      setRolesAndFiltersTab(RolesAndFiltersTab.Roles);
      setViewerState(ViewerState.RolesAndFiltersS);
    } else if (!selectedNamespace && hasCollections) {
      setViewerState(ViewerState.SelectCollection);
    }
  }, [hasCollections, selectedNamespace]);

  useEffect(() => {
    if (!selectedNamespace) {
      return;
    }

    const [dsName, dbName, collName] = selectedNamespace.split(':', 3);

    const fetchAndSetDefaultRule = () => {
      const dataSource = partialDataSources.find((ds) => ds.name === dsName);
      const dataSourceId = dataSource?.id;
      if (dataSourceId) {
        setIsLoadingRule(true);
        dispatch(loadDefaultRule({ groupId, appId, dataSourceId }))
          .then((defaultRule) => {
            if (defaultRule) {
              setPristineRule(deepCopyRule(defaultRule));
              setActiveRule(defaultRule);
            }
          })

          .catch((error) => {
            // Suppress 404s for the default rule
            if (error?.response?.status !== 404) {
              setRulesPageError(error.message);
            }
            setPristineRule(undefined);
            setActiveRule(new MongoDBBaseRule());
          })
          .finally(() => {
            setIsLoadingRule(false);
          });
      }
    };

    const fetchAndSetNamespaceRule = () => {
      const dataSourceId = selectedDataSourceData?.dataSourceId;
      const partialRule = selectedCollectionData?.data?.partialRule;
      const partialRuleId = partialRule?.id;

      // Fetch namespace rule if there's a partial rule match, else construct a new namespace rule
      if (partialRuleId && dataSourceId) {
        setIsLoadingRule(true);
        dispatch(loadNamespaceRule({ groupId, appId, dataSourceId, ruleId: partialRuleId }))
          .then((rule) => {
            if (rule && isNamespaceRule(rule)) {
              setPristineRule(deepCopyRule(rule));
              setActiveRule(rule);
            }
          })
          .catch((error) => {
            // Suppress 404s for the namespace rule
            if (error?.response?.status !== 404) {
              setRulesPageError(error.message);
            }
            setPristineRule(undefined);
            setActiveRule(undefined);
          })
          .finally(() => {
            setIsLoadingRule(false);
          });
      } else if (selectedCollectionData?.namespace) {
        const [, databaseName, collectionName] = selectedCollectionData.namespace.split(':', 3);
        setPristineRule(undefined);
        setActiveRule(new MongoDBNamespaceRule({ database: databaseName, collection: collectionName }));
      }
    };

    // Detects a default/namespace rule change between data sources
    const selectedDataSourceHasChanged = selectedDataSource && dsName !== selectedDataSource;
    // Detects a namespace rule change within the same data source
    const namespaceRuleHasChanged =
      activeRule &&
      isNamespaceRule(activeRule) &&
      (activeRule.database !== dbName || activeRule.collection !== collName);
    // Detects when a namespace rule is selected after a default rule on the same data sources
    const nsRuleSelectedAfterDefault = activeRule && !isNamespaceRule(activeRule) && dbName;

    if (!activeRule || namespaceRuleHasChanged || selectedDataSourceHasChanged || nsRuleSelectedAfterDefault) {
      setSelectedDataSource(dsName);
      if (!isLoadingRule) {
        if (selectedDataSourceData && selectedNamespace === selectedCollectionData?.namespace) {
          fetchAndSetNamespaceRule();
        } else {
          fetchAndSetDefaultRule();
        }
      }
    }
  }, [
    selectedNamespace,
    selectedCollectionData,
    selectedDataSourceData,
    activeRule,
    selectedDataSource,
    setActiveRule,
    setPristineRule,
    setSelectedDataSource,
  ]);

  const baseTrackingFields = {
    ruleId: activeRule?.id,
    dataSourceName: selectedDataSource,
    ...(activeRule && isNamespaceRule(activeRule)
      ? {
          databaseName: activeRule.database,
          collectionName: activeRule.collection,
        }
      : undefined),
  };

  useEffect(() => {
    if (activeRule && viewerState !== ViewerState.AdvancedView && isRuleAdvanced(activeRule)) {
      setViewerState(ViewerState.AdvancedView);
    }
  }, [activeRule]);

  const handleAddFilterClick = () => {
    track('RULES_CONFIGURATION.ADD_FILTER_CLICKED', {
      ...baseTrackingFields,
    });
    setActiveFilterIdx(ActiveIndex.None);
    setViewerState(ViewerState.AddFilter);
    pushFilterOnActiveRule(new MongoDBRuleFilter());
    setActiveFilterIdx(activeRule?.filters ? activeRule.filters.length : 0);
  };

  const handleEditFilterClick = (_: string, idx: number) => {
    track('RULES_CONFIGURATION.EDIT_FILTER_CLICKED', {
      ...baseTrackingFields,
      idx,
      name: activeRule?.filters && activeRule.filters[idx].name,
    });
    setActiveFilterIdx(idx);
    setViewerState(ViewerState.EditFilter);
  };

  const handleFilterOnClickBackOrCancel = () => {
    const ruleIsNamespaceRule = activeRule && isNamespaceRule(activeRule);
    track(
      ruleIsNamespaceRule
        ? 'RULES_CONFIGURATION.COLLECTION_CHANGES_DISCARDED'
        : 'RULES_CONFIGURATION.DEFAULT_RULE_CHANGES_DISCARDED',
      { ...baseTrackingFields, formType: 'Filter' }
    );
    setActiveRule(pristineRule);
    setHasClientValidationError(false);
    setRolesAndFiltersTab(RolesAndFiltersTab.Filters);
    setActiveFilterIdx(ActiveIndex.None);
    setViewerState(ViewerState.RolesAndFiltersS);
    setOnDiscardChanges(() => setIsCancelChangesModalOpen(true));
  };

  const handleDeleteFilter = (_: string, idx: number) => {
    track('RULES_CONFIGURATION.FILTER_REMOVED', {
      ...baseTrackingFields,
      idx,
      name: activeRule?.filters && activeRule.filters[idx].name,
    });
    deleteFilterOnActiveRuleByIdx({ idx });
  };

  const handleAddPresetRoleClick = (presetRole: PresetRole) => {
    pushRolesOnActiveRule(presetRole.roles);
    setViewerState(ViewerState.RolesAndFiltersS);
    setWaitingToSavePresetRoles(true);
  };

  const handleAddRoleFromScratchClick = () => {
    track('RULES_CONFIGURATION.CREATE_ROLE_FROM_SCRATCH_CLICKED', {
      ...baseTrackingFields,
    });
    setActiveRoleIdx(ActiveIndex.None);
    setViewerState(ViewerState.AddRole);
    pushRoleOnActiveRule(new MongoDBRuleRole());
    setActiveRoleIdx(activeRule?.roles ? activeRule.roles.length : 0);
  };

  const handleEditRoleClick = (_: string, idx: number) => {
    track('RULES_CONFIGURATION.EDIT_ROLE_CLICKED', {
      ...baseTrackingFields,
      idx,
      name: activeRule?.roles && activeRule.roles[idx].name,
    });
    setActiveRoleIdx(idx);
    setViewerState(ViewerState.EditRole);
  };

  const handleRoleOnClickBackOrCancel = () => {
    const ruleIsNamespaceRule = activeRule && isNamespaceRule(activeRule);
    track(
      ruleIsNamespaceRule
        ? 'RULES_CONFIGURATION.COLLECTION_CHANGES_DISCARDED'
        : 'RULES_CONFIGURATION.DEFAULT_RULE_CHANGES_DISCARDED',
      { ...baseTrackingFields, formType: 'Role' }
    );
    setActiveRule(pristineRule);
    setHasClientValidationError(false);
    setRolesAndFiltersTab(RolesAndFiltersTab.Roles);
    setActiveRoleIdx(ActiveIndex.None);
    setViewerState(ViewerState.RolesAndFiltersS);
    setOnDiscardChanges(() => setIsCancelChangesModalOpen(true));
  };

  const handleDeleteRole = (_: string, idx: number) => {
    track('RULES_CONFIGURATION.ROLE_REMOVED', {
      ...baseTrackingFields,
      idx,
      name: activeRule?.roles && activeRule.roles[idx].name,
    });
    deleteRoleOnActiveRuleByIdx({ idx });
  };

  const decrementRole = (_: string, idx: number) => {
    track('RULES_CONFIGURATION.ROLE_REORDERED', {
      ...baseTrackingFields,
      oldIdx: idx,
      newIdx: idx - 1,
      name: activeRule?.roles && activeRule.roles[idx].name,
    });
    swapRolesOnActiveRule({ idx, swapIdx: idx - 1 });
  };

  const incrementRole = (_: string, idx: number) => {
    track('RULES_CONFIGURATION.ROLE_REORDERED', {
      ...baseTrackingFields,
      oldIdx: idx,
      newIdx: idx + 1,
      name: activeRule?.roles && activeRule.roles[idx].name,
    });
    swapRolesOnActiveRule({ idx, swapIdx: idx + 1 });
  };

  let isDeletingOwnRule = false;
  if (selectedNamespace) {
    const [datasourceName, databaseName, collectionName] = selectedNamespace.split(':');
    const isDeletingFromCurrentCollection = deletingCollections?.has(collectionName);
    const isDeletingFromCurrentDatabase = deletingDatabases?.has(databaseName);
    const isDeletingFromCurrentDatasource = deletingDatasources?.has(datasourceName);

    isDeletingOwnRule =
      isDeletingFromCurrentCollection || isDeletingFromCurrentDatabase || isDeletingFromCurrentDatasource;
  }

  const isLoading = isContextLoading || isLoadingRule;

  const onChangeRole = (role: MongoDBRuleRole) => {
    replaceRoleOnActiveRuleByIdx({ role, idx: activeRoleIdx });
  };

  const onChangeFilter = (filter: MongoDBRuleFilter) => {
    replaceFilterOnActiveRuleByIdx({ filter, idx: activeFilterIdx });
  };

  const advancedModeToggle = (
    <SegmentedControl
      name="view"
      label="view"
      size="xsmall"
      darkMode={darkMode}
      followFocus
      value={viewerState === ViewerState.AdvancedView ? RuleView.Advanced : RuleView.Card}
      onChange={(value: string) => {
        const advancedModeToggled = value === RuleView.Advanced;
        setViewerState(advancedModeToggled ? ViewerState.AdvancedView : ViewerState.RolesAndFiltersS);

        track(
          advancedModeToggled
            ? 'RULES_CONFIGURATION.ROLES_ADVANCED_MODE_VIEW_TOGGLED'
            : 'RULES_CONFIGURATION.ROLES_CARD_VIEW_TOGGLED',
          baseTrackingFields
        );
      }}
    >
      <SegmentedControlOption
        value={RuleView.Card}
        data-test-selector={TestSelector.CardOption}
        data-cy={TestSelector.CardOption}
      >
        <Tooltip
          data-test-selector={TestSelector.CardTooltip}
          align="top"
          justify="middle"
          trigger={
            <div style={{ marginBottom: '-0.2em' }}>
              <Icon glyph="Table" />
            </div>
          }
        >
          Card View
        </Tooltip>
      </SegmentedControlOption>
      <SegmentedControlOption
        value={RuleView.Advanced}
        data-test-selector={TestSelector.AdvancedOption}
        data-cy={TestSelector.AdvancedOption}
      >
        <Tooltip
          data-test-selector={TestSelector.AdvancedTooltip}
          align="top"
          justify="middle"
          trigger={
            <div style={{ marginBottom: '-0.2em' }}>
              <Icon glyph="Code" />
            </div>
          }
        >
          Advanced View
        </Tooltip>
      </SegmentedControlOption>
    </SegmentedControl>
  );

  let ruleView = <SelectCollectionEmptyState />;
  switch (viewerState) {
    case ViewerState.AddFilter:
      ruleView = (
        <EditFilterForm
          data-test-selector={TestSelector.EditFilter}
          onChange={onChangeFilter}
          onClickBackOrCancel={handleFilterOnClickBackOrCancel}
        />
      );
      break;
    case ViewerState.EditFilter:
      if (activeRule?.filters?.length) {
        ruleView = (
          <EditFilterForm
            data-test-selector={TestSelector.EditFilter}
            filter={activeRule.filters[activeFilterIdx]}
            onChange={onChangeFilter}
            onClickBackOrCancel={handleFilterOnClickBackOrCancel}
          />
        );
      }
      break;
    case ViewerState.AddRole:
      ruleView = (
        <EditRoleForm
          data-test-selector={TestSelector.EditRole}
          onChange={onChangeRole}
          onClickBackOrCancel={handleRoleOnClickBackOrCancel}
        />
      );
      break;
    case ViewerState.EditRole:
      if (activeRule?.roles?.length) {
        ruleView = (
          <EditRoleForm
            data-test-selector={TestSelector.EditRole}
            onChange={onChangeRole}
            onClickBackOrCancel={handleRoleOnClickBackOrCancel}
            role={activeRule.roles[activeRoleIdx]}
            roleIncompatibilities={getActiveRoleIncompatibilities(
              activeRule,
              activeRoleIdx,
              selectedDataSourceData,
              selectedNamespace,
              syncIncompatibleRolesByDataSourceId
            )}
          />
        );
      }
      break;
    case ViewerState.RolesAndFiltersS:
      if (activeRule) {
        ruleView = (
          <RolesAndFilters
            data-cy="roles-and-filters"
            data-test-selector={TestSelector.RolesAndFiltersC}
            filters={activeRule.filters || []}
            headerLeftComponent={advancedModeToggle}
            onClickAddFilter={handleAddFilterClick}
            onClickAddPresetRoles={handleAddPresetRoleClick}
            onClickAddRoleFromScratch={handleAddRoleFromScratchClick}
            onClickDeleteFilter={handleDeleteFilter}
            onClickDeleteRole={handleDeleteRole}
            onClickDownRole={incrementRole}
            onClickEditFilter={handleEditFilterClick}
            onClickEditRole={handleEditRoleClick}
            onClickUpRole={decrementRole}
            onClickLearnMoreLink={() => setOpenRolesAndFiltersModal(true)}
            roles={activeRule.roles || []}
            selectedTabValue={rolesAndFiltersTab}
            setSelectedTabValue={setRolesAndFiltersTab}
            setViewerState={setViewerState}
            setRulesPageError={setRulesPageError}
          />
        );
      }
      break;
    case ViewerState.AdvancedView:
      ruleView = (
        <AdvancedViewComponent
          data-test-selector={TestSelector.AdvancedView}
          headerLeftComponent={advancedModeToggle}
          rule={activeRule || {}}
          namespace={selectedNamespace || ''}
          setViewerState={setViewerState}
          onDiscardChanges={() => setIsCancelChangesModalOpen(true)}
        />
      );
      break;
    default:
  }

  const themeValue = useThemeValue(darkMode);
  const editorColor = themeValue({ dark: 'grayDark4', light: 'grayLight3' });
  return (
    <StyledRuleViewer
      color={
        viewerState === ViewerState.RolesAndFiltersS || viewerState === ViewerState.AdvancedView ? editorColor : ''
      }
    >
      <StyledLoadingWrapper
        data-test-selector={TestSelector.ViewerLoadingWrapper}
        isLoading={isLoading || isDeletingOwnRule}
      >
        {!isLoading && ruleView}
        <ConfirmationModal
          title={'Discard changes to rule'}
          confirmButtonProps={{
            children: 'OK',
            onClick: () => {
              const ruleIsNamespaceRule = activeRule && isNamespaceRule(activeRule);
              track(
                ruleIsNamespaceRule
                  ? 'RULES_CONFIGURATION.COLLECTION_CHANGES_DISCARDED'
                  : 'RULES_CONFIGURATION.DEFAULT_RULE_CHANGES_DISCARDED',
                { ...baseTrackingFields }
              );
              setActiveRule(pristineRule);
              setHasClientValidationError(false);
              setRolesAndFiltersTab(RolesAndFiltersTab.Roles);
              setActiveFilterIdx(ActiveIndex.None);
              setActiveRoleIdx(ActiveIndex.None);
              setViewerState(isRuleAdvanced(pristineRule) ? ViewerState.AdvancedView : ViewerState.RolesAndFiltersS);
              setIsCancelChangesModalOpen(false);
            },
          }}
          cancelButtonProps={{
            onClick: () => setIsCancelChangesModalOpen(false),
          }}
          variant={Variant.Danger}
          open={isCancelChangesModalOpen}
          data-test-selector={TestSelector.CancelChangesModal}
          data-cy={TestSelector.CancelChangesModal}
          style={{ zIndex: ZIndex.Modal }}
        >
          Are you sure you want to discard your changes to the current rule?
        </ConfirmationModal>
        <UnderstandingRolesAndFiltersModal
          open={openRolesAndFiltersModal}
          setOpen={(open) => {
            if (showRolesAndFiltersModalOnStart && !open) {
              setShowRolesAndFiltersModalOnStart(false);
              track('RULES_CONFIGURATION.UNDERSTANDING_ROLES_AND_FILTERS_MODAL_VIEWED');
            }
            setOpenRolesAndFiltersModal(open);
          }}
          activeModalStep={
            rolesAndFiltersTab === RolesAndFiltersTab.Filters ? ModalStep.FilteringQueries : ModalStep.DefiningRoles
          }
        />
      </StyledLoadingWrapper>
    </StyledRuleViewer>
  );
};

export default RuleViewerComponent;
