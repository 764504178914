import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Location } from 'history';

import { redirectTo as redirectToAction } from 'baas-ui/actions';
import {
  buildEndpointsGuide,
  buildGraphQLGuide,
  buildSyncGuide,
  buildTriggersGuide,
  buildWebSDKGuide,
} from 'baas-ui/guides/content';
import GuideContentBody from 'baas-ui/guides/content/body';
import GuidePopover from 'baas-ui/guides/popover';
import GuideStepHeader from 'baas-ui/guides/step-header';
import { GuideContent, GuideLocalStorageItem, GuideName } from 'baas-ui/guides/types';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { getAppState, getServiceState, getSettingsState } from 'baas-ui/selectors';
import { isSelfHostedOrAtlasMongoService } from 'baas-ui/services/registry';
import { track } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import rootUrl, { AppUrlType, withQueryParams } from 'baas-ui/urls';

import './guide-runner.less';

const baseClassName = 'guide-runner';

interface ReduxStateProps {
  appId: string;
  appUrl: AppUrlType;
  cloudUIBaseUrl?: string;
  clusterId?: string;
  groupId: string;
}

interface ReduxDispatchProps {
  redirectTo(url: string): void;
}

interface RouteProps {
  activeGuide: GuideLocalStorageItem;
  location: Location;
  setActiveGuide(activeGuide: GuideLocalStorageItem): void;
  isExpanded: boolean;
  setIsExpanded(boolean): void;
}

export type Props = ReduxStateProps & ReduxDispatchProps & RouteProps;

export const noLinkedClusterError: GuideContent = {
  header: 'No linked data source',
  steps: [
    {
      showProgress: false,
      body: (
        <>
          <GuideStepHeader isTitle text="Link a data source" />
          <GuideContentBody>
            Link a data source in order to use Guides. After a linking a data source, you will need to restart this
            guide.
          </GuideContentBody>
        </>
      ),
      nextStepTitle: 'Next',
      nextIsButton: true,
    },
  ],
};

export const GuideRunner = ({
  activeGuide,
  appId,
  appUrl,
  cloudUIBaseUrl,
  clusterId,
  groupId,
  location,
  isExpanded,
  setIsExpanded,
  redirectTo,
  setActiveGuide,
}: Props) => {
  const noActiveGuide = Object.keys(activeGuide).length === 0;

  if (!clusterId) {
    return (
      <GuidePopover
        className={classNames(baseClassName, {
          [`${baseClassName}-hide`]: noActiveGuide,
        })}
        currentStep={0}
        isHidden={noActiveGuide}
        header={noLinkedClusterError.header}
        nextIsButton
        nextLabel={noLinkedClusterError.steps[0].nextStepTitle}
        totalSteps={0}
        onClose={() => {}}
        onClickAllGuides={() => redirectTo(withQueryParams(appUrl.dashboard(), { guidesModalOpen: true }))}
        onNext={() => {
          setActiveGuide({});
          redirectTo(appUrl.clusters().new());
        }}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      >
        {noLinkedClusterError.steps[0].body}
      </GuidePopover>
    );
  }

  let activeGuideContent: GuideContent;
  switch (activeGuide.name) {
    case GuideName.SyncGuide:
      activeGuideContent = buildSyncGuide({ groupId, appId });
      break;
    case GuideName.GraphQLGuide:
      activeGuideContent = buildGraphQLGuide({ groupId, appId, clusterId });
      break;
    case GuideName.TriggersGuide:
      activeGuideContent = buildTriggersGuide({ groupId, appId, cloudUIBaseUrl, clusterId });
      break;
    case GuideName.EndpointsGuide:
      activeGuideContent = buildEndpointsGuide({ groupId, appId, cloudUIBaseUrl });
      break;
    case GuideName.WebSDKGuide:
      activeGuideContent = buildWebSDKGuide({ groupId, appId, clusterId });
      break;
    default:
      // This is for the case that an active guide isn't set, but the guide popover is still fading out as it's closing.
      activeGuideContent = {
        header: '',
        steps: [{ showProgress: false, body: '', nextStepTitle: '', nextIsButton: false }],
      };
      break;
  }
  const guideStep = parseInt(activeGuide.step!, 10) || 0;

  const nextGuideStep = guideStep + 1;
  const guideHasNextStep = !!activeGuideContent && nextGuideStep < activeGuideContent.steps.length;

  const prevGuideStep = guideStep - 1;
  const guideHasPrevStep = !!activeGuideContent && prevGuideStep >= 0;

  const showGuidePopover =
    !noActiveGuide && !!activeGuideContent && guideStep >= 0 && guideStep < activeGuideContent.steps.length;

  const onCloseGuide = () => {
    track('GUIDES.CLOSED_GUIDE', { guide: activeGuide.name });
    setActiveGuide({});
  };

  const onNextGuideStep = () => {
    if (guideHasNextStep) {
      track('GUIDES.CLICKED_NEXT_STEP', { guide: activeGuide.name });
      const nextUrl = activeGuideContent.steps[nextGuideStep].url;
      // If we're supposed to redirect, and we're redirecting to a page that isn't the one we're currently on
      if (nextUrl && nextUrl !== location.pathname) {
        redirectTo(nextUrl);
      }
      track('GUIDES.VIEWED_STEP', { guide: activeGuide.name, step: nextGuideStep });
      setActiveGuide({ ...activeGuide, step: `${nextGuideStep}` });
    } else if (guideStep === activeGuideContent.steps.length - 1) {
      redirectTo(appUrl.dashboard());
      if (activeGuideContent.nextGuideName) {
        // start next guide
        track('GUIDES.STARTED_GUIDE', { guide: activeGuideContent.nextGuideName, startLocation: activeGuide.name });
        setActiveGuide({ name: activeGuideContent.nextGuideName, step: '0' });
      } else {
        // close the guides modal
        onCloseGuide();
      }
    }
  };

  const onPrevGuideStep = () => {
    if (guideHasPrevStep) {
      track('GUIDES.CLICKED_PREVIOUS_STEP', { guide: activeGuide.name });
      const prevUrl = activeGuideContent.steps[prevGuideStep].url;
      if (prevUrl && prevUrl !== location.pathname) {
        redirectTo(prevUrl);
      }
      track('GUIDES.VIEWED_STEP', { guide: activeGuide.name, step: prevGuideStep });
      setActiveGuide({ ...activeGuide, step: `${prevGuideStep}` });
    }
  };

  return (
    <GuidePopover
      className={classNames(baseClassName, {
        [`${baseClassName}-hide`]: !showGuidePopover,
      })}
      currentStep={guideStep}
      isHidden={!showGuidePopover}
      header={activeGuideContent.header}
      showProgress={activeGuideContent.steps[guideStep].showProgress}
      nextIsButton={activeGuideContent.steps[guideStep].nextIsButton}
      nextLabel={activeGuideContent.steps[guideStep].nextStepTitle}
      totalSteps={activeGuideContent.steps.length - 2}
      onBack={guideHasPrevStep ? onPrevGuideStep : undefined}
      onClose={onCloseGuide}
      onClickAllGuides={() => redirectTo(withQueryParams(appUrl.dashboard(), { guidesModalOpen: true }))}
      onNext={onNextGuideStep}
      docsLink={activeGuideContent.steps[guideStep].docsLink}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      {activeGuideContent.steps[guideStep].body}
    </GuidePopover>
  );
};

const mapStateToProps = (state: RootState): ReduxStateProps => {
  const { cloudUIBaseUrl } = getSettingsState(state);
  const {
    app: { groupId, id: appId },
  } = getAppState(state);
  const { svcsById } = getServiceState(state);

  const services = Object.values(svcsById);
  const mongoServices = services.filter((svc) => isSelfHostedOrAtlasMongoService(svc.type));
  const clusterId = mongoServices[0]?.id;

  const appUrl = rootUrl.groups().group(groupId).apps().app(appId);

  return { appId, appUrl, cloudUIBaseUrl, clusterId, groupId };
};

const mapDispatchToProps = (dispatch: AsyncDispatch): ReduxDispatchProps => ({
  redirectTo: (url: string) => dispatch(redirectToAction(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuideRunner);
