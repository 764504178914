import { externalLinks } from 'baas-ui/common/links';
import { CONSOLE_SOURCE_DEFAULT } from 'baas-ui/functions/constants';

const HTTP_INCOMING_WEBHOOK_DEFAULT_SOURCE = `// This function is the webhook's request handler.
exports = function(payload, response) {
    // Data can be extracted from the request as follows:

    // Query params, e.g. '?arg1=hello&arg2=world' => {arg1: "hello", arg2: "world"}
    const {arg1, arg2} = payload.query;

    // Headers, e.g. {"Content-Type": ["application/json"]}
    const contentTypes = payload.headers["Content-Type"];

    // Raw request body (if the client sent one).
    // This is a binary object that can be accessed as a string using .text()
    const body = payload.body;

    console.log("arg1, arg2: ", arg1, arg2);
    console.log("Content-Type:", JSON.stringify(contentTypes));
    console.log("Request body:", body);

    // You can use 'context' to interact with other App Service features.
    // Accessing a value:
    // var x = context.values.get("value_name");

    // Querying a mongodb service:
    // const doc = context.services.get("mongodb-atlas").db("dbname").collection("coll_name").findOne();

    // Calling a function:
    // const result = context.functions.execute("function_name", arg1, arg2);

    // The return value of the function is sent as the response back to the client
    // when the "Respond with Result" setting is set.
    return  "Hello World!";
};`;

const HTTP_INCOMING_WEBHOOK_DEFAULT_DEBUG_CONSOLE = `${CONSOLE_SOURCE_DEFAULT}\n
exports({query: {arg1: 'hello', arg2: "world!"}, body: BSON.Binary.fromText('{"msg": "world"}')}, new HTTPResponse())
`;

const TWILIO_INCOMING_WEBHOOK_DEFAULT_SOURCE = `/*
  See ${externalLinks.TwilioDocs} for
  an example of a payload that Twilio would send.

  Try running in the console below.
*/

exports = function(payload) {
  return payload.FromCity === 'New York';
};`;

const TWILIO_INCOMING_WEBHOOK_DEFAULT_DEBUG_CONSOLE = `${CONSOLE_SOURCE_DEFAULT}\n
var payload = {
  "AccountSid": "AC1f58150a1c14eb3e22932bf45469619d",
  "ApiVersion": "2010-04-01",
  "Body": "hello",
  "From": "+14155554345",
  "FromCity": "New York",
  "FromCountry": "US",
  "FromState": "NY",
  "FromZip": "10036",
  "MessageSid": "SMadf5a4c07f0057de8a055788fe0310f0",
  "MessagingServiceSid": "MG9ae8897aedef14ab649cddce9dd2d522",
  "NumMedia": "0",
  "NumSegments": "1",
  "SmsMessageSid": "SMadf5a4c07f0057de8a055788fe0310f0",
  "SmsSid": "SMadf5a4c07f0057de8a055788fe0310f0",
  "SmsStatus": "received",
  "To": "+15005550006",
  "ToCity": "EVERETT",
  "ToCountry": "US",
  "ToState": "WA",
  "ToZip": "98205"
}
exports(payload)
`;

const GITHUB_INCOMING_WEBHOOK_DEFAULT_SOURCE = `/*
  See ${externalLinks.GitHubWebhooks} for
  examples of payloads.

  Try running in the console below.
*/

exports = function(payload) {
  return payload.pull_request && payload.action === 'opened';
};`;

const GITHUB_INCOMING_WEBHOOK_DEFAULT_DEBUG_CONSOLE = `${CONSOLE_SOURCE_DEFAULT}\n
exports({pull_request: {}, action: 'opened'})
`;

export {
  HTTP_INCOMING_WEBHOOK_DEFAULT_SOURCE,
  HTTP_INCOMING_WEBHOOK_DEFAULT_DEBUG_CONSOLE,
  TWILIO_INCOMING_WEBHOOK_DEFAULT_SOURCE,
  TWILIO_INCOMING_WEBHOOK_DEFAULT_DEBUG_CONSOLE,
  GITHUB_INCOMING_WEBHOOK_DEFAULT_SOURCE,
  GITHUB_INCOMING_WEBHOOK_DEFAULT_DEBUG_CONSOLE,
};
