import React from 'react';
import Card from '@leafygreen-ui/card';
import Icon from '@leafygreen-ui/icon';
import IconButton from '@leafygreen-ui/icon-button';
import { Menu, MenuItem } from '@leafygreen-ui/menu';
import { palette } from '@leafygreen-ui/palette';
import Tooltip from '@leafygreen-ui/tooltip';
import { Subtitle } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import { PermissionDisplayValue } from 'baas-ui/rules/permission-display';
import { RoleInfoTable } from 'baas-ui/rules/rule-viewer/common/RoleInfoTable';
import {
  permissionValToDisplayValMap,
  PermissionValue,
  resolveFieldPermissionValue,
  resolvePermissionValue,
} from 'baas-ui/rules/utils';
import { MongoDBRuleRole } from 'admin-sdk';

import './role-card.less';

export enum TestSelector {
  ActionsMenu = 'actions-menu',
  CardComponent = 'card',
  DeleteRole = 'delete-role',
  EditRole = 'edit-role',
  Identifier = 'identifier',
  IncompatibleRoleIcon = 'incompatible-role-icon',
  RoleDown = 'role-down',
  RoleDownIcon = 'role-down-icon',
  RoleUp = 'role-up',
  RoleUpIcon = 'role-up-icon',
}

export interface Props {
  className?: string;
  index: number;
  role: MongoDBRuleRole;
  onClickDelete(): void;
  onClickEdit(): void;
  onClickUp(): void;
  upDisabled: boolean;
  onClickDown(): void;
  downDisabled: boolean;
  onClickViewApplyWhenJson(): void;
  hasSyncIncompatibleRoles: boolean;
}

const baseClassName = 'role-card';
const headerClassName = `${baseClassName}-header`;
const menuItemContentClassName = `${headerClassName}-menu-item-content`;
const menuItemContentIconClassName = `${menuItemContentClassName}-icon`;

const RoleCard = ({
  className = '',
  index,
  role,
  onClickEdit,
  onClickDelete,
  onClickUp,
  upDisabled = false,
  onClickDown,
  downDisabled = false,
  onClickViewApplyWhenJson,
  hasSyncIncompatibleRoles,
}: Props) => {
  const { read, write } = resolveFieldPermissionValue(role);

  const readFields: string[] = [];
  const writeFields: string[] = [];

  if (role.fields) {
    Object.entries(role.fields!).forEach(([field, { read: readAccess, write: writeAccess }]) => {
      if (readAccess) {
        readFields.push(field);
      }
      if (writeAccess) {
        writeFields.push(field);
      }
    });
  }

  return (
    <Card
      className={classNames(baseClassName, className)}
      data-testid={TestSelector.CardComponent}
      data-test-selector={TestSelector.CardComponent}
      data-cy={TestSelector.CardComponent}
      onClick={onClickEdit}
    >
      <div className={`${headerClassName}`}>
        <div className={`${headerClassName}-arrows`}>
          <IconButton
            aria-label="role up"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickUp();
            }}
            disabled={upDisabled}
            data-test-selector={TestSelector.RoleUp}
            data-testid={TestSelector.RoleUp}
            data-cy={TestSelector.RoleUp}
          >
            <Icon
              data-test-selector={TestSelector.RoleUpIcon}
              glyph="CaretUp"
              size="xlarge"
              fill={upDisabled ? palette.gray.light1 : palette.blue.light1}
            />
          </IconButton>
          <IconButton
            aria-label="role down"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickDown();
            }}
            disabled={downDisabled}
            data-test-selector={TestSelector.RoleDown}
            data-testid={TestSelector.RoleDown}
            data-cy={TestSelector.RoleDown}
          >
            <Icon
              data-test-selector={TestSelector.RoleDownIcon}
              glyph="CaretDown"
              size="xlarge"
              fill={downDisabled ? palette.gray.light1 : palette.blue.light1}
            />
          </IconButton>
        </div>
        <Subtitle className={`${headerClassName}-identifier`} data-test-selector={TestSelector.Identifier}>
          {`${index}. ${role.name}`}
          {hasSyncIncompatibleRoles && (
            <Tooltip
              className={`${headerClassName}-sync-incompatible-roles-tooltip`}
              justify="middle"
              trigger={
                <IconButton
                  className={`${headerClassName}-sync-incompatible-roles-icon`}
                  aria-labelledby="sync-incompatible-roles-warning-icon"
                  data-testid={TestSelector.IncompatibleRoleIcon}
                  onClick={onClickEdit}
                >
                  <Icon glyph="ImportantWithCircle" fill={palette.yellow.dark2} />
                </IconButton>
              }
              triggerEvent="hover"
            >
              This role is incompatible with Sync. Click to see this role&apos;s incompatible settings.
            </Tooltip>
          )}
        </Subtitle>
        <Menu
          trigger={
            <IconButton
              aria-label="open role menu"
              className={`${headerClassName}-menu-trigger`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              data-cy="role-card-menu-trigger"
              data-testid={TestSelector.ActionsMenu}
            >
              <Icon glyph="Ellipsis" fill={palette.blue.base} size="xlarge" />
            </IconButton>
          }
        >
          <MenuItem
            data-testid={TestSelector.EditRole}
            data-test-selector={TestSelector.EditRole}
            data-cy={TestSelector.EditRole}
            onClick={onClickEdit}
          >
            <div className={menuItemContentClassName}>
              <Icon glyph="Edit" size="large" className={menuItemContentIconClassName} />
              Edit role
            </div>
          </MenuItem>
          <MenuItem
            data-test-selector={TestSelector.DeleteRole}
            data-testid={TestSelector.DeleteRole}
            data-cy={TestSelector.DeleteRole}
            onClick={onClickDelete}
          >
            <div className={`${menuItemContentClassName} ${menuItemContentClassName}-delete`}>
              <Icon glyph="Trash" size="large" fill={palette.red.base} className={menuItemContentIconClassName} />
              Delete role
            </div>
          </MenuItem>
        </Menu>
      </div>
      <div className={`${baseClassName}-content`}>
        <RoleInfoTable
          applyWhenExp={role.applyWhen || {}}
          onClickViewApplyWhenJSON={onClickViewApplyWhenJson}
          insertDisplayVal={
            resolvePermissionValue(role.insert) === PermissionValue.Enabled
              ? PermissionDisplayValue.IconCheck
              : PermissionDisplayValue.IconX
          }
          deleteDisplayVal={
            resolvePermissionValue(role.delete) === PermissionValue.Enabled
              ? PermissionDisplayValue.IconCheck
              : PermissionDisplayValue.IconX
          }
          searchDisplayVal={
            resolvePermissionValue(role.search) === PermissionValue.Enabled
              ? PermissionDisplayValue.IconCheck
              : PermissionDisplayValue.IconX
          }
          readDisplayVal={permissionValToDisplayValMap.get(read)!}
          writeDisplayVal={permissionValToDisplayValMap.get(write)!}
        />
      </div>
    </Card>
  );
};

export default RoleCard;
