import React from 'react';
import Card from '@leafygreen-ui/card';
import { Body } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import './template-card.less';

export enum TestSelector {
  Header = 'heading',
  Description = 'description',
  Image = 'image',
}

export interface Props {
  headerImage: React.ReactElement;
  headerText: string;
  description: string;
  clientTechnologyLogos: React.ReactElement[];
  onClick?(): void;
  focus: boolean;
  disabled?: boolean;
}

const getSelectedClassName = (focused: boolean, className: string) => {
  return classNames({ [`${className}--selected`]: focused });
};

const getDisabledClassName = (disabled: boolean, className: string) => {
  return classNames({ [`${className}--disabled`]: disabled });
};

const baseClassName = 'template-card';
const containerClassName = `${baseClassName}-container`;
const TemplateCard = ({
  headerImage,
  headerText: heading,
  description,
  clientTechnologyLogos,
  onClick,
  focus,
  disabled = false,
  ...rest
}: Props) => {
  return (
    <Card
      className={classNames(
        containerClassName,
        getSelectedClassName(focus, containerClassName),
        getDisabledClassName(disabled, containerClassName)
      )}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      <div
        data-cy="template-app-card"
        className={classNames(`${baseClassName}-img-background`)}
        data-test-selector={TestSelector.Image}
      >
        {headerImage}
      </div>
      <div className={classNames(`${baseClassName}-content`, getSelectedClassName(focus, `${baseClassName}-content`))}>
        <Body weight="medium" className={`${baseClassName}-content-heading`} data-test-selector={TestSelector.Header}>
          {heading}
        </Body>
        <Body data-test-selector={TestSelector.Description}>{description}</Body>
      </div>
      <div className={`${baseClassName}-tech`}>{clientTechnologyLogos}</div>
    </Card>
  );
};

export default TemplateCard;
