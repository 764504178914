import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { usePrevious } from '@leafygreen-ui/hooks';
import Toast, { Variant as ToastVariant } from '@leafygreen-ui/toast';

import usePoller from 'baas-ui/common/hooks/use-poller';
import * as deployActions from 'baas-ui/deploy/actions';
import * as actions from 'baas-ui/functions/dependencies/actions';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { getAppState, getDependenciesState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';
import { DependencyInstallation, InstallationStatus } from 'admin-sdk';

import './dependencies-status-toast.less';

export const fetchInstallationInterval = 3e3;

export enum TestSelector {
  StatusToast = 'dependencies-status-toast',
}

export interface Props {
  className?: string;
  fetchInstallation(): Promise<any>;
  installation?: DependencyInstallation;
  onSuccess(): void;
}

export function getToastVariantAndTitle(install: DependencyInstallation): {
  variant: ToastVariant;
  title: string;
} {
  switch (install.status) {
    case InstallationStatus.Created:
      return {
        variant: ToastVariant.Progress,
        title: 'Installing dependencies...',
      };
    case InstallationStatus.Successful:
      return {
        variant: ToastVariant.Success,
        title: 'Dependencies installed successfully',
      };
    case InstallationStatus.Failed:
      return {
        variant: ToastVariant.Warning,
        title: 'Failed to install dependencies',
      };
    default:
      throw new Error(`unexpected installation status: ${install.status}`);
  }
}

export function DependenciesStatusToast({ className, fetchInstallation, installation, onSuccess }: Props) {
  const poller = usePoller();
  const [isOpen, setIsOpen] = useState(false);
  const prevInstallation = usePrevious(installation);

  const fetchInstallationWrapper = useCallback(
    () =>
      fetchInstallation().catch((e) => {
        if (e?.response?.status !== 404) {
          throw e;
        }
      }),
    [fetchInstallation]
  );

  React.useEffect(() => {
    fetchInstallationWrapper().catch(() => {});
  }, []);

  // If users dismiss the toast while the installation is in progress, we still
  // want to let them know if the installation completed successfully or not.
  React.useEffect(() => {
    if (prevInstallation && prevInstallation.status !== installation?.status) {
      setIsOpen(true);
    }
  }, [installation?.status, prevInstallation]);

  React.useEffect(() => {
    if (installation?.status === InstallationStatus.Created && !poller.isPolling) {
      setIsOpen(true);
      poller.start(fetchInstallationWrapper, fetchInstallationInterval);
    }
  }, [fetchInstallationWrapper, installation?.status, poller]);

  React.useEffect(() => {
    if (poller.isPolling && installation?.status !== InstallationStatus.Created) {
      poller.stop();

      if (installation?.status === InstallationStatus.Successful) {
        onSuccess();
      }
    }
  }, [installation?.status, onSuccess, poller]);

  if (!installation) {
    return null;
  }

  const { variant, title } = getToastVariantAndTitle(installation);
  return (
    <Toast
      data-testid={TestSelector.StatusToast}
      open={isOpen}
      className={className}
      close={() => setIsOpen(false)}
      variant={variant}
      title={title}
      body={installation.statusMessage}
    />
  );
}

const mapStateToProps = (state: RootState) => {
  const { groupId, id } = getAppState(state).app;
  const { lastInstallStatus } = getDependenciesState(state);

  return {
    appId: id,
    groupId,
    lastInstallStatus,
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  fetchInstallStatus: (groupId: string, appId: string) => dispatch(actions.fetchInstallStatus({ groupId, appId })),
  loadDependencies: (groupId: string, appId: string) => dispatch(actions.loadDependencies({ groupId, appId })),
  loadDrafts: (groupId: string, appId: string) => dispatch(deployActions.loadDrafts({ appId, groupId })),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): Props => {
  const { groupId, appId, lastInstallStatus } = stateProps;
  return {
    installation: lastInstallStatus,
    fetchInstallation: () => dispatchProps.fetchInstallStatus(groupId, appId),
    onSuccess: () => {
      dispatchProps.loadDependencies(groupId, appId);
      dispatchProps.loadDrafts(groupId, appId);
    },
    className: 'dependencies-status-toast',
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DependenciesStatusToast);
