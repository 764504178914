/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import Button, { Size } from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import { OneOf } from '@leafygreen-ui/lib';
import { palette } from '@leafygreen-ui/palette';
import { Option, OptionGroup, Select } from '@leafygreen-ui/select';
import TextInput, { State } from '@leafygreen-ui/text-input';
import classNames from 'classnames';

import './creatable-select.less';

interface CreatableSelectProps extends Omit<React.ComponentProps<typeof Select>, 'label' | 'aria-labelledby'> {
  createNewOptionText?: string;
  createTextInputPlaceholder?: string;
  createTextInputErrorMessage?: string;
  value: string;
  className?: string;
  innerSelectClassName?: string;
  onCreateNewValue?: (value: string) => void;
  isValidNewValue?: (value: string) => boolean;
  'data-testid'?: string;
}

export type Props = CreatableSelectProps & OneOf<{ label: string }, { 'aria-labelledby': string }>;

export enum TestSelector {
  Container = 'container',
  CreatableSelect = 'creatable-select',
  NewOption = 'new-option',
  NewOptionInput = 'new-option-input',
  CreateButton = 'create-button',
  CancelButton = 'cancel-button',
}

const baseClassName = 'creatable-select';
export const NEW_VALUE = `NEW_VALUE_FOR_THE_CREATABLE_SELECT_${Math.random()}`;

const CreatableSelect = ({
  allowDeselect = false,
  createNewOptionText = 'Create New Option',
  children,
  value,
  createTextInputPlaceholder = 'Enter your new option here',
  createTextInputErrorMessage = 'This is not a valid option.',
  className,
  innerSelectClassName,
  onChange = () => {},
  onCreateNewValue = () => {},
  isValidNewValue = () => true,
  ...selectProps
}: Props) => {
  const [showCreateInput, setShowTextInput] = useState(false);
  const [customOptionValue, setCustomOptionValue] = useState('');
  const [textInputState, setTextInputState] = useState<State>(State.None);

  let creatableSelectLabelProps;

  /*
    The following logic is required for the below Select component to work correctly. Regardless of the 
    prop values passed into the CreatableSelect, the Select requires that either label or aria-labelledby
    be provided to it, and their types cannot be string | undefined. Only string. Or undefined. 
    Therefore the logic is as follows: by default set label to undefined and aria-labelledby to a
    default string or the passed value. Then reset the values as necessary based on the parent's passed
    props, and apply to the Select.
  */

  const noLabelNoAriaProps = {
    label: undefined,
    'aria-labelledby': selectProps['aria-labelledby'] || 'creatable-select',
  };

  const hasLabelnoAria = {
    label: selectProps.label,
    'aria-labelledby': undefined,
  };

  if (selectProps.label) {
    creatableSelectLabelProps = hasLabelnoAria;
  } else {
    creatableSelectLabelProps = noLabelNoAriaProps;
  }

  return (
    <div className={className} data-testid={TestSelector.Container}>
      <Select
        data-testid={selectProps['data-testid'] || TestSelector.CreatableSelect}
        className={classNames(baseClassName, innerSelectClassName)}
        allowDeselect={allowDeselect}
        value={value}
        {...selectProps}
        onChange={(optionValue, changeEvent) => {
          if (optionValue === NEW_VALUE) {
            setShowTextInput(true);
          } else {
            setShowTextInput(false);
            onChange(optionValue, changeEvent);
          }
        }}
        {...creatableSelectLabelProps}
      >
        <OptionGroup label="">
          <Option
            data-testid={TestSelector.NewOption}
            data-cy={TestSelector.NewOption}
            data-test-selector={TestSelector.NewOption}
            className={`${baseClassName}-create-option`}
            glyph={<Icon glyph="Plus" fill={palette.black} />}
            value={NEW_VALUE}
          >
            {createNewOptionText}
          </Option>
        </OptionGroup>
        {children}
      </Select>

      {showCreateInput && (
        <>
          <TextInput
            aria-labelledby="create-option-input"
            data-testid={TestSelector.NewOptionInput}
            data-cy={TestSelector.NewOptionInput}
            data-test-selector={TestSelector.NewOptionInput}
            className={`${baseClassName}-text-input`}
            id={'SelectCreateInput'}
            label=""
            value={customOptionValue}
            placeholder={createTextInputPlaceholder}
            onChange={(e) => setCustomOptionValue(e.target.value)}
            state={textInputState}
            errorMessage={createTextInputErrorMessage}
          />
          <div className={`${baseClassName}-button-container`}>
            <Button
              data-testid={TestSelector.CancelButton}
              size={Size.Small}
              onClick={() => {
                setShowTextInput(false);
                setCustomOptionValue('');
                setTextInputState(State.None);
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid={TestSelector.CreateButton}
              className={`${baseClassName}-create-button`}
              variant="primaryOutline"
              size={Size.Small}
              onClick={() => {
                if (isValidNewValue(customOptionValue)) {
                  onCreateNewValue(customOptionValue);
                  setTextInputState(State.None);
                  setShowTextInput(false);
                  setCustomOptionValue('');
                } else {
                  setTextInputState(State.Error);
                }
              }}
            >
              Create
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default CreatableSelect;
