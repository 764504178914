/* eslint-disable camelcase */
import { createReducer } from 'redux-act';

import * as actions from './actions';
import { DeployStoreState } from './types';

export const defaultState: DeployStoreState = {
  loadingDeployConfig: false,
  loadingDeployHistory: false,
  loadingDeployInstallations: false,
  loadingDeployBranches: false,
  loadingDeployment: false,
  loadingLatestDeployment: false,
  loadingDraft: false,
  loadingDiff: false,
  deleteDraftError: undefined,
  deployDraftError: undefined,
  exportError: undefined,
  loadDeployConfigError: undefined,
  loadDeployHistoryError: undefined,
  loadDeployInstallationError: undefined,
  loadDeployBranchesError: undefined,
  loadDraftsError: undefined,
  loadDiffError: undefined,
  loadDeploymentError: undefined,
  loadLatestDeploymentError: undefined,
  deploymentError: undefined,
  deployConfig: {
    uiDraftsDisabled: false,
    automaticDeploymentConfig: {
      enabled: false,
      installationIds: [],
      lastModified: 0,
      providerType: undefined,
      repository: undefined,
    },
  },
  deployInstallations: [],
  deployBranches: [],
  deployHistory: {},
  latestDeployment: undefined,
  lastDeployedAt: undefined,
  draft: undefined,
  diff: undefined,
};

const reducer = createReducer<DeployStoreState>({}, defaultState);

// LOAD
reducer.on(actions.loadDeployConfigActions.req, (state) => ({
  ...state,
  loadingDeployConfig: true,
}));
reducer.on(actions.loadDeployConfigActions.rcv, (state, { payload }) => ({
  ...state,
  loadDeployConfigError: undefined,
  loadingDeployConfig: false,
  deployConfig: payload,
}));
reducer.on(actions.loadDeployConfigActions.fail, (state, { error }) => ({
  ...state,
  loadingDeployConfig: false,
  loadDeployConfigError: error,
}));

reducer.on(actions.loadDeployInstallationActions.req, (state) => ({
  ...state,
  loadDeployInstallationError: undefined,
  loadingDeployInstallations: true,
}));
reducer.on(actions.loadDeployInstallationActions.rcv, (state, { payload }) => ({
  ...state,
  loadDeployInstallationError: undefined,
  loadingDeployInstallations: false,
  deployInstallations: payload,
}));
reducer.on(actions.loadDeployInstallationActions.fail, (state, { error }) => ({
  ...state,
  loadingDeployInstallations: false,
  loadDeployInstallationError: error,
}));

reducer.on(actions.clearInstallations, (state) => ({
  ...state,
  deployInstallations: [],
  deployBranches: [],
}));

reducer.on(actions.loadDeployBranchesActions.req, (state) => ({
  ...state,
  loadDeployBranchesError: undefined,
  loadingDeployBranches: true,
}));
reducer.on(actions.loadDeployBranchesActions.rcv, (state, { payload }) => ({
  ...state,
  loadDeployBranchesError: undefined,
  loadingDeployBranches: false,
  deployBranches: payload,
}));
reducer.on(actions.loadDeployBranchesActions.fail, (state, { error }) => ({
  ...state,
  loadingDeployBranches: false,
  loadDeployBranchesError: error,
}));

reducer.on(actions.loadDeployHistoryActions.req, (state) => ({
  ...state,
  loadDeployHistoryError: undefined,
  loadingDeployHistory: true,
}));
reducer.on(actions.loadDeployHistoryActions.rcv, (state, { payload }) => ({
  ...state,
  loadDeployHistoryError: undefined,
  loadingDeployHistory: false,
  deployHistory: payload.reduce(
    (acc, obj) => ({
      ...acc,
      [obj.id]: obj,
    }),
    {}
  ),
  mostRecentDeploy: payload[0],
}));
reducer.on(actions.loadDeployHistoryActions.fail, (state, { error }) => ({
  ...state,
  loadingDeployHistory: false,
  deployHistory: {},
  loadDeployHistoryError: error,
}));

reducer.on(actions.loadLatestDeploymentActions.req, (state) => ({
  ...state,
  loadLatestDeploymentError: undefined,
  loadingLatestDeployment: true,
}));
reducer.on(actions.loadLatestDeploymentActions.rcv, (state, { payload }) => ({
  ...state,
  loadingLatestDeployment: false,
  latestDeployment: payload[0],
  lastDeployedAt: payload[0] ? payload[0].deployedAt : state.lastDeployedAt,
}));
reducer.on(actions.loadLatestDeploymentActions.fail, (state, { error }) => ({
  ...state,
  loadLatestDeploymentError: error,
  loadingLatestDeployment: false,
}));

reducer.on(actions.loadDraftsActions.req, (state) => ({
  ...state,
  loadDraftsError: undefined,
  loadingDraft: true,
}));
reducer.on(actions.loadDraftsActions.rcv, (state, { payload }) => ({
  ...state,
  loadDraftsError: undefined,
  loadingDraft: false,
  draft: payload[0],
}));
reducer.on(actions.loadDraftsActions.fail, (state, { error }) => ({
  ...state,
  loadDraftsError: error,
  loadingDraft: false,
  draft: undefined,
}));

reducer.on(actions.loadDiffActions.req, (state) => ({
  ...state,
  loadDiffError: undefined,
  loadingDiff: true,
}));
reducer.on(actions.loadDiffActions.rcv, (state, { payload }) => ({
  ...state,
  loadDiffError: undefined,
  loadingDiff: false,
  diff: payload,
}));
reducer.on(actions.loadDiffActions.fail, (state, { error }) => ({
  ...state,
  loadDiffError: error,
  loadingDiff: false,
}));

reducer.on(actions.loadDeploymentActions.req, (state) => ({
  ...state,
  loadDeploymentError: undefined,
  loadingDeployment: true,
}));
reducer.on(
  actions.loadDeploymentActions.rcv,
  (state, { payload: { deployedAt: lastDeployedAt, statusErrorMessage: deploymentError } }) => ({
    ...state,
    loadDeploymentError: undefined,
    deploymentError,
    loadingDeployment: false,
    lastDeployedAt,
  })
);
reducer.on(actions.loadDeploymentActions.fail, (state, { error }) => ({
  ...state,
  loadDeploymentError: error,
  loadingDeployment: false,
}));

// DEPLOY
reducer.on(actions.deployDraftActions.req, (state) => ({
  ...state,
  deployDraftError: undefined,
}));
reducer.on(actions.deployDraftActions.fail, (state, { error }) => ({
  ...state,
  deployDraftError: error,
}));

// DELETE
reducer.on(actions.deleteDraftActions.req, (state) => ({
  ...state,
  deleteDraftError: undefined,
}));
reducer.on(actions.deleteDraftActions.rcv, (state) => ({
  ...state,
  deleteDraftError: undefined,
  draft: undefined,
}));
reducer.on(actions.deleteDraftActions.fail, (state, { error }) => ({
  ...state,
  deleteDraftError: error,
}));

export default reducer;
