import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Banner, { Variant } from '@leafygreen-ui/banner';

import { clearUserProfile } from 'baas-ui/actions';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { getAdminClientState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';
import { rootUrl } from 'baas-ui/urls';
import { BaasAdminClient } from 'admin-sdk';

interface ReduxDispatchProps {
  clearUserProfile(): void;
}

interface ReduxStateProps {
  adminClient: BaasAdminClient;
}

export type Props = ReduxDispatchProps & ReduxStateProps;

const Logout = ({ adminClient, clearUserProfile: clearProfile }: Props) => {
  const history = useHistory();

  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    adminClient
      .logout()
      .then(() => history.push(rootUrl.login()))
      .then(clearProfile)
      .catch((e: Error) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, []);

  return !error ? null : (
    <Banner variant={Variant.Danger} dismissible onClose={() => setError(undefined)}>
      {error}
    </Banner>
  );
};

const mapStateToProps = (state: RootState) => ({
  adminClient: getAdminClientState(state),
});

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  clearUserProfile: () => dispatch(clearUserProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
