import { createReducer } from 'redux-act';

import { Device, PartialUser } from 'admin-sdk';

import * as actions from './actions';
import { fromPasswordRecord } from './converters';
import { PendingUser, PendingUserAction } from './types';

export const defaultState: UsersState = {
  userIds: [],
  partialById: {},
  devicesById: {},
  loadingUsers: false,
  togglingStatusByUser: {},
  loadingUserDevices: {},
  lastUserId: undefined,
  loadUsersError: undefined,
  devicesErrorById: {},

  // pending user state
  userConfirmationStatuses: {},
  partialPendingByEmail: {},
  loadingPendingUsers: false,
  loadPendingUsersError: undefined,
  lastPendingUserId: undefined,
  removePendingUserError: undefined,
};

export type UsersState = {
  userIds: string[];
  loadingUsers: boolean;
  loadUsersError?: string;
  partialById: Record<string, PartialUser>;
  devicesById: Record<string, Device[]>;
  togglingStatusByUser: Record<string, boolean>;
  loadingUserDevices: Record<string, boolean>;
  lastUserId?: string;
  devicesErrorById: Record<string, string>;
  userConfirmationStatuses: any;
  partialPendingByEmail: Record<string, PendingUser>;
  loadingPendingUsers: boolean;
  loadPendingUsersError?: string;
  lastPendingUserId?: string;
  removePendingUserError?: string;
};

const usersReducer = createReducer<UsersState>({}, defaultState);

usersReducer.on(actions.disableUserActions.req, (state, { userId }) => ({
  ...state,
  togglingStatusByUser: { ...state.togglingStatusByUser, [userId]: true },
}));

usersReducer.on(actions.disableUserActions.fail, (state, { reqArgs: { userId } }) => ({
  ...state,
  togglingStatusByUser: { ...state.togglingStatusByUser, [userId]: false },
}));

usersReducer.on(actions.disableUserActions.rcv, (state, { reqArgs: { userId } }) => {
  const existingUser: PartialUser = state.partialById[userId];
  const updatedUser = { ...existingUser, disabled: true };

  return {
    ...state,
    togglingStatusByUser: { ...state.togglingStatusByUser, [userId]: false },
    partialById: { ...state.partialById, [userId]: updatedUser },
  };
});

usersReducer.on(actions.enableUserActions.req, (state, { userId }) => ({
  ...state,
  togglingStatusByUser: { ...state.togglingStatusByUser, [userId]: true },
}));

usersReducer.on(actions.enableUserActions.fail, (state, { reqArgs: { userId } }) => ({
  ...state,
  togglingStatusByUser: { ...state.togglingStatusByUser, [userId]: false },
}));

usersReducer.on(actions.enableUserActions.rcv, (state, { reqArgs: { userId } }) => {
  const existingUser: PartialUser = state.partialById[userId];
  const updatedUser = { ...existingUser, disabled: false };

  return {
    ...state,
    togglingStatusByUser: { ...state.togglingStatusByUser, [userId]: false },
    partialById: { ...state.partialById, [userId]: updatedUser },
  };
});

usersReducer.on(actions.resetUsersAction, (state) => ({
  ...state,
  userIds: [],
  lastUserId: undefined,
}));

usersReducer.on(actions.resetLastUserAction, (state) => ({
  ...state,
  lastUserId: undefined,
}));

usersReducer.on(actions.loadUsersActions.req, (state) => ({
  ...state,
  loadingUsers: true,
}));

usersReducer.on(actions.loadUsersActions.rcv, (state, { payload, reqArgs }) => {
  const newPartialUsersById = payload.reduce((acc, u) => ({ ...acc, [u.id]: u }), {});
  return {
    ...state,
    loadingUsers: false,
    userIds: reqArgs.filter?.after ? state.userIds.concat(payload.map((u) => u.id)) : payload.map((u) => u.id),
    partialById: { ...state.partialById, ...newPartialUsersById },
    lastUserId: payload.length > 0 ? payload[payload.length - 1].id : state.lastUserId,
    loadUsersError: undefined,
  };
});

usersReducer.on(actions.loadUsersActions.fail, (state, { error }) => ({
  ...state,
  loadingUsers: false,
  loadUsersError: error,
}));

usersReducer.on(actions.loadPendingUsersActions.req, (state) => ({
  ...state,
  loadingPendingUsers: true,
}));

usersReducer.on(actions.loadPendingUsersActions.rcv, (state, { payload }) => {
  const newPartialPendingUsersByEmail = payload.reduce((partialPendingUsers, passwordRecord) => {
    const pendingUser = fromPasswordRecord(passwordRecord);
    return {
      ...partialPendingUsers,
      [pendingUser.name]: pendingUser,
    };
  }, {});

  return {
    ...state,
    loadingPendingUsers: false,
    partialPendingByEmail: { ...state.partialPendingByEmail, ...newPartialPendingUsersByEmail },
    lastPendingUserId: payload.length > 0 ? payload[payload.length - 1].id : state.lastPendingUserId,
    loadPendingUsersError: undefined,
  };
});

usersReducer.on(actions.loadPendingUsersActions.fail, (state, { error }) => ({
  ...state,
  loadingPendingUsers: false,
  loadPendingUsersError: error,
}));

usersReducer.on(actions.loadUserDevicesActions.req, (state, { userId }) => ({
  ...state,
  loadingUserDevices: { ...state.loadingUserDevices, [userId]: true },
}));

usersReducer.on(actions.loadUserDevicesActions.rcv, (state, { payload, reqArgs: { userId } }) => {
  const newDevicesErrorById = { ...state.devicesErrorById };
  delete newDevicesErrorById[userId];

  return {
    ...state,
    devicesById: { ...state.devicesById, [userId]: payload },
    loadingUserDevices: { ...state.loadingUserDevices, [userId]: false },
    devicesErrorById: newDevicesErrorById,
  };
});

usersReducer.on(actions.loadUserDevicesActions.fail, (state, { error, reqArgs: { userId } }) => ({
  ...state,
  loadingUserDevices: { ...state.loadingUserDevices, [userId]: false },
  devicesErrorById: { ...state.devicesErrorById, [userId]: error },
}));

usersReducer.on(actions.loadUserActions.rcv, (state, { payload, reqArgs: { userId } }) => ({
  ...state,
  partialById: { ...state.partialById, [userId]: payload },
}));

usersReducer.on(actions.confirmUserByEmailActions.req, (state, { email }) => {
  const { userConfirmationStatuses } = state;
  return {
    ...state,
    userConfirmationStatuses: {
      ...userConfirmationStatuses,
      [email]: {
        email,
        action: PendingUserAction.Confirm,
        confirming: true,
        error: undefined,
      },
    },
  };
});

usersReducer.on(actions.confirmUserByEmailActions.rcv, (state, { reqArgs: { email } }) => {
  const { userConfirmationStatuses, partialPendingByEmail } = state;
  return {
    ...state,
    userConfirmationStatuses: {
      ...userConfirmationStatuses,
      [email]: {
        email,
        action: PendingUserAction.Confirm,
        confirming: false,
        error: undefined,
      },
    },
    partialPendingByEmail: {
      ...partialPendingByEmail,
      [email]: {
        ...partialPendingByEmail[email],
        confirmed: true,
      },
    },
  };
});

usersReducer.on(actions.confirmUserByEmailActions.fail, (state, { error, reqArgs: { email } }) => {
  const { userConfirmationStatuses } = state;
  return {
    ...state,
    userConfirmationStatuses: {
      ...userConfirmationStatuses,
      [email]: {
        email,
        action: PendingUserAction.Confirm,
        confirming: false,
        error,
      },
    },
  };
});

usersReducer.on(actions.runUserConfirmationByEmailActions.req, (state, { email }) => {
  const { userConfirmationStatuses } = state;
  return {
    ...state,
    userConfirmationStatuses: {
      ...userConfirmationStatuses,
      [email]: {
        email,
        action: PendingUserAction.Run,
        confirming: true,
        error: undefined,
      },
    },
  };
});

usersReducer.on(actions.runUserConfirmationByEmailActions.rcv, (state, { reqArgs: { email } }) => {
  const { userConfirmationStatuses } = state;
  return {
    ...state,
    userConfirmationStatuses: {
      ...userConfirmationStatuses,
      [email]: {
        email,
        action: PendingUserAction.Run,
        confirming: false,
        error: undefined,
      },
    },
  };
});

usersReducer.on(actions.runUserConfirmationByEmailActions.fail, (state, { error, reqArgs: { email } }) => {
  const { userConfirmationStatuses } = state;
  return {
    ...state,
    userConfirmationStatuses: {
      ...userConfirmationStatuses,
      [email]: {
        email,
        action: PendingUserAction.Run,
        confirming: false,
        error,
      },
    },
  };
});

usersReducer.on(actions.clearUserConfirmationStatus, (state, { email }) => {
  const userConfirmationStatuses = { ...state.userConfirmationStatuses };
  delete userConfirmationStatuses[email];
  return {
    ...state,
    userConfirmationStatuses,
  };
});

usersReducer.on(actions.removePendingUserByEmailActions.req, (state) => ({
  ...state,
  removePendingUserError: undefined,
}));

usersReducer.on(actions.removePendingUserByEmailActions.rcv, (state, { reqArgs: { email } }) => {
  const partialPendingByEmail = { ...state.partialPendingByEmail };
  delete partialPendingByEmail[email];
  return {
    ...state,
    partialPendingByEmail,
    removePendingUserError: undefined,
  };
});

usersReducer.on(actions.removePendingUserByEmailActions.fail, (state, { error, reqArgs: { email } }) => ({
  ...state,
  removePendingUserError: `Failed to delete user '${email}': ${error}`,
}));

export default usersReducer;
