import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PartialBaseAppFunction')
class PartialBaseAppFunction {
  @JsonProperty('name')
  public name = '';

  @JsonProperty('path', String, true)
  public path?: string = undefined;

  @JsonProperty('last_modified', Number, true)
  public lastModified?: number = undefined;

  @JsonProperty('read_only', Boolean, true)
  public readOnly? = false;

  constructor(partial?: Partial<PartialBaseAppFunction>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PartialAppFunction')
export class PartialAppFunction extends PartialBaseAppFunction {
  @JsonProperty('_id')
  public id = '';

  constructor(partial?: Partial<PartialAppFunction>) {
    super(partial);
    Object.assign(this, partial);
  }
}

@JsonObject('BaseAppFunction')
class BaseAppFunction extends PartialBaseAppFunction {
  @JsonProperty('source')
  public source = '';

  @JsonProperty('can_evaluate', Any, true)
  public canEvaluate?: Record<string, any> = undefined;

  @JsonProperty('private')
  public private = false;

  @JsonProperty('run_as_system', Boolean, true)
  public runAsSystem?: boolean = undefined;

  @JsonProperty('run_as_user_id', String, true)
  public runAsUserId?: string = undefined;

  @JsonProperty('run_as_user_id_script_source', String, true)
  public runAsUserIdScriptSource?: string = undefined;

  @JsonProperty('run_as_user_id_script_transpiled_source', String, true)
  public runAsUserIdScriptTranspiledSource?: string = undefined;

  @JsonProperty('run_as_user_id_script_source_map', Buffer, true)
  public runAsUserIdScripSourceMap?: Buffer = undefined;

  @JsonProperty('disable_arg_logs', Boolean, true)
  public disableArgLogs?: boolean = undefined;

  constructor(partial?: Partial<BaseAppFunction>) {
    super(partial);
    Object.assign(this, partial);
  }
}

@JsonObject('CreateAppFunction')
export class CreateAppFunction extends BaseAppFunction {
  constructor(partial?: Partial<CreateAppFunction>) {
    super(partial);
    Object.assign(this, partial);
  }
}

@JsonObject('ClientAppFunction')
export class ClientAppFunction extends BaseAppFunction {
  @JsonProperty('_id', String)
  public id = '';

  constructor(partial?: Partial<ClientAppFunction>) {
    super(partial);
    Object.assign(this, partial);
  }
}

@JsonObject('AppFunction')
export class AppFunction extends ClientAppFunction {
  @JsonProperty('transpiled_source', String, true)
  public transpiledSource?: string = undefined;

  @JsonProperty('source_map', Buffer, true)
  public sourceMap?: Buffer = undefined;

  constructor(partial?: Partial<AppFunction>) {
    super(partial);
    Object.assign(this, partial);
  }
}
