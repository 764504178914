import JSON5 from 'json5';

import { prettyJSONStringify } from 'baas-ui/common/utils/util';
import { SyncState } from 'admin-sdk';

import { RawPartitionSyncConfig, RawPartitionSyncPermissions } from './admin';
import { PartitionSyncConfig, ServiceSyncConfig, SyncPermissions, SyncType } from './types';
import { QuerySyncConfig, RawQuerySyncConfig, RawSyncConfig } from '.';

export const fromRawPartitionPermissions = (permissions: RawPartitionSyncPermissions): SyncPermissions => {
  return {
    read: prettyJSONStringify(permissions.read),
    write: prettyJSONStringify(permissions.write),
  };
};

export const fromRawPartitionSyncConfig = (raw: RawPartitionSyncConfig): PartitionSyncConfig => {
  return {
    type: SyncType.Partition,
    state: <SyncState>raw.state,
    databaseName: raw.database_name,
    partitionKey: raw.partition.key,
    partitionKeyRequired: raw.partition.required,
    partitionKeyType: raw.partition.type,
    permissions: fromRawPartitionPermissions(raw.partition.permissions),
    lastDisabled: raw.last_disabled,
    maxOfflineTime: raw.client_max_offline_days,
    clientRecoveryDisabled: raw.is_recovery_mode_disabled,
  };
};

interface ConvertRawPartitionSyncConfigResult {
  rawConfig: RawPartitionSyncConfig;
  readErr: string;
  writeErr: string;
  maxOfflineTimeInvalid: boolean;
}

export const toRawPartitionSyncConfig = (syncConfig: PartitionSyncConfig): ConvertRawPartitionSyncConfigResult => {
  let read: unknown;
  let readErr = '';
  try {
    read = JSON5.parse(syncConfig.permissions.read);
  } catch (e) {
    readErr = 'Invalid JSON';
  }

  let write: unknown;
  let writeErr = '';
  try {
    write = JSON5.parse(syncConfig.permissions.write);
  } catch (e) {
    writeErr = 'Invalid JSON';
  }

  let maxOfflineTimeInvalid = false;
  if (syncConfig.maxOfflineTime !== undefined) {
    if (Number.isNaN(syncConfig.maxOfflineTime)) {
      maxOfflineTimeInvalid = true;
    } else if (syncConfig.maxOfflineTime !== 0 && syncConfig.maxOfflineTime < 1) {
      maxOfflineTimeInvalid = true;
    }
  }

  return {
    readErr,
    writeErr,
    maxOfflineTimeInvalid,
    rawConfig: {
      state: syncConfig.state,
      database_name: syncConfig.databaseName,
      partition: {
        key: syncConfig.partitionKey,
        type: syncConfig.partitionKeyType,
        required: syncConfig.partitionKeyRequired,
        permissions: { read, write },
      },
      last_disabled: syncConfig.lastDisabled,
      client_max_offline_days: syncConfig.maxOfflineTime,
      is_recovery_mode_disabled: syncConfig.clientRecoveryDisabled,
    },
  };
};

export const fromRawQuerySyncConfig = (raw: RawQuerySyncConfig): QuerySyncConfig => {
  return {
    type: SyncType.Flexible,
    state: <SyncState>raw.state,
    databaseName: raw.database_name,
    lastDisabled: raw.last_disabled,
    globalQueryableFieldsNames: raw.queryable_fields_names,
    collectionQueryableFieldsNames: raw.collection_queryable_fields_names,
    indexedQueryableFieldsNames: raw.indexed_queryable_fields_names,
    asymmetricTables: raw.asymmetric_tables,
    permissions: JSON.stringify(raw.permissions, null, 2),
    maxOfflineTime: raw.client_max_offline_days,
    clientRecoveryDisabled: raw.is_recovery_mode_disabled,
  };
};
interface ConvertRawQuerySyncConfigResult {
  rawConfig: RawQuerySyncConfig;
  permissionsErr: string;
  maxOfflineTimeInvalid: boolean;
}

export const toRawQuerySyncConfig = (syncConfig: QuerySyncConfig): ConvertRawQuerySyncConfigResult => {
  let maxOfflineTimeInvalid = false;
  if (syncConfig.maxOfflineTime !== undefined) {
    if (Number.isNaN(syncConfig.maxOfflineTime)) {
      maxOfflineTimeInvalid = true;
    } else if (syncConfig.maxOfflineTime !== 0 && syncConfig.maxOfflineTime < 1) {
      maxOfflineTimeInvalid = true;
    }
  }

  return {
    permissionsErr: '',
    maxOfflineTimeInvalid,
    rawConfig: {
      state: syncConfig.state,
      database_name: syncConfig.databaseName,
      queryable_fields_names: syncConfig.globalQueryableFieldsNames,
      collection_queryable_fields_names: syncConfig.collectionQueryableFieldsNames,
      indexed_queryable_fields_names: syncConfig.indexedQueryableFieldsNames,
      client_max_offline_days: syncConfig.maxOfflineTime,
      is_recovery_mode_disabled: syncConfig.clientRecoveryDisabled,
      asymmetric_tables: syncConfig.asymmetricTables,
    },
  };
};

export const convertToRawSyncConfig = (
  syncConfig: ServiceSyncConfig
): { rawConfig?: RawSyncConfig; syncType?: SyncType; errs: string[] } => {
  switch (syncConfig.type) {
    case SyncType.Partition: {
      const { readErr, writeErr, maxOfflineTimeInvalid, rawConfig } = toRawPartitionSyncConfig(
        syncConfig as PartitionSyncConfig
      );
      return {
        rawConfig: rawConfig as RawSyncConfig,
        syncType: SyncType.Partition,
        errs: [readErr, writeErr, maxOfflineTimeInvalid ? 'invalid max offline time' : ''].filter((e) => !!e),
      };
    }
    case SyncType.Flexible: {
      const { permissionsErr, rawConfig } = toRawQuerySyncConfig(syncConfig as QuerySyncConfig);
      return {
        rawConfig: rawConfig as RawSyncConfig,
        syncType: SyncType.Flexible,
        errs: [permissionsErr].filter((e) => !!e),
      };
    }
    default:
      return { rawConfig: undefined, errs: ['unrecognized sync config type'] };
  }
};
