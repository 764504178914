// Global keys

export const themePreferenceKey = () => 'baas_ui_theme_preference';

export const cloudNavKey = () => 'baas_ui_show_cloud_nav';

export const abAssignmentOverridesKey = () => 'baas_ui_ab_assignment_overrides';

export const snippetsKey = () => 'baas_ui_snippets';

// Keys by Group ID

export const timeZoneKey = (groupId: string) => `_baas.timezone-${groupId}`;

// TODO(BAAS-7260): investigate why last used app redirect crashes react
// export const lastUsedAppForGroupIdKey = (groupId: string) => `_baas.last-used-app-${groupId}`;

export const githubRequestIdKey = (groupId: string) => `_baas.github-request-id-${groupId}`;

export const isFirstAppCreatedOnGroup = (groupId: string) => `_baas.first-app-created-on-group-${groupId}`;

export const domainDeprecationBannerDismissed = (groupId: string) =>
  `_baas.domain-deprecation-banner-dismissed-${groupId} `;

// Keys by App ID

export const featureFlagOverridesKey = (appId: string) => `baas_ui_feature_overrides-${appId}`;

export const showGetStartedBanner = (appId: string) => `_baas.show-get-started-banner-${appId}`;

export const showGuidesOnStartKey = (appId: string) => `_baas.show-first-app-guide-modal-${appId}`;

export const showGuidesTooltipOnStartKey = (appId: string) => `_baas.show-guides-tooltip-on-start-${appId}`;

export const showAtlasDataAPIGuideCueKey = (appId: string) =>
  `_baas.show-atlas-data-api-guide-cue-on-first-view-${appId}`;

export const showAtlasTriggersGuideCueKey = (appId: string) =>
  `_baas.show-atlas-triggers-guide-cue-on-first-view-${appId}`;

export const showFrontendTemplateModalOnNewAppKey = (appId: string) =>
  `_baas.show-new-app-frontend-template-modal-${appId}`;

export const showRolesAndFiltersOnStartKey = (appId: string) => `_baas.show-roles-and-filters-modal-on-start-${appId}`;

export const activeGuideKey = (appId: string) => `_baas.active-guide-${appId}`;

export const templateStarterAppSummaryDismissedKey = (appId: string) =>
  `_baas.template-starter-app-summary-dismissed-${appId}`;

export const dataAPIWelcomeDismissedKey = (appId: string) => `_baas.data-api-welcome-dismissed-${appId}`;

export const firstAppSave = (appId: string) => `_baas.first-app-save-${appId}`;

export const clusterForNewAppIsProvisioningKey = (appId: string) =>
  `_baas.cluster-for-new-app-is-provisioning-${appId}`;

export const showClusterProvisioningToastKey = (appId: string) => `_baas.show-cluster-provisioning-toast-${appId}`;

export const thirdPartyServicesKey = (appId: string) => `_baas.third-party-services-visible-${appId}`;

export const selectedLanguageKey = (appId: string) => `_baas.selected-language-${appId}`;

export const selectedSDKKey = (appId: string) => `_baas.selected-sdk-${appId}`;

export const showDevModeAlertKey = (appId: string) => `_baas.show-dev-mode-alert-${appId}`;

export const lastDimissedSyncMigrationModalKey = (appId: string) =>
  `_baas.dismissed-sync-migration-completed-modal-${appId}`;

export const schemaDevModeClusterKey = (appId: string) => `_baas.schema-dev-mode-cluster-${appId}`;

export const syncMigrationCTADismissedKey = (appId: string) => `_baas.sync-migration-cta-dismissed-${appId}`;

export const showSyncGuideModalOnNewAppKey = (appId: string) => `_baas.show-new-app-sync-guide-modal-${appId}`;

export const showFrontEndTemplateModalKey = (appId: string) => `_baas.show-front-end-template-modal-${appId}`;

export const featureDeprecationBannerDismissed = (groupId: string) =>
  `_baas.feature-deprecation-banner-dismissed-${groupId} `;

export const graphQLDeprecationBadgeDismissed = (appId: string) => `_baas.graphql-deprecation-badge-dismissed-${appId}`;

export const hostingDeprecationBadgeDismissed = (appId: string) => `_baas.hosting-deprecation-badge-dismissed-${appId}`;

export const endpointsDeprecationBadgeDismissed = (appId: string) =>
  `_baas.endpoints-deprecation-badge-dismissed-${appId}`;
