import React from 'react';
import { connect } from 'react-redux';

import {
  blockNavigation as blockNavigationAction,
  unblockNavigation as unblockNavigationAction,
} from 'baas-ui/actions';
import { PreventNavigationModalVariant } from 'baas-ui/nav/prevent';

export interface Props {
  blockNavigation(blockFn: () => {}): void;
  unblockNavigation(): void;
  resourceName?: string;
  resourceType?: string;
  buttonText?: string;
  isPrevented?: boolean;
  variant?: PreventNavigationModalVariant;
}

export const PreventNavigationComponent = ({
  blockNavigation,
  unblockNavigation,
  resourceName = '',
  resourceType = '',
  buttonText = 'OK',
  isPrevented = false,
  variant,
}: Props) => {
  React.useEffect(() => {
    if (isPrevented) {
      unblockNavigation();
      blockNavigation(() => JSON.stringify({ resourceType, resourceName, buttonText, variant }));
    } else {
      unblockNavigation();
    }
  }, [resourceName, resourceType, buttonText, isPrevented]);

  React.useEffect(() => {
    return () => {
      unblockNavigation();
    };
  }, []);

  return null;
};

const mapDispatchToProps = (dispatch) => ({
  blockNavigation: (blockFn) => dispatch(blockNavigationAction(blockFn)),
  unblockNavigation: () => dispatch(unblockNavigationAction()),
});

export default connect(() => ({}), mapDispatchToProps)(PreventNavigationComponent);
