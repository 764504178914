import React from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import { Body } from '@leafygreen-ui/typography';

import theme from 'baas-ui/theme';
import { SyncMigrationAction } from 'admin-sdk';

export type Action = Exclude<SyncMigrationAction, SyncMigrationAction.Start>;

interface PublicProps {
  action: Action;
}

export type Props = PublicProps & ReactConfirmProps;

export enum TestSelector {
  ModalSelector = 'sync-migration-action-confirmation-modal',
  BodySelector = 'sync-migration-action-confirmation-body',
}

interface ActionMapping {
  title: string;
  buttonText: string;
  requiredInputText: string;
  bodyText: string;
}

const actionMappings: Record<Action, ActionMapping> = {
  [SyncMigrationAction.Cancel]: {
    title: 'Are you sure you want to stop the migration?',
    buttonText: 'Stop Flexible Sync Migration',
    requiredInputText: 'Stop Migration',
    bodyText:
      'If you choose to stop the Flexible Sync migration flow, the copy of your metadata will not be saved, and you will revert back to Partition-Based Sync.',
  },
  [SyncMigrationAction.Rollback]: {
    title: 'Are you sure you want to revert back to Partition-Based Sync?',
    buttonText: 'Revert to Partition-Based Sync',
    requiredInputText: 'Revert',
    bodyText:
      'If you choose to revert, you will not be able to undo this action and will have to re-do the migration process to go back to Flexible Sync.',
  },
  [SyncMigrationAction.Commit]: {
    title: 'Are you sure you want to commit to Flexible Sync?',
    buttonText: 'Commit',
    requiredInputText: 'Commit',
    bodyText: 'If you choose to commit to Flexible Sync, you will not be able to revert back to Partition-Based Sync.',
  },
};

export const SyncMigrationConfirmActionModal = ({ action, show, cancel, proceed }: Props) => {
  const { title, buttonText, requiredInputText, bodyText } = actionMappings[action];

  return (
    <ConfirmationModal
      title={title}
      open={show}
      confirmButtonProps={{
        children: buttonText,
        onClick: proceed,
      }}
      cancelButtonProps={{
        onClick: cancel,
      }}
      variant="danger"
      requiredInputText={requiredInputText}
      data-testid={TestSelector.ModalSelector}
      data-cy={TestSelector.ModalSelector}
      style={{ zIndex: theme.zIndex.modal }}
    >
      <Body data-testid={TestSelector.BodySelector}>{bodyText}</Body>
    </ConfirmationModal>
  );
};

export default createConfirmation(confirmable(SyncMigrationConfirmActionModal));
