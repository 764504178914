import React, { useEffect, useState } from 'react';
import Button from '@leafygreen-ui/button';
import Modal from '@leafygreen-ui/modal';
import Stepper, { Step } from '@leafygreen-ui/stepper';
import { H3, Link } from '@leafygreen-ui/typography';
import classNames from 'classnames';
import lottie from 'lottie-web/build/player/lottie_light';

import deployAnimation from 'baas-ui/deploy/understanding-save-and-deploy-modal/DeployAnimation.json';
import saveAnimation from 'baas-ui/deploy/understanding-save-and-deploy-modal/SaveAnimation.json';
import { useDarkMode } from 'baas-ui/theme';

import './understanding-save-and-deploy-modal.less';

const baseClassName = 'understanding-save-and-deploy-modal';

export interface Props {
  deploymentConfigUrl: string;
  open: boolean;
  onClose(): void;
}

export default function UnderstandingSaveAndDeployModal({ deploymentConfigUrl, open, onClose }: Props) {
  const [animationContainer, setAnimationContainer] = useState<HTMLDivElement | null>(null);
  const [currentStep, setCurrentStep] = useState(0);

  const darkMode = useDarkMode();

  const openHandler = (isOpen: boolean) => {
    if (!isOpen) {
      onClose();
    }
  };

  const handleForwardButtonClick = () => {
    if (currentStep === 1) {
      onClose();
    } else {
      setCurrentStep(1);
    }
  };

  useEffect(() => {
    if (animationContainer) {
      const animation = currentStep === 0 ? saveAnimation : deployAnimation;
      lottie.loadAnimation({
        container: animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
          className: 'darkreader-invert',
        },
      });
    }

    return lottie.destroy;
  }, [animationContainer, currentStep]);

  return (
    <Modal open={open} setOpen={openHandler} className={baseClassName} data-cy="understanding-save-and-deploy-modal">
      <H3 className={`${baseClassName}-header`}>Understanding Save and Deploy</H3>
      <div className={`${baseClassName}-header-text`}>
        This is the first time you have used <b>Save</b>. In App Services, Save is different from Deploy. Click through
        the deployment steps to understand each one.
      </div>
      <div
        className={classNames(`${baseClassName}-container`, {
          [`${baseClassName}-container-light-mode`]: !darkMode,
          [`${baseClassName}-container-dark-mode`]: darkMode,
        })}
      >
        <Stepper currentStep={currentStep} className={`${baseClassName}-stepper`}>
          <Step>Save Changes as Draft</Step>
          <Step>Review Draft & Deploy</Step>
        </Stepper>
        <div
          className={classNames(`${baseClassName}-animation`, 'darkreader-ignore-child-inline-style')}
          ref={setAnimationContainer}
        />
        <div className={`${baseClassName}-container-text`}>
          {currentStep === 0 ? (
            'Saving creates a Deployment Draft...'
          ) : (
            <>
              Once a Draft is saved, you can <b>deploy</b> it or <b>discard</b> it.
            </>
          )}
        </div>
      </div>
      <div className={`${baseClassName}-text`}>
        You can also{' '}
        <Link
          className={`${baseClassName}-link`}
          hideExternalIcon
          href={deploymentConfigUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>disable drafts</b>
        </Link>{' '}
        if you feel like it, in which case you&apos;d deploy directly.
      </div>
      <div
        className={classNames(`${baseClassName}-footer`, {
          [`${baseClassName}-footer-flex-end`]: currentStep === 0,
          [`${baseClassName}-footer-space-between`]: currentStep === 1,
        })}
      >
        {currentStep === 1 && (
          <Button className={`${baseClassName}-button`} onClick={() => setCurrentStep(0)}>
            Back
          </Button>
        )}
        <Button className={`${baseClassName}-button`} onClick={handleForwardButtonClick}>
          {currentStep === 0 && 'Next'}
          {currentStep === 1 && 'Got it!'}
        </Button>
      </div>
    </Modal>
  );
}
