import React from 'react';
import Tooltip from '@leafygreen-ui/tooltip';
import { Disclaimer } from '@leafygreen-ui/typography';
import classnames from 'classnames';

import LeafygreenRouterLink from 'baas-ui/common/components/leafygreen-router-link';
import { IndexIcon } from 'baas-ui/common/svgs';

import './guide-step-header.less';

export const baseClassName = 'guide-step-header';

export enum TestSelector {
  Index = 'index',
  Subtext = 'subtext',
  Text = 'text',
  ReturnToPageTooltip = 'return-to-page-tooltip',
}

export interface Props {
  index?: number;
  url?: string;
  text: string;
  subtext?: string;
  isTitle?: boolean;
}

const GuideStepHeader = ({ index, url, text, subtext, isTitle }: Props) => (
  <div className={classnames(`${baseClassName}`, { [`${baseClassName}-title`]: isTitle })}>
    {index && (
      <span data-test-selector={TestSelector.Index} className={`${baseClassName}-icon`}>
        <IndexIcon index={index} size={20} />
      </span>
    )}
    <div
      data-test-selector={TestSelector.Text}
      className={classnames(`${baseClassName}-text`, { [`${baseClassName}-text-title`]: isTitle })}
    >
      {url ? (
        <Tooltip
          data-test-selector={TestSelector.ReturnToPageTooltip}
          trigger={({ children, ...rest }) => (
            <span {...rest}>
              <LeafygreenRouterLink className={`${baseClassName}-text-link`} to={url}>
                {text}
                {children}
              </LeafygreenRouterLink>
            </span>
          )}
          justify="middle"
          portalClassName={`${baseClassName}-tooltip`}
        >
          Return to page
        </Tooltip>
      ) : (
        text
      )}
      {subtext && (
        <Disclaimer data-test-selector={TestSelector.Subtext} className={`${baseClassName}-text-subtext`}>
          {subtext}
        </Disclaimer>
      )}
    </div>
  </div>
);

export default GuideStepHeader;
