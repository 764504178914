import React from 'react';
import { throughArea } from 'react-through';
import PropTypes from 'prop-types';

import { statusContainerAreaName } from './constants';

interface WrappedContainerProps {
  [id: string]: {
    statusId: string;
    statusType?: string;
    children: React.ReactChild;
  };
}

export interface Props {
  filter?(item: { statusType?: string }): boolean;
}

const statusContainerThroughArea = throughArea(statusContainerAreaName);

const StatusContainerComponent =
  ({ filter }: Props) =>
  (props: WrappedContainerProps) => {
    let values = Object.values(props);
    if (filter) {
      values = values.filter(filter);
    }

    return (
      <>
        {values.map((item) => (
          <React.Fragment key={item.statusId}>{item.children}</React.Fragment>
        ))}
      </>
    );
  };

export const buildStatusContainer = (statusContainerProps: Props) => {
  const StatusContainerArea = statusContainerThroughArea(StatusContainerComponent(statusContainerProps));

  const StatusContainer = (props, { through }) => {
    if (!through) {
      return null;
    }

    return <StatusContainerArea {...props} />;
  };

  StatusContainer.contextTypes = {
    through: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  };

  return StatusContainer;
};

export default buildStatusContainer({});
