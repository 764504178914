import { AutocompleteOption } from './types';

const EDITOR_FUNCTION_SOURCE_DEFAULT = `exports = async function(arg){
  // This default function will get a value and find a document in MongoDB
  // To see plenty more examples of what you can do with functions see: 
  // https://www.mongodb.com/docs/atlas/app-services/functions/

  // Find the name of the MongoDB service you want to use (see "Linked Data Sources" tab)
  var serviceName = "mongodb-atlas";

  // Update these to reflect your db/collection
  var dbName = "db_name";
  var collName = "coll_name";

  // Get a collection from the context
  var collection = context.services.get(serviceName).db(dbName).collection(collName);

  var findResult;
  try {
    // Get a value from the context (see "Values" tab)
    // Update this to reflect your value's name.
    var valueName = "value_name";
    var value = context.values.get(valueName);

    // Execute a FindOne in MongoDB 
    findResult = await collection.findOne(
      { owner_id: context.user.id, "fieldName": value, "argField": arg},
    );

  } catch(err) {
    console.log("Error occurred while executing findOne:", err.message);

    return { error: err.message };
  }

  // To call other named functions:
  // var result = context.functions.execute("function_name", arg1, arg2);

  return { result: findResult };
};`;

const CONSOLE_SOURCE_DEFAULT = `/*
  To Run the function:
    - Select a user
    - Type 'exports()' to run the function with no arguments
    - Type 'exports(arg1, arg2, args...)' to run the function with arguments
    - To run a saved function type 'context.functions.execute(<function-name-string>, args...)'
    - Click 'Run function as'
*/`;

const EDITOR_FUNCTION_DEBUG_CONSOLE_DEFAULT = `${CONSOLE_SOURCE_DEFAULT}\n
exports('Hello world!')
`;

// TypeScript version for virtual typescript environment/creating filesystem map
const TYPESCRIPT_VERSION = '4.9.5';

// Specific Global MongoDB constants + TypeScript types needed for linting
const FUNCTION_EDITOR_TS_ENV_GLOBALS_PATH = '/globals.d.ts';

const FUNCTION_EDITOR_TS_ENV_GLOBALS_CONTENT = `
  declare var exports: Function;
  declare var require: Function;
  declare var context: any;
  declare var EJSON: EJSON;
  declare var BSON: BSON;
  declare var utils: any;
  declare var console;
  interface Array<T> {};
  interface Boolean {};
  interface Function {};
  interface IArguments {};
  interface NewableFunction extends Function {};
  interface CallableFunction extends Function {};
  interface Number {};
  interface Object {};
  interface RegExp {};
  interface String {};
  interface Promise<T> {};
  declare var Promise;
`;

// Required file for es2021 lib
const FUNCTION_EDITOR_TS_BACKUP_ES6_LIB_PATH = '/lib.es2021.d.ts';

const FUNCTION_EDITOR_TS_BACKUP_ES6_LIB_CONTENT = `
  interface dummyContent {};
`;

// Required file for dom lib
const FUNCTION_EDITOR_TS_BACKUP_DOM_LIB_PATH = '/lib.dom.d.ts';

const FUNCTION_EDITOR_TS_BACKUP_DOM_LIB_CONTENT = `
  interface dummyContent {};
`;

const FUNCTION_RESOURCE = {
  type: 'function',
  runAsAuthedUserDescription: 'This is where you can choose the authentication method for your function.',
  runAsAuthedUserRadioDescription: 'Execute with the permissions of the user calling the function.',
};

const enum RUN_AS {
  AUTHED_USER = 'runAsAuthedUser',
  USER_ID = 'runAsUserId',
  USER_SCRIPT = 'runAsUserScript',
  SYSTEM = 'runAsSystem',
}

export const FUNCTION_SNIPPETS_GITHUB_URL =
  'https://github.com/mongodb/atlas-functions-triggers-examples/tree/main/snippets/functions';

const DEFAULT_AUTOCOMPLETE_OPTIONS: AutocompleteOption[] = [{ label: 'context' }];

const CONTEXT_FUNCTIONS_EXECUTE = 'context.functions.execute';
const CONTEXT_SERVICES_GET = 'context.services.get';
const CONTEXT_VALUES_GET = 'context.values.get';
// Represents a flattened version of a tree for quick lookup of autocomplete options
const AUTOCOMPLETE_OPTIONS_TREE: { [key: string]: AutocompleteOption[] } = {
  context: [
    { label: 'context.app', displayLabel: 'app' },
    { label: 'context.environment', displayLabel: 'environment' },
    { label: 'context.functions', displayLabel: 'functions' },
    { label: 'context.request', displayLabel: 'request' },
    { label: 'context.runningAsSystem()', displayLabel: 'runningAsSystem()' },
    { label: 'context.services', displayLabel: 'services' },
    { label: 'context.user', displayLabel: 'user' },
    { label: 'context.values', displayLabel: 'values' },
  ],
  'context.app': [
    { label: 'context.app.id', displayLabel: 'id' },
    { label: 'context.app.clientAppId', displayLabel: 'clientAppId' },
    { label: 'context.app.name', displayLabel: 'name' },
    { label: 'context.app.projectId', displayLabel: 'projectId' },
    { label: 'context.app.deployment', displayLabel: 'deployment' },
    { label: 'context.app.lastDeployed', displayLabel: 'lastDeployed' },
    { label: 'context.app.hostingUri', displayLabel: 'hostingUri' },
  ],
  'context.environment': [
    { label: 'context.environment.tag', displayLabel: 'tag' },
    { label: 'context.environment.values', displayLabel: 'values' },
  ],
  'context.functions': [{ label: CONTEXT_FUNCTIONS_EXECUTE, displayLabel: 'execute()' }],
  'context.services': [{ label: CONTEXT_SERVICES_GET, displayLabel: 'get()' }],
  'context.values': [{ label: CONTEXT_VALUES_GET, displayLabel: 'get()' }],
};

// these labels will be populated in real time as the user types since database/collection names are not known
// trailing period is used because autocomplete text will be appended onto input rather than replaced with the label
const DYNAMIC_AUTOCOMPLETE_OPTIONS: { [key: string]: AutocompleteOption[] } = {
  'context.services.get()': [{ label: '<PLACEHOLDER>', displayLabel: 'db()' }],
  'context.services.get().db()': [
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'collection',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'aggregate',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'getCollectionNames',
    },
  ],
  'context.services.get().db().collection()': [
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'aggregate',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'bulkWrite',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'count',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'deleteMany',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'deleteOne',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'distinct',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'find',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'findOne',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'findOneAndDelete',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'findOneAndReplace',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'findOneAndUpdate',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'initializeOrderedBulkOp',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'initializeUnorderedBulkOp',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'insertOne',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'insertMany',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'replaceOne',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'updateOne',
    },
    {
      label: '<PLACEHOLDER>',
      displayLabel: 'updateMany',
    },
  ],
};

export {
  EDITOR_FUNCTION_SOURCE_DEFAULT,
  EDITOR_FUNCTION_DEBUG_CONSOLE_DEFAULT,
  CONSOLE_SOURCE_DEFAULT,
  TYPESCRIPT_VERSION,
  FUNCTION_EDITOR_TS_ENV_GLOBALS_PATH,
  FUNCTION_EDITOR_TS_ENV_GLOBALS_CONTENT,
  FUNCTION_EDITOR_TS_BACKUP_ES6_LIB_PATH,
  FUNCTION_EDITOR_TS_BACKUP_ES6_LIB_CONTENT,
  FUNCTION_EDITOR_TS_BACKUP_DOM_LIB_PATH,
  FUNCTION_EDITOR_TS_BACKUP_DOM_LIB_CONTENT,
  FUNCTION_RESOURCE,
  RUN_AS,
  DEFAULT_AUTOCOMPLETE_OPTIONS,
  AUTOCOMPLETE_OPTIONS_TREE,
  CONTEXT_FUNCTIONS_EXECUTE,
  CONTEXT_SERVICES_GET,
  CONTEXT_VALUES_GET,
  DYNAMIC_AUTOCOMPLETE_OPTIONS,
};
