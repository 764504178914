import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from '@emotion/styled';
import Banner from '@leafygreen-ui/banner';
import Box from '@leafygreen-ui/box';
import Button, { Variant } from '@leafygreen-ui/button';
import Card from '@leafygreen-ui/card';
import { useThemeValue } from '@mongodb-js/darkreader';

import { redirectTo as redirectToAction } from 'baas-ui/actions';
import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import * as actions from 'baas-ui/home/actions';
import withCreateApp, { WithCreateAppProps } from 'baas-ui/home/common/withCreateApp';
import { CreateAppErrors } from 'baas-ui/home/constants';
import CreateAppHeader from 'baas-ui/home/create-app/create-app-header';
import CreateAppFooter from 'baas-ui/home/create-app/footer';
import ConfigFormSection from 'baas-ui/home/create-app/form/config-form-section';
import CreateDefaultAppForm from 'baas-ui/home/create-app/form/create-default-app-form';
import { TemplateIdentifier } from 'baas-ui/home/create-app/types';
import { AppProduct, RequestError } from 'baas-ui/home/types';
import { useAppsPageNav } from 'baas-ui/nav/useAppsPageNav';
import { AsyncDispatch, AsyncDispatchPayload } from 'baas-ui/redux_util';
import { useDarkMode } from 'baas-ui/theme';
import urls from 'baas-ui/urls';
import { PartialApp } from 'admin-sdk';

import 'baas-ui/common/styles/banner.less';
import 'baas-ui/home/create-app/create-app.less';

const baseClassName = 'create-app';
const formClassName = `${baseClassName}-form`;

interface BackgroundColorProps {
  backgroundColor?: string;
}

const StyledCreateApp = styled('div')<BackgroundColorProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export enum TestSelector {
  DataSourceErrorBanner = 'data-source-error-banner',
  CreateAppErrorBanner = 'create-app-error-banner',
  LoadAppsErrorBanner = 'load-app-error-banner',
  CancelButton = 'cancel-button',
  CreateAppButton = 'create-app-button',
}

interface DispatchProps {
  loadApps(groupId: string, products: AppProduct[]): AsyncDispatchPayload<PartialApp[]>;
  redirectTo(url: string): void;
  setGroupId(groupId: string): void;
}

type RouteProps = RouteComponentProps<{
  groupId: string;
}>;

export type Props = WithCreateAppProps & DispatchProps & RouteProps;

export const CreateAppComponent = ({ createAppProps, loadApps, match, setGroupId, redirectTo }: Props) => {
  const themeValue = useThemeValue(useDarkMode());
  const backgroundColor = themeValue({ dark: 'grayDark4', light: 'grayLight3' });

  useAppsPageNav();

  const {
    appNameError,
    createAppError,
    dataSourceError,
    groupId,
    handleCreateApp,
    isCreatingApp,
    isLoadingApps,
    setIsLoadingApps,
    templateId,
    setTemplateId,
    setIsUserSelectedProviderRegion,
  } = createAppProps;
  const [isProviderRegionConfirmed, setIsProviderRegionConfirmed] = React.useState(true);
  const [loadAppsError, setLoadAppsError] = React.useState(RequestError.NoError);
  React.useEffect(() => {
    const loadDataAsync = async () => {
      if (match.params.groupId) {
        const matchedGroupId = match.params.groupId;
        setIsLoadingApps(true);
        setLoadAppsError(RequestError.NoError);
        try {
          if (matchedGroupId !== groupId) {
            setGroupId(matchedGroupId);
          }
          await loadApps(matchedGroupId, [AppProduct.Standard, AppProduct.Atlas]);
        } catch (e) {
          setLoadAppsError(e);
        } finally {
          setIsLoadingApps(false);
        }
      }
    };
    loadDataAsync();
  }, [match.params.groupId]);

  return (
    <StyledCreateApp className={baseClassName} backgroundColor={backgroundColor}>
      <CreateAppHeader />
      <div className={`${baseClassName}-content`}>
        {createAppError && (
          <Banner
            className="banner-margin-bottom"
            data-test-selector={TestSelector.CreateAppErrorBanner}
            data-testid={TestSelector.CreateAppErrorBanner}
            variant={Variant.Danger}
          >
            {createAppError === CreateAppErrors.Forbidden ? (
              <>
                {`${createAppError} Creating an App Service requires the `}
                <DocLink href={docLinks.Atlas.UserRoles}>Project Owner</DocLink>
                {` role.`}
              </>
            ) : (
              createAppError
            )}
          </Banner>
        )}
        {dataSourceError && (
          <Banner
            className="banner-margin-bottom"
            data-test-selector={TestSelector.DataSourceErrorBanner}
            data-testid={TestSelector.DataSourceErrorBanner}
            variant={Variant.Danger}
          >
            {dataSourceError}
          </Banner>
        )}
        {loadAppsError && (
          <Banner
            className="banner-margin-bottom"
            data-test-selector={TestSelector.LoadAppsErrorBanner}
            data-testid={TestSelector.LoadAppsErrorBanner}
            variant={Variant.Danger}
          >
            {loadAppsError}
          </Banner>
        )}
        <Card className={formClassName}>
          {templateId ? (
            <ConfigFormSection
              {...createAppProps}
              onChangeDeploymentModel={() => setIsProviderRegionConfirmed(true)}
              onCancelProviderRegion={() => setIsProviderRegionConfirmed(true)}
              onConfirmProviderRegion={() => {
                setIsProviderRegionConfirmed(true);
                setIsUserSelectedProviderRegion(true);
              }}
              onChangeProviderRegion={() => setIsProviderRegionConfirmed(false)}
            />
          ) : (
            <CreateDefaultAppForm
              {...createAppProps}
              onChangeDeploymentModel={() => setIsProviderRegionConfirmed(true)}
              onCancelProviderRegion={() => setIsProviderRegionConfirmed(true)}
              onConfirmProviderRegion={() => {
                setIsProviderRegionConfirmed(true);
                setIsUserSelectedProviderRegion(true);
              }}
              onChangeProviderRegion={() => setIsProviderRegionConfirmed(false)}
            />
          )}
        </Card>
        <CreateAppFooter
          showRightButtonSpinner={isCreatingApp}
          rightButton={
            <Button
              data-test-selector={TestSelector.CreateAppButton}
              data-testid={TestSelector.CreateAppButton}
              data-cy="create-app-submit-button"
              disabled={!isProviderRegionConfirmed || !!appNameError || isCreatingApp || isLoadingApps}
              variant={Variant.Primary}
              onClick={handleCreateApp}
            >
              Create App Service
            </Button>
          }
          leftButton={
            <Box
              data-test-selector={TestSelector.CancelButton}
              data-testid={TestSelector.CancelButton}
              role="button"
              className={`${baseClassName}-footer-cancel-button`}
              onClick={() => {
                setTemplateId(TemplateIdentifier.Default);
                redirectTo(urls.groups().group(groupId).apps().list(true));
              }}
            >
              Cancel
            </Box>
          }
        />
      </div>
    </StyledCreateApp>
  );
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  loadApps: (groupId: string, products: AppProduct[]) => dispatch(actions.loadApps({ groupId, products })),
  redirectTo: (url: string) => dispatch(redirectToAction(url)),
  setGroupId: (groupId: string) => dispatch(actions.setGroupId(groupId)),
});

const { wrappedComponent: WrappedCreateApp } = withCreateApp(CreateAppComponent);
export default connect(undefined, mapDispatchToProps)(WrappedCreateApp);
