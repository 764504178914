import React from 'react';

const waves = [
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 108">
    <defs>
      <style>{'.cls-0{fill:url(#linear-gradient-0);}'}</style>
      <linearGradient id="linear-gradient-0" x1="150" y1="108" x2="150" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#9795f9" />
        <stop offset="0" stopColor="#9694f8" />
        <stop offset="0.34" stopColor="#6d6cc4" />
        <stop offset="0.62" stopColor="#4f4f9f" />
        <stop offset="0.86" stopColor="#3d3d87" />
        <stop offset="1" stopColor="#36367f" />
      </linearGradient>
    </defs>
    <title>Asset 9</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-0"
          d="M300,81.65c-18.77-7.15-35.71-11.43-54.69-12.71-42.4-2.86-75.49,9-107.5,20.52-34.1,12.25-66.31,23.82-107.57,16-11-2.09-19.66-4.22-30.24-8.71V.07s115.64.15,150,0c36.64-.16,150,0,150,0Z"
        />
      </g>
    </g>
  </svg>,
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 108">
    <defs>
      <style>{'.cls-1{fill:url(#linear-gradient-1);}'}</style>
      <linearGradient
        id="linear-gradient-1"
        x1="918.58"
        y1="2133.66"
        x2="918.58"
        y2="2256.05"
        gradientTransform="matrix(1, 0, 0, -1, -768.58, 2236.78)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ef8c9b" />
        <stop offset="0.21" stopColor="#c76e8a" />
        <stop offset="0.43" stopColor="#a8557c" />
        <stop offset="0.63" stopColor="#914472" />
        <stop offset="0.83" stopColor="#843a6c" />
        <stop offset="1" stopColor="#7f366a" />
      </linearGradient>
    </defs>
    <title>Asset 10</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M300,81.65c-18.77-7.15-35.71-11.43-54.69-12.71-42.4-2.86-75.49,9-107.5,20.52-34.1,12.25-66.31,23.82-107.57,16-11-2.09-19.66-4.22-30.24-8.71V.07s115.64.15,150,0c36.64-.16,150,0,150,0Z"
        />
      </g>
    </g>
  </svg>,
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 108">
    <defs>
      <style>{'.cls-2{fill:url(#linear-gradient-2);}'}</style>
      <linearGradient
        id="linear-gradient-2"
        x1="3458.2"
        y1="2233.72"
        x2="3459.76"
        y2="2110.6"
        gradientTransform="matrix(1, 0, 0, -1, -3308.77, 2236.81)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2d614e" />
        <stop offset="0.16" stopColor="#2b684f" />
        <stop offset="0.38" stopColor="#247c51" />
        <stop offset="0.66" stopColor="#1a9d55" />
        <stop offset="0.97" stopColor="#0cca5a" />
        <stop offset="1" stopColor="#0ad05b" />
      </linearGradient>
    </defs>
    <title>Asset 11</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-2"
          d="M300,81.65c-18.77-7.15-35.71-11.43-54.69-12.71-42.4-2.86-75.49,9-107.5,20.52-34.1,12.25-66.31,23.82-107.57,16-11-2.09-19.66-4.22-30.24-8.71V.07s115.64.15,150,0c36.64-.16,150,0,150,0Z"
        />
      </g>
    </g>
  </svg>,
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 108">
    <defs>
      <style>{'.cls-3{fill:url(#linear-gradient-3);}'}</style>
      <linearGradient id="linear-gradient-3" x1="150" y1="108" x2="150" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#ef8c9b" />
        <stop offset="0.03" stopColor="#e58799" />
        <stop offset="0.21" stopColor="#b06f91" />
        <stop offset="0.39" stopColor="#845a8b" />
        <stop offset="0.57" stopColor="#624b86" />
        <stop offset="0.73" stopColor="#4a3f82" />
        <stop offset="0.88" stopColor="#3b3880" />
        <stop offset="1" stopColor="#36367f" />
      </linearGradient>
    </defs>
    <title>Asset 12</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-3"
          d="M300,81.65c-18.77-7.15-35.71-11.43-54.69-12.71-42.4-2.86-75.49,9-107.5,20.52-34.1,12.25-66.31,23.82-107.57,16-11-2.09-19.66-4.22-30.24-8.71V.07s115.64.15,150,0c36.64-.16,150,0,150,0Z"
        />
      </g>
    </g>
  </svg>,
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 108">
    <defs>
      <style>{'.cls-4{fill:url(#linear-gradient-4);}'}</style>
      <linearGradient
        id="linear-gradient-4"
        x1="3458.2"
        y1="2233.72"
        x2="3459.76"
        y2="2110.6"
        gradientTransform="matrix(1, 0, 0, -1, -3308.77, 2236.81)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#b2458d" />
        <stop offset="0.24" stopColor="#9b4eb0" />
        <stop offset="0.5" stopColor="#8557d3" />
        <stop offset="0.55" stopColor="#8258d8" />
        <stop offset="0.83" stopColor="#735eef" />
        <stop offset="1" stopColor="#6e60f7" />
      </linearGradient>
    </defs>
    <title>Asset 13</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-4"
          d="M300,81.65c-18.77-7.15-35.71-11.43-54.69-12.71-42.4-2.86-75.49,9-107.5,20.52-34.1,12.25-66.31,23.82-107.57,16-11-2.09-19.66-4.22-30.24-8.71V.07s115.64.15,150,0c36.64-.16,150,0,150,0Z"
        />
      </g>
    </g>
  </svg>,
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 108">
    <defs>
      <style>{'.cls-5{fill:url(#linear-gradient-5);}'}</style>
      <linearGradient id="linear-gradient-5" x1="150" x2="150" y2="108" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#2c604b" />
        <stop offset="0.11" stopColor="#2f6052" />
        <stop offset="0.28" stopColor="#376067" />
        <stop offset="0.49" stopColor="#436088" />
        <stop offset="0.72" stopColor="#5560b7" />
        <stop offset="0.97" stopColor="#6b60f2" />
        <stop offset="1" stopColor="#6e60f9" />
      </linearGradient>
    </defs>
    <title>Asset 14</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-5"
          d="M300,81.65c-18.77-7.15-35.71-11.43-54.69-12.71-42.4-2.86-75.49,9-107.5,20.52-34.1,12.25-66.31,23.82-107.57,16-11-2.09-19.66-4.22-30.24-8.71V.07s115.64.15,150,0c36.64-.16,150,0,150,0Z"
        />
      </g>
    </g>
  </svg>,
];

export interface Props {
  value: number;
}

const Wave = ({ value }: Props) => {
  return waves[value % waves.length];
};

export default Wave;
