import React from 'react';

import { FormRowLabelGroup } from 'baas-ui/common/components/form-row';
import { IndexIcon } from 'baas-ui/common/svgs';

import './index-form-row-label-group.less';

export interface Props {
  index: number;
  children: React.ReactNode;
  wide?: boolean;
  alignTop?: boolean;
}

const baseClassName = 'index-form-row-label-group';

const IndexFormRowLabelGroup = ({ children, index, wide, alignTop = true }: Props) => (
  <>
    <div className={`${baseClassName}-icon`}>
      <IndexIcon index={index} />
    </div>
    <FormRowLabelGroup alignTop={alignTop} wide={wide}>
      {children}
    </FormRowLabelGroup>
  </>
);

export default IndexFormRowLabelGroup;
