import React, { useEffect, useReducer, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Badge from '@leafygreen-ui/badge';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Box from '@leafygreen-ui/box';
import Button from '@leafygreen-ui/button';
import { useEventListener } from '@leafygreen-ui/hooks';
import Icon from '@leafygreen-ui/icon';
import { palette } from '@leafygreen-ui/palette';
import Tooltip from '@leafygreen-ui/tooltip';
import { H2 } from '@leafygreen-ui/typography';
import classNames from 'classnames';
import equal from 'fast-deep-equal';

import { redirectTo as redirectToAction } from 'baas-ui/actions';
import {
  createCollectionExplorerContext,
  createCollectionExplorerContextProvider,
} from 'baas-ui/common/components/collection-explorer';
import CollectionExplorer, {
  CollectionData,
  ResourceName,
} from 'baas-ui/common/components/collection-explorer/collection-explorer/CollectionExplorer';
import { LEFT_PANEL_MAX_SIZE, LEFT_PANEL_MIN_SIZE } from 'baas-ui/common/components/collection-explorer/constants';
import { SaveButton, SaveStatus } from 'baas-ui/common/components/form-row';
import { LoadingWrapper } from 'baas-ui/common/components/loading-wrapper';
import { Title } from 'baas-ui/common/components/title';
import { DEFAULT_SAVE_ERROR_MESSAGE } from 'baas-ui/common/constants';
import { ErrorCode } from 'baas-ui/constants';
import { BreadcrumbsItem, CloudBreadcrumbs } from 'baas-ui/nav';
import { AsyncDispatch, AsyncDispatchPayload } from 'baas-ui/redux_util';
import { getAppState } from 'baas-ui/selectors';
import LinkDataSourceEmptyState from 'baas-ui/services/mongodb/link-data-source-empty-state';
import { Namespaces } from 'baas-ui/services/mongodb/types';
import { isSelfHostedOrAtlasMongoService } from 'baas-ui/services/registry';
import { confirm as syncIncompatibilityConfirm } from 'baas-ui/sync/submit-confirmable-request/sync-incompatibility-modal/SyncIncompatibilityModal';
import { useDarkMode } from 'baas-ui/theme';
import { track } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import rootUrl, { AppUrlType } from 'baas-ui/urls';
import {
  BuiltinRule,
  BypassServiceChangeValue,
  isRulePartialMongoDBNamespace,
  MongoDBBaseRule,
  MongoDBNamespaceRule,
  MongoDBSyncIncompatibleRoles,
  PartialBuiltinRule,
  PartialMongoDBNamespaceRule,
  PartialServiceDesc,
  PresetRole,
} from 'admin-sdk';

import { useNewCollectionModal } from './new-collection-modal/NewCollectionModal';
import * as actions from './actions';
import CollectionActionMenu from './collection-action-menu';
import DataSourceActionMenu from './data-source-action-menu';
import DatabaseActionMenu from './database-action-menu';
import { makeDataSourceData, RulesPageCollectionData } from './dataSourceData';
import DefaultRolesFiltersButton from './default-roles-filters-button';
import {
  BulkDeleteDatabase,
  BulkDeleteDataSource,
  DeleteDefaultRule,
  DeleteRule,
  useDeleteRulesModal,
} from './delete-rules-modal';
import ExplorerHeader from './explorer-header';
import RuleViewerComponent from './rule-viewer';
import { createRulesPageContext, createRulesPageContextProvider } from './RulesPageContext';
import {
  deleteFilterOnActiveRuleByIdx,
  deleteRoleOnActiveRuleByIdx,
  pushFilterOnActiveRule,
  pushRoleOnActiveRule,
  pushRolesOnActiveRule,
  replaceFilterOnActiveRuleByIdx,
  replaceRoleOnActiveRuleByIdx,
  rulesPageReducer,
  setActiveRule,
  swapRolesOnActiveRule,
} from './rulesPageReducer';
import {
  DataSourceRule,
  DefaultRulesByDataSourceId,
  NamespacesByClusterId,
  PartialRulesByDataSourceId,
  SyncIncompatibleRolesByDataSourceId,
} from './types';
import { deepCopyRule, formatExplorerNamespace, getCountsFromNamespaces, isNamespaceRule } from './utils';

import './rules-page.less';

const baseClassName = 'rules-page';

export const RulesPageCollectionExplorerContext = createCollectionExplorerContext<RulesPageCollectionData>();
export const CollectionExplorerContextProvider = createCollectionExplorerContextProvider(
  RulesPageCollectionExplorerContext
);

export const RulesPageContext = createRulesPageContext();
export const RulesPageContextProvider = createRulesPageContextProvider(RulesPageContext);

export enum TestSelector {
  TitleText = 'title-text',
  EditorContainer = 'editor-container',
  LeftSection = 'left',
  RightSection = 'right',
  ErrorBanner = 'error-banner',
  IsLoadingWrapper = 'is-loading-wrapper',
  ColExplorerHeader = 'explorer-header',
  RulesPageCtxProvider = 'rules-page-context-provider',
  ColExplorerCtxProvider = 'col-explorer-context-provider',
  RuleSaveDraftButton = 'rule-save-draft-button',
  RuleSaveDraftStatus = 'rule-save-draft-status',
  ColExplorer = 'collection-explorer',
  CancelButton = 'cancel-button',
  SyncedBadge = 'synced-badge',
}

interface ReduxStateProps {
  appId: string;
  appUrl: AppUrlType;
  groupId: string;
}

interface ReduxDispatchProps {
  createRule: (
    dataSourceId: string,
    rule: BuiltinRule | MongoDBNamespaceRule,
    bypassServiceChange?: BypassServiceChangeValue
  ) => AsyncDispatchPayload<PartialMongoDBNamespaceRule | PartialBuiltinRule>;
  createDefaultRule: (
    dataSourceId: string,
    rule: MongoDBBaseRule,
    bypassServiceChange?: BypassServiceChangeValue
  ) => AsyncDispatchPayload<MongoDBBaseRule>;
  deleteDefaultRule: (dataSourceId: string) => AsyncDispatchPayload<void>;
  deleteRule: (dataSourceId: string, ruleId: string) => AsyncDispatchPayload<void>;
  deleteRulesByDatabase: (dataSourceId: string, databaseName: string) => AsyncDispatchPayload<void>;
  deleteRulesByDataSource: (dataSourceId: string) => AsyncDispatchPayload<void>;
  listNamespaces(dataSourceId: string): AsyncDispatchPayload<Namespaces>;
  loadDataSources: () => AsyncDispatchPayload<PartialServiceDesc[]>;
  loadDefaultRule(dataSourceId: string): AsyncDispatchPayload<MongoDBBaseRule>;
  loadPresetRoles(): AsyncDispatchPayload<PresetRole[]>;
  loadRules: (dataSourceId: string) => AsyncDispatchPayload<(PartialMongoDBNamespaceRule | PartialBuiltinRule)[]>;
  loadSyncIncompatibleRoles: (dataSourceId: string) => AsyncDispatchPayload<MongoDBSyncIncompatibleRoles>;
  updateRule: (
    dataSourceId: string,
    ruleId: string,
    rule: BuiltinRule | MongoDBNamespaceRule,
    bypassServiceChange?: BypassServiceChangeValue
  ) => AsyncDispatchPayload<void>;
  updateDefaultRule: (
    dataSourceId: string,
    rule: MongoDBBaseRule,
    bypassServiceChange?: BypassServiceChangeValue
  ) => AsyncDispatchPayload<void>;
  redirectTo(url: string): void;
}

export interface RulesMatchParams {
  dataSourceName?: string;
  ruleId?: string;
}

export type Props = ReduxStateProps & ReduxDispatchProps & RouteComponentProps<RulesMatchParams>;

export const RulesPageComponent = ({
  appId,
  appUrl,
  createDefaultRule,
  createRule,
  deleteDefaultRule,
  deleteRule,
  deleteRulesByDatabase,
  deleteRulesByDataSource,
  groupId,
  listNamespaces,
  loadDataSources,
  loadDefaultRule,
  loadPresetRoles,
  loadRules,
  loadSyncIncompatibleRoles,
  match,
  redirectTo,
  updateDefaultRule,
  updateRule,
}: Props) => {
  // Resources
  const [dataSources, setDataSources] = useState<PartialServiceDesc[]>([]);
  const [defaultRulesByDataSourceId, setDefaultRulesByDataSourceId] = useState<DefaultRulesByDataSourceId>({});
  const [namespacesByClusterId, setNamespacesByClusterId] = useState<NamespacesByClusterId>({});
  const [partialRulesByDataSourceId, setPartialRulesByDataSourceId] = useState<PartialRulesByDataSourceId>({});
  const [syncIncompatibleRolesByDataSourceId, setSyncIncompatibleRolesByDataSourceId] =
    useState<SyncIncompatibleRolesByDataSourceId>({});
  const [presetRoles, setPresetRoles] = useState<PresetRole[]>([]);
  const [pristineRule, setPristineRule] = useState<MongoDBBaseRule | MongoDBNamespaceRule>();
  const [rulesState, rulesDispatch] = useReducer(rulesPageReducer, { activeRule: undefined });
  const [ruleSaveError, setRuleSaveError] = useState<string>();
  const [selectedPresetRole, setSelectedPresetRole] = useState<PresetRole>();
  const [hasClientValidationError, setHasClientValidationError] = useState<boolean>(false);
  const [onSaveSuccess, setOnSaveSuccess] = useState<Function>(() => () => {});
  const [onDiscardChanges, setOnDiscardChanges] = useState<Function>(() => () => {});

  // Loading states
  const [isLoadingDataSources, setIsLoadingDataSources] = useState(true);
  const [isLoadingDefaultRules, setIsLoadingDefaultRules] = useState(false);
  const [isLoadingNamespaces, setIsLoadingNamespaces] = useState(false);
  const [isLoadingPresetRoles, setIsLoadingPresetRoles] = useState(false);
  const [isLoadingRules, setIsLoadingRules] = useState(false);
  const [isLoadingSyncIncompatibleRoles, setIsLoadingSyncIncompatibleRoles] = useState(false);
  // API action errors
  const [errors, setErrors] = useState<Set<string>>(new Set());

  // Component state
  const [newCollectionModal, openNewCollectionModal] = useNewCollectionModal();
  const [deleteRulesModal, openDeleteRulesModal] = useDeleteRulesModal();
  const [deletingCollections, setDeletingCollections] = useState<Set<string>>(new Set());
  const [deletingDatabases, setDeletingDatabases] = useState<Set<string>>(new Set());
  const [deletingDatasources, setDeletingDatasources] = useState<Set<string>>(new Set());
  const [deletingDSDefaultRules, setDeletingDSDefaultRules] = useState<Set<string>>(new Set());
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isSavingRule, setIsSavingRule] = useState<boolean>(false);
  const [selectedDataSource, setSelectedDataSource] = useState<string>();
  const [syncIncompatibleFields, setSyncIncompatibleFields] = useState<string[]>();

  // route state
  const [routeControlledNamespace, setRouteControlledNamespace] = React.useState('');

  const uiDraftsDisabled = useSelector((state: RootState) => state.deployment.deployConfig.uiDraftsDisabled);

  // The following bit of logic is used to catch a SyncIncompatibleRoleError thrown during create/update
  // rule requests. If this error is caught, we will display a modal (syncIncompatibilityConfirm)
  // confirming that the user wishes to proceed with saving the incompatible role, assuming the
  // role was not previously incompatible
  function submitRequestAndCatchIncompatibleRoleError<T>(onSubmit: (bypassWarning?: string) => Promise<T>): Promise<T> {
    return onSubmit().catch((e) => {
      if (e.code === ErrorCode.SyncIncompatibleRoleError) {
        if (!e.json.errorDetails || !e.json.errorDetails.sync_incompatible_roles) {
          return Promise.reject(e);
        }
        // Non net-new role incompatibility errors should be ignored
        if (!e.json.errorDetails.sync_incompatible_roles.new_incompatibilities) {
          return onSubmit(e.code);
        }
        setSyncIncompatibleFields(Object.keys(e.json.errorDetails.sync_incompatible_roles.new_incompatibilities));
        return syncIncompatibilityConfirm({
          errorDetails: e.json.errorDetails.sync_incompatible_roles.new_incompatibilities,
        }).then(
          () => {
            return onSubmit(e.code);
          },
          () => Promise.reject(e)
        );
      }
      setErrors((errs) => new Set([...errs, e.message]));
      return Promise.reject(e);
    });
  }

  const createRuleWithBypassLogic = (
    dataSourceId: string,
    rule: BuiltinRule | MongoDBNamespaceRule
  ): AsyncDispatchPayload<PartialMongoDBNamespaceRule | PartialBuiltinRule> => {
    const createRuleFunc = (bypassServiceChange?: BypassServiceChangeValue) =>
      createRule(dataSourceId, rule, bypassServiceChange);
    return submitRequestAndCatchIncompatibleRoleError(createRuleFunc);
  };

  const updateRuleWithBypassLogic = (
    dataSourceId: string,
    ruleId: string,
    rule: BuiltinRule | MongoDBNamespaceRule
  ): AsyncDispatchPayload<void> => {
    const updateRuleFunc = (bypassServiceChange?: BypassServiceChangeValue) =>
      updateRule(dataSourceId, ruleId, rule, bypassServiceChange);
    return submitRequestAndCatchIncompatibleRoleError(updateRuleFunc);
  };

  const createDefaultRuleWithBypassLogic = (
    dataSourceId: string,
    rule: MongoDBBaseRule
  ): AsyncDispatchPayload<MongoDBBaseRule> => {
    const createDefaultRuleFunc = (bypassServiceChange?: BypassServiceChangeValue) =>
      createDefaultRule(dataSourceId, rule, bypassServiceChange);
    return submitRequestAndCatchIncompatibleRoleError(createDefaultRuleFunc);
  };

  const updateDefaultRuleWithBypassLogic = (
    dataSourceId: string,
    rule: MongoDBBaseRule
  ): AsyncDispatchPayload<void> => {
    const updateDefaultRuleFunc = (bypassServiceChange?: BypassServiceChangeValue) =>
      updateDefaultRule(dataSourceId, rule, bypassServiceChange);
    return submitRequestAndCatchIncompatibleRoleError(updateDefaultRuleFunc);
  };

  React.useEffect(() => {
    track('RULES_CONFIGURATION.RULES_VIEWED');
  }, []);

  // setRouteControlledNamespace useEffect (yikes)
  React.useEffect(() => {
    if (isLoadingDataSources) {
      return;
    }

    const matchParams = match.params;

    // No data source specified in route
    if (!matchParams.dataSourceName) {
      setRouteControlledNamespace('');
      return;
    }

    // Specified data source does not exist
    const dataSource = (dataSources || []).find(({ name }) => name === matchParams.dataSourceName);
    if (!dataSource) {
      setRouteControlledNamespace('');
      redirectTo(appUrl.rules().list());
      return;
    }

    // Creating a new namespace rule should not impact the route
    if (match.url === appUrl.rules().new(dataSource.name)) {
      return;
    }

    // Being on the default rule should be agnostic to whether one exists
    if (
      match.url === appUrl.rules().default(dataSource.name) ||
      match.url === appUrl.rules().defaultSync(dataSource.name)
    ) {
      if (dataSource.name !== routeControlledNamespace) {
        setRouteControlledNamespace(dataSource.name);
      }
      return;
    }

    // A rule ID was specified in the route
    if (matchParams.ruleId) {
      // No namespace rules for this data source
      if (!partialRulesByDataSourceId || !partialRulesByDataSourceId[dataSource.id]) {
        setRouteControlledNamespace('');
        redirectTo(appUrl.rules().list());
        return;
      }

      // No namespace rule corresponding to the rule ID
      const rule = partialRulesByDataSourceId[dataSource.id].find(({ id }) => id === matchParams.ruleId);
      if (!rule) {
        setRouteControlledNamespace('');
        redirectTo(appUrl.rules().list());
        return;
      }

      const namespace = formatExplorerNamespace(dataSource.name, rule.database, rule.collection);
      if (namespace !== routeControlledNamespace) {
        setRouteControlledNamespace(namespace);
      }
    }
  }, [
    match.params.dataSourceName,
    match.params.ruleId,
    match.url,
    isLoadingDataSources,
    dataSources.length,
    defaultRulesByDataSourceId.size,
    partialRulesByDataSourceId.size,
  ]);

  React.useEffect(() => {
    setIsLoadingDataSources(true);

    loadDataSources()
      .then(async (services) => {
        if (services) {
          const dataSrcs = services.filter((svc) => isSelfHostedOrAtlasMongoService(svc.type));
          setIsLoadingPresetRoles(true);
          loadPresetRoles()
            .then((presets) => {
              if (presets) {
                setPresetRoles(presets);
              }
            })
            .catch((err) => setErrors((errs) => new Set([...errs, err.message])))
            .finally(() => setIsLoadingPresetRoles(false));

          setDataSources(dataSrcs);
          if (dataSrcs?.length) {
            const fetchedDefaultRules = {};
            const fetchedNamespaces = {};
            const fetchedPartialRules = {};
            const fetchedSyncIncompatibleRoles = {};

            const getDefaultRules = dataSrcs.map((dataSource) => loadDefaultRule(dataSource.id));
            const getNamespaces = dataSrcs.map((dataSource) => listNamespaces(dataSource.id));
            const getRules = dataSrcs.map((dataSource) => loadRules(dataSource.id));
            const getSyncIncompatibleRoles = dataSrcs.map((dataSource) => loadSyncIncompatibleRoles(dataSource.id));

            // Load default rule for data sources
            setIsLoadingDefaultRules(true);
            const loadAllDefaultRules = Promise.all(getDefaultRules)
              .then((defaultRulesResult) => {
                dataSrcs.forEach((dataSource, index) => {
                  fetchedDefaultRules[dataSource.id] = defaultRulesResult[index]
                    ? defaultRulesResult[index]
                    : undefined;
                });
                setDefaultRulesByDataSourceId(fetchedDefaultRules);
              })
              .catch((err) => {
                // Suppress 404s for the default rule
                if (err?.response?.status !== 404) {
                  setErrors((errs) => new Set([...errs, err.message]));
                }
              })
              .finally(() => {
                setIsLoadingDefaultRules(false);
              });

            // Load sync incompatible roles for data sources
            setIsLoadingSyncIncompatibleRoles(true);
            const loadAllSyncIncompatibleRoles = Promise.all(getSyncIncompatibleRoles)
              .then((incompatibleRolesResult) => {
                dataSrcs.forEach((dataSource, index) => {
                  fetchedSyncIncompatibleRoles[dataSource.id] = incompatibleRolesResult[index];
                });
                setSyncIncompatibleRolesByDataSourceId(fetchedSyncIncompatibleRoles);
              })
              .catch((err) => setErrors((errs) => new Set([...errs, err.message])))
              .finally(() => {
                setIsLoadingSyncIncompatibleRoles(false);
              });

            // Load namespaces for data sources
            setIsLoadingNamespaces(true);
            const loadAllNamespaces = Promise.all(getNamespaces)
              .then((namespacesResult) => {
                dataSrcs.forEach((dataSource, index) => {
                  fetchedNamespaces[dataSource.id] = namespacesResult[index];
                });
                setNamespacesByClusterId(fetchedNamespaces);
              })
              .catch((err) => {
                setErrors(
                  (errs) =>
                    new Set([
                      ...errs,
                      err?.response?.status === 403
                        ? "You do not have permission to view this data source's collections."
                        : err.message,
                    ])
                );
              })
              .finally(() => {
                setIsLoadingNamespaces(false);
              });

            // Load partial rules for data sources
            setIsLoadingRules(true);
            const loadAllRules = Promise.all(getRules)
              .then((rulesResult) => {
                dataSrcs.forEach((dataSource, index) => {
                  fetchedPartialRules[dataSource.id] = rulesResult[index];
                });
                setPartialRulesByDataSourceId(fetchedPartialRules);
              })
              .catch((err) => setErrors((errs) => new Set([...errs, err.message])))
              .finally(() => {
                setIsLoadingRules(false);
              });

            await loadAllDefaultRules;
            await loadAllNamespaces;
            await loadAllRules;
            await loadAllSyncIncompatibleRoles;
          }
        }
      })
      .catch((err) => setErrors((errs) => new Set([...errs, err.message])))
      .finally(() => {
        setIsLoadingDataSources(false);
      });

    return () => {
      setSelectedPresetRole(undefined);
      setErrors(new Set());
    };
  }, [appId, groupId]);

  useEffect(() => {
    let activeRuleHasChanges = false;
    if (rulesState.activeRule && selectedDataSource && pristineRule) {
      activeRuleHasChanges = !equal(
        isNamespaceRule(pristineRule)
          ? new MongoDBNamespaceRule(rulesState.activeRule)
          : new MongoDBBaseRule(rulesState.activeRule),
        pristineRule
      );
    } else if (rulesState.activeRule && selectedDataSource) {
      activeRuleHasChanges = true;
    }
    if (!activeRuleHasChanges) {
      setErrors(new Set());
      setRuleSaveError('');
    }
    setHasChanges(activeRuleHasChanges);
  }, [rulesState.activeRule, pristineRule]);

  const isLoading =
    isLoadingDataSources ||
    isLoadingNamespaces ||
    isLoadingRules ||
    isLoadingPresetRoles ||
    isLoadingDefaultRules ||
    isLoadingSyncIncompatibleRoles;

  // resizing logic
  const [leftPanelSize, setLeftPanelSize] = useState(LEFT_PANEL_MIN_SIZE);
  const [isResizing, setIsResizing] = useState(false);
  useEventListener(
    'mousemove',
    (e) => {
      const newSize = leftPanelSize + e.movementX;
      if (newSize >= LEFT_PANEL_MIN_SIZE && newSize <= LEFT_PANEL_MAX_SIZE) {
        setLeftPanelSize(newSize);
      }
    },
    { enabled: isResizing }
  );
  useEventListener('mouseup', () => setIsResizing(false), { enabled: isResizing });

  const { clusterCount, databaseCount, collectionCount } = getCountsFromNamespaces(namespacesByClusterId);

  const deleteDSDefaultRule = (deleteOptions: DeleteDefaultRule) => {
    track('RULES_CONFIGURATION.DELETE_DEFAULT_RULE_SUBMITTED', {
      dataSourceId: deleteOptions.dataSourceId,
      dataSourceName: deleteOptions.dataSourceName,
    });

    setDeletingDSDefaultRules(
      (currentDeletingDSDefaultRules) => new Set([...currentDeletingDSDefaultRules, deleteOptions.dataSourceId])
    );
    deleteDefaultRule(deleteOptions.dataSourceId)
      .then(() => {
        setActiveRule(rulesDispatch)(undefined);
        setPristineRule(undefined);

        setDefaultRulesByDataSourceId((currDefaultRules) => {
          return { ...currDefaultRules, [deleteOptions.dataSourceId]: undefined };
        });
      })
      .catch((err) => setErrors((errs) => new Set([...errs, err.message])))
      .finally(() => {
        setDeletingDSDefaultRules((currentDeletingDSDefaultRules) => {
          const newDeletingDSDefaultRules = new Set(currentDeletingDSDefaultRules);
          newDeletingDSDefaultRules.delete(deleteOptions.dataSourceId);
          return newDeletingDSDefaultRules;
        });
      });
  };

  const deleteCollectionRule = (deleteOptions: DeleteRule) => {
    track('RULES_CONFIGURATION.DELETE_RULES_SUBMITTED', {
      ruleId: deleteOptions.ruleId,
      dataSourceId: deleteOptions.dataSourceId,
      collectionName: deleteOptions.collectionName,
    });

    setDeletingCollections(
      (currentDeletingCollectionRules) => new Set([...currentDeletingCollectionRules, deleteOptions.collectionName])
    );
    deleteRule(deleteOptions.dataSourceId, deleteOptions.ruleId)
      .then(() => {
        // remove the deleted rule from partialRulesByDataSourceId
        setPartialRulesByDataSourceId((currentPartialRules) => {
          const newPartialRules = { ...currentPartialRules };
          newPartialRules[deleteOptions.dataSourceId] = newPartialRules[deleteOptions.dataSourceId].filter(
            (rule) => rule.id !== deleteOptions.ruleId
          );
          return newPartialRules;
        });

        // Reset active rule if it is the collection being deleted
        if (
          rulesState.activeRule &&
          isNamespaceRule(rulesState.activeRule) &&
          rulesState.activeRule?.id === deleteOptions.ruleId
        ) {
          setActiveRule(rulesDispatch)(
            new MongoDBNamespaceRule({
              database: rulesState.activeRule.database,
              collection: rulesState.activeRule.collection,
            })
          );
          setPristineRule(undefined);
        }
      })
      .catch((err) => setErrors((errs) => new Set([...errs, err.message])))
      .finally(() => {
        setDeletingCollections((currentDeletingCollectionRules) => {
          const newDeletingRules = new Set(currentDeletingCollectionRules);
          newDeletingRules.delete(deleteOptions.collectionName);
          return newDeletingRules;
        });
      });
  };

  const deleteDatabaseRules = (deleteOptions: BulkDeleteDatabase) => {
    track('RULES_CONFIGURATION.DELETE_RULES_SUBMITTED', {
      dataSource: deleteOptions.dataSourceName,
      database: deleteOptions.databaseName,
      dataSourceId: deleteOptions.dataSourceId,
    });

    setDeletingDatabases((currentDeletingDbRules) => new Set([...currentDeletingDbRules, deleteOptions.databaseName]));
    deleteRulesByDatabase(deleteOptions.dataSourceId, deleteOptions.databaseName)
      .then(() => {
        // remove the deleted rules from partialRulesByDataSourceId
        setPartialRulesByDataSourceId((currentPartialRules) => {
          const newPartialRules = { ...currentPartialRules };
          newPartialRules[deleteOptions.dataSourceId] = newPartialRules[deleteOptions.dataSourceId].filter(
            (rule) => rule.database !== deleteOptions.databaseName
          );
          return newPartialRules;
        });

        // Reset active rule if it is under the database rules that were deleted
        if (
          rulesState.activeRule &&
          isNamespaceRule(rulesState.activeRule) &&
          selectedDataSource === deleteOptions.dataSourceName &&
          rulesState.activeRule?.database === deleteOptions.databaseName
        ) {
          setActiveRule(rulesDispatch)(
            new MongoDBNamespaceRule({
              database: rulesState.activeRule.database,
              collection: rulesState.activeRule.collection,
            })
          );
          setPristineRule(undefined);
        }
      })
      .catch((err) => setErrors((errs) => new Set([...errs, err.message])))
      .finally(() => {
        setDeletingDatabases((currentDeletingDbRules) => {
          const newDeletingRules = new Set(currentDeletingDbRules);
          newDeletingRules.delete(deleteOptions.databaseName);
          return newDeletingRules;
        });
      });
  };

  const deleteDataSourceRules = (deleteOptions: BulkDeleteDataSource) => {
    track('RULES_CONFIGURATION.DELETE_RULES_SUBMITTED', {
      dataSource: deleteOptions.dataSourceName,
      dataSourceId: deleteOptions.dataSourceId,
    });

    setDeletingDatasources(
      (currentDeletingDataSourceRules) => new Set([...currentDeletingDataSourceRules, deleteOptions.dataSourceId])
    );
    deleteRulesByDataSource(deleteOptions.dataSourceId)
      .then(() => {
        // remove the deleted rules from partialRulesByDataSourceId
        setPartialRulesByDataSourceId((currentPartialRules) => {
          const newPartialRules = { ...currentPartialRules };
          delete newPartialRules[deleteOptions.dataSourceId];
          return newPartialRules;
        });

        // Reset active rule if it is under the data source rules that were deleted
        if (
          rulesState.activeRule &&
          isNamespaceRule(rulesState.activeRule) &&
          selectedDataSource === deleteOptions.dataSourceName
        ) {
          setActiveRule(rulesDispatch)(
            new MongoDBNamespaceRule({
              database: rulesState.activeRule.database,
              collection: rulesState.activeRule.collection,
            })
          );
          setPristineRule(undefined);
        }
      })
      .catch((err) => setErrors((errs) => new Set([...errs, err.message])))
      .finally(() => {
        setDeletingDatasources((currentDeletingDataSourceRules) => {
          const newDeletingRules = new Set(currentDeletingDataSourceRules);
          newDeletingRules.delete(deleteOptions.dataSourceId);
          return newDeletingRules;
        });
      });
  };

  const saveRule = () => {
    setRuleSaveError('');

    if (!rulesState.activeRule) {
      return;
    }

    const dataSourceId = dataSources.filter((dataSource) => dataSource.name === selectedDataSource)[0].id;
    const ruleToSave: DataSourceRule = rulesState.activeRule;

    const ruleIsNamespaceRule = isNamespaceRule(ruleToSave);
    const createRuleTrackingFields = { dataSourceId, dataSourceName: selectedDataSource };
    const updateRuleTrackingFields = { ...createRuleTrackingFields, ruleId: ruleToSave.id };

    const reloadIncompatibleRoles = () =>
      loadSyncIncompatibleRoles(dataSourceId)
        .then((incompatibleRolesResult) => {
          if (incompatibleRolesResult) {
            setSyncIncompatibleRolesByDataSourceId((currentSyncIncompatibleRolesByDataSourceId) => {
              return { ...currentSyncIncompatibleRolesByDataSourceId, [dataSourceId]: incompatibleRolesResult };
            });
          }
        })
        .catch((err) => setErrors((errs) => new Set([...errs, err.message])));

    if (dataSourceId && pristineRule) {
      setIsSavingRule(true);
      const update = () =>
        ruleIsNamespaceRule
          ? updateRuleWithBypassLogic(dataSourceId, ruleToSave.id!, ruleToSave)
          : updateDefaultRuleWithBypassLogic(dataSourceId, ruleToSave!);

      update()
        .then(() => {
          track(
            ruleIsNamespaceRule
              ? 'RULES_CONFIGURATION.COLLECTION_CHANGES_SAVED'
              : 'RULES_CONFIGURATION.DEFAULT_RULE_UPDATED',
            ruleIsNamespaceRule
              ? {
                  ...updateRuleTrackingFields,
                  dataBaseName: ruleToSave.database,
                  collectionName: ruleToSave.collection,
                }
              : updateRuleTrackingFields
          );
          setPristineRule(deepCopyRule(ruleToSave));

          reloadIncompatibleRoles();

          onSaveSuccess();

          // the following block is needed for the defaultRolesFilters button to react to rule updates correctly
          if (!ruleIsNamespaceRule) {
            setDefaultRulesByDataSourceId((currentDefaultRules) => {
              return { ...currentDefaultRules, [dataSourceId]: ruleToSave };
            });
          }
        })
        .catch((err) => {
          if (err.code !== ErrorCode.SyncIncompatibleRoleError) {
            setErrors((errs) => new Set([...errs, err.message]));
            setRuleSaveError(err.message);
          }
        })
        .finally(() => {
          setIsSavingRule(false);
        });
    } else if (ruleToSave && dataSourceId) {
      setIsSavingRule(true);
      const create = (): Promise<void | MongoDBBaseRule> =>
        isNamespaceRule(ruleToSave!)
          ? createRuleWithBypassLogic(dataSourceId, ruleToSave)
          : createDefaultRuleWithBypassLogic(dataSourceId, ruleToSave!);

      create()
        .then((createdPartialRule: MongoDBBaseRule) => {
          track(
            ruleIsNamespaceRule
              ? 'RULES_CONFIGURATION.COLLECTION_SET_UP_SUBMITTED'
              : 'RULES_CONFIGURATION.DEFAULT_RULE_CREATED',
            ruleIsNamespaceRule
              ? {
                  ...createRuleTrackingFields,
                  dataBaseName: ruleToSave.database,
                  collectionName: ruleToSave.collection,
                }
              : createRuleTrackingFields
          );

          const newPristineRule = deepCopyRule(ruleToSave!);
          newPristineRule.id = createdPartialRule.id;

          setActiveRule(rulesDispatch)(newPristineRule);
          setPristineRule(newPristineRule);

          if (isRulePartialMongoDBNamespace(createdPartialRule)) {
            setPartialRulesByDataSourceId((currentPartialRules) => {
              const newPartialRules = { ...currentPartialRules };
              newPartialRules[dataSourceId] = [...(newPartialRules[dataSourceId] || []), createdPartialRule];
              return newPartialRules;
            });
          } else {
            setDefaultRulesByDataSourceId((currentDefaultRules) => {
              return { ...currentDefaultRules, [dataSourceId]: newPristineRule };
            });
          }

          reloadIncompatibleRoles();

          onSaveSuccess();
        })
        .catch((err) => {
          if (err.code !== ErrorCode.SyncIncompatibleRoleError) {
            setErrors((errs) => new Set([...errs, err.message]));
            setRuleSaveError(err.message);
          }
        })
        .finally(() => {
          setIsSavingRule(false);
        });
    }
  };

  const addNewCollection = (
    dataSourceId: string,
    dataSourceName: string,
    databaseName: string,
    collectionName: string,
    ruleToSave: MongoDBNamespaceRule
  ) => {
    setIsSavingRule(true);

    createRuleWithBypassLogic(dataSourceId, ruleToSave)
      .then((createdPartialRule) => {
        track('RULES_CONFIGURATION.COLLECTION_SET_UP_SUBMITTED', {
          dataSourceId,
          dataSourceName,
          databaseName,
          collectionName,
        });

        if (createdPartialRule) {
          setNamespacesByClusterId((currentNamespaces) => {
            const newNamespaces = { ...currentNamespaces };
            newNamespaces[dataSourceId] = [...newNamespaces[dataSourceId]];
            const currDb = newNamespaces[dataSourceId].find((namespace) => namespace.database === databaseName);
            if (currDb) {
              currDb.collections.push(collectionName);
            } else {
              newNamespaces[dataSourceId].push({ database: databaseName, collections: [collectionName] });
            }
            return newNamespaces;
          });

          const newPristineRule = deepCopyRule(ruleToSave);
          newPristineRule.id = createdPartialRule.id;

          setActiveRule(rulesDispatch)(newPristineRule);
          setPristineRule(newPristineRule);
          if (selectedDataSource !== dataSourceName) {
            setSelectedDataSource(dataSourceName);
          }

          setPartialRulesByDataSourceId((currentPartialRules) => {
            const newPartialRules = { ...currentPartialRules };
            newPartialRules[dataSourceId] = [
              ...(newPartialRules[dataSourceId] || []),
              createdPartialRule as PartialMongoDBNamespaceRule,
            ];
            return newPartialRules;
          });

          const namespace = formatExplorerNamespace(dataSourceName, databaseName, collectionName);
          if (namespace !== routeControlledNamespace) {
            setRouteControlledNamespace(namespace);
          }
        }
      })
      .catch((err) => {
        if (err.code !== ErrorCode.SyncIncompatibleRoleError) {
          setErrors((errs) => new Set([...errs, err.message]));
          setRuleSaveError(err.message);
        }
      })
      .finally(() => {
        setIsSavingRule(false);
      });
  };

  const showSaveButton = rulesState.activeRule && selectedDataSource;
  const showCancelButton =
    !isSavingRule && hasChanges && (rulesState.activeRule?.roles || rulesState.activeRule?.filters);
  const constructedDataSources = makeDataSourceData(
    dataSources,
    namespacesByClusterId,
    partialRulesByDataSourceId,
    syncIncompatibleRolesByDataSourceId
  );

  const darkMode = useDarkMode();

  return (
    <LoadingWrapper data-test-selector={TestSelector.IsLoadingWrapper} isLoading={isLoading}>
      {!isLoading && (
        <RulesPageContextProvider
          activeRule={rulesState.activeRule}
          createCollection={(dsId, dsName, dbName, colName, newRule) =>
            addNewCollection(dsId, dsName, dbName, colName, newRule)
          }
          data-test-selector={TestSelector.RulesPageCtxProvider}
          defaultRulesByDataSourcesId={defaultRulesByDataSourceId}
          deletingCollections={deletingCollections}
          deletingDatabases={deletingDatabases}
          deletingDatasources={deletingDatasources}
          deletingDSDefaultRules={deletingDSDefaultRules}
          hasChanges={hasChanges}
          hasClientValidationError={hasClientValidationError}
          isSavingRule={isSavingRule}
          onClickCreateCollection={(dataSourceName, databaseName = '') =>
            openNewCollectionModal(dataSourceName, databaseName)
          }
          onClickDeleteAction={(deleteOptions) => openDeleteRulesModal(deleteOptions)}
          onDeleteDefaultRule={(deleteOptions) => deleteDSDefaultRule(deleteOptions)}
          onDeleteRule={(deleteOptions: DeleteRule) => deleteCollectionRule(deleteOptions)}
          onDeleteRuleByDatabase={(deleteOptions: BulkDeleteDatabase) => deleteDatabaseRules(deleteOptions)}
          onDeleteRuleByDatasource={(deleteOptions: BulkDeleteDataSource) => deleteDataSourceRules(deleteOptions)}
          partialDataSources={dataSources}
          partialRulesByDataSourceId={partialRulesByDataSourceId}
          presetRoles={presetRoles}
          pristineRule={pristineRule}
          pushError={(errMsg: string) => setErrors((errs) => new Set([...errs, errMsg]))}
          ruleSaveError={ruleSaveError}
          saveRule={saveRule}
          selectedDataSource={selectedDataSource}
          selectedPresetRole={selectedPresetRole}
          setPristineRule={setPristineRule}
          setRuleSaveError={setRuleSaveError}
          setSelectedDataSource={setSelectedDataSource}
          rulesDispatchActions={{
            deleteFilterOnActiveRuleByIdx: deleteFilterOnActiveRuleByIdx(rulesDispatch),
            deleteRoleOnActiveRuleByIdx: deleteRoleOnActiveRuleByIdx(rulesDispatch),
            pushFilterOnActiveRule: pushFilterOnActiveRule(rulesDispatch),
            pushRoleOnActiveRule: pushRoleOnActiveRule(rulesDispatch),
            replaceFilterOnActiveRuleByIdx: replaceFilterOnActiveRuleByIdx(rulesDispatch),
            replaceRoleOnActiveRuleByIdx: replaceRoleOnActiveRuleByIdx(rulesDispatch),
            setActiveRule: setActiveRule(rulesDispatch),
            swapRolesOnActiveRule: swapRolesOnActiveRule(rulesDispatch),
            pushRolesOnActiveRule: pushRolesOnActiveRule(rulesDispatch),
          }}
          setHasClientValidationError={setHasClientValidationError}
          setOnDiscardChanges={(newOnDiscard) => setOnDiscardChanges(() => newOnDiscard)}
          setOnSaveSuccess={(newOnSave) => setOnSaveSuccess(() => newOnSave)}
          syncIncompatibleRolesByDataSourceId={syncIncompatibleRolesByDataSourceId}
          syncIncompatibleFields={syncIncompatibleFields || []}
          setSyncIncompatibleFields={setSyncIncompatibleFields}
        >
          <Title>Rules</Title>
          {!!errors.size && (
            <Banner
              data-cy="rules-page-error-banner"
              data-test-selector={TestSelector.ErrorBanner}
              data-testid={TestSelector.ErrorBanner}
              variant={Variant.Danger}
            >
              {[...errors].map((err) => (
                <div key={err}>{err}</div>
              ))}
            </Banner>
          )}
          <CloudBreadcrumbs />
          <BreadcrumbsItem>Rules</BreadcrumbsItem>
          <div className="section-header">
            <div className="section-header section-header-title">
              <H2 data-cy="rules-title-text" data-test-selector={TestSelector.TitleText}>
                Rules
              </H2>
              <div className="section-header-title-controls">
                {showSaveButton && (
                  <>
                    <SaveStatus
                      data-cy={TestSelector.RuleSaveDraftStatus}
                      data-test-selector={TestSelector.RuleSaveDraftStatus}
                      saveErrorMessage={DEFAULT_SAVE_ERROR_MESSAGE}
                      saveError={ruleSaveError}
                      saving={isSavingRule}
                      isDraft={!uiDraftsDisabled}
                    />
                    {showCancelButton && (
                      <Button
                        onClick={() => onDiscardChanges()}
                        className={`${baseClassName}-button`}
                        data-test-selector={TestSelector.CancelButton}
                        data-cy={`header-${TestSelector.CancelButton}`}
                      >
                        Cancel
                      </Button>
                    )}
                    <SaveButton
                      data-cy={TestSelector.RuleSaveDraftButton}
                      onSave={saveRule}
                      isDirty={hasChanges}
                      saving={isSavingRule}
                      isDraft={!uiDraftsDisabled}
                      data-test-selector={TestSelector.RuleSaveDraftButton}
                      disabled={hasClientValidationError}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          {!dataSources.length ? (
            <LinkDataSourceEmptyState
              title="Link your Data Source"
              description="To define your rules, first link an Atlas data source to your application."
              actionTitle="Link a Data Source"
            />
          ) : (
            <CollectionExplorerContextProvider
              isLoading={isLoading}
              hasCollections={constructedDataSources.some(({ databases }) =>
                databases.some(({ collections }) => collections.length)
              )}
              dataSources={constructedDataSources}
              routeControlledNamespace={routeControlledNamespace}
              data-test-selector={TestSelector.ColExplorerCtxProvider}
            >
              <div
                className={`${baseClassName}-container`}
                style={{ gridTemplateColumns: leftPanelSize }}
                data-test-selector={TestSelector.EditorContainer}
              >
                <ExplorerHeader
                  className={`${baseClassName}-left-nav`}
                  clusterCount={clusterCount}
                  databaseCount={databaseCount}
                  collectionCount={collectionCount}
                  data-test-selector={TestSelector.ColExplorerHeader}
                />
                <div
                  data-test-selector={TestSelector.LeftSection}
                  className={classNames(`${baseClassName}-left`, {
                    [`${baseClassName}-left-light-mode`]: !darkMode,
                    [`${baseClassName}-left-dark-mode`]: darkMode,
                  })}
                >
                  <div className={`${baseClassName}-left-content`}>
                    <CollectionExplorer
                      className={`${baseClassName}-collection-explorer`}
                      data-test-selector={TestSelector.ColExplorer}
                      appUrl={appUrl}
                      resourceName={ResourceName.Rules}
                      context={RulesPageCollectionExplorerContext}
                      dataSourceMenu={DataSourceActionMenu}
                      databaseMenu={DatabaseActionMenu}
                      collectionMenu={CollectionActionMenu}
                      collectionRowLink={(collectionData: CollectionData<RulesPageCollectionData>) => {
                        const collectionDataData = collectionData.data;
                        return collectionDataData.partialRule
                          ? appUrl
                              .rules()
                              .rule({
                                dataSourceName: collectionDataData.dataSourceName,
                                ruleId: collectionDataData.partialRule.id,
                              })
                              .get()
                          : appUrl.rules().new(collectionDataData.dataSourceName);
                      }}
                      isCollectionConfigured={({ data }) => !!data.partialRule}
                      defaultConfigButton={DefaultRolesFiltersButton}
                      defaultConfigButtonLink={(dataSourceName) => appUrl.rules().default(dataSourceName)}
                      onUnconfiguredToggleChange={() => track('RULES_CONFIGURATION.UNCONFIGURED_COLLECTIONS_TOGGLED')}
                      shouldPreventNavigation={hasChanges && !!pristineRule}
                      dataSourceDetailElement={(dsId: string) =>
                        syncIncompatibleRolesByDataSourceId[dsId].flexibleSyncEnabled ? (
                          <Badge
                            className={`${baseClassName}-left-badge`}
                            variant="green"
                            data-cy="collection-explorer-synced-badge"
                            data-test-selector={TestSelector.SyncedBadge}
                          >
                            Synced
                          </Badge>
                        ) : undefined
                      }
                      collectionDetailElement={(collectionData: CollectionData<RulesPageCollectionData>) => {
                        return collectionData.data.hasSyncIncompatibleRoles ? (
                          <Tooltip
                            className={`${baseClassName}-left-sync-incompatible-roles-tooltip`}
                            justify="middle"
                            trigger={
                              <Box className={`${baseClassName}-left-sync-incompatible-icon`}>
                                <Icon glyph="ImportantWithCircle" fill={palette.yellow.dark2} />
                              </Box>
                            }
                            triggerEvent="hover"
                          >
                            This collection has roles incompatible with Sync
                          </Tooltip>
                        ) : undefined;
                      }}
                    />
                  </div>
                  <div className={`${baseClassName}-left-resize-handle`} onMouseDown={() => setIsResizing(true)} />
                </div>
                <div data-test-selector={TestSelector.RightSection} className={`${baseClassName}-right`}>
                  <div className={`${baseClassName}-right-content`}>
                    <RuleViewerComponent
                      setRulesPageError={(errMsg: string) => setErrors((errs) => new Set([...errs, errMsg]))}
                    />
                  </div>
                </div>
              </div>
              {newCollectionModal}
            </CollectionExplorerContextProvider>
          )}
          {deleteRulesModal}
        </RulesPageContextProvider>
      )}
    </LoadingWrapper>
  );
};

export const mapStateToProps = (state: RootState) => {
  const { app } = getAppState(state);
  const { groupId, id: appId } = app;

  return {
    app,
    appId,
    groupId,
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  listNamespaces: (groupId: string, appId: string) => (dataSourceId: string) =>
    dispatch(actions.listDataSourceNamespaces({ groupId, appId, dataSourceId })),
  loadRules: (groupId: string, appId: string) => (dataSourceId: string) =>
    dispatch(actions.loadRules({ groupId, appId, dataSourceId })),
  loadDataSources: (groupId: string, appId: string) => () => dispatch(actions.loadDataSources({ groupId, appId })),
  loadPresetRoles: () => dispatch(actions.loadPresetRoles({})),
  loadDefaultRule: (groupId: string, appId: string) => (dataSourceId: string) =>
    dispatch(actions.loadDefaultRule({ groupId, appId, dataSourceId })),
  deleteDefaultRule: (groupId: string, appId: string) => (dataSourceId: string) =>
    dispatch(actions.deleteDefaultRule({ groupId, appId, dataSourceId })),
  deleteRule: (groupId: string, appId: string) => (dataSourceId: string, ruleId: string) =>
    dispatch(actions.deleteRule({ groupId, appId, dataSourceId, ruleId })),
  deleteRules: (groupId: string, appId: string) => (dataSourceId: string, databaseName?: string) =>
    dispatch(actions.deleteRules({ groupId, appId, dataSourceId, databaseName })),
  updateRule:
    (groupId: string, appId: string) =>
    (
      dataSourceId: string,
      ruleId: string,
      rule: BuiltinRule | MongoDBNamespaceRule,
      bypassServiceChange?: BypassServiceChangeValue
    ) =>
      dispatch(actions.updateRule({ groupId, appId, dataSourceId, ruleId, rule, bypassServiceChange })),
  createRule:
    (groupId: string, appId: string) =>
    (dataSourceId: string, rule: BuiltinRule | MongoDBNamespaceRule, bypassServiceChange?: BypassServiceChangeValue) =>
      dispatch(actions.createRule({ groupId, appId, dataSourceId, rule, bypassServiceChange })),
  createDefaultRule:
    (groupId: string, appId: string) =>
    (dataSourceId: string, rule: MongoDBBaseRule, bypassServiceChange?: BypassServiceChangeValue) =>
      dispatch(actions.createDefaultRule({ groupId, appId, dataSourceId, rule, bypassServiceChange })),
  updateDefaultRule:
    (groupId: string, appId: string) =>
    (dataSourceId: string, rule: MongoDBBaseRule, bypassServiceChange?: BypassServiceChangeValue) =>
      dispatch(actions.updateDefaultRule({ groupId, appId, dataSourceId, rule, bypassServiceChange })),
  loadSyncIncompatibleRoles: (groupId: string, appId: string) => (dataSourceId: string) =>
    dispatch(actions.loadSyncIncompatibleRoles({ groupId, appId, dataSourceId })),
  redirectTo: (url: string) => dispatch(redirectToAction(url)),
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: Omit<Props, keyof (ReduxStateProps & ReduxDispatchProps)>
): Props => {
  const { id: appId, groupId } = stateProps.app;
  const {
    createDefaultRule,
    createRule,
    deleteDefaultRule,
    deleteRule,
    deleteRules,
    listNamespaces,
    loadDataSources,
    loadDefaultRule,
    loadRules,
    loadSyncIncompatibleRoles,
    updateDefaultRule,
    updateRule,
    ...otherDispatchProps
  } = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    ...otherDispatchProps,
    appUrl: rootUrl.groups().group(groupId).apps().app(appId),
    createDefaultRule: createDefaultRule(groupId, appId),
    createRule: createRule(groupId, appId),
    deleteDefaultRule: deleteDefaultRule(groupId, appId),
    deleteRule: deleteRule(groupId, appId),
    deleteRulesByDatabase: deleteRules(groupId, appId),
    deleteRulesByDataSource: deleteRules(groupId, appId),
    listNamespaces: listNamespaces(groupId, appId),
    loadDataSources: loadDataSources(groupId, appId),
    loadDefaultRule: loadDefaultRule(groupId, appId),
    loadRules: loadRules(groupId, appId),
    loadSyncIncompatibleRoles: loadSyncIncompatibleRoles(groupId, appId),
    updateDefaultRule: updateDefaultRule(groupId, appId),
    updateRule: updateRule(groupId, appId),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RulesPageComponent);
