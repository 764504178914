import { useTheme } from '@emotion/react';
import { palette } from '@leafygreen-ui/palette';
import { spacing } from '@leafygreen-ui/tokens';

const theme = {
  banner: {
    verticalMargin: '10px',
    width: '750px',
  },
  leafygreen: {
    colors: palette,
    spacing,
  },
  zIndex: {
    base: 0,
    // Icons and dropdowns that need to be above inputs
    inputOverlay: 100,
    mongoNav: 200,
    toast: 300,
    guide: 400,
    // Modal backgrounds should probably be above everything
    modal: 500,
  },
  darkMode: false,
  isOSPreference: false,
};

export type Theme = typeof theme;

// useDarkMode returns whether the app is in dark mode as determined by the emotion theme
export const useDarkMode = () => {
  const currentTheme = useTheme();
  return currentTheme.darkMode;
};

// useIsOSPreference returns whether the app should match the user's OS settings
export const useIsOSPreference = () => {
  const currentTheme = useTheme();
  return currentTheme.isOSPreference;
};

export default theme;
