import { createReducer } from 'redux-act';

import { PushNotification } from 'admin-sdk';

import * as actions from './actions';

export interface PushState {
  messages: PushNotification[];
  messageError?: string;
  loadingMessagesError?: string;
  loadingMessages: boolean;
}

const setMessageError = (state: PushState, { error }: { error?: string }) => {
  return { ...state, messageError: error };
};

export const defaultState: PushState = {
  messages: [],
  loadingMessages: false,
};

const pushReducer = createReducer<PushState>({}, defaultState);

pushReducer.on(actions.loadMessagesActions.req, (state) => ({
  ...state,
  messages: [],
  loadingMessagesError: undefined,
  loadingMessages: true,
}));

pushReducer.on(actions.loadMessagesActions.rcv, (state, { payload }) => ({
  ...state,
  messages: payload,
  loadingMessagesError: undefined,
  loadingMessages: false,
}));

pushReducer.on(actions.loadMessagesActions.fail, (state, { error }) => ({
  ...state,
  loadingMessagesError: error,
  loadingMessages: false,
}));

pushReducer.on(actions.sendPushNotificationActions.fail, setMessageError);
pushReducer.on(actions.saveMessageActions.fail, setMessageError);
pushReducer.on(actions.createMessageActions.fail, setMessageError);
pushReducer.on(actions.setNewMessageError, setMessageError);

export default pushReducer;
