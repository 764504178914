import { createAction, createReducer } from 'redux-act';

import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import { APIKey, PartialAPIKey } from 'admin-sdk';

import { ReadonlyAPIKeysState } from './types';

interface APIKeyRequestPayload extends BaseRequestPayload {
  keyId: string;
}

interface CreateAPIKeyRequestPayload extends BaseRequestPayload {
  key: Pick<APIKey, 'name'>;
}

export const makeKeyActions = (NAME: string) => {
  const setNewKeyName = createAction<{ name: string }>(`${NAME}set new key name`);

  const [loadAPIKeysActions, loadAPIKeys] = createActionsAndExecutor<BaseRequestPayload, PartialAPIKey[]>(
    `${NAME}load api keys`,
    (client, { groupId, appId }) => client.apps(groupId).app(appId).apiKeys().list
  );
  const [deleteAPIKeyActions, deleteAPIKey] = createActionsAndExecutor<APIKeyRequestPayload, void>(
    `${NAME}delete api key`,
    (client, { groupId, appId, keyId }) => client.apps(groupId).app(appId).apiKeys().apiKey(keyId).remove
  );
  const [disableAPIKeyActions, disableAPIKey] = createActionsAndExecutor<APIKeyRequestPayload, void>(
    `${NAME}disable api key`,
    (client, { groupId, appId, keyId }) => client.apps(groupId).app(appId).apiKeys().apiKey(keyId).disable
  );
  const [enableAPIKeyActions, enableAPIKey] = createActionsAndExecutor<APIKeyRequestPayload, void>(
    `${NAME}enable api key`,
    (client, { groupId, appId, keyId }) => client.apps(groupId).app(appId).apiKeys().apiKey(keyId).enable
  );
  const [createAPIKeyActions, createAPIKey] = createActionsAndExecutor<CreateAPIKeyRequestPayload, APIKey>(
    `${NAME}create api key`,
    (client, { groupId, appId, key }) =>
      () =>
        client.apps(groupId).app(appId).apiKeys().create(key)
  );
  return {
    loadAPIKeysActions,
    loadAPIKeys,
    deleteAPIKeyActions,
    deleteAPIKey,
    disableAPIKeyActions,
    disableAPIKey,
    enableAPIKeyActions,
    enableAPIKey,
    createAPIKeyActions,
    createAPIKey,
    setNewKeyName,
  };
};

export const makeAPIKeyReducer = (actions: ReturnType<typeof makeKeyActions>) => {
  const reducer = createReducer<ReadonlyAPIKeysState>({}, { newKeyName: '', apiKeyError: undefined, apiKeys: {} });

  reducer.on(actions.createAPIKeyActions.fail, (state, payload) => ({
    ...state,
    apiKeyError: payload.error,
  }));

  reducer.on(actions.createAPIKeyActions.rcv, (state, { payload: key }) => ({
    ...state,
    apiKeyError: undefined,
    newKeyName: '',
    apiKeys: { ...state.apiKeys, [key.id || '']: key },
  }));

  reducer.on(actions.loadAPIKeysActions.rcv, (state, { payload }) => ({
    ...state,
    apiKeys: payload.reduce((acc, key) => ({ ...acc, [key.id]: key }), {}),
  }));

  reducer.on(actions.setNewKeyName, (state, { name }) => ({
    ...state,
    newKeyName: name,
  }));

  return reducer;
};
