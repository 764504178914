import { useState } from 'react';
import equal from 'fast-deep-equal';

import {
  AWSEventBridgeEventProcessor,
  AWSEventBridgeEventProcessorConfig,
  ErrorHandler,
  EventProcessorType,
  EventSubscriptionExecution,
  FunctionEventProcessor,
  FunctionEventProcessorConfig,
  OperationType,
  PartialAppFunction,
} from 'admin-sdk';

import {
  AuthenticationTrigger,
  DatabaseTrigger,
  ScheduledTrigger,
  ScheduleType,
  Trigger,
  TriggerType,
  TriggerTypes,
} from './types';

// isNonSyncTriggerType checks if a string is one of the TriggerType values
export const isNonSyncTriggerType = (type: string) => Object.values(TriggerType).includes(type as TriggerTypes);

export const isDatabaseTrigger = (trigger?: Trigger): trigger is DatabaseTrigger =>
  trigger?.type === TriggerType.Database;

export const isAuthTrigger = (trigger?: Trigger): trigger is AuthenticationTrigger =>
  trigger?.type === TriggerType.AuthEvent;

export const isScheduledTrigger = (trigger?: Trigger): trigger is ScheduledTrigger =>
  trigger?.type === TriggerType.Scheduled;

export const setEventTimes = (trigger: Trigger, payload: EventSubscriptionExecution): Trigger => {
  let clusterTime = payload.clusterTime;
  if (payload.clusterTime?.getTime() === 0) {
    clusterTime = undefined;
  }
  return {
    ...trigger,
    completedAt: payload.completedAt,
    clusterTime,
  };
};

interface DatabaseForm {
  name: string;
  disabled: boolean;
  skipCatchUpEvents: boolean;
  unordered: boolean;
  dataSourceId: string;
  database: string;
  collection: string;
  operationTypes: OperationType[];
  fullDocumentEnabled: boolean;
  fullDocumentBeforeChange: boolean;
  eventProcessorType: EventProcessorType;
  useExtendedJSON: boolean;
  matchExpression: string;
  projectExpression: string;
  autoResumeEnabled: boolean;
  maximumThroughputEnabled: boolean;
  eventBridgeProcessorConfig?: {
    accountId: string;
    region: string;
    errorFunctionId?: string;
    errorFunctionName?: string;
    errorFunctionSource?: string;
  };
  functionProcessorConfig?: {
    functionId?: string;
    functionName?: string;
    functionSource?: string;
  };
}

interface ScheduledForm {
  name: string;
  disabled: boolean;
  skipCatchUpEvents: boolean;
  schedule: string;
  scheduleType: ScheduleType;
  eventProcessorType: EventProcessorType;
  eventBridgeProcessorConfig?: {
    accountId: string;
    region: string;
    useExtendedJSON: boolean;
  };
  functionProcessorConfig?: {
    functionId?: string;
    functionName?: string;
    functionSource?: string;
  };
}

/**
 * Retrieve the function id from a trigger object
 * @param trigger trigger to get the function id for
 */
export const getTriggerFunctionId = (trigger: Trigger): string | undefined => {
  return (trigger.eventProcessors?.FUNCTION?.config as FunctionEventProcessorConfig)?.functionId;
};

/**
 * Retrieve the function name from a trigger object
 * @param trigger trigger to get the function name for
 */
export const getTriggerFunctionName = (trigger: Trigger): string | undefined => {
  return (trigger.eventProcessors?.FUNCTION?.config as FunctionEventProcessorConfig)?.functionName;
};

export const toDatabaseTrigger = (form: DatabaseForm): DatabaseTrigger => {
  const eventProcessors: { AWS_EVENTBRIDGE?: AWSEventBridgeEventProcessor; FUNCTION?: FunctionEventProcessor } = {};
  let errorHandler: ErrorHandler | undefined;

  if (form.eventProcessorType === EventProcessorType.AWSEventBridge) {
    eventProcessors.AWS_EVENTBRIDGE = {
      config: {
        accountID: form.eventBridgeProcessorConfig!.accountId,
        region: form.eventBridgeProcessorConfig!.region,
        useExtendedJSON: form.useExtendedJSON,
      },
    };

    if (form.eventBridgeProcessorConfig?.errorFunctionId) {
      errorHandler = {
        config: {
          functionId: form.eventBridgeProcessorConfig.errorFunctionId,
          enabled: true,
        },
      };
    }
  }

  if (form.eventProcessorType === EventProcessorType.Function) {
    eventProcessors.FUNCTION = {
      config: {
        functionId: form.functionProcessorConfig?.functionId || '',
        functionName: form.functionProcessorConfig?.functionName || '',
      },
    };
  }

  return {
    type: TriggerType.Database,
    name: form.name,
    disabled: form.disabled,
    config: {
      unordered: form.unordered,
      skipCatchUpEvents: form.skipCatchUpEvents,
      serviceId: form.dataSourceId,
      database: form.database,
      collection: form.collection,
      operationTypes: form.operationTypes,
      match: form.matchExpression ? JSON.parse(form.matchExpression) : {},
      project: form.projectExpression ? JSON.parse(form.projectExpression) : {},
      fullDocument: form.fullDocumentEnabled,
      fullDocumentBeforeChange: form.fullDocumentBeforeChange,
      autoResume: form.autoResumeEnabled,
      maximumThroughput: form.maximumThroughputEnabled,
    },
    eventProcessors,
    errorHandler,
  };
};

export const toScheduledTrigger = (form: ScheduledForm): ScheduledTrigger => {
  const eventProcessors: { AWS_EVENTBRIDGE?: AWSEventBridgeEventProcessor; FUNCTION?: FunctionEventProcessor } = {};

  if (form.eventProcessorType === EventProcessorType.AWSEventBridge) {
    eventProcessors.AWS_EVENTBRIDGE = {
      config: {
        accountID: form.eventBridgeProcessorConfig!.accountId,
        region: form.eventBridgeProcessorConfig!.region,
        useExtendedJSON: form.eventBridgeProcessorConfig!.useExtendedJSON,
      },
    };
  }

  if (form.eventProcessorType === EventProcessorType.Function) {
    eventProcessors.FUNCTION = {
      config: {
        functionId: form.functionProcessorConfig?.functionId || '',
        functionName: form.functionProcessorConfig?.functionName || '',
      },
    };
  }

  return {
    type: TriggerType.Scheduled,
    name: form.name,
    disabled: form.disabled,
    config: {
      skipCatchUpEvents: form.skipCatchUpEvents,
      schedule: form.schedule,
      scheduleType: form.scheduleType,
    },
    eventProcessors,
  };
};

// custom hook that will keep track of state and on each update compare to the initial state value to determine if it
// is dirty. It will call the passed in dirty updater function with true if dirty and false otherwise.
export function useDirtyTrackingState<T>(initialValue: T): [T, boolean, (T) => void, () => void] {
  const [state, setState] = useState(initialValue);
  const [defaultValue, setDefaultValue] = useState(initialValue);
  const [isDirty, setIsDirty] = useState(false);

  const updater = (val: T) => {
    setIsDirty(!equal(val, defaultValue));
    setState(val);
  };

  const updateDefaultValue = () => {
    setDefaultValue(state);
    setIsDirty(false);
  };

  return [state, isDirty, updater, updateDefaultValue];
}

type EventBridgeProcessorConfigState = {
  accountId: string;
  region: string;
  useExtendedJSON: boolean;
  errorFunctionId?: string;
  errorFunctionName?: string;
  errorFunctionSource?: string;
};

// custom hook that wraps useState and determines the correct initial state for the event bridge processor config
// NOTE: can only be used in a react component
export const useEventBridgeProcessorConfigState = (
  trigger: Trigger | undefined
): ReturnType<typeof useDirtyTrackingState<EventBridgeProcessorConfigState | undefined>> => {
  const eventBridgeConfig = trigger?.eventProcessors?.AWS_EVENTBRIDGE?.config as AWSEventBridgeEventProcessorConfig;

  const initialValue =
    trigger && eventBridgeConfig
      ? {
          accountId: eventBridgeConfig.accountID,
          region: eventBridgeConfig.region,
          useExtendedJSON: eventBridgeConfig.useExtendedJSON,
          errorFunctionId: trigger.errorHandler?.config?.functionId,
        }
      : undefined;

  return useDirtyTrackingState<EventBridgeProcessorConfigState | undefined>(initialValue);
};

type FunctionProcessorConfigState = {
  functionId?: string;
  functionName?: string;
  functionSource?: string;
};

// custom hook that wraps useState and determines the correct initial state for the function processor config
// NOTE: can only be used in a react component
export const useFunctionProcessorConfigState = (
  trigger: Trigger | undefined
): ReturnType<typeof useDirtyTrackingState<FunctionProcessorConfigState | undefined>> => {
  const functionProcessorConfig = trigger?.eventProcessors?.FUNCTION?.config as FunctionEventProcessorConfig;

  const initialValue =
    trigger && functionProcessorConfig
      ? {
          functionId: functionProcessorConfig.functionId,
          functionName: functionProcessorConfig.functionName,
        }
      : undefined;

  return useDirtyTrackingState<FunctionProcessorConfigState | undefined>(initialValue);
};

/**
 * Applies a reduce operation on an array of app functions, creating an object whose keys are the function ids and
 * values are the functions themselves
 * @param funcs array of app functions
 * @returns an object where the keys are function ids and the values are the app functions
 */
export const reduceFunctions = (funcs: PartialAppFunction[]): Record<string, PartialAppFunction> => {
  return funcs.reduce(
    (acc: Record<string, PartialAppFunction>, func: PartialAppFunction) => ({
      ...acc,
      [func.id]: func,
    }),
    {}
  );
};

export const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
