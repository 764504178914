import React from 'react';

export enum GuideName {
  SyncGuide = 'syncGuide',
  GraphQLGuide = 'graphQLGuide',
  TriggersGuide = 'triggersGuide',
  EndpointsGuide = 'endpointsGuide',
  WebSDKGuide = 'webSDKGuide',
}

export interface GuideCardContent {
  title: string;
  description: string;
  timeEstimate: string;
  logo: React.ReactNode;
}

export interface GuideStep {
  showProgress: boolean;
  body: React.ReactNode;
  url?: string;
  docsLink?: string;
  nextStepTitle: string;
  nextIsButton: boolean;
}

export interface GuideContent {
  header: string;
  steps: GuideStep[];
  nextGuideName?: GuideName;
}

export interface GuideLocalStorageItem {
  name?: GuideName;
  step?: string;
}

export enum GuideStartLocation {
  WelcomeModal = 'welcome-modal',
  ReccomendModal = 'recommend-modal',
  SyncConfigModal = 'sync-config-modal',
}
