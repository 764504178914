import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AllowedIPToStore')
export class AllowedIPToStore {
  @JsonProperty('_id', String, true)
  public id?: string = undefined;

  @JsonProperty('address')
  public address = '';

  @JsonProperty('comment', String, true)
  public comment?: string = undefined;

  @JsonProperty('use_current', Boolean, true)
  public useCurrent?: boolean = false;

  constructor(partial?: Partial<AllowedIPToStore>) {
    Object.assign(this, partial);
  }
}

@JsonObject('ListedAllowedIP')
export class ListedAllowedIP {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('address')
  public address = '';

  @JsonProperty('comment', String, true)
  public comment?: string = undefined;

  @JsonProperty('includes_current', Boolean, true)
  public includesCurrent?: boolean = false;

  constructor(partial?: Partial<ListedAllowedIP>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AccessList')
export class AccessList {
  @JsonProperty('allowed_ips', [ListedAllowedIP])
  public listedAllowedIPs: ListedAllowedIP[] = [];

  @JsonProperty('current_ip')
  public currentIP = '';

  constructor(partial?: Partial<AccessList>) {
    Object.assign(this, partial);
  }
}
