import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundary from 'baas-ui/common/components/error-boundary';
import { errorHandlerNotify } from 'baas-ui/error_util';

const ErrorBoundaryRoute = ({ ...props }) => (
  <ErrorBoundary onError={errorHandlerNotify}>
    <Route {...props} />
  </ErrorBoundary>
);

export default ErrorBoundaryRoute;
