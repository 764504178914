import { Dispatch, Reducer, ReducerAction } from 'react';

import { MongoDBRuleFilter, MongoDBRuleRole } from 'admin-sdk';

import { DataSourceRule } from './types';
import { deepCopyRule } from './utils';

export enum RulesActionType {
  DeleteFilterOnActiveRuleByIdx = 'deleteFilterOnActiveRuleByIdx',
  DeleteRoleOnActiveRuleByIdx = 'deleteRoleOnActiveRuleByIdx',
  PushFilterOnActiveRule = 'pushFilterOnActiveRule',
  PushRoleOnActiveRule = 'pushRoleOnActiveRule',
  ReplaceFilterOnActiveRuleByIdx = 'replaceFilterOnActiveRuleByIdx',
  ReplaceRoleOnActiveRuleByIdx = 'replaceRoleOnActiveRuleByIdx',
  SetActiveRule = 'setActiveRule',
  SwapRolesOnActiveRule = 'swapRoleOnActiveRule',
  PushRolesOnActiveRule = 'pushRolesOnActiveRule',
}

/* eslint-disable @typescript-eslint/no-shadow */
export interface RulesDispatchActions {
  deleteFilterOnActiveRuleByIdx: ({ idx }: { idx: number }) => void;
  deleteRoleOnActiveRuleByIdx: ({ idx }: { idx: number }) => void;
  pushFilterOnActiveRule: (filter: MongoDBRuleFilter) => void;
  pushRoleOnActiveRule: (role: MongoDBRuleRole) => void;
  replaceFilterOnActiveRuleByIdx: ({ filter, idx }: { filter: MongoDBRuleFilter; idx: number }) => void;
  replaceRoleOnActiveRuleByIdx: ({ role, idx }: { role: MongoDBRuleRole; idx: number }) => void;
  setActiveRule: (activeRule: DataSourceRule) => void;
  swapRolesOnActiveRule: ({ idx, swapIdx }: { idx: number; swapIdx: number }) => void;
  pushRolesOnActiveRule: (role: MongoDBRuleRole[]) => void;
}

export type RulesState = { activeRule: DataSourceRule };

type Action =
  | { type: RulesActionType.DeleteFilterOnActiveRuleByIdx; payload: { idx: number } }
  | { type: RulesActionType.DeleteRoleOnActiveRuleByIdx; payload: { idx: number } }
  | { type: RulesActionType.PushFilterOnActiveRule; payload: MongoDBRuleFilter }
  | { type: RulesActionType.PushRoleOnActiveRule; payload: MongoDBRuleRole }
  | { type: RulesActionType.PushRolesOnActiveRule; payload: MongoDBRuleRole[] }
  | { type: RulesActionType.ReplaceFilterOnActiveRuleByIdx; payload: { filter: MongoDBRuleFilter; idx: number } }
  | { type: RulesActionType.ReplaceRoleOnActiveRuleByIdx; payload: { role: MongoDBRuleRole; idx: number } }
  | { type: RulesActionType.SetActiveRule; payload: DataSourceRule }
  | { type: RulesActionType.SwapRolesOnActiveRule; payload: { idx: number; swapIdx: number } };

// TODO: Add more active rule actions and accompanying tests
export const rulesPageReducer = (state: RulesState, action: ReducerAction<Reducer<RulesState, Action>>): RulesState => {
  switch (action.type) {
    case RulesActionType.SetActiveRule:
      return { activeRule: action.payload ? deepCopyRule(action.payload) : action.payload };

    case RulesActionType.PushFilterOnActiveRule:
      return { activeRule: { ...state.activeRule, filters: [...(state.activeRule?.filters || []), action.payload] } };

    case RulesActionType.ReplaceFilterOnActiveRuleByIdx: {
      if (state.activeRule?.filters) {
        const currActiveRuleFilters = state.activeRule?.filters;
        currActiveRuleFilters[action.payload.idx] = action.payload.filter;
        return {
          activeRule: {
            ...state.activeRule,
            filters: currActiveRuleFilters,
          },
        };
      }
      return state;
    }

    case RulesActionType.PushRolesOnActiveRule:
      return { activeRule: { ...state.activeRule, roles: [...(state.activeRule?.roles || []), ...action.payload] } };

    case RulesActionType.DeleteFilterOnActiveRuleByIdx: {
      if (state.activeRule?.filters) {
        const currActiveRuleFilters = state.activeRule?.filters;
        currActiveRuleFilters.splice(action.payload.idx, 1);
        return {
          activeRule: {
            ...state.activeRule,
            filters: currActiveRuleFilters.length === 0 ? undefined : currActiveRuleFilters,
          },
        };
      }
      return state;
    }
    case RulesActionType.DeleteRoleOnActiveRuleByIdx: {
      if (state.activeRule?.roles) {
        const currActiveRuleRoles = state.activeRule?.roles;
        currActiveRuleRoles.splice(action.payload.idx, 1);
        return {
          activeRule: {
            ...state.activeRule,
            roles: currActiveRuleRoles.length === 0 ? undefined : currActiveRuleRoles,
          },
        };
      }
      return state;
    }
    case RulesActionType.PushRoleOnActiveRule:
      return { activeRule: { ...state.activeRule, roles: [...(state.activeRule?.roles || []), action.payload] } };

    case RulesActionType.ReplaceRoleOnActiveRuleByIdx: {
      if (state.activeRule?.roles) {
        const currActiveRuleRoles = state.activeRule?.roles;
        currActiveRuleRoles[action.payload.idx] = action.payload.role;
        return {
          activeRule: {
            ...state.activeRule,
            roles: currActiveRuleRoles,
          },
        };
      }
      return state;
    }

    case RulesActionType.SwapRolesOnActiveRule: {
      if (state.activeRule?.roles) {
        const { idx, swapIdx } = action.payload;
        const currActiveRuleRoles = state.activeRule?.roles;

        const newActiveRuleRoles = currActiveRuleRoles.map((val, i) => {
          if (i === idx) return currActiveRuleRoles[swapIdx];
          if (i === swapIdx) return currActiveRuleRoles[idx];
          return val;
        });

        return {
          activeRule: {
            ...state.activeRule,
            roles: newActiveRuleRoles,
          },
        };
      }
      return state;
    }

    default:
      throw new Error(`Unknown action type`);
  }
};

export const setActiveRule = (dispatch: Dispatch<Action>) => (payload: DataSourceRule) =>
  dispatch({ type: RulesActionType.SetActiveRule, payload });

export const pushFilterOnActiveRule = (dispatch: Dispatch<Action>) => (payload: MongoDBRuleFilter) =>
  dispatch({ type: RulesActionType.PushFilterOnActiveRule, payload });

export const replaceFilterOnActiveRuleByIdx =
  (dispatch: Dispatch<Action>) => (payload: { filter: MongoDBRuleFilter; idx: number }) => {
    dispatch({ type: RulesActionType.ReplaceFilterOnActiveRuleByIdx, payload });
  };

export const deleteFilterOnActiveRuleByIdx = (dispatch: Dispatch<Action>) => (payload: { idx: number }) => {
  dispatch({ type: RulesActionType.DeleteFilterOnActiveRuleByIdx, payload });
};

export const deleteRoleOnActiveRuleByIdx = (dispatch: Dispatch<Action>) => (payload: { idx: number }) => {
  dispatch({ type: RulesActionType.DeleteRoleOnActiveRuleByIdx, payload });
};

export const swapRolesOnActiveRule = (dispatch: Dispatch<Action>) => (payload: { idx: number; swapIdx: number }) => {
  dispatch({ type: RulesActionType.SwapRolesOnActiveRule, payload });
};
export const pushRolesOnActiveRule = (dispatch: Dispatch<Action>) => (payload: MongoDBRuleRole[]) =>
  dispatch({ type: RulesActionType.PushRolesOnActiveRule, payload });

export const pushRoleOnActiveRule = (dispatch: Dispatch<Action>) => (payload: MongoDBRuleRole) =>
  dispatch({ type: RulesActionType.PushRoleOnActiveRule, payload });

export const replaceRoleOnActiveRuleByIdx =
  (dispatch: Dispatch<Action>) => (payload: { role: MongoDBRuleRole; idx: number }) => {
    dispatch({ type: RulesActionType.ReplaceRoleOnActiveRuleByIdx, payload });
  };
