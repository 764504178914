import React from 'react';

import './expand-arrows-icon.less';

const baseClassName = 'expand-arrows-icon';

export default function ExpandArrowsIcon() {
  return (
    <div className={`${baseClassName}-container`}>
      <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1">
        <title>fontawesome-free-5.5.0/Solid/expand-arrows-alt</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="06.1_Scenario-4,-Screen-1" transform="translate(-576.000000, -800.000000)" className={baseClassName}>
            <g id="In-Page-example" transform="translate(397.000000, 121.000000)">
              <g id="Modal-/Default" transform="translate(36.000000, 657.000000)">
                <g id="fontawesome-free-5.5.0/Solid/expand-arrows-alt" transform="translate(138.000000, 17.000000)">
                  <g id="expand-arrows-alt" transform="translate(5.000000, 5.000000)">
                    <path
                      d="M14.7481564,10.2 L14.7481564,13.8615385 C14.7481564,14.2963462 14.3959911,14.6461538 13.9582529,14.6461538 L10.2720366,14.6461538 C9.56770601,14.6461538 9.21554071,13.7994231 9.71252165,13.3057692 L10.9039594,12.1223077 L7.37243257,8.61769231 L3.84419698,12.1255769 L5.03563474,13.3057692 C5.53261569,13.7994231 5.18045038,14.6461538 4.47611977,14.6461538 L0.789903489,14.6461538 C0.352165306,14.6461538 0,14.2963462 0,13.8615385 L0,10.2 C0,9.50038462 0.852437515,9.15057692 1.34941846,9.64423077 L2.54085622,10.8276923 L6.07238307,7.32307692 L2.54085622,3.81519231 L1.34941846,5.00192308 C0.852437515,5.49557692 0,5.14576923 0,4.44615385 L0,0.784615385 C0,0.349807692 0.352165306,0 0.789903489,0 L4.47611977,0 C5.18045038,0 5.53261569,0.846730769 5.03563474,1.34038462 L3.84419698,2.52384615 L7.37243257,6.02846154 L10.9039594,2.52057692 L9.71252165,1.34038462 C9.21554071,0.846730769 9.56770601,0 10.2720366,0 L13.9582529,0 C14.3959911,0 14.7481564,0.349807692 14.7481564,0.784615385 L14.7481564,4.44615385 C14.7481564,5.14576923 13.8957189,5.49557692 13.3987379,5.00192308 L12.2073002,3.81846154 L8.67577332,7.32307692 L12.2073002,10.8309615 L13.3987379,9.6475 C13.8957189,9.15057692 14.7481564,9.50038462 14.7481564,10.2 Z"
                      id="Path"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
