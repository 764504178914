import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Template')
export class Template {
  @JsonProperty('id')
  public id = '';

  @JsonProperty('name')
  public name = '';
}
