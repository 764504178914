import React, { useMemo } from 'react';

import { MongoDBBaseRule, MongoDBNamespaceRule, PartialServiceDesc, PresetRole } from 'admin-sdk';

import {
  BulkDeleteDatabase,
  BulkDeleteDataSource,
  DeleteDefaultRule,
  DeleteOptions,
  DeleteRule,
} from './delete-rules-modal';
import { RulesDispatchActions } from './rulesPageReducer';
import {
  DataSourceRule,
  DefaultRulesByDataSourceId,
  PartialRulesByDataSourceId,
  SyncIncompatibleRolesByDataSourceId,
} from './types';

export interface RulesPageContext {
  activeRule?: MongoDBBaseRule | MongoDBNamespaceRule;
  createCollection: (
    collectionName: string,
    databaseName: string,
    dataSourceId: string,
    dataSourceName: string,
    newRule: MongoDBNamespaceRule
  ) => void;
  defaultRulesByDataSourcesId: DefaultRulesByDataSourceId;
  deletingCollections: Set<string>;
  deletingDatabases: Set<string>;
  deletingDatasources: Set<string>;
  deletingDSDefaultRules: Set<string>;
  hasChanges: boolean;
  hasClientValidationError: boolean;
  isSavingRule: boolean;
  onClickCreateCollection: (dataSourceName: string, databaseName?: string) => void;
  onClickDeleteAction: (deleteOptions: DeleteOptions) => void;
  onDeleteDefaultRule: (deleteOptions: DeleteDefaultRule) => void;
  onDeleteRule: (deleteOptions: DeleteRule) => void;
  onDeleteRuleByDatabase: (deleteOptions: BulkDeleteDatabase) => void;
  onDeleteRuleByDatasource: (deleteOptions: BulkDeleteDataSource) => void;
  partialDataSources: PartialServiceDesc[];
  partialRulesByDataSourceId: PartialRulesByDataSourceId;
  presetRoles: PresetRole[];
  pristineRule?: MongoDBBaseRule | MongoDBNamespaceRule;
  pushError: (errMsg: string) => void;
  ruleSaveError?: string;
  rulesDispatchActions: RulesDispatchActions;
  saveRule: () => void;
  selectedDataSource?: string;
  selectedPresetRole?: PresetRole;
  setHasClientValidationError: React.Dispatch<React.SetStateAction<boolean>>;
  setOnDiscardChanges: React.Dispatch<React.SetStateAction<Function>>;
  setOnSaveSuccess: React.Dispatch<React.SetStateAction<Function>>;
  setPristineRule: React.Dispatch<React.SetStateAction<DataSourceRule>>;
  setRuleSaveError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedDataSource: React.Dispatch<React.SetStateAction<string | undefined>>;
  syncIncompatibleRolesByDataSourceId: SyncIncompatibleRolesByDataSourceId;
  syncIncompatibleFields: string[];
  setSyncIncompatibleFields: React.Dispatch<React.SetStateAction<string[]>>;
}

export function createRulesPageContext() {
  return React.createContext<RulesPageContext>({
    createCollection: () => {},
    defaultRulesByDataSourcesId: {},
    deletingCollections: new Set(),
    deletingDatabases: new Set(),
    deletingDatasources: new Set(),
    deletingDSDefaultRules: new Set(),
    hasChanges: false,
    hasClientValidationError: false,
    isSavingRule: false,
    onClickCreateCollection: () => {},
    onClickDeleteAction: () => {},
    onDeleteDefaultRule: () => {},
    onDeleteRule: () => {},
    onDeleteRuleByDatabase: () => {},
    onDeleteRuleByDatasource: () => {},
    partialDataSources: [],
    partialRulesByDataSourceId: {},
    presetRoles: [],
    pushError: () => {},
    saveRule: () => {},
    rulesDispatchActions: {
      deleteFilterOnActiveRuleByIdx: () => {},
      deleteRoleOnActiveRuleByIdx: () => {},
      pushFilterOnActiveRule: () => {},
      pushRoleOnActiveRule: () => {},
      replaceFilterOnActiveRuleByIdx: () => {},
      replaceRoleOnActiveRuleByIdx: () => {},
      setActiveRule: () => {},
      swapRolesOnActiveRule: () => {},
      pushRolesOnActiveRule: () => {},
    },
    setHasClientValidationError: () => {},
    setOnDiscardChanges: () => () => {},
    setOnSaveSuccess: () => {},
    setPristineRule: () => {},
    setRuleSaveError: () => {},
    setSelectedDataSource: () => {},
    syncIncompatibleRolesByDataSourceId: {},
    syncIncompatibleFields: [],
    setSyncIncompatibleFields: () => {},
  });
}

export type Props = React.PropsWithChildren<RulesPageContext>;

export function createRulesPageContextProvider(context: React.Context<RulesPageContext>) {
  return function RulesPageContextProvider({
    activeRule,
    children,
    createCollection,
    defaultRulesByDataSourcesId,
    deletingCollections,
    deletingDatabases,
    deletingDatasources,
    deletingDSDefaultRules,
    hasChanges,
    hasClientValidationError,
    isSavingRule,
    onClickCreateCollection,
    onClickDeleteAction,
    onDeleteDefaultRule,
    onDeleteRule,
    onDeleteRuleByDatabase,
    onDeleteRuleByDatasource,
    partialDataSources,
    partialRulesByDataSourceId,
    presetRoles,
    pristineRule,
    pushError,
    ruleSaveError,
    rulesDispatchActions,
    saveRule,
    selectedDataSource,
    selectedPresetRole,
    setHasClientValidationError,
    setOnDiscardChanges,
    setOnSaveSuccess,
    setPristineRule,
    setRuleSaveError,
    setSelectedDataSource,
    syncIncompatibleRolesByDataSourceId,
    syncIncompatibleFields,
    setSyncIncompatibleFields,
  }: Props) {
    const values = useMemo(
      () => ({
        activeRule,
        createCollection,
        defaultRulesByDataSourcesId,
        deletingCollections,
        deletingDatabases,
        deletingDatasources,
        deletingDSDefaultRules,
        hasChanges,
        hasClientValidationError,
        isSavingRule,
        onClickCreateCollection,
        onClickDeleteAction,
        onDeleteDefaultRule,
        onDeleteRule,
        onDeleteRuleByDatabase,
        onDeleteRuleByDatasource,
        partialDataSources,
        partialRulesByDataSourceId,
        presetRoles,
        pristineRule,
        pushError,
        ruleSaveError,
        rulesDispatchActions,
        saveRule,
        selectedDataSource,
        selectedPresetRole,
        setHasClientValidationError,
        setOnDiscardChanges,
        setOnSaveSuccess,
        setPristineRule,
        setRuleSaveError,
        setSelectedDataSource,
        syncIncompatibleRolesByDataSourceId,
        syncIncompatibleFields,
        setSyncIncompatibleFields,
      }),
      [
        activeRule,
        defaultRulesByDataSourcesId,
        deletingCollections,
        deletingDatabases,
        deletingDatasources,
        deletingDSDefaultRules,
        hasChanges,
        isSavingRule,
        partialDataSources,
        partialRulesByDataSourceId,
        presetRoles,
        pristineRule,
        ruleSaveError,
        selectedDataSource,
        selectedPresetRole,
        hasClientValidationError,
        syncIncompatibleRolesByDataSourceId,
        syncIncompatibleFields,
        setSyncIncompatibleFields,
      ]
    );
    return <context.Provider value={values}>{children}</context.Provider>;
  };
}
