import React from 'react';

import { IconProps } from './types';

export enum TestSelector {
  Icon = 'icon',
}

const XamarinIcon = ({ className, height = 25, width = 25 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={TestSelector.Icon}
    >
      <path
        d="M5.7708 0C5.23064 0.00117187 4.70189 0.307812 4.43002 0.775546L0.200801 8.12211C-0.0669336 8.59109 -0.0669336 9.20414 0.200801 9.67312L4.43002 17.0198C4.70189 17.4875 5.23064 17.7942 5.7708 17.7953H14.2292C14.7694 17.7941 15.2981 17.4875 15.57 17.0198L19.7992 9.67312C20.067 9.20414 20.0669 8.59109 19.7992 8.12211L15.57 0.775546C15.2981 0.307734 14.7694 0.00109375 14.2292 0H5.7708ZM5.84759 4.29789C5.85924 4.29672 5.87142 4.29672 5.88306 4.29789H7.34197C7.37439 4.29894 7.40601 4.30817 7.43391 4.32473C7.4618 4.34128 7.48505 4.36462 7.5015 4.39258L9.97642 8.80289C9.98892 8.82476 9.99689 8.84898 9.99986 8.87398C10.003 8.84898 10.011 8.82476 10.0235 8.80289L12.4924 4.39258C12.5094 4.36383 12.5336 4.33999 12.5626 4.32339C12.5916 4.30679 12.6244 4.29801 12.6578 4.29789H14.1168C14.246 4.29898 14.3427 4.46172 14.2822 4.57609L11.8663 8.89765L14.2822 13.2133C14.3484 13.3283 14.2494 13.4985 14.1168 13.4974H12.6578C12.6238 13.4967 12.5906 13.487 12.5616 13.4693C12.5326 13.4517 12.5087 13.4266 12.4924 13.3968L10.0235 8.9864C10.011 8.96461 10.003 8.94031 9.99994 8.91539C9.9969 8.94041 9.9889 8.96458 9.97642 8.98648L7.50142 13.3968C7.48569 13.4258 7.46277 13.4503 7.43487 13.4679C7.40696 13.4855 7.375 13.4957 7.34205 13.4974H5.88299C5.75041 13.4986 5.65134 13.3283 5.7176 13.2133L8.13345 8.89765L5.7176 4.57609C5.6597 4.47172 5.73033 4.32015 5.84752 4.29789H5.84759Z"
        fill="#3498DB"
      />
    </svg>
  );
};

export default XamarinIcon;
