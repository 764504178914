import { JsonObject, JsonProperty } from 'json2typescript';

import { PartialApp } from './App';

@JsonObject('PartialDraft')
export class PartialDraft {
  @JsonProperty('_id', String)
  public id = '';

  @JsonProperty('user_id', String)
  public userId = '';

  @JsonProperty('app', PartialApp)
  public app: PartialApp = new PartialApp();

  constructor(partial?: Partial<PartialDraft>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AppAssetDiff')
export class AppAssetDiff {
  @JsonProperty('added', [String])
  public added: string[] = [];

  @JsonProperty('deleted', [String])
  public deleted: string[] = [];

  @JsonProperty('modified', [String])
  public modified: string[] = [];

  constructor(partial?: Partial<AppAssetDiff>) {
    Object.assign(this, partial);
  }
}

@JsonObject('DependencyData')
export class DependencyData {
  @JsonProperty('name')
  public name = '';

  @JsonProperty('version')
  public version = '';

  constructor(partial?: Partial<DependencyData>) {
    Object.assign(this, partial);
  }
}

@JsonObject('DependencyDiffData')
export class DependencyDiffData {
  @JsonProperty('name')
  public name = '';

  @JsonProperty('version')
  public version = '';

  @JsonProperty('previous_version')
  public previousVersion = '';

  constructor(partial?: Partial<DependencyDiffData>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AppDependenciesDiff')
export class AppDependenciesDiff {
  @JsonProperty('added', [DependencyData])
  public added: DependencyData[] = [];

  @JsonProperty('deleted', [DependencyData])
  public deleted: DependencyData[] = [];

  @JsonProperty('modified', [DependencyData])
  public modified: DependencyDiffData[] = [];

  constructor(partial?: Partial<AppDependenciesDiff>) {
    Object.assign(this, partial);
  }
}

@JsonObject('FieldDiff')
export class FieldDiff {
  @JsonProperty('field_name', String)
  public field = '';

  @JsonProperty('previous')
  public previousValue: any = undefined;

  @JsonProperty('updated')
  public updatedValue: any = undefined;

  constructor(partial?: Partial<FieldDiff>) {
    Object.assign(this, partial);
  }
}

@JsonObject('SchemaOptionsDiff')
export class SchemaOptionsDiff {
  @JsonProperty('graphql_validation_diff', [FieldDiff])
  public graphQLValidationDiffs: FieldDiff[] = [];

  @JsonProperty('rest_validation_diff', [FieldDiff])
  public restValidationDiffs: FieldDiff[] = [];

  @JsonProperty('null_type_schema_validation_diff', FieldDiff)
  public nullTypeSchemaValidationDiff?: FieldDiff = undefined;

  constructor(partial?: Partial<SchemaOptionsDiff>) {
    Object.assign(this, partial);
  }
}

@JsonObject('GraphQLConfigDiff')
export class GraphQLConfigDiff {
  @JsonProperty('field_diffs', [FieldDiff])
  public fieldDiffs: FieldDiff[] = [];

  constructor(partial?: Partial<GraphQLConfigDiff>) {
    Object.assign(this, partial);
  }
}

@JsonObject('DraftDiff')
export class DraftDiff {
  @JsonProperty('diffs', [String])
  public diffs: string[] = [];

  @JsonProperty('hosting_files_diff', AppAssetDiff)
  public hostingFilesDiff: AppAssetDiff = new AppAssetDiff();

  @JsonProperty('dependencies_diff', AppDependenciesDiff)
  public dependenciesDiff: AppDependenciesDiff = new AppDependenciesDiff();

  @JsonProperty('graphql_config_diff', GraphQLConfigDiff)
  public graphQLConfigDiff: GraphQLConfigDiff = new GraphQLConfigDiff();

  @JsonProperty('schema_options_diff', SchemaOptionsDiff)
  public schemaOptionsDiff: SchemaOptionsDiff = new SchemaOptionsDiff();

  constructor(partial?: Partial<DraftDiff>) {
    Object.assign(this, partial);
  }
}
