import React, { useEffect, useRef } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Label } from '@leafygreen-ui/typography';
import { useThemeValue } from '@mongodb-js/darkreader';
import { select as d3Select } from 'd3-selection';

import { metricsColorCode, styledMetricsColor } from 'baas-ui/app/metrics-card/colors';
import { displayUnits, drawRoundedRectangle, scaleValue, Size } from 'baas-ui/app/metrics-card/utils';
import { useDarkMode } from 'baas-ui/theme';

export enum TestSelector {
  Chart = 'gauge-chart',
  TotalValue = 'total-value',
  GaugeTitle = 'gauge-title',
  SuccessValue = 'success-value',
  FailValue = 'fail-value',
}

export interface Props {
  success: number;
  fail: number;
  title: string;
  dims?: GaugeChartDimensions;
}

interface GaugeChartDimensions extends Size {
  dividerHeight: number;
  dividerMargin: number;
  labelPaddingTop: number;
  valuePaddingTop: number;
}

export const MINI_DIMENSIONS: GaugeChartDimensions = {
  height: 75,
  width: 130,
  dividerHeight: 0,
  dividerMargin: 2,
  labelPaddingTop: 0,
  valuePaddingTop: 2,
};

export const REGULAR_DIMENSIONS: GaugeChartDimensions = {
  height: 160,
  width: 275,
  dividerHeight: 1,
  dividerMargin: 15,
  labelPaddingTop: 6,
  valuePaddingTop: 9,
};

const barHeight = 8;
const barRadius = 4;
const barSpacer = 2;

const classBarEmpty = 'bar-empty';
const classBarSuccess = 'bar-success';
const classBarFail = 'bar-fail';
const classBarSpacer = 'bar-spacer';

const StyledContainer = styled.div<{ dims: GaugeChartDimensions }>`
  height: ${({ dims }) => dims.height}px;
  width: ${({ dims }) => dims.width}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDivider = styled.hr<{ dims: GaugeChartDimensions }>`
  height: ${({ dims }) => dims.dividerHeight}px;
  background-color: ${styledMetricsColor('light-gray-2')};
  border: none;
  margin: ${({ dims }) => dims.dividerMargin}px 0;
`;

const StyledChartHeader = styled(Body)`
  color: ${styledMetricsColor('dark-gray-2')};
`;

const StyledTotalValue = styled(Label)`
  color: ${styledMetricsColor('dark-gray-2')};
  font-size: 15px;
  text-align: right;
`;

const StyledChartLabel = styled(StyledChartHeader, { shouldForwardProp: (prop) => isPropValid(prop) })<{
  alignRight?: boolean;
  dims: GaugeChartDimensions;
}>`
  font-size: 11px;
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
  padding-top: ${({ dims }) => dims.labelPaddingTop}px;
`;

const StyledValue = styled(Label, { shouldForwardProp: (prop) => isPropValid(prop) || prop === 'weight' })<{
  fail?: boolean;
  alignRight?: boolean;
  dims: GaugeChartDimensions;
}>`
  color: ${({ fail }) => styledMetricsColor(fail ? 'red' : 'dark-gray-2')};
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
  padding-top: ${({ dims }) => dims.valuePaddingTop}px;
`;

const StyledLegend = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLegendItem = styled.div`
  display: flex;
  flex-direction: column;
`;

interface GaugeGraphicProps {
  success: number;
  fail: number;
  width: number;
}

export const GaugeGraphic = ({ success, fail, width }: GaugeGraphicProps) => {
  const theme = useTheme();
  const darkMode = useDarkMode();
  const themeValue = useThemeValue(darkMode);
  const spacerFill = themeValue({ dark: 'grayDark1', light: 'white' });

  const svgRef = useRef<SVGSVGElement>(null);
  const total = success === -1 || fail === -1 ? 0 : success + fail;

  useEffect(() => {
    // draw chart on changes to success and fail props
    const svg = d3Select(svgRef.current).attr('width', width).attr('height', barHeight);

    if (total === 0) {
      svg
        .select(`.${classBarEmpty}`)
        .call((path) =>
          path
            .style('fill', metricsColorCode(theme, 'light-gray-2'))
            .attr('d', () => drawRoundedRectangle({ x: 0, y: 0 }, { width, height: barHeight }, barRadius, 'both'))
        );
      return;
    }

    const failureBarWidth = (fail / total) * (width - barSpacer);
    const successBarWidth = (success / total) * (width - barSpacer);

    if (success !== 0) {
      svg
        .select(`.${classBarSuccess}`)
        .call((path) =>
          path
            .style('fill', metricsColorCode(theme, darkMode ? 'light-gray-3' : 'dark-gray-1'))
            .attr('d', () =>
              drawRoundedRectangle(
                { x: 0, y: 0 },
                { width: successBarWidth, height: barHeight },
                barRadius,
                fail === 0 ? 'both' : 'left'
              )
            )
        );
    }

    if (fail !== 0) {
      svg
        .select(`.${classBarFail}`)
        .call((path) =>
          path
            .style('fill', metricsColorCode(theme, darkMode ? 'light-red' : 'red'))
            .attr('d', () =>
              drawRoundedRectangle(
                { x: successBarWidth + barSpacer, y: 0 },
                { width: failureBarWidth, height: barHeight },
                barRadius,
                success === 0 ? 'both' : 'right'
              )
            )
        );
    }

    svg
      .select(`.${classBarSpacer}`)
      .call((rect) =>
        rect.style('fill', spacerFill).attr('width', barSpacer).attr('height', barHeight).attr('x', successBarWidth)
      );
  }, [success, fail]);

  return (
    <svg ref={svgRef}>
      <path className={classBarEmpty} />
      <path className={classBarSuccess} />
      <path className={classBarFail} />
      <rect className={classBarSpacer} />
    </svg>
  );
};

export const GaugeChart = ({ title, success, fail, dims = REGULAR_DIMENSIONS }: Props) => {
  success = Math.round(success);
  fail = Math.round(fail);
  const total = success === -1 || fail === -1 ? 0 : success + fail;

  return (
    <StyledContainer dims={dims} data-testid={TestSelector.Chart}>
      <StyledLegend>
        <StyledChartHeader data-testid={TestSelector.GaugeTitle}>{title}</StyledChartHeader>
        <StyledTotalValue data-testid={TestSelector.TotalValue}>
          {success === -1 || fail === -1 ? 'N/A' : displayUnits(scaleValue().fromOnes(total).auto())}
        </StyledTotalValue>
      </StyledLegend>
      <StyledDivider dims={dims} />
      <GaugeGraphic success={success} fail={fail} width={dims!.width} />
      <StyledLegend>
        <StyledLegendItem>
          <StyledValue dims={dims} data-testid={TestSelector.SuccessValue}>
            {success === -1 ? 'N/A' : displayUnits(scaleValue().fromOnes(success).auto())}
          </StyledValue>
          <StyledChartLabel dims={dims}>Success</StyledChartLabel>
        </StyledLegendItem>
        <StyledLegendItem>
          <StyledValue dims={dims} alignRight fail={fail > 0} data-testid={TestSelector.FailValue}>
            {fail === -1 ? 'N/A' : displayUnits(scaleValue().fromOnes(fail).auto())}
          </StyledValue>
          <StyledChartLabel dims={dims} alignRight>
            Fail
          </StyledChartLabel>
        </StyledLegendItem>
      </StyledLegend>
    </StyledContainer>
  );
};

export default GaugeChart;
