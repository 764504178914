import React from 'react';

import { passThroughProps } from 'baas-ui/common/utils/util';

const EmptyLogsIcon = ({ ...rest }) => (
  <svg width="59px" height="55px" viewBox="0 0 59 55" version="1.1" {...passThroughProps(rest)}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="00" transform="translate(-753.000000, -310.000000)" fillRule="nonzero">
        <g id="dash-overview-zero" transform="translate(753.000000, 310.000000)">
          <path
            d="M26,14 L24,14 L1,14 C0.44771525,14 0,13.5522847 0,13 C0,12.4477153 0.44771525,12 1,12 L58,12 C58.5522847,12 59,12.4477153 59,13 C59,13.5522847 58.5522847,14 58,14 L26,14 Z"
            id="Combined-Shape"
            fill="#116149"
          />
          <path
            d="M57,30 L57,7 C57,4.23917118 54.761602,2 52,2 L7,2 C4.24028475,2 2,4.24028475 2,7 L2,37 C2,39.7597153 4.24028475,42 7,42 L28,42 L28,30.000355 L57,30 Z M57,30.0003072 L28,30.0047622 L28,42 L29,42 C29.5522847,42 30,42.4477153 30,43 C30,43.5522847 29.5522847,44 29,44 L28,44 L7,44 C3.13571525,44 0,40.8642847 0,37 L0,7 C0,3.13571525 3.13571525,0 7,0 L52,0 C55.8663262,0 59,3.13475628 59,7 L59,30 L59,33 C59,33.5522847 58.5522847,34 58,34 C57.4477153,34 57,33.5522847 57,33 L57,30.0003072 Z"
            id="Combined-Shape"
            fill="#116149"
          />
          <path
            d="M52.8,41.4 C52.8,37.3130985 49.4863293,34 45.4021,34 C41.3129067,34 38,37.3117627 38,41.4 C38,45.4869013 41.3136708,48.8 45.4021,48.8 C49.4855653,48.8 52.8,45.4855653 52.8,41.4 Z M54.8,41.4 C54.8,46.5901347 50.5901347,50.8 45.4021,50.8 C40.2091786,50.8 36,46.5915481 36,41.4 C36,36.2070386 40.2084918,32 45.4021,32 C50.5908215,32 54.8,36.2084517 54.8,41.4 Z"
            id="Stroke-2333"
            fill="#116149"
          />
          <path
            d="M50.6319032,48.0461168 C50.2413789,47.6555925 50.2413789,47.0224275 50.6319032,46.6319032 C51.0224275,46.2413789 51.6555925,46.2413789 52.0461168,46.6319032 L58.7073168,53.2931032 C59.0978411,53.6836275 59.0978411,54.3167925 58.7073168,54.7073168 C58.3167925,55.0978411 57.6836275,55.0978411 57.2931032,54.7073168 L50.6319032,48.0461168 Z"
            id="Stroke-2334"
            fill="#116149"
          />
          <path
            d="M8.5,5.5 C7.672,5.5 7,6.172 7,7 C7,7.828 7.672,8.5 8.5,8.5 C9.328,8.5 10,7.828 10,7 C10,6.172 9.328,5.5 8.5,5.5 Z"
            id="Shape"
            fill="#15CC62"
          />
          <path
            d="M14.5,5.5 C13.672,5.5 13,6.172 13,7 C13,7.828 13.672,8.5 14.5,8.5 C15.328,8.5 16,7.828 16,7 C16,6.172 15.328,5.5 14.5,5.5 Z"
            id="Shape"
            fill="#15CC62"
          />
          <path
            d="M20.5,5.5 C19.672,5.5 19,6.172 19,7 C19,7.828 19.672,8.5 20.5,8.5 C21.328,8.5 22,7.828 22,7 C22,6.172 21.328,5.5 20.5,5.5 Z"
            id="Shape"
            fill="#15CC62"
          />
          <rect id="Rectangle-2" fill="#15CC62" x="7" y="19" width="8" height="2" rx="1" />
          <rect id="Rectangle-2-Copy-3" fill="#15CC62" x="7" y="33" width="5" height="2" rx="1" />
          <rect id="Rectangle-2-Copy" fill="#116149" x="24" y="26" width="7" height="2" rx="1" />
          <rect id="Rectangle-2" fill="#116149" x="19" y="19" width="15" height="2" rx="1" />
          <rect id="Rectangle-2-Copy-4" fill="#15CC62" x="16" y="33" width="16" height="2" rx="1" />
          <rect id="Rectangle-2" fill="#15CC62" x="38" y="19" width="14" height="2" rx="1" />
          <rect id="Rectangle-2-Copy-2" fill="#116149" x="35" y="26" width="17" height="2" rx="1" />
          <rect id="Rectangle-2" fill="#116149" x="7" y="26" width="13" height="2" rx="1" />
        </g>
      </g>
    </g>
  </svg>
);

export default EmptyLogsIcon;
