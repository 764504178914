import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Badge from '@leafygreen-ui/badge';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import { Cell, Row, Table, TableHeader } from '@leafygreen-ui/table-legacy';
import Tooltip from '@leafygreen-ui/tooltip';
import { H2 } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import { redirectTo } from 'baas-ui/actions';
import { DataSourceType } from 'baas-ui/common/components/data-source-select';
import { ItemListLoadingWrapper } from 'baas-ui/common/components/loading-wrapper';
import ToJS from 'baas-ui/common/components/to-js';
import { AsyncDispatch, AsyncExecutorPayload } from 'baas-ui/redux_util';
import { getAppState, getServiceState } from 'baas-ui/selectors';
import * as svcactions from 'baas-ui/services/actions';
import { NON_ATLAS_CLUSTER_NAME } from 'baas-ui/services/mongodb/constants';
import LinkDataSourceEmptyState from 'baas-ui/services/mongodb/link-data-source-empty-state';
import {
  ClusterServiceConfig,
  ClusterType,
  DataLakeServiceConfig,
  MDBServiceById,
  MDBServiceConfigById,
} from 'baas-ui/services/mongodb/types';
import {
  isAtlasMongoService,
  isDataLakeServiceType,
  isSelfHostedOrAtlasMongoService,
  MongoDataSourceType,
} from 'baas-ui/services/registry';
import * as svcSagas from 'baas-ui/services/sagas';
import * as syncSagas from 'baas-ui/sync/sagas';
import { getSyncConfigFromServiceConfig, RawSyncConfig, SyncType } from 'baas-ui/sync/types';
import { useDarkMode } from 'baas-ui/theme';
import { Track, track, usePageLoadTracker } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import urls, { UrlObj } from 'baas-ui/urls';
import { SyncState } from 'admin-sdk';

import ClusterActionsDropdown from './cluster-actions-dropdown';
import DataLakeActionsDropdown from './data-lake-actions-dropdown';
import UnlinkDataSourceConfirm from './unlink-data-source-confirm';

import 'baas-ui/common/styles/table.less';
import './data-source-list.less';

export enum TestSelector {
  DataSourceTable = 'data-source-table',
  DataSourceNameColumn = 'data-source-name-column',
  DataSourceNameCell = 'data-source-name-cell',
  DataSourceDescriptionColumn = 'data-source-description-column',
  DataSourceDescriptionCell = 'data-source-description-cell',
  DataSourceActionsColumn = 'data-source-actions-column',
  DataSourceActionsCell = 'data-source-actions-cell',
  LinkButton = 'data-source-link-button',
  UnlinkDataSourceModal = 'unlink-data-source-modal',
}

export interface SvcDataForTable {
  svcId: string;
  dataSourceName: string;
  svcName: string;
  syncConfig?: RawSyncConfig;
  serviceType: MongoDataSourceType;
}

interface ReduxStateProps {
  appName: string;
  clustersUrlRoot: UrlObj;
  servicesUrlRoot: UrlObj;
  svcConfigsById: MDBServiceConfigById;
  loadingSvcs: boolean;
  error?: string;
  svcsById: MDBServiceById;
}

interface ReduxDispatchProps {
  loadMongoServiceConfigs(): Promise<void>;
  deleteService(svcId: string): void;
  clearErrors(): void;
  terminateSync(
    svcId: string,
    rawConfig: RawSyncConfig,
    syncType: SyncType
  ): AsyncExecutorPayload<typeof svcactions.saveSvcConfig>;
  onClickRow(svcId: string): void;
  navigateTo(url: string): void;
}

export type Props = ReduxStateProps & ReduxDispatchProps;

export function DataSourceListComponent({
  appName = '',
  error = undefined,
  svcsById = {},
  svcConfigsById = {},
  loadingSvcs = false,
  clustersUrlRoot,
  servicesUrlRoot,
  loadMongoServiceConfigs,
  deleteService,
  terminateSync,
  onClickRow,
  navigateTo,
  clearErrors,
}: Props) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [unlinkDataSourceModalOpen, setUnlinkDataSourceModalOpen] = React.useState(false);
  const [dataSourceToDelete, setDataSourceToDelete] = React.useState<SvcDataForTable>();

  const darkMode = useDarkMode();

  usePageLoadTracker('CLUSTER_LINK.LIST_VIEWED');

  React.useEffect(() => {
    loadMongoServiceConfigs().then(() => {
      setIsLoading(false);
    });

    return () => {
      clearErrors();
    };
  }, []);

  const handleUnlinkDataSource = (svcId = '', syncConfig?: RawSyncConfig) => {
    if (syncConfig && syncConfig.state !== SyncState.Terminated) {
      const svcConfig = svcConfigsById[svcId];
      const fullSyncConfig = getSyncConfigFromServiceConfig(svcConfig);
      if (fullSyncConfig.type) {
        track('SYNC_CONFIGURE.SYNC_TERMINATE_SUBMITTED');
        return terminateSync(svcId, syncConfig, fullSyncConfig.type).then(() => deleteService(svcId));
      }
    }
    return deleteService(svcId);
  };

  const handleDataSourceTypeBadge = (service: SvcDataForTable) => {
    if (service.serviceType === MongoDataSourceType.DataFederation) {
      return service.dataSourceName.includes(DataSourceType.ONLINE_ARCHIVE) ? 'Online Archive' : 'Federated DB';
    }
    const config = svcConfigsById[service.svcId]?.config as ClusterServiceConfig;

    switch (config?.clusterType) {
      case ClusterType.Flex:
        return 'Flex Cluster';
      case ClusterType.Serverless:
        return 'Serverless';
      default:
        return 'Cluster';
    }
  };

  const mongoConfigs: SvcDataForTable[] = Object.entries(svcsById)
    .filter(([_, svc]) => isSelfHostedOrAtlasMongoService(svc.type))
    .map(([id, svc]) => {
      const config = svcConfigsById[id]?.config as ClusterServiceConfig;
      return {
        svcId: id,
        dataSourceName: isAtlasMongoService(svc.type) && config ? config.clusterName : NON_ATLAS_CLUSTER_NAME,
        svcName: svc.name,
        syncConfig: config?.flexible_sync ? config.flexible_sync : config?.sync,
        serviceType: isAtlasMongoService(svc.type) ? MongoDataSourceType.Atlas : MongoDataSourceType.SelfHosted,
      };
    });

  const dataLakeConfigs: SvcDataForTable[] = Object.entries(svcsById)
    .filter(([_, svc]) => isDataLakeServiceType(svc.type))
    .map(([id, svc]) => {
      const config = svcConfigsById[id]?.config as DataLakeServiceConfig;

      return {
        svcId: id,
        dataSourceName: config?.dataLakeName,
        svcName: svc.name,
        serviceType: MongoDataSourceType.DataFederation,
      };
    });

  const svcData: SvcDataForTable[] = [...mongoConfigs, ...dataLakeConfigs];

  const hasServices = !(loadingSvcs || isLoading) && svcData.length > 0;

  return (
    <div>
      {error && <Banner variant={Variant.Danger}>{error}</Banner>}
      <UnlinkDataSourceConfirm
        data-testid={TestSelector.UnlinkDataSourceModal}
        dataSource={dataSourceToDelete}
        open={unlinkDataSourceModalOpen}
        appName={appName}
        onCancel={() => {
          setUnlinkDataSourceModalOpen(false);
        }}
        onConfirm={() => {
          setUnlinkDataSourceModalOpen(false);
          handleUnlinkDataSource(dataSourceToDelete?.svcId, dataSourceToDelete?.syncConfig);
        }}
      />
      <div className="section-header">
        <div className="section-header section-header-title section-header-title-function-list">
          <H2 data-cy="data-sources-title-text">Linked Data Sources</H2>
          {hasServices && (
            <div className="section-header-title-controls">
              <Track event="CLUSTER_LINK.LINK_CLUSTER_CLICKED">
                <Link to={clustersUrlRoot.new()}>
                  <Button
                    variant="primary"
                    data-testid={TestSelector.LinkButton}
                    data-test-selector={TestSelector.LinkButton}
                    data-cy="data-source-header-link-button"
                  >
                    Link a Data Source
                  </Button>
                </Link>
              </Track>
            </div>
          )}
        </div>
      </div>
      <ItemListLoadingWrapper
        hasItems={hasServices}
        isLoading={loadingSvcs || isLoading}
        emptyState={
          <LinkDataSourceEmptyState
            description="Link a data source to your application"
            title="Link your Data Source."
            actionTitle="Link a Data Source"
          />
        }
      >
        <Table
          data={svcData}
          data-test-selector={TestSelector.DataSourceTable}
          data-testid={TestSelector.DataSourceTable}
          data-cy={TestSelector.DataSourceTable}
          columns={[
            <TableHeader<SvcDataForTable>
              label={
                <>
                  Service Name
                  <Tooltip
                    className="data-source-list-tooltip"
                    align="top"
                    trigger={
                      <span className="tooltip-indicator tooltip-indicator-small tooltip-indicator-primary">
                        <Icon title="" glyph="InfoWithCircle" size="small" />
                      </span>
                    }
                    triggerEvent="hover"
                  >
                    Functions and SDKs use the Service Name to reference your data source.
                  </Tooltip>
                </>
              }
              sortBy={(datum) => datum.svcName}
              data-test-selector={TestSelector.DataSourceNameColumn}
            />,
            <TableHeader label="Atlas Data Source" data-test-selector={TestSelector.DataSourceDescriptionColumn} />,
            <TableHeader label="Actions" data-test-selector={TestSelector.DataSourceActionsColumn} />,
          ]}
        >
          {({ datum }) => (
            <Row
              onClick={() => onClickRow(datum.svcId)}
              className={classNames('clickable-row', {
                'clickable-row-light-mode': !darkMode,
                'clickable-row-dark-mode': darkMode,
              })}
            >
              <Cell
                key={datum.svcName}
                data-cy={TestSelector.DataSourceNameCell}
                data-test-selector={TestSelector.DataSourceNameCell}
              >
                {`${datum.svcName} `}
                <Badge variant="lightgray" className="data-source-list-type-badge">
                  {handleDataSourceTypeBadge(datum)}
                </Badge>
              </Cell>
              <Cell
                key="atlas-path"
                data-cy={TestSelector.DataSourceDescriptionCell}
                data-test-selector={TestSelector.DataSourceDescriptionCell}
              >
                {datum.dataSourceName}
              </Cell>
              <Cell key="actions" data-test-selector={TestSelector.DataSourceActionsCell}>
                {datum.serviceType === MongoDataSourceType.DataFederation ? (
                  <DataLakeActionsDropdown
                    editConfig={() => navigateTo(servicesUrlRoot.service(datum.svcId).config().get())}
                    unlink={() => {
                      setDataSourceToDelete(datum);
                      setUnlinkDataSourceModalOpen(true);
                    }}
                  />
                ) : (
                  <ClusterActionsDropdown
                    editConfig={() => navigateTo(servicesUrlRoot.service(datum.svcId).config().get())}
                    editRule={() => navigateTo(clustersUrlRoot.cluster(datum.svcId).rules().list())}
                    unlink={() => {
                      setDataSourceToDelete(datum);
                      setUnlinkDataSourceModalOpen(true);
                    }}
                  />
                )}
              </Cell>
            </Row>
          )}
        </Table>
      </ItemListLoadingWrapper>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  const {
    app: { id: appId, groupId, name: appName },
    error,
  } = getAppState(state);
  const { svcConfigsById, loadServiceError, configLoadError, deleteSvcError, svcsById, loadingSvcs, configSaveError } =
    getServiceState(state);

  const clustersUrlRoot = urls.groups().group(groupId).apps().app(appId).clusters();

  const servicesUrlRoot = urls.groups().group(groupId).apps().app(appId).services();

  return {
    appId,
    groupId,
    appName,
    clustersUrlRoot,
    servicesUrlRoot,
    svcConfigsById,
    loadingSvcs,
    error: error || loadServiceError || configLoadError || deleteSvcError || configSaveError,
    svcsById: Object.keys(svcsById).reduce((acc, key) => ({ ...acc, [key]: svcsById[key] }), {}),
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  loadMongoServiceConfigs: (groupId: string, appId: string) =>
    dispatch(svcSagas.loadMongoServiceConfigs(groupId, appId)),
  deleteService: (groupId: string, appId: string, svcId: string) =>
    dispatch(svcactions.deleteSvc({ groupId, appId, svcId })),
  clearErrors: () => dispatch(svcactions.clearErrors()),
  terminateSync: (groupId: string, appId: string, svcId: string, rawConfig: RawSyncConfig, syncType: SyncType) =>
    dispatch(syncSagas.terminateSyncServiceConfigRaw(groupId, appId, svcId, rawConfig, syncType, false)),
  onClickRow: (url: string) => dispatch(redirectTo(url, { replace: false })),
  navigateTo: (url: string) => dispatch(redirectTo(url)),
});

const mergeProps = (
  { groupId, appId, servicesUrlRoot, ...otherStateProps }: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>
): Props => ({
  servicesUrlRoot,
  ...otherStateProps,
  ...dispatchProps,
  loadMongoServiceConfigs: () => dispatchProps.loadMongoServiceConfigs(groupId, appId),
  deleteService: (svcId: string) => dispatchProps.deleteService(groupId, appId, svcId),
  terminateSync: (svcId: string, rawConfig: RawSyncConfig, syncType: SyncType) =>
    dispatchProps.terminateSync(groupId, appId, svcId, rawConfig, syncType),
  onClickRow: (svcId: string) => dispatchProps.onClickRow(servicesUrlRoot.service(svcId).config().get()),
  navigateTo: (url: string) => dispatchProps.navigateTo(url),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ToJS(DataSourceListComponent));
