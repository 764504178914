import { createAction } from 'redux-act';

import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import {
  AccessList,
  AllowedIPToStore,
  AuthProviderConfig,
  ListedAllowedIP,
  PartialAuthProviderConfig,
} from 'admin-sdk';

import { makeKeyActions } from './apikeys';

interface ProviderConfigPayload extends BaseRequestPayload {
  providerConfig: AuthProviderConfig;
}

export interface ProviderIdPayload extends BaseRequestPayload {
  providerId: string;
}

interface OriginsPayload extends BaseRequestPayload {
  newOrigins: string[];
}

interface CreateIPAccessPayload extends BaseRequestPayload {
  allowedIP: AllowedIPToStore;
}

interface UpdateIPAccessPayload extends BaseRequestPayload {
  id: string;
  allowedIP: AllowedIPToStore;
}

interface DeleteIPAccessPayload extends BaseRequestPayload {
  allowedIP: ListedAllowedIP;
}

export const NAME = 'auth/';

export const apiKeyActions = makeKeyActions(NAME);
export const clearErrors = createAction(`${NAME}clear errors`);

export const removeAllowedRequestOrigin = createAction<{ index: number }>(`${NAME}remove allowed request origin`);
export const cancelEditingAllowedRequestOrigins = createAction(`${NAME}cancel editing allowed request origins`);
export const addAllowedRequestOrigin = createAction(`${NAME}add allowed request origin`);
export const setAllowedRequestOriginInput = createAction<{ index: number; input: string }>(
  `${NAME}set allowed request origin input`
);

export const [loadProvidersActions, loadProviders] = createActionsAndExecutor<
  BaseRequestPayload,
  PartialAuthProviderConfig[]
>(
  `${NAME}load providers`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).authProviders().list()
);

export const [enableProviderActions, enableProvider] = createActionsAndExecutor<ProviderConfigPayload, void>(
  `${NAME} enable provider`,
  (client, { groupId, appId, providerConfig }) =>
    () =>
      client.apps(groupId).app(appId).authProviders().authProvider(providerConfig.id!).enable()
);

export const [disableProviderActions, disableProvider] = createActionsAndExecutor<ProviderConfigPayload, void>(
  `${NAME} disable provider`,
  (client, { groupId, appId, providerConfig }) =>
    () =>
      client.apps(groupId).app(appId).authProviders().authProvider(providerConfig.id!).disable()
);

export const [getProviderActions, getProvider] = createActionsAndExecutor<ProviderIdPayload, AuthProviderConfig>(
  `${NAME}get provider`,
  (client, { groupId, appId, providerId }) =>
    () =>
      client.apps(groupId).app(appId).authProviders().authProvider(providerId).get()
);

export const [getDeployedProviderActions, getDeployedProvider] = createActionsAndExecutor<
  ProviderIdPayload,
  AuthProviderConfig
>(`${NAME}get deployed provider`, (client, { groupId, appId, providerId }) => () => {
  return client.apps(groupId).app(appId).authProviders().authProvider(providerId).getDeployed();
});

export const [addProviderActions, addProvider] = createActionsAndExecutor<ProviderConfigPayload, AuthProviderConfig>(
  `${NAME}add provider`,
  (client, { groupId, appId, providerConfig }) =>
    () =>
      client.apps(groupId).app(appId).authProviders().create(providerConfig)
);

export const [updateProviderActions, updateProvider] = createActionsAndExecutor<ProviderConfigPayload, void>(
  `${NAME}update provider`,
  (client, { groupId, appId, providerConfig }) =>
    () =>
      client.apps(groupId).app(appId).authProviders().authProvider(providerConfig.id!).update(providerConfig)
);

export const [loadAllowedRequestOriginsActions, loadAllowedRequestOrigins] = createActionsAndExecutor<
  BaseRequestPayload,
  String[]
>(
  `${NAME}load allowed request origins`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).security().allowedRequestOrigins().get()
);

export const [saveAllowedRequestOriginsActions, saveAllowedRequestOrigins] = createActionsAndExecutor<
  OriginsPayload,
  void
>(
  `${NAME}save allowed request origins`,
  (client, { groupId, appId, newOrigins }) =>
    () =>
      client.apps(groupId).app(appId).security().allowedRequestOrigins().update(newOrigins)
);

export const [loadIPEntriesActions, loadIPEntries] = createActionsAndExecutor<BaseRequestPayload, AccessList>(
  `${NAME}load IP Entries`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).security().accessList().get()
);

export const [createIPEntryActions, createIPEntry] = createActionsAndExecutor<CreateIPAccessPayload, ListedAllowedIP>(
  `${NAME}create IP Entry`,
  (client, { groupId, appId, allowedIP }) =>
    () =>
      client.apps(groupId).app(appId).security().accessList().create(allowedIP)
);

export const [deleteIPEntryActions, deleteIPEntry] = createActionsAndExecutor<DeleteIPAccessPayload, void>(
  `${NAME}delete IP Entry`,
  (client, { groupId, appId, allowedIP }) =>
    () =>
      client.apps(groupId).app(appId).security().accessList().allowedIP(allowedIP.id).delete()
);

export const [updateIPEntryActions, updateIPEntry] = createActionsAndExecutor<UpdateIPAccessPayload, ListedAllowedIP>(
  `${NAME}update IP Entry`,
  (client, { groupId, appId, id, allowedIP }) =>
    () =>
      client.apps(groupId).app(appId).security().accessList().allowedIP(id).update(allowedIP)
);

export const asyncEditRcvActions = [
  addProviderActions.rcv,
  disableProviderActions.rcv,
  enableProviderActions.rcv,
  updateProviderActions.rcv,
  saveAllowedRequestOriginsActions.rcv,
];
