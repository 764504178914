import React from 'react';
import { connect } from 'react-redux';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import Modal from '@leafygreen-ui/modal';
import { H3 } from '@leafygreen-ui/typography';

import { createDownloadFromBlob } from 'baas-ui/common/utils/util';
import * as actions from 'baas-ui/deploy/actions';
import DeploymentNameEditor from 'baas-ui/deploy/deployment-name-editor';
import ExportDownloadBanner from 'baas-ui/deploy/export-page/export-download-banner/ExportDownloadBanner';
import { DraftAction } from 'baas-ui/deploy/types';
import { AsyncDispatch, AsyncExecutorPayload } from 'baas-ui/redux_util';
import { getAppState, getDeploymentState } from 'baas-ui/selectors';
import { Track, usePageLoadTracker } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import { rootUrl } from 'baas-ui/urls';
import { DraftDiff, PartialDraft } from 'admin-sdk';

import ApplicationDiff from './application-diff';
import DependenciesDiff from './dependencies-diff';
import GraphQLConfigDiff from './graphql-config-diff';
import HostingDiff from './hosting-diff';
import NullTypeSchemaValidationDiff from './null-type-schema-validation-diff';

import 'baas-ui/deploy/export-page/export-page.less';
import './confirm-deployment-diff.less';

export enum TestSelector {
  ConfirmModal = 'deployment-diff-confirm-modal',
  DiscardButton = 'discard-button',
  DeployButton = 'deploy-button',
  ExportButton = 'export-button',
  Title = 'title',
  GitHubInfoBanner = 'github-info-banner',
  ErrorBanner = 'diff-loading-error-banner',
  DownloadBanner = 'export-draft-download-banner',
}

interface ReduxStateProps {
  loadingDiff?: boolean;
  diff?: DraftDiff;
  draft?: PartialDraft;
  dependenciesUrl: string;
  appName: string;
  automaticDeploymentEnabled: boolean;
}

interface ReduxDispatchProps {
  loadDiff(draftId: string): AsyncExecutorPayload<typeof actions.loadDiff>;
  pullApp(): Promise<Response | void>;
}

export interface PublicProps {
  isOpen: boolean;
  onCancel(): any;
  onDiscard(): any;
  onDeploy(deploymentName?: string): any;
}

export type Props = ReduxStateProps & ReduxDispatchProps & PublicProps;

const baseClassName = 'deployment-diff';

const fetchAndCreateDownloadFromBlob = (fetchFile: () => Promise<Response | void>, filename: string) => {
  fetchFile()
    .then((response: Response) => response.blob())
    .then((blob: Blob) => createDownloadFromBlob(blob, filename, `${baseClassName}-header`))
    .catch(() => {
      // TODO(BAAS-18456) do something with this error
    });
};

export function ConfirmDeploymentDiffComponent({
  automaticDeploymentEnabled,
  dependenciesUrl,
  diff,
  draft,
  isOpen,
  loadDiff,
  loadingDiff,
  onCancel,
  onDiscard,
  onDeploy,
  pullApp,
  appName,
}: Props) {
  usePageLoadTracker('DRAFT_DEPLOY.REVIEW_VIEWED');

  const [isExporting, setIsExporting] = React.useState(false);
  const [deploymentName, setDeploymentName] = React.useState('');
  const [errMessage, setErrMessage] = React.useState('');

  React.useEffect(() => {
    const loadDiffAsync = async (draftId: string) => {
      setErrMessage('');
      try {
        await loadDiff(draftId);
      } catch (err) {
        setErrMessage(err?.message);
      }
    };

    if (draft) {
      loadDiffAsync(draft.id);
    }
  }, [draft]);

  if (loadingDiff) {
    return null;
  }

  const handleExport = () => {
    setIsExporting(true);
    fetchAndCreateDownloadFromBlob(pullApp, appName);
    setTimeout(() => setIsExporting(false), 2500);
  };

  return (
    <Modal
      data-testid={TestSelector.ConfirmModal}
      open={isOpen}
      setOpen={(open) => {
        if (!open) {
          onCancel();
        }
      }}
      className={`${baseClassName}-modal`}
      size="large"
      data-cy={`${baseClassName}-modal`}
      initialFocus={`.${baseClassName}-header`}
    >
      <div className={`${baseClassName}-header`}>
        <div className={`${baseClassName}-header-text`}>
          <H3>Deployment Draft</H3>
          <span className={`${baseClassName}-header-name-editor`}>
            <DeploymentNameEditor
              deploymentName={deploymentName}
              onChange={setDeploymentName}
              emptyNamePlaceholder={<i>Set a deployment name</i>}
              tooltipText="Set a custom deployment name, or leave this blank so your deployment name will be set to its ID."
            />
          </span>
        </div>
        <div className={`${baseClassName}-export`}>
          <Button
            data-testid={TestSelector.ExportButton}
            className={`${baseClassName}-export-button`}
            onClick={handleExport}
            leftGlyph={<Icon glyph="Export" />}
            disabled={!!errMessage}
          >
            Export State
          </Button>
          {isExporting && (
            <ExportDownloadBanner
              data-testid={TestSelector.DownloadBanner}
              className={`${baseClassName}-export-download-banner`}
            />
          )}
        </div>
      </div>
      {errMessage ? (
        <Banner data-testid={TestSelector.ErrorBanner} style={{ marginBottom: '20px' }} variant={Variant.Danger}>
          {`Error when generating diff: ${errMessage}. Please discard your draft and try again.`}
        </Banner>
      ) : (
        <>
          {automaticDeploymentEnabled && (
            <Banner
              variant={Variant.Info}
              data-testid={TestSelector.GitHubInfoBanner}
              className={`${baseClassName}-github-info-banner`}
            >
              If you deploy this draft, the changes will be pushed to your GitHub repo.
            </Banner>
          )}
          {!!diff && (
            <>
              <ApplicationDiff applicationDiff={diff.diffs} />
              <GraphQLConfigDiff
                graphqlDiff={diff.graphQLConfigDiff}
                graphqlValidationDiffs={diff.schemaOptionsDiff.graphQLValidationDiffs}
              />
              <NullTypeSchemaValidationDiff diff={diff.schemaOptionsDiff.nullTypeSchemaValidationDiff} />
              <HostingDiff hostingDiff={diff.hostingFilesDiff} />
              <DependenciesDiff dependenciesDiff={diff.dependenciesDiff} dependenciesUrl={dependenciesUrl} />
            </>
          )}
        </>
      )}
      <div className={`${baseClassName}-actions`}>
        <Track event="DRAFT_DEPLOY.REVIEW_FINISHED" properties={{ review_type: DraftAction.DISCARD }}>
          <Button
            data-testid={TestSelector.DiscardButton}
            data-cy="deploy-modal-discard-button"
            onClick={onDiscard}
            variant="danger"
            leftGlyph={<Icon glyph="Trash" />}
          >
            Discard Draft
          </Button>
        </Track>
        <Track event="DRAFT_DEPLOY.REVIEW_FINISHED" properties={{ review_type: DraftAction.DEPLOY }}>
          <Button
            data-testid={TestSelector.DeployButton}
            data-cy="deploy-modal-deploy-button"
            variant="primary"
            onClick={() => onDeploy(deploymentName)}
            leftGlyph={<Icon glyph="Checkmark" />}
            disabled={!!errMessage}
          >
            Deploy {automaticDeploymentEnabled && 'and Push to GitHub'}
          </Button>
        </Track>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state: RootState) => {
  const { groupId, id: appId, name: appName } = getAppState(state).app;
  const { diff, loadingDiff, draft, deployConfig } = getDeploymentState(state);
  const dependenciesUrl = rootUrl.groups().group(groupId).apps().app(appId).dependencies().list();

  return {
    appId,
    groupId,
    appName,
    draft,
    loadingDiff,
    diff,
    dependenciesUrl,
    automaticDeploymentEnabled: deployConfig.automaticDeploymentConfig.enabled,
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  loadDiff: (groupId: string, appId: string, draftId: string) =>
    dispatch(actions.loadDiff({ groupId, appId, draftId })),
  pullApp: (groupId: string, appId: string) => dispatch(actions.pullApp({ groupId, appId })),
});

const mergeProps = (
  { groupId, appId, ...otherStateProps }: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: Omit<Props, keyof (ReduxDispatchProps & ReduxStateProps)>
): Props => ({
  ...otherStateProps,
  ...dispatchProps,
  ...ownProps,
  loadDiff: (draftId: string) => dispatchProps.loadDiff(groupId, appId, draftId),
  pullApp: () => dispatchProps.pullApp(groupId, appId),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ConfirmDeploymentDiffComponent);
