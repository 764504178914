/* eslint-disable camelcase */
import { OptionProps } from 'react-select/src/types';

import { ValidationAction, ValidationLevel } from 'admin-sdk';

export enum GraphQLReadyState {
  READY,
  NEED_SERVICE,
  NEED_SCHEMAS,
}

export interface ValidationLevelOptionTypes extends Partial<OptionProps> {
  label: string;
  value: ValidationLevel;
}

export interface ValidationActionOptionTypes extends Partial<OptionProps> {
  label: string;
  value: ValidationAction;
}
