import { createAction } from 'redux-act';

import { AsyncDispatch, createActionsAndExecutor } from 'baas-ui/redux_util';
import { ServiceRequestPayload } from 'baas-ui/services/actions';
import { BuiltinRule, MongoDBNamespaceRule, PartialBuiltinRule, PartialMongoDBNamespaceRule, Rule } from 'admin-sdk';

const NAME = 'default service/';

export interface DefaultRuleRequestPayload extends ServiceRequestPayload {
  ruleId: string;
}

export interface AddRuleRequestPayload extends ServiceRequestPayload {
  rule: Rule;
}

export interface UpdateRuleRequestPayload extends DefaultRuleRequestPayload {
  rule: Rule;
}

export interface SetRulePayload {
  ruleId: string;
}

export interface SetRuleWhenPayload extends SetRulePayload {
  value: any;
}

export interface SetRuleActionPayload extends SetRulePayload {
  action: any;
  enabled: boolean;
}

export function makeBaseActions(name: string) {
  const setRuleWhen = createAction<SetRuleWhenPayload>(`${name}set default svc rule when`);

  const setRuleAction = createAction<SetRuleActionPayload>(`${name}set default svc rule action`);

  const discardChanges = createAction<SetRulePayload>(`${name}discard default svc rule changes`);

  const [loadRulesActions, loadRules] = createActionsAndExecutor<
    ServiceRequestPayload,
    (PartialMongoDBNamespaceRule | PartialBuiltinRule)[]
  >(
    `${name}load default svc rules`,
    (client, { groupId, appId, svcId }) =>
      () =>
        client.apps(groupId).app(appId).services().service(svcId).rules().list()
  );

  const [loadRuleActions, loadRule] = createActionsAndExecutor<
    DefaultRuleRequestPayload,
    MongoDBNamespaceRule | BuiltinRule
  >(
    `${name}load default svc rule`,
    (client, { groupId, appId, svcId, ruleId }) =>
      () =>
        client.apps(groupId).app(appId).services().service(svcId).rules().rule(ruleId).get()
  );

  const [addRuleActions, addRule] = createActionsAndExecutor<
    AddRuleRequestPayload,
    PartialMongoDBNamespaceRule | PartialBuiltinRule
  >(
    `${name}add default svc rule`,
    (client, { groupId, appId, svcId, rule }) =>
      () =>
        client.apps(groupId).app(appId).services().service(svcId).rules().create(rule)
  );

  const [updateRuleActions, updateRule] = createActionsAndExecutor<UpdateRuleRequestPayload, void>(
    `${name}update default svc rule`,
    (client, { groupId, appId, svcId, ruleId, rule }) =>
      () =>
        client.apps(groupId).app(appId).services().service(svcId).rules().rule(ruleId).update(rule)
  );

  const [deleteRuleActions, deleteRule] = createActionsAndExecutor<DefaultRuleRequestPayload, void>(
    `${name}delete default svc rule`,
    (client, { groupId, appId, svcId, ruleId }) =>
      () =>
        client.apps(groupId).app(appId).services().service(svcId).rules().rule(ruleId).remove()
  );

  interface RulesEditProps {
    app: {
      groupId: string;
      id: string;
    };
    service: {
      id: string;
    };
  }

  return {
    loadRulesActions,
    loadRuleActions,
    addRuleActions,
    updateRuleActions,
    deleteRuleActions,
    setRuleWhen,
    setRuleAction,
    discardChanges,
    loadRules,
    loadRule,
    addRule,
    rulesEditMapper: {
      mapDispatchToProps: (dispatch: AsyncDispatch, ownProps: RulesEditProps) => {
        const groupId = ownProps.app.groupId;
        const appId = ownProps.app.id;
        const svcId = ownProps.service.id;

        return {
          loadRule: (ruleId: string) => dispatch(loadRule({ groupId, appId, svcId, ruleId })),
          loadRules: () => dispatch(loadRules({ groupId, appId, svcId })),
          addRule: (rule: Rule) => dispatch(addRule({ groupId, appId, svcId, rule })),
          deleteRule: (ruleId: string) =>
            dispatch(deleteRule({ groupId, appId, svcId, ruleId })).then(() => loadRules({ groupId, appId, svcId })),
          updateRule: (ruleId: string, rule: Rule) =>
            dispatch(
              updateRule({
                groupId,
                appId,
                svcId,
                ruleId,
                rule,
              })
            ),
        };
      },
    },
  };
}

const defaultActions = makeBaseActions(NAME);
export default defaultActions;
