import { Any, JsonConvert, JsonObject, JsonProperty } from 'json2typescript';

const jsonConvert: JsonConvert = new JsonConvert();

@JsonObject('GraphQLConfig')
export class GraphQLConfig {
  @JsonProperty('use_natural_pluralization')
  public useNaturalPluralization = false;

  constructor(partial?: Partial<GraphQLConfig>) {
    Object.assign(this, partial);
  }
}

export enum GraphQLAlertType {
  Mongo = 'mongo',
  Custom = 'custom',
}

@JsonObject('GraphQLAlert')
export class GraphQLAlert {
  @JsonProperty('error_code')
  public errorCode = '';

  @JsonProperty('message')
  public message = '';

  @JsonProperty('details', Any, true)
  public details?: Record<string, any> = undefined;

  constructor(partial?: Partial<GraphQLAlert>) {
    Object.assign(this, partial);
  }
}

export type FormatType = 'custom' | 'generated' | 'generated-list' | 'scalar' | 'scalar-list';

@JsonObject('CustomResolver')
export class CustomResolver {
  @JsonProperty('_id', String, true)
  public id?: string = undefined;

  @JsonProperty('function_id')
  public functionId = '';

  @JsonProperty('on_type')
  public onType = '';

  @JsonProperty('field_name')
  public fieldName = '';

  @JsonProperty('input_type', Any, true)
  public inputType?: any = undefined;

  @JsonProperty('input_type_format', String, true)
  public inputTypeFormat?: FormatType = undefined;

  @JsonProperty('payload_type', Any, true)
  public payloadType?: any = undefined;

  @JsonProperty('payload_type_format', String, true)
  public payloadTypeFormat?: FormatType = undefined;

  constructor(partial?: Partial<CustomResolver>) {
    Object.assign(this, partial);
  }
}

@JsonObject('BaseGraphQLAlerts')
export class BaseGraphQLAlerts {
  @JsonProperty('type')
  public type: GraphQLAlertType = GraphQLAlertType.Mongo;

  @JsonProperty('display_name')
  public displayName = '';

  @JsonProperty('warnings', [GraphQLAlert])
  public warnings: GraphQLAlert[] = [];

  @JsonProperty('errors', [GraphQLAlert])
  public errors: GraphQLAlert[] = [];

  constructor(partial?: Partial<BaseGraphQLAlerts>) {
    Object.assign(this, partial);
  }
}

@JsonObject('MongoAlerts')
export class MongoAlerts extends BaseGraphQLAlerts {
  @JsonProperty('type')
  public type: GraphQLAlertType = GraphQLAlertType.Mongo;

  @JsonProperty('service_id')
  public serviceId = '';

  @JsonProperty('rule_id', String, true)
  public ruleId?: string = undefined;

  @JsonProperty('schema_id')
  public schemaId = '';

  constructor(partial?: Partial<MongoAlerts>) {
    super();
    Object.assign(this, partial);
  }
}

@JsonObject('CustomResolverAlert')
export class CustomResolverAlerts extends BaseGraphQLAlerts {
  @JsonProperty('type')
  public type: GraphQLAlertType = GraphQLAlertType.Custom;

  @JsonProperty('custom_resolver_id')
  public customResolverId = '';

  constructor(partial?: Partial<CustomResolverAlerts>) {
    super();
    Object.assign(this, partial);
  }
}

export type GraphQLAlerts = MongoAlerts | CustomResolverAlerts;

export const deserializeGraphQLAlerts = (data: any): GraphQLAlerts => {
  switch (data.type) {
    case GraphQLAlertType.Mongo:
      return jsonConvert.deserializeObject(data, MongoAlerts);
    case GraphQLAlertType.Custom:
      return jsonConvert.deserializeObject(data, CustomResolverAlerts);
    default:
      throw new Error('unable to deserialize GraphQL alert');
  }
};

@JsonObject('ExtendableTypes')
export class ExtendableTypes {
  @JsonProperty('root_types')
  public rootTypes: string[] = [];

  @JsonProperty('generated_types')
  public generatedTypes: string[] = [];

  @JsonProperty('generated_input_types')
  public generatedInputTypes: string[] = [];

  constructor(partial?: Partial<ExtendableTypes>) {
    Object.assign(this, partial);
  }
}
