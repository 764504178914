import React from 'react';
import { connect } from 'react-redux';
import Toast, { Variant } from '@leafygreen-ui/toast';

import * as baseActions from 'baas-ui/actions';
import * as dataAPIActions from 'baas-ui/endpoints/data-api/actions';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { getAppState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';
import rootUrl from 'baas-ui/urls';

import './data-api-enabled-toast.less';

const baseClassName = 'data-api-enabled-toast';

export enum TestSelector {
  RulesLink = 'rules-link',
  ToastBody = 'toast-body',
}

export interface ReduxProps {
  open: boolean;
  rulesURL: string;
  hideDataAPIEnabledToast(): void;
  redirectTo(url: string): void;
}

export const DataAPIEnabledToast = ({ open, rulesURL, hideDataAPIEnabledToast, redirectTo }: ReduxProps) => {
  return (
    <Toast
      data-cy="data-api-enabled-toast"
      className={baseClassName}
      variant={Variant.Success}
      title="Data API Enabled"
      body={
        <span className={`${baseClassName}-body`} data-testid={TestSelector.ToastBody}>
          <span className={`${baseClassName}-body-description`}>Continue building your app by setting up Rules</span>
          <span
            data-testid={TestSelector.RulesLink}
            className={`${baseClassName}-body-link`}
            onClick={() => redirectTo(rulesURL)}
          >
            GO TO RULES
          </span>
        </span>
      }
      open={open}
      close={hideDataAPIEnabledToast}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    app: { groupId, id: appId },
  } = getAppState(state);
  const open = state.dataAPI.isDataAPIEnabledToastOpen;
  const appUrl = rootUrl.groups().group(groupId).apps().app(appId);
  const rulesURL = appUrl.rules().list();

  return { open, rulesURL };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  redirectTo: (url: string) => dispatch(baseActions.redirectTo(url)),
  hideDataAPIEnabledToast: () => dispatch(dataAPIActions.hideDataAPIEnabledToast()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataAPIEnabledToast);
