import JSON5 from 'json5';

import { prettyJSONStringify } from 'baas-ui/common/utils/util';
import {
  BuiltinRule,
  deserializeBaseRule,
  deserializeRule,
  MongoDBBaseRule,
  MongoDBNamespaceRule,
  MongoDBRuleField,
  MongoDBRuleFilter,
  MongoDBRuleRole,
  RulesConverter,
} from 'admin-sdk';

import { FILTERS_LIMIT } from './constants';
import { ROLES_LIMIT } from './constants';
import { PermissionDisplayValue } from './permission-display';
import { DataSourceRule, NamespacesByClusterId } from './types';

export const getCountsFromNamespaces = (namespacesByClusterId: NamespacesByClusterId) => {
  let databaseCount = 0;
  let collectionCount = 0;

  const clusterIds = Object.keys(namespacesByClusterId);
  const clusterCount = clusterIds.length;

  clusterIds.forEach((clusterId) => {
    namespacesByClusterId[clusterId].forEach((namespace) => {
      databaseCount++;
      collectionCount += namespace.collections.length;
    });
  });

  return { clusterCount, databaseCount, collectionCount };
};

export const formatExplorerNamespace = (datasourceName?: string, dbName?: string, collName?: string) => {
  return `${datasourceName}:${dbName}:${collName}`;
};

export const isNamespaceRule = (
  rule: MongoDBNamespaceRule | BuiltinRule | MongoDBBaseRule
): rule is MongoDBNamespaceRule => {
  return !!(rule as MongoDBNamespaceRule).database;
};

export enum PermissionValue {
  Enabled,
  Disabled,
  FieldLevel,
}

export const resolvePermissionValue = (permission: any): PermissionValue => {
  if (!permission) {
    return PermissionValue.Disabled;
  }
  if (typeof permission === 'boolean' && permission) {
    return PermissionValue.Enabled;
  }
  return PermissionValue.FieldLevel;
};

export interface FieldPermissionValues {
  read: PermissionValue;
  write: PermissionValue;
}

export const resolveFieldPermissionValue = (role: MongoDBRuleRole): FieldPermissionValues => {
  if (role.fields && Object.keys(role.fields).length) {
    return {
      read: PermissionValue.FieldLevel,
      write: PermissionValue.FieldLevel,
    };
  }
  if (role.write) {
    return {
      read: PermissionValue.Enabled,
      write: PermissionValue.Enabled,
    };
  }
  if (role.read) {
    return {
      read: PermissionValue.Enabled,
      write: PermissionValue.Disabled,
    };
  }
  if (role.read === undefined && role.write === undefined && role.additionalFields) {
    return {
      read: role.additionalFields.read ? PermissionValue.Enabled : PermissionValue.Disabled,
      write: role.additionalFields.write ? PermissionValue.Enabled : PermissionValue.Disabled,
    };
  }
  return {
    read: PermissionValue.Disabled,
    write: PermissionValue.Disabled,
  };
};

export const permissionValToDisplayValMap = new Map<PermissionValue, PermissionDisplayValue>([
  [PermissionValue.Enabled, PermissionDisplayValue.TextAll],
  [PermissionValue.Disabled, PermissionDisplayValue.TextNone],
  [PermissionValue.FieldLevel, PermissionDisplayValue.TextFieldLevel],
]);

export const deepCopyRule = (rule: MongoDBNamespaceRule | MongoDBBaseRule): MongoDBNamespaceRule | MongoDBBaseRule => {
  const filtersCopy = rule.filters?.map((f) => new MongoDBRuleFilter({ ...f }));
  const rolesCopy = rule.roles?.map((r) => new MongoDBRuleRole({ ...r }));

  if (isNamespaceRule(rule)) {
    return new MongoDBNamespaceRule({ ...rule, filters: filtersCopy, roles: rolesCopy });
  }
  return new MongoDBBaseRule({ ...rule, filters: filtersCopy, roles: rolesCopy });
};

export const isBooleanLiteralExpression = (obj) => typeof obj === 'boolean';

export function areFieldsAdvanced(fieldsObj: Record<string, MongoDBRuleField>): boolean {
  return Object.values(fieldsObj).some((fieldPermissions) => {
    const { read, write, fields, additionalFields } = fieldPermissions;
    if (!!read && !isBooleanLiteralExpression(read)) {
      return true;
    }
    if (!!write && !isBooleanLiteralExpression(write)) {
      return true;
    }
    if (!!fields || !!additionalFields) {
      // nested fields not allowed.
      return true;
    }
    return false;
  });
}

export function isRoleAdvanced(role: MongoDBRuleRole): boolean {
  const permissionNames = ['insert', 'delete', 'read', 'write', 'search'];
  if (
    permissionNames.some(
      (permissionName) => !!role[permissionName] && !isBooleanLiteralExpression(role[permissionName])
    )
  ) {
    return true;
  }
  if (role.additionalFields) {
    const { read, write } = role.additionalFields;
    if (!!read && !isBooleanLiteralExpression(read)) {
      return true;
    }
    if (!!write && !isBooleanLiteralExpression(write)) {
      return true;
    }
  }
  if (role.fields) {
    return areFieldsAdvanced(role.fields);
  }
  return false;
}

// Takes an array of roles and returns true if a "basic" Rule model will not be capable of
// fully and accurately representing the rule settings
export function isRuleAdvanced(rule: DataSourceRule) {
  // If any of the following conditions are met, then a rule must be displayed in advanced mode:
  // - Contains at least one nested field, anywhere
  // - Contains at least one expression that is not a boolean literal
  // (insert, delete, top-level read/write, field-level read/write, or additional fields read/write)
  return (
    (rule?.roles && rule.roles.some((role) => isRoleAdvanced(role))) ||
    !!(rule?.roles && rule?.roles.length > ROLES_LIMIT) ||
    !!(rule?.filters && rule?.filters.length > FILTERS_LIMIT)
  );
}

// Converts a data source rule into the correct JSON representation for the Advanced View JSON editor
// Strips all rule metadata from the rule i.e. id, database, collection
export function ruleToAdvancedJSONString(rule: DataSourceRule): string {
  if (!rule) {
    return '{}';
  }

  // TODO(BAAS-14049): Use a single rule converter from the SDK
  const serializedRule = new RulesConverter().serialize([
    isNamespaceRule(rule) ? new MongoDBNamespaceRule(rule) : new MongoDBBaseRule(rule),
  ])[0];
  delete serializedRule._id;
  if (isNamespaceRule(rule)) {
    delete serializedRule.database;
    delete serializedRule.collection;
  }

  return prettyJSONStringify(serializedRule);
}

export interface DefaultRuleMetadata {
  id?: string;
}

export interface NamespaceRuleMetadata extends DefaultRuleMetadata {
  database: string;
  collection: string;
}

export type RuleMetadata = DefaultRuleMetadata | NamespaceRuleMetadata;

export const isNamespaceRuleMetadata = (metadata: RuleMetadata): metadata is NamespaceRuleMetadata => {
  return !!(metadata as NamespaceRuleMetadata).database;
};

export const advancedRuleJSONStrToRule = (jsonRuleStr: string, metadata: RuleMetadata): DataSourceRule => {
  const parsedRule = JSON5.parse(jsonRuleStr);

  return isNamespaceRuleMetadata(metadata)
    ? deserializeRule({
        ...parsedRule,
        _id: metadata.id,
        database: metadata.database,
        collection: metadata.collection,
      })
    : deserializeBaseRule({ ...parsedRule, _id: metadata.id });
};
