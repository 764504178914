import { createAction } from 'redux-act';

import { AppsReqPayload } from 'baas-ui/home/actions';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import {
  BypassServiceChangeValue,
  CreateAtlasAPIKeyRequest,
  CreateAtlasAPIKeyResponse,
  Deployment,
  DeploymentsFilter,
  DraftDiff,
  EnableTTLInstallationAutoDeployRequest,
  GitHubBranch,
  GitHubDirectory,
  GithubGroupAuthenticationResponse,
  GithubPushAppRequest,
  Installation,
  PartialCodeDeploy,
  PartialDraft,
} from 'admin-sdk';

const NAME = 'deploy/';

export interface DeployHistoryReqPayload extends BaseRequestPayload {
  filter: DeploymentsFilter;
}

export interface LatestDeployHistoryPayload extends BaseRequestPayload {
  filter?: DeploymentsFilter;
}

export interface IndividualDraftReqPayload extends BaseRequestPayload {
  draftId: string;
  deploymentName?: string;
}

interface PullAppPayload extends BaseRequestPayload {
  deploymentId?: string;
}

interface DeployDraftPayload extends BaseRequestPayload {
  draftId: string;
  deploymentName?: string;
  bypassWarning?: BypassServiceChangeValue;
}

interface PushDraftPayload extends BaseRequestPayload {
  draftId?: string;
  deploymentName?: string;
  force?: boolean;
  bypassWarning?: BypassServiceChangeValue;
}

interface BaseDeploymentReqPayload extends BaseRequestPayload {
  deploymentId: string;
}

interface SaveDeployConfigReqPayload extends BaseRequestPayload {
  deployConfig: PartialCodeDeploy;
}

interface ListBranchesReqPayload extends BaseRequestPayload {
  repoId: string;
}

interface RenameDeploymentPayload extends BaseRequestPayload {
  deploymentId: string;
  name: string;
}

interface TTLInstallationsByRequestIdPayload extends AppsReqPayload {
  githubAuthRequestId: string;
}

interface TTLInstallationReqPayload {
  installationId: string;
  githubAuthRequestId: string;
}

type GroupTTLInstallationReqPayload = TTLInstallationReqPayload & AppsReqPayload;

type AppTTLInstallationReqPayload = TTLInstallationReqPayload & BaseRequestPayload;

interface LoadTTLInstallaionBranchesReqPayload extends GroupTTLInstallationReqPayload {
  repositoryId: string;
}

interface LoadTTLInstallaionDirectoriesReqPayload extends GroupTTLInstallationReqPayload {
  repositoryId: string;
  branch: string;
}

interface EnableTTLInstallationAutoDeployReqPayload extends AppTTLInstallationReqPayload {
  repositoryId: string;
  branch: string;
  directory: string;
}

export const resetEditStatus = createAction(`${NAME}reset edit status`);

export const [loadDeployHistoryActions, loadDeployHistory] = createActionsAndExecutor<
  DeployHistoryReqPayload,
  Deployment[]
>(
  `${NAME}load deploy history`,
  (client, { groupId, appId, filter }) =>
    () =>
      client.apps(groupId).app(appId).deploy().deployments().list(filter)
);

export const [loadLatestDeploymentActions, loadLatestDeployment] = createActionsAndExecutor<
  LatestDeployHistoryPayload,
  Deployment[]
>(
  `${NAME}load latest deployment`,
  (client, { groupId, appId, filter }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .deploy()
        .deployments()
        .list({
          before: Date.now(),
          limit: 1,
          ...filter,
        })
);

export const [loadDeploymentActions, loadDeployment] = createActionsAndExecutor<BaseDeploymentReqPayload, Deployment>(
  `${NAME}load deployment`,
  (client, { groupId, appId, deploymentId }) =>
    () =>
      client.apps(groupId).app(appId).deploy().deployments().get(deploymentId)
);

export const [pullAppActions, pullApp] = createActionsAndExecutor<PullAppPayload, Response>(
  `${NAME}pull app`,
  (client, { groupId, appId, deploymentId }) =>
    () =>
      client.apps(groupId).app(appId).pull(deploymentId)
);

export const [deleteDraftActions, deleteDraft] = createActionsAndExecutor<IndividualDraftReqPayload, void>(
  `${NAME}delete draft`,
  (client, { groupId, appId, draftId }) =>
    () =>
      client.apps(groupId).app(appId).drafts().delete(draftId)
);

export const [createDraftActions, createDraft] = createActionsAndExecutor<BaseRequestPayload, PartialDraft>(
  `${NAME}create draft`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).drafts().create()
);

export const [loadDraftsActions, loadDrafts] = createActionsAndExecutor<BaseRequestPayload, PartialDraft[]>(
  `${NAME}load drafts`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).drafts().list()
);

export const [deployDraftActions, deployDraft] = createActionsAndExecutor<DeployDraftPayload, Deployment>(
  `${NAME}deploy draft`,
  (client, { groupId, appId, draftId, bypassWarning, deploymentName }) =>
    () =>
      client.apps(groupId).app(appId).drafts().deploy(draftId, bypassWarning, deploymentName)
);

export const [pushAppActions, pushApp] = createActionsAndExecutor<PushDraftPayload, String>(
  `${NAME}github push app`,
  (client, { groupId, appId, draftId, deploymentName, force, bypassWarning }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .deploy()
        .push()
        .github(new GithubPushAppRequest({ draftId, deploymentName, force }), bypassWarning)
);

export const [loadDiffActions, loadDiff] = createActionsAndExecutor<IndividualDraftReqPayload, DraftDiff>(
  `${NAME}load diff`,
  (client, { groupId, appId, draftId }) =>
    () =>
      client.apps(groupId).app(appId).drafts().diff(draftId)
);

export const [redeployActions, redeploy] = createActionsAndExecutor<BaseDeploymentReqPayload, void>(
  `${NAME}redeploy deployment`,
  (client, { groupId, appId, deploymentId }) =>
    () =>
      client.apps(groupId).app(appId).deploy().deployments().deployment(deploymentId).redeploy()
);

export const [initiateGithubAuthActions, initiateGithubAuth] = createActionsAndExecutor<
  BaseRequestPayload,
  string | null
>(
  `${NAME}initiate github auth`,
  (client, { groupId, appId }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .deploy()
        .auth()
        .github()
        .then((res) => res.headers.get('X-BAAS-Location'))
);

export const clearInstallations = createAction(`${NAME}clear installations`);
export const [initializeGithubAuthForGroupActions, initializeGithubAuthForGroup] = createActionsAndExecutor<
  AppsReqPayload,
  GithubGroupAuthenticationResponse
>(
  `${NAME}initialize github auth for group`,
  (client, { groupId }) =>
    () =>
      client.apps(groupId).deploy().auth().github()
);

export const [loadInstallationsByRequestIdActions, loadInstallationsByRequestId] = createActionsAndExecutor<
  TTLInstallationsByRequestIdPayload,
  Installation[]
>(
  `${NAME}load installations by request id`,
  (client, { groupId, githubAuthRequestId }) =>
    () =>
      client.apps(groupId).deploy().installationRequest(githubAuthRequestId)
);

export const [loadTTLInstallationBranchesActions, loadTTLInstallationBranches] = createActionsAndExecutor<
  LoadTTLInstallaionBranchesReqPayload,
  GitHubBranch[]
>(
  `${NAME}load ttl installation branches`,
  (client, { groupId, installationId, repositoryId, githubAuthRequestId }) =>
    () =>
      client
        .apps(groupId)
        .deploy()
        .ttlInstallations(installationId)
        .repository(repositoryId)
        .github()
        .branches()
        .list(githubAuthRequestId)
);

export const [loadTTLInstallationDirectoriesActions, loadTTLInstallationDirectories] = createActionsAndExecutor<
  LoadTTLInstallaionDirectoriesReqPayload,
  GitHubDirectory[]
>(
  `${NAME}load ttl installation directories`,
  (client, { groupId, installationId, repositoryId, branch, githubAuthRequestId }) =>
    () =>
      client
        .apps(groupId)
        .deploy()
        .ttlInstallations(installationId)
        .repository(repositoryId)
        .github()
        .branches()
        .branch(branch)
        .directories()
        .list(githubAuthRequestId)
);

export const [enableAutoDeployWithTTLInstallationActions, enableAutoDeployWithTTLInstallation] =
  createActionsAndExecutor<EnableTTLInstallationAutoDeployReqPayload, void>(
    `${NAME}enable auto deploy wtih ttl installation`,
    (client, { groupId, appId, installationId, githubAuthRequestId, repositoryId, branch, directory }) =>
      () =>
        client
          .apps(groupId)
          .app(appId)
          .deploy()
          .ttlInstallations(installationId)
          .enableAutoDeploy(
            new EnableTTLInstallationAutoDeployRequest({ githubAuthRequestId, repositoryId, branch, directory })
          )
  );

export const [loadDeployConfigActions, loadDeployConfig] = createActionsAndExecutor<
  BaseRequestPayload,
  PartialCodeDeploy
>(
  `${NAME}get deploy config`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).deploy().config()
);

export const [loadDeployInstallationActions, loadDeployInstallation] = createActionsAndExecutor<
  BaseRequestPayload,
  Installation[]
>(
  `${NAME}load deploy installation`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).deploy().installation()
);

export const [loadDeployBranchesActions, loadDeployBranches] = createActionsAndExecutor<
  ListBranchesReqPayload,
  GitHubBranch[]
>(
  `${NAME}load deploy branches`,
  (client, { groupId, appId, repoId }) =>
    () =>
      client.apps(groupId).app(appId).deploy().repositories().repository(repoId).github().branches().list()
);

export const [saveDeployConfigActions, saveDeployConfig] = createActionsAndExecutor<
  SaveDeployConfigReqPayload,
  PartialCodeDeploy
>(
  `${NAME}save deploy config`,
  (client, { groupId, appId, deployConfig }) =>
    () =>
      client.apps(groupId).app(appId).deploy().updateConfig(deployConfig)
);

export const [clearDeployConfigActions, clearDeployConfig] = createActionsAndExecutor<
  BaseRequestPayload,
  PartialCodeDeploy
>(
  `${NAME}clear deploy config`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).deploy().overwriteConfig({})
);

export const [renameDeploymentActions, renameDeployment] = createActionsAndExecutor<RenameDeploymentPayload, void>(
  `${NAME}rename deployment`,
  (client, { groupId, appId, deploymentId, name }) =>
    () =>
      client.apps(groupId).app(appId).deploy().deployments().deployment(deploymentId).rename(name)
);

export const [createAtlasApiKeyActions, createAtlasApiKey] = createActionsAndExecutor<
  Pick<BaseRequestPayload, 'groupId'>,
  CreateAtlasAPIKeyResponse
>(
  `${NAME}create atlas api key`,
  (client, { groupId }) =>
    () =>
      client
        .apps(groupId)
        .atlasAPIKeys()
        .create(new CreateAtlasAPIKeyRequest({ desc: 'Created via App Services UI', roles: ['GROUP_OWNER'] }))
);

export const asyncEditRcvActions = [redeployActions.rcv];
