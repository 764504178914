import { createAction } from 'redux-act';

import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import { CustomUserDataConfig, RefreshTokenExpirationPayload } from 'admin-sdk';

const NAME = 'userSettings/';

export const clearRefreshTokenExpirationSaveError = createAction(`${NAME}clear refresh token expiration save error`);

export const [loadCustomUserDataActions, loadCustomUserData] = createActionsAndExecutor<
  BaseRequestPayload,
  CustomUserDataConfig
>(
  `${NAME}load custom user data`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).customUserData().get()
);

export const [updateCustomUserDataActions, updateCustomUserData] = createActionsAndExecutor<
  CustomUserDataUpdatePayload,
  void
>(
  `${NAME}update custom user data`,
  (client, { groupId, appId, data }) =>
    () =>
      client.apps(groupId).app(appId).customUserData().update(data)
);

export const [loadRefreshTokenExpirationActions, loadRefreshTokenExpiration] = createActionsAndExecutor<
  BaseRequestPayload,
  RefreshTokenExpirationPayload
>(
  `${NAME}load refresh token expiration`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).security().refreshTokenExpiration().get()
);

export const [updateRefreshTokenExpirationActions, updateRefreshTokenExpiration] = createActionsAndExecutor<
  RefreshTokenExpirationUpdatePayload,
  void
>(
  `${NAME}update refresh token expiration`,
  (client, { groupId, appId, expiration }) =>
    () =>
      client.apps(groupId).app(appId).security().refreshTokenExpiration().update(expiration)
);

export const asyncEditRcvActions = [updateCustomUserDataActions.rcv];

export interface CustomUserDataUpdatePayload extends BaseRequestPayload {
  data: CustomUserDataConfig;
}

export interface RefreshTokenExpirationUpdatePayload extends BaseRequestPayload {
  expiration: RefreshTokenExpirationPayload;
}
