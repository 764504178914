import React from 'react';
import { InlineCode } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import { prettyJSONStringify } from 'baas-ui/common/utils/util';

// Apply When
export const applyWhenDescription = (
  <>
    For each query to the underlying collection, write a{' '}
    <DocLink showExternalIcon={false} href={docLinks.Rules.Expressions}>
      JSON expression
    </DocLink>{' '}
    to define when this filter should be applied. If left empty, no documents will be filtered before rules processing.
  </>
);

export const applyWhenExampleText = (
  <>
    The following is a filter&apos;s Apply When expression that evaluates to <InlineCode>true</InlineCode> only if a
    document&apos;s <InlineCode>%%user.data.email</InlineCode> value match the values in{' '}
    <InlineCode>approvedEmails</InlineCode>:
  </>
);

export const applyWhenExampleCode = prettyJSONStringify({
  '%%user.data.email': { '%in': '%%values.approvedEmails' },
});

// Query
export const queryDescription = (
  <>
    Write a{' '}
    <DocLink showExternalIcon={false} href={docLinks.Rules.Filters}>
      filter query
    </DocLink>{' '}
    that specifies documents containing additional query predicates. The filter will be appended to any query sent to
    the linked data source.
  </>
);

export const queryExampleText = (
  <>
    A filter that withholds documents that have a <InlineCode>score</InlineCode> below <InlineCode>20</InlineCode> could
    use the following filter query:
  </>
);

export const queryExampleCode = `{ "score": { "$gt": 20 } }`;

// Projection
export const projDescription = (
  <>
    Write a{' '}
    <DocLink showExternalIcon={false} href={docLinks.Rules.Filters}>
      filter projection
    </DocLink>{' '}
    that specifies fields to withhold from data returned from the linked data source.
  </>
);

export const projExampleText = (
  <>
    A filter that withholds the <InlineCode>career_stats</InlineCode> and <InlineCode>personal</InlineCode> fields from
    documents could use the following filter projection:
  </>
);

export const projExampleCode = prettyJSONStringify({
  career_stats: 0,
  personal: 0,
});
