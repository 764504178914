import semverCoerce from 'semver/functions/coerce';
import semverLt from 'semver/functions/lt';

import { DataSourceSelectOptionType, DataSourceType } from 'baas-ui/common/components/data-source-select';
import { FLEX_CLUSTER_INSTANCE_SIZE, MONGODB_SYNC_MIN_VERSION, SERVERLESS_INSTANCE_SIZE } from 'baas-ui/constants';
import { TemplateIdentifier } from 'baas-ui/home/create-app/types';
import { DataSourceLinkMethod } from 'baas-ui/home/types';
import { ClusterState, DataLakeState } from 'baas-ui/home/types';
import { AtlasCluster, AtlasDataLake } from 'admin-sdk';

export const createClusterSelectOptions = (
  clusters: AtlasCluster[],
  atlasClustersUrl: string
): DataSourceSelectOptionType[] =>
  clusters.reduce((acc: DataSourceSelectOptionType[], cluster) => {
    // Filter out Deleted clusters
    if (cluster.state === ClusterState.Deleted) {
      return acc;
    }

    acc.push({
      label: cluster.name ?? '',
      value: cluster.name ?? '',
      regionName:
        cluster.replicationSpecs && cluster.replicationSpecs[0].regionConfigs
          ? cluster.replicationSpecs[0].regionConfigs[0].regionName
          : undefined,
      state: cluster.state,
      mongoDBVersion: cluster.mongoDBVersion,
      paused: cluster.paused,
      instanceSize:
        cluster.replicationSpecs && cluster.replicationSpecs[0].regionConfigs
          ? cluster.replicationSpecs[0].regionConfigs[0].electableSpecs?.instanceSize
          : undefined,
      backingProviderName:
        cluster.replicationSpecs && cluster.replicationSpecs[0].regionConfigs
          ? cluster.replicationSpecs[0].regionConfigs[0].backingProviderName
          : undefined,
      upgradeUrl: `${atlasClustersUrl}/edit/${cluster.name}`,
      dataSourceUrl: atlasClustersUrl,
      type: DataSourceType.CLUSTER,
      isMultiCloud: cluster.isMultiCloud ?? false,
      isMultiRegion: cluster.isMultiRegion ?? false,
    });

    return acc;
  }, []);

export const createDataLakeSelectOptions = (
  dataLakes: AtlasDataLake[],
  dataSourceURL: string
): DataSourceSelectOptionType[] =>
  dataLakes.map((lake: AtlasDataLake) => {
    const stores = lake?.storage?.stores;
    const firstStore = stores && stores.length > 0 ? stores[0] : {};

    let provider: string | undefined;
    switch (firstStore.provider) {
      case 's3':
        provider = 'AWS';
        break;
      case 'atlas':
        provider = 'Atlas';
        break;
      case 'http':
        provider = 'HTTP';
        break;
      default:
        break;
    }

    return {
      label: lake.name ?? '',
      value: lake.name ?? '',
      regionName: firstStore.region,
      state: lake.state,
      dataSourceUrl: dataSourceURL,
      type: DataSourceType.DATA_FEDERATION,
      isMultiCloud: false,
      isMultiRegion: false,
      backingProviderName: provider,
    };
  });

export const createFlexClusterSelectOptions = (
  flexClusters: AtlasCluster[],
  atlasClustersUrl: string
): DataSourceSelectOptionType[] =>
  flexClusters.reduce((acc: DataSourceSelectOptionType[], flexCluster) => {
    // Filter out Deleted Flex clusters
    if (flexCluster.state === ClusterState.Deleted) {
      return acc;
    }
    acc.push({
      label: flexCluster.name ?? '',
      value: flexCluster.name ?? '',
      state: flexCluster.state,
      regionName: flexCluster.providerSettings?.regionName,
      backingProviderName: flexCluster.providerSettings?.backingProviderName,
      instanceSize: FLEX_CLUSTER_INSTANCE_SIZE,
      mongoDBVersion: flexCluster.mongoDBVersion,
      dataSourceUrl: atlasClustersUrl,
      type: DataSourceType.CLUSTER,
      isMultiCloud: false,
      isMultiRegion: false,
    });

    return acc;
  }, []);

export const createServerlessSelectOptions = (
  serverlessInstances: AtlasCluster[],
  atlasClustersUrl: string
): DataSourceSelectOptionType[] =>
  serverlessInstances.reduce((acc: DataSourceSelectOptionType[], serverlessInstance) => {
    // Filter out Deleted serverless instances
    if (serverlessInstance.state === ClusterState.Deleted) {
      return acc;
    }
    acc.push({
      label: serverlessInstance.name ?? '',
      value: serverlessInstance.name ?? '',
      state: serverlessInstance.state,
      regionName: serverlessInstance.providerSettings?.regionName,
      backingProviderName: serverlessInstance.providerSettings?.backingProviderName,
      instanceSize: SERVERLESS_INSTANCE_SIZE,
      mongoDBVersion: serverlessInstance.mongoDBVersion,
      dataSourceUrl: atlasClustersUrl,
      type: DataSourceType.CLUSTER,
      isMultiCloud: false,
      isMultiRegion: false,
    });

    return acc;
  }, []);

export const dataSourceOptionIsEnabled = (opt?: DataSourceSelectOptionType): boolean => {
  if (!opt) {
    return false;
  }

  if (opt.type === DataSourceType.CLUSTER) {
    return (
      !opt.paused &&
      (opt.state === ClusterState.Idle || opt.state === ClusterState.Creating || opt.state === ClusterState.Updating)
    );
  }

  return opt.state === DataLakeState.Active;
};

export const templateAppUsesSync = (template: TemplateIdentifier): boolean =>
  template === TemplateIdentifier.IOSSwiftTodo ||
  template === TemplateIdentifier.IOSSwiftUITodo ||
  template === TemplateIdentifier.AndroidKotlinTodo ||
  template === TemplateIdentifier.XamarinCSharpTodo ||
  template === TemplateIdentifier.ReactNativeTodo;

// semver expects versions with 3 numbers, but MongoDB versions can be valid with just 2.
// semverCoerce is used to assert proper version formatting (i.e 4.4 -> 4.4.0)
export const dataSourceOptionSupportsSync = (opt?: DataSourceSelectOptionType): boolean => {
  if (opt?.mongoDBVersion) {
    return !semverLt(semverCoerce(opt.mongoDBVersion)!, MONGODB_SYNC_MIN_VERSION);
  }
  return false;
};

export const processDataSourceOpts = ({
  clusterOpts,
  dataLakeOpts,
  flexClusterOpts,
  onlineArchiveOpts,
  serverlessInstanceOpts,
  templateId,
}) => {
  const m0ClusterOpt = clusterOpts.find((clusterOpt) => clusterOpt.instanceSize === 'M0');
  const hasM0Cluster = !!m0ClusterOpt;
  let enabledClusterOpts = clusterOpts.filter(dataSourceOptionIsEnabled);
  if (templateAppUsesSync(templateId)) {
    enabledClusterOpts = enabledClusterOpts.filter(dataSourceOptionSupportsSync);
  }
  const enabledDataLakeOpts =
    templateId === TemplateIdentifier.Default ? dataLakeOpts.filter(dataSourceOptionIsEnabled) : [];
  const enabledFlexClusterOpts = flexClusterOpts.filter(dataSourceOptionIsEnabled);
  const enabledOnlineArchiveOpts =
    templateId === TemplateIdentifier.Default ? onlineArchiveOpts.filter(dataSourceOptionIsEnabled) : [];
  const enabledServerlessInstanceOpts =
    templateId === TemplateIdentifier.Default ? serverlessInstanceOpts.filter(dataSourceOptionIsEnabled) : [];
  const numEnabledDataSources =
    enabledClusterOpts.length +
    enabledDataLakeOpts.length +
    enabledFlexClusterOpts.length +
    enabledServerlessInstanceOpts.length +
    enabledOnlineArchiveOpts.length;

  return {
    m0ClusterOpt,
    hasM0Cluster,
    enabledClusterOpts,
    enabledDataLakeOpts,
    enabledFlexClusterOpts,
    enabledOnlineArchiveOpts,
    enabledServerlessInstanceOpts,
    numEnabledDataSources,
  };
};

export interface SetDefaultDataSourceConfigParams {
  m0ClusterOpt?: DataSourceSelectOptionType;
  enabledClusterOpts: DataSourceSelectOptionType[];
  enabledDataLakeOpts: DataSourceSelectOptionType[];
  enabledFlexClusterOpts: DataSourceSelectOptionType[];
  enabledOnlineArchiveOpts: DataSourceSelectOptionType[];
  enabledServerlessInstanceOpts: DataSourceSelectOptionType[];
  setSelectedDataSource(selectedDataSource?: DataSourceSelectOptionType): void;
  dataSourceLinkMethod: DataSourceLinkMethod;
  setDataSourceLinkMethod(dataSourceLinkMethod: DataSourceLinkMethod): void;
}

export const setDefaultDataSourceConfig = ({
  m0ClusterOpt,
  enabledClusterOpts,
  enabledDataLakeOpts,
  enabledFlexClusterOpts,
  enabledOnlineArchiveOpts,
  enabledServerlessInstanceOpts,
  setSelectedDataSource,
  dataSourceLinkMethod,
  setDataSourceLinkMethod,
}: SetDefaultDataSourceConfigParams) => {
  const hasEnabledDataSources = [
    enabledClusterOpts,
    enabledDataLakeOpts,
    enabledFlexClusterOpts,
    enabledOnlineArchiveOpts,
    enabledServerlessInstanceOpts,
  ].some((dataSourceOpts) => dataSourceOpts.length > 0);

  setSelectedDataSource(undefined);
  if (!!m0ClusterOpt && !hasEnabledDataSources) {
    // This is a state where the users cannot link data sources, as they cannot provision a
    // new cluster, nor do they have available data sources to link
    setDataSourceLinkMethod(DataSourceLinkMethod.UseExisting);
    return;
  }

  let newDataSourceLinkingMethod = dataSourceLinkMethod;
  if (hasEnabledDataSources) {
    // Prioritize existing data sources
    newDataSourceLinkingMethod = DataSourceLinkMethod.UseExisting;
  }

  const allOptsSyncUnsupported =
    enabledClusterOpts.length > 0 && enabledClusterOpts.every((opt) => !dataSourceOptionSupportsSync(opt));
  if (!m0ClusterOpt && (allOptsSyncUnsupported || !hasEnabledDataSources)) {
    // If existing clusters don't support Sync or if there are no available data sources, suggest creating
    // a free tier cluster if possible
    newDataSourceLinkingMethod = DataSourceLinkMethod.CreateCluster;
  }
  setDataSourceLinkMethod(newDataSourceLinkingMethod);

  if (newDataSourceLinkingMethod === DataSourceLinkMethod.UseExisting) {
    // We prioritize the default data source as follows:
    // 1) M0 cluster, 2) any other cluster, 3) any serverless instance, 4) any datalake
    if (!!m0ClusterOpt && enabledClusterOpts.includes(m0ClusterOpt)) {
      setSelectedDataSource(m0ClusterOpt);
    } else if (enabledClusterOpts.length > 0) {
      setSelectedDataSource(enabledClusterOpts[0]);
    } else if (enabledFlexClusterOpts.length > 0) {
      setSelectedDataSource(enabledFlexClusterOpts[0]);
    } else if (enabledServerlessInstanceOpts.length > 0) {
      setSelectedDataSource(enabledServerlessInstanceOpts[0]);
    } else if (enabledDataLakeOpts.length > 0) {
      setSelectedDataSource(enabledDataLakeOpts[0]);
    } else if (enabledOnlineArchiveOpts.length > 0) {
      setSelectedDataSource(enabledOnlineArchiveOpts[0]);
    }
  }
};

export enum StepCardIdentifier {
  Template = 'template',
  DataSource = 'data-source',
  Name = 'name',
  Deployment = 'deployment',
}

// getCardIndices returns the card indices of each section in the app creation page.
// If a section should not be visible, its index will be -1.
export const getCardIndices = (templateSelected, isSelfHosted) => {
  if (templateSelected && isSelfHosted) {
    return {
      [StepCardIdentifier.DataSource]: -1,
      [StepCardIdentifier.Template]: 1,
      [StepCardIdentifier.Name]: 2,
      [StepCardIdentifier.Deployment]: 3,
    };
  }

  if (templateSelected && !isSelfHosted) {
    return {
      [StepCardIdentifier.Template]: 1,
      [StepCardIdentifier.DataSource]: 2,
      [StepCardIdentifier.Name]: 3,
      [StepCardIdentifier.Deployment]: 4,
    };
  }

  if (!templateSelected && isSelfHosted) {
    return {
      [StepCardIdentifier.Template]: -1,
      [StepCardIdentifier.DataSource]: -1,
      [StepCardIdentifier.Name]: 1,
      [StepCardIdentifier.Deployment]: 2,
    };
  }

  // !templateSelected && !isSelfHosted
  return {
    [StepCardIdentifier.Template]: -1,
    [StepCardIdentifier.DataSource]: 1,
    [StepCardIdentifier.Name]: 2,
    [StepCardIdentifier.Deployment]: 3,
  };
};
