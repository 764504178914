import React from 'react';
import { connect } from 'react-redux';
import querystring from 'query-string';

import { passThroughProps } from 'baas-ui/common/utils/util';
import { getAppState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';
import { withQueryParams } from 'baas-ui/urls';
import { PartialApp } from 'admin-sdk';

import ExternalRedirect from './ExternalRedirect';

interface ReduxStateProps {
  app: PartialApp;
}

export interface OwnProps {
  location?: { search: string };
  component: any;
}

export type Props = ReduxStateProps & OwnProps;

const CloudAtlasRedirect = ({ location, component: Component, ...rest }: Props) => {
  const cloudUIBaseUrl = window.settings.cloudUIBaseUrl;
  if (cloudUIBaseUrl) {
    if (location) {
      const nextURL = querystring.parse(location.search).nextURL;
      if (nextURL) {
        // Redirect to Cloud with nStitch param.
        // When Cloud encounters this param, it will reauthenticate the legacy BaaS session.
        // It will also reauthenticate the browser authN session if needed.
        return <ExternalRedirect url={withQueryParams(cloudUIBaseUrl, { nStitch: nextURL })} />;
      }
    }

    // Redirect to Cloud without nStitch param.
    return <ExternalRedirect url={cloudUIBaseUrl} />;
  }

  // Fall back to local HomePage or Login components when cloudUIBaseUrl isn't present.
  // This should only happen when running the UI locally.
  return <Component {...passThroughProps(rest)} />;
};

const mapStateToProps = (state: RootState) => {
  const { app } = getAppState(state);
  return { app };
};

export default connect(mapStateToProps)(CloudAtlasRedirect);
