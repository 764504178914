export enum TemplateIdentifier {
  Default = '',
  TriggersDatabase = 'triggers',

  // web templates
  WebGraphQLTodo = 'web.graphql.todo',
  WebMQLTodo = 'web.mql.todo',

  // pbs templates
  IOSSwiftTodo = 'ios.swift.todo',
  IOSSwiftUITodo = 'swiftui.todo',
  AndroidKotlinTodo = 'android.kotlin.todo',
  XamarinCSharpTodo = 'xamarin.todo',
  ReactNativeTodo = 'react-native.todo',

  // v2 templates
  SyncTodo = 'sync.todo',
  FlutterTodoFlexSync = 'flutter.todo.flex',
  KotlinTodoFlexSync = 'kotlin.todo.flex',
  SwiftUITodoFlexSync = 'swiftui.todo.flex',
  ReactNativeTodoFlexSync = 'react-native.todo.flex',
  XamarinTodoFlexSync = 'xamarin.todo.flex',
  MauiTodoFlexSync = 'maui.todo.flex',
}

// This is an enum that the forms can keep in state to keep track of what page of the form to render
export enum CreateAppFormState {
  Templates,
  Config,
  LinkDataSource,
}
