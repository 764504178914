import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Body, Link } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import Footer from 'baas-ui/app/footer';
import { setShowSideNav as setShowSideNavAction } from 'baas-ui/home/actions';
import { TopNav } from 'baas-ui/nav';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { getHomeState, getSettingsState } from 'baas-ui/selectors';
import { useDarkMode } from 'baas-ui/theme';
import { RootState } from 'baas-ui/types';
import sadfacegif from 'baas-static/images/sadface.gif';

interface ReduxStateProps {
  showCloudNav?: boolean;
  showSideNav: boolean;
}

interface ReduxDispatchProps {
  setShowSideNav(showSideNav: boolean): void;
}

interface OwnProps {
  showTopNav?: boolean;
  showFooter?: boolean;
}

export type Props = ReduxStateProps & ReduxDispatchProps & OwnProps;

export enum TestSelector {
  NotFoundPage = 'not-found-page',
}

const NotFound = ({ showTopNav, showFooter, showSideNav, setShowSideNav, showCloudNav = false }: Props) => {
  const darkMode = useDarkMode();

  useEffect(() => {
    if (showSideNav) {
      setShowSideNav(false);
    }
  });

  return (
    <div>
      {!showCloudNav && showTopNav && <TopNav />}
      <div
        data-testid={TestSelector.NotFoundPage}
        data-cy={'not-found-page'}
        className={classNames('not-found', 'not-found-is-spaced', {
          'not-found-light-mode': !darkMode,
          'not-found-dark-mode': darkMode,
        })}
      >
        <div className="not-found-error-message-container">
          <div className="not-found-container">
            <img className="not-found-image" src={sadfacegif} alt="a sad computer" />
            <div className="not-found-code">
              &#123; status: 404, <br />
              &nbsp;&nbsp;message: &#34;Document Not Found&#34; &#125;
            </div>
          </div>
          <Body className="not-found-text">Oops! We can&#39;t find the page you were looking for.</Body>

          <Body className="not-found-text">
            If you&#39;re experiencing a critical issue, please&nbsp;
            <Link className="not-found-text-link" href="mailto:support-operations@mongodb.com">
              email support
            </Link>
            .
          </Body>
        </div>
      </div>
      {showFooter && <Footer />}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { showSideNav } = getHomeState(state);
  const { showCloudNav } = getSettingsState(state);

  return { showCloudNav, showSideNav };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  setShowSideNav: (showSideNav: boolean) => dispatch(setShowSideNavAction(showSideNav)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
