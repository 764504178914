import React from 'react';
import Copyable from '@leafygreen-ui/copyable';
import { Body, H2, Link } from '@leafygreen-ui/typography';

import { passThroughProps } from 'baas-ui/common/utils/util';

import './error-boundary.less';

export enum TestSelector {
  ErrorBoundaryContainer = 'error-boundary-container',
}

const baseClassName = 'error-boundary';
export interface Props {
  children: React.ReactNode;
  onError(error: Error, info: React.ErrorInfo): void;
}

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends React.Component<Props, State> {
  static defaultProps = {
    onError: console.error.bind(console), // eslint-disable-line no-console
  };

  state: State = {
    hasError: false,
    error: undefined,
  };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.onError(error, info);
    this.setState({ hasError: true, error });
  }

  render() {
    const { error } = this.state;

    if (this.state.hasError) {
      return (
        <div
          className={`${baseClassName}`}
          data-testid={TestSelector.ErrorBoundaryContainer}
          {...passThroughProps(this.props)}
        >
          <div className={`${baseClassName}-message`}>
            <H2>Oops!</H2>
            <H2>Something went wrong.</H2>
            {error?.message && <Copyable className={`${baseClassName}-code`}>{error.message}</Copyable>}
            <Body className={`${baseClassName}-announcement`}>
              It seems we encountered an error while processing your request. You can file a ticket for it at our&nbsp;
              <Link href="https://support.mongodb.com/" target="_blank" rel="noreferrer">
                help center
              </Link>
            </Body>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
