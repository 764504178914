import { MongoDataSourceType, servicesByType, SVCTYPE_GCM } from './services/registry';

export function EditConfig(props) {
  const editorProps = {
    app: props.app,
    loadSvcConfig: props.loadSvcConfig,
    service: props.service,
    editing: props.editing,
  };

  if (props.clusterLink) {
    return servicesByType.get(MongoDataSourceType.Atlas).getConfigEditor(editorProps);
  }

  const type = editorProps.service.name === 'gcm' ? SVCTYPE_GCM : props.service.type;
  return servicesByType.get(type).getConfigEditor(editorProps);
}

export function EditRules(props) {
  const editorProps = {
    ...props,
    app: props.app,
    service: props.service,
  };
  return servicesByType.get(props.service.type).getRulesEditor(editorProps);
}
