import React, { useMemo } from 'react';

import {
  CollectionData,
  DatabaseData,
  DataSourceData,
} from 'baas-ui/common/components/collection-explorer/collection-explorer/CollectionExplorer';
import { parseCollectionNamespace } from 'baas-ui/services/mongodb/util';

export interface CollectionExplorerContext<T> {
  dataSources: DataSourceData<T>[];
  hasCollections?: boolean;
  isLoading: boolean;
  selectedCollectionData: CollectionData<T> | null;
  selectedDatabaseData: DatabaseData<T> | null;
  selectedDataSourceData: DataSourceData<T> | null;
  selectedNamespace: string | null;
  setSelectedNamespace: (namespace: string | null) => void;
}

export function createCollectionExplorerContext<T>() {
  return React.createContext<CollectionExplorerContext<T>>({
    dataSources: [],
    hasCollections: false,
    isLoading: false,
    selectedCollectionData: null,
    selectedDatabaseData: null,
    selectedDataSourceData: null,
    selectedNamespace: null,
    setSelectedNamespace: () => {},
  });
}

interface PublicProps<T> {
  dataSources: DataSourceData<T>[];
  hasCollections?: boolean;
  isLoading: boolean;
  routeControlledNamespace?: string;
}

export type Props<T> = React.PropsWithChildren<PublicProps<T>>;

export function createCollectionExplorerContextProvider<T>(context: React.Context<CollectionExplorerContext<T>>) {
  return function CollectionExplorerContextProvider({
    children,
    dataSources,
    hasCollections,
    isLoading,
    routeControlledNamespace,
  }: Props<T>) {
    const [selectedNamespace, setSelectedNamespace] = React.useState<string | null>(null);
    const [selectedDataSourceData, setSelectedDataSourceData] = React.useState<DataSourceData<T> | null>(null);
    const [selectedDatabaseData, setSelectedDatabaseData] = React.useState<DatabaseData<T> | null>(null);
    const [selectedCollectionData, setSelectedCollectionData] = React.useState<CollectionData<T> | null>(null);

    React.useEffect(() => {
      if (selectedNamespace) {
        /*
          selectedNamespace in this context refers to a string formatted as 'dataSourceName:databaseName:collectionName'
          or as 'dataSourceName'. If the selectedNamespace is set to only a dataSourceName, then we only set the
          dataSourceData. Otherwise, we parse the selectedNamespace to set each Data accordingly.
        */
        const isNamespaceDataSourceOnly = dataSources.find(({ namespace }) => namespace === selectedNamespace);
        if (isNamespaceDataSourceOnly) {
          setSelectedDataSourceData(isNamespaceDataSourceOnly);
          setSelectedDatabaseData(null);
          setSelectedCollectionData(null);
        } else {
          const { dataSourceName, database, collection } = parseCollectionNamespace(selectedNamespace);

          const dataSourceData = dataSources.find(({ namespace }) => namespace === dataSourceName);
          setSelectedDataSourceData(dataSourceData || null);

          const databaseData = dataSourceData?.databases.find(({ databaseName }) => databaseName === database);
          setSelectedDatabaseData(databaseData || null);

          const collectionData = databaseData?.collections.find(({ collectionName }) => collectionName === collection);
          setSelectedCollectionData(collectionData || null);
        }
      } else {
        setSelectedDataSourceData(null);
        setSelectedDatabaseData(null);
        setSelectedCollectionData(null);
      }
    }, [selectedNamespace, dataSources]);

    React.useEffect(() => {
      setSelectedNamespace(routeControlledNamespace || null);
    }, [routeControlledNamespace]);

    const values = useMemo(
      () => ({
        dataSources,
        hasCollections,
        isLoading,
        selectedCollectionData,
        selectedDatabaseData,
        selectedDataSourceData,
        selectedNamespace,
        setSelectedNamespace,
      }),
      [
        dataSources,
        hasCollections,
        isLoading,
        selectedCollectionData,
        selectedDatabaseData,
        selectedDataSourceData,
        selectedNamespace,
      ]
    );

    return <context.Provider value={values}>{children}</context.Provider>;
  };
}
