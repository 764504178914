import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import Toast, { Variant } from '@leafygreen-ui/toast';

import { getAppId, getGroupId } from 'baas-ui/app/selectors';
import { ZIndex } from 'baas-ui/common/styles/zIndex';
import { SavedTriggerNameContext } from 'baas-ui/events/context/savedTriggerNameContext';
import urls from 'baas-ui/urls';

const StyledToast = styled(Toast)`
  z-index: ${ZIndex.Toast};
`;

export const TestSelector = {
  TriggerSavedToast: 'trigger-saved-toast',
};

export const TriggerToast: React.FC = () => {
  const appId = useSelector(getAppId);
  const groupId = useSelector(getGroupId);
  const { savedTriggerName, setSavedTriggerName } = useContext(SavedTriggerNameContext);
  const triggersHomeUrl = urls.groups().group(groupId).apps().app(appId).triggers().list();

  // TODO(BAAS-31879) - this should be the default behavior once upgraded to leafygreen toast > 6.0
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSavedTriggerName('');
    }, 6000);

    return () => clearTimeout(timeoutId);
  }, [savedTriggerName]);

  return (
    <StyledToast
      data-testid={TestSelector.TriggerSavedToast}
      open={!!savedTriggerName}
      close={() => setSavedTriggerName('')}
      variant={Variant.Success}
      title="Trigger changes have been saved"
      body={
        <>
          {savedTriggerName} has been successfully saved. <Link to={triggersHomeUrl}>Triggers Home</Link>
        </>
      }
    />
  );
};
