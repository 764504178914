import React from 'react';

import { SavedTriggerNameContextProvider } from './savedTriggerNameContext';

/**
 * Wrapper for all of the triggers related contexts so that the outer app component
 * doesn't need to be aware of all the different (smaller) pieces of state being managed here
 */
export const TriggersContextProvider: React.FC = ({ children }) => {
  return <SavedTriggerNameContextProvider>{children}</SavedTriggerNameContextProvider>;
};
