import React from 'react';
import { Body, InlineCode, Link } from '@leafygreen-ui/typography';

import { prettyJSONStringify } from 'baas-ui/common/utils/util';
import PermissionDisplay, { PermissionDisplayValue } from 'baas-ui/rules/permission-display';
import { Permission } from 'baas-ui/rules/types';

import './role-info-table.less';

export enum TestSelector {
  ApplyWhenJSON = 'apply-when',
  DocumentPermissions = 'document-permissions',
  FieldPermissions = 'field-permissions',
  Table = 'table',
  ViewJSONLink = 'view-json',
}

export interface Props {
  applyWhenExp: Record<string, any>;
  onClickViewApplyWhenJSON?: () => void;
  insertDisplayVal: PermissionDisplayValue;
  deleteDisplayVal: PermissionDisplayValue;
  searchDisplayVal: PermissionDisplayValue;
  readDisplayVal: PermissionDisplayValue;
  writeDisplayVal: PermissionDisplayValue;
}

const baseClassName = 'role-info-table';
const tableEntryClassName = `${baseClassName}-entry`;

export const RoleInfoTable = ({
  applyWhenExp,
  onClickViewApplyWhenJSON,
  insertDisplayVal,
  deleteDisplayVal,
  searchDisplayVal,
  readDisplayVal,
  writeDisplayVal,
}: Props) => {
  const applyWhenJSON = prettyJSONStringify(applyWhenExp);
  const hasApplyWhenJson = !!Object.keys(applyWhenExp).length;

  return (
    <table className={baseClassName} data-testid={TestSelector.Table}>
      <colgroup>
        <col className={`${baseClassName}-header-column`} />
        <col />
      </colgroup>
      <tbody>
        <tr>
          <td className={tableEntryClassName}>
            <div style={{ display: 'flex' }}>
              <Body weight="medium">Apply When</Body>
              {onClickViewApplyWhenJSON && (
                <Link
                  hideExternalIcon
                  style={{ marginLeft: '8px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickViewApplyWhenJSON();
                  }}
                  data-testid={TestSelector.ViewJSONLink}
                >
                  View
                </Link>
              )}
            </div>
          </td>
          <td className={tableEntryClassName}>
            {!hasApplyWhenJson ? (
              <Body>Always</Body>
            ) : (
              <div className={`${tableEntryClassName}-apply-when`}>
                <InlineCode className={`${tableEntryClassName}-apply-when-json`}>{applyWhenJSON}</InlineCode>
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td className={tableEntryClassName}>
            <Body weight="medium">Document Permissions</Body>
          </td>
          <td className={tableEntryClassName}>
            <div className={`${tableEntryClassName}-permissions-groups`} data-testid={TestSelector.DocumentPermissions}>
              <PermissionDisplay name={Permission.Insert} displayVal={insertDisplayVal} />
              <PermissionDisplay name={Permission.Delete} displayVal={deleteDisplayVal} />
              <PermissionDisplay name={Permission.Search} displayVal={searchDisplayVal} />
            </div>
          </td>
        </tr>
        <tr>
          <td className={tableEntryClassName}>
            <Body weight="medium">Field Permissions</Body>
          </td>
          <td className={tableEntryClassName}>
            <div className={`${tableEntryClassName}-permissions-groups`} data-testid={TestSelector.FieldPermissions}>
              <PermissionDisplay name={Permission.Read} displayVal={readDisplayVal} />
              <PermissionDisplay name={Permission.Write} displayVal={writeDisplayVal} />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
