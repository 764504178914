import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { OrderedMap } from 'immutable';

import Alert from './Alert';

const mapStateToProps = (state, ownProps) => {
  const alerts = state.alerts.alerts.get(ownProps.alertKey) || new OrderedMap();
  return { alerts };
};

export const AlertContainer = connect(mapStateToProps)((props) => (
  <div className={classNames('alert-container', props.classNames)}>
    <TransitionGroup>
      {Array.from(props.alerts.entries()).map(([key, message]) => (
        <CSSTransition key={key} classNames="alert" timeout={{ enter: 500, exit: 300 }}>
          <Alert message={message} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  </div>
));

AlertContainer.displayName = 'Connect(AlertContainer)';

export default AlertContainer;
