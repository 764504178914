import { UsersSort, UserStatus } from 'admin-sdk';

/* eslint-disable camelcase */
interface PendingUser {
  name: string;
  recordID: string;
  providers: string;
  confirmed: boolean;
}

interface PendingUserFilter {
  after?: string;
  limit?: string;
}

interface LoginId {
  id: string;
  id_type: string;
  confirmed?: boolean;
}

interface RawPendingUser {
  _id: string;
  login_ids: LoginId[];
}

enum PendingUserAction {
  Confirm,
  Run,
}

interface Sort {
  field: string;
  direction: string;
}

interface UsersFilter {
  providers: { [key: string]: boolean };
  status?: string;
  sort?: Sort;
}

interface QueryParams {
  provider_types?: string;
  status?: UserStatus;
  sort?: UsersSort;
  desc?: string;
  after?: string | [string, string];
}

export { PendingUser, PendingUserFilter, RawPendingUser, PendingUserAction, Sort, UsersFilter, QueryParams };
