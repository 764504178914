import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { StatusBanner, Variant } from 'baas-ui/common/components/status';
import { retryAsyncFunction } from 'baas-ui/common/utils/util';
import { resumeEvent } from 'baas-ui/events/actions';
import { AsyncDispatch, AsyncDispatchPayload, AsyncExecutorPayload } from 'baas-ui/redux_util';
import { getAppState, getSyncState } from 'baas-ui/selectors';
import { setSyncEventSubscriptionError } from 'baas-ui/sync/actions';
import { fetchSyncEventSubscriptions } from 'baas-ui/sync/sagas';
import { getErroredOrFirstSubscription } from 'baas-ui/sync/util';
import { RootState } from 'baas-ui/types';
import { BaseEventSubscription, ResponseError } from 'admin-sdk';

const syncTerminateRequiredErr =
  'Sync cannot be resumed from this state and must be terminated and re-enabled to continue functioning';

const syncPausedInactivityErr = 'Device Sync has been paused due to prolonged period of inactivity.';

export enum TestSelector {
  SyncErrorBanner = 'sync-error-banner',
  ResumeErrorBanner = 'resume-error-banner',
}

interface StateProps {
  syncEventSubscriptionError?: string;
}

interface DispatchProps {
  fetchEventSubscriptions(): AsyncDispatchPayload<BaseEventSubscription[]>;
  resumeEventSubscription(triggerId: string): AsyncExecutorPayload<typeof resumeEvent>;
}

export type Props = StateProps & DispatchProps;

export function SyncEventSubscriptionErrorBanner({
  syncEventSubscriptionError,
  fetchEventSubscriptions,
  resumeEventSubscription,
}: Props) {
  const dispatch = useDispatch();

  const [eventSubId, setEventSubId] = React.useState('');
  const [resumeError, setResumeError] = React.useState('');

  React.useEffect(() => {
    retryAsyncFunction(fetchEventSubscriptions)()
      .then((subscriptions) => {
        const es = getErroredOrFirstSubscription(subscriptions || []);
        if (es && es?.id) {
          setEventSubId(es.id);

          if (es?.error) {
            dispatch(setSyncEventSubscriptionError(es.error));
          }
        }
      })
      .catch(() => {}); // error handled via redux state

    return () => {
      setEventSubId('');
      setResumeError('');
    };
  }, []);

  let bannerAction: {
    actionLabel?: string;
    action?: () => void;
  } = {
    actionLabel: 'restart atlas sync',
    action: () => {
      setResumeError('');
      resumeEventSubscription(eventSubId)
        .then(
          () => dispatch(setSyncEventSubscriptionError('')),
          (rawError: ResponseError) => setResumeError(rawError.message || '')
        )
        .catch((resumeErr) => {
          setResumeError(resumeErr);
        });
    },
  };

  if (
    syncEventSubscriptionError?.includes(syncTerminateRequiredErr) ||
    syncEventSubscriptionError?.includes(syncPausedInactivityErr)
  ) {
    // Suppress the "restart atlas sync" button if the error message indicates
    // that termination is necessary.
    bannerAction = {};
  }

  return (
    <>
      {syncEventSubscriptionError && (
        <StatusBanner
          data-testid={TestSelector.SyncErrorBanner}
          message={
            <>
              <div>Synchronization between Atlas and Device Sync has been stopped, due to error:</div>
              <code>{syncEventSubscriptionError}</code>
            </>
          }
          statusId="syncEventSubscriptionError"
          variant={Variant.Error}
          {...bannerAction}
        />
      )}
      {resumeError && (
        <StatusBanner
          data-testid={TestSelector.ResumeErrorBanner}
          message={`Error resuming Device Sync: ${resumeError}`}
          statusId="resumeError"
          variant={Variant.Error}
        />
      )}
    </>
  );
}

function mapStateToProps(state: RootState) {
  const {
    app: { id: appId, groupId },
  } = getAppState(state);
  const { syncEventSubscriptionError } = getSyncState(state);

  return {
    appId,
    groupId,
    syncEventSubscriptionError,
  };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
  return {
    fetchEventSubscriptions: (groupId: string, appId: string) => () =>
      dispatch(fetchSyncEventSubscriptions(groupId, appId)),
    resumeEventSubscription: (groupId: string, appId: string) => (triggerId: string) =>
      dispatch(resumeEvent({ groupId, appId, triggerId })),
  };
}

function mergeProps(
  { appId, groupId, ...otherStateProps }: ReturnType<typeof mapStateToProps>,
  { fetchEventSubscriptions, resumeEventSubscription, ...otherDispatchProps }: ReturnType<typeof mapDispatchToProps>
): Props {
  return {
    ...otherStateProps,
    ...otherDispatchProps,
    fetchEventSubscriptions: fetchEventSubscriptions(groupId, appId),
    resumeEventSubscription: resumeEventSubscription(groupId, appId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SyncEventSubscriptionErrorBanner);
