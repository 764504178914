import { Action } from 'redux';
import { createAction, SimpleActionCreator } from 'redux-act';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import {
  BaasAdminClient,
  ErrInvalidCloudSession,
  ErrInvalidSession,
  ErrMissingSession,
  ErrUIIPRestricted,
  ErrUnauthorized,
  ResponseError,
} from 'admin-sdk';

import { handleDomainRedirect } from './domain-redirect-provider/actions';
import { Domain } from './domain-redirect-provider/types';
import { AdminErrorSource } from './tracking/types';
import { asResponseError, parseErrorMessage } from './error_util';
import { getAdminClientState, getRouterState, getSettingsState } from './selectors';
import { track } from './tracking';
import { RootState } from './types';
import { hasStitchInUrl, rootUrl, withQueryParams } from './urls';

const REQUEST_ACTION = 'REQUEST';
const RECEIVE_ACTION = 'RECEIVE';
const FAIL_ACTION = 'FAIL';

export type AsyncDispatch = ThunkDispatch<RootState, undefined, Action>;
export type AsyncDispatchPayload<T> = Promise<T | void>;
export type AsyncExecutorPayload<T extends (...args: any) => any> = ReturnType<ReturnType<T>>;
export type AsyncThunkResult<R> = ThunkAction<R, RootState, undefined, Action>;

export interface FailurePayload<REQP> {
  error: string;
  rawError: ResponseError;
  reqArgs: REQP;
}

export interface SuccessPayload<RCVP, REQP> {
  payload: RCVP;
  reqArgs: REQP;
}

export interface AsyncActions<REQP, RCVP> {
  req: SimpleActionCreator<REQP>;
  rcv: SimpleActionCreator<SuccessPayload<RCVP, REQP>>;
  fail: SimpleActionCreator<FailurePayload<REQP>>;
}

/* Given a request and receive payload type returns an AsyncActions object
 * that contains actions for the following:
 *  req: when request is started
 *  rcv: when a successful result is received
 *  fail: when the request has failed
 *
 * see the types above for what will be in the final payload that is passed to the reducer
 */
export function makeAsyncActions<REQP, RCVP>(name: string): AsyncActions<REQP, RCVP> {
  return {
    req: createAction<REQP>(`${name} (${REQUEST_ACTION})`),
    rcv: createAction<SuccessPayload<RCVP, REQP>>(`${name} (${RECEIVE_ACTION})`),
    fail: createAction<FailurePayload<REQP>>(`${name} (${FAIL_ACTION})`),
  };
}

/* Given AsyncActions {req, rcv, fail},
 * returns a function which applies its argument objects to the given apiMethod using the client
 * currently in the redux store. It executes the API request, and dispatches the individual
 * async actions appopriately:
 *   req: when request is started
 *   rcv: when a successful result is received
 *   fail: when the request has failed
 * the AsyncActions passed in must have the same types
 */
export function asyncActionExecutor<REQP, RCVP>(
  asyncActions: AsyncActions<REQP, RCVP>,
  apiMethod: (client: BaasAdminClient | undefined, reqP: REQP) => () => Promise<RCVP>
) {
  // Dispatchers who intend on accessing the data directly in a dispatch need to make sure
  // they are checking the value for undefined (in the event of an invalid session and logout)
  // otherwise, they may throw a JavaScript error. For that reason, the return type of the
  // promise in this function should not get casted to RCVP.
  return (reqArgs: REQP): AsyncThunkResult<Promise<RCVP | void>> =>
    async (dispatch, getState) => {
      dispatch(asyncActions.req(reqArgs));
      const client = getAdminClientState(getState());
      try {
        const payload = await apiMethod(client, reqArgs)();
        dispatch(asyncActions.rcv({ payload, reqArgs }));
        return payload;
      } catch (e) {
        const error = asResponseError(e);

        if (
          error.code === ErrUnauthorized ||
          error.code === ErrInvalidSession ||
          error.code === ErrInvalidCloudSession ||
          error.code === ErrMissingSession
        ) {
          if (hasStitchInUrl(window.location.href)) {
            dispatch(handleDomainRedirect({ oldDomain: Domain.Stitch }));
            return undefined;
          }

          const history = getRouterState(getState()).history;
          // only push history once since multiple async actions may be in flight hitting
          // this same branch
          const loginUrl = rootUrl.login();
          if (history && history.location.pathname !== loginUrl) {
            let newUrl = loginUrl;
            if (typeof window !== 'undefined') {
              newUrl = withQueryParams(loginUrl, { nextURL: window.location.href });
            }
            history && history.push(newUrl);
          }

          // rethrow error here to prevent success handlers from being called in the event of a failure
          throw e;
        } else if (e.code === ErrUIIPRestricted) {
          const { cloudUIBaseUrl } = getSettingsState(getState());
          const newUrl = `${
            cloudUIBaseUrl || 'https://cloud-dev.mongodb.com'
          }/v2#/preferences/organizations?restrictedOrigin=appServices`;

          window.location.replace(newUrl);
          throw e;
        }

        const errorMessage = parseErrorMessage(error);

        // Track Bad Request and Conflict errors
        if (error.response?.status === 400 || error.response?.status === 409) {
          track('ADMIN_ERROR.ERROR_ENCOUNTERED', {
            error: errorMessage,
            source: AdminErrorSource.AdminRequest,
            statusCode: error.response.status,
          });
        }

        dispatch(
          asyncActions.fail({
            error: errorMessage,
            reqArgs,
            rawError: error,
          })
        );

        throw error;
      }
    };
}

export function createActionsAndExecutor<REQP, RCVP>(
  name: string,
  apiMethod: (client: BaasAdminClient, requestPayload: REQP) => () => Promise<RCVP>
) {
  const actions = makeAsyncActions<REQP, RCVP>(name);
  const executor = asyncActionExecutor(actions, apiMethod);

  return [actions, executor] as [typeof actions, typeof executor];
}

export interface AsyncDataState<T> {
  isLoading: boolean;
  isSaving?: boolean;
  isDeleting?: boolean;
  data: T | null;
  error?: string;
}

export const makeDefaultAsyncDataState = () => ({ isLoading: false, data: null });
