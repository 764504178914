import React from 'react';
import Modal from '@leafygreen-ui/modal';
import { Body, H3 } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';

import './collections-using-default-rule-modal.less';

export enum TestSelector {
  ModalC = 'collections-using-default-rule-modal',
  Title = 'collections-using-default-rule-modal-title',
  Description = 'collections-using-default-rule-modal-description',
  List = 'collections-using-default-rule-modal-list',
}

export interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  dataSourceName: string;
  namespaces: string[];
}

const baseClassName = 'collections-using-default-rule-modal';

export default function CollectionsUsingDefaultRuleModal({ open, setOpen, dataSourceName, namespaces }: Props) {
  return (
    <Modal
      className={baseClassName}
      contentClassName={`${baseClassName}-content`}
      open={open}
      setOpen={setOpen}
      data-testid={TestSelector.ModalC}
      data-cy={TestSelector.ModalC}
    >
      <H3 data-testid={TestSelector.Title}>Collections applying default roles and filters</H3>
      <Body className={`${baseClassName}-text`} data-testid={TestSelector.Description}>
        The existing collections below currently use your default rules, or will use your default rules upon{' '}
        <DocLink href={docLinks.Deploy.DeployUI} showExternalIcon>
          saving draft
        </DocLink>
        . Any new collections that are added to {dataSourceName} will also apply default rules.
      </Body>
      <ul className={`${baseClassName}-list`} data-testid={TestSelector.List}>
        {namespaces.map((name) => (
          <li key={name}>
            <Body className={`${baseClassName}-list-item`}>{name}</Body>
          </li>
        ))}
      </ul>
    </Modal>
  );
}
