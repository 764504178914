import React from 'react';

export const NEW_ID = 'NEW';
export const SORT_DOWN = 'down';
export const SORT_UP = 'up';
export const PRODUCT_SELF_HOSTED = 'self_hosted';
export const PRODUCT_CLOUD = 'cloud';
export const DEFAULT_BANNER_TIMEOUT = 10e3;
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY HH:mm:ss';
export const ABBR_DEFAULT_DATE_FORMAT = 'MM/DD/YYYY HH:mm';
export const DEFAULT_SAVE_STATUS_TIMEOUT = 3e3;
export const DEFAULT_SAVE_ERROR_MESSAGE = 'Changes could not be saved. Please resolve the errors above.';
export const NO_DRAFT_FLOW_MESSAGE =
  'Edits to this page will be updated in your application immediately and will not be included as draft changes.';
export const NO_DRAFT_FLOW_SECTION_MESSAGE =
  'Edits to this section will be updated in your application immediately and will not be included as draft changes.';
export const MASKED_INPUT_VALUE = 'XXXXXXXXXXXXXXXXXXXXXXXX';

export enum EnvironmentDesc {
  QA = 'qa',
  Prod = 'prod',
  Dev = 'dev',
  Local = 'local',
  Test = 'test',
  Staging = 'staging',
}

export const serviceDeprecationDate = 'September 30th, 2025';

export const SERVICE_DEPRECATION_MESSAGE = (
  <span>
    {`3rd Party Services and Push Notifications have been replaced with function dependencies.
    Support will be removed on `}{' '}
    <b>{serviceDeprecationDate}.</b>
  </span>
);
