import React from 'react';
import Code from '@leafygreen-ui/code';
import { Link } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import LeafygreenRouterLink from 'baas-ui/common/components/leafygreen-router-link';
import { docLinks } from 'baas-ui/common/links';
import GuideContentBody from 'baas-ui/guides/content/body';
import GuideFeedbackBlurb from 'baas-ui/guides/content/guide-feedback-blurb';
import GuideStepHeader from 'baas-ui/guides/step-header';
import { GuideContent } from 'baas-ui/guides/types';
import { rootUrl } from 'baas-ui/urls';

import 'baas-ui/guides/content/guide-content.less';

export enum TestSelector {
  EndpointsDocs = 'endpoints-docs',
  EndpointsConfigureDocs = 'endpoints-configure',
  EndpointsFunctionCodeSnippet = 'endpoints-code-snippet',
  EndpointsTestCodeSnippet = 'endpoints-test-code-snippet',
  CheckAtlasDataExplorer = 'check-atlas-data-explorer',
  GuidesLink = 'guides-link',
}

const endpointsGuideDataTarget = 'endpoints-guide';

const buildEndpointsGuide = ({ groupId, appId, cloudUIBaseUrl }): GuideContent => {
  const appUrl = rootUrl.groups().group(groupId).apps().app(appId);
  const guidesUrl = appUrl.guides();
  const newEndpointUrl = appUrl.endpoints().list();
  const atlasDataExplorerUrl = `${cloudUIBaseUrl}/v2/${groupId}#clusters`;

  return {
    header: 'HTTPS Endpoints',
    steps: [
      {
        body: (
          <div data-cy={`${endpointsGuideDataTarget}-introduction`}>
            <GuideContentBody>This guide walks you through an overview of HTTPS Endpoints:</GuideContentBody>
            <GuideStepHeader index={1} text="HTTPS Endpoints Introduction" />
            <GuideStepHeader index={2} text="Create an HTTPS Endpoint" />
            <GuideStepHeader index={3} text="Configure Your HTTPS Endpoint Settings" />
            <GuideStepHeader index={4} text="Write Your HTTPS Endpoint Logic" />
            <GuideStepHeader index={5} text="Test Your HTTPS Endpoint" />
            <GuideStepHeader index={6} text="Success" />
          </div>
        ),
        showProgress: false,
        nextStepTitle: 'Start',
        nextIsButton: true,
      },
      {
        url: newEndpointUrl,
        docsLink: docLinks.Endpoints.LearnMore,
        body: (
          <div data-cy={`${endpointsGuideDataTarget}-step-1`}>
            <GuideStepHeader isTitle index={1} text="HTTPS Endpoints Introduction" url={newEndpointUrl} />
            <GuideContentBody>
              <DocLink data-test-selector={TestSelector.EndpointsDocs} href={docLinks.Endpoints.LearnMore}>
                HTTPS Endpoints
              </DocLink>
              &nbsp;allow you to execute code in response to HTTP requests from an external app or service.
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Create an HTTPS Endpoint',
        nextIsButton: false,
      },
      {
        body: (
          <div data-cy={`${endpointsGuideDataTarget}-step-2`}>
            <GuideStepHeader isTitle index={2} text="Create an HTTPS Endpoint" />
            <GuideContentBody>
              Click <b>Add an Endpoint</b> to get started with your configuration.
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Configure Your HTTPS Endpoint Settings',
        nextIsButton: false,
      },
      {
        docsLink: docLinks.Endpoints.Configure,
        body: (
          <div data-cy={`${endpointsGuideDataTarget}-step-3`}>
            <GuideStepHeader isTitle index={3} text="Configure Your HTTPS Endpoint Settings" />
            <GuideContentBody>
              For the sake of this example, you can leave all the settings as is and ensure that the HTTP function is
              ‘POST’. To learn more about configuring your Endpoint, see&nbsp;
              <DocLink data-test-selector={TestSelector.EndpointsConfigureDocs} href={docLinks.Endpoints.Configure}>
                Configure HTTPS Endpoints.
              </DocLink>
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Write Your HTTPS Endpoint Logic',
        nextIsButton: false,
      },
      {
        body: (
          <div data-cy={`${endpointsGuideDataTarget}-step-4`}>
            <GuideStepHeader isTitle index={4} text="Write Your HTTPS Endpoint Logic" />
            <GuideContentBody>
              Copy this function code to insert a document into the “items” collection when your endpoint is called.
            </GuideContentBody>
            <GuideContentBody>
              A database change event object has the following form:
              <Code language="javascript" data-test-selector={TestSelector.EndpointsFunctionCodeSnippet}>
                {`exports = async function(payload, response) {
  // Convert the request body from BSON to a JSON object and then pull out relevant fields
  const { someField } = JSON.parse(payload.body.text());
  // If the request is missing required fields or something else is wrong, respond with an error
  if (!someField) {
    response.setStatusCode(400)
    response.setBody(\`Could not find "someField" in the endpoint request body.\`);
  }
  // Execute application logic, such as working with MongoDB
  const cluster = context.services.get('mongodb-atlas');
  const requests = cluster.db("demo").collection("requests");
  try {
    const { insertedId } = await requests.insertOne({ someField });
    // Respond with an affirmative result
    response.setStatusCode(200)
    response.setBody(\`Successfully created a document for the request with _id: \${insertedId}.\`);
  } catch (err) {
    // If the insert fails for some reason, respond with an error
    response.setStatusCode(500)
    response.setBody(\`Failed to create a document for the request. \${err}\`)
  }
}
`}
              </Code>
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Test Your HTTPS Endpoint',
        nextIsButton: false,
      },
      {
        body: (
          <div data-cy={`${endpointsGuideDataTarget}-step-5`}>
            <GuideStepHeader isTitle index={5} text="Test Your HTTPS Endpoint" />
            <GuideContentBody>
              Once you save and deploy your endpoint, you can test it by calling the following from your shell:
              <Code language="javascript" data-test-selector={TestSelector.EndpointsTestCodeSnippet}>
                {`curl \\\n
-H "Content-Type: application/json" \\\n
-d '{"someField":"someValue"}' \\\n
https://data.mongodb-api.com/app/${appId}/\\\n
endpoint/<Your Endpoint Route>
`}
              </Code>
            </GuideContentBody>
            <GuideContentBody>
              Notice that the endpoint has inserted a document into the `requests` collection by checking the&nbsp;
              <Link data-test-selector={TestSelector.CheckAtlasDataExplorer} href={atlasDataExplorerUrl}>
                Atlas Data Explorer
              </Link>
              .
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Success',
        nextIsButton: false,
      },
      {
        body: (
          <div data-cy={`${endpointsGuideDataTarget}-conclusion`}>
            <GuideStepHeader isTitle text="Success" />
            <GuideContentBody>
              Congratulations - you’ve built and tested your first HTTPS Endpoint. To learn more about HTTPS Endpoints
              in App Services, you can visit our&nbsp;
              <DocLink data-test-selector={TestSelector.EndpointsDocs} href={docLinks.Endpoints.LearnMore}>
                documentation.
              </DocLink>
            </GuideContentBody>
            <GuideContentBody>
              Be sure to check out{' '}
              <LeafygreenRouterLink data-test-selector={TestSelector.GuidesLink} to={guidesUrl}>
                other guides
              </LeafygreenRouterLink>{' '}
              to learn more about other services.
            </GuideContentBody>
            <GuideFeedbackBlurb />
          </div>
        ),
        showProgress: false,
        nextStepTitle: 'Finish',
        nextIsButton: true,
      },
    ],
  };
};

export default buildEndpointsGuide;
