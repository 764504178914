import React from 'react';
import { connect } from 'react-redux';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import { RadioBox, RadioBoxGroup } from '@leafygreen-ui/radio-box-group';
import { Description, H3 } from '@leafygreen-ui/typography';
import PropTypes from 'prop-types';

import * as coreActions from 'baas-ui/actions';
import { FormRow, FormRowInputGroup, FormRowLabelGroup } from 'baas-ui/common/components/form-row';
import { MAX_RESOURCE_NAME_LENGTH } from 'baas-ui/constants';
import { BreadcrumbsItem } from 'baas-ui/nav';
import { getServiceState, getSettingsState, getValuesState } from 'baas-ui/selectors';
import { secretFieldNameBySvcType, servicesByType, SVCTYPE_AWS_S3, SVCTYPE_AWS_SES } from 'baas-ui/services/registry';
import * as svcSagas from 'baas-ui/services/sagas';
import urls from 'baas-ui/urls';
import * as valueActions from 'baas-ui/values/actions';
import { SECRETS_SHAPE } from 'baas-ui/values/proptypes';

const SERVICE_NAME_ID = 'service-name';

class AddService extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeSecretOption = this.onChangeSecretOption.bind(this);
    this.updateFormData = this.updateFormData.bind(this);
    this.addService = this.addService.bind(this);
    this.cancel = this.cancel.bind(this);
    this.state = {
      name: '',
      formElements: {},
      selectedType: '',
      selectedSecret: undefined,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedType } = this.state;

    if (selectedType !== prevState.selectedType && secretFieldNameBySvcType[selectedType]) {
      this.props.loadSecrets();
    }
  }

  onChangeSecretOption(opt) {
    this.setState({ selectedSecret: opt });
  }

  addService() {
    const { name, formElements, selectedType, selectedSecret } = this.state;
    const { createSecret, onCreateService } = this.props;
    const secretFieldName = secretFieldNameBySvcType[selectedType];
    let prom = Promise.resolve();

    if (selectedSecret && selectedSecret.__isNew__) {
      prom = createSecret({
        name: selectedSecret.value,
        value: formElements[secretFieldName],
      });
    }

    const serviceConfigPayload = {
      name,
      type: selectedType,
      config: { ...formElements },
    };

    delete serviceConfigPayload.config[secretFieldName];

    if (selectedSecret) {
      serviceConfigPayload.secretConfig = {
        [secretFieldName]: selectedSecret.value,
      };
    }

    return prom
      .then(() => onCreateService(serviceConfigPayload).catch(() => {}))
      .then(() =>
        this.setState((prevState) => ({
          ...prevState,
          selectedSecret: {
            ...prevState.selectedSecret,
            __isNew__: false,
          },
        }))
      )
      .catch(() => {});
  }

  updateFormData({ name = '', selectedType = '', formElements = {} }) {
    this.setState({
      selectedType,
      name,
      formElements,
    });
  }

  cancel() {
    return this.props.onCancel();
  }

  render() {
    const { name, selectedType, selectedSecret, formElements } = this.state;
    const { configForm: ConfigForm } = servicesByType.get(selectedType, {});
    const { createServiceError, product, services, secrets, loadingSecrets, secretSaveError } = this.props;

    // hide S3 and SES servics if app does not use them already
    const usingDepAwsSvcs = services.some((svc) => svc.type === SVCTYPE_AWS_S3 || svc.type === SVCTYPE_AWS_SES);
    const canSubmit = name.length > 0 && selectedType.length > 0;

    return (
      <>
        <BreadcrumbsItem>Add a Service</BreadcrumbsItem>
        <div className="section-header">
          <div className="section-header-title">
            <H3>Add a Service</H3>
          </div>
        </div>
        {(createServiceError || secretSaveError) && (
          <Banner className="add-service-error-banner" variant={Variant.Danger}>
            {createServiceError || secretSaveError}
          </Banner>
        )}
        <RadioBoxGroup size="full" value={selectedType} className="add-service">
          {servicesByType
            .entrySeq()
            .filter(([, svc]) => !svc.hideAdd && (!svc.forProduct || svc.forProduct === product))
            .filter(([, svc]) => usingDepAwsSvcs || (svc.type !== 'aws-ses' && svc.type !== 'aws-s3'))
            .map(([type, svc]) => {
              return (
                <RadioBox
                  key={type}
                  data-id={`radio-box-for-${type}`}
                  data-cy={`radio-box-for-${type}`}
                  className="add-service-radio-box"
                  checked={selectedType === type}
                  onClick={() => {
                    if (selectedType !== type) {
                      this.updateFormData({ name: '', selectedType: type, formElements: {} });
                    }
                  }}
                  value={type}
                >
                  <div className="add-service-radio-box-illustration">{React.createElement(svc.icon)}</div>
                  <div>
                    <div className="add-service-radio-box-illustration-title">{svc.getLongDisplayName()}</div>
                    <div className="add-service-radio-box-illustration-description">{svc.description}</div>
                  </div>
                </RadioBox>
              );
            })}
        </RadioBoxGroup>
        <div>
          <FormRow>
            <FormRowLabelGroup>
              <label className="form-row-label" htmlFor={SERVICE_NAME_ID}>
                Service Name
              </label>
              <Description className="form-row-description">
                Enter a unique name for this service. You can add multiple instances of any type of service.
              </Description>
            </FormRowLabelGroup>
            <FormRowInputGroup>
              <input
                type="text"
                id={SERVICE_NAME_ID}
                name="serviceName"
                data-cy="service-name-input"
                className="text-input form-row-text-input"
                placeholder="Service Name"
                onChange={(e) => this.updateFormData({ name: e.target.value, selectedType, formElements })}
                value={name}
                maxLength={MAX_RESOURCE_NAME_LENGTH}
              />
            </FormRowInputGroup>
          </FormRow>
          {ConfigForm && (
            <div className="add-service-form">
              <ConfigForm
                secrets={secrets}
                loadingSecrets={loadingSecrets}
                selectedSecret={selectedSecret}
                onChangeSecretOption={this.onChangeSecretOption}
                formElements={this.state.formElements}
                onChange={(updatedFormElements) =>
                  this.updateFormData({ name, selectedType, formElements: updatedFormElements })
                }
              />
            </div>
          )}
        </div>
        <footer className="add-service-footer">
          <div className="add-service-footer-actions">
            <Button className="cancel-add-service" onClick={this.cancel} name="cancelService">
              Cancel
            </Button>
            <Button
              variant="primary"
              className="save-add-service"
              disabled={!canSubmit}
              onClick={this.addService}
              name="addService"
              data-cy="add-service-button"
            >
              Add Service
            </Button>
          </div>
        </footer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () =>
    dispatch(
      coreActions.redirectTo(urls.groups().group(ownProps.app.groupId).apps().app(ownProps.app.id).services().list(), {
        replace: true,
      })
    ),
  createSecret: (newSecret) =>
    dispatch(valueActions.createSecret({ groupId: ownProps.app.groupId, appId: ownProps.app.id, secret: newSecret })),
  onCreateService: (svcConfig) => dispatch(svcSagas.createService(ownProps.app.groupId, ownProps.app.id, svcConfig)),
  loadSecrets: () => dispatch(valueActions.loadSecrets({ groupId: ownProps.app.groupId, appId: ownProps.app.id })),
});

const mapStateToProps = (state) => {
  const { product } = getSettingsState(state);
  const { createServiceError } = getServiceState(state);
  const { secrets } = getValuesState(state);
  return {
    product,
    createServiceError,
    secrets: secrets.data || [],
    loadingSecrets: secrets.isLoading,
    secretSaveError: secrets.error,
  };
};

AddService.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onCreateService: PropTypes.func.isRequired,
  createSecret: PropTypes.func.isRequired,
  loadSecrets: PropTypes.func.isRequired,
  createServiceError: PropTypes.string,
  secretSaveError: PropTypes.string,
  product: PropTypes.string.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    })
  ),
  secrets: SECRETS_SHAPE,
  loadingSecrets: PropTypes.bool,
};

AddService.defaultProps = {
  createServiceError: '',
  secretSaveError: '',
  services: [],
  secrets: [],
  loadingSecrets: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddService);
