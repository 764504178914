import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Repository')
export class Repository {
  @JsonProperty('id')
  public id = '';

  @JsonProperty('full_name')
  public fullName = '';

  @JsonProperty('url')
  public url = '';

  constructor(partial?: Partial<Repository>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AutomaticDeploymentConfig')
export class AutomaticDeploymentConfig {
  @JsonProperty('enabled')
  public enabled = false;

  @JsonProperty('provider', String, true)
  public providerType?: string = undefined;

  @JsonProperty('installation_ids')
  public installationIds: string[] = [];

  @JsonProperty('repository', Repository, true)
  public repository?: Repository = undefined;

  @JsonProperty('branch', String, true)
  public branch?: string = undefined;

  @JsonProperty('directory', String, true)
  public directory?: string = undefined;

  @JsonProperty('last_modified')
  public lastModified = 0;

  constructor(partial?: Partial<AutomaticDeploymentConfig>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PartialCodeDeploy')
export class PartialCodeDeploy {
  @JsonProperty('ui_drafts_disabled')
  public uiDraftsDisabled = false;

  @JsonProperty('automatic_deployment')
  public automaticDeploymentConfig: AutomaticDeploymentConfig = new AutomaticDeploymentConfig();

  constructor(partial?: Partial<PartialCodeDeploy>) {
    Object.assign(this, partial);
  }
}

@JsonObject('Installation')
export class Installation {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('provider')
  public providerType = '';

  @JsonProperty('installation_id')
  public installationId = '';

  @JsonProperty('owner_id')
  public ownerId = '';

  @JsonProperty('authenticated_repositories', [Repository])
  public authenticatedRepositories: Repository[] = [];

  constructor(partial?: Partial<Installation>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AutomaticDeploymentConfigUpdateRequest')
export class AutomaticDeploymentConfigUpdateRequest {
  @JsonProperty('enabled', Boolean, true)
  public enabled?: boolean = undefined;

  constructor(partial?: Partial<AutomaticDeploymentConfigUpdateRequest>) {
    Object.assign(this, partial);
  }
}

@JsonObject('CodeDeployUpdateRequest')
export class CodeDeployUpdateRequest {
  @JsonProperty('ui_drafts_disabled', Boolean, true)
  public uiDraftsDisabled?: boolean = undefined;

  @JsonProperty('automatic_deployment', AutomaticDeploymentConfigUpdateRequest, true)
  public automaticDeploymentConfig?: AutomaticDeploymentConfigUpdateRequest = undefined;

  constructor(partial?: Partial<CodeDeployUpdateRequest>) {
    Object.assign(this, partial);
  }
}

@JsonObject('GitHubBranch')
export class GitHubBranch {
  @JsonProperty('name')
  public name = '';

  constructor(partial?: Partial<GitHubBranch>) {
    Object.assign(this, partial);
  }
}

@JsonObject('GitHubDirectory')
export class GitHubDirectory {
  @JsonProperty('name')
  public name = '';

  constructor(partial?: Partial<GitHubDirectory>) {
    Object.assign(this, partial);
  }
}

@JsonObject('GithubGroupAuthenticationResponse')
export class GithubGroupAuthenticationResponse {
  @JsonProperty('request_id')
  public requestId = '';

  @JsonProperty('request_timestamp')
  public requestTimestamp = '';

  @JsonProperty('github_redirect_url')
  public githubRedirectUrl = '';

  constructor(partial?: Partial<GithubGroupAuthenticationResponse>) {
    Object.assign(this, partial);
  }
}

@JsonObject('GithubPushAppRequest')
export class GithubPushAppRequest {
  @JsonProperty('draftId', String, true)
  public draftId?: string = undefined;

  @JsonProperty('deploymentName', String, true)
  public deploymentName?: string = undefined;

  @JsonProperty('force', Boolean, true)
  public force?: boolean = undefined;

  @JsonProperty('frontendDirectory', String, true)
  public frontendDirectory?: string = undefined;

  constructor(partial?: Partial<GithubPushAppRequest>) {
    Object.assign(this, partial);
  }
}

@JsonObject('EnableTTLInstallationAutoDeployRequest')
export class EnableTTLInstallationAutoDeployRequest {
  @JsonProperty('github_auth_request_id')
  public githubAuthRequestId = '';

  @JsonProperty('repository_id')
  public repositoryId = '';

  @JsonProperty('branch')
  public branch = '';

  @JsonProperty('directory')
  public directory = '';

  constructor(partial?: Partial<EnableTTLInstallationAutoDeployRequest>) {
    Object.assign(this, partial);
  }
}
