import { createReducer } from 'redux-act';

import { prettyJSONStringify } from 'baas-ui/common/utils/util';
import { AsyncDataState, makeDefaultAsyncDataState } from 'baas-ui/redux_util';
import { EnvironmentValue, PartialEnvironmentValue, PartialSecret, PartialValue, Secret, Value } from 'admin-sdk';

import * as actions from './actions';

// Redux state types
export interface ValuesState {
  editingValue: AsyncDataState<Value>;
  editingSecret: AsyncDataState<Secret>;
  editingEnvironmentValue: AsyncDataState<EnvironmentValue>;

  values: AsyncDataState<PartialValue[]>;
  secrets: AsyncDataState<PartialSecret[]>;
  environmentValues: AsyncDataState<PartialEnvironmentValue[]>;
}

export const defaultState: ValuesState = {
  editingValue: makeDefaultAsyncDataState(),
  editingSecret: makeDefaultAsyncDataState(),
  editingEnvironmentValue: makeDefaultAsyncDataState(),

  values: makeDefaultAsyncDataState(),
  secrets: makeDefaultAsyncDataState(),
  environmentValues: makeDefaultAsyncDataState(),
};

const reducer = createReducer<ValuesState>({}, defaultState);

reducer.on(actions.loadValuesActions.req, (state) => ({
  ...state,
  values: {
    ...state.values,
    isLoading: true,
  },
}));
reducer.on(actions.loadValuesActions.rcv, (state, { payload }) => ({
  ...state,
  values: {
    ...state.values,
    isLoading: false,
    data: payload,
    error: undefined,
  },
}));
reducer.on(actions.loadValuesActions.fail, (state, { error }) => ({
  ...state,
  values: {
    ...state.values,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.loadValueActions.req, (state) => ({
  ...state,
  editingValue: {
    ...state.editingValue,
    isLoading: true,
  },
}));
reducer.on(actions.loadValueActions.rcv, (state, { payload }) => ({
  ...state,
  editingValue: {
    ...state.editingValue,
    isLoading: false,
    data: {
      ...payload,
      value: typeof payload.value !== 'string' ? prettyJSONStringify(payload.value) : payload.value,
    },
    error: undefined,
  },
}));
reducer.on(actions.loadValueActions.fail, (state, { error }) => ({
  ...state,
  editingValue: {
    ...state.editingValue,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.updateValueActions.req, (state) => ({
  ...state,
  editingValue: {
    ...state.editingValue,
    isLoading: true,
  },
}));
reducer.on(actions.updateValueActions.rcv, (state, { reqArgs }) => ({
  ...state,
  editingValue: {
    ...state.editingValue,
    isLoading: false,
    data: {
      ...reqArgs.value,
      value: typeof reqArgs.value.value !== 'string' ? prettyJSONStringify(reqArgs.value.value) : reqArgs.value.value,
    },
    error: undefined,
  },
}));
reducer.on(actions.updateValueActions.fail, (state, { error }) => ({
  ...state,
  editingValue: {
    ...state.editingValue,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.createValueActions.req, (state) => ({
  ...state,
  editingValue: {
    ...state.editingValue,
    isLoading: true,
  },
}));
reducer.on(actions.createValueActions.rcv, (state, { payload }) => ({
  ...state,
  editingValue: {
    ...state.editingValue,
    isLoading: false,
    data: {
      ...payload,
      value: typeof payload.value !== 'string' ? prettyJSONStringify(payload.value) : payload.value,
    },
    error: undefined,
  },
}));
reducer.on(actions.createValueActions.fail, (state, { error }) => ({
  ...state,
  editingValue: {
    ...state.editingValue,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.deleteValueActions.req, (state) => ({
  ...state,
  values: {
    ...state.values,
    isLoading: true,
  },
}));
reducer.on(actions.deleteValueActions.rcv, (state, { reqArgs }) => ({
  ...state,
  values: {
    ...state.values,
    isLoading: false,
    data: state.values.data?.filter(({ id }) => id !== reqArgs.id) || [],
    error: undefined,
  },
}));
reducer.on(actions.deleteValueActions.fail, (state, { error }) => ({
  ...state,
  values: {
    ...state.values,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.loadSecretsActions.req, (state) => ({
  ...state,
  secrets: {
    ...state.secrets,
    isLoading: true,
  },
}));
reducer.on(actions.loadSecretsActions.rcv, (state, { payload }) => ({
  ...state,
  secrets: {
    ...state.values,
    isLoading: false,
    data: payload,
    error: undefined,
  },
}));
reducer.on(actions.loadSecretsActions.fail, (state, { error }) => ({
  ...state,
  secrets: {
    ...state.secrets,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.updateSecretActions.req, (state) => ({
  ...state,
  editingSecret: {
    ...state.editingSecret,
    isLoading: true,
  },
}));
reducer.on(actions.updateSecretActions.rcv, (state, { reqArgs }) => ({
  ...state,
  editingSecret: {
    ...state.values,
    isLoading: false,
    data: reqArgs.secret,
    error: undefined,
  },
}));
reducer.on(actions.updateSecretActions.fail, (state, { error }) => ({
  ...state,
  editingSecret: {
    ...state.editingSecret,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.discardEditingValues, (state) => {
  return {
    ...state,
    editingSecret: makeDefaultAsyncDataState(),
    editingValue: makeDefaultAsyncDataState(),
    editingEnvironmentValue: makeDefaultAsyncDataState(),
  };
});

reducer.on(actions.createSecretActions.req, (state) => ({
  ...state,
  editingSecret: {
    ...state.editingSecret,
    isLoading: true,
  },
}));
reducer.on(actions.createSecretActions.rcv, (state, { payload }) => ({
  ...state,
  secrets: {
    ...state.secrets,
    data: [...(state.secrets.data || []), new PartialSecret(payload)],
  },
  editingSecret: {
    ...state.editingSecret,
    isLoading: false,
    data: payload,
    error: undefined,
  },
}));
reducer.on(actions.createSecretActions.fail, (state, { error }) => ({
  ...state,
  editingSecret: {
    ...state.editingSecret,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.deleteSecretActions.req, (state) => ({
  ...state,
  secrets: {
    ...state.secrets,
    isLoading: true,
  },
}));
reducer.on(actions.deleteSecretActions.rcv, (state, { reqArgs }) => ({
  ...state,
  secrets: {
    ...state.secrets,
    isLoading: false,
    data: state.secrets.data?.filter(({ id }) => id !== reqArgs.id) || [],
    error: undefined,
  },
}));
reducer.on(actions.deleteSecretActions.fail, (state, { error, rawError }) => {
  // hide internal server error messages from user
  if (rawError.response.status === 500) {
    error = 'internal server error';
  }
  return {
    ...state,
    secrets: {
      ...state.secrets,
      isLoading: false,
      error,
    },
  };
});

reducer.on(actions.loadEnvironmentValuesActions.req, (state) => ({
  ...state,
  environmentValues: {
    ...state.environmentValues,
    isLoading: true,
  },
}));
reducer.on(actions.loadEnvironmentValuesActions.rcv, (state, { payload }) => ({
  ...state,
  environmentValues: {
    ...state.environmentValues,
    isLoading: false,
    data: payload,
    error: undefined,
  },
}));
reducer.on(actions.loadEnvironmentValuesActions.fail, (state, { error }) => ({
  ...state,
  environmentValues: {
    ...state.environmentValues,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.loadEnvironmentValueActions.req, (state) => ({
  ...state,
  editingEnvironmentValue: {
    ...state.editingEnvironmentValue,
    isLoading: true,
  },
}));
reducer.on(actions.loadEnvironmentValueActions.rcv, (state, { payload }) => ({
  ...state,
  editingEnvironmentValue: {
    ...state.editingEnvironmentValue,
    isLoading: false,
    data: payload,
    error: undefined,
  },
}));
reducer.on(actions.loadEnvironmentValueActions.fail, (state, { error }) => ({
  ...state,
  editingEnvironmentValue: {
    ...state.editingEnvironmentValue,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.updateEnvironmentValueActions.req, (state) => ({
  ...state,
  editingEnvironmentValue: {
    ...state.editingEnvironmentValue,
    isLoading: true,
  },
}));
reducer.on(actions.updateEnvironmentValueActions.rcv, (state, { reqArgs }) => ({
  ...state,
  editingEnvironmentValue: {
    ...state.editingEnvironmentValue,
    isLoading: false,
    data: reqArgs.environmentValue,
    error: undefined,
  },
}));
reducer.on(actions.updateEnvironmentValueActions.fail, (state, { error }) => ({
  ...state,
  editingEnvironmentValue: {
    ...state.editingEnvironmentValue,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.createEnvironmentValueActions.req, (state) => ({
  ...state,
  editingEnvironmentValue: {
    ...state.editingEnvironmentValue,
    isLoading: true,
  },
}));
reducer.on(actions.createEnvironmentValueActions.rcv, (state, { payload }) => ({
  ...state,
  editingEnvironmentValue: {
    ...state.editingEnvironmentValue,
    isLoading: false,
    data: payload,
    error: undefined,
  },
}));
reducer.on(actions.createEnvironmentValueActions.fail, (state, { error }) => ({
  ...state,
  editingEnvironmentValue: {
    ...state.editingEnvironmentValue,
    isLoading: false,
    error,
  },
}));

reducer.on(actions.deleteEnvironmentValueActions.req, (state) => ({
  ...state,
  environmentValues: {
    ...state.environmentValues,
    isLoading: true,
  },
}));
reducer.on(actions.deleteEnvironmentValueActions.rcv, (state, { reqArgs }) => ({
  ...state,
  environmentValues: {
    ...state.environmentValues,
    isLoading: false,
    data: state.environmentValues.data?.filter(({ id }) => id !== reqArgs.id) || [],
    error: undefined,
  },
}));
reducer.on(actions.deleteEnvironmentValueActions.fail, (state, { error }) => ({
  ...state,
  environmentValues: {
    ...state.environmentValues,
    isLoading: false,
    error,
  },
}));

export default reducer;
