import React from 'react';
import { Body, Subtitle } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import { useDarkMode } from 'baas-ui/theme';

import './explorer-header.less';

const baseClassName = 'rules-explorer-header';
const footerClassName = `${baseClassName}-footer`;
const footerMaskClassName = `${footerClassName}-mask`;
const contentClassName = `${baseClassName}-content`;
const countersClassName = `${contentClassName}-counters`;
const counterItemClassName = `${countersClassName}-item`;
const itemNumberClassName = `${counterItemClassName}-number`;

export enum TestSelector {
  HeaderContainer = 'header-container',
  CollectionsTab = 'collections-tab',
  ClusterCount = 'cluster-count',
  DatabaseCount = 'database-count',
  CollectionCount = 'collection-count',
}

export interface Props {
  className?: string;
  clusterCount?: number;
  databaseCount?: number;
  collectionCount?: number;
}

const ExplorerHeader = ({ className, clusterCount = 0, databaseCount = 0, collectionCount = 0 }: Props) => {
  const darkMode = useDarkMode();
  return (
    <div
      className={classNames(baseClassName, className, {
        [`${baseClassName}-light-mode`]: !darkMode,
        [`${baseClassName}-dark-mode`]: darkMode,
      })}
      data-test-selector={TestSelector.HeaderContainer}
    >
      <div className={contentClassName}>
        <Subtitle>Collections</Subtitle>
        <div className={countersClassName}>
          <span className={counterItemClassName}>
            <Body>Clusters:</Body>
            <Body weight="medium" className={itemNumberClassName} data-test-selector={TestSelector.ClusterCount}>
              {clusterCount}
            </Body>
          </span>
          <span className={counterItemClassName}>
            <Body>Databases:</Body>
            <Body weight="medium" className={itemNumberClassName} data-test-selector={TestSelector.DatabaseCount}>
              {databaseCount}
            </Body>
          </span>
          <span className={counterItemClassName}>
            <Body>Collections:</Body>
            <Body weight="medium" className={itemNumberClassName} data-test-selector={TestSelector.CollectionCount}>
              {collectionCount}
            </Body>
          </span>
        </div>
      </div>
      <div
        className={classNames(footerClassName, {
          [`${footerClassName}-light-mode`]: !darkMode,
          [`${footerClassName}-dark-mode`]: darkMode,
        })}
      >
        <div className={`${footerMaskClassName} ${footerMaskClassName}-left`} />
        <div className={`${footerMaskClassName} ${footerMaskClassName}-right`} />
      </div>
    </div>
  );
};

export default ExplorerHeader;
