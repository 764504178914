import React from 'react';
import Banner from '@leafygreen-ui/banner';
import { Body } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';

export interface Props {
  className?: string;
}

const AppsUserPermissionsBanner = ({ className }: Props) => (
  <Banner variant="danger" className={className}>
    <Body>You do not have the required permissions to create/edit applications for this project.</Body>
    <Body>
      {`Creating/Editing an App Service requires the `}
      <DocLink href={docLinks.Atlas.UserRoles}>Project Owner</DocLink>
      {` role.`}
    </Body>
  </Banner>
);

export default AppsUserPermissionsBanner;
