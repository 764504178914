import React from 'react';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import GuideContentBody from 'baas-ui/guides/content/body';
import GuideFeedbackBlurb from 'baas-ui/guides/content/guide-feedback-blurb';
import GuideStepHeader from 'baas-ui/guides/step-header';
import { GuideContent, GuideName } from 'baas-ui/guides/types';
import { rootUrl } from 'baas-ui/urls';

import 'baas-ui/guides/content/guide-content.less';

export enum TestSelector {
  AndroidDocs = 'android-docs',
  IOSDocs = 'ios-docs',
  JSDocs = 'js-docs',
  RNDocs = 'rn-docs',
  DotNetDocs = 'dotnet-docs',
  KotlinDocs = 'kotlin-docs',
  FlutterDocs = 'flutter-docs',
  CPPDocs = 'cpp-docs',
  LoadSampleDataDocs = 'load-sample-data',
  QueryableFieldsDocs = 'queryable-fields-docs',
  SyncPermissionsDocs = 'sync-permissions-docs',
}

const guideContentClassName = 'guide-content';
const syncGuideDataTarget = 'sync-guide';

const buildSyncGuide = ({ groupId, appId }): GuideContent => {
  const appUrl = rootUrl.groups().group(groupId).apps().app(appId);
  const schemaUrl = appUrl.schemas().root();
  const syncConfigUrl = appUrl.sync().config();
  const dataModelsUrl = appUrl.sdks().dataModels();
  const sdksUrl = appUrl.sdks().list();

  return {
    header: 'Set up Sync',
    steps: [
      {
        showProgress: false,
        body: (
          <div data-cy={`${syncGuideDataTarget}-introduction`}>
            <GuideContentBody>
              This guide walks you through setting up Device Sync for your application. You will:
            </GuideContentBody>
            <GuideStepHeader
              index={1}
              text="Generate a JSON Schema"
              subtext="Ensure only data that matches your schema is synced"
            />
            <GuideStepHeader index={2} text="Configure Sync" subtext="Quick and easy setup to begin synchronizing" />
            <GuideStepHeader
              index={3}
              text="Choose Queryable Fields"
              subtext="Define which fields devices will query on"
            />
            <GuideStepHeader index={4} text="Set Permissions" subtext="Restrict what data users can read and write" />
            <GuideStepHeader
              index={5}
              text="View Realm Object Models"
              subtext="Define and validate data in your mobile client app"
            />
            <GuideStepHeader
              index={6}
              text="Connect to your Application"
              subtext="Use one of the Atlas Device SDKs to begin synchronizing data"
            />
          </div>
        ),
        nextStepTitle: 'Start',
        nextIsButton: true,
      },
      {
        url: schemaUrl,
        showProgress: true,
        body: (
          <div data-cy={`${syncGuideDataTarget}-generate-schema-v2`}>
            <GuideStepHeader isTitle index={1} text="Generate a JSON Schema" url={schemaUrl} />
            <GuideContentBody>
              Your app creates Object Models for collections in your Atlas cluster that have defined a document schema.
            </GuideContentBody>
            <GuideContentBody>
              Select a collection you would like to set up a document schema for and{' '}
              <b>click &apos;Generate a Schema&apos;</b>. App Services will use this schema to enforce validation and
              synchronization in the cloud, and will translate the schema into Object Models you can include in your
              mobile application.
            </GuideContentBody>
            <GuideContentBody>
              Your app will generate a JSON Schema for you based on data in your collection. You may keep the default #
              of sample documents. When ready, <b>click &apos;Generate Schema from sampling&apos;</b> and save changes
              to view your JSON Schema.
            </GuideContentBody>
            <GuideContentBody>
              (Optional) If you don’t have existing data to work with, you can load sample data into your cluster
              through Atlas.{' '}
              <DocLink data-test-selector={TestSelector.LoadSampleDataDocs} href={docLinks.Atlas.LoadSampleData}>
                Learn how to load Sample Data.
              </DocLink>
            </GuideContentBody>
          </div>
        ),
        docsLink: docLinks.MongoDB.GenerateSchema,
        nextStepTitle: 'Configure Sync',
        nextIsButton: false,
      },
      {
        url: syncConfigUrl,
        showProgress: true,
        body: (
          <div data-cy={`${syncGuideDataTarget}-configure-sync-cluster`}>
            <GuideStepHeader isTitle index={2} text="Configure Sync - Cluster" url={syncConfigUrl} />
            <GuideContentBody>Sync is configured for a single Atlas cluster.</GuideContentBody>
            <GuideContentBody>
              <b>Select the cluster</b> you would like to sync.
            </GuideContentBody>
          </div>
        ),
        docsLink: docLinks.Sync.ConfigureSync,
        nextStepTitle: 'Queryable Fields',
        nextIsButton: false,
      },
      {
        url: syncConfigUrl,
        showProgress: true,
        body: (
          <div data-cy={`${syncGuideDataTarget}-configure-sync-queryable-fields`}>
            <GuideStepHeader isTitle index={3} text="Configure Sync - Queryable Fields" url={syncConfigUrl} />
            <GuideContentBody>
              Queryable fields define a subset of fields in your MongoDB documents or Realm Object models that SDKs are
              able to define subscriptions on.{' '}
            </GuideContentBody>
            <GuideContentBody>
              <DocLink data-test-selector={TestSelector.QueryableFieldsDocs} href={docLinks.Sync.QueryableFields}>
                Learn more about queryable fields.
              </DocLink>
            </GuideContentBody>
          </div>
        ),
        docsLink: docLinks.Sync.QueryableFields,
        nextStepTitle: 'Permissions',
        nextIsButton: false,
      },
      {
        url: syncConfigUrl,
        showProgress: true,
        body: (
          <div data-cy={`${syncGuideDataTarget}-configure-sync-permissions`}>
            <GuideStepHeader isTitle index={4} text="Configure Sync - Permissions" url={syncConfigUrl} />
            <GuideContentBody>
              Sync permissions allow you to control who can sync, read, and write data.
            </GuideContentBody>
            <GuideContentBody>
              Permissions are defined in the Rules page. If no rules are currently set you may select from a template
              option after you enable sync.
            </GuideContentBody>
            <GuideContentBody>
              <DocLink data-test-selector={TestSelector.SyncPermissionsDocs} href={docLinks.Rules.RolesFlexibleSync}>
                Learn more about sync permissions.
              </DocLink>
            </GuideContentBody>
            <GuideContentBody>
              When you&apos;re ready, <b>click &apos;Enable Sync&apos;</b>
            </GuideContentBody>
          </div>
        ),
        docsLink: docLinks.Rules.RolesFlexibleSync,
        nextStepTitle: 'Object Models',
        nextIsButton: false,
      },
      {
        url: dataModelsUrl,
        showProgress: true,
        body: (
          <div data-cy={`${syncGuideDataTarget}-view-realm-object-models`}>
            <GuideStepHeader isTitle index={5} text="View Realm Object Models" url={dataModelsUrl} />
            <GuideContentBody>
              View your Realm Object Model in your language of choice. You can immediately use the generated Object
              Models to prevent improper data from entering your database from your mobile client.
            </GuideContentBody>
            <GuideContentBody>
              <b>Click the Copy icon</b> for specific objects or <b>Copy All Data Models</b> to include in your code.
            </GuideContentBody>
          </div>
        ),
        docsLink: docLinks.Sync.ConfigureDataModel,
        nextStepTitle: 'Connect via SDK',
        nextIsButton: false,
      },
      {
        url: sdksUrl,
        showProgress: true,
        body: (
          <div data-cy={`${syncGuideDataTarget}-connect-to-app`}>
            <GuideStepHeader isTitle index={6} text="Connect to your Application" url={sdksUrl} />
            <GuideContentBody>
              Install an Atlas Device SDK to connect your client application to App Services.
            </GuideContentBody>
            <GuideContentBody>View the instructions in the Docs:</GuideContentBody>
            <div className={`${guideContentClassName}-links`}>
              <li>
                <DocLink data-test-selector={TestSelector.AndroidDocs} href={docLinks.SDKs.Android.QuickStart}>
                  Android (Java)
                </DocLink>
              </li>
              <li>
                <DocLink data-test-selector={TestSelector.IOSDocs} href={docLinks.SDKs.iOS.QuickStart}>
                  iOS (Swift)
                </DocLink>
              </li>
              <li>
                <DocLink data-test-selector={TestSelector.JSDocs} href={docLinks.SDKs.Node.QuickStart}>
                  NodeJS
                </DocLink>
              </li>
              <li>
                <DocLink data-test-selector={TestSelector.RNDocs} href={docLinks.SDKs.ReactNative.QuickStart}>
                  React Native
                </DocLink>
              </li>
              <li>
                <DocLink data-test-selector={TestSelector.KotlinDocs} href={docLinks.SDKs.Kotlin.QuickStart}>
                  Kotlin (Android, Kotlin Multiplatform, JVM)
                </DocLink>
              </li>
              <li>
                <DocLink data-test-selector={TestSelector.DotNetDocs} href={docLinks.SDKs.DotNet.QuickStart}>
                  .NET (Xamarin, MAUI, Console)
                </DocLink>
              </li>
              <li>
                <DocLink data-test-selector={TestSelector.FlutterDocs} href={docLinks.SDKs.Flutter.QuickStart}>
                  Flutter (Dart)
                </DocLink>
              </li>
              <li>
                <DocLink data-test-selector={TestSelector.CPPDocs} href={docLinks.SDKs.CPP.QuickStart}>
                  C++
                </DocLink>
              </li>
            </div>
          </div>
        ),
        docsLink: docLinks.SDKs.Android.Intro,
        nextStepTitle: 'Finish Guide',
        nextIsButton: false,
      },
      {
        showProgress: false,
        body: (
          <div data-cy={`${syncGuideDataTarget}-conclusion`}>
            <GuideStepHeader isTitle text="You've successfully set up Sync!" />
            <GuideContentBody>
              If you&apos;re also interested in learning more about setting up GraphQL with your app, be sure to check
              out our GraphQL guide
            </GuideContentBody>
            <GuideFeedbackBlurb />
          </div>
        ),
        nextStepTitle: 'GraphQL',
        nextIsButton: true,
      },
    ],
    nextGuideName: GuideName.GraphQLGuide,
  };
};

export default buildSyncGuide;
