import React from 'react';

import { errorHandlerNotify } from 'baas-ui/error_util';
import EVENTS from 'baas-ui/tracking/events';
import EventTracker from 'baas-ui/tracking/EventTracker';
import { EventProperties } from 'baas-ui/tracking/types';

interface Props {
  event: keyof typeof EVENTS;
  properties?: EventProperties;
  children: React.ReactElement;
}

const Track = (props: Props) => {
  const onClick = (e) => {
    try {
      const {
        children: { props: childProps },
        properties = {},
        event,
      } = props;

      if (childProps.onClick) {
        childProps.onClick(e);
      }

      EventTracker.logEvent(EVENTS[event], properties);
    } catch (err) {
      errorHandlerNotify(err);
    }
  };

  const { children } = props;

  if (children && children.type && typeof children.type !== 'function') {
    return <children.type {...children.props} onClick={onClick} />;
  }

  return <span onClick={onClick}>{children}</span>;
};

export default Track;
