import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Secret')
export class Secret {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('value', String, true)
  public value?: string = undefined;

  constructor(partial?: Partial<Secret>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PartialSecret')
export class PartialSecret {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('name')
  public name = '';

  constructor(partial?: Partial<PartialSecret>) {
    Object.assign(this, partial);
  }
}
