import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import Banner from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import Modal from '@leafygreen-ui/modal';
import { palette } from '@leafygreen-ui/palette';
import Stepper, { Step } from '@leafygreen-ui/stepper';
import { spacing } from '@leafygreen-ui/tokens';
import { Body, H3 } from '@leafygreen-ui/typography';
import semverGte from 'semver/functions/gte';

import DocLink from 'baas-ui/common/components/doc-link';
import { SpinnerCircle } from 'baas-ui/common/components/spinner';
import { docLinks } from 'baas-ui/common/links';
import { MONGODB_FLEXIBLE_SYNC_MIN_VERSION } from 'baas-ui/constants';
import { AsyncDispatch, AsyncDispatchPayload } from 'baas-ui/redux_util';
import { getAppState, getSettingsState, getSyncState } from 'baas-ui/selectors';
import { loadSyncMigrationPrecheck } from 'baas-ui/sync/actions';
import { triggerSyncMigrationAction } from 'baas-ui/sync/sagas';
import { RootState } from 'baas-ui/types';
import { rootUrl, UrlObj } from 'baas-ui/urls';
import { SyncMigrationAction, SyncMigrationPrecheck } from 'admin-sdk';

import { CheckRequirementsBody } from './CheckRequirementsBody';
import { ReviewProcessBody } from './ReviewProcessBody';

interface PublicProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
}

interface ReduxStateProps {
  appUrl: UrlObj;
  atlasClustersUrl: string;
}

interface ReduxDispatchProps {
  loadPrecheck(): AsyncDispatchPayload<SyncMigrationPrecheck>;
  startMigration(): AsyncDispatchPayload<void>;
}

export type Props = PublicProps & ReduxStateProps & ReduxDispatchProps;

// TODO(BAAS-18418):The LG component has a mismatch between proptypes and typescript types.
// Remove the TS ignore once the problem has been addressed.
// eslint-disable-next-line
// @ts-ignore
const StyledModal = styled(Modal)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
}));

const TopPaddedDiv = styled.div(({ theme }) => ({
  marginTop: theme.leafygreen.spacing[3],
}));

// TODO(BAAS-18418):The LG component has a mismatch between proptypes and typescript types.
// Remove the TS ignore once the problem has been addressed.
// eslint-disable-next-line
// @ts-ignore
const VerticalPaddedStepper = styled(Stepper)(({ theme }) => ({
  paddingTop: theme.leafygreen.spacing[4],
  paddingBottom: theme.leafygreen.spacing[4],
}));

const Footer = styled.footer(({ theme }) => ({
  marginTop: theme.leafygreen.spacing[4],
  display: 'flex',
  justifyContent: 'space-between',
}));

export enum TestSelector {
  ModalSelector = 'sync-migration-modal',
  ErrorBanner = 'sync-migration-modal-error-banner',
  StepperSelector = 'sync-migration-modal-stepper',
  LoadingText = 'sync-migration-modal-loading-text',
  ReviewProcess = 'sync-migration-modal-review-process-body',
  CheckRequirements = 'sync-migration-modal-check-requirements-body',
  BackButton = 'sync-migration-modal-back-button',
  CancelButton = 'sync-migration-modal-cancel-button',
  SubmitButton = 'sync-migration-modal-submit-button',
}

export const SyncMigrationModal = ({
  isOpen,
  setIsOpen,
  appUrl,
  atlasClustersUrl,
  loadPrecheck,
  startMigration,
}: Props) => {
  const [step, setStep] = React.useState(0);
  const [error, setError] = React.useState('');
  const [startingMigration, setStartingMigration] = React.useState(false);
  const [precheck, setPrecheck] = React.useState<SyncMigrationPrecheck>();

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }

    // reset state when modal is opened
    setStep(0);
    setError('');
    setPrecheck(undefined);
    setStartingMigration(false);

    loadPrecheck()
      .then((res) => res && setPrecheck(res))
      .catch(() =>
        setError('We were unable to check the migration pre-requisites. Please close the modal and try again.')
      );
  }, [isOpen]);

  const percentUsersBreaking = () =>
    precheck && precheck.last10DaysConnectionsTotal > 0
      ? ((precheck.last10DaysConnectionsTotal - precheck.last10DaysConnectionsSupportMigration) /
          precheck.last10DaysConnectionsTotal) *
        100
      : 0;

  const satisfiesAllRequirements = () =>
    precheck &&
    precheck.clusterTimeBasedOplogWindowHours &&
    semverGte(precheck.clusterMongoDBVersion, MONGODB_FLEXIBLE_SYNC_MIN_VERSION);

  const onConfirm = async () => {
    if (step === 0) {
      setStep(1);
      return;
    }

    setError('');
    setStartingMigration(true);

    try {
      await startMigration();
      setIsOpen(false);
    } catch (e: unknown) {
      setError('We were unable to start the migration. Please try again.');
    } finally {
      setStartingMigration(false);
    }
  };

  return (
    <StyledModal open={isOpen} setOpen={setIsOpen} data-testid={TestSelector.ModalSelector}>
      <H3>Migrate to Flexible Sync</H3>

      {error && (
        <TopPaddedDiv>
          <Banner variant="danger" data-testid={TestSelector.ErrorBanner}>
            {error}
          </Banner>
        </TopPaddedDiv>
      )}

      <TopPaddedDiv>
        <Body>
          {`Before you migrate, we will run a check to make sure we can automatically migrate your Sync settings. `}
          <DocLink href={docLinks.Sync.SyncMode} showExternalIcon>
            Learn more about Flexible Sync
          </DocLink>
        </Body>
      </TopPaddedDiv>

      <VerticalPaddedStepper currentStep={step} data-testid={TestSelector.StepperSelector}>
        <Step>Review Migration Process</Step>
        <Step>Check Migration Requirements</Step>
      </VerticalPaddedStepper>

      {/* eslint-disable no-nested-ternary */}
      {!precheck ? (
        <div style={{ display: 'flex', gap: `${spacing[2]}px` }} data-testid={TestSelector.LoadingText}>
          <SpinnerCircle />
          <Body style={{ color: palette.gray.base }}>Loading migration information...</Body>
        </div>
      ) : step === 0 ? (
        <div data-testid={TestSelector.ReviewProcess}>
          <ReviewProcessBody percentUsersAffected={percentUsersBreaking()} />
        </div>
      ) : (
        <div data-testid={TestSelector.CheckRequirements}>
          <CheckRequirementsBody
            appUrl={appUrl}
            atlasClustersUrl={atlasClustersUrl}
            clusterName={precheck.clusterName}
            clusterVersion={precheck.clusterMongoDBVersion}
            timeBasedOplogHours={precheck.clusterTimeBasedOplogWindowHours}
            isPermissionsMigratable={precheck.canAutoMigratePermissions}
          />
        </div>
      )}

      <Footer>
        {step === 0 ? (
          <div />
        ) : (
          <Button
            onClick={() => {
              setError('');
              setStep(step - 1);
            }}
            data-testid={TestSelector.BackButton}
          >
            Back
          </Button>
        )}

        <div
          style={{
            display: 'flex',
            gap: `${spacing[2]}px`,
          }}
        >
          <Button onClick={() => setIsOpen(false)} data-testid={TestSelector.CancelButton}>
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            disabled={!precheck || startingMigration || (step === 1 && !satisfiesAllRequirements())}
            leftGlyph={startingMigration ? <SpinnerCircle /> : undefined}
            variant="primary"
            data-testid={TestSelector.SubmitButton}
            data-cy={TestSelector.SubmitButton}
          >
            {step === 0 ? 'Next: Migration Requirements' : 'Start Migration'}
          </Button>
        </div>
      </Footer>
    </StyledModal>
  );
};

const mapStateToProps = (state: RootState) => {
  const { cloudUIBaseUrl } = getSettingsState(state);
  const {
    app: { id: appId, groupId },
  } = getAppState(state);
  const {
    config: { serviceId },
  } = getSyncState(state);

  return {
    cloudUIBaseUrl,
    appId,
    groupId,
    serviceId: serviceId || '',
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  loadPrecheck: (groupId: string, appId: string) => dispatch(loadSyncMigrationPrecheck({ groupId, appId })),
  startMigration: (groupId: string, appId: string, serviceId: string) =>
    dispatch(triggerSyncMigrationAction(groupId, appId, serviceId, SyncMigrationAction.Start)),
});

const mergeProps = (
  { cloudUIBaseUrl, appId, groupId, serviceId }: ReturnType<typeof mapStateToProps>,
  { loadPrecheck, startMigration }: ReturnType<typeof mapDispatchToProps>,
  ownProps: PublicProps
): Props => {
  return {
    ...ownProps,
    appUrl: rootUrl.groups().group(groupId).apps().app(appId),
    atlasClustersUrl: `${cloudUIBaseUrl}/v2/${groupId}#clusters`,
    loadPrecheck: () => loadPrecheck(groupId, appId),
    startMigration: () => startMigration(groupId, appId, serviceId),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SyncMigrationModal);
