import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Spinner } from 'baas-ui/common/components/spinner';
import { passThroughProps } from 'baas-ui/common/utils/util';

import './loading-wrapper.less';

const baseClassName = 'loading-wrapper';
const spinnerClassName = 'spinner-container';

const onClick = ({
  e,
  allowInteraction,
  isLoading,
}: {
  e: React.MouseEvent<HTMLDivElement>;
  allowInteraction?: boolean;
  isLoading?: boolean;
}) => {
  if (!allowInteraction && isLoading) {
    e.preventDefault();
  }
};

export const loadingWrapperTimeDelayMS = 300;

export enum TestSelector {
  LoadingWrapperContainer = 'loading-wrapper-container',
  SpinnerContainer = 'spinner-container',
}

export interface Props {
  allowInteraction?: boolean;
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  showLoadingIndicator?: boolean;
}

interface State {
  timer: any;
  timedOut: boolean;
}

class LoadingWrapper extends React.Component<Props, State> {
  static propTypes = {
    allowInteraction: PropTypes.bool,
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    showLoadingIndicator: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    allowInteraction: false,
    children: null,
    isLoading: false,
    showLoadingIndicator: true,
    className: '',
  };

  state = {
    timer: this.setTimer(),
    timedOut: false,
  };

  constructor(props: Props) {
    super(props);
    this.setTimer = this.setTimer.bind(this);
  }

  componentDidUpdate({ isLoading: wasLoading }) {
    const { isLoading } = this.props;
    const { timedOut, timer } = this.state;

    // Handle case where we need to cancel existing timer
    // When wasLoading is true and isLoading is false and timedOut is false
    if (wasLoading && !isLoading && !timedOut) {
      clearTimeout(timer);
      return;
    }

    if (!wasLoading && isLoading) {
      clearTimeout(timer);
      // NOTE: according to the React docs, this is the recommended approach this this behavior.
      // The conditional above prevents an infinite loop
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        timer: this.setTimer(),
        timedOut: false,
      });
    }
  }

  componentWillUnmount() {
    const { timer } = this.state;
    clearTimeout(timer);
  }

  setTimer() {
    return setTimeout(() => this.setState({ timedOut: true }), loadingWrapperTimeDelayMS);
  }

  render() {
    const { allowInteraction, isLoading, showLoadingIndicator, children, className, ...rest } = this.props;
    const { timedOut } = this.state;
    const shouldShowSpinner = isLoading && timedOut && showLoadingIndicator;

    return (
      <div
        data-testid={TestSelector.LoadingWrapperContainer}
        className={classNames(baseClassName, className)}
        onClick={(e) => onClick({ e, allowInteraction, isLoading })}
        style={{
          opacity: isLoading && timedOut ? 0.5 : 1.0,
        }}
        {...passThroughProps(rest)}
      >
        {children}
        {shouldShowSpinner && (
          <div className={spinnerClassName} data-testid={TestSelector.SpinnerContainer}>
            <Spinner open xlarge />
          </div>
        )}
      </div>
    );
  }
}

export default LoadingWrapper;
