import React from 'react';
import PropTypes from 'prop-types';

import { passThroughProps } from 'baas-ui/common/utils/util';

import LoadingWrapper from './LoadingWrapper';

import './loading-wrapper.less';

export enum TestSelector {
  ItemListEmptyState = 'item-list-empty-state',
}
export interface Props {
  children?: React.ReactNode;
  emptyState?: React.ReactNode;
  isLoading?: boolean;
  hasItems?: boolean;
  showLoadingIndicator?: boolean;
}

export default function ItemListLoadingWrapper({
  children,
  hasItems = false,
  isLoading = false,
  emptyState,
  showLoadingIndicator = true,
  ...rest
}: Props) {
  let content: React.ReactNode;

  if (isLoading && !hasItems) {
    content = <div data-testid={TestSelector.ItemListEmptyState} />;
  } else if (hasItems) {
    content = children;
  } else {
    content = emptyState;
  }

  return (
    <LoadingWrapper isLoading={isLoading} showLoadingIndicator={showLoadingIndicator} {...passThroughProps(rest)}>
      {content}
    </LoadingWrapper>
  );
}

ItemListLoadingWrapper.propTypes = {
  children: PropTypes.node,
  emptyState: PropTypes.node,
  isLoading: PropTypes.bool,
  hasItems: PropTypes.bool,
  showLoadingIndicator: PropTypes.bool,
};
