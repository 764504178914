import React from 'react';
import styled from '@emotion/styled';
import { Body } from '@leafygreen-ui/typography';

import CopyText from 'baas-ui/common/components/copy-text';

export interface Props {
  copyText: string;
  display: string;
  tooltipDisplay: string;
  onIconClick: () => void;
}

const StyledSpan = styled.span`
  display: flex;
  align-items: center;
`;

const StyledBody = styled(Body)`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export enum TestSelector {
  BodySelector = 'body',
}

export default function SideNavCopyText({ copyText, display, tooltipDisplay, onIconClick }: Props) {
  return (
    <StyledSpan>
      <StyledBody data-testid={TestSelector.BodySelector}>{display}</StyledBody>
      <CopyText copyText={copyText} tooltipDisplay={tooltipDisplay} onIconClick={onIconClick} />
    </StyledSpan>
  );
}
