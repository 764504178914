import { JsonObject, JsonProperty } from 'json2typescript';

import { DateConverter } from '../../Converter';

export enum MessageState {
  Draft = 'draft',
  Sent = 'sent',
}

@JsonObject('PushNotification')
export class PushNotification {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('appID')
  public appId = '';

  @JsonProperty('state')
  public state: MessageState = MessageState.Draft;

  @JsonProperty('topic')
  public topic = '';

  @JsonProperty('message')
  public message = '';

  @JsonProperty('label')
  public label = '';

  @JsonProperty('created', DateConverter)
  public created: Date = new Date(0);

  @JsonProperty('sent', DateConverter)
  public sent: Date = new Date(0);

  constructor(partial?: Partial<PushNotification>) {
    Object.assign(this, partial);
  }
}

@JsonObject('SendNotificationRequest')
export class SendNotificationRequest {
  @JsonProperty('topic')
  public topic = '';

  @JsonProperty('message')
  public message = '';

  @JsonProperty('label')
  public label = '';

  @JsonProperty('state')
  public state = '';

  constructor(partial?: Partial<SendNotificationRequest>) {
    Object.assign(this, partial);
  }
}
