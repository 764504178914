import React from 'react';
import classNames from 'classnames';

import { SpinnerCircle } from 'baas-ui/common/components/spinner';

import './create-app-footer.less';

const baseClassName = 'create-app-footer';

export enum TestSelector {
  CreateAppFooter = 'create-app-footer',
  RightButtonSpinner = 'right-button-spinner',
  RightButton = 'right-button',
  LeftButton = 'left-button',
}

export interface Props {
  className?: string;
  showRightButtonSpinner?: boolean;
  rightButton?: React.ReactNode;
  leftButton?: React.ReactNode;
}

const CreateAppFooter = ({ className, showRightButtonSpinner, rightButton, leftButton }: Props) => {
  return (
    <div
      data-test-selector={TestSelector.CreateAppFooter}
      data-testid={TestSelector.CreateAppFooter}
      className={classNames(baseClassName, className, { [`${baseClassName}-no-left-button`]: !leftButton })}
    >
      {leftButton && <div data-test-selector={TestSelector.LeftButton}>{leftButton}</div>}
      <div className={`${baseClassName}-actions-right`}>
        {showRightButtonSpinner && (
          <SpinnerCircle data-test-selector={TestSelector.RightButtonSpinner} className={`${baseClassName}-spinner`} />
        )}
        <div data-test-selector={TestSelector.RightButton}>{rightButton}</div>
      </div>
    </div>
  );
};

export default CreateAppFooter;
