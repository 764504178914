import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AtlasElectableSpecs')
export class AtlasElectableSpecs {
  @JsonProperty('instanceSize', String, true)
  public instanceSize?: string = undefined;

  constructor(partial?: Partial<AtlasElectableSpecs>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AtlasRegionConfig')
export class AtlasRegionConfig {
  @JsonProperty('electableSpecs', AtlasElectableSpecs, true)
  public electableSpecs?: AtlasElectableSpecs = undefined;

  @JsonProperty('regionName', String, true)
  public regionName?: string = undefined;

  @JsonProperty('backingProviderName', String, true)
  public backingProviderName?: string = undefined;

  constructor(partial?: Partial<AtlasRegionConfig>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AtlasReplicationSpec')
export class AtlasReplicationSpec {
  @JsonProperty('regionConfigs', [AtlasRegionConfig], true)
  public regionConfigs?: AtlasRegionConfig[] = [];

  constructor(partial?: Partial<AtlasReplicationSpec>) {
    Object.assign(this, partial);
  }
}

@JsonObject('ProviderSettings')
export class ProviderSettings {
  @JsonProperty('providerName', String, true)
  public providerName?: string = undefined;

  @JsonProperty('backingProviderName', String, true)
  public backingProviderName?: string = undefined;

  @JsonProperty('regionName', String, true)
  public regionName?: string = undefined;

  constructor(partial?: Partial<ProviderSettings>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AtlasConnectionStrings')
export class AtlasConnectionStrings {
  @JsonProperty('standard', String, true)
  public standard?: string = undefined;

  @JsonProperty('standardSrv', String, true)
  public standardSrv?: string = undefined;

  constructor(partial?: Partial<AtlasConnectionStrings>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AtlasCluster')
export class AtlasCluster {
  @JsonProperty('id', String, true)
  public id?: string = undefined;

  @JsonProperty('name', String, true)
  public name?: string = undefined;

  @JsonProperty('stateName', String, true)
  public state?: string = undefined;

  @JsonProperty('mongoDBVersion', String, true)
  public mongoDBVersion?: string = undefined;

  @JsonProperty('connectionStrings', AtlasConnectionStrings, true)
  public connectionStrings?: AtlasConnectionStrings = undefined;

  @JsonProperty('providerSettings', ProviderSettings, true)
  public providerSettings?: ProviderSettings = undefined;

  @JsonProperty('replicationSpecs', [AtlasReplicationSpec], true)
  public replicationSpecs?: AtlasReplicationSpec[] = [];

  @JsonProperty('clusterType', String, true)
  public clusterType?: string = undefined;

  @JsonProperty('paused', Boolean, true)
  public paused?: boolean = undefined;

  @JsonProperty('isMultiCloud', Boolean, true)
  public isMultiCloud?: boolean = undefined;

  @JsonProperty('isMultiRegion', Boolean, true)
  public isMultiRegion?: boolean = undefined;

  constructor(partial?: Partial<AtlasCluster>) {
    Object.assign(this, partial);
  }
}

@JsonObject('CreateAtlasClusterRequest')
export class CreateAtlasClusterRequest {
  @JsonProperty('region_name', String, true)
  public regionName?: string = undefined;

  constructor(partial?: Partial<CreateAtlasClusterRequest>) {
    Object.assign(this, partial);
  }
}

@JsonObject('CreateAtlasClusterResponse')
export class CreateAtlasClusterResponse {
  @JsonProperty('cluster_name')
  public clusterName = '';

  @JsonProperty('region')
  public readonly region: string = '';

  constructor(partial?: Partial<CreateAtlasClusterResponse>) {
    Object.assign(this, partial);
  }
}
