import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CreateAtlasAPIKeyRequest')
export class CreateAtlasAPIKeyRequest {
  @JsonProperty('desc')
  public desc = '';

  @JsonProperty('roles')
  public roles: string[] = [];

  constructor(partial?: Partial<CreateAtlasAPIKeyRequest>) {
    Object.assign(this, partial);
  }
}

@JsonObject('CreateAtlasAPIKeyResponse')
export class CreateAtlasAPIKeyResponse {
  @JsonProperty('desc')
  public desc = '';

  @JsonProperty('public_key')
  public publicKey = '';

  @JsonProperty('private_key')
  public privateKey = '';

  constructor(partial?: Partial<CreateAtlasAPIKeyResponse>) {
    Object.assign(this, partial);
  }
}
