import { Language } from '@leafygreen-ui/code';

/* eslint-disable @typescript-eslint/ban-types,camelcase */

// These values are kept in sync with the constants defined on the server here:
// eslint-disable-next-line max-len
// https://github.com/10gen/baas/blob/9e4b987262d69f24cbc601e10f79ce8e62177040/devicesync/schemagen/generate_schemas.go#L35-L51
export enum RealmLanguage {
  JS = 'JAVA_SCRIPT',
  TS = 'TYPE_SCRIPT',
  KotlinMultiplatform = 'KOTLIN_MULTIPLATFORM',
  Swift = 'SWIFT',
  ObjC = 'OBJECTIVE_C',
  CSharp = 'C_SHARP',
  CPP = 'CPP',
  Dart = 'DART',
  Flutter = 'FLUTTER',
  Java = 'JAVA',
  KotlinJavaSDK = 'KOTLIN',
}

export enum RealmSDK {
  Node = 'node',
  Web = 'web',
  // Kotlin is the Kotlin Multiplatform SDK
  Kotlin = 'kotlin',
  IOS = 'ios',
  DotNet = 'dotNet',
  Flutter = 'flutter',
  CPP = 'cpp',
  ReactNative = 'reactNative',
  // Android SDK is the Legacy Java SDK that can be used with Java and Kotlin
  Android = 'android',
}

export type WebSDKLanguage = RealmLanguage.JS | RealmLanguage.TS;

export interface InstallSnippet {
  description: string;
  snippet?: string;
  docsURL?: string;
}

export interface SDKSnippets {
  language: Language;
  docsUrl: string;
  installSnippets: InstallSnippet[];
  loginSnippet: string;
}

export interface SyncSnippets {
  openFlexibleRealmSnippet: string;
  createModelSnippet: string;
  useModelSnippet: string;
  disclaimer?: string;
  instantiateCollectionSnippet?: string;
  insertDocumentsSnippet?: string;
  findDocumentsSnippet?: string;
  aggregateDocumentsSnippet?: string;
  callFunctionSnippet?: string;
}

export const CLIENT_APP_ID_MARKER = '<YOUR_APP_ID>';
