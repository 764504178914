import React from 'react';
import { Body } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import GuideContentBody from 'baas-ui/guides/content/body';
import GuideFeedbackBlurb from 'baas-ui/guides/content/guide-feedback-blurb';
import GuideStepHeader from 'baas-ui/guides/step-header';
import { GuideContent, GuideName } from 'baas-ui/guides/types';
import rootUrl from 'baas-ui/urls';

export enum TestSelector {
  AndroidDocs = 'android-docs',
  IOSDocs = 'ios-docs',
  JSDocs = 'js-docs',
  DotNetDocs = 'dotnet-docs',
  KotlinDocs = 'kotlin-docs',
  GraphiQLDocs = 'graphiql',
  LoadSampleDataDocs = 'load-sample-data',
}

const guideContentClassName = 'guide-content';
const graphQLGuideDataTarget = 'graphql-guide';

const buildGraphQLGuide = ({ groupId, appId, clusterId }): GuideContent => {
  const appUrl = rootUrl.groups().group(groupId).apps().app(appId);
  const newCollectionUrl = appUrl.clusters().cluster(clusterId).rules().new();
  const schemaUrl = appUrl.schemas().root();
  const graphqlExploreUrl = appUrl.graphql().explore();
  const sdksUrl = appUrl.sdks().list();
  const customResolversUrl = appUrl.graphql().customResolvers().list();

  return {
    header: 'Set up GraphQL',
    steps: [
      {
        showProgress: false,
        body: (
          <div data-cy={`${graphQLGuideDataTarget}-introduction`}>
            <Body>This guide walks you through setting up GraphQL for your application. You will:</Body>
            <GuideStepHeader index={1} text="Configure a Collection" />
            <GuideStepHeader
              index={2}
              text="Generate a Schema"
              subtext="We'll translate it to a GraphQL Schema for you"
            />
            <GuideStepHeader index={3} text="Query your GraphQL API" />
            <GuideStepHeader index={4} text="Connect to your application" />
            <GuideStepHeader index={5} text="(Optional) Add a Custom Resolver" />
          </div>
        ),
        nextStepTitle: 'Start',
        nextIsButton: true,
      },
      {
        url: newCollectionUrl,
        showProgress: true,
        body: (
          <div data-cy={`${graphQLGuideDataTarget}-step-1`}>
            <GuideStepHeader isTitle index={1} text="Configure a Collection" url={newCollectionUrl} />
            <GuideContentBody>
              Your app creates GraphQL types for each collection in your Atlas cluster that has a defined document
              schema and evaluates collection permissions for GraphQL requests.
            </GuideContentBody>
            <GuideContentBody>
              Select a collection you would like to set up permissions for. You will be prompted to
              &apos;configure&apos; this collection by setting a permissions template.
            </GuideContentBody>
            <GuideContentBody>
              (Optional) If you don&apos;t have existing data to work with, you can load sample data into your cluster
              through Atlas.{' '}
              <DocLink data-test-selector={TestSelector.LoadSampleDataDocs} href={docLinks.Atlas.LoadSampleData}>
                Learn how to load Sample Data.
              </DocLink>
            </GuideContentBody>
            <GuideContentBody>
              For this guide, you can select any permissions template and <b>click &apos;Save Draft&apos;</b>.
            </GuideContentBody>
          </div>
        ),
        docsLink: docLinks.MongoDB.RolesPermissions,
        nextStepTitle: 'JSON Schema',
        nextIsButton: false,
      },
      {
        url: schemaUrl,
        showProgress: true,
        body: (
          <div data-cy={`${graphQLGuideDataTarget}-step-2`}>
            <GuideStepHeader isTitle index={2} text="Generate a JSON Schema" url={schemaUrl} />
            <GuideContentBody>
              Now that you have a configured collection, you can define a document schema.
            </GuideContentBody>
            <GuideContentBody>
              Select the collection you configured permissions for and <b>click &apos;Generate a Schema&apos;</b>
            </GuideContentBody>
            <GuideContentBody>
              Your app will generate a JSON Schema for you based on data in your collection. You may keep the default #
              of sample documents. When ready, <b>click &apos;Generate Schema from sampling&apos;</b> and save changes
              to view your JSON Schema.
            </GuideContentBody>
          </div>
        ),
        docsLink: docLinks.MongoDB.GenerateSchema,
        nextStepTitle: 'Query GraphQL API',
        nextIsButton: false,
      },
      {
        url: graphqlExploreUrl,
        showProgress: true,
        body: (
          <div data-cy={`${graphQLGuideDataTarget}-step-3`}>
            <GuideStepHeader isTitle index={3} text="Query your GraphQL API" url={graphqlExploreUrl} />
            <GuideContentBody>You are now ready to view your GraphQL schema and write a query.</GuideContentBody>
            <GuideContentBody>
              GraphiQL is embedded directly into the Explore page. Try running the sample query in GraphiQL, or write
              your own queries directly against the GraphQL endpoint.
            </GuideContentBody>
          </div>
        ),
        docsLink: docLinks.GraphQL.GraphQLOperations,
        nextStepTitle: 'Connect to your app',
        nextIsButton: false,
      },
      {
        url: sdksUrl,
        showProgress: true,
        body: (
          <div data-cy={`${graphQLGuideDataTarget}-step-4`}>
            <GuideStepHeader isTitle index={4} text="Connect to your Application" url={sdksUrl} />
            <GuideContentBody>
              Install an Atlas Device SDK to connect your client application to App Services.
            </GuideContentBody>
            <GuideContentBody>View the instructions in the Docs:</GuideContentBody>
            <div className={`${guideContentClassName}-links`}>
              <DocLink data-test-selector={TestSelector.JSDocs} href={docLinks.SDKs.Node.Intro}>
                JavaScript
              </DocLink>
              <DocLink data-test-selector={TestSelector.AndroidDocs} href={docLinks.SDKs.Android.Intro}>
                Android (Java)
              </DocLink>
              <DocLink data-test-selector={TestSelector.IOSDocs} href={docLinks.SDKs.iOS.Intro}>
                iOS (Swift)
              </DocLink>
              <DocLink data-test-selector={TestSelector.DotNetDocs} href={docLinks.SDKs.DotNet.Intro}>
                .NET
              </DocLink>
              <DocLink data-test-selector={TestSelector.KotlinDocs} href={docLinks.SDKs.Kotlin.Intro}>
                Kotlin
              </DocLink>
            </div>
          </div>
        ),
        docsLink: docLinks.SDKs.Android.Intro,
        nextStepTitle: 'Custom Resolver',
        nextIsButton: false,
      },
      {
        url: customResolversUrl,
        showProgress: true,
        body: (
          <div data-cy={`${graphQLGuideDataTarget}-step-5`}>
            <GuideStepHeader isTitle index={5} text="(Optional) Add a Custom Resolver" url={customResolversUrl} />
            <GuideContentBody>
              Custom Resolvers handle complex logic beyond basic CRUD operations by using a defined field name, passing
              arguments from the query to a function, and returning the result of the function back to the caller.
            </GuideContentBody>
            <GuideContentBody>
              <b>{`Click 'Add a Custom Resolver'.`}</b>
            </GuideContentBody>
          </div>
        ),
        docsLink: docLinks.GraphQL.CustomResolvers,
        nextStepTitle: 'Finish Guide',
        nextIsButton: false,
      },
      {
        showProgress: false,
        body: (
          <div data-cy={`${graphQLGuideDataTarget}-conclusion`}>
            <GuideStepHeader isTitle text="You've successfully set up GraphQL for your application!" />
            <GuideContentBody>
              If you&apos;re also interested in learning more about App Services&apos; mobile capabilities, be sure to
              check out the guide on how to synchronize your data between mobile devices with our Device Sync guide.
            </GuideContentBody>
            <GuideFeedbackBlurb />
          </div>
        ),
        nextStepTitle: 'Device Sync',
        nextIsButton: true,
      },
    ],
    nextGuideName: GuideName.SyncGuide,
  };
};

export default buildGraphQLGuide;
