import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';

import * as actions from 'baas-ui/services/actions';

import awsReducer from './aws/reducers';
import defaultReducer from './default/reducers';
import mongoReducer from './mongodb/reducers';
import { BaseServicesState } from './types';

const serviceReducers = combineReducers({
  mongodb: mongoReducer,
  default: defaultReducer,
  aws: awsReducer,
});

export const defaultState: BaseServicesState = {
  configInput: {},
  configSaveError: undefined,
  createServiceError: undefined,
  creating: false,
  deleteSvcError: undefined,
  error: undefined,
  loadingSvc: false,
  loadingSvcConfig: false,
  loadingSvcs: false,
  readPreferenceTagsError: undefined,
  saving: false,
  savingConfig: false,
  service: undefined,
  serviceConfig: undefined,
  svcConfigsById: {},
  svcsById: {},
};

export const basicServiceReducer = createReducer<BaseServicesState>({}, defaultState);

basicServiceReducer.on(actions.saveSvcConfigActions.req, (state) => ({
  ...state,
  savingConfig: true,
  configSaveError: undefined,
  readPreferenceTagsError: undefined,
}));

basicServiceReducer.on(actions.saveSvcConfigActions.rcv, (state) => ({
  ...state,
  savingConfig: false,
  configSaveError: undefined,
  readPreferenceTagsError: undefined,
}));

basicServiceReducer.on(actions.saveSvcConfigActions.fail, (state, payload) => ({
  ...state,
  savingConfig: false,
  configSaveError: payload.error,
}));

basicServiceReducer.on(actions.loadServicesActions.req, (state) => ({
  ...state,
  error: undefined,
  loadingSvcs: true,
}));

basicServiceReducer.on(actions.loadServicesActions.rcv, (state, { payload }) => ({
  ...state,
  error: undefined,
  loadingSvcs: false,
  svcsById: payload.reduce((partialObj, svc) => ({ ...partialObj, [svc.id]: svc }), state.svcsById),
}));

basicServiceReducer.on(actions.loadServicesActions.fail, (state, { error }) => ({
  ...state,
  loadingSvcs: false,
  error,
}));

basicServiceReducer.on(actions.loadSvcActions.req, (state, { svcId: nextServiceId }) => {
  let { service } = state;

  const { id: currentServiceId } = service || {};

  // only unset current service if different
  if (currentServiceId !== nextServiceId) {
    service = undefined;
  }

  return {
    ...state,
    loadingSvc: true,
    service,
    configSaveError: undefined,
  };
});

basicServiceReducer.on(actions.loadSvcActions.rcv, (state, { payload, reqArgs }) => ({
  ...state,
  service: payload,
  loadingSvc: false,
  svcsById: { ...state.svcsById, [reqArgs.svcId]: payload },
}));

basicServiceReducer.on(actions.loadSvcActions.fail, (state, { error }) => ({
  ...state,
  loadServiceError: error,
  loadingSvc: false,
}));

basicServiceReducer.on(actions.createServiceActions.req, (state) => ({ ...state, creating: true }));

basicServiceReducer.on(actions.createServiceActions.rcv, (state, { payload, reqArgs }) => ({
  ...state,
  serviceConfig: { ...reqArgs.serviceDesc, service_id: payload.id },
  createServiceError: undefined,
  creating: false,
}));

basicServiceReducer.on(actions.createServiceActions.fail, (state, payload) => ({
  ...state,
  creating: false,
  createServiceError: payload.error,
}));

basicServiceReducer.on(actions.deleteSvcActions.req, (state) => ({
  ...state,
  deleteSvcError: undefined,
}));

basicServiceReducer.on(actions.deleteSvcActions.rcv, (state, { reqArgs }) => {
  const newSvcsById = { ...state.svcsById };
  delete newSvcsById[reqArgs.svcId];

  const newSvcConfigsById = { ...state.svcConfigsById };
  delete newSvcConfigsById[reqArgs.svcId];

  return {
    ...state,
    svcsById: newSvcsById,
    svcConfigsById: newSvcConfigsById,
    service: undefined,
  };
});

basicServiceReducer.on(actions.deleteSvcActions.fail, (state, { error }) => ({
  ...state,
  deleteSvcError: error,
}));

basicServiceReducer.on(actions.loadSvcConfigActions.req, (state) => ({
  ...state,
  loadingSvcConfig: true,
  configLoadError: undefined,
}));

basicServiceReducer.on(actions.loadSvcConfigActions.rcv, (state, { payload, reqArgs }) => ({
  ...state,
  serviceConfig: { ...payload, service_id: reqArgs.svcId },
  loadingSvcConfig: false,
  svcConfigsById: { ...state.svcConfigsById, [reqArgs.svcId]: { ...payload } },
  configInput: { ...payload.config },
}));

basicServiceReducer.on(actions.loadSvcConfigActions.fail, (state, { error }) => ({
  ...state,
  configLoadError: error,
  loadingSvcConfig: false,
}));

basicServiceReducer.on(actions.resetConfigInput, (state) => ({
  ...state,
  configInput: {},
  configSaveError: undefined,
  readPreferenceTagsError: undefined,
}));

basicServiceReducer.on(actions.setConfigInput, (state, { key, value }) => ({
  ...state,
  configInput: { ...state.configInput, [key]: value },
}));

basicServiceReducer.on(actions.removeConfigInputField, (state, { key }) => {
  const newConfigInput = { ...state.configInput };
  delete newConfigInput[key];

  return {
    ...state,
    configInput: newConfigInput,
  };
});

basicServiceReducer.on(actions.setReadPreferenceTagsError, (state, error) => ({
  ...state,
  readPreferenceTagsError: error,
}));

basicServiceReducer.on(actions.clearErrors, (state) => ({
  ...state,
  error: undefined,
  configSaveError: undefined,
  readPreferenceTagsError: undefined,
  deleteSvcError: undefined,
}));

basicServiceReducer.on(actions.clearServiceConfig, (state) => ({
  ...state,
  serviceConfig: undefined,
}));

const serviceReducer = combineReducers({
  serviceTypes: serviceReducers,
  base: basicServiceReducer,
});

export default serviceReducer;
