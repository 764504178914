import { createAction } from 'redux-act';

import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { TransformAssetRequest } from 'admin-sdk';

const NAME = 'hosting/';

// Custom domain
export const setCustomDomainName = createAction(`${NAME}config/set custom domain name`);
export const toggleCustomDomainEnabled = createAction(`${NAME}config/toggle custom domain enabled`);

// Error File
export const setErrorFileConfig = createAction(`${NAME}config/set error file path`);

export const discardHostingConfigChanges = createAction(`${NAME}config/discard changes`);

// state actions
export const setUploadStarted = createAction(`${NAME}assets/set upload started`);
export const setUploadFinished = createAction(`${NAME}assets/set upload finished`);
export const resetUploadState = createAction(`${NAME}assets/reset upload state`);
export const toggleSelect = createAction(`${NAME}assets/toggle select asset`);
export const toggleSelectAll = createAction(`${NAME}assets/toggle select all`);
export const clearSelections = createAction(`${NAME}assets/clear selections`);
export const setHostingEnabledAt = createAction<number>(`${NAME}config/set hosting enabled at`);

// api actions and types
export const [loadAssetActions, loadAsset] = createActionsAndExecutor(
  `${NAME}load asset`,
  (client, { groupId, appId, path }) =>
    () =>
      client.apps(groupId).app(appId).hosting().assets().asset(path).get()
);

export const [loadAssetsActions, loadAssets] = createActionsAndExecutor(
  `${NAME}load assets`,
  (client, { groupId, appId, params: { prefix, recursive } }) =>
    () =>
      client.apps(groupId).app(appId).hosting().assets().list(prefix, recursive)
);

export const [loadPresignURLActions, loadPresignURL] = createActionsAndExecutor(
  `${NAME}presign`,
  (client, { groupId, appId, path }) =>
    () =>
      client.apps(groupId).app(appId).hosting().presign().get(path)
);

export const [flushCacheActions, flushCache] = createActionsAndExecutor(
  `${NAME}flush cache`,
  (client, { groupId, appId, path }) =>
    () =>
      client.apps(groupId).app(appId).hosting().cache().invalidate(path)
);

export const [loadHostingConfigActions, loadHostingConfig] = createActionsAndExecutor(
  `${NAME}load config`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).hosting().config().get()
);

export const [setAssetAttributesActions, setAttributes] = createActionsAndExecutor(
  `${NAME}set asset attributes`,
  (client, { groupId, appId, path, attributes }) =>
    () =>
      client.apps(groupId).app(appId).hosting().assets().asset(path).patch({ attributes })
);

export const [updateHostingConfigActions, updateHostingConfig] = createActionsAndExecutor(
  `${NAME}update config`,
  (client, { groupId, appId, config }) =>
    () =>
      client.apps(groupId).app(appId).hosting().config().patch(config)
);

export const [createDirectoryActions, createDirectory] = createActionsAndExecutor(
  `${NAME}create directory`,
  (client, { groupId, appId, directoryName }) =>
    () =>
      client.apps(groupId).app(appId).hosting().assets().createDirectory(directoryName)
);

export const [uploadAssetActions, uploadAsset] = createActionsAndExecutor(
  `${NAME}upload assets`,
  (client, { groupId, appId, metadata, body }) =>
    () =>
      client.apps(groupId).app(appId).hosting().assets().upload(metadata, body)
);

export const [moveAssetActions, moveAsset] = createActionsAndExecutor(
  `${NAME}move assets`,
  (client, { groupId, appId, moveFrom, moveTo }) =>
    () =>
      client.apps(groupId).app(appId).hosting().assets().transform(
        new TransformAssetRequest({
          moveFrom,
          moveTo,
        })
      )
);

export const [copyAssetActions, copyAsset] = createActionsAndExecutor(
  `${NAME}copy assets`,
  (client, { groupId, appId, copyFrom, copyTo }) =>
    () =>
      client.apps(groupId).app(appId).hosting().assets().transform(
        new TransformAssetRequest({
          copyFrom,
          copyTo,
        })
      )
);

export const [deleteAssetActions, deleteAsset] = createActionsAndExecutor(
  `${NAME}delete assets`,
  (client, { groupId, appId, path }) =>
    () =>
      client.apps(groupId).app(appId).hosting().assets().asset(path).delete()
);

export const asyncEditRcvActions = [
  setAssetAttributesActions.rcv,
  updateHostingConfigActions.rcv,
  moveAssetActions.rcv,
  copyAssetActions.rcv,
  deleteAssetActions.rcv,
];
