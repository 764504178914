import { JsonObject, JsonProperty } from 'json2typescript';

import { DateConverter, dateToISO8601 } from '../../Converter';

export enum MeasurementGroupGranularity {
  Monthly = 'P31D',
  Hourly = 'PT1H',
}

@JsonObject('DataPoint')
export class DataPoint {
  @JsonProperty('timestamp', DateConverter)
  public timestamp: Date = new Date();

  @JsonProperty('value')
  public value = 0;

  constructor(partial?: Partial<DataPoint>) {
    Object.assign(this, partial);
  }
}

export enum MeasurementName {
  ComputeTime = 'compute_time',
  DataOut = 'data_out',
  RequestCount = 'request_count',
  SyncTime = 'sync_time',
}

export enum MeasurementUnit {
  None = '',
  Gigabytes = 'GIGABYTES',
  GigabyteSeconds = 'GIGABYTE_SECONDS',
  Hours = 'HOURS',
  Minutes = 'MINUTES',
}

@JsonObject('Measurement')
export class Measurement {
  @JsonProperty('name')
  public name: MeasurementName = MeasurementName.DataOut;

  @JsonProperty('units')
  public units: MeasurementUnit = MeasurementUnit.None;

  @JsonProperty('data_points', [DataPoint])
  public dataPoints: DataPoint[] = [];

  constructor(partial?: Partial<Measurement>) {
    Object.assign(this, partial);
  }
}

interface MeasurementGroup {
  start: Date;

  end: Date;

  granularity: MeasurementGroupGranularity;

  measurements: Measurement[];
}

@JsonObject('AppMeasurementGroup')
export class AppMeasurementGroup implements MeasurementGroup {
  @JsonProperty('app_name')
  public appName = '';

  @JsonProperty('group_id')
  public groupId = '';

  @JsonProperty('app_id')
  public appId = '';

  @JsonProperty('start', DateConverter)
  public start: Date = new Date();

  @JsonProperty('end', DateConverter)
  public end: Date = new Date();

  @JsonProperty('granularity')
  public granularity: MeasurementGroupGranularity = MeasurementGroupGranularity.Monthly;

  @JsonProperty('measurements', [Measurement])
  public measurements: Measurement[] = [];

  constructor(partial?: Partial<AppMeasurementGroup>) {
    Object.assign(this, partial);
  }
}

@JsonObject('GroupMeasurementGroup')
export class GroupMeasurementGroup implements MeasurementGroup {
  @JsonProperty('group_id')
  public groupId = '';

  @JsonProperty('start', DateConverter)
  public start: Date = new Date();

  @JsonProperty('end', DateConverter)
  public end: Date = new Date();

  @JsonProperty('granularity')
  public granularity: MeasurementGroupGranularity = MeasurementGroupGranularity.Monthly;

  @JsonProperty('measurements', [Measurement])
  public measurements: Measurement[] = [];

  constructor(partial?: Partial<AppMeasurementGroup>) {
    Object.assign(this, partial);
  }
}

export interface MeasurementRequest {
  start?: Date;
  end?: Date;
  granularity?: MeasurementGroupGranularity;
}

export function getMeasurementFilter(request?: MeasurementRequest) {
  if (!request) {
    return undefined;
  }
  const filter: Record<string, any> = {};
  if (request.start) {
    filter.start = dateToISO8601(request.start);
  }
  if (request.end) {
    filter.end = dateToISO8601(request.end);
  }
  if (request.granularity) {
    filter.granularity = request.granularity;
  }
  return filter;
}
