import React from 'react';
import styled from '@emotion/styled';
import { createIconComponent } from '@leafygreen-ui/icon';
import { palette } from '@leafygreen-ui/palette';
import { useThemeValue } from '@mongodb-js/darkreader';

import { passThroughProps } from 'baas-ui/common/utils/util';
import { useDarkMode } from 'baas-ui/theme';

import 'baas-ui/common/styles/darkreader-overrides.less';

export enum Variant {
  Default,
  Brand,
}
interface SvgProps {
  index: number;
  size?: number;
  className?: string;
}

interface IndexIconProps extends SvgProps {
  disabled?: boolean;
  variant?: Variant;
  className?: string;
}

const StyledCircle = styled('circle')`
  fill: ${({ color }) => color};
  stroke: ${({ color }) => color};
`;

const StyledText = styled('text')`
  fill: ${({ color }) => color};
`;

const IndexSvg = ({ index, size, variant, className, ...rest }: SvgProps & { variant: Variant }) => {
  const themeValue = useThemeValue(useDarkMode());
  const iconColor = themeValue({ dark: 'white', light: 'grayDark3' });
  const textColor = themeValue({ dark: 'grayDark3', light: 'white' });

  return (
    <svg {...rest} height={size} width={size} viewBox="0 0 24 24" className={className}>
      {variant === Variant.Default && (
        <>
          <StyledCircle cx="12" cy="12" r="11" color={iconColor} />
          <StyledText
            x="50%"
            y="50%"
            textAnchor="middle"
            dy=".3em"
            fontSize="smaller"
            fontWeight="bold"
            color={textColor}
          >
            {index}
          </StyledText>
        </>
      )}
      {variant === Variant.Brand && (
        <>
          <circle cx="12" cy="12" r="11" fill="#4F4FBF" stroke="#4F4FBF" />
          <text x="50%" y="50%" textAnchor="middle" fill={palette.white} dy=".3em" fontSize="smaller" fontWeight="bold">
            {index}
          </text>
        </>
      )}
    </svg>
  );
};

const DisabledIndexSvg = ({ index, size, className, ...rest }: SvgProps) => (
  <svg {...rest} height={size} width={size} viewBox="0 0 24 24" className={className}>
    <circle cx="12" cy="12" r="11" fill="white" stroke={palette.gray.base} />
    <text x="50%" y="50%" textAnchor="middle" fill={palette.gray.base} dy=".3em" fontSize="smaller" fontWeight="bold">
      {index}
    </text>
  </svg>
);

const IndexIcon = ({
  index,
  size = 24,
  disabled = false,
  variant = Variant.Default,
  className,
  ...rest
}: IndexIconProps) => {
  const Icon = createIconComponent({
    [`${index}`]: () =>
      disabled ? (
        <DisabledIndexSvg index={index} size={size} className={className} {...passThroughProps(rest)} />
      ) : (
        <IndexSvg index={index} size={size} variant={variant} className={className} {...passThroughProps(rest)} />
      ),
  });

  return <Icon glyph={`${index}`} {...passThroughProps(rest)} />;
};

export default IndexIcon;
