import { setResourceNotFoundError } from './app/actions';

// handleResourceNotFound should be used to wrap an asyncActionExecutor from util.js.
// In the event of a 404 on the wrapped async action handleResourceNotFound will dispatch
// setResourceNotFoundError to set and error message on the app state.
// App.js checks if this error is set and will render a 404 page appropriately.
export const handleResourceNotFound =
  (fetchAction) =>
  (...args) =>
  (dispatch) => {
    return dispatch(fetchAction(...args)).catch((e) => {
      if (e.response.status === 404) {
        dispatch(setResourceNotFoundError(e));
      }
      throw e;
    });
  };
