import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import { Body, Disclaimer, H2 } from '@leafygreen-ui/typography';

import { FormRow, FormRowInputGroup, FormRowLabelGroup } from 'baas-ui/common/components/form-row';
import { TopNav } from 'baas-ui/nav';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { performUserProfileInitActions } from 'baas-ui/sagas';
import { getSettingsState } from 'baas-ui/selectors';
import * as actions from 'baas-ui/session/actions';
import { RootState } from 'baas-ui/types';
import { rootUrl } from 'baas-ui/urls';
import { enterPressedCaller } from 'baas-ui/util';
import { CreateTempAPIKeyResponse, ServerError } from 'admin-sdk';

interface ReduxDispatchProps {
  setLoginError(error: ServerError): void;
  loadRootAuthProviders(): void;
  login(username: string, password: string): Promise<void>;
  loginCloud(username: string, apiKey: string): Promise<void>;
  performUserProfileInitActions(): void;
  generateTempApiKey(): Promise<CreateTempAPIKeyResponse>;
}

interface ReduxStateProps {
  authProviders: Object;
  loginError: string;
  showCloudNav?: boolean;
}

export type Props = ReduxDispatchProps & ReduxStateProps;

const Login = ({
  authProviders = {},
  loginError = '',
  showCloudNav = false,
  performUserProfileInitActions: initUserProfile,
  loadRootAuthProviders,
  login,
  generateTempApiKey,
  loginCloud,
  setLoginError,
}: Props) => {
  const history = useHistory();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [useTempApiToken, setUseTempApiToken] = useState(false);

  useEffect(() => {
    loadRootAuthProviders();
  }, []);

  const doLogin = () => {
    login(username, password)
      .then(() => history.push(rootUrl.get()))
      .then(() => initUserProfile())
      .catch((e: ServerError) => setLoginError(e));
  };

  const doLoginCloud = () => {
    let loginPromise = loginCloud(username, apiKey);

    if (useTempApiToken) {
      loginPromise = loginPromise.then(() => generateTempApiKey()).then(({ key }) => loginCloud(username, key));
    }

    loginPromise
      .then(() => history.push(rootUrl.get()))
      .then(() => initUserProfile())
      .catch((e: ServerError) => setLoginError(e));
  };

  return (
    <div>
      {!showCloudNav && <TopNav />}
      <div className="main-content">
        <div className="login-form">
          <H2 className="login-form-title">Log in</H2>
          {loginError && <Banner variant={Variant.Danger}>{loginError}</Banner>}
          {authProviders['local-userpass'] && (
            <div className="login-form-provider">
              <FormRow>
                <FormRowLabelGroup>
                  <label className="form-row-label" htmlFor="username">
                    Username
                  </label>
                </FormRowLabelGroup>
                <FormRowInputGroup>
                  <input
                    type="text"
                    id="username"
                    className="text-input form-row-text-input"
                    placeholder="user@domain.com"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    data-cy="username"
                  />
                </FormRowInputGroup>
              </FormRow>
              <FormRow last>
                <FormRowLabelGroup>
                  <label className="form-row-label" htmlFor="password">
                    Password
                  </label>
                </FormRowLabelGroup>
                <FormRowInputGroup>
                  <input
                    type="password"
                    id="password"
                    className="text-input form-row-text-input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={enterPressedCaller(doLogin)}
                    data-cy="password"
                  />
                </FormRowInputGroup>
              </FormRow>
              <div className="login-form-submit-panel">
                <Button variant="primary" className="login-form-submit-button" onClick={doLogin}>
                  Log In
                </Button>
              </div>
            </div>
          )}

          {authProviders['mongodb-cloud'] && (
            <div className="login-form-provider">
              <Body className="login-form-provider-cloud">Log in with MongoDB Cloud</Body>
              <FormRow>
                <FormRowLabelGroup>
                  <label className="form-row-label" htmlFor="username">
                    Username
                  </label>
                </FormRowLabelGroup>
                <FormRowInputGroup>
                  <input
                    type="text"
                    id="username"
                    className="text-input form-row-text-input"
                    placeholder="user@domain.com"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    data-cy="username"
                  />
                </FormRowInputGroup>
              </FormRow>
              <FormRow>
                <FormRowLabelGroup>
                  <label className="form-row-label" htmlFor="apiKey">
                    API Key
                  </label>
                </FormRowLabelGroup>
                <FormRowInputGroup>
                  <input
                    type="password"
                    id="apiKey"
                    className="text-input form-row-text-input"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    onKeyPress={enterPressedCaller(doLoginCloud)}
                    data-cy="apiKey"
                  />
                </FormRowInputGroup>
              </FormRow>
              <FormRow last>
                <FormRowLabelGroup>
                  <label className="form-row-label" htmlFor="apiKey">
                    Generate temporary API key
                  </label>
                </FormRowLabelGroup>
                <FormRowInputGroup>
                  <input
                    type="checkbox"
                    id="tempApiKey"
                    checked={useTempApiToken}
                    onChange={() => setUseTempApiToken(!useTempApiToken)}
                  />
                </FormRowInputGroup>
                <Disclaimer>
                  (An API key of type Temporary will be automatically generated for you and used on any subsequent
                  request, it lasts 24 hours)
                </Disclaimer>
              </FormRow>
              <div className="login-form-submit-panel">
                <Button variant="primary" className="login-form-submit-button" onClick={doLoginCloud}>
                  Log In
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { authProviders, loginError } = state.session;
  const { showCloudNav } = getSettingsState(state);

  return { authProviders, loginError, showCloudNav };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  setLoginError: (e: ServerError) => dispatch(actions.setLoginError(e)),
  loadRootAuthProviders: () => dispatch(actions.loadRootAuthProviders(undefined)),
  login: (username: string, password: string) => dispatch(actions.login({ username, password })),
  loginCloud: (username: string, apiKey: string) => dispatch(actions.loginCloud({ username, apiKey })),
  performUserProfileInitActions: () => dispatch(performUserProfileInitActions()),
  generateTempApiKey: () => dispatch(actions.generateTempApiKey(undefined)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
