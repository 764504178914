import { createReducer } from 'redux-act';

import * as actions from './actions';

export const defaultState = {
  app: undefined,
  apps: undefined,
  loadingApp: false,
  loadingFeatureFlags: false,
  enabledFeatureFlags: undefined,
  isFullscreen: false,
  resourceNotFoundError: undefined,
  setEnvironmentData: {
    isLoading: false,
    error: '',
  },
  isDeploymentMigrating: false,
};
const appReducer = createReducer(
  {
    [actions.loadAppActions.req]: (state) => ({
      ...state,
      loadingApp: true,
    }),
    [actions.loadAppActions.rcv]: (state, { payload }) => ({
      ...state,
      loadingApp: false,
      app: payload,
    }),
    [actions.getAppEnabledFeatureFlagsActions.fail]: (state) => ({
      ...state,
      loadingFeatureFlags: false,
    }),
    [actions.getAppEnabledFeatureFlagsActions.req]: (state) => ({
      ...state,
      loadingFeatureFlags: true,
    }),
    [actions.getAppEnabledFeatureFlagsActions.rcv]: (state, { payload }) => ({
      ...state,
      loadingFeatureFlags: false,
      enabledFeatureFlags: payload.enabled,
    }),
    [actions.loadAppActions.fail]: (state, payload) => ({
      ...state,
      error: payload.error,
    }),
    [actions.toggleIsFullscreen]: (state) => ({
      ...state,
      isFullscreen: !state.isFullscreen,
    }),
    [actions.setResourceNotFoundError]: (state, error) => ({
      ...state,
      resourceNotFoundError: error,
    }),
    [actions.setDeploymentMigrating]: (state, payload) => ({
      ...state,
      isDeploymentMigrating: payload,
    }),
    [actions.setAppEnvironmentActions.req]: (state) => ({
      ...state,
      setEnvironmentData: {
        isLoading: true,
      },
    }),
    [actions.setAppEnvironmentActions.rcv]: (state, { payload }) => ({
      ...state,
      app: {
        ...state.app,
        environment: payload.environment,
      },
      setEnvironmentData: {
        error: '',
        isLoading: false,
      },
    }),
    [actions.setAppEnvironmentActions.fail]: (state, error) => ({
      ...state,
      setEnvironmentData: {
        error,
        isLoading: false,
      },
    }),
  },
  defaultState
);

export default appReducer;
