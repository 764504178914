import React from 'react';
import Button from '@leafygreen-ui/button';
import { Body } from '@leafygreen-ui/typography';

import { Track } from 'baas-ui/tracking';

import './github-authorization-button.less';

const authButton = 'github-authorization-button';

export enum TestSelector {
  AuthorizeGitHub = 'authorize-github',
  GithubUsername = 'github-username',
  GithubAuthError = 'github-auth-error',
}

export type Props = {
  isAuthorized: boolean;
  hasAuthorizationError?: boolean;
  buttonText?: string;
  disabled?: boolean;
  onGithubAuthClick(): void;
};

function GithubAuthorizationButton({
  isAuthorized,
  hasAuthorizationError,
  buttonText,
  disabled,
  onGithubAuthClick,
}: Props) {
  return (
    <>
      <div className={`${authButton}-row`}>
        <Track event="DEPLOY_CONFIGURATION.GITHUB_APP_AUTHORIZED_CLICKED">
          <Button
            size="xsmall"
            data-test-selector={TestSelector.AuthorizeGitHub}
            data-testid={TestSelector.AuthorizeGitHub}
            onClick={onGithubAuthClick}
            disabled={disabled}
          >
            <span className={`${authButton}-row-text`}>{buttonText || (isAuthorized ? 'Unlink' : 'Authorize')}</span>
          </Button>
        </Track>
      </div>
      {hasAuthorizationError && (
        <Body data-test-selector={TestSelector.GithubAuthError} className={`${authButton}-error`}>
          {`Authorization unsuccessful. Ensure you've installed App Services as a GitHub App.`}
        </Body>
      )}
    </>
  );
}

export default GithubAuthorizationButton;
