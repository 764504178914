import { RawPartitionSyncPermissions } from 'baas-ui/sync/types';
import { MongoDBSyncIncompatibleRole } from 'admin-sdk';

type TemplateOption = { value: RuleTemplateType | FlexibleSyncTemplateType; label: string };
type TemplatesMap<T> = Partial<{ [t in RuleTemplateType]: T }>;

export enum RuleTemplateType {
  NoTemplate = 'no_template',
  ReadWriteOwnData = 'read_write_own_data',
  ReadAllWriteOwnData = 'read_all_write_own_data',
  ReadAllWriteNone = 'read_all_write_none',
}

export interface PartitionRuleTemplate {
  type: RuleTemplateType;
  display: string;
  permissions: RawPartitionSyncPermissions;
}

const USER_PARTITION = { '%%partition': '%%user.id' };

export const DEFAULT_PARTITION_RULE_TEMPLATE: PartitionRuleTemplate = {
  type: RuleTemplateType.NoTemplate,
  display: 'No template',
  permissions: {
    read: {},
    write: {},
  },
};

export const PARTITION_RULE_TEMPLATES: PartitionRuleTemplate[] = [
  DEFAULT_PARTITION_RULE_TEMPLATE,
  {
    type: RuleTemplateType.ReadWriteOwnData,
    display: 'Users can only read and write their own data',
    permissions: {
      read: USER_PARTITION,
      write: USER_PARTITION,
    },
  },
  {
    type: RuleTemplateType.ReadAllWriteOwnData,
    display: 'Users can read all data, but only write their own data',
    permissions: {
      read: true,
      write: USER_PARTITION,
    },
  },
  {
    type: RuleTemplateType.ReadAllWriteNone,
    display: 'Users can only read all data',
    permissions: {
      read: true,
      write: false,
    },
  },
];

export const TEMPLATE_OPTIONS = PARTITION_RULE_TEMPLATES.map((t) => ({ value: t.type, label: t.display }));

export const TEMPLATE_OPTIONS_BY_TYPE = TEMPLATE_OPTIONS.reduce<TemplatesMap<TemplateOption>>(
  (acc, t) => ({ ...acc, [t.value]: t }),
  {}
);

export const RULE_TEMPLATES_BY_TYPE = PARTITION_RULE_TEMPLATES.reduce<TemplatesMap<PartitionRuleTemplate>>(
  (acc, t) => ({ ...acc, [t.type]: t }),
  {}
);

export enum FlexibleSyncTemplateType {
  Custom = 'custom',
  DenyAllAccess = 'deny_all_access',
  ReadAllWriteNone = 'read_all_write_none',
  ReadAllWriteAll = 'read_all_write_all',
  ReadOwnWriteOwn = 'read_own_write_own',
  ReadAllWriteOwn = 'read_all_write_own',
  AdminReadOwnWriteOwn = 'admin_read_own_write_own',
}
export interface FlexibleSyncRuleTemplate {
  type: FlexibleSyncTemplateType;
  display: string;
  permissions: string;
  tooltip: string;
}

export const FLEXIBLE_RULE_TEMPLATES: FlexibleSyncRuleTemplate[] = [
  {
    type: FlexibleSyncTemplateType.Custom,
    display: 'Custom (start from scratch)',
    tooltip: '',
    permissions: ``,
  },
  {
    type: FlexibleSyncTemplateType.DenyAllAccess,
    display: 'Deny all access',
    tooltip: '',
    permissions: `{
  "defaultRoles": [
    {
      "name": "no-access",
      "applyWhen": {},
      "read": false,
      "write": false
    }
  ]
}
    `,
  },
  {
    type: FlexibleSyncTemplateType.ReadAllWriteNone,
    display: 'Users can read all data',
    tooltip: '',
    permissions: `{
  "defaultRoles": [
    {
      "name": "read-only",
      "applyWhen": {},
      "read": true,
      "write": false
    }
  ]
}
  `,
  },
  {
    type: FlexibleSyncTemplateType.ReadAllWriteAll,
    display: 'Users can read and write all data',
    tooltip: '',
    permissions: `{
  "defaultRoles": [
    {
      "name": "read-write",
      "applyWhen": {},
      "read": true,
      "write": true
    }
  ]
}
  `,
  },
  {
    type: FlexibleSyncTemplateType.ReadOwnWriteOwn,
    display: 'Users can only read and write their own data',
    tooltip: 'You may have to choose which field specifies ownership. (e.g owner_id in the template below)',
    permissions: `{
  "defaultRoles": [
    {
      "name": "owner-read-write",
      "applyWhen": {},
      "read": {"owner_id": "%%user.id"},
      "write": {"owner_id": "%%user.id"}
    }
  ]
}
    `,
  },
  {
    type: FlexibleSyncTemplateType.ReadAllWriteOwn,
    display: 'Users can read all data but only write their own data',
    tooltip: 'You may have to choose which field specifies ownership. (e.g owner_id in the template below)',
    permissions: `{
  "defaultRoles": [
    {
      "name": "owner-write",
      "applyWhen": {},
      "read": true,
      "write": {"owner_id": "%%user.id"}
    }
  ]
}
    `,
  },
  {
    type: FlexibleSyncTemplateType.AdminReadOwnWriteOwn,
    display: 'Users can read and write their own data, admins can read and write all data',
    tooltip:
      'You may have to update the field that specifies admin on user’s custom data, eg. isGlobalAdmin in the below example. You will also need to specify which field specifies “ownership” in the document, eg. owner_id in the below example',
    permissions: `{
  "defaultRoles": [
    {
      "name": "admin",
      "applyWhen": { "%%user.custom_data.isGlobalAdmin": true},
      "read": true,
      "write": true
    },
    {
      "name": "user",
      "applyWhen": {},
      "read": {"owner_id": "%%user.id"},
      "write": {"owner_id": "%%user.id"}
    }
  ]
}
  `,
  },
];

export const FLEXIBLE_DENY_ALL_ACCESS = FLEXIBLE_RULE_TEMPLATES[1].permissions;

export const FLEXIBLE_TEMPLATE_OPTIONS = FLEXIBLE_RULE_TEMPLATES.map((t) => ({ value: t.type, label: t.display }));

export const FLEXIBLE_TEMPLATE_OPTIONS_BY_TYPE = FLEXIBLE_TEMPLATE_OPTIONS.reduce<TemplatesMap<TemplateOption>>(
  (acc, t) => ({ ...acc, [t.value]: t }),
  {}
);

export const FLEXIBLE_TEMPLATES_BY_TYPE = FLEXIBLE_RULE_TEMPLATES.reduce<TemplatesMap<FlexibleSyncRuleTemplate>>(
  (acc, t) => ({ ...acc, [t.type]: t }),
  {}
);

export interface IncompatibleRolesModalPayload {
  incompatibleRolesByDatabase: IncompatibleRolesByDatabase;
  numIncompatibleCollections: number;
}

export type IncompatibleRolesByDatabase = Record<string, IncompatibleRolesByCollection>;

export type IncompatibleRolesByCollection = Record<string, MongoDBSyncIncompatibleRole[]>;
