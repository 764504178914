import { DeployStatus } from 'baas-ui/deploy/deploy-context/DeployContext';
import { AppEnvironment } from 'admin-sdk';

export const STATUS_TYPE_DEPLOYMENT = 'DEPLOYMENT';
export const INSTALLATION_CHECK_DELAY = 5e3;
export const MAX_INSTALLATION_CHECKS = 4;
export const DEFAULT_BRANCH = 'master';
export const DEFAULT_DIRECTORY = '/';

export const statusMessagesByDeployStatus: { [K in DeployStatus]: string } = {
  [DeployStatus.Deploying]: 'Deploying changes...',
  [DeployStatus.Drafting]: 'Changes have been made to your app since the last deploy.',
  [DeployStatus.Creating]: 'Creating draft. ',
  [DeployStatus.Discarding]: 'Discarding draft...',
  [DeployStatus.Error]: 'Failed to Deploy: ',
  [DeployStatus.Failure]: 'Failed to Deploy: ',
  [DeployStatus.None]: '',
  [DeployStatus.Successful]: 'Deployment was successful!',
  [DeployStatus.SuccessfulWithWarning]: 'Warning on deployment: ',
};

export interface EnvironmentSelectOption {
  value: AppEnvironment;
  label: string;
}

export const environmentOptions: EnvironmentSelectOption[] = [
  { value: AppEnvironment.None, label: 'No Environment' },
  { value: AppEnvironment.Testing, label: 'Testing' },
  { value: AppEnvironment.Development, label: 'Development' },
  { value: AppEnvironment.QA, label: 'QA' },
  { value: AppEnvironment.Production, label: 'Production' },
];
