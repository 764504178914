import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Identity')
export class Identity {
  @JsonProperty('id', String, true)
  public id = '';

  @JsonProperty('provider_type')
  public providerType = '';

  @JsonProperty('provider_id')
  public providerId = '';

  @JsonProperty('provider_data', Any, true)
  public providerData?: Record<string, Any> = undefined;

  constructor(partial?: Partial<User>) {
    Object.assign(this, partial);
  }
}

export enum UserType {
  Server = 'server',
  Normal = 'normal',
  System = 'system',
  Unknown = 'unknown',
}

@JsonObject('RoleAssignment')
export class RoleAssignment {
  @JsonProperty('role_name')
  public roleName = '';

  @JsonProperty('group_id', String, true)
  public groupId?: string = undefined;

  constructor(partial?: Partial<RoleAssignment>) {
    Object.assign(this, partial);
  }
}

@JsonObject('User')
export class User {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('domain_id')
  public domainId = '';

  @JsonProperty('identities', [Identity], true)
  public identities?: Identity[] = undefined;

  @JsonProperty('data', Any, true)
  public data?: Record<string, Any> = undefined;

  @JsonProperty('sessions_valid_since')
  public sessionsValidSince = 0;

  @JsonProperty('creation_date')
  public creationDate = 0;

  @JsonProperty('last_authentication_date')
  public lastAuthenticationDate = 0;

  @JsonProperty('disabled')
  public disabled = false;

  @JsonProperty('type')
  public type: UserType = UserType.Unknown;

  @JsonProperty('roles', [RoleAssignment], true)
  public roles?: RoleAssignment[] = undefined;

  @JsonProperty('custom_data', Any, true)
  public customData?: Record<string, any> = undefined;

  constructor(partial?: Partial<User>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PartialUser')
export class PartialUser {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('identities', [Identity], true)
  public identities?: Identity[] = undefined;

  @JsonProperty('type')
  public type: UserType = UserType.Unknown;

  @JsonProperty('creation_date')
  public creationDate = 0;

  @JsonProperty('last_authentication_date')
  public lastAuthenticationDate = 0;

  @JsonProperty('disabled')
  public disabled = false;

  @JsonProperty('data', Any, true)
  public data?: Record<string, Any> = undefined;

  constructor(partial?: Partial<PartialUser>) {
    Object.assign(this, partial);
  }
}

@JsonObject('UserProfile')
export class UserProfile {
  @JsonProperty('user_id')
  public userId = '';

  @JsonProperty('domain_id')
  public domainId = '';

  @JsonProperty('identities', [Identity], true)
  public identities?: Identity[] = undefined;

  @JsonProperty('custom_data', Any, true)
  public customData?: Record<string, any> = undefined;

  @JsonProperty('data')
  public data: Record<string, any> = {};

  @JsonProperty('type')
  public type: UserType = UserType.Unknown;

  @JsonProperty('roles', [RoleAssignment], true)
  public roles?: RoleAssignment[] = undefined;

  constructor(partial?: Partial<UserProfile>) {
    Object.assign(this, partial);
  }
}

export interface UserFilter {
  descending?: boolean;
  after?: string | [string, string];
  status?: UserStatus;
  providerTypes?: [string];
  sort?: UsersSort;
  limit?: number;
}

export enum UserStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export enum UsersSort {
  Id = '_id',
  Status = 'status',
  CreatedDate = 'created_date',
  LastAuthDate = 'last_authentication_date',
}

@JsonObject('UserActionToken')
export class UserActionToken {
  @JsonProperty('token_id')
  public tokenId = '';

  @JsonProperty('token')
  public token = '';

  constructor(partial?: Partial<UserActionToken>) {
    Object.assign(this, partial);
  }
}

@JsonObject('EmailPasswordRegistrationRequest')
export class EmailPasswordRegistrationRequest {
  @JsonProperty('email')
  public email = '';

  @JsonProperty('password')
  public password = '';

  constructor(partial?: Partial<EmailPasswordRegistrationRequest>) {
    Object.assign(this, partial);
  }
}

@JsonObject('Device')
export class Device {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('client_app_version')
  public clientAppVersion = '';

  @JsonProperty('platform')
  public platform = '';

  @JsonProperty('platform_version')
  public platformVersion = '';

  constructor(partial?: Partial<Device>) {
    Object.assign(this, partial);
  }
}

export enum LoginIDType {
  Email = 'email',
  Username = 'username',
}

@JsonObject('LoginId')
export class LoginId {
  @JsonProperty('id_type', String)
  public idType: LoginIDType = LoginIDType.Email;

  @JsonProperty('id')
  public id = '';

  @JsonProperty('confirmed', Boolean, true)
  public confirmed?: boolean = undefined;

  constructor(partial?: Partial<LoginId>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PasswordRecord')
export class PasswordRecord {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('domain_id')
  public domainId = '';

  @JsonProperty('login_ids', [LoginId])
  public loginIds: LoginId[] = [];

  @JsonProperty('user_id')
  public userId = '';

  constructor(partial?: Partial<PasswordRecord>) {
    Object.assign(this, partial);
  }
}
