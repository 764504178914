import React from 'react';
import { connect } from 'react-redux';

import { redirectTo as redirectToAction } from 'baas-ui/actions';
import { InsertedSnippetToast } from 'baas-ui/common/components/insert-snippet-toast/InsertSnippetToast';
import { useLocalStorage } from 'baas-ui/common/hooks/use-local-storage';
import usePoller from 'baas-ui/common/hooks/use-poller';
import { clusterForNewAppIsProvisioningKey, showClusterProvisioningToastKey } from 'baas-ui/common/local-storage-keys';
import { clusterIsFreeTier } from 'baas-ui/common/utils/util';
import DataAPIEnabledToast from 'baas-ui/endpoints/data-api/enabled-toast/DataAPIEnabledToast';
import { TriggerToast } from 'baas-ui/events/trigger-toast';
import DependenciesStatusToast from 'baas-ui/functions/dependencies/dependencies-status-toast';
import { GuideLocalStorageItem } from 'baas-ui/guides/types';
import * as homeActions from 'baas-ui/home/actions';
import { ClusterProvisionToastState } from 'baas-ui/home/types';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { getAppState, getHomeState, getSettingsState } from 'baas-ui/selectors';
import ClusterProvisionToast from 'baas-ui/services/mongodb/cluster-provision-toast';
import SyncEnabledToast from 'baas-ui/sync/sync-page-config/sync-enabled-toast/SyncEnabledToast';
import SyncTerminatingToast from 'baas-ui/sync/sync-page-config/sync-terminating-toast/SyncTerminatingToast';
import { RootState } from 'baas-ui/types';
import rootUrl, { withQueryParams } from 'baas-ui/urls';
import { AtlasCluster } from 'admin-sdk';

interface OwnProps {
  activeGuide: GuideLocalStorageItem;
  clusterStatePoller: ReturnType<typeof usePoller>;
  guidePopoverExpanded: boolean;
}

interface ReduxStateProps {
  appId: string;
  atlasClustersUrl: string;
  clusterProvisionToastState: ClusterProvisionToastState;
  dataSourceBuildError: string;
}

interface ReduxDispatchProps {
  getAtlasClusters(): Promise<void | AtlasCluster[]>;
  getNewAppClusterState(clusterName: string): Promise<void | AtlasCluster>;
  openGuidesModal(): void;
}

export type Props = OwnProps & ReduxStateProps & ReduxDispatchProps;

// This component renders all the App-level toasts, and contains logic that controls these toasts.
const Toasts = ({
  activeGuide,
  appId,
  atlasClustersUrl,
  clusterProvisionToastState,
  clusterStatePoller,
  dataSourceBuildError,
  guidePopoverExpanded,
  getAtlasClusters,
  getNewAppClusterState,
  openGuidesModal,
}: Props) => {
  const [clusterForNewAppIsProvisioning, setClusterForNewAppIsProvisioning] = useLocalStorage(
    clusterForNewAppIsProvisioningKey(appId),
    false
  );
  const [showClusterProvisioningToast, setShowClusterProvisioningToast] = useLocalStorage(
    showClusterProvisioningToastKey(appId),
    false
  );

  React.useEffect(() => {
    if (appId && clusterForNewAppIsProvisioning) {
      // Find cluster that's being provisioned and start poller for it
      getAtlasClusters()
        .then((atlasClusters) => {
          if (atlasClusters) {
            const freeCluster = atlasClusters.find((cluster) => clusterIsFreeTier(cluster));
            const freeClusterName = freeCluster?.name;
            if (freeClusterName) {
              clusterStatePoller.start(() => getNewAppClusterState(freeClusterName).catch(() => {}), 3000);
            }
          }
        })
        .catch(() => {});
    }

    if (
      !clusterForNewAppIsProvisioning &&
      (clusterProvisionToastState as ClusterProvisionToastState) === ClusterProvisionToastState.None
    ) {
      setShowClusterProvisioningToast(false);
    }
  }, [appId, clusterForNewAppIsProvisioning, clusterProvisionToastState]);

  React.useEffect(() => {
    if (
      (clusterProvisionToastState as ClusterProvisionToastState) === ClusterProvisionToastState.CreatingSucceeded ||
      (clusterProvisionToastState as ClusterProvisionToastState) === ClusterProvisionToastState.Error
    ) {
      setClusterForNewAppIsProvisioning(false);
      clusterStatePoller.stop();
    }
  }, [clusterProvisionToastState]);

  return (
    <>
      <ClusterProvisionToast
        open={showClusterProvisioningToast}
        onClose={() => setShowClusterProvisioningToast(false)}
        state={clusterProvisionToastState}
        error={dataSourceBuildError}
        atlasClustersUrl={atlasClustersUrl}
        guidePopoverOpen={Object.keys(activeGuide).length !== 0}
        guidePopoverExpanded={guidePopoverExpanded}
        onViewGuidesLinkClick={openGuidesModal}
      />
      <DependenciesStatusToast data-cy="dep-install-status-toast" />
      <SyncEnabledToast />
      <SyncTerminatingToast />
      <DataAPIEnabledToast />
      <TriggerToast />
      <InsertedSnippetToast />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { app } = getAppState(state);
  const { cloudUIBaseUrl } = getSettingsState(state);
  const { groupId, dataSourceBuildError, clusterProvisionToastState } = getHomeState(state);

  let dashboardUrl;
  if (app) {
    dashboardUrl = rootUrl.groups().group(app.groupId).apps().app(app.id).dashboard();
  }

  return {
    appId: app.id,
    atlasClustersUrl: `${cloudUIBaseUrl}/v2/${groupId}#clusters`,
    clusterProvisionToastState,
    dashboardUrl,
    dataSourceBuildError,
    groupId,
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  redirectTo: (url) => dispatch(redirectToAction(url)),
  getAtlasClusters: (groupId) => dispatch(homeActions.getAtlasClusters({ groupId })),
  getNewAppClusterState: (groupId, clusterName) =>
    dispatch(homeActions.getNewAppClusterState({ groupId, clusterName })),
});

const mergeProps = (
  { dashboardUrl, groupId, ...otherStateProps }: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: OwnProps
): Props => ({
  ...otherStateProps,
  ...dispatchProps,
  ...ownProps,
  getAtlasClusters: () => dispatchProps.getAtlasClusters(groupId),
  getNewAppClusterState: (clusterName: string) => dispatchProps.getNewAppClusterState(groupId, clusterName),
  openGuidesModal: () => dispatchProps.redirectTo(withQueryParams(dashboardUrl, { guidesModalOpen: true })),
});

export { Toasts as ToastsComponent };

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Toasts);
