import React from 'react';
import { Link } from '@leafygreen-ui/typography';

import { onClickDocsLink } from 'baas-ui/tracking';

import './doc-link.less';

export interface Props {
  children: React.ReactNode;
  className?: string;
  href: string;
  showExternalIcon?: boolean;
  [key: string]: any;
}

// DocLink opens the provided href link in a new tab, and tracks that the link was clicked.
// This component is intended for docLinks.
const DocLink = ({ children, href, className, showExternalIcon, ...rest }: Props) => (
  <Link
    {...rest}
    target="_blank"
    href={href}
    onClick={() => onClickDocsLink(href)}
    className={`doc-link ${className}`}
    rel="noopener noreferrer"
    hideExternalIcon={!showExternalIcon}
  >
    {children}
  </Link>
);

export default DocLink;
