import React from 'react';
import { InlineCode } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import LeafygreenRouterLink from 'baas-ui/common/components/leafygreen-router-link';
import { docLinks } from 'baas-ui/common/links';
import GuideContentBody from 'baas-ui/guides/content/body';
import GuideFeedbackBlurb from 'baas-ui/guides/content/guide-feedback-blurb';
import GuideStepHeader from 'baas-ui/guides/step-header';
import { GuideContent } from 'baas-ui/guides/types';
import { rootUrl } from 'baas-ui/urls';

import 'baas-ui/guides/content/guide-content.less';

export enum TestSelector {
  AuthProviderDocs = 'auth-provider-docs',
  DeployDocs = 'deploy-docs',
  GuidesLink = 'guides-link',
  RolesAndPermissionsDocs = 'roles-and-permissions',
  WatchDocs = 'watch-docs',
  WebCallFunctionDocs = 'web-call-function-docs',
  WebMongoDBDocs = 'web-mongodb-docs',
  WebQuickStartDocs = 'web-quick-start-docs',
}

const guideDataTarget = 'web-sdk-guide';

const buildWebSDKGuide = ({ groupId, appId, clusterId }): GuideContent => {
  const appUrl = rootUrl.groups().group(groupId).apps().app(appId);
  const authProvidersUrl = appUrl.auth().providers().list();
  const guidesUrl = appUrl.guides();
  const rulesUrl = appUrl.clusters().cluster(clusterId).rules().list();

  return {
    header: 'Web SDK',
    steps: [
      {
        body: (
          <div data-cy={`${guideDataTarget}-introduction`}>
            <GuideContentBody>
              In this walkthrough, you will set up data access rules, authentication, and query your data from a client
              application:
            </GuideContentBody>
            <GuideStepHeader index={1} text="Configure a Collection" />
            <GuideStepHeader index={2} text="Set up Roles and Permissions" />
            <GuideStepHeader index={3} text="Set up Application Authentication" />
            <GuideStepHeader index={4} text="Connect to App Services from your Client" />
            <GuideStepHeader index={5} text="Query Atlas Data" />
            <GuideStepHeader index={6} text="Success" />
          </div>
        ),
        showProgress: false,
        nextStepTitle: 'Start',
        nextIsButton: true,
      },
      {
        url: rulesUrl,
        body: (
          <div data-cy={`${guideDataTarget}-step-1`}>
            <GuideStepHeader isTitle index={1} text="Configure a Collection" url={rulesUrl} />
            <GuideContentBody>
              Select a collection from your Atlas Cluster that you would like to set data access and permissions for.
              This will be the collection that you interact with from your web client. You will be prompted to
              &apos;configure&apos; this collection by setting a permissions template. Learn more about these in the
              next step.
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Set up Roles and Permissions',
        nextIsButton: false,
      },
      {
        docsLink: docLinks.MongoDB.RolesPermissions,
        body: (
          <div data-cy={`${guideDataTarget}-step-2`}>
            <GuideStepHeader isTitle index={2} text="Set up Roles and Permissions" />
            <GuideContentBody>
              Choosing a permissions template will set up a “default” role for you automatically. Roles are sets of
              document-level and field-level CRUD permissions that let you control what data each user can see.
            </GuideContentBody>
            <GuideContentBody>
              You must define at least one role before you can successfully query a collection and can learn more about
              setting more advanced permissions&nbsp;
              <DocLink
                data-test-selector={TestSelector.RolesAndPermissionsDocs}
                href={docLinks.MongoDB.RolesPermissions}
              >
                here.
              </DocLink>
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Set Up Authentication',
        nextIsButton: false,
      },
      {
        url: authProvidersUrl,
        docsLink: docLinks.Authentication.LearnMore,
        body: (
          <div data-cy={`${guideDataTarget}-step-3`}>
            <GuideStepHeader isTitle index={3} text="Set up Authentication" url={authProvidersUrl} />
            <GuideContentBody>App Services manages authentication for your application’s end users.</GuideContentBody>
            <GuideContentBody>
              You can enable any of the authentication providers listed on this page. Every application must have at
              least one authentication provider configured - this way App Services can apply role-based permissions for
              each operation associated with the user’s requests.
            </GuideContentBody>
            <GuideContentBody>
              Read more about authentication providers&nbsp;
              <DocLink data-test-selector={TestSelector.AuthProviderDocs} href={docLinks.Authentication.LearnMore}>
                here.
              </DocLink>
            </GuideContentBody>
            <GuideContentBody>
              Remember to&nbsp;
              <b>
                Save and&nbsp;
                <DocLink data-test-selector={TestSelector.DeployDocs} href={docLinks.Deploy.DeployUI}>
                  Deploy
                </DocLink>
              </b>
              &nbsp;your changes.
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Connect App Services from the Client',
        nextIsButton: false,
      },
      {
        docsLink: docLinks.SDKs.Web.QuickStart,
        body: (
          <div data-cy={`${guideDataTarget}-step-4`}>
            <GuideStepHeader isTitle index={4} text="Connect App Services from Your Client Application" />
            <GuideContentBody>
              View the instructions for setting up your client application&nbsp;
              <DocLink data-test-selector={TestSelector.WebQuickStartDocs} href={docLinks.SDKs.Web.QuickStart}>
                here.
              </DocLink>
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Query Atlas Data',
        nextIsButton: false,
      },
      {
        body: (
          <div data-cy={`${guideDataTarget}-step-5`}>
            <GuideStepHeader isTitle index={5} text="Query Atlas Data with Realm’s MongoClient" />
            <GuideContentBody>
              Once you’ve connected to your App Service from your web app, you can query data stored in Atlas directly
              from your client application code using the <InlineCode>MongoClient</InlineCode>.
            </GuideContentBody>
            <GuideContentBody>
              Read more about ways to access MongoDB data from the client in our docs:
            </GuideContentBody>
            <GuideContentBody>
              a)&nbsp;
              <DocLink data-test-selector={TestSelector.WebMongoDBDocs} href={docLinks.SDKs.Web.MongoDB}>
                Perform CRUD with the MongoDB query
              </DocLink>
            </GuideContentBody>
            <GuideContentBody>
              b)&nbsp;
              <DocLink data-test-selector={TestSelector.WebCallFunctionDocs} href={docLinks.SDKs.Web.CallFunction}>
                Call an App Services Function from the client
              </DocLink>
            </GuideContentBody>
            <GuideContentBody>
              c) Watch for changes in a linked collection using&nbsp;
              <InlineCode
                data-test-selector={TestSelector.WatchDocs}
                href={docLinks.MongoDB.Watch}
                target="_blank"
                rel="noopener noreferrer"
              >
                watch()
              </InlineCode>
            </GuideContentBody>
          </div>
        ),
        showProgress: true,
        nextStepTitle: 'Success',
        nextIsButton: false,
      },
      {
        body: (
          <div data-cy={`${guideDataTarget}-conclusion`}>
            <GuideStepHeader isTitle text="Success" />
            <GuideContentBody>
              Congratulations - You’ve successfully connected your web application to your Realm. Be sure to check
              out&nbsp;
              <LeafygreenRouterLink data-test-selector={TestSelector.GuidesLink} to={guidesUrl}>
                other guides
              </LeafygreenRouterLink>
              &nbsp; to learn more about other services.
            </GuideContentBody>
            <GuideFeedbackBlurb />
          </div>
        ),
        showProgress: false,
        nextStepTitle: 'Finish',
        nextIsButton: true,
      },
    ],
  };
};

export default buildWebSDKGuide;
