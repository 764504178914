import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('APIKey')
export class APIKey {
  @JsonProperty('_id', String, true)
  public id?: string = undefined;

  @JsonProperty('key')
  public key = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('disabled')
  public disabled = false;

  constructor(partial?: Partial<APIKey>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PartialAPIKey')
export class PartialAPIKey {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('disabled')
  public disabled = false;

  constructor(partial?: Partial<PartialAPIKey>) {
    Object.assign(this, partial);
  }
}
