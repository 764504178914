import React from 'react';
import Button, { Variant } from '@leafygreen-ui/button';
import PropTypes from 'prop-types';

import { passThroughProps } from 'baas-ui/common/utils/util';

import { FormRow, FormRowInputGroup, SaveStatus } from '.';

import './form-row.less';

export enum TestSelector {
  ContainerFormRow = 'container-form-row',
  SubmitButton = 'submit-button',
  CancelButton = 'cancel-button',
  SaveStatusC = 'save-status-component',
}

export interface Props {
  className?: string;
  submitPrimary?: boolean;
  submitDisabled?: boolean;
  submitDisabledLabel?: string;
  submitLabel: string;
  submitError?: string;
  submitting?: boolean;
  hasValidationError?: boolean;
  isDraft?: boolean;
  onSubmit(): void;
  onCancel?(): void;
}

export default function FormRowSubmitButtonFooter({
  className,
  onCancel = () => {},
  onSubmit,
  submitLabel,
  submitPrimary = true,
  submitDisabled = false,
  submitDisabledLabel = 'disabled',
  submitError = '',
  submitting = false,
  hasValidationError = false,
  isDraft = false,
  ...rest
}: Props) {
  return (
    <FormRow last className={className} data-testid={TestSelector.ContainerFormRow} {...passThroughProps(rest)}>
      <FormRowInputGroup className="form-row-button-footer-actions-container" right>
        <SaveStatus
          saving={submitting}
          saveError={submitError}
          hasValidationError={hasValidationError}
          className="form-footer-save-status"
          isDraft={isDraft}
          data-testid={TestSelector.SaveStatusC}
        />
        {!submitting && (
          <Button onClick={onCancel} data-testid={TestSelector.CancelButton}>
            Cancel
          </Button>
        )}
        <Button
          variant={submitPrimary ? Variant.Primary : Variant.Danger}
          onClick={onSubmit}
          disabled={submitDisabled || submitting}
          data-testid={TestSelector.SubmitButton}
          data-cy="form-row-submit-button"
        >
          {submitDisabled ? submitDisabledLabel : submitLabel}
        </Button>
      </FormRowInputGroup>
    </FormRow>
  );
}

FormRowSubmitButtonFooter.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
  submitPrimary: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  submitDisabledLabel: PropTypes.string,
  submitError: PropTypes.string,
  submitting: PropTypes.bool,
  hasValidationError: PropTypes.bool,
  isDraft: PropTypes.bool,
};
