import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@leafygreen-ui/tabs';
import Tooltip from '@leafygreen-ui/tooltip';
import { Body } from '@leafygreen-ui/typography';

import { AsyncDispatch } from 'baas-ui/redux_util';
import { loadDefaultRuleNamespaces } from 'baas-ui/rules/actions';
import CollectionsUsingDefaultRuleModal from 'baas-ui/rules/collections-using-default-rule-modal/';
import PresetRolesDisplay from 'baas-ui/rules/preset-roles-display';
import * as pc from 'baas-ui/rules/preset-roles-display/constants';
import FiltersCards from 'baas-ui/rules/rule-viewer/filters';
import FiltersEmptyState from 'baas-ui/rules/rule-viewer/filters-empty-state/FiltersEmptyState';
import RoleCards from 'baas-ui/rules/rule-viewer/roles';
import { ViewerState } from 'baas-ui/rules/rule-viewer/RuleViewer';
import { RulesPageCollectionExplorerContext } from 'baas-ui/rules/RulesPage';
import { CardActionFunction } from 'baas-ui/rules/types';
import { useRulesPageContext } from 'baas-ui/rules/useRulesPageContext';
import { isNamespaceRule } from 'baas-ui/rules/utils';
import { track } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import { MongoDBNamespace, MongoDBRuleFilter, MongoDBRuleRole, PresetRole } from 'admin-sdk';

import './roles-and-filters.less';

export enum TestSelector {
  TabsContainer = 'tabs-container',
  RolesAndFiltersTabs = 'roles-and-filters-tabs',
  RolesTab = 'roles-tab',
  FiltersTab = 'filters-tab',
  UsedByCollections = 'used-by-collections',
  UsedByCollectionsTooltip = 'used-by-collections-tooltip',
  NamespaceName = 'namespace-name',
  PresetRoles = 'preset-roles',
  DefaultRuleCollectionsModal = 'default-rule-collections-modal',
}

export enum RolesAndFiltersTab {
  Roles,
  Filters,
}

export interface Props {
  roles: MongoDBRuleRole[];
  filters: MongoDBRuleFilter[];
  headerLeftComponent: React.ReactNode;
  onClickAddPresetRoles(presetRole: PresetRole): void;
  onClickAddFilter(): void;
  onClickDeleteFilter: CardActionFunction;
  onClickEditFilter: CardActionFunction;
  onClickAddRoleFromScratch(): void;
  onClickDeleteRole: CardActionFunction;
  onClickEditRole: CardActionFunction;
  onClickUpRole: CardActionFunction;
  onClickDownRole: CardActionFunction;
  onClickLearnMoreLink: () => void;
  setSelectedTabValue(tab: RolesAndFiltersTab): void;
  selectedTabValue?: RolesAndFiltersTab;
  setViewerState(viewerState: ViewerState): void;
  setRulesPageError: (errMsg: string) => void;
}

const baseClassName = 'roles-and-filters';

export default function RolesAndFilters({
  filters,
  roles,
  headerLeftComponent,
  onClickAddPresetRoles,
  onClickAddFilter,
  onClickDeleteFilter,
  onClickEditFilter,
  onClickAddRoleFromScratch,
  onClickDeleteRole,
  onClickEditRole,
  onClickUpRole,
  onClickDownRole,
  onClickLearnMoreLink,
  setSelectedTabValue,
  selectedTabValue = RolesAndFiltersTab.Roles,
  setViewerState,
  setRulesPageError,
}: Props) {
  const { selectedNamespace } = useContext(RulesPageCollectionExplorerContext);

  const {
    presetRoles: contextPresetRoles,
    selectedDataSource,
    activeRule,
    setOnSaveSuccess,
    partialDataSources,
  } = useRulesPageContext();

  const { id: appId, groupId } = useSelector((state: RootState) => state.app.app);

  const isDefaultRule = selectedNamespace && selectedNamespace.split(':', 3).length < 3;

  const [showPresetRoles, setShowPresetRoles] = useState(roles.length === 0);
  const [loadingDefaultRuleNamespaces, setLoadingDefaultRulesNamespaces] = useState(false);
  const [namespacesUsingDefaultRule, setNamespacesUsingDefaultRule] = useState<string[]>([]);
  const [openCollectionsUsingDefaultRuleModal, setOpenCollectionsUsingDefaultRuleModal] = useState(false);

  const dispatch = useDispatch<AsyncDispatch>();

  useEffect(() => {
    setOnSaveSuccess(() => {
      setViewerState(ViewerState.RolesAndFiltersS);
    });
  }, []);

  useEffect(() => {
    if (isDefaultRule) {
      const dataSource = partialDataSources.find((ds) => ds.name === selectedNamespace);
      const dataSourceId = dataSource?.id;
      if (dataSourceId) {
        setLoadingDefaultRulesNamespaces(true);
        dispatch(loadDefaultRuleNamespaces({ groupId, appId, dataSourceId }))
          .then((namespaces: MongoDBNamespace[]) => {
            if (namespaces?.length) {
              setNamespacesUsingDefaultRule(
                namespaces.flatMap((namespace) => namespace.Collections.map((col) => `${namespace.Database}.${col}`))
              );
            }
          })
          .catch((error) => {
            setRulesPageError(error.message);
          })
          .finally(() => {
            setLoadingDefaultRulesNamespaces(false);
          });
      }
    }
  }, [selectedNamespace, isDefaultRule, roles, filters]);

  const baseTrackingFields = {
    ruleId: activeRule?.id,
    ruleType: isDefaultRule ? 'Default' : 'Namespace',
    dataSourceName: selectedDataSource,
    ...(activeRule && isNamespaceRule(activeRule)
      ? { databaseName: activeRule.database, collectionName: activeRule.collection }
      : undefined),
  };

  useEffect(() => {
    track(
      selectedTabValue === RolesAndFiltersTab.Roles
        ? 'RULES_CONFIGURATION.ROLES_VIEWED'
        : 'RULES_CONFIGURATION.FILTERS_VIEWED',
      baseTrackingFields
    );
    setSelectedTabValue(selectedTabValue);
  }, [selectedTabValue]);

  useEffect(() => {
    if (roles.length === 0) {
      const isEmptyConfiguredRule = !!activeRule?.id && roles.length === 0;
      setShowPresetRoles(!isEmptyConfiguredRule);
    } else {
      setShowPresetRoles(false);
    }
  }, [roles.length, activeRule]);

  const hasCollectionsUsingDefaultRule = namespacesUsingDefaultRule.length > 0;

  return (
    <>
      <div className={`${baseClassName}-header`} data-testid={TestSelector.TabsContainer} data-cy={'roles-and-filters'}>
        <Tabs
          setSelected={(tab: RolesAndFiltersTab) => {
            track(
              tab === RolesAndFiltersTab.Roles
                ? 'RULES_CONFIGURATION.ROLES_VIEWED'
                : 'RULES_CONFIGURATION.FILTERS_VIEWED',
              baseTrackingFields
            );
            setSelectedTabValue(tab);
          }}
          selected={selectedTabValue}
          aria-label="Rules Navigation Tabs"
          className={`${baseClassName}-tabs`}
          data-testid={TestSelector.RolesAndFiltersTabs}
          data-test-selector={TestSelector.RolesAndFiltersTabs}
        >
          <Tab data-testid={TestSelector.RolesTab} data-cy={TestSelector.RolesTab} name="Roles" />
          <Tab data-testid={TestSelector.FiltersTab} data-cy={TestSelector.FiltersTab} name="Filters" />
        </Tabs>
        <div className={`${baseClassName}-header-right`}>
          {!loadingDefaultRuleNamespaces && !!selectedDataSource && isDefaultRule && (
            <>
              <Tooltip
                className={`${baseClassName}-tooltip`}
                data-testid={TestSelector.UsedByCollectionsTooltip}
                data-test-selector={TestSelector.UsedByCollectionsTooltip}
                style={hasCollectionsUsingDefaultRule ? { width: '250px' } : { width: '190px' }}
                align="bottom"
                justify="middle"
                trigger={
                  <div>
                    <Body
                      className={`${baseClassName}-tooltip-trigger`}
                      data-testid={TestSelector.UsedByCollections}
                      data-cy={TestSelector.UsedByCollections}
                      onClick={() => hasCollectionsUsingDefaultRule && setOpenCollectionsUsingDefaultRuleModal(true)}
                    >
                      Used by {namespacesUsingDefaultRule.length} collections
                    </Body>
                  </div>
                }
              >
                {hasCollectionsUsingDefaultRule
                  ? 'Click to see which collections apply your default roles and filters'
                  : 'No default rule is configured'}
              </Tooltip>
              <CollectionsUsingDefaultRuleModal
                open={openCollectionsUsingDefaultRuleModal}
                setOpen={setOpenCollectionsUsingDefaultRuleModal}
                dataSourceName={selectedNamespace}
                namespaces={namespacesUsingDefaultRule}
                data-test-selector={TestSelector.DefaultRuleCollectionsModal}
              />
            </>
          )}

          <Body
            data-testid={TestSelector.NamespaceName}
            data-cy={TestSelector.NamespaceName}
            className={`${baseClassName}-namespace`}
          >
            {selectedNamespace}
          </Body>
        </div>
      </div>

      {/* Roles */}
      {selectedTabValue === RolesAndFiltersTab.Roles && !showPresetRoles && (
        <RoleCards
          onClickAdd={() => setShowPresetRoles(true)}
          onClickDelete={onClickDeleteRole}
          onClickEdit={onClickEditRole}
          onClickUp={onClickUpRole}
          onClickDown={onClickDownRole}
          onClickLearnMoreLink={onClickLearnMoreLink}
          roles={roles}
          headerLeftComponent={headerLeftComponent}
        />
      )}
      {selectedTabValue === RolesAndFiltersTab.Roles &&
        showPresetRoles &&
        (isDefaultRule ? (
          <PresetRolesDisplay
            data-testid={TestSelector.PresetRoles}
            data-test-selector={TestSelector.PresetRoles}
            headingText={pc.defaultRuleHeadingText}
            description={pc.defaultRuleDescription}
            onClickSelectPreset={(selectedPresetRole: string) => {
              track('RULES_CONFIGURATION.PRESET_ROLE_ADDED', {
                presetRoleName: selectedPresetRole,
                ruleType: 'Default',
              });
              onClickAddPresetRoles(contextPresetRoles.find((preset) => preset.name === selectedPresetRole)!);
            }}
            onClickStartFromScratch={() => {
              track('RULES_CONFIGURATION.PRESET_ROLE_SKIPPED', {
                ruleType: 'Default',
              });
              onClickAddRoleFromScratch();
            }}
          />
        ) : (
          <PresetRolesDisplay
            data-testid={TestSelector.PresetRoles}
            data-test-selector={TestSelector.PresetRoles}
            headingText={pc.nsRuleHeadingText}
            description={pc.nsRuleDescription}
            onClickSelectPreset={(selectedPresetRole: string) => {
              track('RULES_CONFIGURATION.PRESET_ROLE_ADDED', {
                presetRoleName: selectedPresetRole,
                ruleType: 'Namespace',
              });
              onClickAddPresetRoles(contextPresetRoles.find((preset) => preset.name === selectedPresetRole)!);
            }}
            onClickStartFromScratch={() => {
              track('RULES_CONFIGURATION.PRESET_ROLE_SKIPPED', {
                ruleType: 'Namespace',
              });
              onClickAddRoleFromScratch();
            }}
          />
        ))}

      {/* Filters */}
      {selectedTabValue === RolesAndFiltersTab.Filters && filters.length > 0 && (
        <FiltersCards
          onClickAdd={onClickAddFilter}
          onClickDelete={onClickDeleteFilter}
          onClickEdit={onClickEditFilter}
          onClickLearnMoreLink={onClickLearnMoreLink}
          filters={filters}
          headerLeftComponent={headerLeftComponent}
        />
      )}
      {selectedTabValue === RolesAndFiltersTab.Filters && filters.length === 0 && (
        <FiltersEmptyState onClickAdd={onClickAddFilter} />
      )}
    </>
  );
}
