import React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import { Variant } from '@leafygreen-ui/confirmation-modal';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';

import { passThroughProps } from 'baas-ui/common/utils/util';
import { PreventNavigationModalVariant } from 'baas-ui/nav/prevent';

import './discard-changes-modal.less';

export enum TestSelector {
  DiscardChangesConfirmationModal = 'discard-changes-confirmation-modal',
  ModalBody = 'modal-body',
}

interface ProceedResult {
  confirmed: boolean;
  defaultValue: boolean;
  text: string;
}

interface ProceedPromptResult {
  promptValue: string;
}

const baseClassName = 'discard-changes-modal';

export interface Props {
  buttonText: string;
  title: string;
  show: boolean;
  proceedResult: ProceedResult[];
  proceed(result: ProceedPromptResult | ProceedResult[]): void;
  modalBody: React.ReactNode;
  modalDataProps?: Record<string, any>;
  variant?: PreventNavigationModalVariant;
}

export const DiscardChangesModal = ({
  buttonText = 'OK',
  title = 'Discard Changes',
  show = false,
  proceed = () => {},
  proceedResult = [],
  modalBody,
  ...rest
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(show);

  const handleConfirmation = () => {
    proceed(proceedResult);
    setIsOpen(false);
  };

  return (
    <ConfirmationModal
      open={isOpen}
      className={baseClassName}
      title={title}
      confirmButtonProps={{
        children: buttonText,
        onClick: handleConfirmation,
      }}
      cancelButtonProps={{
        onClick: () => setIsOpen(false),
      }}
      variant={Variant.Danger}
      data-test-selector={TestSelector.DiscardChangesConfirmationModal}
      data-cy={TestSelector.DiscardChangesConfirmationModal}
      {...passThroughProps(rest)}
    >
      <div className={`${baseClassName}-content`} data-test-selector={TestSelector.ModalBody}>
        {modalBody}
      </div>
    </ConfirmationModal>
  );
};

export { DiscardChangesModal as DiscardChangesModalComponent };

export const confirm = createConfirmation(confirmable(DiscardChangesModal));
