import { JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';

import createStringMapSerializers from '../common/createStringMapSerializers';

/* eslint-disable class-methods-use-this */
@JsonConverter
export class MetricsDateConverter implements JsonCustomConvert<Date> {
  serialize(date: Date): any {
    return date.toISOString();
  }

  deserialize(date: any): Date {
    return new Date(date);
  }
}

@JsonObject('DataPoint')
export class MetricDataPoint {
  @JsonProperty('timestamp', MetricsDateConverter)
  public timestamp: Date = new Date();

  @JsonProperty('value')
  public value: number | null = null;

  constructor(partial?: Partial<MetricDataPoint>) {
    Object.assign(this, partial);
  }
}

@JsonObject('Metric')
export class Metric {
  @JsonProperty('name', String)
  public name = '';

  @JsonProperty('units', String)
  public units = '';

  @JsonProperty('total_usage', Number, true)
  public totalUsage?: number = undefined;

  @JsonProperty('data_points', [MetricDataPoint])
  public dataPoints: MetricDataPoint[] = [];

  constructor(partial?: Partial<Metric>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AppMetrics')
export class AppMetrics {
  @JsonProperty('group_id', String)
  public groupId = '';

  @JsonProperty('app_id', String)
  public appId = '';

  @JsonProperty('app_name', String)
  public appName = '';

  @JsonProperty('start', MetricsDateConverter)
  public start: Date = new Date();

  @JsonProperty('end', MetricsDateConverter)
  public end: Date = new Date();

  @JsonProperty('granularity', String)
  public granularity = '';

  @JsonProperty('measurements', [Metric])
  public measurements: Metric[] = [];

  constructor(partial?: Partial<AppMetrics>) {
    Object.assign(this, partial);
  }
}

const appMetricsMapSerializers = createStringMapSerializers(AppMetrics);
@JsonConverter
class AppMetricsMapConverter implements JsonCustomConvert<Record<string, AppMetrics>> {
  public serialize = appMetricsMapSerializers.serialize;

  public deserialize = appMetricsMapSerializers.deserialize;
}

@JsonObject('GroupMetrics')
export class GroupMetrics {
  @JsonProperty('metrics_map', AppMetricsMapConverter, true)
  public metricsMap: Record<string, AppMetrics> = {};

  constructor(partial?: Partial<GroupMetrics>) {
    Object.assign(this, partial);
  }
}

export interface MetricsRequestOptions {
  start?: Date;
  end?: Date;
  period?: string;
  granularity: string;
  metrics?: string[];
}

export function getMetricsFilter(requestOptions?: MetricsRequestOptions) {
  if (!requestOptions) {
    return undefined;
  }
  const queryParams: Record<string, any> = {};
  if (requestOptions.start) {
    queryParams.start = requestOptions.start.toISOString();
  }
  if (requestOptions.end) {
    queryParams.end = requestOptions.end.toISOString();
  }
  if (requestOptions.period) {
    queryParams.period = requestOptions.period;
  }
  if (requestOptions.granularity) {
    queryParams.granularity = requestOptions.granularity;
  }
  if (requestOptions.metrics) {
    queryParams.metrics = requestOptions.metrics;
  }

  return queryParams;
}
