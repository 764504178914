import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CustomUserDataConfig')
export class CustomUserDataConfig {
  @JsonProperty('mongo_service_id')
  public mongoServiceId = '';

  @JsonProperty('database_name')
  public databaseName = '';

  @JsonProperty('collection_name')
  public collectionName = '';

  @JsonProperty('user_id_field')
  public userIdField = '';

  @JsonProperty('enabled')
  public enabled = false;

  @JsonProperty('on_user_creation_function_id', String, true)
  public onUserCreationFunctionId?: string = undefined;

  constructor(partial?: Partial<CustomUserDataConfig>) {
    Object.assign(this, partial);
  }
}

@JsonObject('RefreshTokenExpiration')
export class RefreshTokenExpirationPayload {
  @JsonProperty('expiration_time_seconds')
  public expirationTimeSeconds = 5184000; // 60 days

  constructor(partial?: Partial<RefreshTokenExpirationPayload>) {
    Object.assign(this, partial);
  }
}
