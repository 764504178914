import React from 'react';
import Banner, { Variant } from '@leafygreen-ui/banner';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import Icon from '@leafygreen-ui/icon';
import { Body } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import { SyncPageConfigDirtyState } from 'baas-ui/sync/types';

import './confirm-save-modal.less';

export enum TestSelector {
  Modal = 'confirm-save-modal',
  ChangesMade = 'confirm-save-modal-changes-made',
  DevModeBanner = 'confirm-save-modal-dev-mode-banner',
  RisksBanner = 'confirm-save-modal-risks-banner',
}

export interface Props {
  open: boolean;
  formDirtyState: SyncPageConfigDirtyState;
  onConfirm(): void;
  onCancel(): void;
}

const baseClassName = 'confirm-save-modal';

const getDirtyFieldsDisplayText = (dirtyState: SyncPageConfigDirtyState) => {
  const {
    isDevDetailsDirty,
    isDevModeDirty,
    isPartitionKeyDirty,
    isQueryableFieldsDirty,
    isPermissionsDirty,
    isClientRecoveryDirty,
    isMaxOfflineTimeDirty,
    isAsymmetricTablesDirty,
  } = dirtyState;

  const changesMade: string[] = [];
  if (isDevDetailsDirty) {
    changesMade.push('Development Details');
  }
  if (isDevModeDirty) {
    changesMade.push('Development Mode');
  }
  if (isPartitionKeyDirty) {
    changesMade.push('Partition Keys');
  }
  if (isQueryableFieldsDirty) {
    changesMade.push('Queryable Fields');
  }
  if (isPermissionsDirty) {
    changesMade.push('Permissions');
  }
  if (isMaxOfflineTimeDirty) {
    changesMade.push('Max Offline Time');
  }
  if (isClientRecoveryDirty) {
    changesMade.push('Client Recovery');
  }
  if (isAsymmetricTablesDirty) {
    changesMade.push('Asymmetric Tables');
  }

  return changesMade;
};

const ConfirmSaveModal = ({ open, formDirtyState, onConfirm, onCancel }: Props) => {
  return (
    <ConfirmationModal
      title="Confirm: Saving changes to Sync details"
      confirmButtonProps={{
        children: 'Save Changes',
        onClick: onConfirm,
      }}
      cancelButtonProps={{
        onClick: onCancel,
      }}
      open={open}
      data-test-selector={TestSelector.Modal}
      data-cy="confirm-save-modal-component"
      className={baseClassName}
    >
      <Body data-cy="confirm-save-modal-changes">
        Changes to your Sync details include:
        <ul className={`${baseClassName}-item-list`} data-test-selector={TestSelector.ChangesMade}>
          {getDirtyFieldsDisplayText(formDirtyState).map((elementChanged: string) => (
            <li data-cy={`dirty-field-${elementChanged.replace(/\s/g, '-').toLowerCase()}`} key={elementChanged}>
              {elementChanged}
            </li>
          ))}
        </ul>
      </Body>
      {formDirtyState.isDevModeDirty && (
        <Banner
          image={<Icon glyph="Warning" size="small" />}
          variant={Variant.Warning}
          className={`${baseClassName}-banner`}
          data-test-selector={TestSelector.DevModeBanner}
          data-cy="confirm-save-modal-dev-mode-banner"
        >
          Turning Dev Mode ON while Sync is being used in production will enable{' '}
          <DocLink href={docLinks.Authentication.Anonymous}>anonymous authentication</DocLink> and allow clients to
          change the Sync Schema at will, potentially leading to Schema mismatch errors.
        </Banner>
      )}
      {formDirtyState.isQueryableFieldsDirty && (
        <Banner
          image={<Icon glyph="Warning" size="small" />}
          variant={Variant.Warning}
          className={`${baseClassName}-banner`}
          data-test-selector={TestSelector.RisksBanner}
          data-cy="confirm-save-modal-risks-banner"
        >
          There are potential risks in adding/removing fields while Sync is running:
          <ul className={`${baseClassName}-item-list`}>
            <li>
              <Body className={`${baseClassName}-item-list-text`}>
                When you add a queryable field, a device using the new field cannot sync on the field until the device
                has caught up to the point in time in Sync History where the field was added.
              </Body>
            </li>
            <li>
              <Body className={`${baseClassName}-item-list-text`}>
                If a queryable field is removed, any device using that field will have their sync session dropped and
                receive a{' '}
                <DocLink href={docLinks.Sync.ClientReset} showExternalIcon>
                  client reset
                </DocLink>
              </Body>
            </li>
          </ul>
        </Banner>
      )}
    </ConfirmationModal>
  );
};

export default ConfirmSaveModal;
