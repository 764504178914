import React, { useContext, useState } from 'react';
import Box from '@leafygreen-ui/box';
import Button, { Variant } from '@leafygreen-ui/button';
import Card from '@leafygreen-ui/card';
import Code from '@leafygreen-ui/code';
import Icon, { Size as IconSize } from '@leafygreen-ui/icon';
import { palette } from '@leafygreen-ui/palette';
import { RadioBox, RadioBoxGroup, Size as RadioBoxSize } from '@leafygreen-ui/radio-box-group';
import { Option, Select } from '@leafygreen-ui/select';
import { Body, H3, Label, Link } from '@leafygreen-ui/typography';

import { prettyJSONStringify } from 'baas-ui/common/utils/util';
import PermissionDisplay, { PermissionDisplayValue } from 'baas-ui/rules/permission-display';
import { RulesPageContext } from 'baas-ui/rules/RulesPage';
import { Permission } from 'baas-ui/rules/types';
import { permissionValToDisplayValMap, PermissionValue, resolvePermissionValue } from 'baas-ui/rules/utils';
import { PresetRole } from 'admin-sdk';

import * as presetRoleConstants from './constants';

import './preset-roles-display.less';

export enum TestSelector {
  Container = 'container',
  AddPresetRoleButton = 'add-preset-role-button',
  DescriptionText = 'description-text',
  DocumentFiltersCode = 'document-filters-code',
  DocumentPermissions = 'document-permissions',
  DocumentPermissionsGroup = 'document-permissions-group',
  FieldPermissions = 'field-permissions',
  FieldPermissionsGroup = 'field-permissions-group',
  Heading = 'heading',
  OtherPresetRolesSelect = 'other-preset-roles-select',
  PermissionsCard = 'permissions-card',
  PermissionsCardTitle = 'permissions-card-title',
  PresetRoleOption = 'preset-role-option',
  PresetRolesGroup = 'preset-roles-group',
  StartFromScratchLink = 'start-from-scratch-link',
}

const baseClassName = 'preset-roles-display';

export interface Props {
  headingText: string;
  description: React.ReactNode;
  onClickStartFromScratch: () => void;
  onClickSelectPreset: (selectedPresetRole: string) => void;
}

export const PresetRolesDisplayComponent = ({
  headingText,
  description,
  onClickSelectPreset,
  onClickStartFromScratch,
}: Props) => {
  const [selectedPresetRole, setSelectedPresetRole] = useState('migratedDenyAllAccess');
  const { presetRoles: contextPresetRoles } = useContext(RulesPageContext);

  // TODO(BAAS-23625) Audit migrated sync rules styles
  // TODO(BAAS-23627) Replace old preset roles with "migrated" counterparts
  const getRadioGroupPresetRoles = () => {
    const radioGroupPresetRoleNames = presetRoleConstants.migratedRadioGroupPresetRoleNames;

    return contextPresetRoles.filter((presetRole) => {
      return radioGroupPresetRoleNames.includes(presetRole.name);
    });
  };
  const radioGroupPresetRoles = getRadioGroupPresetRoles();

  const getPresetRoleDisplayName = (presetRole) => {
    if (presetRoleConstants.migratedRadioGroupPresetRoleNames.includes(presetRole.name)) {
      return presetRoleConstants.migratedPresetRoleNameToDisplayNameMap[presetRole.name];
    }
    return presetRole.name;
  };

  const otherPresetRoles = contextPresetRoles.filter((presetRole) => {
    return (
      !presetRoleConstants.migratedRadioGroupPresetRoleNames.includes(presetRole.name) &&
      !presetRoleConstants.radioGroupPresetRoleNames.includes(presetRole.name)
    );
  });

  const baseClassNameForCard = `${baseClassName}-migrated-card`;

  const getCardTitleBody = (presetRole) => {
    if (presetRole.roles.length > 1) {
      const roleNames = presetRole.roles.map((role) => role.name);
      return `${roleNames.join(', ')} (${presetRole.roles.length} roles — ${
        presetRoleConstants.presetRoleDescriptions[getPresetRoleDisplayName(presetRole)]
      })`;
    }
    return `${getPresetRoleDisplayName(presetRole)} (${
      presetRoleConstants.presetRoleDescriptions[getPresetRoleDisplayName(presetRole)]
    })`;
  };

  return (
    <div className={baseClassName} data-cy="preset-roles-display" data-testid={TestSelector.Container}>
      <div className={`${baseClassName}-content-container`}>
        <div className={`${baseClassName}-header`}>
          <H3 className={`${baseClassName}-header-title`} data-testid={TestSelector.Heading}>
            {headingText}
          </H3>
          <Body className={`${baseClassName}-header-description`} data-testid={TestSelector.DescriptionText}>
            {description}
          </Body>
        </div>
        <div className={`${baseClassName}-info-container`}>
          <div>
            <Label htmlFor="preset-roles-selector">Popular presets</Label>
            <RadioBoxGroup
              name="preset-roles-selector"
              value={selectedPresetRole}
              onChange={(e) => setSelectedPresetRole(e.target.value)}
              className={`${baseClassName}-group`}
              size={RadioBoxSize.Full}
              data-testid={TestSelector.PresetRolesGroup}
            >
              {radioGroupPresetRoles.map((presetRole) => (
                <div className={`${baseClassName}-group-option`} key={`${presetRole.name}-option`}>
                  <RadioBox
                    value={presetRole.name}
                    data-cy={TestSelector.PresetRoleOption}
                    data-testid={TestSelector.PresetRoleOption}
                  >
                    {getPresetRoleDisplayName(presetRole)}
                  </RadioBox>
                  {selectedPresetRole === presetRole.name ? (
                    <Icon
                      glyph="CaretLeft"
                      size={IconSize.XLarge}
                      fill={palette.green.dark1}
                      className={`${baseClassName}-group-option-picker`}
                    />
                  ) : (
                    // NB: empty div rendered here in the place of the icon
                    // in order to prevent the RadioBox from filling the space
                    <div className={`${baseClassName}-group-option-picker`} />
                  )}
                </div>
              ))}
            </RadioBoxGroup>
            <Label id="other-presets-label" htmlFor="other-presets-select">
              Other presets
            </Label>
            <Box className={`${baseClassName}-other-presets`}>
              <Select
                allowDeselect={false}
                aria-labelledby="other-presets-label"
                className={`${baseClassName}-other-presets-select`}
                data-testid={TestSelector.OtherPresetRolesSelect}
                data-cy={TestSelector.OtherPresetRolesSelect}
                name="other-presets-select"
                onChange={(val) => setSelectedPresetRole(val)}
                placeholder={presetRoleConstants.otherPresetRolesPlaceholder}
                value={selectedPresetRole}
              >
                {otherPresetRoles.map((presetRole) => (
                  <Option value={presetRole.name} key={presetRole.name}>
                    {presetRole.roles.length > 1
                      ? presetRoleConstants.presetRoleDescriptions[getPresetRoleDisplayName(presetRole)]
                      : `${presetRole.name} (${
                          presetRoleConstants.presetRoleDescriptions[getPresetRoleDisplayName(presetRole)]
                        })`}
                  </Option>
                ))}
              </Select>
              {otherPresetRoles.some((presetRole) => presetRole.name === selectedPresetRole) && (
                <Icon
                  glyph="CaretLeft"
                  size={IconSize.XLarge}
                  fill={palette.green.dark1}
                  className={`${baseClassName}-other-presets-picker`}
                />
              )}
            </Box>
          </div>

          <div className={`${baseClassNameForCard}-border`} />

          {contextPresetRoles.map((presetRole: PresetRole) => {
            const role = presetRole.roles[0];
            return (
              selectedPresetRole === presetRole.name && (
                <Card
                  className={baseClassNameForCard}
                  data-testid={TestSelector.PermissionsCard}
                  key={`${presetRole.name}-card`}
                >
                  <Body className={`${baseClassName}-card-title`} data-testid={TestSelector.PermissionsCardTitle}>
                    {getCardTitleBody(presetRole)}
                  </Body>

                  <hr className={`${baseClassNameForCard}-divider`} />

                  <div className={`${baseClassNameForCard}-section`}>
                    <Label htmlFor="doc-permissions">Document Permissions</Label>
                    <div
                      className={`${baseClassName}-permissions-group`}
                      data-testid={TestSelector.DocumentPermissionsGroup}
                    >
                      <PermissionDisplay
                        name={Permission.Insert}
                        displayVal={
                          resolvePermissionValue(role.insert) === PermissionValue.Enabled
                            ? PermissionDisplayValue.IconCheck
                            : PermissionDisplayValue.IconX
                        }
                      />
                      <PermissionDisplay
                        name={Permission.Delete}
                        displayVal={
                          resolvePermissionValue(role.delete) === PermissionValue.Enabled
                            ? PermissionDisplayValue.IconCheck
                            : PermissionDisplayValue.IconX
                        }
                      />
                      <PermissionDisplay
                        name={Permission.Search}
                        displayVal={
                          resolvePermissionValue(role.search) === PermissionValue.Enabled
                            ? PermissionDisplayValue.IconCheck
                            : PermissionDisplayValue.IconX
                        }
                      />
                    </div>
                  </div>

                  <div className={`${baseClassNameForCard}-section`}>
                    <Label htmlFor="doc-filters">Document Filters</Label>
                    <div className={`${baseClassName}-doc-filters-content`}>
                      <Code
                        copyable={false}
                        className={`${baseClassName}-doc-filters-content-code`}
                        language="json"
                        data-testid={TestSelector.DocumentFiltersCode}
                      >
                        {prettyJSONStringify(role.documentFilters!)}
                      </Code>
                    </div>
                  </div>

                  <div className={`${baseClassNameForCard}-section`}>
                    <Label htmlFor="field-permissions">Field Permissions</Label>
                    <div
                      className={`${baseClassName}-permissions-group`}
                      data-testid={TestSelector.FieldPermissionsGroup}
                    >
                      <PermissionDisplay
                        name={Permission.Read}
                        displayVal={permissionValToDisplayValMap.get(resolvePermissionValue(role.read))!}
                      />
                      <PermissionDisplay
                        name={Permission.Write}
                        displayVal={permissionValToDisplayValMap.get(resolvePermissionValue(role.write))!}
                      />
                    </div>
                  </div>
                </Card>
              )
            );
          })}
        </div>

        <hr className={`${baseClassName}-card-divider`} />

        <div className={`${baseClassName}-migrated-footer`}>
          <Link
            hideExternalIcon
            onClick={onClickStartFromScratch}
            data-testid={TestSelector.StartFromScratchLink}
            data-cy="role-start-from-scratch"
          >{`Skip (start from scratch)`}</Link>
          <Button
            variant={Variant.Primary}
            className={`${baseClassName}-footer-add-button`}
            onClick={() => onClickSelectPreset(selectedPresetRole)}
            data-testid={TestSelector.AddPresetRoleButton}
            data-cy={TestSelector.AddPresetRoleButton}
          >
            Add preset role
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PresetRolesDisplayComponent;
