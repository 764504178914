import React from 'react';
import FontAwesome from 'react-fontawesome';

import { passThroughProps } from 'baas-ui/common/utils/util';

import './spinner-circle.less';

const baseClassName = 'spinner-circle';

interface Props {
  className?: string;
}

const SpinnerCircle = ({ className, ...rest }: Props) => (
  <div className={className} {...passThroughProps(rest)}>
    <FontAwesome className={baseClassName} name="circle-o-notch" spin />
  </div>
);

export default SpinnerCircle;
