import { is, Map, Record } from 'immutable';

import { existy, isImmutable } from 'baas-ui/util';

export default class MapState extends Record({
  input: new Map(),
  error: null,
  data: new Map(),
}) {
  constructor(data) {
    if (existy(data)) {
      const value = isImmutable(data) ? data : new Map(data);
      super({ data: value, input: value });
    } else {
      super({ data: new Map(), input: new Map() });
    }
  }

  discardChanges() {
    return new MapState(this.data);
  }

  getKey(key, defaultValue) {
    return this.input.get(key, defaultValue);
  }

  setKey(key, value) {
    return this.setIn(['input', key], value);
  }

  deleteKey(key) {
    return this.deleteIn(['input', key]);
  }

  keyIsDirty(key) {
    return !is(this.input.get(key), this.data.get(key));
  }

  get dirty() {
    return !is(this.input, this.data);
  }
}
