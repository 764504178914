import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AtlasGroup')
export class AtlasGroup {
  @JsonProperty('id')
  public id = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('orgId')
  public orgId = '';

  @JsonProperty('betaFeatures')
  public betaFeatures: string[] = [];

  constructor(partial?: Partial<AtlasGroup>) {
    Object.assign(this, partial);
  }
}
