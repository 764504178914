import equal from 'fast-deep-equal';
import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';

import { createFunctionActions } from 'baas-ui/functions/actions';

import * as actions from './actions';
import { makeAPIKeyReducer } from './apikeys';
import { ReadonlyAuthState, ReadonlySecurityState } from './types';
import { constructProvidersByType, defaultProviders } from './util';

export const defaultState = {
  fetchingProviderError: '',
  loadingProviders: false,
  loadingProvidersError: '',
  providersByType: defaultProviders,
  saveError: '',
  saving: false,
};

export const securityDefaultState = {
  allowedRequestOrigins: [],
  allowedRequestOriginsPristine: [],
  saveError: '',
  saving: false,
  loadError: '',
  dirty: false,
};

const authReducer = createReducer<ReadonlyAuthState>({}, defaultState);

authReducer.on(actions.clearErrors, (state) => ({
  ...state,
  saveError: '',
}));

/* FETCH */
authReducer.on(actions.getProviderActions.req, (state) => ({
  ...state,
  fetchingProviderError: '',
}));

authReducer.on(actions.getProviderActions.rcv, (state, { payload }) => {
  const providersByType = { ...state.providersByType, [payload.type]: payload };

  return {
    ...state,
    providersByType,
  };
});

authReducer.on(actions.getProviderActions.fail, (state, payload) => ({
  ...state,
  fetchingProviderError: payload.error,
}));

/* LOAD */
authReducer.on(actions.loadProvidersActions.req, (state) => ({
  ...state,
  loadingProvidersError: '',
  loadingProviders: true,
}));

authReducer.on(actions.loadProvidersActions.rcv, (state, { payload }) => ({
  ...state,
  providersByType: constructProvidersByType(payload),
  loadingProviders: false,
}));

authReducer.on(actions.loadProvidersActions.fail, (state, payload) => ({
  ...state,
  loadingProvidersError: payload.error,
  loadingProviders: false,
}));

/* ADD */
authReducer.on(actions.addProviderActions.req, (state) => ({
  ...state,
  saveError: '',
  saving: true,
}));

authReducer.on(actions.addProviderActions.rcv, (state, { payload }) => {
  const providersByType = { ...state.providersByType, [payload.type]: payload };

  return {
    ...state,
    providersByType,
    saving: false,
  };
});

authReducer.on(actions.addProviderActions.fail, (state, payload) => ({
  ...state,
  saveError: payload.error,
  saving: false,
}));

/* UPDATE */
authReducer.on(actions.updateProviderActions.req, (state) => ({
  ...state,
  saveError: '',
  saving: true,
}));

authReducer.on(actions.updateProviderActions.rcv, (state, { reqArgs }) => {
  const updatedProvider = reqArgs.providerConfig;
  const providersByType = { ...state.providersByType, [updatedProvider.type]: updatedProvider };

  return {
    ...state,
    providersByType,
    saving: false,
  };
});

authReducer.on(actions.updateProviderActions.fail, (state, payload) => ({
  ...state,
  saveError: payload.error,
  saving: false,
}));

/* ENABLE */
authReducer.on(actions.enableProviderActions.req, (state) => ({
  ...state,
  saveError: '',
  saving: true,
}));

authReducer.on(actions.enableProviderActions.rcv, (state, { reqArgs }) => {
  const enableProvider = reqArgs.providerConfig;
  const providersByType = { ...state.providersByType, [enableProvider.type]: enableProvider };

  return {
    ...state,
    providersByType,
    saving: false,
  };
});

authReducer.on(actions.enableProviderActions.fail, (state, payload) => ({
  ...state,
  saveError: payload.error,
  saving: false,
}));

/* DISABLE */
authReducer.on(actions.disableProviderActions.req, (state) => ({
  ...state,
  saveError: '',
  saving: true,
}));

authReducer.on(actions.disableProviderActions.rcv, (state, { reqArgs }) => {
  const disableProvider = reqArgs.providerConfig;
  const providersByType = { ...state.providersByType, [disableProvider.type]: disableProvider };

  return {
    ...state,
    providersByType,
    saving: false,
  };
});

authReducer.on(actions.disableProviderActions.fail, (state, payload) => ({
  ...state,
  saveError: payload.error,
  saving: false,
}));

/* CREATE FUNCTION */
authReducer.on(createFunctionActions.req, (state) => ({
  ...state,
  saveError: '',
  saving: true,
}));

authReducer.on(createFunctionActions.rcv, (state) => ({
  ...state,
  saving: false,
}));

authReducer.on(createFunctionActions.fail, (state, { error }) => ({
  ...state,
  saveError: `error creating new function - ${error}`,
  saving: false,
}));

/* SECURITY REDUCER */
const securityReducer = createReducer<ReadonlySecurityState>({}, securityDefaultState);

securityReducer.on(actions.addAllowedRequestOrigin, (state) => ({
  ...state,
  allowedRequestOrigins: [...state.allowedRequestOrigins, ''],
  dirty: true,
}));

securityReducer.on(actions.removeAllowedRequestOrigin, (state, { index }) => {
  const newLocalOrigins = state.allowedRequestOrigins.filter((_, i) => i !== index);
  const isDirty = !equal(newLocalOrigins, state.allowedRequestOriginsPristine);

  return {
    ...state,
    allowedRequestOrigins: newLocalOrigins,
    dirty: isDirty,
  };
});

securityReducer.on(actions.setAllowedRequestOriginInput, (state, { index, input }) => {
  const newLocalOrigins = [...state.allowedRequestOrigins];
  newLocalOrigins[index] = input;
  const isDirty = !equal(newLocalOrigins, state.allowedRequestOriginsPristine);

  return {
    ...state,
    allowedRequestOrigins: newLocalOrigins,
    dirty: isDirty,
  };
});

securityReducer.on(actions.cancelEditingAllowedRequestOrigins, (state) => ({
  ...state,
  allowedRequestOrigins: state.allowedRequestOriginsPristine,
  dirty: false,
}));

/* LOAD ALLOWED REQUEST ORIGINS */
securityReducer.on(actions.loadAllowedRequestOriginsActions.req, (state) => ({
  ...state,
  loadError: '',
}));

securityReducer.on(actions.loadAllowedRequestOriginsActions.rcv, (state, { payload }) => {
  // convert from String[] to string[]
  const remote = payload.map((str) => str.valueOf());

  return {
    ...state,
    allowedRequestOrigins: remote,
    allowedRequestOriginsPristine: remote,
    dirty: false,
  };
});

securityReducer.on(actions.loadAllowedRequestOriginsActions.fail, (state, payload) => ({
  ...state,
  loadError: payload.error,
}));

/* SAVE ALLOWED REQUEST ORIGINS */
securityReducer.on(actions.saveAllowedRequestOriginsActions.req, (state) => ({
  ...state,
  saveError: '',
  saving: true,
}));

securityReducer.on(actions.saveAllowedRequestOriginsActions.rcv, (state) => ({
  ...state,
  allowedRequestOriginsPristine: state.allowedRequestOrigins,
  dirty: false,
  saving: false,
}));

securityReducer.on(actions.saveAllowedRequestOriginsActions.fail, (state, payload) => ({
  ...state,
  saveError: payload.error,
  saving: false,
}));

export default combineReducers({
  apiKeys: makeAPIKeyReducer(actions.apiKeyActions),
  auth: authReducer,
  security: securityReducer,
});
