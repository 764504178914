import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@leafygreen-ui/button';
import { Body, H3 } from '@leafygreen-ui/typography';
import BrandIcon from '@mongodb-js/brand-icons';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import { getAppState } from 'baas-ui/selectors';
import { Track } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import urls, { ResourceUrlType } from 'baas-ui/urls';

export enum TestSelector {
  EmptyState = 'link-data-source-empty-state',
}

interface OwnProps {
  description: string;
  title: string;
  actionTitle: string;
}

interface StateProps {
  clustersUrlRoot: ResourceUrlType<'clusters'>;
}

type Props = OwnProps & StateProps;

const LinkDataSourceEmptyState: React.FC<Props> = ({ clustersUrlRoot, description, title, actionTitle }) => (
  <div className="empty-state" data-cy={TestSelector.EmptyState} data-testid={TestSelector.EmptyState}>
    <BrandIcon iconName={'Technical_REALM_Database'} height={72} width={72} />
    <H3>{title}</H3>
    <Body>{description}</Body>
    <Track event="CLUSTER_LINK.LINK_CLUSTER_CLICKED">
      <Link to={clustersUrlRoot.new()}>
        <Button variant="primary" data-cy="data-source-empty-state-link-button">
          {actionTitle}
        </Button>
      </Link>
    </Track>
    <DocLink href={docLinks.General.AtlasOverview}>Learn more about Atlas Clusters and your app</DocLink>
  </div>
);

const mapStateToProps = (state: RootState) => {
  const {
    app: { id: appId, groupId },
  } = getAppState(state);

  const clustersUrlRoot = urls.groups().group(groupId).apps().app(appId).clusters();

  return { clustersUrlRoot };
};

export { LinkDataSourceEmptyState as LinkDataSourceEmptyStateComponent };
export default connect(mapStateToProps)(LinkDataSourceEmptyState);
