import { isRawErrorResponse, ResponseError, ServerError, toErrorResponse } from './Error';

export const JSONTYPE = 'application/json';
export const DEFAULT_BAAS_SERVER_URL = 'https://services.cloud.mongodb.com';

export const checkStatus = (response: Response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.headers.get('Content-Type') === JSONTYPE) {
    return response.json().then((json) => {
      if (!isRawErrorResponse(json)) {
        return Promise.reject(json);
      }
      const serverErr = new ServerError(json.error, json.error_code);
      serverErr.response = response;
      serverErr.json = toErrorResponse(json);
      return Promise.reject(serverErr);
    });
  }

  const error = new ResponseError(response.statusText);
  error.response = response;
  return Promise.reject(error);
};

export interface FetchOptions {
  apiVersion?: number;
  apiType?: string;
  credentials?: RequestCredentials;
  headers?: Record<string, string>;
  noAuth?: boolean;
  refreshOnFailure?: boolean;
  useRefreshToken?: boolean;
  multipart?: boolean;
  body?: string | FormData;
  rootURL?: string;
  queryParams?: { [key: string]: any };
  skipDraft?: boolean;

  // Used to determine if a request requires legacy auth credentials IN ADDITION TO Cloud AuthN credentials.
  // Currently, this is only needed for endpoints that require a legacy access token for Programmatic User Access,
  // or endpoints that require a legacy refresh token.
  requiresLegacyAuth?: boolean;
}

export const makeFetchArgs = (method: string, options?: FetchOptions): RequestInit => {
  const headers = options?.headers ?? {};
  if (!headers.Accept) {
    headers.Accept = JSONTYPE;
  }
  if (!options?.multipart && !headers['Content-Type']) {
    headers['Content-Type'] = JSONTYPE;
  }
  return {
    body: options?.body,
    credentials: options?.credentials,
    headers,
    method,
    mode: 'cors',
  };
};
