import { OptionProps } from 'react-select/src/types';

import { Namespaces } from 'baas-ui/services/mongodb/types';
import {
  AuthActionType,
  AuthEventSubscription,
  AuthEventSubscriptionConfig,
  AuthProviderType,
  DatabaseEventSubscription,
  DatabaseEventSubscriptionConfig,
  EventProcessorType,
  OperationType,
  ResourceType,
  ScheduledEventSubscription,
  ScheduledEventSubscriptionConfig,
} from 'admin-sdk';

import { NamespaceLevel } from './edit-database-event/utils';

export interface TriggerResumeOptions {
  disableToken: boolean;
}

export const { SyncTranslator, SyncPublisher, ...TriggerType } = ResourceType;

export type TriggerTypes = Exclude<ResourceType, ResourceType.SyncTranslator | ResourceType.SyncPublisher>;

export interface TriggerTypeFilter {
  type: TriggerTypes;
}

const TriggerTypeReverseMap: { [key: string]: TriggerTypes } = Object.entries(TriggerType).reduce(
  (acc, [triggerType, str]) => ({
    ...acc,
    [str]: TriggerType[triggerType],
  }),
  {}
);

export const toTriggerType = (str: string): TriggerTypes | undefined => TriggerTypeReverseMap[str];

export interface TriggerTypeOptionProps extends Partial<OptionProps> {
  value: TriggerTypes;
}

export type TriggerTypeSelectOptions = {
  [key in TriggerTypes]: TriggerTypeOptionProps;
};

type OmitFunction<K> = Omit<Omit<K, 'functionId'>, 'functionName'>;

export interface AuthenticationTrigger extends OmitFunction<AuthEventSubscription> {
  config: AuthEventSubscriptionConfig;
  completedAt?: number;
}

export interface DatabaseTrigger extends OmitFunction<DatabaseEventSubscription> {
  config: DatabaseEventSubscriptionConfig;
  completedAt?: number;
  clusterTime?: Date;
}

export enum ScheduleType {
  Advanced = 'ADVANCED',
  Basic = 'BASIC',
}

export interface ScheduledTrigger extends OmitFunction<ScheduledEventSubscription> {
  config: ScheduledEventSubscriptionConfig;
  completedAt?: number;
}

export type Trigger = AuthenticationTrigger | DatabaseTrigger | ScheduledTrigger;

export interface TriggerSaveOptions {
  isNewCollection?: boolean;
  isNewDatabase?: boolean;
}

interface ServiceSupportedRegion {
  label: string;
  value: string;
}

interface State {
  loadEventsError?: string;
  createEventError?: string;
  fetchEventError?: string;
  updateEventError?: string;
  removeEventError?: string;
  fetchEventExecutionError?: string;
  loadRegionsError?: string;
  atlasClustersError?: string;

  loadingEvents: boolean;
  fetchingEvent: boolean;
  loadingServiceSupportedRegions: boolean;

  namespaces: Namespaces;

  triggers: { [key: string]: Trigger };

  serviceSupportedRegions?: ServiceSupportedRegion[];
}

export type TriggersState = Readonly<State>;

export interface ErrorHandlerInfo {
  functionId?: string;
  functionName?: string;
  functionSource?: string;
}

export interface AuthenticationEventFormInput {
  name: string;
  enabled: boolean;
  actionType: AuthActionType;
  providers: AuthProviderType[];
  eventConfig: {
    eventProcessorType: EventProcessorType;
    eventBridge?: {
      accountId: string;
      region: string;
      useExtendedJSON: boolean;
    };
    function?: {
      id?: string;
      name?: string;
      source?: string;
    };
  };
}

export interface ScheduledEventFormInput {
  name: string;
  enabled: boolean;
  skipCatchUpEvents: boolean;
  scheduleConfig: {
    schedule: string;
    scheduleType: ScheduleType;
  };
  eventConfig: {
    eventProcessorType: EventProcessorType;
    eventBridge?: {
      accountId: string;
      region: string;
      useExtendedJSON: boolean;
    };
    function?: {
      id?: string;
      name?: string;
      source?: string;
    };
  };
}

export interface DatabaseEventFormInput {
  name: string;
  enabled: boolean;

  autoResume: boolean;
  skipCatchUpEvents: boolean;
  unordered: boolean;

  namespaceWatchLevel: NamespaceLevel;
  watchConfig: {
    dataSourceId: string;
    database: string;
    collection: string;
  };
  operationTypes: Array<OperationType>;
  fullDocument: boolean;
  fullDocumentBeforeChange: boolean;

  eventConfig: {
    eventProcessorType: EventProcessorType;
    eventBridge?: {
      accountId: string;
      region: string;
      useExtendedJSON: boolean;
      errorFunctionId?: string;
      errorFunctionName?: string;
      errorFunctionSource?: string;
    };
    function?: {
      id?: string;
      name?: string;
      source?: string;
    };
  };

  projectExpression: string;
  matchExpression: string;
  maximumThroughput: boolean;
}
