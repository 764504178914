import { ClusterType, MDBService } from 'baas-ui/services/mongodb/types';
import { PartitionKeyType, SyncConfig, SyncState } from 'admin-sdk';

export interface MongoSyncServicesById {
  [svcId: string]: MongoSyncService;
}

export interface MongoSyncService extends MDBService {
  config?: {
    clusterName?: string;
    clusterId?: string;
    clusterType?: ClusterType;
    sync?: PartitionSyncConfig;
  };
  syncQuery?: {
    clusterName?: string;
    clusterId?: string;
    sync?: QuerySyncConfig;
  };
}

export enum SyncType {
  Partition = 'partition',
  Flexible = 'flexible',
}

export interface ServiceSyncConfig {
  state: SyncState;
  databaseName: string;
  /** Timestamp sync was disabled - in seconds since epoch */
  lastDisabled?: number;
  maxOfflineTime?: number; // days
  clientRecoveryDisabled?: boolean;
  type?: SyncType;
}

export interface SyncPermissions {
  read: string;
  write: string;
}

export interface PartitionSyncConfig extends ServiceSyncConfig {
  partitionKey: string;
  partitionKeyType?: PartitionKeyType;
  partitionKeyRequired?: boolean;
  permissions: SyncPermissions;
}

export interface QuerySyncConfig extends ServiceSyncConfig {
  globalQueryableFieldsNames?: string[];
  collectionQueryableFieldsNames?: Record<string, string[]>;
  indexedQueryableFieldsNames?: string[];
  asymmetricTables?: string[];
  permissions: string;
}

export interface QueryableFields {
  globalQueryableFields: string[];
  collectionQueryableFields: Record<string, string[]>;
  indexedQueryableFields: string[];
}

export interface DatabaseSelectOption {
  value: string;
  label: string;
  isNew: boolean;
}

export interface ClusterSelectOption {
  value: string;
  label: string;
  disabled: boolean;
}

// TODO: replace with PartitionField from SDK which
// has a required type field which conflicts with
// the usage of CreatablePartition as a view model.
export interface Partition {
  key: string;
  type?: PartitionKeyType;
  required?: boolean;
}

export interface CreatablePartition extends Partition {
  isNew?: boolean;
}

export interface AppSyncData {
  serviceId: string;
  partitionFields: Partition[];
  queryableFieldsNames: string[];
}

export interface ConfigState extends SyncConfig {
  isLoading: boolean;
  isSaving: boolean;
  loadError?: string;
}

export interface DataState extends AppSyncData {
  loadError?: string;
}

export interface PermissionsErrorState {
  read: string;
  write: string;
}

export enum SyncEnabledToastState {
  None = 'none',
  EnablingSucceeded = 'enabling-succeeded',
}

export interface ClientSchemaAlert {
  errorCode: string;
  error: string;
}

export interface ClientSchema {
  serviceId: string;
  ruleId: string;
  schemaId: string;
  modelName: string;
  collectionDisplayName: string;
  importStatements: string[];
  schema: string;
  warnings: ClientSchemaAlert[];
  error?: ClientSchemaAlert;
}

export interface SyncPageConfigDirtyState {
  isDevDetailsDirty: boolean;
  isDevModeDirty: boolean;
  isPartitionKeyDirty: boolean;
  isQueryableFieldsDirty: boolean;
  isAsymmetricTablesDirty: boolean;
  isPermissionsDirty: boolean;
  isClientRecoveryDirty: boolean;
  isMaxOfflineTimeDirty: boolean;
}

export interface SyncPageHeaderOverflowControl {
  collapseHeader: boolean;
  headerOverflowThreshold?: number;
}
