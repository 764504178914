import { Namespaces } from 'baas-ui/services/mongodb/types';
import {
  MongoDBBaseRule,
  MongoDBNamespaceRule,
  MongoDBSyncIncompatibleRoles,
  PartialMongoDBNamespaceRule,
} from 'admin-sdk';

export type NamespacesByClusterId = {
  [dataSourceId: string]: Namespaces;
};

export type PartialRulesByDataSourceId = {
  [dataSourceId: string]: PartialMongoDBNamespaceRule[];
};

export type DefaultRulesByDataSourceId = {
  [dataSourceId: string]: MongoDBBaseRule | undefined;
};

export type SyncIncompatibleRolesByDataSourceId = {
  [datasourceId: string]: MongoDBSyncIncompatibleRoles;
};

export type DocumentFilterExpression = Record<string, any> | boolean | undefined;

export type CardActionFunction = (name: string, idx: number) => void;

export enum Permission {
  Insert = 'Insert',
  Search = 'Search',
  Delete = 'Delete',
  Read = 'Read',
  Write = 'Write',
}

export interface RulesSyncParams {
  showSyncBanner?: string;
}

export type DataSourceRule = MongoDBNamespaceRule | MongoDBBaseRule | undefined;
