import React from 'react';
import Button, { Size, Variant } from '@leafygreen-ui/button';
import { Body, H3 } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';

import EmptyFiltersLogo from './EmptyFiltersLogo';

import './filters-empty-state.less';

const baseClassName = 'filters-empty-state';

export enum TestSelector {
  EmptyStateContainer = 'filters-empty-state-container',
  FiltersDocLink = 'filters-doc-link',
  Image = 'image',
  HeaderText = 'header-text',
  AddFilterButton = 'add-filter-button',
}

export interface Props {
  onClickAdd(): void;
}

const FiltersEmptyState = ({ onClickAdd }: Props) => (
  <div
    data-testid={TestSelector.EmptyStateContainer}
    data-test-selector={TestSelector.EmptyStateContainer}
    data-cy={TestSelector.EmptyStateContainer}
    className={baseClassName}
  >
    <EmptyFiltersLogo data-test-selector={TestSelector.Image} />
    <H3 className={`${baseClassName}-title`} data-test-selector={TestSelector.HeaderText}>
      Improve query performance with Filters
    </H3>
    <Body className={`${baseClassName}-description`}>
      <DocLink data-test-selector={TestSelector.FiltersDocLink} href={docLinks.Rules.Filters} showExternalIcon>
        <span style={{ fontSize: '15px' }}>Add query filters </span>
      </DocLink>{' '}
      to enhance query performance. Query filters will bulk-filter documents returned from MongoDB before each document
      is individually processed through rules.
    </Body>
    <Button
      className={`${baseClassName}-button`}
      variant={Variant.Primary}
      onClick={onClickAdd}
      size={Size.Large}
      data-test-selector={TestSelector.AddFilterButton}
      data-testid={TestSelector.AddFilterButton}
      data-cy={TestSelector.AddFilterButton}
    >
      Add a filter
    </Button>
  </div>
);

export default FiltersEmptyState;
