import { createReducer } from 'redux-act';

import * as actions from './actions';
import { Trigger, TriggersState } from './types';
import { isNonSyncTriggerType, setEventTimes } from './util';

export const defaultState = {
  loadEventsError: undefined,
  fetchEventError: undefined,
  createEventError: undefined,
  updateEventError: undefined,
  removeEventError: undefined,
  loadRegionsError: undefined,
  fetchingEvent: false,
  loadingEvents: false,
  namespaces: [],
  triggers: {},
  serviceSupportedRegions: [],
  loadingServiceSupportedRegions: false,
  atlasClustersError: undefined,
};

const eventReducer = createReducer<TriggersState>({}, defaultState);

eventReducer.on(actions.loadEventsActions.req, (state) => ({
  ...state,
  loadingEvents: true,
  loadEventsError: undefined,
}));

eventReducer.on(actions.loadEventsActions.rcv, (state, { payload }) => ({
  ...state,
  loadingEvents: false,
  triggers: payload.reduce((acc, trigger) => {
    if (!isNonSyncTriggerType(trigger.type)) {
      return acc;
    }
    return {
      ...acc,
      [trigger.id || '']: trigger as Trigger,
    };
  }, state.triggers),
}));

eventReducer.on(actions.loadEventsActions.fail, (state, { error }) => ({
  ...state,
  loadingEvents: false,
  loadEventsError: error,
}));

eventReducer.on(actions.fetchEventActions.req, (state) => ({
  ...state,
  fetchingEvent: true,
  fetchEventError: undefined,
}));

eventReducer.on(actions.fetchEventActions.rcv, (state, { payload, reqArgs: { triggerId } }) => ({
  ...state,
  fetchingEvent: false,
  triggers: !isNonSyncTriggerType(payload.type)
    ? state.triggers
    : {
        ...state.triggers,
        [triggerId]: payload as Trigger,
      },
}));

eventReducer.on(actions.fetchEventActions.fail, (state, { error }) => ({
  ...state,
  fetchingEvent: false,
  fetchEventError: error,
}));

eventReducer.on(actions.createEventActions.req, (state) => ({
  ...state,
  createEventError: undefined,
}));

eventReducer.on(actions.createEventActions.rcv, (state, { payload }) => ({
  ...state,
  triggers: {
    ...state.triggers,
    [payload.id || '']: payload as Trigger,
  },
}));

eventReducer.on(actions.createEventActions.fail, (state, { error }) => ({
  ...state,
  createEventError: error,
}));

eventReducer.on(actions.updateEventActions.req, (state) => ({
  ...state,
  updateEventError: undefined,
}));

eventReducer.on(actions.updateEventActions.rcv, (state, { reqArgs: { triggerId, trigger } }) => ({
  ...state,
  triggers: {
    ...state.triggers,
    [triggerId]: { id: triggerId, ...trigger },
  },
}));

eventReducer.on(actions.updateEventActions.fail, (state, { error }) => ({
  ...state,
  updateEventError: error,
}));

eventReducer.on(actions.clearErrors, (state) => ({
  ...state,
  loadEventsError: undefined,
  fetchEventError: undefined,
  createEventError: undefined,
  updateEventError: undefined,
  removeEventError: undefined,
  loadRegionsError: undefined,
  fetchEventExecutionError: undefined,
}));

eventReducer.on(actions.setAtlasClusterErrors, (state, payload) => ({
  ...state,
  atlasClustersError: payload,
}));

eventReducer.on(actions.removeEventActions.req, (state) => ({ ...state, removeEventError: undefined }));

eventReducer.on(actions.removeEventActions.rcv, (state, { reqArgs }) => {
  const triggers = { ...state.triggers };

  delete triggers[reqArgs.triggerId];

  return { ...state, triggers };
});

eventReducer.on(actions.removeEventActions.fail, (state, { error }) => ({ ...state, removeEventError: error }));

eventReducer.on(actions.resumeEventActions.req, (state) => ({ ...state, updateEventError: undefined }));

eventReducer.on(actions.resumeEventActions.fail, (state, { error }) => ({ ...state, updateEventError: error }));

eventReducer.on(actions.loadServiceSupportedRegionsActions.req, (state) => ({
  ...state,
  loadRegionsError: undefined,
  loadingServiceSupportedRegions: true,
}));

eventReducer.on(actions.loadServiceSupportedRegionsActions.rcv, (state, { payload }) => ({
  ...state,
  serviceSupportedRegions: payload,
  loadingServiceSupportedRegions: false,
}));

eventReducer.on(actions.loadServiceSupportedRegionsActions.fail, (state, { error }) => ({
  ...state,
  loadRegionsError: error,
  loadingServiceSupportedRegions: false,
}));

eventReducer.on(actions.updateExecutionsAction, (state, payload) => {
  const newTriggers = { ...state.triggers };
  payload.forEach((eventSubExecution) => {
    if (newTriggers[eventSubExecution.resourceId]) {
      newTriggers[eventSubExecution.resourceId] = setEventTimes(
        newTriggers[eventSubExecution.resourceId],
        eventSubExecution
      );
    }
  });

  return {
    ...state,
    triggers: newTriggers,
  };
});

export default eventReducer;
