import React from 'react';
import { withRouter } from 'react-router-dom';
import Button, { Size as ButtonSize } from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import { Menu, MenuItem } from '@leafygreen-ui/menu';
import { palette } from '@leafygreen-ui/palette';

import urls from 'baas-ui/urls';
import { stopPropagation } from 'baas-ui/util';
import { PartialApp } from 'admin-sdk';

export interface Props {
  app: PartialApp;
  deleteApp(name: string, id: string): void;
  history: any;
}

const HomeAppDisplay = ({ app, deleteApp, history }: Props) => (
  <div
    className="home-projects-listing-project"
    onClick={() => history.push(urls.groups().group(app.groupId).apps().app(app.id).get())}
  >
    <div className="home-projects-listing-project-header">
      <div className="home-projects-listing-project-header-title">
        {app.name} ({app.clientAppId})
      </div>
      <Menu
        trigger={
          <Button size={ButtonSize.XSmall} onClick={(e) => e.stopPropagation()} data-cy="open-service-actions">
            <Icon glyph="Ellipsis" />
          </Button>
        }
      >
        <MenuItem
          onClick={stopPropagation(() => {
            deleteApp(app.name, app.id);
          })}
          data-cy="delete-service"
          style={{ color: palette.red.base }}
        >
          Delete App
        </MenuItem>
      </Menu>
    </div>
  </div>
);

// @ts-expect-error error: "Unused '@ts-expect-error' directive.(2578)"
export default withRouter(HomeAppDisplay);
