import React from 'react';
import Toast, { Variant } from '@leafygreen-ui/toast';
import { Link } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import { ClusterProvisionToastState } from 'baas-ui/home/types';

import './cluster-provision-toast.less';

type ToastProps = JSX.LibraryManagedAttributes<typeof Toast, React.ComponentProps<typeof Toast>>;

type ClusterProvisionToastProps = ToastProps & { 'data-test-selector'?: string };

export interface Props {
  open: boolean;
  onClose(): void;
  state: ClusterProvisionToastState;
  error?: string;
  atlasClustersUrl: string;
  guidePopoverOpen: boolean;
  guidePopoverExpanded: boolean;
  onViewGuidesLinkClick(): void;
}

export const TestSelectors = {
  EmptyToast: 'empty-toast',
  CreatingClusterToast: 'creating-cluster-toast',
  CreatingSucceededToast: 'creating-succeeded-toast',
  ErrorToast: 'error-toast',
  GuidesLink: 'guides-link',
};

export const baseClassName = 'cluster-provision-toast';

export default function ClusterProvisionToast({
  open,
  onClose,
  state,
  error,
  atlasClustersUrl,
  guidePopoverOpen,
  guidePopoverExpanded,
  onViewGuidesLinkClick,
}: Props) {
  const emptyToastProps: ClusterProvisionToastProps = {
    variant: Variant.Success,
    title: '',
    body: '',
    'data-test-selector': TestSelectors.EmptyToast,
  };

  const creatingClusterToastProps: ClusterProvisionToastProps = {
    variant: Variant.Progress,
    title: 'Cluster is provisioning.',
    body: (
      <div className={`${baseClassName}-body`}>
        <div className={`${baseClassName}-body-text`}>In the meantime, check out our Guides.</div>
        <Link
          className={`${baseClassName}-body-link`}
          arrowAppearance="none"
          hideExternalIcon
          onClick={onViewGuidesLinkClick}
          data-test-selector={TestSelectors.GuidesLink}
        >
          <b>VIEW GUIDES</b>
        </Link>
      </div>
    ),
    progress: 0.5,
    'data-test-selector': TestSelectors.CreatingClusterToast,
  };

  const creatingClusterSucceededToastProps: ClusterProvisionToastProps = {
    variant: Variant.Success,
    title: 'Success!',
    body: 'Your cluster was created successfully!',
    close: onClose,
    'data-test-selector': TestSelectors.CreatingSucceededToast,
  };

  const errorToastProps: ClusterProvisionToastProps = {
    variant: Variant.Warning,
    title: 'Failed to provision cluster.',
    body: (
      <div className={`${baseClassName}-body`}>
        <div className={`${baseClassName}-body-text`}>{error}</div>
        <Link
          className={`${baseClassName}-body-link`}
          arrowAppearance="none"
          hideExternalIcon
          href={atlasClustersUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>See Atlas Clusters</b>
        </Link>
      </div>
    ),
    close: onClose,
    'data-test-selector': TestSelectors.ErrorToast,
  };

  let toastProps: ClusterProvisionToastProps;
  switch (state) {
    case ClusterProvisionToastState.CreatingCluster:
      toastProps = creatingClusterToastProps;
      break;
    case ClusterProvisionToastState.CreatingSucceeded:
      toastProps = creatingClusterSucceededToastProps;
      break;
    case ClusterProvisionToastState.Error:
      toastProps = errorToastProps;
      break;
    default:
      // This toast should never be visible
      toastProps = emptyToastProps;
  }

  return (
    <Toast
      {...toastProps}
      className={classNames(baseClassName, {
        [`${baseClassName}-guide-popover-collapsed`]: guidePopoverOpen && !guidePopoverExpanded,
        [`${baseClassName}-guide-popover-expanded`]: guidePopoverOpen && guidePopoverExpanded,
      })}
      open={state !== ClusterProvisionToastState.None && open}
    />
  );
}
