import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { passThroughProps } from 'baas-ui/common/utils/util';

import './form-row.less';

const baseClassName = 'form-row';

export enum TestSelector {
  FormRowContainer = 'form-row-container',
  ErrorMessage = 'form-row-error-message',
}

export interface Props {
  children: React.ReactNode;
  className?: string;
  noBorder?: boolean;
  last?: boolean;
  small?: boolean;
  nested?: boolean;
  wide?: boolean;
  noHeader?: boolean;
  noFooter?: boolean;
  noWrap?: boolean;
  solo?: boolean;
  compact?: boolean;
  errorMessage?: string;
}

const FormRow = ({
  children,
  className,
  noBorder,
  last,
  small,
  nested,
  wide,
  noHeader,
  noFooter,
  noWrap,
  solo,
  compact,
  errorMessage,
  ...rest
}: Props) => {
  return (
    <div
      data-testid={TestSelector.FormRowContainer}
      className={classnames(
        baseClassName,
        {
          [`${baseClassName}-is-last`]: last || noBorder,
          [`${baseClassName}-is-nested`]: nested,
          [`${baseClassName}-is-small`]: small,
          [`${baseClassName}-is-solo`]: solo,
          [`${baseClassName}-is-wide`]: wide,
          [`${baseClassName}-is-compact`]: compact,
          [`${baseClassName}-no-footer`]: noFooter,
          [`${baseClassName}-no-header`]: noHeader,
          [`${baseClassName}-no-wrap`]: noWrap,
        },
        className
      )}
      {...passThroughProps(rest)}
    >
      {children}
      {errorMessage && (
        <div className={`${baseClassName}-error-message`} data-testid={TestSelector.ErrorMessage}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

FormRow.defaultProps = {
  children: null,
  className: undefined,
  last: false,
  nested: false,
  noBorder: false,
  noFooter: false,
  noHeader: false,
  noWrap: false,
  small: false,
  solo: false,
  wide: false,
  compact: false,
};

// Leave these until all components using this component are converted to TS
FormRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  last: PropTypes.bool,
  nested: PropTypes.bool,
  noBorder: PropTypes.bool,
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool,
  noWrap: PropTypes.bool,
  small: PropTypes.bool,
  solo: PropTypes.bool,
  wide: PropTypes.bool,
  compact: PropTypes.bool,
};

export default FormRow;
