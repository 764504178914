import React from 'react';
import { useSelector } from 'react-redux';
import { createAgent, throughArea } from 'react-through';
import PropTypes from 'prop-types';

import { RootState } from 'baas-ui/types';

const TitleAgent = createAgent('stitch-title', 'children');

export interface TitleProps {
  children: React.ReactNode;
}

export const Title = ({ children }: TitleProps, { through }: { through: any }) => {
  if (!through) {
    return null;
  }

  return <TitleAgent>{children}</TitleAgent>;
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

Title.contextTypes = {
  through: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

const TitleThroughArea = throughArea('stitch-title')((props: Record<string, TitleProps>) => {
  const app = useSelector((state: RootState) => state.app.app);

  const baseTitle = 'App Services';
  const childText = [...Object.values(props).map(({ children }) => children)].join(' - ');
  document.title = childText.length > 0 ? `${app?.name ? `${app.name} | ` : ''}${childText} | ${baseTitle}` : baseTitle;
  return null;
});

export const TitleArea = (_: {}, { through }: { through: any }) => {
  if (!through) {
    return null;
  }

  return <TitleThroughArea />;
};

TitleArea.contextTypes = {
  through: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};
