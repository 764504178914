import React from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm';
import Button from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import Modal from '@leafygreen-ui/modal';
import { palette } from '@leafygreen-ui/palette';
import { Body, H3 } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';

import './sync-incompatibility-modal.less';

const baseClassName = 'sync-incompatibility-modal';

export enum TestSelector {
  ModalSelector = 'sync-incompatibility-modal',
  ErrorList = 'error-list',
}

interface PublicProps {
  errorDetails: Record<string, string[]>;
}

export type Props = PublicProps & ReactConfirmProps;

export const SyncIncompatibilityModal = ({ errorDetails, show, proceed, cancel }: Props) => {
  return (
    <Modal
      open={show}
      setOpen={() => cancel()} // Gets called when user clicks the 'x'
      data-testid={TestSelector.ModalSelector}
      data-cy={TestSelector.ModalSelector}
      className={baseClassName}
    >
      <div className={`${baseClassName}-container`}>
        <div className={`${baseClassName}-icon`}>
          <Icon glyph="ImportantWithCircle" size="large" fill={palette.yellow.dark2} />
        </div>
        <div>
          <H3>Role incompatible with Sync</H3>
          <Body data-cy="sync-incompatibility-modal-changes" className={`${baseClassName}-body`}>
            The following role settings are configured in a way incompatible with Sync:
          </Body>
          <Body as={'div'} className={`${baseClassName}-error-list`} data-testid={TestSelector.ErrorList}>
            <ul>
              {errorDetails &&
                Object.entries(errorDetails).map(([role, errs]) => {
                  return (
                    <li key={role}>
                      {role}:
                      <ul className={`${baseClassName}-error-list-indented-list`}>
                        {errs.map((e) => {
                          return <li key={e}>{e}</li>;
                        })}
                      </ul>
                    </li>
                  );
                })}
            </ul>
          </Body>
          <Body className={`${baseClassName}-body`} weight="medium">
            Collections with{' '}
            <DocLink href={docLinks.Rules.SyncCompatibility} showExternalIcon>
              sync-incompatible roles
            </DocLink>
            can result in clients being unable to read/write data to Sync if the incompatible role is assigned to the
            given collection.
          </Body>
        </div>
      </div>
      <div className={`${baseClassName}-footer`}>
        <Button className={`${baseClassName}-footer-cancel`} onClick={() => cancel()}>
          Cancel
        </Button>
        <Button onClick={() => proceed()}>Save anyway</Button>
      </div>
    </Modal>
  );
};

export const confirm = createConfirmation(confirmable(SyncIncompatibilityModal));
