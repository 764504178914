import React from 'react';

import { IconProps } from './types';

export enum TestSelector {
  Icon = 'icon',
}

const FlutterIcon = ({ className, height = 25, width = 25 }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={TestSelector.Icon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3127 22.1881L8.56116 16.4623L11.7399 13.2213L20.7058 22.1881H14.3127Z"
        fill="#02539A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49023 16.4667L14.1907 10.7543H20.5993L11.7312 19.641L8.49023 16.4667Z"
        fill="#45D1FD"
        fillOpacity="0.85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.55554 11.484L6.79204 14.725L20.5993 0.888885H14.1574L3.55554 11.484Z"
        fill="#45D1FD"
      />
    </svg>
  );
};

export default FlutterIcon;
