import React from 'react';
import Icon from '@leafygreen-ui/icon';
import { Option, Select } from '@leafygreen-ui/select';
import TextInput from '@leafygreen-ui/text-input';

import { SelectOption } from 'baas-ui/deploy/types';

import './github-selector.less';

const githubSelector = 'github-selector';

export enum TestSelector {
  DiscardChanges = 'discard-changes',
  RepoSelect = 'repo-select',
  RepoSelectOption = 'repo-select-option',
  BranchSelect = 'branch-select',
  BranchSelectOption = 'branch-select-option',
  DirectoryInput = 'directory-input',
}

export type Props = {
  isGithubLinked: boolean;
  isDevModeEnabled?: boolean;
  onRepositorySelect(so: SelectOption): void;
  onBranchSelect(so: SelectOption): void;
  onDirectoryChange(string): void;
  repository: SelectOption | null;
  branch: SelectOption | null;
  directory: string;
  branchOptions: SelectOption[];
  repositoryOptions: SelectOption[];
  isLoadingInstallations: boolean;
  isLoadingBranches: boolean;
};

function GithubSelector({
  isGithubLinked,
  isDevModeEnabled = false,
  repository,
  branch,
  directory,
  onRepositorySelect,
  onBranchSelect,
  onDirectoryChange,
  branchOptions,
  repositoryOptions,
  isLoadingInstallations,
  isLoadingBranches,
}: Props) {
  return (
    <div className={`${githubSelector}`}>
      <Select
        label="Repository"
        data-testid={TestSelector.RepoSelect}
        className={`${githubSelector}-select`}
        value={repository?.value || ''}
        placeholder={'Select repository...'}
        onChange={(selectedRepo) => {
          const repoFromOptions = repositoryOptions.find(({ value }) => value === selectedRepo);
          if (repoFromOptions) {
            onRepositorySelect(repoFromOptions);
          }
        }}
        // TODO(BAAS-9399): Add loading spinner for when isLoadingInstallations is true
        disabled={!isGithubLinked || isLoadingInstallations || isDevModeEnabled}
      >
        {repositoryOptions.map((opt) => (
          <Option data-testid={`${TestSelector.RepoSelectOption}-${opt.value}`} key={opt.label} value={opt.value}>
            {opt.label}
          </Option>
        ))}
      </Select>
      <Icon className={`${githubSelector}-icon`} glyph="ArrowRight" />
      <Select
        label="Branch"
        data-testid={TestSelector.BranchSelect}
        className={`${githubSelector}-select`}
        value={branch?.value || ''}
        placeholder={'Select branch...'}
        onChange={(selectedBranch) => {
          const branchFromOptions = branchOptions.find(({ value }) => value === selectedBranch);
          if (branchFromOptions) {
            onBranchSelect(branchFromOptions);
          }
        }}
        // TODO(BAAS-9399): Add loading spinner for when isLoadingBranches is true
        disabled={!isGithubLinked || !repository || isLoadingBranches || isDevModeEnabled}
      >
        {branchOptions.map((opt) => (
          <Option data-testid={`${TestSelector.BranchSelectOption}-${opt.value}`} key={opt.label} value={opt.value}>
            {opt.label}
          </Option>
        ))}
      </Select>
      <Icon className={`${githubSelector}-icon`} glyph="ArrowRight" />
      <TextInput
        data-testid={TestSelector.DirectoryInput}
        label="Directory"
        value={directory}
        className={`${githubSelector}-select`}
        onChange={(e) => onDirectoryChange(e.target.value)}
        disabled={!isGithubLinked || isDevModeEnabled}
      />
    </div>
  );
}

export default GithubSelector;
