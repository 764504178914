import React, { useRef, useState } from 'react';

const pollersStarted = new Set<string>();

// usePoller allows you to call a function periodically at the timeout interval specified.
// If you're having issues with the poller starting multiple times, or multiple instances of your poller going off,
// pass in an id when you initialize the poller. This ensures that only one poller of that id will run at a time.
export default function usePoller(id?: string) {
  const interval = useRef<number>();
  const [isPolling, setIsPolling] = useState<boolean>(false);
  React.useEffect(() => {
    return () => clearInterval(interval.current);
  }, [interval]);

  return {
    isPolling,
    start: (action: Function, timeout: number) => {
      if (id && !pollersStarted.has(id)) {
        pollersStarted.add(id);
        action();
        interval.current = setInterval(action, timeout);
        setIsPolling(true);
      } else if (!id) {
        action();
        interval.current = setInterval(action, timeout);
        setIsPolling(true);
      }
    },
    stop: () => {
      if (id) {
        pollersStarted.delete(id);
      }
      clearInterval(interval.current);
      setIsPolling(false);
    },
  };
}
