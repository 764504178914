import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ValuesByEnvironment')
export class ValuesByEnvironment {
  @JsonProperty('none', Any, true)
  public none: any = undefined;

  @JsonProperty('development', Any, true)
  public development: any = undefined;

  @JsonProperty('testing', Any, true)
  public testing: any = undefined;

  @JsonProperty('staging', Any, true)
  public staging: any = undefined;

  @JsonProperty('qa', Any, true)
  public qa: any = undefined;

  @JsonProperty('production', Any, true)
  public production: any = undefined;

  constructor(partial?: Partial<ValuesByEnvironment>) {
    Object.assign(this, partial);
  }
}

@JsonObject('EnvironmentValue')
export class EnvironmentValue {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('values', ValuesByEnvironment, {})
  public values: ValuesByEnvironment = new ValuesByEnvironment();

  @JsonProperty('last_modified', Number, true)
  public lastModified?: number = undefined;

  constructor(partial?: Partial<EnvironmentValue>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PartialEnvironmentValue')
export class PartialEnvironmentValue {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('last_modified', Number, true)
  public lastModified?: number = undefined;

  constructor(partial?: Partial<PartialEnvironmentValue>) {
    Object.assign(this, partial);
  }
}
