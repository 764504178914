import { OptionTypeBase, StylesConfig } from 'react-select';
import { palette } from '@leafygreen-ui/palette';

// using the StylesConfig types everything in the object so don't need to explicitly do so
export const selectStyles: StylesConfig<OptionTypeBase, false> = {
  control: (base, state) => ({
    ...base,
    height: '38px',
    minHeight: '38px',
    borderRadius: '3px',
    borderWidth: '1px',
    borderColor: state.isFocused ? palette.blue.light1 : palette.gray.light1,
    boxShadow: state.isFocused ? '0 2px 6px rgba(78, 175, 233, 0.3)' : 'none',
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '3px',
    zIndex: '10',
  }),
  option: (base, state) => ({
    ...base,
    padding: '8px 6px',
    width: 'auto',
    margin: '0 4px',
    borderRadius: '3px',
    color: state.isDisabled ? palette.gray.base : palette.gray.dark3,
    background: state.isFocused && !state.isDisabled ? palette.gray.light2 : 'none',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    '&:active': {
      background: palette.gray.light1,
    },
    '&::before': state.isSelected
      ? {
          fontFamily: 'MMS Icons',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '21px',
          lineHeight: '18px',
          zoom: '1',
          content: `'\\E213'`,
          color: palette.green.dark1,
          position: 'relative',
          left: '-2px',
        }
      : {},
  }),
  multiValueLabel: (base) => ({
    ...base,
    background: palette.gray.light2,
    borderBottomRightRadius: '0',
    borderTopRightRadius: '0',
    paddingRight: '8px',
  }),
  multiValueRemove: (base) => ({
    ...base,
    background: palette.gray.light2,
    color: palette.gray.base,
    borderLeft: `1px solid ${palette.gray.light1}`,
    borderBottomLeftRadius: '0',
    borderTopLeftRadius: '0',
    '&:hover': {
      color: palette.gray.dark2,
      background: palette.gray.light1,
    },
  }),
};

export const mergeSelectStyles = (customStyles: StylesConfig<OptionTypeBase, false>) => {
  const styles = { ...selectStyles };

  Object.keys(customStyles).forEach((key) => {
    if (selectStyles[key]) {
      styles[key] = (base, state) => {
        return customStyles[key](selectStyles[key](base, state), state);
      };
    } else {
      styles[key] = customStyles[key];
    }
  });

  return styles;
};
