import React from 'react';
import Box from '@leafygreen-ui/box';
import Card from '@leafygreen-ui/card';
import Icon from '@leafygreen-ui/icon';
import { Subtitle } from '@leafygreen-ui/typography';

import { IndexIcon } from 'baas-ui/common/svgs';

import './expandable-step-card.less';

export enum TestSelector {
  StepNumber = 'step-number',
  Title = 'title',
  DescriptionText = 'description',
  DescriptionContent = 'descriptionContent',
  Chevron = 'chevron',
  EditButton = 'edit-button',
  WrapperCard = 'card',
  Children = 'children',
}

export interface Props {
  stepNumber: number;
  title: string;
  open: boolean;
  description?: React.ReactNode;
  onClick(e: React.MouseEvent<Element>): void;
  children: React.ReactNode;
}

const baseClassName = 'expandable-step-card';
const ExpandableStepCard = ({ stepNumber, title, description, onClick, open, children, ...rest }: Props) => {
  return (
    <Card
      {...rest}
      className={baseClassName}
      data-test-selector={TestSelector.WrapperCard}
      onClick={(e) => {
        if (!open) {
          onClick(e);
        }
      }}
    >
      <Box>
        <IndexIcon data-test-selector={TestSelector.StepNumber} index={stepNumber} />
      </Box>
      <div className={`${baseClassName}-content`}>
        <Subtitle data-test-selector={TestSelector.Title}>{title}</Subtitle>
        {description && <div data-test-selector={TestSelector.DescriptionContent}>{description}</div>}
        <div className={`${baseClassName}-content-children`}>
          <div className={`${baseClassName}-content-children-wrapper`} data-test-selector={TestSelector.Children}>
            {children}
          </div>
        </div>
      </div>
      <Box>
        <Icon
          data-test-selector={TestSelector.Chevron}
          glyph={open ? 'ChevronDown' : 'ChevronRight'}
          onClick={onClick}
        />
      </Box>
    </Card>
  );
};

export default ExpandableStepCard;
