import { createAction } from 'redux-act';

import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { ServiceRequestPayload } from 'baas-ui/services/actions';
import { BaseRequestPayload } from 'baas-ui/types';
import { GenerateSchemasRequest } from 'admin-sdk';

import { Namespaces, RawCollQuery, RawValidateCollectionResults } from './types';

const NAME = 'mongodb-service/';
const VALIDATE_DOCUMENTS = 'validate_documents';

export interface MongoDBRuleRequest extends ServiceRequestPayload {
  ruleId: string;
  bypassServiceChange?: string;
}

interface MongoDBCreateCollectionRequest extends ServiceRequestPayload {
  dbName: string;
  collectionName: string;
}

export interface GenerateSingleSchemaPayload extends ServiceRequestPayload {
  /* eslint-disable @typescript-eslint/ban-types,camelcase */
  query?: Object;
  project?: Object;
  sort?: Object;
  skip?: number;
  limit?: number;
  database_name: string;
  collection_name: string;
  /* eslint-enable */
}

export type GenerateSchemasPayload = BaseRequestPayload & GenerateSchemasRequest;

export interface GetEstimatedDocumentCountRequest extends ServiceRequestPayload {
  databaseName: string;
  collectionName: string;
}

export interface GenerateSchemaResponse {
  schema: Record<string, any>;
}
interface ValidateDocumentsRequest extends MongoDBRuleRequest {
  collQuery: RawCollQuery;
}

export const setAddRuleErrors = createAction(`${NAME}set add rule errors`);
export const clearAddRuleErrors = createAction(`${NAME}clear add rule errors`);
export const clearValidationResults = createAction<{ id: string }>(`${NAME}clearValidationResults`);
export const updateNamespaces = createAction<Namespaces>(`${NAME}update namespaces`);

export const [validateCollectionDocumentsActions, validateCollectionDocuments] = createActionsAndExecutor<
  ValidateDocumentsRequest,
  RawValidateCollectionResults
>(
  `${NAME}validate collection documents`,
  (client, { groupId, appId, svcId, collQuery }) =>
    () =>
      client.apps(groupId).app(appId).services().service(svcId).runCommand(VALIDATE_DOCUMENTS, collQuery)
);

// returns a promise that resolve to a list of namespaces [{database, collections: [...]}]
export const [listSvcNamespacesActions, listSvcNamespaces] = createActionsAndExecutor<
  ServiceRequestPayload,
  Namespaces
>(
  `${NAME}list svc namespaces`,
  (client, { groupId, appId, svcId }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .services()
        .service(svcId)
        .runCommand('list_namespaces', {})
        .catch((err) => {
          // don't surface this failure to user via the UI, but log for debugging purposes
          // eslint-disable-next-line no-console
          console.error(err);
          return [];
        })
);

// returns a promise that resolve to a list of namespaces [{database, collections: [...]}]
export const [listSvcNamespacesWithPreimageActions, listSvcNamespacesWithPreimage] = createActionsAndExecutor<
  ServiceRequestPayload,
  Namespaces
>(
  `${NAME}list svc namespaces with preimage`,
  (client, { groupId, appId, svcId }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .services()
        .service(svcId)
        .runCommand('list_namespaces_with_preimage', {})
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          return [];
        })
);

export const [createCollectionActions, createCollection] = createActionsAndExecutor<
  MongoDBCreateCollectionRequest,
  Namespaces
>(
  `${NAME}create collection`,
  (client, { groupId, appId, svcId, dbName, collectionName }) =>
    () =>
      client.apps(groupId).app(appId).services().service(svcId).runCommand('create_collection', {
        database_name: dbName,
        collection_name: collectionName,
      })
);

export const [loadEstimatedDocumentsActions, loadEstimatedDocuments] = createActionsAndExecutor<
  GetEstimatedDocumentCountRequest,
  number
>(
  `${NAME}/load estimated documents`,
  (client, { groupId, appId, svcId, databaseName, collectionName }) =>
    () =>
      client
        .apps(groupId)
        .app(appId)
        .services()
        .service(svcId)
        .runCommand('count_estimated_documents', {
          collection_name: collectionName,
          database_name: databaseName,
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          return 0;
        })
);
