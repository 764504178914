import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as homeActions from 'baas-ui/home/actions';
import { AppProduct, appServicesVisibleProductTypes } from 'baas-ui/home/types';
import { AsyncDispatch, AsyncDispatchPayload } from 'baas-ui/redux_util';
import { getDomainRedirectState, getHomeState, getSettingsState } from 'baas-ui/selectors';
import { track } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import { replaceBaseUrl } from 'baas-ui/urls';
import { PartialApp } from 'admin-sdk';

import DomainRedirectNoticeModal from './DomainRedirectNoticeModal';
import { Domain } from './types';

interface StateProps {
  shouldHandleDomainRedirect: boolean;
  rejectedDomainRedirect: boolean;
  oldDomain: Domain;
  adminUrl?: string;
}

interface DispatchProps {
  loadApps(): AsyncDispatchPayload<PartialApp[]>;
}

type Props = StateProps & DispatchProps;

const DomainRedirectProvider = ({
  shouldHandleDomainRedirect,
  rejectedDomainRedirect,
  oldDomain,
  adminUrl,
  loadApps,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (shouldHandleDomainRedirect) {
      // load apps so DomainRedirectNoticeModal can access all app ids when migrating local storage to services domain
      loadApps().catch(() => {});

      if (oldDomain === Domain.Realm && !rejectedDomainRedirect) {
        track('APPLICATION.OLD_DOMAIN_USED', { domain: Domain.Realm });
        setModalOpen(true);
      }

      // TODO(BAAS-7827): Remove once the stitch hostname is deprecated
      if (oldDomain === Domain.Stitch && adminUrl) {
        track('APPLICATION.OLD_DOMAIN_USED', { domain: Domain.Stitch });
        window.location.replace(replaceBaseUrl(adminUrl, window.location.href));
      }
    }
  }, [shouldHandleDomainRedirect, oldDomain, rejectedDomainRedirect, adminUrl]);

  return <DomainRedirectNoticeModal oldDomain={oldDomain} open={modalOpen} setOpen={setModalOpen} />;
};

const mapStateToProps = (state: RootState) => ({
  shouldHandleDomainRedirect: getDomainRedirectState(state).shouldHandleDomainRedirect,
  rejectedDomainRedirect: getDomainRedirectState(state).rejectedDomainRedirect,
  oldDomain: getDomainRedirectState(state).oldDomain,
  adminUrl: getSettingsState(state)?.adminUrl,
  groupId: getHomeState(state).groupId,
});

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  loadApps: (groupId: string, products: AppProduct[]) =>
    dispatch(
      homeActions.loadApps({
        groupId,
        products,
      })
    ),
});

const mergeProps = (
  { groupId, ...otherStateProps }: ReturnType<typeof mapStateToProps>,
  { loadApps }: ReturnType<typeof mapDispatchToProps>
) => ({
  ...otherStateProps,
  loadApps: () => loadApps(groupId, appServicesVisibleProductTypes),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DomainRedirectProvider);
