import React from 'react';
import styled from '@emotion/styled';
import Box from '@leafygreen-ui/box';
import { Cell, Row, Table, TableHeader } from '@leafygreen-ui/table-legacy';
import { Body } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';

export interface Props {
  percentUsersAffected: number;
}

const StyledRow = styled(Row, {
  shouldForwardProp: (propName): propName is 'isGray' => propName !== 'isGray',
})<{ isGray: boolean }>(({ theme, isGray }) => ({
  backgroundColor: isGray ? theme.leafygreen.colors.gray.light3 : theme.leafygreen.colors.white,
}));

const StyledStatusBody = styled(Body, { shouldForwardProp: (propName): propName is 'isRed' => propName !== 'isRed' })<{
  isRed: boolean;
}>(({ theme, isRed }) => ({
  paddingTop: theme.leafygreen.spacing[4],
  color: isRed ? theme.leafygreen.colors.red.base : theme.leafygreen.colors.black,
  fontStyle: 'italic',
}));

const rows = [
  {
    title: '% end users affected',
    description: <Body>Migration may result in some of your end users seeing a broken application.</Body>,
    status: ({ percentUsersAffected }: Props) => (
      <StyledStatusBody isRed={percentUsersAffected >= 50}>
        {percentUsersAffected.toFixed(2)}% of your users who connected in the last 10 days are on an outdated SDK
        version that does not support Sync migrations
        {percentUsersAffected > 0 ? ' - they will lose the ability to connect to Sync.' : ''}
      </StyledStatusBody>
    ),
  },

  {
    title: 'Expected App Downtime',
    description: (
      <Body>
        {`Your application may experience downtime as we migrate your data from Partition-Based to Flexible Sync.
        We recommend that your app uses the latest version of the SDK to ensure `}
        <DocLink href={docLinks.Sync.ClientReset} showExternalIcon>
          client reset
        </DocLink>
        {` with automatic recovery is leveraged.`}
      </Body>
    ),
    status: () => (
      <StyledStatusBody isRed={false}>
        Migration completion time depends on the amount of data in your synced collections. Completion time may range
        between a few minutes to a few hours during which you may cancel migration at any time.
      </StyledStatusBody>
    ),
  },

  {
    title: 'Client Max Offline Time',
    description: (
      <Body>
        {'We will set '}
        <DocLink href={docLinks.Sync.MaxOfflineTime} showExternalIcon>
          Client Max Offline time
        </DocLink>
        {' to 30 days after migrating to Flexible Sync.'}
      </Body>
    ),
  },
];

export enum TestSelector {
  RowPrefix = 'row',
}

export const ReviewProcessBody = (props: Props) => (
  <>
    <Body>
      {'First, please review how your application and end users may be affected by the migration. '}
      <DocLink href={docLinks.Sync.Migration} showExternalIcon>
        View all Flexible Sync migration requirements
      </DocLink>
    </Body>

    <Table data={rows} columns={[<TableHeader label="" />]}>
      {({ index, datum }) => (
        <StyledRow isGray={index % 2 === 0}>
          <Cell>
            <Box data-testid={`${TestSelector.RowPrefix}${index}`}>
              <Body className="bold-text">{datum.title}</Body>

              {datum.description}

              {datum.status && datum.status(props)}
            </Box>
          </Cell>
        </StyledRow>
      )}
    </Table>
  </>
);
