import React from 'react';
import InlineDefinition from '@leafygreen-ui/inline-definition';
import { Link } from '@leafygreen-ui/typography';

import { docLinks } from 'baas-ui/common/links';

const baseClassName = 'preset-roles-display';

const presetRolesInfoText = `Preset roles are roles with preconfigured settings that you can add
to quickly get started. You can freely change a preset role's name and permissions once it has been added.`;

export const presetRoleDescriptions = {
  denyAllAccess: 'User is denied all access',
  readAll: 'User can read all data',
  readAndWriteAll: 'User can read and write all data',
  readOwnWriteOwn: 'User can only read and write their own data',
  readAllWriteOwn: 'User can read all data but only write their own data',
  adminReadOwnWriteOwn: 'Users can read and write their own data, admins can read and write all data',
};

export const radioGroupPresetRoleNames = ['denyAllAccess', 'readAll', 'readAndWriteAll'];

export const migratedPresetRoleNames = ['migratedDenyAllAccess', 'migratedReadAll', 'migratedReadAndWriteAll'];
export const migratedRadioGroupPresetRoleNames = migratedPresetRoleNames;

export const migratedPresetRoleNameToDisplayNameMap = {
  migratedDenyAllAccess: 'denyAllAccess',
  migratedReadAll: 'readAll',
  migratedReadAndWriteAll: 'readAndWriteAll',
};

export const otherPresetRolesPlaceholder = 'Select a preset';

export const defaultRuleHeadingText = 'Set up default roles for this cluster';

export const defaultRuleDescription = (
  <>
    Avoid defining rules one collection at a time by applying{' '}
    <Link href={docLinks.Rules.DefaultRule} className={`${baseClassName}-header-doclink`}>
      default roles and filters
    </Link>{' '}
    to every collection without permissions in this cluster. First, add a <b>preset role</b> - once added, edit its
    permissions if needed.
  </>
);

export const nsRuleHeadingText = 'Set up roles for this collection';

export const nsRuleDescription = (
  <>
    Configure customized access to data in your cluster. Start by adding a{' '}
    <InlineDefinition definition={presetRolesInfoText}>preset role</InlineDefinition>, which you can edit later if
    needed.
  </>
);
