import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ThroughProvider } from 'react-through';
import styled from '@emotion/styled';
import { PageLoader } from '@leafygreen-ui/loading-indicator';
import { AmplitudeIntegration } from 'heliotrope/lib/types';
import { History } from 'history';

import usePoller from 'baas-ui/common/hooks/use-poller';
import { getTimeZone, setTimeZone } from 'baas-ui/common/timezone/timezone';
import Nav from 'baas-ui/nav/Nav';
import { useDarkMode } from 'baas-ui/theme';
import { hasRealmInUrl, hasStitchInUrl } from 'baas-ui/urls';
import { AtlasGroup } from 'admin-sdk';

import ExperimentManager from './ab/ExperimentManager';
import ErrorBoundary from './common/components/error-boundary';
import { PRODUCT_SELF_HOSTED } from './common/constants';
import { handleDomainRedirect as handleDomainRedirectAction } from './domain-redirect-provider/actions';
import { Domain } from './domain-redirect-provider/types';
import AppsRouter from './home/apps/AppsRouter';
import HomePage from './home/home-page';
import NotFound from './home/not-found';
import { RequestError } from './home/types';
import { getAtlasGroup } from './actions';
import DomainRedirectProvider from './domain-redirect-provider';
import { errorHandlerNotify } from './error_util';
import LocalStorageMigrationProvider from './local-storage-migration-provider';
import { CloudAtlasRedirect, ErrorBoundaryRoute } from './nav';
import { AsyncDispatch, AsyncDispatchPayload } from './redux_util';
import { getHomeState, getNavigationState, getSettingsState, getUserProfileState } from './selectors';
import Session from './session';
import { featureSettings } from './stitch_ui';
import { EventTracker } from './tracking';
import { RootState } from './types';

const PageLoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
`;

const FullPageLoader = styled(PageLoader)`
  align-items: center;
`;

export enum TestSelector {
  LoadingState = 'loading-state',
}

interface ComputedProps {
  error?: RequestError;
  groupId?: string;
  orgId?: string;
  product: string;
  recaptchaSiteKey: string;
  adminUrl: string;
  loadAtlasFeatureFlags(groupId: string): AsyncDispatchPayload<AtlasGroup>;
  handleDomainRedirect(oldDomain: Domain): void;
  userId?: string;
}

// TODO(BAAS-9007): the updated react-router-dom typings expect this type for History.state
// this should be able to go away once react-router is updated to v6 and
// history is accordingly updated as well
type PoorMansUnknown = {} | null | undefined;

interface PublicProps {
  history: History<PoorMansUnknown>;
}

export type Props = ComputedProps & PublicProps;

export function StitchRouterComponent({
  loadAtlasFeatureFlags,
  handleDomainRedirect,
  error,
  groupId,
  orgId,
  history,
  product,
  adminUrl,
  userId,
}: Props) {
  const [loadingData, setLoadingData] = useState(false);
  const darkMode = useDarkMode();

  const clusterStatePoller = usePoller('clusterStatePoller');

  React.useEffect(() => {
    const loadDataAsync = async () => {
      if (groupId && product !== PRODUCT_SELF_HOSTED) {
        setLoadingData(true);
        try {
          const atlasGroup = await loadAtlasFeatureFlags(groupId);
          if (atlasGroup) {
            featureSettings.clearAtlasFeatures();
            featureSettings.setAtlasFeatures(atlasGroup.betaFeatures);
          }

          if (window.settings.cloudUIBaseUrl) {
            try {
              const timeZonePreferences = await getTimeZone(window.settings.cloudUIBaseUrl, groupId);
              setTimeZone(groupId, timeZonePreferences);
            } catch (err) {
              // If fetching the timezone from atlas fails we don't need to do anything.
              // When no timezones are set, the useTimeZone hook will default to UTC.
              errorHandlerNotify(err);
            }
          }
        } finally {
          setLoadingData(false);
        }
      }
    };
    loadDataAsync();
  }, [groupId]);

  React.useEffect(() => {
    const groups: AmplitudeIntegration = {};
    if (orgId) {
      groups.Organization = orgId;
    }
    if (groupId) {
      groups.Project = groupId;
    }
    if (orgId || groupId) {
      EventTracker.updateState({ integrations: { Amplitude: { groups } } });
    }
  }, [groupId, orgId]);

  React.useEffect(() => {
    if (userId) {
      EventTracker.init(userId);
    }

    return () => {
      if (userId) {
        EventTracker.reset();
      }
    };
  }, [userId]);

  React.useEffect(() => {
    return () => {
      // clear assignments when groupId or userId changes from a previous value
      if (groupId && userId) {
        ExperimentManager.clearAssignments();
      }
    };
  }, [groupId, userId]);

  React.useEffect(() => {
    if ((orgId || groupId) && userId) {
      ExperimentManager.updateEntityIds({
        orgId,
        groupId,
        userId,
      });
    }
  }, [orgId, groupId, userId]);

  React.useEffect(() => {
    if ((hasStitchInUrl(window.location.href) || hasRealmInUrl(window.location.href)) && adminUrl) {
      const oldDomain = hasStitchInUrl(window.location.href) ? Domain.Stitch : Domain.Realm;
      handleDomainRedirect(oldDomain);
    }
  }, []);

  if (loadingData) {
    return (
      <PageLoaderContainer>
        <FullPageLoader darkMode={darkMode} data-test-selector={TestSelector.LoadingState} description="Loading..." />
      </PageLoaderContainer>
    );
  }

  return (
    <ThroughProvider>
      <Router history={history}>
        <ErrorBoundary onError={errorHandlerNotify}>
          <DomainRedirectProvider />
          <LocalStorageMigrationProvider />
          <Switch>
            {[RequestError.NotFound, RequestError.Forbidden].includes(error!) && (
              <Route
                render={() => (
                  <Nav>
                    <NotFound showTopNav showFooter />
                  </Nav>
                )}
              />
            )}
            <Route
              exact
              path="/"
              render={(routeProps) => <CloudAtlasRedirect component={HomePage} {...routeProps} />}
            />
            <ErrorBoundaryRoute
              path="/login"
              render={(routeProps) => <CloudAtlasRedirect component={Session.Login} {...routeProps} />}
            />
            <Route path="/logout" component={Session.Logout} />
            <ErrorBoundaryRoute
              path="/groups/:groupId/apps"
              render={() => (
                <Nav>
                  <AppsRouter clusterStatePoller={clusterStatePoller} />
                </Nav>
              )}
            />
            <Redirect exact from="/groups/:groupId" to="/groups/:groupId/apps" />
            <Redirect exact from="/groups" to="/" />
            <Route
              render={() => (
                <Nav>
                  <NotFound showTopNav showFooter />
                </Nav>
              )}
            />
          </Switch>
        </ErrorBoundary>
      </Router>
    </ThroughProvider>
  );
}

const mapStateToProps = (state: RootState) => {
  const { groupId, error } = getHomeState(state);
  const { product, recaptchaSiteKey, adminUrl } = getSettingsState(state);
  const { organization } = getNavigationState(state);
  const userProfile = getUserProfileState(state);

  return {
    error,
    groupId,
    product,
    recaptchaSiteKey,
    adminUrl,
    userId: userProfile?.userId,
    orgId: organization?.orgId,
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  loadAtlasFeatureFlags: (groupId: string) => dispatch(getAtlasGroup({ groupId })),
  handleDomainRedirect: (oldDomain: Domain) => dispatch(handleDomainRedirectAction({ oldDomain })),
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: PublicProps
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(StitchRouterComponent);
