import { JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';

import createStringMapSerializers from '../common/createStringMapSerializers';

export enum FeatureFlagStatus {
  Enabled = 'enabled',
}

@JsonObject('FeatureSettings')
export class FeatureSettings {
  @JsonProperty('enabled', [String], true)
  public enabled?: string[] = undefined;

  @JsonProperty('immutable', [String], true)
  public immutable?: string[] = undefined;

  constructor(partial?: Partial<FeatureSettings>) {
    Object.assign(this, partial);
  }
}

@JsonObject('CompressionSettings')
export class CompressionSettings {
  @JsonProperty('type', String, true)
  public type?: string = undefined;

  @JsonProperty('level', Number, true)
  public level?: number = undefined;

  constructor(partial?: Partial<CompressionSettings>) {
    Object.assign(this, partial);
  }
}

@JsonObject('EventSubscriptionSettings')
export class EventSubscriptionSettings {
  @JsonProperty('batch_size', Number, true)
  public batchSize?: number = undefined;

  @JsonProperty('cursor_batch_size', Number, true)
  public cursorBatchSize?: number = undefined;

  @JsonProperty('distributed_trigger_event_processing_enabled', Boolean, true)
  public distributedTriggerEventProcessingEnabled?: boolean = undefined;

  @JsonProperty('limiter_max_token_count', Number, true)
  public limiterMaxTokenCount?: number = undefined;

  @JsonProperty('limiter_rate_sec', Number, true)
  public limiterRateSec?: number = undefined;

  @JsonProperty('max_retry_minutes', Number, true)
  public maxRetryMinutes?: number = undefined;

  @JsonProperty('throttle_document_size_threshold_bytes', Number, true)
  public throttleDocumentSizeThresholdBytes?: number = undefined;

  @JsonProperty('unordered_batch_size', Number, true)
  public unorderedBatchSize?: number = undefined;

  @JsonProperty('unordered_batch_timeout_sec', Number, true)
  public unorderedBatchTimeoutSec?: number = undefined;

  @JsonProperty('compression', CompressionSettings, true)
  public compression?: CompressionSettings = undefined;

  constructor(partial?: Partial<EventSubscriptionSettings>) {
    Object.assign(this, partial);
  }
}

@JsonObject('EventSubscriptionsOverrides')
export class EventSubscriptionsOverrides {
  @JsonProperty('event_subscription_id', String, true)
  public eventSubscriptionID?: string = undefined;

  @JsonProperty('batch_size', Number, true)
  public batchSize?: number = undefined;

  @JsonProperty('cursor_batch_size', Number, true)
  public cursorBatchSize?: number = undefined;

  @JsonProperty('distributed_trigger_event_processing_enabled', Boolean, true)
  public distributedTriggerEventProcessingEnabled?: boolean = undefined;

  @JsonProperty('limiter_max_token_count', Number, true)
  public limiterMaxTokenCount?: number = undefined;

  @JsonProperty('limiter_rate_sec', Number, true)
  public limiterRateSec?: number = undefined;

  @JsonProperty('max_retry_minutes', Number, true)
  public maxRetryMinutes?: number = undefined;

  @JsonProperty('throttle_document_size_threshold_bytes', Number, true)
  public throttleDocumentSizeThresholdBytes?: number = undefined;

  @JsonProperty('unordered_batch_size', Number, true)
  public unorderedBatchSize?: number = undefined;

  @JsonProperty('unordered_batch_timeout_sec', Number, true)
  public unorderedBatchTimeoutSec?: number = undefined;

  @JsonProperty('compression', CompressionSettings, true)
  public compression?: CompressionSettings = undefined;

  constructor(partial?: Partial<EventSubscriptionsOverrides>) {
    Object.assign(this, partial);
  }
}

@JsonObject('SyncSettings')
export class SyncSettings {
  @JsonProperty('changeset_integration_timeout_secs', Number, true)
  public changesetIntegrationTimeoutSecs?: number = undefined;

  @JsonProperty('changeset_integration_max_attempts', Number, true)
  public changesetIntegrationMaxAttempts?: number = undefined;

  @JsonProperty('changeset_integration_upper_bound_bytes', Number, true)
  public changesetIntegrationUpperBoundBytes?: number = undefined;

  @JsonProperty('changeset_integration_upper_bound_count', Number, true)
  public changesetIntegrationUpperBoundCount?: number = undefined;

  @JsonProperty('download_loop_sleep_millis', Number, true)
  public downloadLoopSleepMillis?: number = undefined;

  @JsonProperty('translator_batch_size', Number, true)
  public translatorSteadyStateBatchSize?: number = undefined;

  @JsonProperty('translator_download_bytes_threshold', Number, true)
  public translatorDownloadBytesThreshold?: number = undefined;

  @JsonProperty('translator_max_num_unsyncable_docs', Number, true)
  public translatorMaxNumUnsyncableDocs?: number = undefined;

  @JsonProperty('translator_num_client_metas', Number, true)
  public translatorNumClientMetas?: number = undefined;

  @JsonProperty('translator_pbs_conn_pool_size', Number, true)
  public translatorPBSConnectionPoolSize?: number = undefined;

  @JsonProperty('translator_download_backpressure_threshold_bytes', Number, true)
  public translatorDownloadBackpressureThresholdBytes?: number = undefined;

  @JsonProperty('translator_download_backpressure_threshold_count', Number, true)
  public translatorDownloadBackpressureThresholdCount?: number = undefined;

  @JsonProperty('translator_changestream_cursor_batch_size', Number, true)
  public translatorChangestreamCursorBatchSize?: number = undefined;

  @JsonProperty('num_instructions_before_bootstrap_flush', Number, true)
  public numInstructionsBeforeBootstrapFlush?: number = undefined;

  @JsonProperty('app_partition_compaction_timeout_sec', Number, true)
  public appPartitionCompactionTimeoutSec?: number = undefined;

  @JsonProperty('app_partition_compaction_lvt_build_timeout_sec', Number, true)
  public appPartitionCompactionLVTBuildTimeout?: number = undefined;

  @JsonProperty('max_changeset_bytes_written_per_compaction', Number, true)
  public maxChangesetBytesWrittenPerCompaction?: number = undefined;

  @JsonProperty('max_changeset_entries_modified_per_compaction', Number, true)
  public maxChangesetEntriesModifiedPerCompaction?: number = undefined;

  @JsonProperty('initial_sync_max_docs_per_scan', Number, true)
  public initialSyncMaxDocsPerScan?: number = undefined;

  @JsonProperty('initial_sync_max_seconds_per_scan', Number, true)
  public initialSyncMaxSecondsPerScan?: number = undefined;

  @JsonProperty('initial_sync_max_doc_bytes_per_scan', Number, true)
  public initialSyncMaxDocBytesPerScan?: number = undefined;

  constructor(partial?: Partial<SyncSettings>) {
    Object.assign(this, partial);
  }
}

const eventSubscriptionSettingsMapSerializers = createStringMapSerializers(EventSubscriptionSettings);

@JsonConverter
class EventSubscriptionSettingsMapConverter implements JsonCustomConvert<Record<string, EventSubscriptionSettings>> {
  public serialize = eventSubscriptionSettingsMapSerializers.serialize;

  public deserialize = eventSubscriptionSettingsMapSerializers.deserialize;
}

@JsonObject('AppSettings')
export class AppSettings {
  @JsonProperty('connection_pool_size', Number, true)
  public connectionPoolSize?: number = undefined;

  @JsonProperty('features', FeatureSettings, true)
  public features?: FeatureSettings = undefined;

  @JsonProperty('event_subscriptions', EventSubscriptionSettingsMapConverter, true)
  public eventSubscriptions?: Record<string, EventSubscriptionSettings> = undefined;

  @JsonProperty('event_subscriptions_overrides', EventSubscriptionsOverrides, true)
  public eventSubscriptionsOverrides?: EventSubscriptionsOverrides = undefined;

  @JsonProperty('sync_settings', SyncSettings, true)
  public syncSettings?: SyncSettings = undefined;

  constructor(partial?: Partial<AppSettings>) {
    Object.assign(this, partial);
  }
}
