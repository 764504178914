import { useMemo } from 'react';
import moment from 'moment';

const SHORT_DATE = 'MMM DD';

interface BillingPeriodDates {
  date: Date;
  short: string;
}

interface BillingPeriod {
  start: BillingPeriodDates;
  end: BillingPeriodDates;
  daysUntil: {
    endOfMonth: number;
    nextBillingPeriod: number;
  };
}

const useLegacyBillingPeriod = (isPricingChangeEnabled: boolean) => {
  const billingPeriod = useMemo<BillingPeriod>(() => {
    const startTime = isPricingChangeEnabled ? moment().utc().startOf('day') : moment().utc().startOf('month');
    const endTime = isPricingChangeEnabled ? moment().utc().endOf('day') : moment().utc().endOf('month');

    const daysRemainingInMonth = moment().utc().endOf('month').diff(moment().utc(), 'days');

    return {
      start: {
        date: startTime.toDate(),
        short: startTime.format(SHORT_DATE),
      },
      end: {
        date: endTime.toDate(),
        short: endTime.format(SHORT_DATE),
      },
      daysUntil: {
        endOfMonth: daysRemainingInMonth,
        nextBillingPeriod: daysRemainingInMonth + 1,
      },
    };
  }, []);

  return billingPeriod;
};

export default useLegacyBillingPeriod;
