import { createAction } from 'redux-act';

import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import { AppLogRequest, CreateLogForwarderRequest, PatchLogForwarderRequest } from 'admin-sdk';

import { AppliedFilter, LogDate, LogFilterType } from './types';

const NAME = 'logs/';

export const resetLogs = createAction(`${NAME}reset logs`);
export const setStartDate = createAction<LogDate | undefined>(`${NAME}set start date`);
export const setEndDate = createAction<LogDate | undefined>(`${NAME}set end date`);
export const setErrorsOnly = createAction<boolean>(`${NAME}set errors only`);
export const setLogFilterTypes = createAction<Set<LogFilterType>>(`${NAME}set log type`);
export const setUserId = createAction<string>(`${NAME}set user id`);
export const setCoId = createAction<string>(`${NAME}set correlation id`);
export const setLimit = createAction<number | undefined>(`${NAME}set limit`);
export const clearError = createAction(`${NAME}clearError`);
export const clearDownloadError = createAction(`${NAME}clearDownloadError`);
export const setApplied = createAction<AppliedFilter>(`${NAME}setApplied`);

interface RequestPayload extends BaseRequestPayload {
  filter: AppLogRequest;
}

interface LogForwarderByIdPayload extends BaseRequestPayload {
  logForwarderId: string;
}

interface UpdateLogForwarderPayload extends LogForwarderByIdPayload {
  data: PatchLogForwarderRequest;
}

interface CreateLogForwarderPayload extends BaseRequestPayload {
  data: CreateLogForwarderRequest;
}

export const [loadLogsActions, loadLogs] = createActionsAndExecutor(
  `${NAME}load logs`,
  (client, { groupId, appId, filter }: RequestPayload) =>
    () =>
      client.apps(groupId).app(appId).logs().list(filter)
);

export const [deleteLogForwarderActions, deleteLogForwarder] = createActionsAndExecutor(
  `${NAME}delete log forwarder`,
  (client, { groupId, appId, logForwarderId }: LogForwarderByIdPayload) =>
    () =>
      client.apps(groupId).app(appId).logForwarders().logForwarder(logForwarderId).remove()
);

export const [disableLogForwarderActions, disableLogForwarder] = createActionsAndExecutor(
  `${NAME}disable log forwarder`,
  (client, { groupId, appId, logForwarderId }: LogForwarderByIdPayload) =>
    () =>
      client.apps(groupId).app(appId).logForwarders().logForwarder(logForwarderId).disable()
);

export const [enableLogForwarderActions, enableLogForwarder] = createActionsAndExecutor(
  `${NAME}enable log forwarder`,
  (client, { groupId, appId, logForwarderId }: LogForwarderByIdPayload) =>
    () =>
      client.apps(groupId).app(appId).logForwarders().logForwarder(logForwarderId).enable()
);

export const [resumeLogForwarderActions, resumeLogForwarder] = createActionsAndExecutor(
  `${NAME}resume log forwarder`,
  (client, { groupId, appId, logForwarderId }: LogForwarderByIdPayload) =>
    () =>
      client.apps(groupId).app(appId).logForwarders().logForwarder(logForwarderId).resume()
);

export const [loadLogForwardersActions, loadLogForwarders] = createActionsAndExecutor(
  `${NAME}load log forwarders`,
  (client, { groupId, appId }: BaseRequestPayload) =>
    () =>
      client.apps(groupId).app(appId).logForwarders().list()
);

export const [loadLogForwarderActions, loadLogForwarder] = createActionsAndExecutor(
  `${NAME}load log forwarder`,
  (client, { groupId, appId, logForwarderId }: LogForwarderByIdPayload) =>
    () =>
      client.apps(groupId).app(appId).logForwarders().logForwarder(logForwarderId).get()
);

export const [updateLogForwarderActions, updateLogForwarder] = createActionsAndExecutor(
  `${NAME}update log forwarder`,
  (client, { groupId, appId, logForwarderId, data }: UpdateLogForwarderPayload) =>
    () =>
      client.apps(groupId).app(appId).logForwarders().logForwarder(logForwarderId).update(data)
);

export const [createLogForwarderActions, createLogForwarder] = createActionsAndExecutor(
  `${NAME}create log forwarder`,
  (client, { groupId, appId, data }: CreateLogForwarderPayload) =>
    () =>
      client.apps(groupId).app(appId).logForwarders().create(data)
);

export const [downloadLogsAction, downloadLogs] = createActionsAndExecutor(
  `${NAME}download logs`,
  (client, { groupId, appId, filter }: RequestPayload) =>
    () =>
      client.apps(groupId).app(appId).logs().download(filter)
);

export const asyncEditRcvActions = [
  createLogForwarderActions.rcv,
  updateLogForwarderActions.rcv,
  deleteLogForwarderActions.rcv,
  disableLogForwarderActions.rcv,
  enableLogForwarderActions.rcv,
  resumeLogForwarderActions.rcv,
];
