import { UpdateStateOptions } from 'heliotrope';
import { Store } from 'redux';

import { errorHandlerNotify } from 'baas-ui/error_util';
import { getAppState, getNavigationState, getUserProfileState } from 'baas-ui/selectors';
import { BaasEnvironment } from 'baas-ui/types';

import { EventLogger, EventProperties, TrackingEvent } from './types';

let registeredEventTracker: EventTracker;

class EventTracker {
  store: Store;

  env: BaasEnvironment;

  eventLogger: EventLogger;

  static register(config: { store: Store; env: BaasEnvironment; eventLogger: EventLogger }) {
    try {
      registeredEventTracker = new EventTracker(config);
    } catch (err) {
      errorHandlerNotify(err);
    }
  }

  static init(userId: string) {
    try {
      registeredEventTracker.init(userId);
    } catch (err) {
      errorHandlerNotify(err);
    }
  }

  static reset() {
    try {
      registeredEventTracker.reset();
    } catch (err) {
      errorHandlerNotify(err);
    }
  }

  static logEvent(event: TrackingEvent, properties?: EventProperties) {
    return registeredEventTracker.logEvent(event, properties);
  }

  static updateState(stateOptions: UpdateStateOptions) {
    try {
      registeredEventTracker.updateState(stateOptions);
    } catch (err) {
      errorHandlerNotify(err);
    }
  }

  constructor({ store, env, eventLogger }: { store: Store; env: BaasEnvironment; eventLogger: EventLogger }) {
    this.env = env;
    this.store = store;
    this.eventLogger = eventLogger;
  }

  init(userId: string) {
    this.eventLogger.init(userId);
  }

  reset() {
    this.eventLogger.reset();
  }

  updateState(stateOptions: UpdateStateOptions) {
    this.eventLogger.updateState(stateOptions);
  }

  logEvent(event: TrackingEvent, properties?: EventProperties) {
    const state = this.store.getState();
    const { app: { id: appId = '', clientAppId = '', groupId = '' } = {} } = getAppState(state) || {};
    const { userId } = getUserProfileState(state) || {};
    const { organization: { orgId = '' } = {} } = getNavigationState(state) || {};

    const { eventType, actionFn, context } = event;
    let { action = '' } = event;

    if (actionFn) {
      action = actionFn(properties || {});
    }

    const stringifiedProperties = Object.entries(properties || {}).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: typeof value === 'object' ? JSON.stringify(value) : value,
      }),
      {}
    );

    const eventProperties: { [key: string]: string } = {
      action,
      env: this.env,
      customer_id: userId,
      ...stringifiedProperties,
    };

    if (context) {
      eventProperties.context = context;
    }

    if (appId) {
      eventProperties.app_id = appId;
    }

    if (clientAppId) {
      eventProperties.client_app_id = clientAppId;
    }

    if (groupId) {
      eventProperties.group_id = groupId;
    }

    if (orgId) {
      eventProperties.org_id = orgId;
    }

    return this.eventLogger.logEvent(eventType, eventProperties);
  }
}

export default EventTracker;
