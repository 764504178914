import React from 'react';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

interface BreadcrumbsContainerProps {
  children: React.ReactNode;
}

const BreadcrumbsContainer = ({ children }: BreadcrumbsContainerProps) => (
  <div className="breadcrumbs-container" data-cy="breadcrumbs-container">
    {children}
  </div>
);

function urlWithoutQueryParams(url: string) {
  const qsStart = url.indexOf('?');
  if (qsStart < 0) {
    return url;
  }
  return url.substring(0, qsStart);
}

const StitchBreadcrumbs = (props, { through }) => {
  if (!through) {
    return null;
  }

  return (
    <Breadcrumbs
      separator={<span>{' > '}</span>}
      container={BreadcrumbsContainer}
      item={NavLink}
      finalItem={'span'}
      compare={(a, b) => {
        // Since our breadcrumbs include items where the base url is different, a simple length
        // comparison will not result in the expected ordering. We use a custom prop to determine
        // if a breadcrumb item should be prioritized.
        if (a.orderIndex !== undefined && b.orderIndex !== undefined) {
          return a.orderIndex - b.orderIndex;
        }
        if (a.orderIndex !== undefined) {
          return -1;
        }
        if (b.orderIndex !== undefined) {
          return 1;
        }

        return urlWithoutQueryParams(a.to).length - urlWithoutQueryParams(b.to).length;
      }}
      removeProps={{ orderIndex: true }}
    />
  );
};

StitchBreadcrumbs.contextTypes = {
  through: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

export default StitchBreadcrumbs;
