import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

import appComponents from 'baas-ui/app/components';
import usePoller from 'baas-ui/common/hooks/use-poller';
import CreateApp from 'baas-ui/home/create-app';
import { BreadcrumbsItem, CloudBreadcrumbs, ErrorBoundaryRoute } from 'baas-ui/nav';
import { getHomeState, getSettingsState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';
import urls from 'baas-ui/urls';

import Apps from './Apps';

const { App } = appComponents;

interface ReduxStateProps {
  groupId: string;
  recaptchaSiteKey: string;
  showCloudNav?: boolean;
}

interface OwnProps {
  clusterStatePoller: ReturnType<typeof usePoller>;
}

export type Props = ReduxStateProps & OwnProps;

const AppsRouter = ({ clusterStatePoller, groupId, recaptchaSiteKey, showCloudNav = false }: Props) => (
  <Switch>
    <ErrorBoundaryRoute
      exact
      path="/groups/:groupId/apps"
      render={(routeProps) => (
        <>
          {!showCloudNav && (
            <>
              <CloudBreadcrumbs />
              <BreadcrumbsItem to={urls.groups().group(groupId).apps().list()}>App Services</BreadcrumbsItem>
            </>
          )}
          <Apps {...routeProps} clusterStatePoller={clusterStatePoller} />
        </>
      )}
    />
    <ErrorBoundaryRoute
      exact
      path="/groups/:groupId/apps/create"
      render={(routeProps) => (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
          {!showCloudNav && (
            <>
              <CloudBreadcrumbs />
              <BreadcrumbsItem to={urls.groups().group(groupId!).apps().list()}>App Services</BreadcrumbsItem>
            </>
          )}
          <CreateApp {...routeProps} clusterStatePoller={clusterStatePoller} />
        </GoogleReCaptchaProvider>
      )}
    />
    <ErrorBoundaryRoute
      path="/groups/:groupId/apps/:appId"
      component={(routeProps) => <App {...routeProps} clusterStatePoller={clusterStatePoller} />}
    />
  </Switch>
);

const mapStateToProps = (state: RootState) => {
  const { groupId } = getHomeState(state);
  const { recaptchaSiteKey } = getSettingsState(state);

  return { groupId, recaptchaSiteKey };
};

export default connect(mapStateToProps)(AppsRouter);
