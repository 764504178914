import React from 'react';
import styled from '@emotion/styled';
import { css } from '@leafygreen-ui/emotion';
import Icon from '@leafygreen-ui/icon';
import IconButton from '@leafygreen-ui/icon-button';
import Tooltip from '@leafygreen-ui/tooltip';
import { Body, Overline } from '@leafygreen-ui/typography';

import { FeatureFlag } from 'baas-ui/common/featureSettings';
import { Measurement } from 'baas-ui/measurements/types';
import MeasurementsUsageBar from 'baas-ui/measurements/usage-bar';
import { legacyFormatMeasurementThreshold, legacyMeasurementUsage } from 'baas-ui/measurements/utils';
import { MetricDetails } from 'baas-ui/metrics/types';
import { featureSettings } from 'baas-ui/stitch_ui';
import { MeasurementName } from 'admin-sdk';

export interface Props {
  measurement: Measurement;
  details: MetricDetails;
  setModalOpen: () => void;
}

export enum TestSelector {
  Title = 'title',
  Display = 'display',
  MetricTooltip = 'metric-tooltip',
  UsageTooltip = 'usage-tooltip',
  UsageAmount = 'usage-amount',
  Threshold = 'threshold',
  Alert = 'alert',
  Label = 'label',
  ExpandButton = 'expand-button',
}

const styles = {
  alert: css`
    margin-right: 4px;
  `,
  display: css`
    display: flex;
    margin-bottom: 8px;
    font-size: 21px;
  `,
  label: css`
    margin-bottom: 8px;
    font-weight: normal;
  `,
  tooltip: css`
    max-width: 250px;
  `,
};

export const StyledUsageMetric = styled.div`
  min-width: 200px;
  display: flex;
  flex-direction: column;

  &:not(:first-of-type) {
    margin-left: 32px;
  }
`;

export const StyledUsageMetricValue = styled.span<{ thresholdExceeded: boolean }>`
  display: flex;
  align-items: center;
  ${({ theme, thresholdExceeded }) =>
    thresholdExceeded && {
      color: theme.leafygreen.colors.blue.base,
    }}
`;

const StyledMetricTitle = styled(Body)`
  font-size: 16px;
  text-decoration: 1px dashed underline ${({ theme }) => theme.leafygreen.colors.gray.dark2};
  text-underline-offset: 4px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

function UsageMetric({ measurement, setModalOpen, details }: Props) {
  let thresholdValue: number;

  const isPricingChangeEnabled = featureSettings.useFeatureSetting(FeatureFlag.PricingChange);

  const isPricingChangeEnabledAndSyncMetric = isPricingChangeEnabled && measurement.name === MeasurementName.SyncTime;
  const usageValue = isPricingChangeEnabledAndSyncMetric ? measurement.usage * 60 : measurement.usage;
  thresholdValue = measurement.threshold;

  if (isPricingChangeEnabled) {
    switch (measurement.name) {
      case MeasurementName.ComputeTime:
        thresholdValue = 25;
        break;
      case MeasurementName.DataOut:
        thresholdValue = 0.5;
        break;
      case MeasurementName.RequestCount:
        thresholdValue = 50000;
        break;
      case MeasurementName.SyncTime:
        thresholdValue = 30000;
        break;
      default:
        thresholdValue = measurement.threshold;
        break;
    }
  }

  const formattedUsage = legacyMeasurementUsage(usageValue, measurement.units);
  const formattedThreshold = legacyFormatMeasurementThreshold(measurement, isPricingChangeEnabled);

  const thresholdExceeded = usageValue >= thresholdValue;

  return (
    <StyledUsageMetric>
      <StyledHeader>
        <Tooltip
          className={styles.tooltip}
          trigger={
            <span>
              <StyledMetricTitle data-test-selector={TestSelector.Title}>{details.title}</StyledMetricTitle>
            </span>
          }
          justify="middle"
          data-test-selector={TestSelector.MetricTooltip}
        >
          {details.description}
        </Tooltip>
        <IconButton aria-labelledby="expand-usage" data-testid={TestSelector.ExpandButton} onClick={setModalOpen}>
          <Icon glyph="FullScreenEnter" />
        </IconButton>
      </StyledHeader>
      <Body className={styles.display} data-test-selector={TestSelector.Display}>
        <StyledUsageMetricValue thresholdExceeded={thresholdExceeded}>
          {thresholdExceeded && (
            <Tooltip
              className={styles.tooltip}
              trigger={
                <span>
                  <Icon
                    title="Free Tier Limit Exceeded"
                    glyph="InfoWithCircle"
                    size="small"
                    className={styles.alert}
                    data-test-selector={TestSelector.Alert}
                  />
                </span>
              }
              justify="middle"
              data-test-selector={TestSelector.UsageTooltip}
            >
              You have exceeded the limit for Free Tier. Go to the billing documentation to find out more.
            </Tooltip>
          )}
          <span data-test-selector={TestSelector.UsageAmount}>{formattedUsage}</span>
        </StyledUsageMetricValue>
        <span data-test-selector={TestSelector.Threshold}>{`/${formattedThreshold}`}</span>
      </Body>
      <Overline className={styles.label} data-test-selector={TestSelector.Label}>
        Free Tier Used
      </Overline>
      <MeasurementsUsageBar usage={usageValue} threshold={thresholdValue} />
    </StyledUsageMetric>
  );
}

export default UsageMetric;
