import { Map, OrderedMap } from 'immutable';
import { createReducer } from 'redux-act';

import * as actions from './actions';

export const alertReducer = createReducer(
  {
    [actions.createAlert]: (state, payload) => {
      const { key, id, message } = payload;
      const alertsListAtKey = (state.alerts.get(key) || new OrderedMap()).set(id, message);
      return { ...state, alerts: state.alerts.set(key, alertsListAtKey) };
    },
    [actions.expireAlert]: (state, payload) => {
      const { key, id } = payload;
      const alertsListAtKey = (state.alerts.get(key) || new OrderedMap()).remove(id);
      if (alertsListAtKey.size === 0) {
        // Alerts list is empty under the given key after expiring this alert,
        // so just remove the whole thing to keep the state tree small.
        return { ...state, alerts: state.alerts.remove(key) };
      }
      return { ...state, alerts: state.alerts.set(key, alertsListAtKey) };
    },
  },
  {
    alerts: new Map(),
  }
);
