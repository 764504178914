import { Granularity, Period, timeOptionMinutes } from 'baas-ui/app/metrics-card/time_options';

import { ScaledValue, TimeWindow } from './types';

interface Coordinates {
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}

export type RoundedEdge = 'left' | 'right' | 'both';

// Commands used to draw the rounded rectangle
// M - Marks the start of a new path
// v - Draws a vertical line
// h - Draws a horizontal line
// a - Draws an elliptical arc
// https://www.w3.org/TR/SVG/paths.html#PathDataEllipticalArcCommands

// drawRoundedRectangle returns the path value for a rounded rectangle svg
export const drawRoundedRectangle = (
  coordinates: Coordinates,
  size: Size,
  radius: number,
  roundedEdge: RoundedEdge
) => {
  let rect = `M${coordinates.x + radius},${coordinates.y}`; // move to starting position

  // draw horizontal line from top left to top right
  rect += `h${size.width - 2 * radius}`;
  // draw top right
  if (roundedEdge === 'right' || roundedEdge === 'both') {
    rect += `a${radius},${radius} 0 0 1 ${radius},${radius}`; // draw arc
  } else {
    rect += `h${radius}v${radius}`; // draw corner
  }

  // draw vertical line from top right to bottom right
  rect += `v${size.height - 2 * radius}`;
  // draw bottom right
  if (roundedEdge === 'right' || roundedEdge === 'both') {
    rect += `a${radius},${radius} 0 0 1 ${-radius},${radius}`; // draw arc
  } else {
    rect += `v${radius}h${-radius}`; // draw corner
  }

  // draw horizontal line from bottom right to bottom left
  rect += `h${2 * radius - size.width}`;
  // draw bottom left
  if (roundedEdge === 'left' || roundedEdge === 'both') {
    rect += `a${radius},${radius} 0 0 1 ${-radius},${-radius}`; // draw arc
  } else {
    rect += `h${-radius}v${-radius}`; // draw corner
  }

  // draw vertical line from bottom left to top left
  rect += `v${2 * radius - size.height}`;
  // draw top left
  if (roundedEdge === 'left' || roundedEdge === 'both') {
    rect += `a${radius},${radius} 0 0 1 ${radius},${-radius}`; // draw arc
  } else {
    rect += `v${-radius}h${radius}`; // draw corner
  }

  rect += 'z';

  return rect;
};

export function displayUnits({ value, units, isMetric }: ScaledValue, unitType?: string) {
  const spacing = isMetric ? ' ' : '';
  const display = `${value.toLocaleString('en-US')}${spacing}${units}`;
  return unitType?.includes('PER_SECOND') ? `${display}/s` : display;
}

export const round = (n: number, decimals = 0) => {
  if (decimals < 0) {
    decimals = 0;
  }

  const scale = 10 ** decimals;
  return Math.round(n * scale) / scale;
};

const SCALING_THRESHOLD = 100000;

const DATA_SCALE = 1024;
export enum DataUnit {
  B = 'B',
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
  TB = 'TB',
  PB = 'PB',
}

export const scaleData = (decimals = 0) => {
  const doScale = (num: number) => {
    if (num === 0) {
      return { value: 0, units: DataUnit.B, isMetric: true };
    }

    const idx =
      num < SCALING_THRESHOLD
        ? 0
        : Math.min(Math.floor(Math.log(num) / Math.log(DATA_SCALE)), Object.keys(DataUnit).length - 1);

    return {
      value: round(num / DATA_SCALE ** idx, decimals),
      units: Object.values(DataUnit)[idx],
      isMetric: true,
    };
  };

  const doConversion = (num: number, newUnits: DataUnit) => {
    if (num === 0) {
      return { value: 0, units: newUnits, isMetric: true };
    }
    const idx = Object.values(DataUnit).indexOf(newUnits);

    return {
      value: round(num / DATA_SCALE ** idx, decimals),
      units: newUnits,
      isMetric: true,
    };
  };

  const doScaleData = (num: number) => {
    return {
      toUnits: (newUnits: DataUnit) => doConversion(num, newUnits),
      auto: () => doScale(num),
    };
  };

  return {
    fromBytes: (bytes: number) => doScaleData(bytes),
    fromMegabytes: (megabytes: number) => doScaleData(megabytes * DATA_SCALE ** 2),
    fromGigabytes: (gigabytes: number) => doScaleData(gigabytes * DATA_SCALE ** 3),
  };
};

const TIME_SCALES = [1, 1000, 60, 60, 1000];
export enum TimeUnit {
  Milliseconds = 'ms',
  Seconds = 's',
  Minutes = 'min',
  Hours = 'hr',
  KiloHours = 'K⋅hr',
}

export const scaleTime = (decimals = 0) => {
  const doScale = (num: number) => {
    let exp = 0;
    let units = TimeUnit.Milliseconds;

    // find magnitude to scale value and determine units
    while (exp < TIME_SCALES.length && num >= TIME_SCALES[exp]) {
      num /= TIME_SCALES[exp];
      units = Object.values(TimeUnit)[exp];
      exp++;
    }

    return { value: round(num, decimals), units, isMetric: true };
  };

  const doConversion = (num: number, newUnits: TimeUnit) => {
    if (num === 0) {
      return { value: 0, units: newUnits, isMetric: true };
    }

    let exp = 0;
    let units = TimeUnit.Milliseconds;

    // continue scaling until correct units are found
    while (exp < Object.keys(TimeUnit).length && units !== newUnits) {
      num /= TIME_SCALES[exp];
      units = Object.values(TimeUnit)[exp];
      exp++;
    }

    return { value: round(num, decimals), units, isMetric: true };
  };

  const doScaleTime = (num: number) => {
    return {
      toUnits: (newUnits: TimeUnit) => doConversion(num, newUnits),
      auto: () => doScale(num),
    };
  };

  return {
    fromMillis: (millis: number) => doScaleTime(millis),
    fromSeconds: (seconds: number) => doScaleTime(seconds * TIME_SCALES[1]),
    fromMinutes: (minutes: number) => doScaleTime(minutes * TIME_SCALES[2] * TIME_SCALES[1]),
  };
};

const VALUE_SCALE = 1000;
export enum ValueUnit {
  None = '',
  K = 'K',
  M = 'M',
  G = 'G',
  T = 'T',
  P = 'P',
}

export const scaleValue = (decimals = 0) => {
  const doScale = (num: number) => {
    if (num === 0) {
      return { value: 0, units: ValueUnit.None };
    }

    // find magnitude to scale value and determine units
    const idx =
      num < SCALING_THRESHOLD
        ? 0
        : Math.min(Math.floor(Math.log(num) / Math.log(VALUE_SCALE)), Object.keys(ValueUnit).length - 1);

    return {
      value: round(num / VALUE_SCALE ** idx, decimals),
      units: Object.values(ValueUnit)[idx],
    };
  };

  const doConversion = (num: number, newUnits: ValueUnit) => {
    if (num === 0) {
      return { value: 0, units: newUnits };
    }

    const idx = Object.values(ValueUnit).indexOf(newUnits);

    return {
      value: round(num / VALUE_SCALE ** idx, decimals),
      units: newUnits,
    };
  };

  const doScaleValue = (num: number) => {
    return {
      toUnits: (newUnits: ValueUnit) => doConversion(num, newUnits),
      auto: () => doScale(num),
    };
  };

  return {
    fromOnes: (ones: number) => doScaleValue(ones),
  };
};

// Calculates time window for the specified period and granularity
export const calcTimeWindowForPeriod = (period: Period, granularity: Granularity): TimeWindow => {
  const end = new Date().getTime();
  const start = new Date(end - timeOptionMinutes[period] * 60 * 1000).getTime();
  return {
    start,
    end,
    period,
    granularity,
  };
};
