import { History, TransitionPromptHook } from 'history';
import { createAction } from 'redux-act';

import { AtlasGroup, Snippet, UserProfile } from 'admin-sdk';

import { AppsReqPayload } from './home/actions';
import { createActionsAndExecutor } from './redux_util';
import { getRouterState } from './selectors';
import { RootState } from './types';

const NAME = 'root/';

export const setHistory = createAction<History>('router/set history');
export const unblockNavigation = createAction('router/unblock navigation');
export const blockNavigation = createAction<TransitionPromptHook>('router/block navigation');
export const clearUserProfile = createAction(`${NAME}clear user profile`);
export const resetApp = createAction(`${NAME}reset app`);
export const clearAll = createAction(`${NAME}clear all app state`);
export const setHasAcknowledgedDarkMode = createAction(`${NAME}set has acknowledged dark mode`);

export const [getUserProfileActions, getUserProfile] = createActionsAndExecutor<{}, UserProfile>(
  `${NAME}get user profile`,
  (client) => () => client.userProfile()
);

export const [getAtlasGroupActions, getAtlasGroup] = createActionsAndExecutor<AppsReqPayload, AtlasGroup>(
  `${NAME}get atlas group`,
  (client, { groupId }) =>
    () =>
      client.private().group(groupId).get()
);

export const redirectTo = (path: string, options?: { replace?: boolean; state?: any }) => {
  return (_dispatch, getState: () => RootState) => {
    const { history } = getRouterState(getState());
    return options?.replace ? history!.replace(path, options.state) : history!.push(path, options?.state);
  };
};

export const [loadSnippetsAction, loadSnippets] = createActionsAndExecutor<{}, Snippet[]>(
  `${NAME}load snippets`,
  (client) => () => client.snippets().list()
);

export const setFirstAppSaveAction = createAction(`${NAME}set first app save`);
export const unsetFirstAppSaveAction = createAction(`${NAME}unset first app save`);
export const setFirstDraftSaveAction = createAction(`${NAME}set session first draft save`);
export const unsetFirstDraftSaveAction = createAction(`${NAME}unset session first draft save`);
