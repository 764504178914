import { redirectTo, resetApp } from 'baas-ui/actions';
import * as appActions from 'baas-ui/app/actions';
import { AsyncDispatch } from 'baas-ui/redux_util';
import * as svcActions from 'baas-ui/services/actions';
import * as syncActions from 'baas-ui/sync/actions';
import { DEFAULT_POLL_COUNT } from 'baas-ui/sync/constants';
import submitDestructiveRequest from 'baas-ui/sync/submit-confirmable-request';
import { TrackerLocation } from 'baas-ui/sync/submit-confirmable-request/constants';
import { rootUrl } from 'baas-ui/urls';
import { BypassServiceChangeValue } from 'admin-sdk';

import * as actions from './actions';

const deployHistoryUrl = (groupId: string, appId: string) =>
  rootUrl.groups().group(groupId).apps().app(appId).deploy().history().list();

export const discardDraft = (groupId: string, appId: string, draftId: string) => (dispatch: AsyncDispatch) =>
  dispatch(actions.deleteDraft({ groupId, appId, draftId })).then(() =>
    Promise.all([
      dispatch(redirectTo(deployHistoryUrl(groupId, appId))),
      dispatch(resetApp()),
      // reloading services is necessary as many parts of our app have
      // the assumption that services are loaded when the app initially starts - HK
      dispatch(svcActions.loadServices({ groupId, appId })),
      // reload the app config to reset the environment value in the side nav
      dispatch(appActions.loadApp({ groupId, appId })),
    ])
  );

export const deployDraft =
  ({
    groupId,
    appId,
    draftId,
    pushToGithub,
    deploymentName,
  }: actions.IndividualDraftReqPayload & { pushToGithub: boolean }) =>
  (dispatch: AsyncDispatch) => {
    const getSchemaVersions = () => dispatch(syncActions.loadSchemaVersions({ groupId, appId }));

    if (pushToGithub) {
      return submitDestructiveRequest(
        TrackerLocation.GitHubPush,
        (bypassWarning?: BypassServiceChangeValue) =>
          dispatch(actions.pushApp({ groupId, appId, draftId, bypassWarning, deploymentName })),
        { getSchemaVersions }
      ).then(() => {
        dispatch(syncActions.setProgressPollCount(DEFAULT_POLL_COUNT));

        // a destructive schema change may lead to a sync migration state change
        dispatch(syncActions.setMigrationStatusPollCount(DEFAULT_POLL_COUNT));
      });
    }

    const deployFunc = (bypassWarning?: BypassServiceChangeValue) =>
      dispatch(actions.deployDraft({ groupId, appId, draftId, bypassWarning, deploymentName }));

    return submitDestructiveRequest(TrackerLocation.Deploy, deployFunc, { getSchemaVersions }).then(() => {
      dispatch(syncActions.setProgressPollCount(DEFAULT_POLL_COUNT));

      // a destructive schema change may lead to a sync migration state change
      dispatch(syncActions.setMigrationStatusPollCount(DEFAULT_POLL_COUNT));
    });
  };

export default discardDraft;
