import React from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm';

import { ErrorCode } from 'baas-ui/constants';
import { SchemaModificationType, SyncSchemaChange } from 'baas-ui/sync/types';

import SchemaChangeModal from './schema-change-modal/SchemaChangeModal';

interface ModalProps {
  schemaChanges: SyncSchemaChange[];
  schemaChangeType: SchemaModificationType;
  latestSchemaVersionFunc(): Promise<number>;
  errorCode: ErrorCode;
}

type Props = ModalProps & ReactConfirmProps;

const SchemaModalWrapper = ({
  schemaChanges,
  schemaChangeType,
  latestSchemaVersionFunc,
  errorCode,
  show,
  proceed,
  cancel,
}: Props) => {
  const props = {
    schemaChanges,
    schemaChangeType,
    latestSchemaVersionFunc,
    errorCode,
    isOpen: show,
    onConfirm: () => proceed(),
    onCancel: () => cancel(),
  };

  return <SchemaChangeModal {...props} />;
};

export default createConfirmation(confirmable(SchemaModalWrapper));
