import React from 'react';
import Select from 'react-select';

import {
  DataSourceSelectOption,
  DataSourceSelectOptionStyles,
  DataSourceSelectOptionType,
} from 'baas-ui/common/components/data-source-select';
import { TemplateIdentifier } from 'baas-ui/home/create-app/types';

export enum AriaLabel {
  DataSourceSelect = 'data-source-select',
}
export interface Props {
  id: string;
  value?: DataSourceSelectOptionType;
  isDisabled?: boolean;
  name?: string;
  options: DataSourceSelectOptionType[];
  onChange(opt: DataSourceSelectOptionType): void;
  enabledOptions: DataSourceSelectOptionType[];
  selectedTemplate?: TemplateIdentifier;
}

const DataSourceSelect = ({
  id,
  name,
  value,
  isDisabled,
  options,
  enabledOptions,
  selectedTemplate = TemplateIdentifier.Default,
  onChange,
}: Props) => (
  <Select
    id={id}
    name={name}
    aria-label={AriaLabel.DataSourceSelect}
    placeholder="Select a Data Source"
    styles={DataSourceSelectOptionStyles}
    value={value}
    options={options}
    formatOptionLabel={(opt) => (
      <DataSourceSelectOption
        label={opt.label}
        value={opt.value}
        dataSourceUrl={opt.dataSourceUrl}
        type={opt.type}
        isMultiCloud={opt.isMultiCloud}
        isMultiRegion={opt.isMultiRegion}
        {...opt}
        selectedTemplate={selectedTemplate}
      />
    )}
    onChange={onChange}
    isDisabled={isDisabled}
    isOptionDisabled={(opt: DataSourceSelectOptionType) => !enabledOptions.includes(opt)}
    isClearable={false}
    isSearchable={false}
  />
);

export default DataSourceSelect;
