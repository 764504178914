import ExperimentSDKClient, { types } from '@mongodb-js/mdb-experiment-js';

import { abAssignmentOverridesKey } from 'baas-ui/common/local-storage-keys';
import { errorHandlerNotify } from 'baas-ui/error_util';
import { EventTracker } from 'baas-ui/tracking';
import { EventType } from 'baas-ui/tracking/events';
import { BaasEnvironment } from 'baas-ui/types';

import { ABTestGroup, ABTestName } from './constants';
import { ExpSdkType, ExpTypes } from './types';

let registeredExperimentManager: ExperimentManager;

const convertToSDKEnvironment = (baasEnvironment: BaasEnvironment) => {
  let sdkEnvironment: types.Environment;
  switch (baasEnvironment) {
    case BaasEnvironment.Prod:
      sdkEnvironment = types.Environment.PROD;
      break;
    case BaasEnvironment.QA:
      sdkEnvironment = types.Environment.QA;
      break;
    case BaasEnvironment.Dev:
      sdkEnvironment = types.Environment.DEV;
      break;
    default:
      sdkEnvironment = types.Environment.LOCAL;
      break;
  }

  return sdkEnvironment;
};

/**
 * ExperimentManager is our wrapper class around Atlas Growth's
 * experiment SDK.
 *
 * https://wiki.corp.mongodb.com/display/MMS/MDB+Experiment+JS+SDK+Usage
 */
class ExperimentManager {
  sdk: ExpSdkType | null;

  assignments: types.ServerAssignment<ABTestName, ABTestGroup>[];

  static init(environment: BaasEnvironment) {
    registeredExperimentManager = new ExperimentManager();
    registeredExperimentManager.init(environment);
  }

  static assign(experimentName: ABTestName) {
    registeredExperimentManager.assign(experimentName);
  }

  static getAssignment(
    experimentName: ABTestName,
    trackIsInSample: boolean
  ): Promise<types.ServerAssignment<ABTestName, ABTestGroup> | undefined> {
    return registeredExperimentManager.getAssignment(experimentName, trackIsInSample);
  }

  static trackIsInSample(experimentName: ABTestName) {
    registeredExperimentManager.trackIsInSample(experimentName);
  }

  static updateEntityIds(entityIds: types.EntityIds) {
    registeredExperimentManager.updateEntityIds(entityIds);
  }

  static setAssignments(assignments: types.ServerAssignment<ABTestName, ABTestGroup>[]) {
    registeredExperimentManager.setAssignments(assignments);
  }

  static clearAssignments() {
    registeredExperimentManager.clearAssignments();
  }

  constructor() {
    this.sdk = null;

    // handle local storage overrides
    try {
      const assignmentOverrides = localStorage.getItem(abAssignmentOverridesKey()) || '[]';
      this.assignments = JSON.parse(assignmentOverrides);
    } catch {
      this.assignments = [];
    }
  }

  init(environment: BaasEnvironment) {
    const sdkEnvironment = convertToSDKEnvironment(environment);

    // comment out this if block to test the SDK against a local MMS server
    if (sdkEnvironment === types.Environment.LOCAL) {
      return;
    }

    this.sdk = ExperimentSDKClient.create<ExpTypes>({
      entityIds: { orgId: '[PLACEHOLDER]' },
      environment: sdkEnvironment,
      trackerOptions: {
        tracker: {
          track: (options) => EventTracker.logEvent({ eventType: options.eventName }, options.properties),
        },
      },
    });
  }

  /**
   * Allocates the current entity to experiment specified by the
   * experimentName parameter
   *
   * Note: This function does not return the entity's actual assignment, use
   * getAssignment {@link getAssignment} to retrieve the entity's assignment
   * from the SDK.
   */
  assign(experimentName: ABTestName) {
    if (!this.sdk) {
      return;
    }

    try {
      this.sdk.assign(experimentName);
    } catch (err) {
      errorHandlerNotify(err);
    }
  }

  /**
   * Retrieves the entity's assignment to the experiment specified
   * by the experimentName parameter. Pass `true` for the trackIsInSample
   * parameter to record an "Experiment Viewed" event when getAssignment
   * is called.
   *
   * @returns either a {@link types.ServerAssignment} or undefined:
   * - ServerAssignment containing the entity's assigned test group if the entity
   * is assigned to the given experiment
   * - undefined if the SDK  assignments array does not already include
   */
  async getAssignment(
    experimentName: ABTestName,
    trackIsInSample: boolean
  ): Promise<types.ServerAssignment<ABTestName, ABTestGroup> | undefined> {
    // reference this.assignments array if assignment already fetched via
    // getAssignment or local storage override
    const experimentAssignment = this.assignments.find((assignment) => assignment.testName === experimentName);
    if (experimentAssignment) {
      return experimentAssignment;
    }

    // fetch assignment with SDK if assignment does not already exist in
    // this.assignments array and if SDK exists
    if (this.sdk) {
      try {
        const assignmentResponse = await this.sdk.getAssignment(experimentName, trackIsInSample);
        if (assignmentResponse) {
          this.assignments = [...this.assignments, assignmentResponse.experimentData];
          EventTracker.updateState({
            persistedProperties: {
              test_assignments: this.assignments.map((assignment) => assignment.testGroupId),
              experiment_ids: this.assignments.map((assignment) => assignment.testId),
            },
          });
        }
        return assignmentResponse?.experimentData;
      } catch (err) {
        errorHandlerNotify(err);
        return undefined;
      }
    }

    // return undefined if SDK or local override do not exist
    return undefined;
  }

  /**
   * Logs "Experiment Viewed" event to Segment if SDK exists; otherwise, logs
   * event to console.
   */
  trackIsInSample(experimentName: ABTestName) {
    if (!this.sdk) {
      const experimentData = this.assignments.find((assignment) => assignment.testName === experimentName);
      EventTracker.logEvent({ eventType: EventType.ExperimentViewed }, experimentData);
    } else {
      this.sdk.trackIsInSample(experimentName);
    }
  }

  /**
   * Updates and populates SDK's entity IDs used to make all function calls
   */
  updateEntityIds(entityIds: types.EntityIds) {
    if (!this.sdk) {
      return;
    }

    this.sdk.updateEntityIds(entityIds);
  }

  /**
   * Primarily used for manually setting assignments for unit testing.
   */
  setAssignments(assignments: types.ServerAssignment<ABTestName, ABTestGroup>[]) {
    this.assignments = assignments;
  }

  /**
   * Primarily used to clear assignment array when any of the entity IDs are
   * changed.
   */
  clearAssignments() {
    this.assignments = [];
  }
}

export default ExperimentManager;
