import React from 'react';
import Badge from '@leafygreen-ui/badge';
import Icon from '@leafygreen-ui/icon';
import { palette } from '@leafygreen-ui/palette';
import { colors } from '@leafygreen-ui/theme';
import Tooltip from '@leafygreen-ui/tooltip';
import { Description, Disclaimer, Link } from '@leafygreen-ui/typography';

import { mergeSelectStyles } from 'baas-ui/common/styles/ReactSelect.styles';
import { ZIndex } from 'baas-ui/common/styles/zIndex';
import { FLEX_CLUSTER_INSTANCE_SIZE, MONGODB_SYNC_MIN_VERSION, SERVERLESS_INSTANCE_SIZE } from 'baas-ui/constants';
import {
  dataSourceOptionIsEnabled,
  dataSourceOptionSupportsSync,
  templateAppUsesSync,
} from 'baas-ui/home/create-app/form/util';
import { TemplateIdentifier } from 'baas-ui/home/create-app/types';
import { ClusterState } from 'baas-ui/home/types';

import './data-source-select-option.less';

const selectContainerClassname = `data-source-select`;
const selectOptionClassname = `${selectContainerClassname}-option`;

export const DATA_FEDERATION_INSTANCE_NAME = 'Federated Database Instance';

export enum TestSelector {
  SelectOption = 'select-option',
  RecommendedBadge = 'recommended-badge',
  UpgradeBadge = 'upgrade-badge',
  WarningTooltip = 'warning-tooltip',
  UpdatingTooltip = 'updating-tooltip',
  ServiceDescription = 'service-description',
  LabelStart = 'label-start',
  LabelEnd = 'label-end',
  LabelTooltip = 'label-tooltip',
}

export const DataSourceSelectOptionStyles = mergeSelectStyles({
  option: (base) => ({
    ...base,
    display: 'flex',
    paddingRight: 40,
    cursor: 'default',
  }),
  menuList: (base) => ({
    ...base,
    overflowY: 'visible',
    maxHeight: 'max-content',
  }),
  singleValue: (base) => ({
    ...base,
    // We could use display: 'contents' to avoid this hard-coded value
    // but only few browsers have full support for it.
    // See https://caniuse.com/#feat=css-display-contents
    width: '96%',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '2px 0px 2px 8px',
  }),
});

export enum DataSourceType {
  DATA_FEDERATION = 'datalake',
  CLUSTER = 'cluster',
  ONLINE_ARCHIVE = 'online-archive',
}

const IneligibleDataSourceTooltip = ({ children }: React.PropsWithChildren<{}>) => (
  <Tooltip
    trigger={
      <span>
        <Icon glyph="Warning" size={12} fill={colors.mongodb.alertRed} title="Ineligible data source warning" />
      </span>
    }
    triggerEvent="hover"
    popoverZIndex={ZIndex.ToolTip}
  >
    {children}
  </Tooltip>
);

type OptionProps = {
  label: string;
  value: string;
  regionName?: string;
  state?: string;
  instanceSize?: string;
  backingProviderName?: string;
  mongoDBVersion?: string;
  paused?: boolean;
  upgradeUrl?: string;
  dataSourceUrl: string;
  type: DataSourceType;
  isMultiCloud: boolean;
  isMultiRegion: boolean;
};

export type Props = OptionProps & { selectedTemplate?: TemplateIdentifier };

const optIsFlexCluster = (opt: OptionProps): boolean => opt.instanceSize === FLEX_CLUSTER_INSTANCE_SIZE;
const optIsServerless = (opt: OptionProps): boolean => opt.instanceSize === SERVERLESS_INSTANCE_SIZE;

const buildOptionSubtext = (opt: OptionProps): string => {
  let provider = '';
  if (opt.isMultiCloud) {
    provider = 'Multi-Cloud, ';
  } else if (opt.backingProviderName) {
    provider = `${opt.backingProviderName}, `;
  }

  let type = '';
  if (opt.type === DataSourceType.DATA_FEDERATION) {
    type = DATA_FEDERATION_INSTANCE_NAME;
  } else if (opt.type === DataSourceType.CLUSTER) {
    if (optIsFlexCluster(opt)) {
      type = 'Flex Cluster';
    } else if (optIsServerless(opt)) {
      type = 'Serverless Instance';
    } else {
      type = 'Cluster';
    }
  }

  let state = '';
  if (opt.paused) {
    state = ' PAUSED';
  } else if (opt.state) {
    state = ` ${opt.state}`;
  }

  let region = '';
  if (opt.isMultiRegion) {
    region = ' (Multi-Region)';
  } else if (opt.regionName) {
    region = ` (${opt.regionName})`;
  }

  return `${provider}${type}${state}${region}`;
};

const optionTooltipAdditionalText = ({ selectedTemplate = TemplateIdentifier.Default, ...opt }: Props) => {
  if (opt.type === DataSourceType.DATA_FEDERATION && selectedTemplate !== TemplateIdentifier.Default) {
    return `. ${DATA_FEDERATION_INSTANCE_NAME}s cannot be used to create a template app.`;
  }

  if (optIsServerless(opt) && selectedTemplate !== TemplateIdentifier.Default) {
    return '. Serverless instances cannot be used to create a template app.';
  }

  if (
    opt.type === DataSourceType.CLUSTER &&
    !dataSourceOptionSupportsSync(opt) &&
    templateAppUsesSync(selectedTemplate)
  ) {
    return `. Clusters must run MongoDB ${MONGODB_SYNC_MIN_VERSION}+ to use this app template.`;
  }

  return '';
};

const DataSourceSelectOption = ({ selectedTemplate = TemplateIdentifier.Default, ...opt }: Props) => {
  let optionAdditionalInfo: JSX.Element | null = null;
  if (opt.instanceSize === 'M0' && !opt.isMultiCloud && !opt.isMultiRegion) {
    optionAdditionalInfo = (
      <Badge data-test-selector={TestSelector.RecommendedBadge} variant="blue">
        Recommended
      </Badge>
    );
  }

  if (!dataSourceOptionIsEnabled(opt)) {
    if (opt.type === DataSourceType.CLUSTER) {
      optionAdditionalInfo = (
        <IneligibleDataSourceTooltip data-test-selector={TestSelector.WarningTooltip}>
          {optIsServerless(opt) ? (
            <>
              Serverless instances which are being deleted/repaired cannot be used. Visit{' '}
              <Link href={opt.dataSourceUrl}>Atlas</Link> to manage your Serverless instance.
            </>
          ) : (
            <>
              Clusters which are being deleted/repaired or are paused cannot be used. Visit{' '}
              <Link href={opt.dataSourceUrl}>Atlas</Link> to manage your cluster.
            </>
          )}
        </IneligibleDataSourceTooltip>
      );
    } else if (opt.type === DataSourceType.DATA_FEDERATION) {
      optionAdditionalInfo = (
        <IneligibleDataSourceTooltip data-test-selector={TestSelector.WarningTooltip}>
          {`${DATA_FEDERATION_INSTANCE_NAME}s which are not active cannot be used. Visit `}
          <Link href={opt.dataSourceUrl}>Atlas</Link>
          {` to manage your ${DATA_FEDERATION_INSTANCE_NAME}.`}
        </IneligibleDataSourceTooltip>
      );
    }
  } else if (opt.type === DataSourceType.CLUSTER && opt.state === ClusterState.Updating) {
    optionAdditionalInfo = (
      <>
        {optionAdditionalInfo}
        <Tooltip
          data-test-selector={TestSelector.UpdatingTooltip}
          popoverZIndex={ZIndex.ToolTip}
          trigger={
            <span>
              <Icon glyph="InfoWithCircle" size={14} fill={palette.gray.dark3} title="Updating data source" />
            </span>
          }
          triggerEvent="hover"
        >
          This data source is currently updating. Some app features may not be available until updates are complete.
        </Tooltip>
      </>
    );
  }

  return (
    <div className={selectContainerClassname} data-testid={TestSelector.SelectOption}>
      <Tooltip
        className={`${selectOptionClassname}-tooltip`}
        data-test-selector={TestSelector.LabelTooltip}
        popoverZIndex={ZIndex.ToolTip}
        trigger={
          <div className={selectOptionClassname}>
            <span data-test-selector={TestSelector.LabelStart} className={`${selectOptionClassname}-label-start`}>
              {opt.label.substring(0, opt.label.length - 3)}
            </span>
            <span data-test-selector={TestSelector.LabelEnd}>{opt.label.substring(opt.label.length - 3)}</span>
          </div>
        }
      >
        {opt.label}
        {optionTooltipAdditionalText({ selectedTemplate, ...opt })}
      </Tooltip>
      <Description>
        <span className={`${selectOptionClassname}-subtext`} data-test-selector={TestSelector.ServiceDescription}>
          {buildOptionSubtext(opt)}
        </span>
      </Description>
      <div className={`${selectOptionClassname}-additional-info-container`}>
        {opt.type === DataSourceType.CLUSTER && opt.upgradeUrl && !dataSourceOptionSupportsSync(opt) && (
          <Disclaimer data-test-selector={TestSelector.UpgradeBadge}>
            <Link href={opt.upgradeUrl} className={`${selectOptionClassname}-link`}>
              Upgrade
            </Link>
          </Disclaimer>
        )}
        {optionAdditionalInfo}
      </div>
    </div>
  );
};

export default DataSourceSelectOption;
