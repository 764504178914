import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import Icon from '@leafygreen-ui/icon';
import IconButton from '@leafygreen-ui/icon-button';
import { Menu } from '@leafygreen-ui/menu';
import TextInput from '@leafygreen-ui/text-input';
import Toggle from '@leafygreen-ui/toggle';
import { Body, H3, Label } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import { CollectionExplorerContext } from 'baas-ui/common/components/collection-explorer/collection-explorer-context';
import { ServiceSyncConfig } from 'baas-ui/sync/types';
import { useDarkMode } from 'baas-ui/theme';
import { track } from 'baas-ui/tracking';
import { AppUrlType } from 'baas-ui/urls';

import './collection-explorer.less';

const baseClassName = 'collection-explorer';

enum AriaLabel {
  ToggleEmpty = 'toggle-empty',
}

export enum TestSelector {
  Container = 'collection-explorer-container',
  DataSourceRowHeader = 'data-source-header',
  DataSourceRowContent = 'data-source-content',
  DatabaseRowHeader = 'database-header',
  DatabaseRowContent = 'database-content',
  CollectionRowHeader = 'collection-header',
  CollectionRowLink = 'collection-row-link',
  SearchInput = 'search-input',
  UnconfiguredToggle = 'toggle-unconfigured',
  DefaultConfigButtonLink = 'default-config-button-link',
  PreventNavigationModal = 'prevent-navigation-modal',
}

export enum ResourceName {
  Schema = 'schema',
  Rules = 'rules',
}

export interface DataSourceData<T> {
  namespace: string;
  dataSourceName: string;
  dataSourceId: string;
  databases: DatabaseData<T>[];
  hasCollectionData: boolean;
}

export interface DatabaseData<T> {
  namespace: string;
  databaseName: string;
  collections: CollectionData<T>[];
  hasCollectionData: boolean;
}

export interface CollectionData<T> {
  namespace: string;
  collectionName: string;
  data: T;
}

export interface DefaultConfigButtonProps {
  dataSourceId: string;
  dataSourceName: string;
  updateCurrentDataSource: () => void;
}

export type DataSourceMenuProps = {
  appUrl: AppUrlType;
  dataSourceId: string;
  dataSourceName: string;
  syncConfig?: ServiceSyncConfig;
  setSelectedNamespace(): void;
  hasCollectionData: boolean;
} & Required<Pick<React.ComponentProps<typeof Menu>, 'trigger'>>;

export type DatabaseMenuProps = {
  dataSourceName: string;
  databaseName: string;
  hasCollectionData: boolean;
};

export type CollectionMenuProps<T> = {
  appUrl: AppUrlType;
  collectionData: CollectionData<T>;
  setSelectedNamespace(): void;
  onTriggerClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
};

interface PublicProps<T> {
  className?: string;
  appUrl: AppUrlType;
  resourceName: ResourceName;
  context: React.Context<CollectionExplorerContext<T>>;
  isCollectionConfigured: (data: CollectionData<T>) => boolean;
  dataSourceMenu: React.ComponentType<DataSourceMenuProps>;
  databaseMenu: React.ComponentType<DatabaseMenuProps>;
  collectionMenu: React.ComponentType<CollectionMenuProps<T>>;
  defaultConfigButtonLink?: (dataSourceName: string) => string;
  collectionRowLink: (collectionData: CollectionData<T>) => string;
  defaultConfigButton?: React.ComponentType<DefaultConfigButtonProps>;
  onUnconfiguredToggleChange?: (isChecked?: boolean) => void;
  shouldPreventNavigation: boolean;
  syncConfig?: ServiceSyncConfig;
  dataSourceDetailElement?: (dataSourceId: string) => React.ReactNode | void;
  collectionDetailElement?: (collectionData: CollectionData<T>) => React.ReactNode | void;
}

export type Props<T> = PublicProps<T>;

export default function CollectionExplorer<T>({
  className,
  appUrl,
  context,
  resourceName,
  dataSourceMenu: DataSourceMenu,
  isCollectionConfigured,
  databaseMenu: DatabaseMenu,
  collectionMenu: CollectionMenu,
  collectionRowLink,
  defaultConfigButton: DefaultConfigButton,
  shouldPreventNavigation,
  defaultConfigButtonLink = () => '',
  onUnconfiguredToggleChange = () => {},
  syncConfig,
  dataSourceDetailElement = () => {},
  collectionDetailElement = () => {},
}: Props<T>) {
  const { dataSources, selectedDataSourceData, selectedDatabaseData, selectedCollectionData, setSelectedNamespace } =
    useContext(context);

  const [searchValue, setSearchValue] = React.useState('');
  const [showEmpty, setShowEmpty] = React.useState(true);
  const [collapsedDataSources, setCollapsedDataSources] = React.useState(new Set());
  const [collapsedDatabases, setCollapsedDatabases] = React.useState(new Set());
  const [showPreventNavigationModal, setShowPreventNavigationModal] = React.useState(false);
  const [selectedCollectionKey, setSelectedCollectionKey] = React.useState<string>();

  function handleToggleDataSource(dataSourceName: string) {
    return (e: React.MouseEvent) => {
      if (e.target !== e.currentTarget) {
        return;
      }

      const newDataSources = new Set([...collapsedDataSources]);
      if (newDataSources.has(dataSourceName)) {
        newDataSources.delete(dataSourceName);
      } else {
        newDataSources.add(dataSourceName);
      }

      setCollapsedDataSources(newDataSources);
    };
  }

  function handleToggleDatabase(databaseKey: string) {
    return (e: React.MouseEvent) => {
      if (e.target !== e.currentTarget) {
        return;
      }

      const newDatabases = new Set([...collapsedDatabases]);
      if (newDatabases.has(databaseKey)) {
        newDatabases.delete(databaseKey);
      } else {
        newDatabases.add(databaseKey);
      }

      setCollapsedDatabases(newDatabases);
    };
  }

  const darkMode = useDarkMode();
  return (
    <div className={`${baseClassName} ${className}`} data-testid={TestSelector.Container}>
      <ConfirmationModal
        className={`${baseClassName}-discard-changes-modal`}
        open={showPreventNavigationModal}
        confirmButtonProps={{
          children: 'OK',
          onClick: () => {
            setShowPreventNavigationModal(false);
            selectedCollectionKey && setSelectedNamespace(selectedCollectionKey);
          },
        }}
        cancelButtonProps={{
          onClick: () => {
            setShowPreventNavigationModal(false);
            setSelectedCollectionKey('');
          },
        }}
        title={`Discard changes to ${resourceName}`}
        variant="danger"
        data-testid={TestSelector.PreventNavigationModal}
        data-cy={`${baseClassName}-prevent-navigation-modal`}
      >
        Are you sure you want to navigate away and discard your changes to {resourceName}?
      </ConfirmationModal>
      <div className="view-controls">
        <div className="view-controls__row">
          <TextInput
            className="view-controls__input"
            aria-labelledby="search-input"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search for collection"
            data-test-selector={TestSelector.SearchInput}
          />
          <Icon className="view-controls__icon" glyph="MagnifyingGlass" />
        </div>
        <div className="view-controls__row">
          <Label className="view-controls__label" htmlFor={AriaLabel.ToggleEmpty} id={AriaLabel.ToggleEmpty}>
            Show collections without {resourceName === ResourceName.Schema ? 'a ' : ''}
            {resourceName}
          </Label>
          <Toggle
            data-cy={TestSelector.UnconfiguredToggle}
            data-test-selector={TestSelector.UnconfiguredToggle}
            className="view-controls__toggle"
            id={AriaLabel.ToggleEmpty}
            checked={showEmpty}
            onChange={(isChecked) => {
              setShowEmpty(isChecked);
              onUnconfiguredToggleChange(isChecked);
            }}
            size="xsmall"
            aria-labelledby={AriaLabel.ToggleEmpty}
          />
        </div>
      </div>
      <ul className={`${baseClassName}-list`}>
        {dataSources.map(({ dataSourceId, dataSourceName, databases, namespace: dataSourceKey, hasCollectionData }) => {
          const isDataSourceSelected = selectedDataSourceData?.dataSourceName === dataSourceName;
          return (
            <li className={`${baseClassName}-list-item`} key={dataSourceKey} data-cy={`${baseClassName}-data-source`}>
              <div
                data-cy={`data-source-header-${dataSourceKey}`}
                data-test-selector={`${TestSelector.DataSourceRowHeader}-${dataSourceKey}`}
                data-testid={`${TestSelector.DataSourceRowHeader}-${dataSourceKey}`}
                className={classNames(
                  `${baseClassName}-list-item__content ${baseClassName}-list-item__content--top-level`,
                  {
                    [`${baseClassName}-list-item__content--collapsed`]: collapsedDataSources.has(dataSourceName),
                    [`${baseClassName}-list-item__content-light-mode`]: !darkMode,
                    [`${baseClassName}-list-item__content-dark-mode`]: darkMode,
                  }
                )}
                onClick={handleToggleDataSource(dataSourceKey)}
              >
                <Icon
                  className={`${baseClassName}-list-item__toggle`}
                  glyph={collapsedDataSources.has(dataSourceName) ? 'ChevronRight' : 'ChevronDown'}
                  size="small"
                  onClick={handleToggleDataSource(dataSourceKey)}
                />
                <H3
                  className={classNames(`${baseClassName}-list-item__title`, {
                    [`${baseClassName}-list-item__title--selected`]: isDataSourceSelected,
                  })}
                  onClick={handleToggleDataSource(dataSourceKey)}
                >
                  {dataSourceName}
                </H3>
                {dataSourceDetailElement && dataSourceDetailElement(dataSourceId)}
                <DataSourceMenu
                  appUrl={appUrl}
                  dataSourceId={dataSourceId}
                  dataSourceName={dataSourceName}
                  setSelectedNamespace={() => setSelectedNamespace(dataSourceName)}
                  hasCollectionData={hasCollectionData}
                  trigger={
                    <IconButton
                      className={`${baseClassName}-list-item__menu-trigger`}
                      aria-label="Data Source Options"
                      data-cy="data-source-menu-trigger"
                    >
                      <Icon glyph="Ellipsis" />
                    </IconButton>
                  }
                  syncConfig={syncConfig}
                />
              </div>
              {!collapsedDataSources.has(dataSourceName) && (
                <ul
                  className={`${baseClassName}-list`}
                  data-test-selector={`${TestSelector.DataSourceRowContent}-${dataSourceKey}`}
                >
                  {!!DefaultConfigButton && (
                    <Link
                      key={dataSourceId}
                      className={`${baseClassName}-additional-rows--link`}
                      to={defaultConfigButtonLink(dataSourceName)}
                      data-cy={`${baseClassName}-additional-rows-link-${dataSourceName}`}
                      data-test-selector={`${TestSelector.DefaultConfigButtonLink}-${dataSourceName}`}
                    >
                      <div className={`${baseClassName}-additional-rows`}>
                        <DefaultConfigButton
                          dataSourceId={dataSourceId}
                          dataSourceName={dataSourceName}
                          updateCurrentDataSource={() => {
                            setSelectedNamespace(dataSourceName);
                            setSelectedCollectionKey(undefined);
                          }}
                        />
                      </div>
                    </Link>
                  )}
                  {databases.map(
                    ({ databaseName, collections, namespace: databaseKey, hasCollectionData: dbHasCollectionData }) => {
                      const isDatabaseSelected =
                        isDataSourceSelected && selectedDatabaseData?.databaseName === databaseName;
                      const headerTestSelector = `${TestSelector.DatabaseRowHeader}-${databaseKey}`;
                      return (
                        <li key={databaseKey} data-cy={`${baseClassName}-database-${databaseName}`}>
                          <div
                            data-test-selector={headerTestSelector}
                            data-testid={headerTestSelector}
                            className={classNames(
                              `${baseClassName}-list-item__content ${baseClassName}-list-item__content--middle-level`,
                              {
                                [`${baseClassName}-list-item__content-light-mode`]: !darkMode,
                                [`${baseClassName}-list-item__content-dark-mode`]: darkMode,
                              }
                            )}
                            onClick={handleToggleDatabase(databaseKey)}
                          >
                            <Icon
                              className={`${baseClassName}-list-item__toggle`}
                              glyph={collapsedDatabases.has(databaseKey) ? 'ChevronRight' : 'ChevronDown'}
                              size="small"
                              onClick={handleToggleDatabase(databaseKey)}
                            />
                            <Body
                              className={classNames(`${baseClassName}-list-item__title`, {
                                [`${baseClassName}-list-item__title--selected`]: isDatabaseSelected,
                              })}
                              onClick={handleToggleDatabase(databaseKey)}
                            >
                              {databaseName}
                            </Body>
                            <DatabaseMenu
                              dataSourceName={dataSourceName}
                              databaseName={databaseName}
                              hasCollectionData={dbHasCollectionData}
                            />
                          </div>
                          {!collapsedDatabases.has(databaseKey) && (
                            <ul
                              className={`${baseClassName}-list`}
                              data-test-selector={`${TestSelector.DatabaseRowContent}-${databaseKey}`}
                            >
                              {collections.map((collectionData) => {
                                const { collectionName, namespace: collectionKey } = collectionData;
                                const isCollectionSelected =
                                  isDataSourceSelected &&
                                  isDatabaseSelected &&
                                  selectedCollectionData?.collectionName === collectionName;

                                if (!isCollectionConfigured(collectionData) && !showEmpty) {
                                  return null;
                                }

                                if (searchValue && !collectionName.startsWith(searchValue)) {
                                  return null;
                                }

                                return (
                                  <Link
                                    data-testid={`${TestSelector.CollectionRowLink}-${collectionKey}`}
                                    key={collectionKey}
                                    className={`${baseClassName}-list-item--link`}
                                    to={collectionRowLink(collectionData)}
                                    onClick={(e) => {
                                      if (shouldPreventNavigation) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                      }
                                    }}
                                    data-cy={`${baseClassName}-collection-${collectionName}`}
                                  >
                                    <li
                                      data-cy={`collection-header-${dataSourceName}-${databaseName}-${collectionName}`}
                                      data-test-selector={`${TestSelector.CollectionRowHeader}-${collectionKey}`}
                                      data-testid={`${TestSelector.CollectionRowHeader}-${collectionKey}`}
                                      onClick={(e) => {
                                        if (collectionKey === selectedCollectionKey) {
                                          return;
                                        }
                                        setSelectedCollectionKey(collectionKey);
                                        if (shouldPreventNavigation) {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setShowPreventNavigationModal(true);
                                        } else setSelectedNamespace(collectionKey);
                                      }}
                                    >
                                      <div
                                        className={classNames(
                                          `${baseClassName}-list-item__content 
                                          ${baseClassName}-list-item__content--bottom-level`,
                                          {
                                            [`${baseClassName}-list-item__content-light-mode`]: !darkMode,
                                            [`${baseClassName}-list-item__content-dark-mode`]: darkMode,
                                          }
                                        )}
                                      >
                                        <Body
                                          className={classNames(`${baseClassName}-list-item__title`, {
                                            [`${baseClassName}-list-item__title--selected`]: isCollectionSelected,
                                            [`${baseClassName}-list-item__title--unconfigured`]:
                                              !isCollectionConfigured(collectionData),
                                          })}
                                        >
                                          {collectionName}
                                        </Body>
                                        {collectionDetailElement && collectionDetailElement(collectionData)}
                                        <CollectionMenu
                                          appUrl={appUrl}
                                          collectionData={collectionData}
                                          setSelectedNamespace={() => setSelectedNamespace(collectionKey)}
                                          onTriggerClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            switch (resourceName) {
                                              case ResourceName.Rules:
                                                track('RULES_CONFIGURATION.CONFIGURE_COLLECTION_VIEWED');
                                                break;
                                              case ResourceName.Schema:
                                                track('SCHEMAS.CONFIGURE_COLLECTION_VIEWED');
                                                break;
                                              default:
                                                break;
                                            }
                                          }}
                                        />
                                      </div>
                                    </li>
                                  </Link>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    }
                  )}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
