import React from 'react';

const Icon = () => (
  <svg width="201" height="175" viewBox="0 0 201 175" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M68.6814 45.5643L64.2701 20.1412C63.803 17.4544 62.8074 14.887 61.3409 12.5878C59.8744 10.2886 57.9663 8.30331 55.727 6.74692C53.4876 5.19054 50.9617 4.09402 48.2956 3.52091C45.6294 2.94781 42.876 2.90951 40.1949 3.40824L39.3732 3.5597C34.1116 4.53752 29.4368 7.52397 26.3379 11.8872C23.239 16.2504 21.9591 21.648 22.7691 26.9381L22.841 27.3963L20.9969 27.6803L20.9288 27.2183C20.0458 21.4503 21.4412 15.5651 24.8199 10.8076C28.1986 6.0501 33.2956 2.79359 39.0325 1.72699L39.8541 1.57553C42.7777 1.03211 45.7801 1.0742 48.6873 1.69939C51.5945 2.32457 54.3488 3.52041 56.7906 5.21763C59.2323 6.91485 61.313 9.07972 62.9122 11.5868C64.5113 14.0939 65.5971 16.8934 66.1066 19.8231L70.5179 45.2425L68.6814 45.5643Z"
      fill="#B1FF05"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M140.498 73.5587C140.499 71.4341 140.081 69.3302 139.268 67.3671C138.455 65.4041 137.264 63.6204 135.762 62.1179C134.259 60.6154 132.476 59.4235 130.513 58.6103C128.55 57.7972 126.446 57.3786 124.322 57.3786C124.322 50.2409 121.962 43.3035 117.609 37.6465C113.257 31.9895 107.156 27.9305 100.256 26.1015C93.3571 24.2726 86.0466 24.7763 79.4632 27.5342C72.8799 30.2921 67.3932 35.1495 63.8576 41.35C62.9685 41.2529 62.0748 41.2036 61.1804 41.2023C54.7971 41.2821 48.7023 43.8738 44.2165 48.4159C39.7307 52.9581 37.2153 59.0848 37.2153 65.4687C37.2153 71.8525 39.7307 77.9792 44.2165 82.5214C48.7023 87.0636 54.7971 89.6553 61.1804 89.735H140.498C142.643 89.735 144.7 88.8829 146.217 87.3661C147.734 85.8492 148.586 83.792 148.586 81.6469C148.586 79.5018 147.734 77.4445 146.217 75.9277C144.7 74.4109 142.643 73.5587 140.498 73.5587Z"
      fill="#001E2B"
    />
    <path
      d="M140.498 73.5587C140.499 71.4341 140.081 69.3302 139.268 67.3671C138.455 65.4041 137.264 63.6204 135.762 62.1179C134.259 60.6154 132.476 59.4235 130.513 58.6103C128.55 57.7972 126.446 57.3786 124.322 57.3786C124.322 50.2409 121.962 43.3035 117.609 37.6465C113.257 31.9895 107.156 27.9305 100.256 26.1015C93.3571 24.2726 86.0466 24.7763 79.4632 27.5342C72.8799 30.2921 67.3932 35.1495 63.8576 41.35C62.9685 41.2529 62.0748 41.2036 61.1804 41.2023C54.7971 41.2821 48.7023 43.8738 44.2165 48.4159C39.7307 52.9581 37.2153 59.0848 37.2153 65.4687C37.2153 71.8525 39.7307 77.9792 44.2165 82.5214C48.7023 87.0636 54.7971 89.6553 61.1804 89.735H140.498C142.643 89.735 144.7 88.8829 146.217 87.3661C147.734 85.8492 148.586 83.792 148.586 81.6469C148.586 79.5018 147.734 77.4445 146.217 75.9277C144.7 74.4109 142.643 73.5587 140.498 73.5587Z"
      stroke="#001E2B"
      strokeMiterlimit="10"
    />
    <path
      d="M140.498 69.0148C140.499 66.8901 140.081 64.7862 139.268 62.8232C138.455 60.8602 137.264 59.0765 135.762 57.5739C134.259 56.0714 132.476 54.8796 130.513 54.0664C128.55 53.2532 126.446 52.8347 124.322 52.8347C124.322 45.697 121.962 38.7595 117.609 33.1026C113.257 27.4456 107.156 23.3866 100.256 21.5576C93.3571 19.7286 86.0466 20.2323 79.4632 22.9903C72.8799 25.7482 67.3932 30.6056 63.8576 36.8061C62.9685 36.709 62.0748 36.6597 61.1804 36.6584C54.7971 36.7381 48.7023 39.3298 44.2165 43.872C39.7307 48.4142 37.2153 54.5409 37.2153 60.9247C37.2153 67.3086 39.7307 73.4353 44.2165 77.9775C48.7023 82.5196 54.7971 85.1113 61.1804 85.1911H140.498C142.643 85.1911 144.7 84.3389 146.217 82.8221C147.734 81.3053 148.586 79.248 148.586 77.1029C148.586 74.9578 147.734 72.9006 146.217 71.3837C144.7 69.8669 142.643 69.0148 140.498 69.0148Z"
      fill="white"
    />
    <path
      d="M140.498 69.0148C140.499 66.8901 140.081 64.7862 139.268 62.8232C138.455 60.8602 137.264 59.0765 135.762 57.5739C134.259 56.0714 132.476 54.8796 130.513 54.0664C128.55 53.2532 126.446 52.8347 124.322 52.8347C124.322 45.697 121.962 38.7595 117.609 33.1026C113.257 27.4456 107.156 23.3866 100.256 21.5576C93.3571 19.7286 86.0466 20.2323 79.4632 22.9903C72.8799 25.7482 67.3932 30.6056 63.8576 36.8061C62.9685 36.709 62.0748 36.6597 61.1804 36.6584C54.7971 36.7381 48.7023 39.3298 44.2165 43.872C39.7307 48.4142 37.2153 54.5409 37.2153 60.9247C37.2153 67.3086 39.7307 73.4353 44.2165 77.9775C48.7023 82.5196 54.7971 85.1113 61.1804 85.1911H140.498C142.643 85.1911 144.7 84.3389 146.217 82.8221C147.734 81.3053 148.586 79.248 148.586 77.1029C148.586 74.9578 147.734 72.9006 146.217 71.3837C144.7 69.8669 142.643 69.0148 140.498 69.0148Z"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M77.6177 74.403C77.6169 75.0079 77.4368 75.5991 77.1002 76.1017C76.7635 76.6044 76.2854 76.9959 75.7263 77.2269C75.1672 77.4579 74.5521 77.518 73.9589 77.3995C73.3656 77.281 72.8208 76.9893 72.3933 76.5613C71.9658 76.1332 71.6748 75.5881 71.557 74.9947C71.4393 74.4013 71.5001 73.7863 71.7318 73.2274C71.9635 72.6686 72.3556 72.191 72.8587 71.855C73.3617 71.5189 73.9531 71.3396 74.5581 71.3396C74.9602 71.3396 75.3584 71.4189 75.7298 71.5729C76.1013 71.7269 76.4387 71.9526 76.7229 72.2371C77.007 72.5216 77.2323 72.8593 77.3858 73.2309C77.5394 73.6026 77.6182 74.0008 77.6177 74.403ZM74.5581 72.9186C74.2645 72.9186 73.9775 73.0057 73.7334 73.1688C73.4893 73.3319 73.2991 73.5637 73.1867 73.8349C73.0744 74.1061 73.045 74.4046 73.1023 74.6925C73.1595 74.9805 73.3009 75.245 73.5085 75.4525C73.7161 75.6601 73.9806 75.8015 74.2685 75.8588C74.5564 75.916 74.8549 75.8866 75.1261 75.7743C75.3974 75.662 75.6292 75.4717 75.7923 75.2276C75.9554 74.9835 76.0424 74.6965 76.0424 74.403C76.0424 74.0093 75.8861 73.6317 75.6077 73.3534C75.3293 73.075 74.9518 72.9186 74.5581 72.9186Z"
      fill="#B1FF05"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M93.0065 136.681L89.7008 135.878C86.9104 135.204 84.3955 133.686 82.4984 131.532C80.6013 129.377 79.4141 126.69 79.0983 123.837L73.6797 74.6492C73.653 74.4213 73.7177 74.1921 73.8597 74.0118C74.0016 73.8316 74.2092 73.7149 74.437 73.6874C74.6652 73.6617 74.8943 73.7266 75.0749 73.8683C75.2556 74.01 75.3733 74.217 75.4026 74.4447L80.8326 123.625C81.1088 126.124 82.1477 128.478 83.8081 130.367C85.4685 132.256 87.67 133.588 90.1135 134.182L93.4154 134.985L93.0065 136.681Z"
      fill="#B1FF05"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path d="M180.271 68.6055L98.9038 65.124L96.3204 125.503L177.688 128.984L180.271 68.6055Z" fill="black" />
    <path
      d="M184.057 65.0937L102.689 61.6123L100.106 121.991L181.474 125.472L184.057 65.0937Z"
      fill="#E3FCF7"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M148.641 80.382L149.397 80.4143C150.353 80.4552 151.252 80.8738 151.899 81.5782C152.545 82.2825 152.886 83.2148 152.845 84.17L151.454 116.682L143.498 116.342L144.889 83.8296C144.93 82.875 145.348 81.9758 146.051 81.3293C146.755 80.6829 147.686 80.3421 148.641 80.382Z"
      fill="#00ED64"
    />
    <path
      d="M161.444 73.973L162.201 74.0054C163.156 74.0463 164.056 74.4649 164.702 75.1692C165.349 75.8736 165.689 76.8059 165.648 77.761L163.961 117.204L156.016 116.864L157.704 77.4211C157.745 76.4685 158.161 75.5709 158.862 74.9247C159.563 74.2785 160.492 73.9363 161.444 73.973Z"
      fill="#00684A"
    />
    <path
      d="M135.703 90.0116L136.46 90.044C137.415 90.0848 138.315 90.5035 138.961 91.2078C139.608 91.9121 139.948 92.8444 139.907 93.7996L138.951 116.147L131.006 115.807L131.963 93.4597C132.003 92.5045 132.422 91.6047 133.126 90.9581C133.831 90.3116 134.763 89.9714 135.718 90.0123L135.703 90.0116Z"
      fill="#B1FF05"
    />
    <path
      d="M122.48 95.0853L123.236 95.1177C124.192 95.1586 125.091 95.5772 125.738 96.2815C126.384 96.9858 126.725 97.9181 126.684 98.8733L125.968 115.606L118.023 115.266L118.74 98.5183C118.781 97.5631 119.199 96.6633 119.904 96.0167C120.608 95.3702 121.54 95.03 122.496 95.0708L122.48 95.0853Z"
      fill="white"
    />
    <path d="M175.925 117.737L108.13 114.836L109.974 71.7751" stroke="black" strokeMiterlimit="10" />
    <path d="M140.377 73.0693L118.529 72.1345L118.411 74.9L140.259 75.8348L140.377 73.0693Z" fill="#E3FCF7" />
    <path d="M132.636 77.354L118.332 76.7419L118.214 79.5074L132.518 80.1194L132.636 77.354Z" fill="#E3FCF7" />
    <path d="M195.873 114.804L115.28 103.076L106.577 162.88L187.17 174.608L195.873 114.804Z" fill="black" />
    <path
      d="M10.6294 108.304L13.28 124.935C14.1244 130.236 25.4615 132.845 38.6009 130.751C51.7404 128.657 61.6991 122.659 60.8623 117.354C61.7067 122.655 51.7404 128.661 38.6009 130.751C25.4615 132.841 14.1244 130.244 13.28 124.935L13.7344 127.794C14.5788 133.095 25.9159 135.704 39.0553 133.61C52.1948 131.516 62.1535 125.518 61.3167 120.213L58.2117 100.72L10.6294 108.304Z"
      fill="black"
    />
    <path
      d="M10.5117 107.57L13.185 124.341C14.0295 129.642 25.3665 132.251 38.506 130.157C51.6454 128.063 61.6042 122.065 60.7673 116.756L58.094 99.989L10.5117 107.57Z"
      fill="#00ED64"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M35.8282 113.39C48.9673 111.298 58.9335 105.3 58.0884 99.9943C57.2434 94.6887 45.907 92.0842 32.7679 94.177C19.6289 96.2698 9.6626 102.267 10.5077 107.573C11.3527 112.879 22.6891 115.483 35.8282 113.39Z"
      fill="white"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M34.2979 103.784C47.437 101.692 57.4032 95.694 56.5582 90.3884C55.7131 85.0828 44.3767 82.4783 31.2377 84.571C18.0986 86.6638 8.13233 92.6614 8.9774 97.967C9.82248 103.273 21.1589 105.877 34.2979 103.784Z"
      fill="#B1FF05"
      stroke="black"
      strokeWidth="1.48"
      strokeMiterlimit="10"
    />
    <path
      d="M29.4751 101.155L30.2854 106.233C30.4445 107.244 32.5006 107.748 34.8748 107.369C37.249 106.99 39.04 105.854 38.8848 104.851L38.0782 99.7732L29.4751 101.155Z"
      fill="#B1FF05"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M5.87695 78.4737L8.98195 97.9633C9.82636 103.264 21.1634 105.873 34.3029 103.779C47.4423 101.685 57.4011 95.6875 56.5642 90.3825L53.4592 70.8892L5.87695 78.4737Z"
      fill="#00ED64"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M31.1954 84.2948C44.3344 82.2021 54.3007 76.2045 53.4556 70.8989C52.6106 65.5933 41.2742 62.9888 28.1351 65.0815C14.996 67.1743 5.02979 73.1719 5.87487 78.4775C6.71994 83.7831 18.0563 86.3876 31.1954 84.2948Z"
      fill="white"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M24.814 71.9115L25.6243 76.9931C25.7871 78.0004 27.8432 78.5078 30.2174 78.1291C32.5916 77.7504 34.3827 76.6145 34.2274 75.6148L33.4171 70.5332L24.814 71.9115Z"
      fill="#B1FF05"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M1.23877 49.3737L4.34377 68.8633C5.19197 74.1645 16.529 76.7772 29.6685 74.6833C42.8079 72.5893 52.7667 66.5875 51.926 61.2825L48.84 41.793L1.23877 49.3737Z"
      fill="#00ED64"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M26.5587 55.1925C39.6977 53.0998 49.664 47.1022 48.8189 41.7966C47.9738 36.491 36.6375 33.8865 23.4984 35.9792C10.3593 38.072 0.393074 44.0696 1.23815 49.3752C2.08322 54.6808 13.4196 57.2853 26.5587 55.1925Z"
      fill="#B1FF05"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M50.3542 51.3994C51.2024 56.7006 41.2361 62.7062 28.0967 64.8001C14.9572 66.8941 3.62017 64.289 2.77197 58.9839"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M55.0686 81.0029C55.913 86.3042 45.9467 92.3059 32.8072 94.3999C19.6678 96.4939 8.32316 93.8887 7.48633 88.5837"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M59.7869 110.622C60.6313 115.923 50.665 121.929 37.5255 124.023C24.386 126.117 13.049 123.496 12.2046 118.195"
      fill="#00ED64"
    />
    <path
      d="M59.7869 110.622C60.6313 115.923 50.665 121.929 37.5255 124.023C24.386 126.117 13.049 123.496 12.2046 118.195"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M33.023 104.113L33.5644 107.502C33.5644 107.502 30.4367 107.615 30.2171 106.237L29.9331 104.457L33.023 104.113Z"
      fill="black"
      stroke="black"
      strokeWidth="0.52"
      strokeMiterlimit="10"
    />
    <path
      d="M28.0552 74.8838L28.5967 78.2728C28.5967 78.2728 25.4689 78.3864 25.2493 77.0081L24.9653 75.2284L28.0552 74.8838Z"
      fill="black"
      stroke="black"
      strokeWidth="0.52"
      strokeMiterlimit="10"
    />
    <path d="M120.56 98.9225L111.911 158.352L192.129 170.026L200.778 110.597L120.56 98.9225Z" fill="white" />
    <path
      d="M120.728 99.1446L200.572 110.766L191.977 169.836L112.133 158.212L120.728 99.1597M120.406 98.728L111.697 158.518L192.291 170.257L201 110.451L120.406 98.7129V98.728Z"
      fill="black"
    />
    <path
      d="M150.945 140.04L159.03 143.807C159.886 141.58 160.347 139.219 160.389 136.832L151.494 136.204C151.53 137.504 151.344 138.801 150.945 140.04Z"
      fill="#00684A"
    />
    <path
      d="M150.945 140.04C150.309 141.996 149.161 143.747 147.62 145.11C146.08 146.474 144.203 147.401 142.184 147.796C140.165 148.191 138.077 148.039 136.137 147.355C134.196 146.672 132.474 145.483 131.147 143.91C129.821 142.337 128.939 140.439 128.593 138.411C128.247 136.383 128.449 134.299 129.179 132.376C129.908 130.452 131.139 128.758 132.743 127.47C134.347 126.182 136.266 125.346 138.302 125.049L135.973 116.419C132.484 117.109 129.236 118.697 126.549 121.026C123.862 123.355 121.828 126.345 120.649 129.7C119.471 133.055 119.187 136.659 119.827 140.157C120.467 143.655 122.008 146.926 124.298 149.646C126.588 152.367 129.548 154.443 132.886 155.67C136.224 156.897 139.824 157.232 143.331 156.642C146.838 156.053 150.13 154.559 152.883 152.308C155.636 150.057 157.755 147.127 159.03 143.807L150.945 140.04Z"
      fill="#A6FFEC"
    />
    <path
      d="M148.689 128.899L155.406 123.068C153.041 120.344 149.998 118.294 146.585 117.127C143.172 115.959 139.511 115.715 135.973 116.419L138.302 125.049C140.217 124.766 142.173 124.971 143.988 125.644C145.804 126.317 147.42 127.437 148.689 128.899Z"
      fill="#00ED64"
    />
    <path
      d="M151.509 136.204L160.404 136.832C160.504 131.783 158.722 126.877 155.406 123.068L148.688 128.899C150.456 130.928 151.454 133.514 151.509 136.204Z"
      fill="#B1FF05"
    />
    <path d="M192.172 142.694L170.504 139.629L170.177 141.942L191.844 145.006L192.172 142.694Z" fill="#A6FFEC" />
    <path d="M182.58 114L132.552 106.72L132.058 110.115L182.085 117.395L182.58 114Z" fill="#A6FFEC" />
    <path d="M191.351 148.485L169.683 145.421L169.356 147.734L191.024 150.798L191.351 148.485Z" fill="#A6FFEC" />
    <path d="M190.534 154.277L168.867 151.213L168.54 153.526L190.207 156.59L190.534 154.277Z" fill="#A6FFEC" />
    <path d="M188.983 160.882L167.324 157.73L166.988 160.042L188.646 163.194L188.983 160.882Z" fill="#A6FFEC" />
    <path
      d="M166.398 142.228C167.044 142.228 167.568 141.704 167.568 141.058C167.568 140.412 167.044 139.888 166.398 139.888C165.752 139.888 165.228 140.412 165.228 141.058C165.228 141.704 165.752 142.228 166.398 142.228Z"
      fill="#00ED64"
    />
    <path
      d="M165.554 148.011C166.2 148.011 166.724 147.487 166.724 146.84C166.724 146.194 166.2 145.67 165.554 145.67C164.908 145.67 164.384 146.194 164.384 146.84C164.384 147.487 164.908 148.011 165.554 148.011Z"
      fill="#B1FF05"
    />
    <path
      d="M164.713 153.796C165.359 153.796 165.883 153.273 165.883 152.626C165.883 151.98 165.359 151.456 164.713 151.456C164.067 151.456 163.543 151.98 163.543 152.626C163.543 153.273 164.067 153.796 164.713 153.796Z"
      fill="#00684A"
    />
    <path
      d="M163.873 159.582C164.519 159.582 165.043 159.058 165.043 158.412C165.043 157.766 164.519 157.242 163.873 157.242C163.226 157.242 162.703 157.766 162.703 158.412C162.703 159.058 163.226 159.582 163.873 159.582Z"
      fill="#A6FFEC"
    />
    <path
      d="M21.0496 49.163C26.7084 48.2617 31.0442 45.9523 30.734 44.0048C30.4238 42.0572 25.585 41.2091 19.9263 42.1104C14.2675 43.0118 9.93164 45.3212 10.2418 47.2687C10.552 49.2163 15.3908 50.0644 21.0496 49.163Z"
      fill="black"
    />
    <path
      d="M25.1969 47.6017C29.4595 46.9228 32.6226 44.5358 32.2617 42.2702C31.9009 40.0046 28.1528 38.7184 23.8901 39.3974C19.6274 40.0763 16.4644 42.4633 16.8253 44.7289C17.1861 46.9945 20.9342 48.2807 25.1969 47.6017Z"
      fill="white"
    />
    <path
      d="M16.7372 44.735C17.1159 47.0486 20.8646 48.3739 25.1283 47.6961C29.392 47.0183 32.5576 44.5722 32.1789 42.2586L31.8003 39.9525C31.4216 37.6352 27.6767 36.3098 23.4092 36.9914C19.1417 37.673 15.9875 40.0964 16.3548 42.4138L16.7372 44.735Z"
      fill="#B1FF05"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M20.4403 29.6608L20.3495 29.0815C20.2753 28.611 20.3907 28.1304 20.6704 27.745C20.95 27.3595 21.3712 27.1007 21.8414 27.0253C22.3113 26.9512 22.7915 27.0667 23.1764 27.3465C23.5612 27.6262 23.8192 28.0473 23.8937 28.5173L23.9884 29.0966C27.2638 29.1572 29.9485 30.8422 30.3423 33.2997C30.3612 33.4171 30.3726 33.5307 30.3801 33.6481L31.3836 40.0171C31.713 42.1073 28.733 44.3187 24.6965 44.9397C20.66 45.5607 17.1233 44.4096 16.809 42.3232L15.7828 35.9541C15.7518 35.8419 15.7265 35.7281 15.7071 35.6133C15.3322 33.1748 17.3618 30.7248 20.4403 29.6608Z"
      fill="#B1FF05"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M22.6519 26.374L22.7731 27.1313C22.5509 27.373 22.2447 27.5206 21.9173 27.5441C21.6048 27.6204 21.2755 27.5856 20.9858 27.4456L20.8608 26.6883C21.1466 26.8191 21.4681 26.8498 21.7734 26.7754C22.101 26.7369 22.4083 26.5965 22.6519 26.374Z"
      fill="black"
    />
    <path
      d="M19.5882 44.7351L18.1683 35.8859C18.1683 35.8859 17.2898 31.2397 20.8189 29.7364C21.1975 29.5736 20.603 29.6266 21.0991 29.5471C21.0991 29.5471 21.2846 29.4146 20.4478 29.6607C19.262 30.0416 18.1862 30.7042 17.3125 31.5919C15.1125 33.6707 15.635 35.2611 16.044 37.8473C16.4529 40.4335 16.8392 42.5805 16.8392 42.5805C16.8392 42.5805 17.1345 44.0081 19.5882 44.7351Z"
      fill="black"
    />
    <path
      d="M20.645 47.6393L20.2095 44.9129C19.32 44.6965 18.4771 44.3206 17.7217 43.8035C16.9644 42.9969 16.9644 43.0462 16.6085 40.9143C16.4184 41.2175 16.3259 41.5717 16.3434 41.9291C16.3568 42.2715 16.3986 42.6122 16.4684 42.9477C16.6691 44.22 16.8281 45.072 16.8281 45.072C17.1403 45.8167 17.6902 46.4371 18.3919 46.8365C19.0918 47.2303 19.8538 47.5018 20.645 47.6393Z"
      fill="black"
    />
    <path
      d="M20.4403 29.6608L20.3267 28.7369C21.0045 29.445 23.0038 29.2783 23.8406 28.2749L23.9883 29.0966C23.9883 29.0966 23.6589 29.9524 22.3676 30.1341C20.9628 30.3348 20.4403 29.6608 20.4403 29.6608Z"
      fill="black"
    />
    <path d="M115.276 103.079L120.407 98.7129L111.701 158.518L106.57 162.884L115.276 103.079Z" fill="black" />
    <path
      d="M113.041 138.026C113.645 133.753 112.294 130.028 110.023 129.707C107.752 129.386 105.421 132.59 104.816 136.863C104.212 141.136 105.563 144.861 107.835 145.182C110.106 145.503 112.437 142.299 113.041 138.026Z"
      fill="white"
    />
    <path
      d="M108.289 145.879C110.61 146.219 112.996 143.027 113.617 138.752C114.238 134.477 112.86 130.74 110.542 130.403L108.232 130.066C105.911 129.729 103.526 132.921 102.905 137.192C102.284 141.464 103.662 145.205 105.979 145.545L108.289 145.879Z"
      fill="#B1FF05"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M95.0134 137.844L94.4303 137.76C94.197 137.726 93.9729 137.646 93.7705 137.525C93.5682 137.404 93.3916 137.245 93.251 137.055C93.1103 136.866 93.0083 136.651 92.9508 136.423C92.8932 136.194 92.8813 135.956 92.9156 135.723C92.9849 135.253 93.2379 134.829 93.6191 134.545C94.0003 134.261 94.4786 134.14 94.949 134.208L95.5284 134.296C96.5621 131.187 98.9742 129.123 101.435 129.483C101.551 129.498 101.666 129.52 101.78 129.547L108.16 130.49C110.251 130.793 111.47 134.311 110.883 138.343C110.296 142.376 108.123 145.394 106.017 145.08L99.633 144.163C99.5194 144.163 99.4021 144.144 99.2885 144.129C96.8385 143.781 95.1194 141.115 95.0134 137.844Z"
      fill="#B1FF05"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M92.6617 134.739L93.4001 134.905C93.5423 135.199 93.5667 135.537 93.4683 135.848C93.4253 136.167 93.2727 136.462 93.0366 136.681L92.2793 136.511C92.5063 136.293 92.653 136.005 92.6958 135.693C92.7817 135.379 92.7699 135.046 92.6617 134.739Z"
      fill="black"
    />
    <path
      d="M109.156 143.186L100.273 141.895C100.273 141.895 95.5774 141.346 95.1949 137.533C95.1533 137.116 95.0245 137.703 95.0965 137.207C95.0965 137.207 95.0245 136.991 95.0132 137.862C95.0223 139.107 95.3342 140.332 95.922 141.429C97.2511 144.152 98.9247 144.129 101.515 144.504C104.105 144.879 106.271 145.159 106.271 145.159C106.271 145.159 107.717 145.288 109.156 143.186Z"
      fill="black"
    />
    <path
      d="M112.231 143.024L109.497 142.626C109.027 143.41 108.417 144.102 107.698 144.667C106.699 145.159 106.74 145.182 104.608 144.868C104.841 145.14 105.151 145.335 105.498 145.424C105.829 145.514 106.168 145.576 106.509 145.61C107.782 145.795 108.645 145.901 108.645 145.901C109.448 145.823 110.202 145.482 110.792 144.932C111.376 144.382 111.863 143.737 112.231 143.024Z"
      fill="black"
    />
    <path
      d="M95.0135 137.843C95.0135 137.843 95.8579 137.571 96.0851 136.17C96.1679 135.833 96.16 135.481 96.0622 135.148C95.9644 134.815 95.7802 134.515 95.5284 134.276L94.809 134.178C95.4224 135.496 95.0778 137.256 94.2031 137.696L95.0135 137.843Z"
      fill="black"
    />
    <path d="M106.57 162.884L111.701 158.518L192.295 170.249L187.164 174.615L106.57 162.884Z" fill="black" />
    <path d="M102.511 61.4263L98.8983 65.1296L96.312 125.51L100.102 121.997L102.511 61.4263Z" fill="black" />
    <path d="M89.2463 73.0095L88.7124 85.475L101.712 84.8729L98.9248 73.4147L89.2463 73.0095Z" fill="black" />
    <path
      d="M121.501 35.1663C121.501 35.1208 121.501 35.0716 121.501 35.0262C121.517 31.9767 120.482 29.0148 118.569 26.64C116.655 24.2652 113.982 22.6229 110.999 21.9899C108.016 21.3569 104.906 21.772 102.193 23.1652C99.4803 24.5584 97.3311 26.8445 96.1078 29.6379C95.7309 29.5925 95.3515 29.5697 94.9718 29.5697C92.5214 29.619 90.1879 30.627 88.4724 32.3774C86.7568 34.1278 85.7959 36.4811 85.7959 38.932C85.7959 41.383 86.7568 43.7362 88.4724 45.4866C90.1879 47.237 92.5214 48.2451 94.9718 48.2944H121.508C123.222 48.2532 124.851 47.5436 126.049 46.3171C127.246 45.0906 127.917 43.4444 127.917 41.7303C127.917 40.0162 127.246 38.3701 126.049 37.1436C124.851 35.9171 123.222 35.2074 121.508 35.1663H121.501Z"
      fill="black"
    />
    <path
      d="M144.618 29.3389C144.618 29.2934 144.618 29.2442 144.618 29.1988C144.634 26.1493 143.599 23.1874 141.686 20.8126C139.773 18.4379 137.099 16.7955 134.116 16.1625C131.133 15.5295 128.023 15.9446 125.31 17.3378C122.597 18.731 120.448 21.0171 119.225 23.8105C118.848 23.7651 118.469 23.7423 118.089 23.7423C115.606 23.7423 113.226 24.7285 111.47 26.4839C109.715 28.2393 108.729 30.6202 108.729 33.1027C108.729 35.5853 109.715 37.9662 111.47 39.7216C113.226 41.477 115.606 42.4632 118.089 42.4632H144.625C146.366 42.4632 148.035 41.7718 149.266 40.5412C150.496 39.3105 151.188 37.6414 151.188 35.901C151.188 34.1606 150.496 32.4915 149.266 31.2609C148.035 30.0302 146.366 29.3389 144.625 29.3389H144.618Z"
      fill="black"
    />
    <path
      d="M144.618 29.3389C144.618 29.2934 144.618 29.2442 144.618 29.1988C144.634 26.1493 143.599 23.1874 141.686 20.8126C139.773 18.4379 137.099 16.7955 134.116 16.1625C131.133 15.5295 128.023 15.9446 125.31 17.3378C122.597 18.731 120.448 21.0171 119.225 23.8105C118.848 23.7651 118.469 23.7423 118.089 23.7423C115.606 23.7423 113.226 24.7285 111.47 26.4839C109.715 28.2393 108.729 30.6202 108.729 33.1027C108.729 35.5853 109.715 37.9662 111.47 39.7216C113.226 41.477 115.606 42.4632 118.089 42.4632H144.625C146.366 42.4632 148.035 41.7718 149.266 40.5412C150.496 39.3105 151.188 37.6414 151.188 35.901C151.188 34.1606 150.496 32.4915 149.266 31.2609C148.035 30.0302 146.366 29.3389 144.625 29.3389H144.618Z"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M144.618 23.2803C144.618 23.2348 144.618 23.1856 144.618 23.1402C144.634 20.0907 143.599 17.1288 141.686 14.754C139.773 12.3793 137.099 10.7369 134.116 10.1039C131.133 9.47086 128.023 9.88597 125.31 11.2792C122.597 12.6724 120.448 14.9585 119.225 17.7519C118.848 17.7065 118.469 17.6837 118.089 17.6837C115.606 17.6837 113.226 18.6699 111.47 20.4253C109.715 22.1807 108.729 24.5616 108.729 27.0442C108.729 29.5267 109.715 31.9076 111.47 33.663C113.226 35.4184 115.606 36.4046 118.089 36.4046H144.625C146.366 36.4046 148.035 35.7132 149.266 34.4826C150.496 33.2519 151.188 31.5828 151.188 29.8424C151.188 28.102 150.496 26.4329 149.266 25.2023C148.035 23.9717 146.366 23.2803 144.625 23.2803H144.618Z"
      fill="white"
    />
    <path
      d="M144.618 23.2803C144.618 23.2348 144.618 23.1856 144.618 23.1402C144.634 20.0907 143.599 17.1288 141.686 14.754C139.773 12.3793 137.099 10.7369 134.116 10.1039C131.133 9.47086 128.023 9.88597 125.31 11.2792C122.597 12.6724 120.448 14.9585 119.225 17.7519C118.848 17.7065 118.469 17.6837 118.089 17.6837C115.606 17.6837 113.226 18.6699 111.47 20.4253C109.715 22.1807 108.729 24.5616 108.729 27.0442C108.729 29.5267 109.715 31.9076 111.47 33.663C113.226 35.4184 115.606 36.4046 118.089 36.4046H144.625C146.366 36.4046 148.035 35.7132 149.266 34.4826C150.496 33.2519 151.188 31.5828 151.188 29.8424C151.188 28.102 150.496 26.4329 149.266 25.2023C148.035 23.9717 146.366 23.2803 144.625 23.2803H144.618Z"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M9.66374 55.2618L12.7612 74.6984C12.7612 74.6984 5.0138 73.1838 4.38523 69.2344L1.24237 49.5024C1.24237 49.5024 1.15527 51.7024 4.83584 53.5844C6.34418 54.4002 7.97452 54.9666 9.66374 55.2618Z"
      fill="black"
    />
    <path
      d="M14.2873 84.2821L17.3847 103.719C17.3847 103.719 9.63734 102.204 9.00877 98.2508L5.8659 78.5227C5.8659 78.5227 5.77881 80.7076 9.45937 82.6009C12.288 84.0473 14.2873 84.2821 14.2873 84.2821Z"
      fill="black"
    />
    <path
      d="M18.9258 113.299L22.0195 132.739C22.0195 132.739 14.2721 131.225 13.6435 127.271L10.5007 107.54C10.5007 107.54 10.4136 109.743 14.0941 111.622C15.6037 112.438 17.2353 113.004 18.9258 113.299Z"
      fill="black"
    />
    <path
      d="M27.0318 78.616C30.859 78.4409 33.9197 77.3844 33.8681 76.2563C33.8164 75.1282 30.672 74.3557 26.8448 74.5308C23.0176 74.7059 19.9569 75.7624 20.0085 76.8905C20.0601 78.0186 23.2045 78.7912 27.0318 78.616Z"
      fill="black"
    />
    <path
      d="M31.8423 107.75C35.6695 107.575 38.7302 106.518 38.6786 105.39C38.627 104.262 35.4826 103.489 31.6554 103.665C27.8281 103.84 24.7674 104.896 24.8191 106.024C24.8707 107.152 28.0151 107.925 31.8423 107.75Z"
      fill="black"
    />
  </svg>
);

export default Icon;
