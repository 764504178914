import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AutoscaleCompute')
export class AutoscaleCompute {
  @JsonProperty('enabled', Boolean)
  public enabled?: boolean = undefined;

  @JsonProperty('scaleDownEnabled', Boolean)
  public scaleDownEnabled?: boolean = undefined;

  constructor(partial?: Partial<AutoscaleCompute>) {
    Object.assign(this, partial);
  }
}

@JsonObject('Autoscaling')
export class Autoscaling {
  @JsonProperty('compute', AutoscaleCompute)
  public compute?: AutoscaleCompute = undefined;

  @JsonProperty('diskGBEnabled', Boolean)
  public diskGBEnabled?: boolean = undefined;

  constructor(partial?: Partial<Autoscaling>) {
    Object.assign(this, partial);
  }
}

@JsonObject('ClusterStorageMetrics')
export class ClusterStorageMetrics {
  @JsonProperty('autoScaling', Autoscaling)
  public autoScaling?: Autoscaling = undefined;

  @JsonProperty('diskSizeGB', Number)
  public diskSizeGB?: number = undefined;

  @JsonProperty('diskPartitionSpaceFree', Number, true)
  public diskPartitionSpaceFree?: number = undefined;

  @JsonProperty('diskPartitionSpaceFreeUnits', String, true)
  public diskPartitionSpaceFreeUnits?: string = undefined;

  @JsonProperty('percentCapacityRemaining', Number, true)
  public percentCapacityRemaining?: number = undefined;

  @JsonProperty('warning', String, true)
  public warning?: string = undefined;

  @JsonProperty('partial', Boolean)
  public partial?: boolean = undefined;

  @JsonProperty('instanceSize', String, true)
  public instanceSize?: string = undefined;

  constructor(partial?: Partial<ClusterStorageMetrics>) {
    Object.assign(this, partial);
  }
}
