import React, { ComponentProps } from 'react';
import Badge from '@leafygreen-ui/badge';
import classNames from 'classnames';

import { AppEnvironment } from 'admin-sdk';

import './environment-badge.less';

interface PublicProps extends ComponentProps<typeof Badge> {
  className?: string;
  environment: AppEnvironment;
}

type Props = PublicProps;

export default function EnvironmentBadge({ className, environment, ...rest }: Props) {
  switch (environment) {
    case AppEnvironment.None:
      return (
        <Badge {...rest} className={classNames('environment-badge', className)} variant="lightgray">
          No Environment
        </Badge>
      );
    case AppEnvironment.Testing:
      return (
        <Badge {...rest} className={classNames('environment-badge', className)} variant="darkgray">
          Test
        </Badge>
      );
    case AppEnvironment.Development:
      return (
        <Badge {...rest} className={classNames('environment-badge', className)} variant="blue">
          Development
        </Badge>
      );
    case AppEnvironment.QA:
      return (
        <Badge {...rest} className={classNames('environment-badge', className)} variant="yellow">
          QA
        </Badge>
      );
    case AppEnvironment.Production:
      return (
        <Badge {...rest} className={classNames('environment-badge', className)} variant="green">
          Production
        </Badge>
      );
    default:
      return null;
  }
}
