import React from 'react';
import Button, { Variant } from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import Tooltip from '@leafygreen-ui/tooltip';
import { Body, Link } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import { FILTERS_LIMIT } from 'baas-ui/rules/constants';
import FilterCard from 'baas-ui/rules/filter-card';
import { CardActionFunction } from 'baas-ui/rules/types';
import { MongoDBRuleFilter } from 'admin-sdk';

import './filters.less';

export enum TestSelector {
  AddFilter = 'add-filter',
  AddFilterDiv = 'add-filter-div',
  AddFilterTooltip = 'add-filter-tooltip',
  DocsLink = 'docs-link',
  FilterCardC = 'filter-card',
  FiltersContainer = 'filters-container',
  LearnMoreLink = 'learn-more-link',
}

export const getCardTestSelector = (name: string) => `${name}-${TestSelector.FilterCardC}`;

const baseClassName = 'filters';
export const leftHeaderClassName = `${baseClassName}-header-left`;
export interface Props {
  filters: MongoDBRuleFilter[];
  headerLeftComponent: React.ReactNode;
  onClickAdd(): void;
  onClickDelete: CardActionFunction;
  onClickEdit: CardActionFunction;
  onClickLearnMoreLink: () => void;
}

export default function Filters({
  filters,
  headerLeftComponent,
  onClickAdd,
  onClickDelete,
  onClickEdit,
  onClickLearnMoreLink,
}: Props) {
  return (
    <div className={baseClassName} data-testid={TestSelector.FiltersContainer}>
      <div className={`${baseClassName}-header`}>
        <div className={leftHeaderClassName}>
          {headerLeftComponent}
          <Link
            onClick={() => onClickLearnMoreLink()}
            hideExternalIcon
            data-testid={TestSelector.LearnMoreLink}
            className={`${leftHeaderClassName}-learn-more-link`}
          >
            Learn more about Filters
          </Link>
          <Body className={`${leftHeaderClassName}-divider`}>|</Body>
          <DocLink href={docLinks.Rules.Filters} showExternalIcon data-testid={TestSelector.DocsLink}>
            Docs
          </DocLink>
        </div>

        <Tooltip
          className={`${baseClassName}-tooltip`}
          triggerEvent="hover"
          align="top"
          justify="start"
          trigger={
            <div data-testid={TestSelector.AddFilterDiv}>
              <Button
                leftGlyph={<Icon glyph="Plus" />}
                variant={Variant.PrimaryOutline}
                size="small"
                onClick={onClickAdd}
                disabled={filters.length >= FILTERS_LIMIT}
                data-test-selector={TestSelector.AddFilter}
                data-testid={TestSelector.AddFilter}
              >
                Add filter
              </Button>
            </div>
          }
          enabled={filters.length >= FILTERS_LIMIT}
          data-test-selector={TestSelector.AddFilterTooltip}
          data-testid={TestSelector.AddFilterTooltip}
        >
          {`You cannot add more than ${FILTERS_LIMIT} filters in this UI. Use advanced mode to configure more.`}
        </Tooltip>
      </div>
      {filters.map((f, idx) => (
        <FilterCard
          className={`${baseClassName}-list-card`}
          key={f.name}
          name={f.name}
          onClickDelete={() => onClickDelete(f.name, idx)}
          onClickEdit={() => onClickEdit(f.name, idx)}
          data-testid={getCardTestSelector(f.name)}
          data-test-selector={getCardTestSelector(f.name)}
          data-cy={getCardTestSelector(f.name)}
        />
      ))}
    </div>
  );
}
