import React, { useContext, useState } from 'react';
import ConfirmationModal, { Variant } from '@leafygreen-ui/confirmation-modal';

import { RulesPageContext } from 'baas-ui/rules/RulesPage';
import { track } from 'baas-ui/tracking';

import './delete-rules-modal.less';

export enum TestSelector {
  DeleteRuleModal = 'delete-rule-modal',
}

export interface DeleteDefaultRule {
  dataSourceId: string;
  dataSourceName: string;
  isDefaultRule: true;
}

function isDeleteDefaultRule(input: DeleteOptions): input is DeleteDefaultRule {
  return typeof (input as DeleteDefaultRule).isDefaultRule !== 'undefined';
}

export interface DeleteRule {
  ruleId: string;
  dataSourceId: string;
  collectionName: string;
}

function isDeleteRule(input: DeleteOptions): input is DeleteRule {
  return typeof (input as DeleteRule).ruleId !== 'undefined';
}

export interface BulkDeleteDatabase {
  dataSourceName: string;
  databaseName: string;
  dataSourceId: string;
}

function isBulkDeleteDatabase(input: DeleteOptions): input is BulkDeleteDatabase {
  return (
    typeof (input as BulkDeleteDatabase).dataSourceName !== 'undefined' &&
    typeof (input as BulkDeleteDatabase).databaseName !== 'undefined'
  );
}

export interface BulkDeleteDataSource {
  dataSourceName: string;
  dataSourceId: string;
}

function isBulkDeleteDataSource(input: DeleteOptions): input is BulkDeleteDataSource {
  return (
    typeof (input as BulkDeleteDatabase).dataSourceName !== 'undefined' &&
    typeof (input as BulkDeleteDatabase).databaseName === 'undefined' &&
    typeof (input as DeleteDefaultRule).isDefaultRule === 'undefined'
  );
}

export type DeleteOptions = DeleteDefaultRule | DeleteRule | BulkDeleteDataSource | BulkDeleteDatabase;

interface PublicProps {
  deleteOptions: DeleteOptions;
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
}

export type Props = PublicProps;

function getConfirmationModalBaseProps(deleteOptions: DeleteOptions) {
  if (isDeleteRule(deleteOptions)) {
    const collectionName = deleteOptions.collectionName;
    return {
      title: `Delete roles and filters for ${collectionName}`,
      children: (
        <>
          Are you sure you&apos;d like to delete roles and filters for{' '}
          {collectionName ? <b>collection: {collectionName}</b> : 'this collection'}?
        </>
      ),
    };
  }

  if (isBulkDeleteDatabase(deleteOptions)) {
    const databasePath = `${deleteOptions.dataSourceName}.${deleteOptions.databaseName}`;
    return {
      title: `Delete all roles and filters for ${databasePath}`,
      children: (
        <>
          Are you sure you&apos;d like to delete all roles and filters for <b>database: {databasePath}</b>?
        </>
      ),
    };
  }

  if (isDeleteDefaultRule(deleteOptions)) {
    return {
      title: `Delete all default roles and filters for ${deleteOptions.dataSourceName}`,
      children: (
        <>
          Are you sure you&apos;d like to delete all default roles and filters for{' '}
          <b>data source: {deleteOptions.dataSourceName}</b>?
        </>
      ),
    };
  }

  return {
    title: `Delete all roles and filters for ${deleteOptions.dataSourceName}`,
    children: (
      <>
        Are you sure you&apos;d like to delete all roles and filters for{' '}
        <b>data source: {deleteOptions.dataSourceName}</b>?
      </>
    ),
  };
}

export function DeleteRulesModal({ deleteOptions, isOpen, setIsOpen }: Props) {
  const { onDeleteRule, onDeleteDefaultRule, onDeleteRuleByDatabase, onDeleteRuleByDatasource } =
    useContext(RulesPageContext);

  const handleDeleteRules = () => {
    if (isDeleteDefaultRule(deleteOptions)) {
      setIsOpen(false);
      onDeleteDefaultRule(deleteOptions);
    } else if (isDeleteRule(deleteOptions)) {
      // close delete modal before calling saga in case the submit destrucive request modal appears after
      setIsOpen(false);
      onDeleteRule(deleteOptions);
    } else if (isBulkDeleteDatabase(deleteOptions)) {
      setIsOpen(false);
      onDeleteRuleByDatabase(deleteOptions);
    } else if (isBulkDeleteDataSource(deleteOptions)) {
      setIsOpen(false);
      onDeleteRuleByDatasource(deleteOptions);
    }
  };

  return (
    <ConfirmationModal
      className="delete-rules-modal"
      {...getConfirmationModalBaseProps(deleteOptions)}
      confirmButtonProps={{
        children: 'Delete',
        onClick: handleDeleteRules,
      }}
      cancelButtonProps={{
        onClick: () => setIsOpen(false),
      }}
      variant={Variant.Danger}
      open={isOpen}
      data-cy={TestSelector.DeleteRuleModal}
    />
  );
}

export function useDeleteRulesModal(): [React.ReactNode, (options: DeleteOptions) => void] {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteOptions, setDeleteOptions] = useState<DeleteOptions>();

  return [
    deleteOptions && (
      <DeleteRulesModal
        data-test-selector={TestSelector.DeleteRuleModal}
        deleteOptions={deleteOptions}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    ),
    (options: DeleteOptions) => {
      if (isDeleteRule(options)) {
        track('RULES_CONFIGURATION.DELETE_RULES_VIEWED', {
          ruleId: options.ruleId,
          dataSourceId: options.dataSourceId,
          collectionName: options.collectionName,
        });
      } else if (isBulkDeleteDatabase(options)) {
        track('RULES_CONFIGURATION.DELETE_RULES_VIEWED', {
          dataSourceName: options.dataSourceName,
          database: options.databaseName,
          dataSourceId: options.dataSourceId,
        });
      } else if (isDeleteDefaultRule(options)) {
        track('RULES_CONFIGURATION.DELETE_DEFAULT_RULE_VIEWED', {
          dataSourceName: options.dataSourceName,
          dataSourceId: options.dataSourceId,
        });
      } else if (isBulkDeleteDataSource(options)) {
        track('RULES_CONFIGURATION.DELETE_RULES_VIEWED', {
          dataSourceName: options.dataSourceName,
          dataSourceId: options.dataSourceId,
        });
      }

      setDeleteOptions(options);
      setIsOpen(true);
    },
  ];
}
