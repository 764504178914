import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Banner, { Variant as BannerVariant } from '@leafygreen-ui/banner';
import Button, { Size, Variant } from '@leafygreen-ui/button';
import Code from '@leafygreen-ui/code';
import Icon from '@leafygreen-ui/icon';
import Modal from '@leafygreen-ui/modal';
import Tooltip from '@leafygreen-ui/tooltip';
import { Body, H3, Link } from '@leafygreen-ui/typography';
import qs from 'query-string';

import { redirectTo } from 'baas-ui/actions';
import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import { prettyJSONStringify } from 'baas-ui/common/utils/util';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { ROLES_LIMIT } from 'baas-ui/rules/constants';
import RoleCard from 'baas-ui/rules/role-card';
import { RulesPageCollectionExplorerContext } from 'baas-ui/rules/RulesPage';
import { CardActionFunction, RulesSyncParams } from 'baas-ui/rules/types';
import { useRulesPageContext } from 'baas-ui/rules/useRulesPageContext';
import { getAppState } from 'baas-ui/selectors';
import { track } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import rootUrl from 'baas-ui/urls';
import { MongoDBRuleRole } from 'admin-sdk';

import './roles.less';

export enum TestSelector {
  AddRole = 'add-role',
  AddRoleDiv = 'add-role-div',
  AddRoleTooltip = 'add-role-tooltip',
  ApplyWhenModal = 'apply-when-modal',
  ApplyWhenModalCode = 'apply-when-modal-code',
  DocsLink = 'docs-link',
  LearnMoreLink = 'learn-more-link',
  RoleCardC = 'role-card',
  RoleOrderInfoBanner = 'role-order-info-banner',
  RolesContainer = 'roles-container',
  SyncInfoBanner = 'role-sync-info-banner',
  EmptyConfiguredRuleBanner = 'empty-configured-rule-banner',
}

export const getCardTestSelector = (name: string) => `${name}-${TestSelector.RoleCardC}`;

const baseClassName = 'roles';
export const leftHeaderClassName = `${baseClassName}-header-left`;

const emptyRuleBannerText = `This collection is configured, but does not have any roles.
As a result, all access to it is currently denied by default. `;
const emptyDefaultRuleBannerText = `This default rule is configured, but does not have any roles.
As a result, all access to collections using the default roles & filters are currently denied by default. `;

export interface Props {
  roles: MongoDBRuleRole[];
  headerLeftComponent: React.ReactNode;
  onClickAdd(): void;
  onClickDelete: CardActionFunction;
  onClickEdit: CardActionFunction;
  onClickUp: CardActionFunction;
  onClickDown: CardActionFunction;
  onClickLearnMoreLink: () => void;
}

export default function Roles({
  roles,
  headerLeftComponent,
  onClickAdd,
  onClickDelete,
  onClickEdit,
  onClickUp,
  onClickDown,
  onClickLearnMoreLink,
}: Props) {
  const dispatch = useDispatch<AsyncDispatch>();
  const { id: appId, groupId } = useSelector((state: RootState) => getAppState(state)).app;

  const [isViewJsonModalOpen, setIsViewJsonModalOpen] = React.useState<boolean>(false);
  const [viewJsonModalRole, setViewJsonModalRole] = React.useState<number>(0);
  const [isRoleOrderBannerDismissed, setIsRoleOrderBannerDismissed] = useState(false);
  const [showSyncBanner, setShowSyncBanner] = useState<boolean>(false);

  const { selectedDataSourceData, selectedNamespace } = useContext(RulesPageCollectionExplorerContext);
  const location = useLocation();
  const [syncIncompatibleRoleNames, setSyncIncompatibleRoleNames] = useState<string[]>([]);
  const { syncIncompatibleRolesByDataSourceId } = useRulesPageContext();

  const isDefaultRule = selectedNamespace && selectedNamespace.split(':', 3).length < 3;
  const isEmptyConfiguredRule = !!roles && roles.length === 0;

  React.useEffect(() => {
    const syncParams: RulesSyncParams = qs.parse(location.search);
    if (syncParams?.showSyncBanner) {
      setShowSyncBanner(true);
    } else {
      setShowSyncBanner(false);
    }
  }, [location.search]);

  React.useEffect(() => {
    if (!selectedDataSourceData || !selectedNamespace) {
      setSyncIncompatibleRoleNames([]);
      return;
    }

    const dataSourceId = selectedDataSourceData.dataSourceId;
    const dataSourceSyncIncompatibleRoles = syncIncompatibleRolesByDataSourceId[dataSourceId];
    if (!dataSourceSyncIncompatibleRoles) {
      setSyncIncompatibleRoleNames([]);
      return;
    }

    if (isDefaultRule) {
      const syncIncompatibleDefaultRoles = dataSourceSyncIncompatibleRoles.defaultRoles;
      setSyncIncompatibleRoleNames(
        syncIncompatibleDefaultRoles ? syncIncompatibleDefaultRoles.map((defaultRole) => defaultRole.name) : []
      );
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, dbName, collName] = selectedNamespace.split(':', 3);
    const syncIncompatibleNamespaceRoles = dataSourceSyncIncompatibleRoles.namespaceRoles;
    if (!syncIncompatibleNamespaceRoles) {
      setSyncIncompatibleRoleNames([]);
      return;
    }

    const selectedNamespaceIncompatibleRoles = syncIncompatibleNamespaceRoles.filter((namespaceRole) => {
      return namespaceRole.collection === collName && namespaceRole.database === dbName;
    });
    setSyncIncompatibleRoleNames(selectedNamespaceIncompatibleRoles.map((namespaceRole) => namespaceRole.name));
  }, [selectedDataSourceData, selectedNamespace, syncIncompatibleRolesByDataSourceId]);

  const handleClickViewApplyWhen = (_: string, idx: number) => {
    track('RULES_CONFIGURATION.APPLY_WHEN_MODAL_VIEWED', {
      name: roles && roles[idx].name,
    });
    setViewJsonModalRole(idx);
    setIsViewJsonModalOpen(true);
  };

  return (
    <>
      <div className={baseClassName} data-testid={TestSelector.RolesContainer}>
        <div className={`${baseClassName}-header`}>
          <div className={leftHeaderClassName}>
            {headerLeftComponent}
            <Link
              onClick={() => onClickLearnMoreLink()}
              hideExternalIcon
              data-testid={TestSelector.LearnMoreLink}
              className={`${leftHeaderClassName}-learn-more-link`}
            >
              Learn more about Roles
            </Link>
            <Body className={`${leftHeaderClassName}-divider`}>|</Body>
            <DocLink href={docLinks.Rules.Roles} showExternalIcon data-testid={TestSelector.DocsLink}>
              Docs
            </DocLink>
          </div>
          <Tooltip
            className={`${baseClassName}-tooltip`}
            triggerEvent="hover"
            align="top"
            justify="start"
            trigger={
              <div data-testid={TestSelector.AddRoleDiv}>
                <Button
                  leftGlyph={<Icon glyph="Plus" />}
                  variant={Variant.PrimaryOutline}
                  size={Size.Small}
                  onClick={onClickAdd}
                  disabled={roles.length >= ROLES_LIMIT}
                  data-testid={TestSelector.AddRole}
                  data-cy="add-role-button"
                >
                  Add role
                </Button>
              </div>
            }
            enabled={roles.length >= ROLES_LIMIT}
            data-testid={TestSelector.AddRoleTooltip}
          >
            {`You cannot add more than ${ROLES_LIMIT} roles in this UI. Use advanced mode to configure more.`}
          </Tooltip>
        </div>
        {isEmptyConfiguredRule && (
          <Banner
            className={`${baseClassName}-empty-rule-banner`}
            variant={BannerVariant.Warning}
            data-cy={TestSelector.EmptyConfiguredRuleBanner}
            data-testid={TestSelector.EmptyConfiguredRuleBanner}
          >
            {isDefaultRule ? emptyDefaultRuleBannerText : emptyRuleBannerText}
            <DocLink showExternalIcon href={isDefaultRule ? docLinks.Rules.DefaultRule : docLinks.Rules.Roles}>
              Learn more
            </DocLink>
          </Banner>
        )}
        {roles.length > 1 && !isRoleOrderBannerDismissed && (
          <Banner
            variant={BannerVariant.Info}
            className={`${baseClassName}-order-info-banner`}
            dismissible
            onClose={() => setIsRoleOrderBannerDismissed(true)}
            data-testid={TestSelector.RoleOrderInfoBanner}
          >
            App Services evaluates roles from top-to-bottom. Use the arrows next to the role names to order your roles
            accordingly.{' '}
            <DocLink
              href={docLinks.Rules.RolesOrder}
              className={`${baseClassName}-order-info-banner-link`}
              showExternalIcon
            >
              Learn more about role order
            </DocLink>
          </Banner>
        )}
        {showSyncBanner && isDefaultRule && (
          <Banner
            variant={BannerVariant.Info}
            className={`${baseClassName}-sync-info-banner`}
            dismissible
            onClose={() => {
              setShowSyncBanner(false);
              if (selectedDataSourceData?.dataSourceName) {
                const defaultRuleUrl = rootUrl
                  .groups()
                  .group(groupId)
                  .apps()
                  .app(appId)
                  .rules()
                  .default(selectedDataSourceData.dataSourceName);
                dispatch(redirectTo(defaultRuleUrl));
              }
            }}
            data-testid={TestSelector.SyncInfoBanner}
          >
            Your Flexible Sync permissions have been added as roles within the service rules.
          </Banner>
        )}
        {roles.map((r, idx) => (
          <RoleCard
            className={`${baseClassName}-list-card`}
            key={r.name}
            role={r}
            index={idx}
            onClickDelete={() => onClickDelete(r.name, idx)}
            onClickEdit={() => onClickEdit(r.name, idx)}
            onClickUp={() => onClickUp(r.name, idx)}
            onClickDown={() => onClickDown(r.name, idx)}
            onClickViewApplyWhenJson={() => handleClickViewApplyWhen(r.name, idx)}
            data-test-selector={getCardTestSelector(r.name)}
            data-testid={getCardTestSelector(r.name)}
            data-cy={getCardTestSelector(r.name)}
            upDisabled={idx === 0}
            downDisabled={idx === roles.length - 1}
            hasSyncIncompatibleRoles={syncIncompatibleRoleNames.includes(r.name)}
          />
        ))}
      </div>

      <Modal
        className={`${baseClassName}-apply-when-modal`}
        open={isViewJsonModalOpen}
        setOpen={setIsViewJsonModalOpen}
        data-testid={TestSelector.ApplyWhenModal}
      >
        <H3 className={`${baseClassName}-apply-when-modal-heading`}>Apply When: {roles[viewJsonModalRole]?.name}</H3>
        <Code showLineNumbers language="json" data-testid={TestSelector.ApplyWhenModalCode}>
          {prettyJSONStringify(roles[viewJsonModalRole]?.applyWhen) ?? ''}
        </Code>
      </Modal>
    </>
  );
}
