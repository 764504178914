import React from 'react';
import Box from '@leafygreen-ui/box';
import Button from '@leafygreen-ui/button';
import Code from '@leafygreen-ui/code';
import Icon from '@leafygreen-ui/icon';
import Modal from '@leafygreen-ui/modal';
import { palette } from '@leafygreen-ui/palette';
import { Body, H3 } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import { IncompatibleRolesModalPayload } from 'baas-ui/sync/types';
import { stringifyIncompatibleRolesByDatabase } from 'baas-ui/sync/util';

import './incompatible-roles-modal.less';

const baseClassName = 'incompatible-roles-modal';
const contentIncompatibilityClassName = `${baseClassName}-content-incompatibility`;

export enum TestSelector {
  IncompatibleRolesModal = 'incompatible-roles-modal',
  WarningIcon = 'incompatible-roles-modal-warning-icon',
  Header = 'incompatible-roles-modal-header',
  Description = 'incompatible-roles-modal-description',
  CollectionsLabel = 'incompatible-roles-modal-collections-label',
  CollectionsCode = 'incompatible-roles-modal-collections-code',
  CancelButton = 'incompatible-roles-modal-cancel-button',
  ConfirmButton = 'incompatible-roles-modal-confirm-button',
}
export interface Props {
  incompatibleRoles?: IncompatibleRolesModalPayload;
  open: boolean;
  showConfirmButton: boolean;
  onClickConfirm?(): void;
  onCancel(): void;
}

const IncompatibleRolesModal = ({ incompatibleRoles, onClickConfirm, onCancel, open, showConfirmButton }: Props) => {
  if (!incompatibleRoles) {
    return null;
  }

  return (
    <Modal
      open={open}
      setOpen={onCancel}
      className={baseClassName}
      data-cy={TestSelector.IncompatibleRolesModal}
      data-testid={TestSelector.IncompatibleRolesModal}
      contentClassName={`${baseClassName}-content`}
      size="large"
    >
      <div className={`${baseClassName}-max-height-control`}>
        <Box className={contentIncompatibilityClassName}>
          <div className={`${contentIncompatibilityClassName}-icon`} data-testid={TestSelector.WarningIcon}>
            <Icon glyph="ImportantWithCircle" size="large" fill={palette.yellow.dark2} />
          </div>
          <Box className={`${contentIncompatibilityClassName}-collections`}>
            <H3 className={`${contentIncompatibilityClassName}-collections-header`} data-testid={TestSelector.Header}>
              Collections with sync-incompatible roles
            </H3>
            <Body
              className={`${contentIncompatibilityClassName}-collections-description`}
              data-testid={TestSelector.Description}
            >
              Your rules contain{' '}
              <DocLink href={docLinks.Rules.SyncCompatibility} showExternalIcon>
                sync-incompatible
              </DocLink>
              roles. If a sync client is assigned an incompatible role during a sync session, then it will be denied all
              access during that session.
            </Body>
            <Body
              className={`${contentIncompatibilityClassName}-collections-label`}
              data-testid={TestSelector.CollectionsLabel}
            >
              Collections with incompatible roles ({incompatibleRoles.numIncompatibleCollections})
            </Body>
            <Box className={`${contentIncompatibilityClassName}-collections-code`}>
              <Code
                className={`${contentIncompatibilityClassName}-collections-code-content`}
                data-testid={TestSelector.CollectionsCode}
                language="none"
                copyable={false}
              >
                {`Collections are listed by database. Sync-incompatible roles are provided to the right of the
collection name.
------------------------------------------------------------------------------------------------------
${stringifyIncompatibleRolesByDatabase(incompatibleRoles.incompatibleRolesByDatabase)}
`}
              </Code>
            </Box>
          </Box>
        </Box>
        <div className={`${baseClassName}-footer`}>
          <Button onClick={onCancel} data-testid={TestSelector.CancelButton}>
            {showConfirmButton ? 'Cancel' : 'Close'}
          </Button>
          {showConfirmButton && (
            <Button
              className={`${baseClassName}-footer-confirm-button`}
              data-testid={TestSelector.ConfirmButton}
              onClick={onClickConfirm}
            >
              Save Changes Anyways
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default IncompatibleRolesModal;
