import { JsonObject, JsonProperty } from 'json2typescript';

import { AppEnvironment, DeploymentModel, Location, ProviderRegion } from '../common';

import { ServiceDesc } from './Service';

@JsonObject('PartialApp')
export class PartialApp {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('client_app_id')
  public clientAppId = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('product')
  public product = '';

  @JsonProperty('location')
  public location: Location = Location.Virginia;

  @JsonProperty('provider_region')
  public providerRegion: ProviderRegion = ProviderRegion.AWSProviderRegionUSEast1;

  @JsonProperty('deployment_model')
  public deploymentModel: DeploymentModel = DeploymentModel.Global;

  @JsonProperty('domain_id')
  public domainId = '';

  @JsonProperty('group_id')
  public groupId = '';

  @JsonProperty('last_used')
  public lastUsed = 0;

  @JsonProperty('last_modified')
  public lastModified = 0;

  @JsonProperty('environment', String, true)
  public environment = AppEnvironment.None;

  @JsonProperty('template_id', String, true)
  public templateId = '';

  constructor(partial?: Partial<PartialApp>) {
    Object.assign(this, partial);
  }
}

@JsonObject('CreateAppRequest')
export class CreateAppRequest {
  @JsonProperty('name')
  public name = '';

  @JsonProperty('location', String, true)
  public location?: string = undefined;

  @JsonProperty('provider_region', String, true)
  public providerRegion?: string = undefined;

  @JsonProperty('deployment_model', String, true)
  public deploymentModel?: string = undefined;

  @JsonProperty('environment', String, true)
  public environment?: string = undefined;

  @JsonProperty('template_id', String, true)
  public templateId?: string = undefined;

  @JsonProperty('data_source', ServiceDesc, true)
  public dataSource?: ServiceDesc = undefined;

  @JsonProperty('create_cluster', Boolean, true)
  public createCluster?: boolean = undefined;

  @JsonProperty('create_cluster_region', String, true)
  public createClusterRegion?: string = undefined;

  public product?: string;

  constructor(partial?: Partial<CreateAppRequest>) {
    Object.assign(this, partial);
  }
}

@JsonObject('UpdateEnvironmentRequest')
export class UpdateEnvironmentRequest {
  @JsonProperty('environment')
  public environment = AppEnvironment.None;

  constructor(partial?: Partial<UpdateEnvironmentRequest>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AppResourceNames')
export class AppResourceNames {
  @JsonProperty('datasources', [String])
  public datasources: string[] = [];

  @JsonProperty('functions', [String])
  public functions: string[] = [];

  @JsonProperty('services', [String])
  public services: string[] = [];

  @JsonProperty('values', [String])
  public values: string[] = [];

  constructor(partial?: Partial<AppResourceNames>) {
    Object.assign(this, partial);
  }
}
