import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { BaseRequestPayload } from 'baas-ui/types';
import { Endpoint, WebhookConversionStrategy } from 'admin-sdk';

const NAME = 'endpoints/';

interface CreateEndpointPayload extends BaseRequestPayload {
  newEndpoint: Endpoint;
}

interface UpdateEndpointPayload extends BaseRequestPayload {
  endpointId: string;
  endpointToUpdate: Endpoint;
}

interface EndpointPayload extends BaseRequestPayload {
  endpointId: string;
}

interface ConvertEndpointPayload extends BaseRequestPayload {
  strategy?: WebhookConversionStrategy;
}

// endpoint actions
export const [loadEndpointsActions, loadEndpoints] = createActionsAndExecutor<BaseRequestPayload, Endpoint[]>(
  `${NAME}load`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).endpoints().list()
);
export const [fetchEndpointActions, fetchEndpoint] = createActionsAndExecutor<EndpointPayload, Endpoint>(
  `${NAME}fetch`,
  (client, { groupId, appId, endpointId }) =>
    () =>
      client.apps(groupId).app(appId).endpoints().endpoint(endpointId).get()
);
export const [createEndpointActions, createEndpoint] = createActionsAndExecutor<CreateEndpointPayload, Endpoint>(
  `${NAME}create`,
  (client, { groupId, appId, newEndpoint }) =>
    () =>
      client.apps(groupId).app(appId).endpoints().create(newEndpoint)
);
export const [removeEndpointActions, removeEndpoint] = createActionsAndExecutor<EndpointPayload, void>(
  `${NAME}delete`,
  (client, { groupId, appId, endpointId }) =>
    () =>
      client.apps(groupId).app(appId).endpoints().endpoint(endpointId).remove()
);
export const [updateEndpointActions, updateEndpoint] = createActionsAndExecutor<UpdateEndpointPayload, Endpoint>(
  `${NAME}update`,
  (client, { groupId, appId, endpointId, endpointToUpdate }) =>
    () =>
      client.apps(groupId).app(appId).endpoints().endpoint(endpointId).update(endpointToUpdate)
);
export const [duplicateEndpointActions, duplicateEndpoint] = createActionsAndExecutor<EndpointPayload, Endpoint>(
  `${NAME}duplicate`,
  (client, { groupId, appId, endpointId }) =>
    () =>
      client.apps(groupId).app(appId).endpoints().endpoint(endpointId).duplicate()
);
export const [convertEndpointsActions, convertEndpoints] = createActionsAndExecutor<ConvertEndpointPayload, void>(
  `${NAME}convert`,
  (client, { groupId, appId, strategy }) =>
    () =>
      client.apps(groupId).app(appId).endpoints().convert({ strategy })
);
export const [convertEndpointsDryRunAction, convertEndpointsDryRun] = createActionsAndExecutor<
  ConvertEndpointPayload,
  void
>(
  `${NAME}convert dry run`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).endpoints().convert({ strategy: WebhookConversionStrategy.DryRun })
);

export const asyncEditRcvActions = [
  convertEndpointsActions.rcv,
  createEndpointActions.rcv,
  removeEndpointActions.rcv,
  updateEndpointActions.rcv,
  duplicateEndpointActions.rcv,
];
