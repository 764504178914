import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloudNavLayout, Env, HostName, LinkInterface, SideNavVariant } from '@lg-private/cloud-nav';

import { redirectTo as redirectToAction } from 'baas-ui/actions';
import { EnvironmentDesc } from 'baas-ui/common/constants';
import { getHomeState, getSettingsState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';

export const getNavEnv = (environment: EnvironmentDesc, cloudUIBaseUrl?: string): Env => {
  switch (environment) {
    case EnvironmentDesc.Prod:
      return Env.Prod;
    case EnvironmentDesc.Staging:
      return Env.Staging;
    case EnvironmentDesc.QA:
      return Env.QA;
    case EnvironmentDesc.Dev:
      return Env.Dev;
    default:
      return cloudUIBaseUrl ? Env.MmsLocal : Env.Test;
  }
};

export interface Props {
  children: ReactElement;
}

const Nav = ({ children }: Props) => {
  const {
    cloudUIBaseUrl,
    environmentDesc,
    showCloudNav = false,
  } = useSelector((state: RootState) => getSettingsState(state));
  const showSideNav = useSelector((state: RootState) => getHomeState(state).showSideNav);
  const dispatch = useDispatch();

  if (!showCloudNav) {
    return children;
  }

  return (
    <CloudNavLayout
      env={getNavEnv(environmentDesc, cloudUIBaseUrl)}
      currentHost={HostName.BAAS}
      enabledFeatures={{
        sideNav: showSideNav,
      }}
      onNavigation={({ host, path }: LinkInterface) => {
        if (host === HostName.BAAS) {
          // TODO(LG-4651): use event parameter instead of global event
          // eslint-disable-next-line no-restricted-globals
          event?.preventDefault();
          // eslint-disable-next-line no-restricted-globals
          event?.stopPropagation();
          dispatch(redirectToAction(path));
        }
      }}
      sideNavVariant={SideNavVariant.Default}
    >
      {children}
    </CloudNavLayout>
  );
};

export default Nav;
