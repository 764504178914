import React, { createContext, useMemo, useState } from 'react';

interface ContextData {
  inserted: boolean;
  setInserted: (inserted: boolean) => void;
}
export const SnippetsContext = createContext<ContextData>({
  inserted: false,
  setInserted: () => {},
});

export const SnippetsContextProvider: React.FC = ({ children }) => {
  const [inserted, setInserted] = useState(false);

  const memoizedData = useMemo(
    () => ({
      inserted,
      setInserted,
    }),
    [inserted]
  );

  return <SnippetsContext.Provider value={memoizedData}>{children}</SnippetsContext.Provider>;
};
