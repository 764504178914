import React from 'react';
import Banner from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import Modal from '@leafygreen-ui/modal';
import { Body, H3 } from '@leafygreen-ui/typography';

import './understanding-drafts-modal.less';

const baseClassName = 'understanding-drafts-modal';

export enum TestSelector {
  GotIt = 'got-it',
}

export interface Props {
  open: boolean;
  onClose(): void;
}

export default function UnderstandingFirstDraftModal({ open, onClose }: Props) {
  const openHandler = (isOpen: boolean) => {
    if (!isOpen) {
      onClose();
    }
  };

  return (
    <Modal open={open} setOpen={openHandler} className={baseClassName}>
      <H3 className={`${baseClassName}-header`}>You Just Saved a Draft</H3>
      <Body>
        Remember: automatic deployment to Github is <b>ON</b>.
      </Body>
      <Banner className={`${baseClassName}-banner`}>
        Be careful: if you make any further changes in GitHub before you deploy changes made in the UI, you will lose
        the changes made in the UI. You can always export your draft from the UI to resolve conflicts manually.
      </Banner>
      <div className={`${baseClassName}-footer`}>
        <Button className={`${baseClassName}-footer-button`} onClick={onClose} data-test-selector={TestSelector.GotIt}>
          Got it!
        </Button>
      </div>
    </Modal>
  );
}
