import { is, Record } from 'immutable';

export default class InputState extends Record({
  input: '',
  error: undefined,
  data: '',
}) {
  constructor(data) {
    const inputData = data || '';
    super({ data: inputData, input: inputData });
  }

  discardChanges() {
    return new InputState(this.data);
  }

  get dirty() {
    return !is(this.input, this.data);
  }
}
