import React from 'react';
import { List, Record } from 'immutable';

import loadable from 'baas-ui/common/components/loadable';

const ActionEditRules = loadable(() =>
  import(/* webpackChunkName: 'ActionEditRules' */ '../services/default/components/ActionEditRules')
);

export default class AvailableService extends Record({
  type: '',
  actions: List(),
  description: '',
  displayName: '',
  displayNameLong: '',
  defaultTab: '',
  incomingWebhooks: false,
  configEditor: null,
  configForm: null,
  rulesEditor: ActionEditRules,
  incomingWebhookOptionsEditor: null,
  incomingWebhookCurlExample: null,
  defaultIncomingWebhook: null,
  defaultDebugSource: null,
  defaultRules: {},
  icon: <span />,
  hideAdd: false,
  hideSidebar: false,
  forProduct: null,
}) {
  getLongDisplayName() {
    return this.displayNameLong || this.displayName;
  }

  getConfigEditor(props) {
    if (!this.configEditor) {
      return null;
    }
    return React.createElement(this.configEditor, props);
  }

  getRulesEditor(props) {
    const outProps = props;
    outProps.actions = this.actions.map((a) => a.name).toArray();
    return React.createElement(this.rulesEditor, outProps);
  }
}
