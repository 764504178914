import React, { useEffect, useState } from 'react';
import ConfirmationModal, { Variant } from '@leafygreen-ui/confirmation-modal';
import ArrowLeftIcon from '@leafygreen-ui/icon/dist/ArrowLeft';
import IconButton from '@leafygreen-ui/icon-button';
import TextInput, { State } from '@leafygreen-ui/text-input';
import { H3 } from '@leafygreen-ui/typography';
import classNames from 'classnames';
import JSON5 from 'json5';
import { useDebouncedCallback } from 'use-debounce';

import { prettyJSONStringify } from 'baas-ui/common/utils/util';
import { PARSE_JSON_DEBOUNCE_TIMEOUT } from 'baas-ui/rules/constants';
import ExpressionEditorFormRow from 'baas-ui/rules/rule-viewer/common/ExpressionEditorFormRow';
import SaveFooter from 'baas-ui/rules/rule-viewer/common/SaveFooter';
import { useRulesPageContext } from 'baas-ui/rules/useRulesPageContext';
import { validateRoleOrFilterName } from 'baas-ui/rules/validation';
import { MongoDBRuleFilter } from 'admin-sdk';

import * as fc from './constants';

import './edit-filter-form.less';

export enum TestSelector {
  ApplyWhenExpEditor = 'apply-when-editor',
  BackNavIcon = 'back-nav-icon',
  CancelAndExitModal = 'cancel-exit-modal',
  NameInput = 'name-input',
  ProjectionExpEditor = 'projection-editor',
  QueryExpEditor = 'query-editor',
  SaveFooterC = 'save-footer',
  Title = 'title',
}

export interface Props {
  filter?: MongoDBRuleFilter;
  onClickBackOrCancel: () => void;
  onChange: (filter: MongoDBRuleFilter) => void;
}

const baseClassName = 'edit-filter-form';

export const EditFilterFormComponent = ({ filter, onClickBackOrCancel, onChange }: Props) => {
  const [filterDisplayName, setFilterDisplayName] = useState(filter?.name || '');
  const [applyWhenDisplayVal, setApplyWhenDisplayVal] = useState(prettyJSONStringify(filter?.applyWhen || {}));
  const [queryDisplayVal, setQueryDisplayVal] = useState(prettyJSONStringify(filter?.query || {}));
  const [projDisplayVal, setProjDisplayVal] = useState(prettyJSONStringify(filter?.projection || {}));

  // JSON parse states
  const [applyWhenParsedObj, setApplyWhenParsedObj] = useState(
    JSON5.parse(prettyJSONStringify(filter?.applyWhen || {}))
  );
  const [applyWhenParseError, setApplyWhenParseError] = useState('');
  const [queryParsedObj, setQueryParsedObj] = useState(JSON5.parse(prettyJSONStringify(filter?.query || {})));
  const [queryParseError, setQueryParseError] = useState('');
  const [projectionParsedObj, setProjectionParsedObj] = useState(
    JSON5.parse(prettyJSONStringify(filter?.projection || {}))
  );
  const [projectionParseError, setProjectionParseError] = useState('');

  const [filterDisplayNameError, setFilterDisplayNameError] = useState<string>('');
  const [isCancelChangesModalOpen, setIsCancelChangesModalOpen] = useState<boolean>(false);

  const { hasChanges, setHasClientValidationError, setOnDiscardChanges } = useRulesPageContext();

  useEffect(() => {
    setOnDiscardChanges(() => setIsCancelChangesModalOpen(true));
  }, []);

  // Apply When JSON Parsing
  const parseApplyWhenJSON = (applyWhenVal: string) => {
    setApplyWhenParseError('');
    try {
      const parsedApplyWhen = JSON5.parse(applyWhenVal);
      setApplyWhenParsedObj(parsedApplyWhen);
    } catch (e) {
      setApplyWhenParseError(e.message);
    }
  };

  const parseApplyWhenJSONDebounced = useDebouncedCallback(parseApplyWhenJSON, PARSE_JSON_DEBOUNCE_TIMEOUT);

  useEffect(() => {
    parseApplyWhenJSONDebounced(applyWhenDisplayVal);
  }, [applyWhenDisplayVal]);

  // Query JSON Parsing
  const parseQueryJSON = (queryVal: string) => {
    setQueryParseError('');
    try {
      const parsedQuery = JSON5.parse(queryVal);
      setQueryParsedObj(parsedQuery);
    } catch (e) {
      setQueryParseError(e.message);
    }
  };

  const parseQueryJSONDebounced = useDebouncedCallback(parseQueryJSON, PARSE_JSON_DEBOUNCE_TIMEOUT);

  useEffect(() => {
    parseQueryJSONDebounced(queryDisplayVal);
  }, [queryDisplayVal]);

  // Projection JSON Parsing
  const parseProjectionJSON = (projVal: string) => {
    setProjectionParseError('');
    try {
      const parsedProjection = JSON5.parse(projVal);
      setProjectionParsedObj(parsedProjection);
    } catch (e) {
      setProjectionParseError(e.message);
    }
  };

  const parseProjectionJSONDebounced = useDebouncedCallback(parseProjectionJSON, PARSE_JSON_DEBOUNCE_TIMEOUT);

  useEffect(() => {
    parseProjectionJSONDebounced(projDisplayVal);
  }, [projDisplayVal]);

  useEffect(() => {
    const constructedFilter = new MongoDBRuleFilter({
      name: filterDisplayName,
      applyWhen: applyWhenParsedObj,
      query: queryParsedObj,
      projection: projectionParsedObj,
    });

    onChange(constructedFilter);
  }, [filterDisplayName, applyWhenParsedObj, queryParsedObj, projectionParsedObj]);

  return (
    <div className={baseClassName} data-cy="edit-filter-form">
      <div className={`${baseClassName}-header`}>
        <IconButton
          aria-label="back-nav"
          className={`${baseClassName}-back-nav`}
          data-cy="back-nav-icon"
          data-test-selector={TestSelector.BackNavIcon}
          onClick={() => (hasChanges ? setIsCancelChangesModalOpen(true) : onClickBackOrCancel())}
        >
          <ArrowLeftIcon />
        </IconButton>
        <H3 className={`${baseClassName}-header-title`} data-test-selector={TestSelector.Title}>
          {filterDisplayName ? `Edit ${filterDisplayName}` : 'Configure a new filter'}
        </H3>
      </div>

      <div className={`${baseClassName}-form`}>
        <TextInput
          data-cy="name-input"
          label="Filter name"
          placeholder="Enter filter name"
          value={filterDisplayName}
          onChange={(e) => setFilterDisplayName(e.target.value)}
          type="text"
          className={classNames(`${baseClassName}-name-input`, `${baseClassName}-form-input-section`)}
          data-test-selector={TestSelector.NameInput}
          onBlur={() => {
            const validationError = validateRoleOrFilterName(filterDisplayName);
            setFilterDisplayNameError(validationError);
            setHasClientValidationError(!!validationError);
          }}
          errorMessage={filterDisplayNameError}
          state={filterDisplayNameError ? State.Error : State.None}
          autoFocus
        />

        <hr className={`${baseClassName}-divider`} />

        <ExpressionEditorFormRow
          data-cy={TestSelector.ApplyWhenExpEditor}
          labelText="Apply When"
          data-test-selector={TestSelector.ApplyWhenExpEditor}
          description={fc.applyWhenDescription}
          exampleCode={fc.applyWhenExampleCode}
          exampleText={fc.applyWhenExampleText}
          expressionStr={applyWhenDisplayVal}
          onChangeExpression={setApplyWhenDisplayVal}
          className={`${baseClassName}-form-input-section`}
          parsingError={applyWhenParseError}
        />

        <hr className={`${baseClassName}-divider`} />

        <ExpressionEditorFormRow
          data-cy={TestSelector.QueryExpEditor}
          labelText="Query (document-level filter)"
          data-test-selector={TestSelector.QueryExpEditor}
          description={fc.queryDescription}
          exampleCode={fc.queryExampleCode}
          exampleText={fc.queryExampleText}
          expressionStr={queryDisplayVal}
          onChangeExpression={setQueryDisplayVal}
          className={`${baseClassName}-form-input-section`}
          parsingError={queryParseError}
        />

        <hr className={`${baseClassName}-divider`} />

        <ExpressionEditorFormRow
          data-cy={TestSelector.ProjectionExpEditor}
          labelText="Projection (field-level filter)"
          data-test-selector={TestSelector.ProjectionExpEditor}
          description={fc.projDescription}
          exampleCode={fc.projExampleCode}
          exampleText={fc.projExampleText}
          expressionStr={projDisplayVal}
          onChangeExpression={setProjDisplayVal}
          className={`${baseClassName}-form-input-section`}
          parsingError={projectionParseError}
        />

        <hr className={`${baseClassName}-divider`} />

        <SaveFooter
          data-test-selector={TestSelector.SaveFooterC}
          namePrefix="filter"
          onCancel={() => setIsCancelChangesModalOpen(true)}
        />
      </div>
      <ConfirmationModal
        className={`${baseClassName}-cancel-modal`}
        title={filterDisplayName ? `Discard changes to filter: ${filterDisplayName}` : 'Discard changes to filter'}
        confirmButtonProps={{
          children: 'OK',
          onClick: onClickBackOrCancel,
        }}
        cancelButtonProps={{
          onClick: () => setIsCancelChangesModalOpen(false),
        }}
        variant={Variant.Danger}
        open={isCancelChangesModalOpen}
        data-test-selector={TestSelector.CancelAndExitModal}
        data-cy="cancel-exit-modal"
      >
        Are you sure you want to discard your changes to{' '}
        {filterDisplayName ? (
          <span>
            <b>filter: {filterDisplayName}</b>?
          </span>
        ) : (
          <span>the current filter?</span>
        )}
      </ConfirmationModal>
    </div>
  );
};

export default EditFilterFormComponent;
