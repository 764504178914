import React, { useEffect, useState } from 'react';
import Button from '@leafygreen-ui/button';
import Code from '@leafygreen-ui/code';
import Copyable from '@leafygreen-ui/copyable';
import Modal from '@leafygreen-ui/modal';
import Stepper, { Step } from '@leafygreen-ui/stepper';
import { Tab, Tabs } from '@leafygreen-ui/tabs';
import { Body, H3, InlineCode, Link } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import CollapsibleExample from 'baas-ui/common/components/collapsible-example';
import { docLinks } from 'baas-ui/common/links';
import { PermissionDisplayValue } from 'baas-ui/rules/permission-display';
import { RoleInfoTable } from 'baas-ui/rules/rule-viewer/common/RoleInfoTable';

import './understanding-roles-and-filters-modal.less';

export enum TestSelector {
  ModalC = 'modal',
  DefiningRolesPage = 'defining-roles-page',
  FilteringQueriesPage = 'filtering-queries-page',
  ModalForwardButton = 'modal-forward-button',
  ModalBackButton = 'modal-back-button',
}

export interface Props {
  open: boolean;
  setOpen(open: boolean): void;
  activeModalStep?: ModalStep;
}

export enum ModalStep {
  DefiningRoles,
  FilteringQueries,
}

enum CollTab {
  UnfilteredCollection,
  FilteredCollection,
}

const baseClassName = 'understanding-roles-and-filters-modal';

const applyWhenExp = { '%%user.custom_data.role': 'employee' };

const unfilteredCollectionContent = `  { "_id": ObjectId(...), "name": "sarah", age: 42, "vote": "yes"}
{ "_id": ObjectId(...), "name": "andy", age: 22, "vote": "no"}
{ "_id": ObjectId(...), "name": "jennifer", age: 37, "vote": "yes"}
{ "_id": ObjectId(...), "name": "rick", age: 43, "vote": "no"}
{ "_id": ObjectId(...), "name": "tom", age: 64, "vote": "yes"}
{ "_id": ObjectId(...), "name": "bob", age: 67, "vote": "yes"}`;

const filteredCollectionContent = `  { "_id": ObjectId(...), "name": "sarah", age: 42, "vote": "yes"}
{ "_id": ObjectId(...), "name": "jennifer", age: 37, "vote": "yes"}
{ "_id": ObjectId(...), "name": "rick", age: 43, "vote": "no"}
{ "_id": ObjectId(...), "name": "tom", age: 64, "vote": "yes"}
{ "_id": ObjectId(...), "name": "bob", age: 67, "vote": "yes"}`;

function UnderstandingRolesAndFiltersModal({ open, setOpen, activeModalStep = ModalStep.DefiningRoles }: Props) {
  const [currentStep, setCurrentStep] = useState(activeModalStep);
  const [selectedCollTab, setSelectedCollTab] = useState(CollTab.UnfilteredCollection);

  useEffect(() => {
    setCurrentStep(activeModalStep);
  }, [activeModalStep]);

  const handleForwardButtonClick = () => {
    if (currentStep === ModalStep.FilteringQueries) {
      setOpen(false);
    } else {
      setCurrentStep(ModalStep.FilteringQueries);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={baseClassName}
      data-testid={TestSelector.ModalC}
      data-cy={baseClassName}
    >
      <H3 className={`${baseClassName}-header`}>Understanding Roles and Filters</H3>
      <Stepper currentStep={currentStep} maxDisplayedSteps={2} className={`${baseClassName}-stepper`}>
        <Step>Defining Roles</Step>
        <Step>Filtering queries</Step>
      </Stepper>

      <div className={`${baseClassName}-body`}>
        {currentStep === ModalStep.DefiningRoles && (
          <div data-testid={TestSelector.DefiningRolesPage}>
            <Body>
              A <Link href={docLinks.Rules.Roles}>role</Link>
              is a named set of permissions that an end-user can have for a document when making a request to the app.
              Roles are evaluated in order from top to bottom using the{' '}
              <InlineCode className={`${baseClassName}-body-code`}>apply_when</InlineCode> expression.
            </Body>
            <CollapsibleExample className={`${baseClassName}-view-example`}>
              <div className={`${baseClassName}-view-example-content-wrapper`}>
                <Body className={`${baseClassName}-view-example-description`}>
                  In a collection named <InlineCode className={`${baseClassName}-body-code`}>company</InlineCode>, each
                  employee has their own document with all of their employment data. This collection has a role called{' '}
                  <b>Employee</b>. Users in this role can read and write their own data but can&apos;t create or delete
                  their own documents:
                </Body>
                <RoleInfoTable
                  applyWhenExp={applyWhenExp}
                  insertDisplayVal={PermissionDisplayValue.IconX}
                  deleteDisplayVal={PermissionDisplayValue.IconX}
                  searchDisplayVal={PermissionDisplayValue.IconCheck}
                  readDisplayVal={PermissionDisplayValue.TextAll}
                  writeDisplayVal={PermissionDisplayValue.TextAll}
                />
              </div>
            </CollapsibleExample>
          </div>
        )}
        {currentStep === ModalStep.FilteringQueries && (
          <div data-testid={TestSelector.FilteringQueriesPage}>
            <Body>
              <Link href={docLinks.Rules.Filters}>Filters</Link>
              modify an incoming MongoDB query to return only a subset of the results matched by the query. Filters will
              filter out data that is returned by MongoDB before roles are evaluated, making them useful for request
              performance improvements.
            </Body>
            <CollapsibleExample className={`${baseClassName}-view-example`}>
              <div className={`${baseClassName}-view-example-content-wrapper`}>
                <Body>
                  In a voting app where some users have agreed to anonymously share their vote, you could use a filter
                  to constrain all queries to an anonymous subset of the existing data:
                </Body>
                <Copyable label="Filter" size="default">{`{"age": {"$gt": 30}}`}</Copyable>
                <Tabs
                  setSelected={setSelectedCollTab}
                  selected={selectedCollTab}
                  className={`${baseClassName}-view-example-tabs`}
                  aria-label="Collection Examples"
                >
                  <Tab name="Unfiltered Collection">
                    <Code language="none">{unfilteredCollectionContent}</Code>
                  </Tab>
                  <Tab name="Filtered Collection">
                    <Code language="none">{filteredCollectionContent}</Code>
                  </Tab>
                </Tabs>
              </div>
            </CollapsibleExample>
          </div>
        )}
      </div>
      <div
        className={classNames(`${baseClassName}-footer`, {
          [`${baseClassName}-footer-flex-end`]: currentStep === ModalStep.DefiningRoles,
          [`${baseClassName}-footer-space-between`]: currentStep === ModalStep.FilteringQueries,
        })}
      >
        {currentStep === ModalStep.FilteringQueries && (
          <Button
            className={`${baseClassName}-button`}
            onClick={() => setCurrentStep(ModalStep.DefiningRoles)}
            data-testid={TestSelector.ModalBackButton}
          >
            Back
          </Button>
        )}
        <Button
          className={`${baseClassName}-button`}
          onClick={handleForwardButtonClick}
          data-testid={TestSelector.ModalForwardButton}
        >
          {currentStep === ModalStep.DefiningRoles && 'Next'}
          {currentStep === ModalStep.FilteringQueries && 'Got it!'}
        </Button>
      </div>
    </Modal>
  );
}

export default UnderstandingRolesAndFiltersModal;
