import React from 'react';
import Icon from '@leafygreen-ui/icon';
import classNames from 'classnames';

import { passThroughProps } from 'baas-ui/common/utils/util';

import './collapsible.less';

export interface Props {
  open?: boolean;
  headerContent?: React.ReactNode;
  children?: React.ReactNode;
  hoverControls?: React.ReactNode;
  dirty?: boolean;
  deleted?: boolean;
  isNew?: boolean;
  className?: string;
  wide?: boolean;
  accordion?: boolean;
  darkMode?: boolean;
  toggle?(): any;
}

export enum TestSelector {
  CollapsibleContainer = 'collapsible-container',
  CollapsibleHeader = 'collapsible-header',
  HeaderContent = 'header-content',
  HoverControls = 'hover-controls',
  CollapsibleHeaderToggle = 'collapsible-header-toggle',
  AccordionHeaderContent = 'accordion-header-content',
  CollapsibleContent = 'collapsible-content',
}

const baseClassName = 'collapsible';
const headerClassName = `${baseClassName}-header`;
const toggleClassName = `${headerClassName}-toggle`;
const contentClassName = `${baseClassName}-content`;

export default function Collapsible({
  open,
  toggle,
  headerContent,
  children,
  hoverControls,
  className,
  dirty,
  deleted,
  isNew,
  wide,
  accordion,
  darkMode,
  ...rest
}: Props) {
  const headerContentClassNames = classNames(`${headerClassName}-content`, {
    [`${headerClassName}-content-accordion`]: accordion,
  });

  return (
    <div
      className={classNames(
        baseClassName,
        {
          [`${baseClassName}-is-open`]: open,
          [`${baseClassName}-is-dirty`]: dirty,
          [`${baseClassName}-is-deleted`]: deleted,
          [`${baseClassName}-is-new`]: isNew,
          [`${baseClassName}-accordion`]: accordion,
        },
        className
      )}
      data-testid={TestSelector.CollapsibleContainer}
      {...passThroughProps(rest)}
    >
      <div
        className={classNames(headerClassName, {
          [`${headerClassName}-is-open`]: open,
          [`${headerClassName}-is-dirty`]: dirty,
          [`${headerClassName}-is-deleted`]: deleted,
          [`${headerClassName}-is-new`]: isNew,
          [`${headerClassName}-accordion`]: accordion,
          [`${headerClassName}-accordion-light-mode`]: accordion && !darkMode,
          [`${headerClassName}-accordion-dark-mode`]: accordion && darkMode,
        })}
        onClick={toggle}
        data-testid={TestSelector.CollapsibleHeader}
      >
        {!accordion && (
          <div className={headerContentClassNames} data-testid={TestSelector.HeaderContent}>
            {headerContent}
          </div>
        )}
        {hoverControls && (
          <div className={`${headerClassName}-controls`} data-testid={TestSelector.HoverControls}>
            {hoverControls}
          </div>
        )}
        <div
          className={classNames(toggleClassName, {
            [`${toggleClassName}-open`]: open && !accordion,
            [`${toggleClassName}-accordion`]: accordion,
            [`${toggleClassName}-accordion-open`]: accordion && open,
          })}
          data-testid={TestSelector.CollapsibleHeaderToggle}
        >
          <Icon glyph="ChevronDown" size={'large'} />
        </div>
        {accordion && (
          <div className={headerContentClassNames} data-testid={TestSelector.AccordionHeaderContent}>
            {headerContent}
          </div>
        )}
      </div>
      <div
        className={classNames(contentClassName, {
          [`${contentClassName}-is-hidden`]: !open,
          [`${contentClassName}-is-wide`]: wide,
          [`${contentClassName}-accordion`]: accordion,
        })}
        data-testid={TestSelector.CollapsibleContent}
        hidden={!open}
      >
        {children}
      </div>
    </div>
  );
}
