import { createAction } from 'redux-act';

import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { RealmLanguage } from 'baas-ui/sdks/types';
import { BaseRequestPayload } from 'baas-ui/types';
import {
  AllowedAsymmetricTables,
  AllowedQueryableFields,
  ClusterStorageMetrics,
  GetSchemaVersionsResponse,
  GetSyncAlertsResponse,
  GetSyncStateResponse,
  PartitionKeyType,
  PatchSyncSchemasRequest,
  PutSyncMigrationRequest,
  SyncClientSchema,
  SyncConfig,
  SyncData,
  SyncMigrationAction,
  SyncMigrationPrecheck,
  SyncMigrationStatus,
  SyncProgress,
} from 'admin-sdk';

import { PermissionsErrorState, SyncType } from './types';

const NAME = 'sync ';

interface UpdateConfigPayload extends BaseRequestPayload {
  config: SyncConfig;
}

interface LoadClientSchemasPayload extends BaseRequestPayload {
  language: RealmLanguage;
  filter?: string[];
  schemaVersion?: number;
}

interface LoadSyncDataPayload extends BaseRequestPayload {
  serviceId?: string;
}

interface LoadQueryableFieldPayload extends BaseRequestPayload {
  serviceId?: string;
}

interface LoadAsymmetricTablesPayload extends BaseRequestPayload {
  serviceId?: string;
}

interface PatchSchemasPayload extends BaseRequestPayload {
  serviceId: string;
  partitionKey: string;
  partitionKeyRequired?: boolean;
  partitionKeyType: PartitionKeyType;
}

interface LoadClusterStorageMetrics extends BaseRequestPayload {
  clusterName: string;
}

export interface GetSyncStatePayload extends BaseRequestPayload {
  syncType: SyncType;
}

export interface PutSyncMigrationPayload extends BaseRequestPayload {
  serviceId: string;
  action: SyncMigrationAction;
}

export const setDevelopmentModeEnabled = createAction<boolean>(`${NAME}set development mode enabled`);

export const setDataServiceId = createAction<string>(`${NAME}set data serviceId`);
export const setConfigServiceId = createAction<string>(`${NAME}set config serviceId`);
export const setPermissionsError = createAction<PermissionsErrorState>(`${NAME}set permissions error`);
export const setFlexiblePermissionsError = createAction<string>(`${NAME}set flexible permissions error`);
export const setPauseSyncInProgress = createAction<boolean>(`${NAME}set pause sync in progress`);
export const setTerminateSyncInProgress = createAction<boolean>(`${NAME}set terminate sync in progress`);
export const setPauseSyncError = createAction<string>(`${NAME}set pause sync error`);
export const setTerminateSyncError = createAction<string>(`${NAME}set terminate sync error`);
export const setSyncEventSubscriptionError = createAction<string>(`${NAME}set sync event subscription error`);
export const setSyncEnabledToastState = createAction<string>(`${NAME}set sync enabled status`);

export const [loadConfigActions, loadConfig] = createActionsAndExecutor<BaseRequestPayload, SyncConfig>(
  `${NAME}load config`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).sync().config().get()
);

export const [updateConfigActions, updateConfig] = createActionsAndExecutor<UpdateConfigPayload, void>(
  `${NAME}update config`,
  (client, { groupId, appId, config }) =>
    () => {
      return client.apps(groupId).app(appId).sync().config().update(config);
    }
);

export const [loadClientSchemasActions, loadClientSchemas] = createActionsAndExecutor<
  LoadClientSchemasPayload,
  SyncClientSchema[]
>(`${NAME}load client schemas`, (client, { groupId, appId, language, filter, schemaVersion }) => () => {
  return client.apps(groupId).app(appId).sync().clientSchemas().get(language, filter, schemaVersion);
});

export const setProgressPollCount = createAction<number>(`${NAME}set progress poll count`);

export const dismissProgress = createAction(`${NAME}dismiss progress`);

export const [loadProgressActions, loadProgress] = createActionsAndExecutor<BaseRequestPayload, SyncProgress>(
  `${NAME}load progress`,
  (client, { appId, groupId }) =>
    () =>
      client.apps(groupId).app(appId).sync().progress()
);

export const [loadClusterStorageMetrics, getClusterStorageMetrics] = createActionsAndExecutor<
  LoadClusterStorageMetrics,
  ClusterStorageMetrics
>(
  `${NAME}load cluster storage metrics`,
  (client, { groupId, clusterName }) =>
    () =>
      client.private().group(groupId).atlasClusters().cluster(clusterName).storageMetrics().get()
);

export const [loadDataActions, loadData] = createActionsAndExecutor<LoadSyncDataPayload, SyncData>(
  `${NAME}load data`,
  (client, { groupId, appId, serviceId }) =>
    () =>
      client.apps(groupId).app(appId).sync().data(serviceId)
);

export const [patchSchemasActions, patchSchemas] = createActionsAndExecutor<PatchSchemasPayload, void>(
  `${NAME}patch schemas`,
  (client, { groupId, appId, serviceId, partitionKey, partitionKeyType, partitionKeyRequired }) =>
    () =>
      client.apps(groupId).app(appId).sync().schemas().patch(
        new PatchSyncSchemasRequest({
          serviceId,
          partitionKey,
          partitionKeyType,
          partitionKeyRequired,
        })
      )
);

export const [loadQueryableFieldsAction, loadQueryableFields] = createActionsAndExecutor<
  LoadQueryableFieldPayload,
  AllowedQueryableFields
>(`${NAME}get queryable fields`, (client, { groupId, appId, serviceId }) => () => {
  if (!serviceId) {
    return Promise.resolve(new AllowedQueryableFields());
  }
  return client.apps(groupId).app(appId).sync().allowedQueryableFields(serviceId);
});

export const [loadAsymmetricTablesAction, loadAsymmetricTables] = createActionsAndExecutor<
  LoadAsymmetricTablesPayload,
  AllowedAsymmetricTables
>(`${NAME}get asymmetric tables`, (client, { groupId, appId, serviceId }) => () => {
  if (!serviceId) {
    return Promise.resolve(new AllowedAsymmetricTables());
  }
  return client.apps(groupId).app(appId).sync().allowedAsymmetricTables(serviceId);
});

export const [getSyncStateAction, getSyncState] = createActionsAndExecutor<GetSyncStatePayload, GetSyncStateResponse>(
  `${NAME}get sync state`,
  (client, { appId, groupId, syncType }) =>
    () =>
      client.apps(groupId).app(appId).sync().state().get(syncType)
);

export const setMigrationStatusPollCount = createAction<number>(`${NAME}set migration status poll count`);

export const [loadSyncMigrationPrecheckAction, loadSyncMigrationPrecheck] = createActionsAndExecutor<
  BaseRequestPayload,
  SyncMigrationPrecheck
>(
  `${NAME}load migration precheck`,
  (client, { appId, groupId }) =>
    () =>
      client.apps(groupId).app(appId).sync().migration().precheck()
);

export const [loadSyncMigrationStatusAction, loadSyncMigrationStatus] = createActionsAndExecutor<
  BaseRequestPayload,
  SyncMigrationStatus
>(
  `${NAME}load migration status`,
  (client, { appId, groupId }) =>
    () =>
      client.apps(groupId).app(appId).sync().migration().status()
);

export const [putSyncMigrationAction, putSyncMigration] = createActionsAndExecutor<PutSyncMigrationPayload, void>(
  `${NAME}put migration`,
  (client, { groupId, appId, serviceId, action }) =>
    () =>
      client.apps(groupId).app(appId).sync().migration().put(new PutSyncMigrationRequest({ serviceId, action }))
);

export const [loadSchemaVersionsAction, loadSchemaVersions] = createActionsAndExecutor<
  BaseRequestPayload,
  GetSchemaVersionsResponse
>(
  `${NAME}load schema versions`,
  (client, { appId, groupId }) =>
    () =>
      client.apps(groupId).app(appId).sync().schemas().versions().get()
);

export const [getSyncAlertsAction, getSyncAlerts] = createActionsAndExecutor<BaseRequestPayload, GetSyncAlertsResponse>(
  `${NAME}get sync alerts`,
  (client, req) => () => client.apps(req.groupId).app(req.appId).sync().alerts().get()
);
