import { createAction } from 'redux-act';

import { handleResourceNotFound } from 'baas-ui/action_wrappers';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { track } from 'baas-ui/tracking';
import { BaseRequestPayload } from 'baas-ui/types';
import {
  AppFunction,
  ClientAppFunction,
  CreateAppFunction,
  DebugExecuteFunctionResponse,
  PartialAppFunction,
  User,
} from 'admin-sdk';

const NAME = 'functions/';

interface FunctionRequest extends BaseRequestPayload {
  functionId: string;
}

interface CreateAppFunctionPayload extends BaseRequestPayload {
  createAppFunction: CreateAppFunction;
}

interface UpdateAppFunctionPayload extends BaseRequestPayload {
  appFunction: AppFunction;
}

export interface ExecuteDebugSourcePayload extends BaseRequestPayload {
  userId: string;
  source: string;
  evalSource: string;
  runAsSystem: boolean;
}

type LoadFunctionsRequest = BaseRequestPayload;
type FetchFunctionRequest = FunctionRequest;
export type CreateFunctionRequest = CreateAppFunctionPayload;
type UpdateFunctionRequest = FunctionRequest & UpdateAppFunctionPayload;
type RemoveFunctionRequest = FunctionRequest;

// editor action types
export const openDefaultEditor = createAction(`${NAME}open default editor`);
export const updateEditor = createAction<Partial<AppFunction>>(`${NAME}update editor`);
export const discardChanges = createAction(`${NAME}discard changes`);
export const updateEditorError = createAction<{ error: string }>(`${NAME}update editor error`);
export const clearErrors = createAction(`${NAME}clear function errors`);

// user actions
export const selectUser = createAction<User | undefined>(`${NAME}select user`);
export const clearSelectedUser = createAction(`${NAME}clear selected user`);

// debug console actions
export const updateDebugSource = createAction<string>(`${NAME} update debug source`);
export const clearDebugSource = createAction(`${NAME} clear debug console source`);
export const clearDebugResultSource = createAction(`${NAME} clear debug result source`);

const trackFunctionSaveError =
  ({ id, name }) =>
  (e) => {
    track('FUNCTIONS.SAVE_FAILED', { errMsg: e.message, functionId: id, functionName: name });
    throw e;
  };

// api actions
export const [loadFunctionsActions, loadFunctions] = createActionsAndExecutor<
  LoadFunctionsRequest,
  PartialAppFunction[]
>(`${NAME}load`, (client, { groupId, appId }) => client.apps(groupId).app(appId).functions().list);

const [fetchFunctionActions, _fetchFunc] = createActionsAndExecutor<FetchFunctionRequest, ClientAppFunction>(
  `${NAME}fetch`,
  (client, { groupId, appId, functionId }) => client.apps(groupId).app(appId).functions().function(functionId).get
);
const fetchFunction = handleResourceNotFound(_fetchFunc);
export { fetchFunctionActions, fetchFunction };

export const [createFunctionActions, createFunction] = createActionsAndExecutor<
  CreateFunctionRequest,
  PartialAppFunction
>(`${NAME}create`, (client, { groupId, appId, createAppFunction }) => () => {
  track('FUNCTIONS.SAVE_SUBMITTED', createAppFunction);
  return client
    .apps(groupId)
    .app(appId)
    .functions()
    .create(createAppFunction)
    .catch((err) => {
      track('FUNCTIONS.SAVE_FAILED', {
        errMsg: err.message,
        functionName: createAppFunction.name,
        newFunctionPayload: createAppFunction,
      });
      throw err;
    });
});

export const [updateFunctionActions, updateFunction] = createActionsAndExecutor<UpdateFunctionRequest, void>(
  `${NAME}update`,
  (client, { groupId, appId, functionId, appFunction }) =>
    () => {
      track('FUNCTIONS.SAVE_SUBMITTED', appFunction);
      return client
        .apps(groupId)
        .app(appId)
        .functions()
        .function(functionId)
        .update(appFunction)
        .catch(trackFunctionSaveError(appFunction));
    }
);

export const [removeFunctionActions, removeFunction] = createActionsAndExecutor<RemoveFunctionRequest, any>(
  `${NAME}remove`,
  (client, { groupId, appId, functionId }) =>
    () =>
      client.apps(groupId).app(appId).functions().function(functionId).remove()
);

export const [executeDebugSourceActions, executeDebugSource] = createActionsAndExecutor<
  ExecuteDebugSourcePayload,
  DebugExecuteFunctionResponse
>(
  `${NAME} execute debug source`,
  (client, { groupId, appId, userId, source, evalSource, runAsSystem }) =>
    () =>
      client.apps(groupId).app(appId).debug().executeFunctionSource({ userId, source, evalSource, runAsSystem })
);

export const asyncEditRcvActions = [createFunctionActions.rcv, updateFunctionActions.rcv, removeFunctionActions.rcv];
