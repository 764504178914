export const Auto = 'Auto' as const;

export enum Granularity {
  PT1M = 'PT1M',
  PT5M = 'PT5M',
  PT1H = 'PT1H',
  P1D = 'P1D',
}

export type SelectableGranularity = Granularity | typeof Auto;

export enum Period {
  PT1H = 'PT1H',
  PT4H = 'PT4H',
  PT24H = 'PT24H',
  P7D = 'P7D',
  P30D = 'P30D',
}

export const timeOptionLabels: Record<Granularity | Period, string> = {
  [Granularity.PT1M]: '1m',
  [Granularity.PT5M]: '5m',
  [Granularity.PT1H]: '1h',
  [Granularity.P1D]: '1d',
  [Period.PT1H]: '1h',
  [Period.PT4H]: '4h',
  [Period.PT24H]: '24h',
  [Period.P7D]: '7d',
  [Period.P30D]: '30d',
};

export const timeOptionMinutes: Record<Granularity | Period, number> = {
  [Granularity.PT1M]: 1,
  [Granularity.PT5M]: 5,
  [Granularity.PT1H]: 60,
  [Granularity.P1D]: 60 * 24,
  [Period.PT1H]: 60,
  [Period.PT4H]: 60 * 4,
  [Period.PT24H]: 60 * 24,
  [Period.P7D]: 60 * 24 * 7,
  [Period.P30D]: 60 * 24 * 30,
};

// retention in minutes for each granularity
// https://www.mongodb.com/docs/atlas/monitor-cluster-metrics/#metrics-data-retention
export const mdbRetention: Record<Granularity, number> = {
  [Granularity.PT1M]: 60 * 48,
  [Granularity.PT5M]: 60 * 48,
  [Granularity.PT1H]: 60 * 24 * 63,
  [Granularity.P1D]: -1,
};

export const allowableGranularitiesByPeriod = Object.values(Period).reduce<Record<Period, Granularity[]>>(
  (acc, period) => ({
    ...acc,
    [period]: Object.values(Granularity).filter(
      (granularity) =>
        timeOptionMinutes[granularity] < timeOptionMinutes[period] &&
        ((mdbRetention[granularity] || 0) === -1 || (mdbRetention[granularity] || 0) > timeOptionMinutes[period])
    ),
  }),
  { [Period.PT1H]: [], [Period.PT4H]: [], [Period.PT24H]: [], [Period.P7D]: [], [Period.P30D]: [] }
);

export const isInvalidControlsCombo = (period: Period, granularity: SelectableGranularity): boolean => {
  if (granularity === Auto) {
    return false;
  }
  return !allowableGranularitiesByPeriod[period].includes(granularity);
};

export const compatibleGranularity = (period: Period, granularity: SelectableGranularity): Granularity => {
  return granularity === Auto ? allowableGranularitiesByPeriod[period][0] : granularity;
};
