import React from 'react';
import Select from 'react-select';

import { selectStyles } from 'baas-ui/common/styles/ReactSelect.styles';
import { environmentOptions, EnvironmentSelectOption } from 'baas-ui/deploy/constants';
import { AppEnvironment } from 'admin-sdk';

import './environment-select.less';

const baseClassName = 'environment-select';

export interface Props {
  selectedEnvironment: EnvironmentSelectOption;
  setSelectedEnvironment(selectedEnvironment: EnvironmentSelectOption): void;
  isDisabled?: boolean;
}

const format = ({ label, value }) => {
  return (
    <>
      <span className={`${baseClassName}-label`}>{label}</span>{' '}
      {value === AppEnvironment.None && <span className={`${baseClassName}-subtext`}>(DEFAULT)</span>}
    </>
  );
};

const EnvironmentSelect = ({ selectedEnvironment, setSelectedEnvironment, isDisabled }: Props) => (
  <Select
    value={selectedEnvironment}
    options={environmentOptions}
    onChange={(selectedValue: EnvironmentSelectOption) => {
      setSelectedEnvironment(selectedValue);
    }}
    styles={selectStyles}
    formatOptionLabel={format}
    isDisabled={isDisabled}
  />
);

export default EnvironmentSelect;
