import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import MarketingModal from '@leafygreen-ui/marketing-modal';
import { Body } from '@leafygreen-ui/typography';

import { useLocalStorage } from 'baas-ui/common/hooks/use-local-storage';
import { docLinks } from 'baas-ui/common/links';
import { lastDimissedSyncMigrationModalKey } from 'baas-ui/common/local-storage-keys';
import { getAppState, getSyncState } from 'baas-ui/selectors';
import theme from 'baas-ui/theme';
import { onClickDocsLink } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import { SyncMigrationStatus } from 'admin-sdk';

import Icon from './icon';

interface PublicProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
}

interface ReduxStateProps {
  appId: string;
  migrationStatus?: SyncMigrationStatus;
}

export type Props = PublicProps & ReduxStateProps;

// TODO(BAAS-18418):The LG component has a mismatch between proptypes and typescript types.
// Remove the TS ignore once the problem has been addressed.
// eslint-disable-next-line
// @ts-ignore
const StyledModal = styled(MarketingModal)(() => ({
  zIndex: theme.zIndex.modal,
}));

export enum TestSelector {
  ModalSelector = 'sync-migration-success-modal',
}

export const SyncMigrationSuccessModal = ({ appId, isOpen, setIsOpen, migrationStatus }: Props) => {
  const [lastDismissedMigration, setLastDismissedMigration] = useLocalStorage(
    lastDimissedSyncMigrationModalKey(appId),
    0
  );

  React.useEffect(() => {
    if (migrationStatus && lastDismissedMigration > 0 && migrationStatus.startedAt.getTime() > lastDismissedMigration) {
      // a migration has been dismissed, but this is a new migration. unset the localstorage key
      setLastDismissedMigration(0);
    }
  }, [lastDismissedMigration, migrationStatus?.startedAt]);

  React.useEffect(() => {
    const showModal = (migrationStatus?.isMigrated || false) && lastDismissedMigration === 0;
    if (!isOpen && showModal) {
      // only open the modal if the migration completed in the last 24 hours
      const elapsedHours = (Date.now() - (migrationStatus?.lastUpdated.getTime() || 0)) / 1000 / 60 / 60;
      if (elapsedHours > 24) {
        return;
      }
    }

    setIsOpen(showModal);
  }, [migrationStatus?.isMigrated, migrationStatus?.lastUpdated, isOpen, lastDismissedMigration]);

  const onClose = () => {
    setLastDismissedMigration(migrationStatus?.startedAt.getTime() || 0);
    return true;
  };

  const oplogWindow = migrationStatus?.clusterTimeBasedOplogWindowHours;
  const oplogMessage = oplogWindow ? (
    <span>
      {' '}
      of <b>{oplogWindow} hours</b>
    </span>
  ) : (
    ''
  );

  const content = migrationStatus?.isRevertible ? (
    <>
      <Body>
        For the duration of your Oplog window{oplogMessage}, you will have the option to revert back to Partition-Based
        Sync.
      </Body>
      <br />
      <Body>
        After the Oplog window ends, your Partition-based Sync metadata will be deleted. You may choose to delete the
        metadata in the “Manage Sync Migration” menu.
      </Body>
    </>
  ) : (
    <Body>Partition-based sync metadata has been deleted and cannot be rolled back.</Body>
  );

  if (!isOpen) {
    return null;
  }

  const onLinkClick = () => {
    onClickDocsLink(docLinks.Sync.LearnMore);
    window.open(docLinks.Sync.LearnMore, '_blank');
  };

  return (
    <StyledModal
      title={<>Flexible Sync is Enabled!</>}
      graphic={<Icon />}
      open={isOpen}
      onButtonClick={onClose}
      onLinkClick={onLinkClick}
      onClose={onClose}
      buttonText="Close"
      linkText="Learn more about Flexible Sync"
      data-testid={TestSelector.ModalSelector}
      data-cy={TestSelector.ModalSelector}
      showBlob
      blobPosition="top right"
    >
      <div style={{ textAlign: 'left', marginTop: theme.leafygreen.spacing[3] }}>{content}</div>
    </StyledModal>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    app: { id: appId },
  } = getAppState(state);

  const migrationStatus = getSyncState(state)?.migration?.status;

  return {
    appId,
    migrationStatus,
  };
};

const mapDispatchToProps = () => ({});

const mergeProps = (
  { appId, migrationStatus }: ReturnType<typeof mapStateToProps>,
  dispatch: ReturnType<typeof mapDispatchToProps>,
  ownProps: PublicProps
): Props => {
  return {
    appId,
    migrationStatus,
    ...ownProps,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SyncMigrationSuccessModal);
