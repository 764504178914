import { createReducer } from 'redux-act';

import * as actions from './actions';
import { MeasurementsState } from './types';
import { legacyMapMeasurementsByName } from './utils';

export const fromLocalStorage = ({ measurements }) => measurements;

const defaultState: MeasurementsState = {
  loading: false,
  error: '',
  appMetricsById: {},
  groupMetricsById: {},
};

const reducer = createReducer<MeasurementsState>({}, defaultState);

reducer.on(actions.loadAppMeasurementsActions.req, (state) => ({
  ...state,
  loading: true,
  error: '',
}));

reducer.on(actions.loadAppMeasurementsActions.rcv, (state, { payload: { appId, measurements } }) => {
  const measurementsByName = legacyMapMeasurementsByName(measurements);

  return {
    ...state,
    loading: false,
    appMetricsById: {
      ...state.appMetricsById,
      [appId]: { lastRefresh: new Date(), measurementsByName },
    },
  };
});

reducer.on(actions.loadAppMeasurementsActions.fail, (state, { error }) => ({
  ...state,
  loading: true,
  error,
}));

reducer.on(actions.getGroupMeasurementsActions.req, (state) => ({
  ...state,
  loading: false,
  error: '',
}));

reducer.on(actions.getGroupMeasurementsActions.rcv, (state, { payload: { groupId, measurements } }) => {
  const measurementsByName = legacyMapMeasurementsByName(measurements);
  return {
    ...state,
    loading: false,
    groupMetricsById: {
      ...state.groupMetricsById,
      [groupId]: { lastRefresh: new Date(), measurementsByName },
    },
  };
});

reducer.on(actions.getGroupMeasurementsActions.fail, (state, { error }) => ({
  ...state,
  loading: false,
  error,
}));

export default reducer;
