import React from 'react';
import { css } from '@leafygreen-ui/emotion';
import IconComponent from '@leafygreen-ui/icon';
import InlineDefinition from '@leafygreen-ui/inline-definition';
import { palette } from '@leafygreen-ui/palette';
import { Body } from '@leafygreen-ui/typography';

import './permission-display.less';

const baseClassName = 'permission-display';

export enum TestSelector {
  Name = 'name',
  PermissionIcon = 'permission-icon',
  PermissionText = 'permission-text',
}

export enum PermissionDisplayValue {
  IconCheck,
  IconX,
  TextAll,
  TextNone,
  TextFieldLevel,
}

export interface Props {
  displayVal: PermissionDisplayValue;
  name: string;
}

export default function PermissionDisplayComponent({ name, displayVal }: Props) {
  let permissionDisplayVal: React.ReactNode;

  switch (displayVal) {
    case PermissionDisplayValue.IconCheck: {
      permissionDisplayVal = (
        <IconComponent
          className={`${baseClassName}-item-icon`}
          glyph="Checkmark"
          fill={palette.green.dark1}
          data-test-selector={TestSelector.PermissionIcon}
        />
      );
      break;
    }
    case PermissionDisplayValue.IconX: {
      permissionDisplayVal = (
        <IconComponent
          className={`${baseClassName}-item-icon`}
          glyph="X"
          fill={palette.gray.dark1}
          data-test-selector={TestSelector.PermissionIcon}
        />
      );
      break;
    }
    case PermissionDisplayValue.TextAll: {
      permissionDisplayVal = (
        <Body className={`${baseClassName}-item-enabled`} data-test-selector={TestSelector.PermissionText}>
          : All
        </Body>
      );
      break;
    }
    case PermissionDisplayValue.TextFieldLevel: {
      permissionDisplayVal = (
        <Body className={`${baseClassName}-item-enabled`} data-test-selector={TestSelector.PermissionText}>
          :{' '}
          <InlineDefinition
            tooltipClassName={css`
              max-width: 300px;
            `}
            definition={'Click here to see Field-level permissions'}
          >
            Field-level
          </InlineDefinition>
        </Body>
      );
      break;
    }
    case PermissionDisplayValue.TextNone: {
      permissionDisplayVal = (
        <Body className={`${baseClassName}-item-disabled`} data-test-selector={TestSelector.PermissionText}>
          : None
        </Body>
      );
      break;
    }
    default: {
      break;
    }
  }

  return (
    <div className={`${baseClassName}-item`}>
      <Body data-test-selector={TestSelector.Name}>{name}</Body>
      {permissionDisplayVal}
    </div>
  );
}
